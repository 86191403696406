// Packages
import React from "react";
import PropTypes from "prop-types";
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Styles
import {
  StyledInputFileButton,
  StyledInputFileContent,
  StyledInputFileButtonName,
  StyledConatinerImg,
  StyleInputHeightWidth,
  StyleLabelHeightWidth,
  StyledHelperTextFile,
} from "./InputFile.styles";

// Import Swiper styles
import "swiper/swiper.min.css";
/* import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css"; */

// Components
import useComponents from "..";

// Controllers
import useControllers from "../../../controllers";

import { StyledHelperText } from "../SelectField/SelectField.styles";

const InputFile = ({
  id,
  label,
  tooltipTitle,
  placeholder = "Cargar archivo",
  onChange,
  inputTypeIcon,
  showPreview = false,
  icon,
  accept,
  maxFiles = 10,
  maxSize = 10485760,
  error,
  directPreview = false,
  valuePreview,
  control,
  textHelper,
  setCustomSize = false,
  inputFileRef,
  ...rest
}) => {
  const { useComponentHooks } = useControllers();
  const { useInputFile } = useComponentHooks();
  const {
    getRootProps,
    getInputProps,
    open,
    files,
    removeFiles,
    filesName,
    errorFile,
    hadledModalPreview,
    showModal,
    handledCloseModal,
    inputHeight,
    inputWidth,
    handleChangeRelation,
    valueWidth,
    valueHeight,
  } = useInputFile(onChange, accept, maxFiles, maxSize, control, id);

  // Component
  const { FormLabel, useModals, useButtons, CheckboxControlled } = useComponents();
  const { DefaultButton } = useButtons();
  const { ModalCustom } = useModals();

  return (
    <StyledInputFileContent>
      <FormLabel label={label} tooltipTitle={tooltipTitle} />
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} {...rest} id={id} ref={inputFileRef} />
        {inputTypeIcon ? (
          <label htmlFor={id}>
            {files.length > 0 && showPreview ? (
              <button
                onClick={() => hadledModalPreview(files)}
                type="button"
                className="button-update underline"
              >
                {inputTypeIcon}
              </button>
            ) : (
              <div className="button-update underline">{inputTypeIcon}</div>
            )}
          </label>
        ) : (
          <StyledInputFileButton
            htmlFor={id}
            directPreview={directPreview}
            defaultValues={files?.length}
          >
            {filesName ? (
              <StyledInputFileButtonName>{filesName}</StyledInputFileButtonName>
            ) : (
              <StyledInputFileButtonName>{placeholder}</StyledInputFileButtonName>
            )}
            {files.length > 0 && showPreview ? (
              <button
                onClick={() => hadledModalPreview(files)}
                type="button"
                className="button-update"
              >
                Examinar...
              </button>
            ) : (
              <div className="button-update">Examinar...</div>
            )}
          </StyledInputFileButton>
        )}
      </div>
      {textHelper && <StyledHelperTextFile dangerouslySetInnerHTML={{ __html: textHelper }} />}

      {(!!valuePreview?.length || !!files?.length) && showPreview && (
        <div className="flex space-x-4 items-center">
          <StyledConatinerImg>
            {valuePreview &&
            files.length === 0 &&
            typeof valuePreview === "object" &&
            valuePreview?.filter((v) => typeof v === "string").length > 0 ? (
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                pagination={{
                  clickable: true,
                }}
                loop={valuePreview.length > 1}
              >
                {valuePreview.map((file, index) => {
                  return (
                    <SwiperSlide key={index} className="py-4">
                      {/(.mp4|.mp3|.avi|.mkv|.mov)/.test(file) ? (
                        <video width="320" height="200" controls>
                          <source src={file} type={"video/mp4"} />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img className="object-contain mx-auto rounded" src={file} />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : typeof valuePreview === "string" ? (
              <div>
                {/(.mp4|.mp3|.avi|.mkv|.mov)/.test(valuePreview) ? (
                  <video width="320" height="200" controls>
                    <source src={valuePreview} type={"video/mp4"} />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img className="object-contain rounded" src={valuePreview} />
                )}
              </div>
            ) : (
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                pagination={{
                  clickable: true,
                }}
                loop={maxFiles > 1 && files.length > 1}
              >
                {files.map((file, index) => {
                  return (
                    <SwiperSlide key={index} className={maxFiles === 1 ? "" : "py-4"}>
                      {/(.mp4|.mp3|.avi|.mkv|.mov)/.test(file.path) ? (
                        <video width="320" height="200" controls>
                          <source src={file.preview} type={file.type ? file.type : "video/mp4"} />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          className={`object-contain rounded ${maxFiles === 1 ? "" : "mx-auto"}`}
                          src={file.preview}
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </StyledConatinerImg>
          {setCustomSize && (
            <div className="flex flex-col justify-between h-full">
              <CheckboxControlled
                control={control}
                label="Bloquear proporción entre ancho y alto"
                id={`${id}_block`}
                name={`${id}_block`}
              />
              <div>
                <StyleLabelHeightWidth htmlFor={`${id}_width`}>Ancho (px)</StyleLabelHeightWidth>
                <StyleInputHeightWidth
                  type="number"
                  control={control}
                  id={`${id}_width`}
                  name={`${id}_width`}
                  ref={inputWidth}
                  value={valueWidth}
                  onChange={handleChangeRelation}
                />
              </div>
              <div className="mt-2">
                <StyleLabelHeightWidth htmlFor={`${id}_height`}>Alto (px)</StyleLabelHeightWidth>
                <StyleInputHeightWidth
                  type="number"
                  control={control}
                  id={`${id}_height`}
                  name={`${id}_height`}
                  ref={inputHeight}
                  value={valueHeight}
                  onChange={handleChangeRelation}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <ModalCustom
        isOpen={showModal}
        children={
          <div>
            <div>
              {files.map((file, index) => (
                <div key={index}>
                  <div className="relative">
                    <img src={file.preview} width="500" />
                    <div className="absolute right-2 bottom-2">
                      <DefaultButton type="button" onClick={() => removeFiles(file)} color="error">
                        Eliminar
                      </DefaultButton>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="justify-center flex mt-5">
              <DefaultButton type="button" color="success" onClick={handledCloseModal}>
                Cerrar
              </DefaultButton>
            </div>
          </div>
        }
      />

      {error && <StyledHelperText>{error.message}</StyledHelperText>}
      {errorFile?.length > 0 && <StyledHelperText>{errorFile[0].message}</StyledHelperText>}
    </StyledInputFileContent>
  );
};

InputFile.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  placeholder: PropTypes.string,
  showPreview: PropTypes.bool,
  inputTypeIcon: PropTypes.any,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  directPreview: PropTypes.bool,
  setCustomSize: PropTypes.bool,
};

export default InputFile;
