// Packages
import styled from "styled-components";
import tw from "twin.macro";

// Components
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Label styles
export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    ${tw`text-sm text-neutral font-light`}
    letter-spacing: -0.006em;
  }
  .Mui-disabled {
    -webkit-text-fill-color: #363635;
  }
  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-bottom: 0rem;
  }
`;

// Focus and hover styles
export const StyledCheckbox = styled(Checkbox)`
  .MuiTouchRipple-root {
    display: none;
  }

  &:hover {
    background-color: transparent !important;

    .StyledCheckboxIcon {
      ${(props) => {
        switch (props.color) {
          case "error":
            return `
              border: 1px solid #FF2D55;
            `;
            break;
          case "success":
            return `
              border: 1px solid #018145;
            `;
            break;
          default:
            return `
              border: 1px solid #FFA700;
            `;
            break;
        }
      }}
    }
  }
  &.Mui-focusVisible {
    background: none;

    .StyledCheckboxIcon {
      ${(props) => {
        switch (props.color) {
          case "error":
            return `
              border: 1px solid #FF2D55;
              outline: 4px solid #f5d4dd;
            `;
            break;
          case "success":
            return `
              border: 1px solid #018145;
              outline: 4px solid #cce6da;
            `;
            break;
          default:
            return `
              border: 1px solid #FFA700;
              outline: 4px solid #fdedcc;
            `;
            break;
        }
      }}
    }
  }
`;
// Styles when checkbox is not checked
export const StyledCheckboxIcon = styled.span.attrs({
  className: "StyledCheckboxIcon",
})`
  ${tw`rounded relative`}
  ${(props) => {
    switch (props.color) {
      case "error":
        return `
          border: 1px solid #FF2D55;
        `;
        break;
      case "success":
        return `
          border: 1px solid #018145;
        `;
        break;
      default:
        return `
          border: 1px solid #B6BBC1;
        `;
        break;
    }
  }}
  height: 24px;
  width: 24px;
`;

// Styles when checkbox is active
export const StyledCheckedIcon = styled(StyledCheckboxIcon)`
  &:before {
    ${tw`absolute block`}
    content: "";
    height: 12px;
    left: 5px;
    top: 5px;
    width: 12px;
    ${(props) => {
      switch (props.color) {
        case "error":
          return `
            background-color: #FF2D55;
          `;
          break;
        case "success":
          return `
            background-color: #018145;
          `;
          break;
        default:
          return `
            background-color:#FFA700;
          `;
          break;
      }
    }}
  }
`;
