import axios from "axios";

const useProfilerProvider = () => {
  const listProfilersProvider = (url, { page }) => {    
    return axios ({
      method: "get",
      url: `profiling-${url}?page=${page}`,
      // params: {search, state, type}
    })
  }

  const getProfilerProvider = (id, url) => {
    return axios ({
      method: "get", 
      url: `profiling-${url}/${id}`,
    })
  }

  const addProfilerProvider = (data, url) => {
    return axios ({
      method: "post",
      url: `profiling-${url}`,
      data
    })
  }

  const editProfilerProvider = ({id, data, url}) => {
    return axios({
      method: "put",
      url: `profiling-${url}/${id}`,
      data: data,
    });
  };

  return {
    listProfilersProvider,
    getProfilerProvider,
    addProfilerProvider,
    editProfilerProvider
  }

}

export default useProfilerProvider;