// Packages
import { useState, useCallback } from 'react';

const useUploadFilesResponse = () => {
  const [showFileTypes, setShowFileTypes] = useState(true);

  const handleShowFileTypes = useCallback(() => {
    setShowFileTypes(prevState => !prevState)
  }, [])

  return {
    handleShowFileTypes,
    showFileTypes,
  }
}

export default useUploadFilesResponse;