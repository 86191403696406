import useAuthTypes from "./auth";
import useToolsTypes from "./tools";
import useUsersTypes from "./users";
import useProjectTypes from "./projects";
import useNotificationsTypes from "./notifications";

const useTypes = () => {
  return {
    useAuthTypes,
    useToolsTypes,
    useUsersTypes,
    useProjectTypes,
    useNotificationsTypes
  };
};

export default useTypes;
