import { combineReducers } from "redux";
import useAuthReducers from "./auth";
import useToolsReducers from "./tools";
import useUserReducers from "./users";
import useProjectReducer from "./projects";

const useReducers = () => {
  const { session } = useAuthReducers();
  const {
    addTools,
    savedQuestions,
    conditionalsList,
    organizeToolsAI,
  } = useToolsReducers();
  const { userSelected } = useUserReducers();
  const { projectSelected } = useProjectReducer();

  return combineReducers({
    session,
    addTools,
    userSelected,
    savedQuestions,
    projectSelected,
    conditionalsList,
    organizeToolsAI,
  });
};

export default useReducers;
