// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StytledWarpperControl = styled.div.attrs((props) => ({
  className: `${props.className} `,
}))`
  ${tw` relative flex items-center`}
  padding-top: 9px;
  padding-bottom: 9px;
`;

export const StyledIndicator = styled.label.attrs((props) => ({
  className: `${props.className} `,
}))`
  ${tw`rounded relative z-10 flex items-center justify-center`}
  height: 24px;
  width: 24px;
  margin-right: 9px;

  &:before {
    ${(props) => {
      switch (props.color) {
        case "error":
          return `
			  background-color: #FF2D55;
			`;

        case "success":
          return `
			  background-color: #018145;
			`;

        default:
          return `
			  background-color:#FFA700;
			`;
      }
    }}
  }

  ${(props) => {
    switch (props.color) {
      case "error":
        return `
			border: 1px solid #FF2D55;
		  `;
        break;
      case "success":
        return `
			border: 1px solid #018145;
		  `;
        break;
      default:
        return `
			border: 1px solid #B6BBC1;
		  `;
        break;
    }
  }}
`;

export const StyledInput = styled.input.attrs((props) => ({
  className: `${props.className} `,
}))`
  ${tw` absolute w-full h-full opacity-0 z-20 top-0 left-0 w-full h-full`}

  &:checked + label {
    &:before {
      content: "";
      height: 12px;
      width: 12px;
      ${tw` relative`}
    }
  }
  &:hover {
    ${StyledIndicator} {
      ${(props) => {
        switch (props.color) {
          case "error":
            return `
				  border: 1px solid #FF2D55;
				`;
          case "success":
            return `
				  border: 1px solid #018145;
				`;
          default:
            return `
				  border: 1px solid #FFA700;
				`;
        }
      }}
    }
  }
`;

export const StyledSummary = styled.div.attrs((props) => ({
  className: `StyledSummary `,
}))`
  ${tw` relative flex items-center justify-between border rounded bg-neutral-30 px-4 py-3 space-x-2 mb-2`}
`;

export const StyledListSummary = styled.div.attrs((props) => ({
  className: `StyledListSummary `,
}))`
  ${tw` relative flex items-center space-x-2`}
`;

export const StyledBadgeCheck = styled.div.attrs((props) => ({
  className: `StyledBadgeCheck `,
}))`
  ${tw` relative border rounded-lg bg-third text-sm px-2 flex items-center`}
  svg {
    width: 12px;
    transform: rotate(45deg);
  }
`;
