import React from "react";
import useComponents from "../../../../../components";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

import _ from "lodash";

import { StyledTableCell, StyledContainerPaper } from "./TableDash.styles";

const FollowMissions = (props) => {
  const { dataQuotesData } = props;
  const { useGrafic, Typography } = useComponents();
  const { Donut } = useGrafic();

  return (
    <div className="relative">
      <Donut
        data={dataQuotesData.chart}
        titleValue={`${parseFloat(dataQuotesData.general?.total_percentage)}%`}
        className="my-6"
      />
      <div className="absolute top-0 right-0">
        <Typography variant="h3">
          Total Target:{" "}
          {parseInt(dataQuotesData.general?.total_quotas) +
            parseInt(dataQuotesData.general?.total_quotas_not_consumer)}
        </Typography>
        <Typography variant="h3">
          Total ocupadas:{" "}
          {parseInt(dataQuotesData.general?.current_quotas_consumer) +
            parseInt(dataQuotesData.general?.current_quotas_no_consumer)}
        </Typography>
      </div>
      <StyledContainerPaper>
        <TableContainer component={Paper} style={{ background: "transparent" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={1} align="center" border="0" />
                <StyledTableCell colSpan={3} className="bg-neutral-70" border="0" color="#000">
                  CONSUMIDOR
                </StyledTableCell>
                <StyledTableCell colSpan={3} className="bg-neutral-60" border="0" color="#000">
                  NO CONSUMIDOR
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell color="#000" font="400" align="left">
                  TIPO
                </StyledTableCell>
                <StyledTableCell>TARGET</StyledTableCell>
                <StyledTableCell>
                  LICENCIAS
                  <br />
                  OCUPADAS
                </StyledTableCell>
                <StyledTableCell>% OCUPACIÓN</StyledTableCell>
                <StyledTableCell>TARGET</StyledTableCell>
                <StyledTableCell>
                  LICENCIAS
                  <br />
                  OCUPADAS
                </StyledTableCell>
                <StyledTableCell>% OCUPACIÓN</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell width="15%" align="left">
                  Tipo
                </StyledTableCell>
                <StyledTableCell width="100px" className="bg-neutral-40">
                  {dataQuotesData.general?.total_quotas}
                </StyledTableCell>
                <StyledTableCell width="100px" className="bg-neutral-40">
                  {dataQuotesData.general?.current_quotas_consumer}
                </StyledTableCell>
                <StyledTableCell width="150px" className="bg-neutral-40">
                  {dataQuotesData.general?.percentage}
                </StyledTableCell>
                <StyledTableCell width="100px" className="bg-neutral-20">
                  {dataQuotesData.general?.total_quotas_not_consumer}
                </StyledTableCell>
                <StyledTableCell width="100px" className="bg-neutral-20">
                  {dataQuotesData.general?.current_quotas_no_consumer}
                </StyledTableCell>
                <StyledTableCell width="150px" className="bg-neutral-20">
                  {dataQuotesData.general?.percentage_no_consumer}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContainerPaper>
      <StyledContainerPaper>
        <TableContainer component={Paper} style={{ background: "transparent" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell color="#000" align="left" font="400" colSpan={7}>
                  Región
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(dataQuotesData.list?.regions, (itemRegion, index) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell width="15%" align="left">
                      {itemRegion?.region}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-40">
                      {itemRegion?.target}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-40">
                      {itemRegion?.current}
                    </StyledTableCell>
                    <StyledTableCell width="150px" className="bg-neutral-40">
                      {itemRegion?.percentage}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-20">
                      {itemRegion?.target_no_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-20">
                      {itemRegion?.current_no_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="150px" className="bg-neutral-20">
                      {itemRegion?.percentage_no_consumer}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContainerPaper>
      <StyledContainerPaper>
        <TableContainer component={Paper} style={{ background: "transparent" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell color="#000" align="left" font="400" colSpan={7}>
                  NSE
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(dataQuotesData.list?.nse, (itemNse, index) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell width="15%" align="left">
                      {itemNse?.nse}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-40">
                      {itemNse?.target}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-40">
                      {itemNse?.current}
                    </StyledTableCell>
                    <StyledTableCell width="150px" className="bg-neutral-40">
                      {itemNse?.percentage}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-20">
                      {itemNse?.target_no_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-20">
                      {itemNse?.current_no_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="150px" className="bg-neutral-20">
                      {itemNse?.percentage_no_consumer}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContainerPaper>
      <StyledContainerPaper>
        <TableContainer component={Paper} style={{ background: "transparent" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell color="#000" align="left" font="400" colSpan={7}>
                  GÉNERO
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(dataQuotesData.list?.genders, (itemGender, index) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell width="15%" align="left">
                      {itemGender?.gender}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-40">
                      {itemGender?.target_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-40">
                      {itemGender?.current_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="150px" className="bg-neutral-40">
                      {itemGender?.percentage_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-20">
                      {itemGender?.target_not_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-20">
                      {itemGender?.current_not_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="150px" className="bg-neutral-20">
                      {itemGender?.percentage_not_consumer}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContainerPaper>
      <StyledContainerPaper>
        <TableContainer component={Paper} style={{ background: "transparent" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell color="#000" align="left" font="400" colSpan={7}>
                  EDAD
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(dataQuotesData.list?.ages, (itemAge, index) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell width="15%" align="left">
                      {itemAge?.age_range}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-40">
                      {itemAge?.target}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-40">
                      {itemAge?.current}
                    </StyledTableCell>
                    <StyledTableCell width="150px" className="bg-neutral-40">
                      {itemAge?.percentage}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-20">
                      {itemAge?.target_no_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="100px" className="bg-neutral-20">
                      {itemAge?.current_no_consumer}
                    </StyledTableCell>
                    <StyledTableCell width="150px" className="bg-neutral-20">
                      {itemAge?.percentage_no_consumer}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContainerPaper>
    </div>
  );
};

export default FollowMissions;
