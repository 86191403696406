// Packages
import React, { useRef } from "react";
import PropTypes from "prop-types";

// Styles
import { StyledFormControl, StyledAutocomplete } from "./SelectMultiField.styles";
import { StyledTextField } from "../TextField/TextField.styles";
import { StyledHelperText } from "../SelectField/SelectField.styles";

// Components
import FormLabel from "../FormLabel";
import Checkbox from "../Checkbox";

const SelectMultiField = (props) => {
  const {
    label,
    tooltipTitle,
    options,
    selectedValue,
    error,
    limitTags,
    disabled,
    placeholder,
    renderchecked,
    onKeyUp,
    maxSelection,
    ...rest
  } = props;

  const componentRef = useRef(null);
  const isMaxSelectionReached = selectedValue.length === maxSelection;

  return (
    <StyledFormControl fullWidth>
      <StyledAutocomplete
        multiple
        limitTags={Math.min(
          limitTags,
          parseInt(componentRef.current ? componentRef?.current?.offsetWidth / 150 : 100)
        )}
        options={options}
        value={selectedValue}
        onKeyDown={(event) => {
          onKeyUp(event.target.value);
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disabled={disabled}
        placeholder={placeholder}
        classes={{ popper: renderchecked && "abi-popper-check" }}
        renderOption={(props, option, { selected }) => {
          const isOptionDisabled = isMaxSelectionReached && !selected;
          return renderchecked ? (
            <li {...props} className="whitespace-nowrap">
              <Checkbox disabled={isOptionDisabled} checked={selected} label={option.label} title={option.label} />
            </li>
          ) : (
            <li {...props}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <StyledTextField
            label={<FormLabel label={label} tooltipTitle={tooltipTitle} error={error} />}
            {...params}
            variant="standard"
            placeholder={placeholder}
          />
        )}
        ref={componentRef}
        {...rest}
      />
      {error && <StyledHelperText>{error?.message}</StyledHelperText>}
    </StyledFormControl>
  );
};

SelectMultiField.propTypes = {
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  error: PropTypes.any,
  limitTags: PropTypes.any,
  disabled: PropTypes.bool,
  renderchecked: PropTypes.bool,
  onKeyUp: PropTypes.func,
  maxSelection: PropTypes.number,
};

SelectMultiField.defaultProps = {
  options: [],
  limitTags: 10,
  renderchecked: false,
  onKeyUp: () => {},
  maxSelection: Infinity,
};

export default SelectMultiField;
