// Packages
import React from "react";

// Controller
import useControllers from "../../../controllers";

// Components
import useComponents from "..";

// Styles
import {
  StyledNavBar,
  StyledNavBarHeader,
  StyledNavBarContent,
  StyleNavBarMenu,
  StyleNavBarItem,
  StyledButtonNabvar,
  StyledAccordionContent,
  StyledAccordionHeader,
  StyledAccordionBody,
} from "./NavBar.styles";

// Assets
import useIcons from "../../../assets/icons";

const NavBar = () => {
  const { IconLogo, IconArrowLeft, IconAccordionArrowDown, IconAccordionArrowUp } = useIcons();

  const { useComponentHooks } = useControllers();
  const { useNavBar } = useComponentHooks();
  const {
    handledButton,
    statusNavBar,
    navBarItems,
    elementActive,
    handleClickElement,
    handleNavigationToSubItems,
    handleNavigateTo,
  } = useNavBar();
  const { Typography } = useComponents();


  const isNotEmpty = (subItems) => {
    return subItems?.length > 0;
  };

  return (
    <StyledNavBar status={statusNavBar}>
      <StyledNavBarHeader status={statusNavBar}>
        <span className="mr-2">
          <IconLogo />
        </span>
        {statusNavBar ? <Typography>Membeer</Typography> : null}
      </StyledNavBarHeader>
      <StyledNavBarContent status={statusNavBar}>
        <StyledButtonNabvar
          onClick={handledButton}
          status={statusNavBar}
          firstActive={navBarItems[0]?.name === elementActive}
        >
          <IconArrowLeft color={navBarItems[0]?.name === elementActive ? "#FFB62A" : "#000000"} />
          <IconArrowLeft color={navBarItems[0]?.name === elementActive ? "#FFB62A" : "#000000"} />
        </StyledButtonNabvar>
        <StyleNavBarMenu>
          {navBarItems?.map((item, index) => {
            return (
              <StyledAccordionContent
                key={index}
                expanded={item.name === elementActive}
                onClick={() => handleClickElement(item.name)}
              >
                <StyledAccordionHeader>
                  <span onClick={() => handleNavigateTo(item)}>
                    <span className="icon">
                      <img
                        src={
                          `${process.env.REACT_APP_AI_GENERATION_URL}` +
                          (item.name === elementActive
                            ? item.iconActive?.url.replace(/^\//, "")
                            : item.icon?.url.replace(/^\//, ""))
                        }
                      />
                    </span>
                    {statusNavBar && (
                      <>
                        <span>{item.name}</span>
                        {isNotEmpty(item?.optionSuboption) ? (
                          <span>
                            {item.name === elementActive ? (
                              <IconAccordionArrowUp color="#000000" />
                            ) : (
                              <IconAccordionArrowDown color="#000000" />
                            )}
                          </span>
                        ) : null}
                      </>
                    )}
                  </span>
                </StyledAccordionHeader>
                {isNotEmpty(item?.optionSuboption) ? (
                  <StyledAccordionBody className={!statusNavBar ? "contracted" : ""}>
                    {item.optionSuboption.map((subItem, index) => (
                      <StyleNavBarItem key={index} statusNavBar={statusNavBar}>
                        <span
                          onClick={() => handleNavigationToSubItems(subItem)}
                          className={
                            window.location.pathname.includes(subItem.link) ? "active" : ""
                          }
                        >
                          <span>{subItem.name}</span>
                        </span>
                      </StyleNavBarItem>
                    ))}
                  </StyledAccordionBody>
                ) : null}
              </StyledAccordionContent>
            );
          })}
        </StyleNavBarMenu>
      </StyledNavBarContent>
    </StyledNavBar>
  );
};

export default NavBar;
