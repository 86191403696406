import React from "react";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";

// Components
import DatePikerRange from "../DatePikerRange";

const DatePickerRangeControlled = ({
  id,
  name,
  label,
  control,
  disabled,
  defaultValue,
  placeholder,
  onClickClear,
  format,
  dateWithHours,
  plugins,
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue ?? [null, null],
  });

  const auxChange = (value) => {
    dateWithHours ? onChange(value) : onChange([value[0]?.toDate(), value[1]?.toDate()]);
  };

  return (
    <DatePikerRange
      id={id}
      name={name}
      label={label}
      onChange={auxChange}
      auxValue={value}
      control={control}
      disabled={disabled}
      error={error}
      placeholder={placeholder}
      onClickClear={onClickClear}
      plugins={plugins}
      format={format}
    />
  );
};

DatePickerRangeControlled.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  placeholder: PropTypes.string,
  onClickClear: PropTypes.func,
  format: PropTypes.string,
  dateWithHours: PropTypes.bool,
  plugins: PropTypes.array,
};

export default DatePickerRangeControlled;
