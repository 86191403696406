import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import _ from "lodash";
import debounce from "lodash.debounce";
import useApi from "../../../../api";

import useHelpers from "../../../../helpers";

const useProjectsRoot = () => {
  const [listProjects, setListProjects] = useState();
  const [valueTextSearch, setValueTextSearch] = useState("");
  const [valueCountrie, setValueCountrie] = useState("");
  const [valueClient, setValueClient] = useState("");
  const [valueStatus, setValuestatus] = useState("");
  const [valuInitDate, setValuInitDate] = useState("");
  const [valueEndDate, setValueEndDate] = useState("");
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });

  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [listCountries, setListCountries] = useState();

  //Actions
  const { useActions } = useApi();
  const { useGeneralActions, useProjectsActions } = useActions();
  const { actListProjects, actDeleteProject } = useProjectsActions();

  const { actListCountries } = useGeneralActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useGeneral, useNavigation, useValidators } = useQuickFunctions();
  const { navigateTo } = useNavigation();
  const { filterptojectValidationShema } = useValidators();
  const { formatDate } = useGeneral();

  const { control, handleSubmit, resetField } = useForm({
    mode: "all",
    resolver: yupResolver(filterptojectValidationShema),
    defaultValues: {
      range_date: null
    }
  });

  const onClickClear = () => resetField("range_date");

  const onSubmitFilter = (data, e) => {
    e.preventDefault();

    setValueCountrie(data.country_id);
    setValueClient(data.client_id);
    setValuestatus(data.status);
    setValuInitDate(!!data.range_date ? formatDate(data.range_date[0], "Y-mm-dd", false) : null);
    setValueEndDate(!!data.range_date ? formatDate(data.range_date[1], "Y-mm-dd", false) : null);

    actListProjects(
      {
        page: 1,
        search: valueTextSearch,
        status: data.status,
        country_id: data.country_id,
        client_id: data.client_id,
        init_date: !!data.range_date ? formatDate(data.range_date[0], "Y-mm-dd", false) : null,
        end_date: !!data.range_date ? formatDate(data.range_date[1], "Y-mm-dd", false) : null,
      },
      loadDataProjects
    );
  };

  const loadDataProjects = (data) => {
    setListProjects(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };

  const loadDataPagination = (data) => {
    setListProjects(data.data);
  };

  const handlePagination = (nro) => {
    actListProjects(
      {
        page: nro.selected + 1,
        search: valueTextSearch,
        country_id: valueCountrie,
        client_id: valueClient,
        status: valueStatus,
        init_date: valuInitDate,
        end_date: valueEndDate,
      },
      loadDataPagination
    );
  };

  const handleSubmitSearch = (e) => {
    setValueTextSearch(e);
    actListProjects({ page: 1, search: e }, loadDataProjects);
  };

  const handleChangeSearch = useCallback(debounce(handleSubmitSearch, 750), []);

  useEffect(() => {
    actListProjects({ page: 1 }, loadDataProjects);
  }, []);

  const handleEditProject = (project) => {
    navigateTo(`/proyectos/editar/${project.id}`);
  };

  const dataListCountries = (data) => {
    setListCountries(
      _.map(data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  useEffect(() => {
    actListCountries(dataListCountries);
  }, []);

  const handleDeleteProject = (id, name) => {
    setModalDelete({ show: true, name: name, id: id });
  };

  
  const handleConfirmDelete = () => {
    const onSuccessDelete = () => {
      actListProjects({ page: 1 }, loadDataProjects);
    }
    actDeleteProject(modalDelete.id, onSuccessDelete);
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handleCloseModal = () => {
    setModalDelete({ show: false, name: "", id: 0 })
  };

  return {
    control,
    listProjects,
    listCountries,
    paginationSettings,
    handlePagination,
    handleChangeSearch,
    handleEditProject,
    handleSubmit,
    onSubmitFilter,
    handleDeleteProject,
    modalDelete,
    handleCloseModal,
    handleConfirmDelete,
    onClickClear
  };
};

export default useProjectsRoot;
