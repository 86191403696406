// Packages
import React from "react";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";

// Components
import InputFile from "../InputFile";

const InputFileControlled = (props) => {
  const {
    control,
    name,
    id,
    label,
    tooltipTitle,
    placeholder,
    showPreview,
    inputTypeIcon,
    accept,
    maxFiles,
    directPreview,
    maxSize,
    inputChange,
    textHelper,
    setCustomSize
  } = props;
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = e => {
    inputChange && inputChange(e)
    return onChange(e);
  }

  return (
    <InputFile
      showPreview={showPreview}
      inputTypeIcon={inputTypeIcon}
      accept={accept}
      maxFiles={maxFiles}
      maxSize={maxSize}
      error={error}
      tooltipTitle={tooltipTitle}
      placeholder={placeholder}
      label={label}
      id={id}
      type="file"
      name={name}
      value={value?.filename}
      valuePreview={value}
      directPreview={directPreview}
      control={control}
      textHelper={textHelper}
      setCustomSize={setCustomSize}
      onChange={(event) => handleChange(event)}
    />
  );
};

InputFileControlled.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  showPreview: PropTypes.bool,
  inputTypeIcon: PropTypes.any,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  createForm: PropTypes.bool,
  directPreview: PropTypes.bool,
  maxSize: PropTypes.number,
  inputChange: PropTypes.func,
};

export default InputFileControlled;
