const IconDashboard = ({color = "#B6B9BA"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={ color }
        d="M6.364 0H2.727A2.727 2.727 0 000 2.727V17.16a2.727 2.727 0 002.727 2.727h3.637A2.727 2.727 0 009.09 17.16V2.727A2.727 2.727 0 006.364 0zM17.273 0h-3.636a2.727 2.727 0 00-2.728 2.727v3.637a2.727 2.727 0 002.727 2.727h3.637A2.727 2.727 0 0020 6.364V2.727A2.727 2.727 0 0017.273 0z"
      ></path>
      <path
        fill={ color }
        d="M17.273 10.91h-3.636a2.727 2.727 0 00-2.728 2.726v3.637A2.727 2.727 0 0013.636 20h3.637A2.727 2.727 0 0020 17.273v-3.636a2.727 2.727 0 00-2.727-2.728z"
        opacity="0.32"
      ></path>
    </svg>
  );
}

export default IconDashboard;