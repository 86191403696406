import axios from "axios";

const useNewsProviders = () => {
  const listNewsProvider = ({ page, search, status, country_id, author, init_date, end_date }) => {
    return axios({
      method: "get",
      url: `articles?page=${page}`,
      params: { search, status, country_id, author, init_date, end_date },
    });
  };

  const createNewProvider = (data) => {
    return axios({
      method: "post",
      url: `articles`,
      data,
    });
  };

  const editNewProvider = (id, data) => {
    return axios({
      method: "post",
      url: `articles/${id}`,
      data,
    });
  };

  const getNewProvider = (id) => {
    return axios({
      method: "get",
      url: `articles/${id}`,
    });
  };

  const getTotalNewsProvider = () => {
    return axios({
      method: "get",
      url: `articles/count`,
    });
  };

  const cloneNewProvider = (id) => {
    return axios({
      method: "post",
      url: `articles/clone/${id}`,
    });
  };
  
  const deleteNewProvider = (id) => {
    return axios({
      method: "delete",
      url: `articles/${id}`,
    });
  };

  return {
    listNewsProvider,
    createNewProvider,
    editNewProvider,
    getNewProvider,
    getTotalNewsProvider,
    cloneNewProvider,
    deleteNewProvider,
  };
};
export default useNewsProviders;
