// Packages
import React from "react";

import useComponents from "../../../components";
import useLayouts from "../../../layouts";

// Controllers
import useControllers from "../../../../controllers";

const ForgotPassword = () => {
  const { Wrapper, TextFieldControlled, useButtons, Typography, useModals } = useComponents();
  const { PublicContentLayout } = useLayouts();
  const { DefaultButton } = useButtons();
  const { DefaultModal } = useModals();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { usePassword } = useScreenHooks();
  const { usePasswordForgot } = usePassword();
  const { control, handleSubmit, onSubmit, handleBack, showModal, handleCloseModal } =
    usePasswordForgot();

  return (
    <PublicContentLayout login>
      <Wrapper
        showLogo={true}
        description={""}
        maxWidth={"582px"}
        className="container"
        title="¿OlvidÓ Su contraseña?"
        subTitle={"Ingrese su email para restablecerla."}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <TextFieldControlled
            id={"email"}
            name={"email"}
            label={"Email"}
            type={"email"}
            placeholder="mail@mail.com"
            control={control}
          />
          <div className="text-center flex space-x-4 items-center justify-center">
            <DefaultButton
              variant="outlined"
              type="button"
              color="secondary"
              onClick={() => handleBack()}
            >
              Cancelar
            </DefaultButton>
            <DefaultButton type="submit" color="secondary">
              Enviar
            </DefaultButton>
          </div>
        </form>
      </Wrapper>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography
            variant="body2"
            className="w-72"
            sx={{ fontSize: "16px", lineHeight: "22px", letter: "-1.1%", fontWeight: 400 }}
          >
            Enviamos un correo electrónico a tu email registrado con un enlace para que recuperes el
            acceso a tu cuenta.
          </Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </PublicContentLayout>
  );
};

export default ForgotPassword;
