import useServices from "../../services";

const useDashboardActions = () => {
  const { useDasboardServices } = useServices();
  const { getDashboardService, getInfoMembeerService } = useDasboardServices();

  const getActDashboard = async (params, onSuccess, onError) => {
    try {
      const response = await getDashboardService(params);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const getActInfoMembeer = async (params, onSuccess, onError) => {
    try {
      const response = await getInfoMembeerService(params);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  return { getActDashboard, getActInfoMembeer };
};
export default useDashboardActions;
