import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

// Components
import useLayouts from "../../layouts";
import useComponents from "../../components";

// Controllers
import useControllers from "../../../controllers";



const SubItems = () => {
  const { PrivateContentLayout } = useLayouts();
  const { Card } = useComponents();

  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { handleBack, header } = useTools();

  const location = useLocation();

  const pathWithOutId = useMemo(() => {
    const pathArray = location.pathname.split("/");
    const filterArray = pathArray.filter((path) => isNaN(path));
    return filterArray.join("/");
  }, [location.pathname]);

  return (
    <PrivateContentLayout
      title="Sub Opciones"
      breadcrumbsList={header[`/${pathWithOutId}`]}
      handleBack={handleBack}
      showBack={true}
    >
        <div className="px-8 py-10 mx-6 mb-4 bg-grey rounded-lg grid gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          {
            location.state.optionSuboption.map(subItem => (
              <Card title={subItem.name} description={subItem.description} link={subItem.url} />
            ))
          }
        </div>
    </PrivateContentLayout>
  );
};

export default SubItems;
