import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useHelpers from "../../../../helpers";

import _ from "lodash";
import useApi from "../../../../api";

const paymentsRoot = () => {
  const navigation = useNavigate();

  //Actions
  const { useActions } = useApi();
  const { useGeneralActions } = useActions();
  const { actListCountries } = useGeneralActions();

  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { paymentsCountryShema } = useValidators();

  const [listCountries, setListCountries] = useState([]);

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(paymentsCountryShema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    navigation(`/pagos/detalle/${data.country_id}`);
  };

  const dataListCountries = (data) => {
    setListCountries(
      _.map(data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  useEffect(() => {
    actListCountries(dataListCountries);
  }, []);

  return {
    handleSubmit,
    control,
    onSubmit,
    listCountries,
  };
};
export default paymentsRoot;
