// Packages
import styled from 'styled-components';
import tw from 'twin.macro';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

export const StyledContentButtons = styled.div.attrs({
  className: "StyledContentButtons"
})`
  ${tw`flex justify-end items-center`}

  button {
    margin-left: 14px;
  }
`;
