import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useControllers from "../../..";
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

const useAnswersMission = () => {
  const params = useParams();
  const [checkAll, setCheckAll] = useState(false);
  const [isVideoCall, setIsVideoCall] = useState(false);
  const [list, setList] = useState([]);
  const [valuesChecks, setValuesChecks] = useState([]);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });

  //Data section Quotes
  const [dataQuotesData, setDataQuotesData] = useState({});

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { FilterTaskValidationShema } = useValidators();

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(FilterTaskValidationShema),
    defaultValues: {
      range_date: [],
    },
  });

  const listHeaderTable = [
    /* "Fecha de suscripción",
    "Ultimo ingreso", */
    "Membeer",
    "Estado membeer",
    "Estado",
    "Email",
    "NSE",
    "Género",
    "País",
    "Consumidor",
  ];

  const listGridCell = [/* "1fr", "1fr", */ "2fr", "1fr", "1fr", "2fr", "1fr", "1fr", "1fr", "1fr"];

  // Actions
  const { useActions } = useApi();
  const { useGeneralActions, useMissionActions } = useActions();
  const { actExportExel } = useGeneralActions();
  const { actMissionMembeers, actApproveTool } = useMissionActions();

  const { useGeneralHooks } = useControllers();
  const { createDownloadExel } = useGeneralHooks();

  const loadData = (data) => {
    setPaginationSettings({ current: data?.meta.current_page, totalPages: data?.meta.last_page });
    setList(data?.data);
    setDataQuotesData({
      chart: [
        {
          name: "Completados",
          value: data?.graph?.completed,
          fill: "#FFA700",
        },
        {
          name: "Por Completar",
          value: data?.graph?.todo,
        },
      ],
      list: data?.data,
      general: {
        total_percentage: data?.graph?.total_percentage,
        todo: data?.graph?.todo,
        completed: data?.graph?.completed,

      },
    });
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    actMissionMembeers(
      {
        id: params.id,
        page: 1,
        status: data.abiFilterStatus,
      },
      loadData
    );
  };

  const handleDownloadExcel = () => {
    actExportExel(`missions/membeers`, params.id, (result) => {
      createDownloadExel(result);
    });
  };

  const handlePagination = (nro) => {
    actMissionMembeers(
      {
        id: params.id,
        page: nro.selected + 1,
      },
      loadData
    );
  };

  const handleChangeAllCheck = () => {
    setCheckAll(!checkAll);

    setValuesChecks(list);

    if (checkAll) {
      setValuesChecks([]);
    }
  };

  const handleChangeCheck = (e) => {
    const { id, checked } = e.target;

    setValuesChecks([...valuesChecks, parseInt(id)]);

    if (!checked) {
      setValuesChecks(valuesChecks.filter((item) => item !== parseInt(id)));
    }
  };

  const handleSubmitApprove = () => {
    const onSuccess = () => {
      setCheckAll(false);
      setValuesChecks([]);
      actMissionMembeers({ id: params.id, tool_id: params.tool_id }, loadData);
    };
    const data = {
      tool_id: parseInt(params.tool_id),
      mission_id: parseInt(params.id),
      all: checkAll,
      users: valuesChecks,
    };
    actApproveTool(data, onSuccess);
  };

  useEffect(() => {
    setIsVideoCall(!!params.tool_id);
    actMissionMembeers({ id: params.id, tool_id: params.tool_id }, loadData);
  }, []);

  return {
    handleDownloadExcel,
    paginationSettings,
    handlePagination,
    checkAll,
    handleChangeAllCheck,
    valuesChecks,
    handleChangeCheck,
    isVideoCall,
    listHeaderTable,
    listGridCell,
    handleSubmitApprove,
    handleSubmit,
    onSubmit,
    control,
    dataQuotesData,
  };
};

export default useAnswersMission;
