// Packages
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledContentCloseUser = styled.div.attrs({
  className: "StyledContentCloseUser"
})`
  ${tw`flex items-center justify-start space-x-2 w-1/2`}

  .MuiFormGroup-root {
    ${tw`flex-shrink-0`}
  }
  .MuiFormControl-root {
    ${tw`w-20`}
  }
`

export const StyledMultipleChoiceAddButton = styled.div.attrs({
  className: "StyledMultipleChoiceAddButton"
})`
  ${tw`flex items-start`}

  button {
    ${tw`bg-third flex justify-center items-center rounded p-1`}
    height: 44px;
    width: 44px;
  }
`