import IconLogo from "./iconLogo";
import IconLogoName from "./iconLogoName";
import IconArrowLeft from "./iconArrowLeft";
import IconBack from "./iconBack";
import IconTooltipForm from "./iconTooltipForm";
import IconArrowDown from "./iconArrowDown";
import IconSearch from "./iconSearch";
import IconTools from "./iconTools";
import IconDashboard from "./iconDashboard";
import IconProject from "./iconProject";
import IconCopy from "./iconCopy";
import IconFilter from "./iconFilter";
import IconUser from "./iconUser";
import IconEdit from "./iconEdit";
import IconTask from "./iconTask";
import IconDelete from "./iconDelete";
import IconCheck from "./iconCheck";
import IconWarning from "./iconWarning";
import IconPlus from "./iconPlus";
import IconUserStatus from "./iconUserStatus";
import IconReport from "./iconReport";
import IconDownload from "./iconDownload";
import IconUserGroup from "./iconUserGroup";
import IconMarker from "./iconMarker";
import IconUpdateImage from "./iconUpdateImage";
import IconLanguaje from "./IconLanguaje";
import IconBrand from "./IconBrand";
import IconNews from "./IconNews";
import IconNotifications from "./IconNotifications";
import IconFaq from "./IconFaq";
import IconPaid from "./IconPaid";
import IconPerfilation from "./IconPerfilation";
import IconUpload from "./iconUpload";
import IconForbidden from "./iconForbidden";
import IconCheckNormal from "./iconCheckNormal";
import IconUserRemove from "./iconUserRemove";
import IconArrowStadisctics from "./iconArrowStadisctics";
import IconArrowFilter from "./iconArrowFilter";
import IconChartBar from "./iconChartBar";
import IconEye from "./iconEye";
import IconBug from "./iconBug";
import IconFace from "./iconFace";
import IconMap from "./iconMap";
import IconQuestions from "./iconQuestions";
import IconArrow from "./iconArrow";
import IconDots from "./iconDots";
import IconHammer from "./iconHammer";
import IconSend from "./iconSend";
import IconList from "./iconList";
import IconAccordionArrowDown from "./iconAccordionArrowDown";
import IconAccordionArrowUp from "./iconAccordionArrowUp";

const useIcons = () => {
  return {
    IconLogo,
    IconLogoName,
    IconArrowLeft,
    IconTooltipForm,
    IconArrowDown,
    IconSearch,
    IconTools,
    IconDashboard,
    IconProject,
    IconCopy,
    IconFilter,
    IconUser,
    IconEdit,
    IconBack,
    IconTask,
    IconDelete,
    IconCheck,
    IconPlus,
    IconUserStatus,
    IconReport,
    IconDownload,
    IconUserGroup,
    IconMarker,
    IconUpdateImage,
    IconLanguaje,
    IconBrand,
    IconNews,
    IconNotifications,
    IconFaq,
    IconPaid,
    IconPerfilation,
    IconUpload,
    IconForbidden,
    IconCheckNormal,
    IconUserRemove,
    IconArrowStadisctics,
    IconArrowFilter,
    IconChartBar,
    IconEye,
    IconBug,
    IconFace,
    IconMap,
    IconQuestions,
    IconWarning,
    IconArrow,
    IconDots,
    IconHammer,
    IconSend,
    IconList,
    IconAccordionArrowDown,
    IconAccordionArrowUp,
  };
};

export default useIcons;
