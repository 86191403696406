// Packages
import React from "react";
import PropTypes from "prop-types";

// Styles
import { StyledRange } from "./RangeField.styles";

const Rangefield = (props) => {
  const { marks, defaultValue, min, max } = props;
  return (
    <StyledRange
      track={false}
      defaultValue={defaultValue}
      marks={marks}
      step={1}
      min={min}
      max={max}
    />
  );
};

Rangefield.prototype = {
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  defaultValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default Rangefield;
