import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Styles
import {
  StyledPrivateContent,
  StyledInnerContent,
  StyledInnerContentChildren,
  StyledContainerBack,
  StyledWarpperContent,
} from "./privateContent.styles";

// Components
import useComponents from "../../components";

// Helper
import useHelpers from "../../../helpers";

import useModels from "../../../models";

const PrivateContentLayout = ({
  title,
  children,
  background = "primary",
  breadcrumbsList = [],
  showBack = false,
  handleBack = () => {},
}) => {

  const { Footer, Header, NavBar, useSpinners } = useComponents();
  const { SpinnerLoading } = useSpinners();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useRouterHelper } = useQuickFunctions();
  const { requireAuth } = useRouterHelper();

  // Models
  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();

  const { loader } = useSelector(session);

  useEffect(() => {
    requireAuth();
  }, []);

  return (
    <StyledPrivateContent background={background}>
      <StyledWarpperContent>
        <NavBar />
        <StyledInnerContent>
          <Header
            breadcrumbsList={breadcrumbsList}
            title={title}
            showBack={showBack}
            handleBack={handleBack}
          />
          <StyledInnerContentChildren>{children}</StyledInnerContentChildren>
        </StyledInnerContent>
      </StyledWarpperContent>
      <SpinnerLoading isOpen={loader && !window.location.pathname.includes("herramientas")} text={"Cargando..."} />
      <Footer />
    </StyledPrivateContent>
  );
};

PrivateContentLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  background: PropTypes.string,
  showBack: PropTypes.bool,
  handleBack: PropTypes.func,
  breadcrumbsList: PropTypes.arrayOf(PropTypes.string),
};

export default PrivateContentLayout;