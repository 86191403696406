import useHelpers from "../../../helpers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useAuthReducers = () => {
  // Helpers
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //strings
  const { useTypes } = useStrings();
  const { useAuthTypes } = useTypes();
  const { LOGIN, LOGOUT, LOADER_OFF, LOADER_ON } = useAuthTypes();

  // State
  const { useAuthInitialStates } = useInitialStates();
  const { sessionInitialState } = useAuthInitialStates();

  const sessionHandler = {
    [LOGIN](state, action) {
      const { payload } = action;
      const { auth_token, user } = payload;

      return {
        ...state,
        authToken: auth_token,
        name: user.name,
        lastname: user.last_name,
        email: user.email,
        rol: user.rol,
        country: user.country,
      };
    },
    [LOGOUT]() {
      return sessionInitialState;
    },
    [LOADER_ON](state) {
      return {...state, loader: true};
    },
    [LOADER_OFF](state) {
      return {...state, loader: false}
    }
  };

  const session = createReducer(sessionInitialState, sessionHandler);

  return {
    session,
  };
};

export default useAuthReducers;
