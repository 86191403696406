import { lazy } from "react"

const DefaultMarker = lazy(() => import("./DefaultMarker"))

const useMarkerMap = () => {
  return {
    DefaultMarker,
  }
}

export default useMarkerMap;