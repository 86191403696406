import axios from "axios";

const useBrandsProviders = () => {
  const listBrandsProvider = () => {
    return axios({
      method: "get",
      url: `brands`,
    });
  };

  const listBrandPaginateProvider = ({ page, search, status, init_date, end_date }) => {
    return axios({
      method: "get",
      url: `brands?page=${page}`,
      params: { paginate: true, search, status, init_date, end_date },
    });
  };
  const createBrandProvider = (data) => {
    return axios({
      method: "post",
      url: `brands`,
      data,
    });
  };

  const getBrandProvider = (id) => {
    return axios({
      method: "get",
      url: `brands/${id}`,
    });
  };

  const editBrandProvider = (id, data) => {
    return axios({
      method: "put",
      url: `brands/${id}`,
      data,
    });
  };

  return {
    listBrandsProvider,
    listBrandPaginateProvider,
    createBrandProvider,
    getBrandProvider,
    editBrandProvider,
  };
};
export default useBrandsProviders;
