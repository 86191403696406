import React from "react";
import { Routes, Route, Outlet, useLocation, useMatch } from "react-router-dom";
import useLayouts from "../../layouts";
import useControllers from "../../../controllers";

// Components
import GamificationView from "./Views";
import GamificationCreate from "./Create";

const GamificationLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const { useScreenHooks } = useControllers();
  const { useGamification } = useScreenHooks();
  const { handleBack, headerAux } = useGamification();

  const location = useLocation();
  const match = useMatch("/gamification/*");
  const basePath = match ? match.pathnameBase : "/gamification";
  const isExact = basePath === location.pathname;

  return (
    <PrivateContentLayout
      breadcrumbsList={headerAux}
      handleBack={handleBack}
      showBack={!isExact}
      title="Gamification"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Gamification = () => (
  <Routes>
    <Route path="/" element={<GamificationLayout />}>
      <Route index element={<GamificationView />} />
      <Route path="creacion" element={<GamificationCreate />} />
      <Route path="editar/:id" element={<GamificationCreate />} />
    </Route>
  </Routes>
);

export default Gamification;
