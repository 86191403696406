import React from "react";
import { Routes, Route, useMatch, useLocation, Outlet } from "react-router-dom";

// Layout
import useLayouts from "../../layouts";

// Views
import View from "./View";
import Create from "./Create";

// Controllers
import useControllers from "../../../controllers";

const NewsLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const location = useLocation();

  const { useScreenHooks } = useControllers();
  const { useNewsScreens } = useScreenHooks();
  const { handleBack, headerAux } = useNewsScreens();

  const match = useMatch("/noticias/*");
  const basePath = match ? match.pathnameBase : "/noticias";
  const isExact = basePath === location.pathname;

  const breadcrumbsList = location.pathname.split("/").length > 2
    ? headerAux[Object.keys(headerAux).filter((header) => location.pathname.includes(header))[1]]
    : headerAux[Object.keys(headerAux).filter((header) => location.pathname.includes(header))];

  return (
    <PrivateContentLayout
      breadcrumbsList={breadcrumbsList}
      handleBack={handleBack}
      showBack={!isExact}
      title="Noticias"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const News = () => (
  <Routes>
    <Route path="/" element={<NewsLayout />}>
      <Route index element={<View />} />
      <Route path="crear" element={<Create />} />
      <Route path="editar/:newId" element={<Create />} />
    </Route>
  </Routes>
);

export default News;
