import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useDasboardServices = () => {
  const { useDashboardProvider } = useProviders();
  const { getDasboardProvider, getInfoMembeerProvider } = useDashboardProvider();

  const getDashboardService = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getDasboardProvider(params)));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const getInfoMembeerService = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getInfoMembeerProvider(params)));
      } catch (error) {
        reject(error);
      }
    });
  };
  return { getDashboardService, getInfoMembeerService };
};

export default useDasboardServices;
