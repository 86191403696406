// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import toolCreateAI from "../../../../assets/images/survey-ai.jpg";
import toolCreateManual from "../../../../assets/images/survey-manual.jpg";
import iconCloseCircle from "../../../../assets/images/icon-close-circle.svg";

// Styles
import {
  StyledSurveyCreationImg,
  StyledSurveyCreationContainer,
  StyledSurveyCreationTitle,
  StyledSurveyCreationDescription,
  StyledSurveyModalContainer,
  StyledSurveyModalClose,
  StyledSurveyModalContent,
  StyledModalTitle,
  StyledModalDescription,
} from "./CreateTools.styles";

const ToolsCreate = ({}) => {
  // Components
  const {
    CardWrapper,
    ContentWrappers,
    Typography,
    TextFieldControlled,
    SelectFieldControlled,
    CheckboxControlled,
    useButtons,
    useModals,
    InputSwitchControlled,
    TextAreaFieldControlled,
    AutocompleteFieldControlled,
  } = useComponents();

  const { DefaultButton } = useButtons();
  const { ModalCustom } = useModals();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useToolsCreate } = useTools();

  const {
    control,
    handleCloseModal,
    handleChooseSurveyType,
    handleSubmit,
    onSubmit,
    id_tool,
    listLenguajes,
    nameMission,
    showModal,
    toolsList,
    typeOfSurvey,
    watchTypeTool,
    watchUseTemplate,
  } = useToolsCreate();

  return (
    <>
      <ContentWrappers
        gridColums={["1fr"]}
        gap={{
          row: "0px",
          column: "20px",
        }}
        contentWidth="892px"
        as="section"
      >
        <CardWrapper>
          <Typography variant="h3">1. CONFIGURACIÓN GENERAL DE HERRAMIENTA</Typography>
          <form
            className="mt-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <ContentWrappers
              gridColums={["48%", "48%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <SelectFieldControlled
                label="Tipo de herramienta"
                helperText="Debes seleccionar un tipo de herramienta"
                name="typeTool"
                id="typeTool"
                disabled={id_tool ? true : false}
                control={control}
                options={[
                  {
                    value: "Survey",
                    label: "Encuesta",
                  },
                  {
                    value: "PassiveTracking",
                    label: "Passive Tracking",
                  },
                  {
                    value: "VideoCall",
                    label: "Video llamada",
                  },
                  {
                    value: "Referral",
                    label: "Referidos",
                  },
                ]}
              />
              <div className="flex items-center">
                {/* <CheckboxControlled
                  label="Requiere validación"
                  name="validation"
                  id="validation"
                  control={control}
                /> */}
                {watchTypeTool === "Survey" && (
                  <CheckboxControlled
                    label="Publicar"
                    name="publish"
                    id="publish"
                    control={control}
                  />
                )}
                {watchTypeTool === "PassiveTracking" && (
                  <CheckboxControlled
                    label="Solicitar foto y video por paso"
                    name="media"
                    id="media"
                    control={control}
                  />
                )}
              </div>
            </ContentWrappers>

            {
              (
                <>
                  {watchTypeTool === "Survey" && (
                    <div className="mb-4">
                      <InputSwitchControlled
                        label="Deseas usar una plantilla?"
                        name="use_template"
                        id="use_template"
                        control={control}
                      />
                    </div>
                  )}
                  {watchUseTemplate && (
                    <AutocompleteFieldControlled
                      label="Plantilla a usar"
                      placeholder="Busca por el nombre de la plantilla que quieres usar"
                      name="template_id"
                      id="template_id"
                      control={control}
                      options={toolsList}
                    />
                  )}

                  <TextFieldControlled
                    label="Nombre de la herramienta"
                    tooltipTitle="Nombre de la herramienta"
                    placeholder="Nombre"
                    helperText="Debes especificar un nombre"
                    name="name"
                    id="name"
                    control={control}
                  />
                  <ContentWrappers gridColums={["48%"]}>
                    <SelectFieldControlled
                      label="Idioma"
                      helperText="Debes seleccionar un idioma"
                      name="language"
                      id="language"
                      options={listLenguajes}
                      control={control}
                    />
                  </ContentWrappers>

                  <TextAreaFieldControlled
                    label="Descripción"
                    placeholder="Describe brevemente la herramienta"
                    name="description"
                    id="description"
                    control={control}
                  />

                  {watchTypeTool === "Survey" && !watchUseTemplate &&  (
                    <div className="mb-4 space-y-2">
                      <Typography variant="h3" className={"uppercase"}>
                        2. Plantilla
                      </Typography>
                        <InputSwitchControlled
                          label="Marcar herramienta como plantilla"
                          name="is_template"
                          id="is_template"
                          control={control}
                        />
                    </div>
                  )}

                  {nameMission && (
                    <div>
                      <Typography variant="h3">
                        {watchTypeTool === "Survey" ? 3 : 2}. MISIÓN VINCULADA
                      </Typography>
                      <ContentWrappers gridColums={["48%"]} className="mt-6">
                        <Typography variant="caption">{nameMission}</Typography>
                      </ContentWrappers>
                    </div>
                  )}

                  <div className="justify-end flex">
                    <DefaultButton color="secondary" type="submit">
                      Siguiente
                    </DefaultButton>
                  </div>
                </>
              )
            }
          </form>
        </CardWrapper>
      </ContentWrappers>

      <ModalCustom isOpen={showModal} padding="1.5rem">
        <StyledSurveyModalContainer>
          <div className="justify-end flex">
            <StyledSurveyModalClose
              alt="cerrar"
              onClick={handleCloseModal}
              src={iconCloseCircle}
            />
          </div>

          <StyledSurveyModalContent>
            <StyledModalTitle>
              ¿Cómo quieres generar la encuesta?
            </StyledModalTitle>
            <StyledModalDescription>
              Selecciona uno de los métodos que tenemos para generar la encuesta que necesitas
            </StyledModalDescription>
            <ContentWrappers
              gridColums={["46%", "46%"]}
              gap={{
                row: "0px",
                column: "8%",
              }}
            >
              <CardWrapper padding="0">
                <StyledSurveyCreationImg src={toolCreateManual} alt="create manual" />
                <StyledSurveyCreationContainer>
                  <StyledSurveyCreationTitle>
                    Hazlo tú mismo
                  </StyledSurveyCreationTitle>
                  <StyledSurveyCreationDescription>
                    Crea manualmente tu encuesta.
                    </StyledSurveyCreationDescription>
                  <div className="my-1 flex justify-center">
                    <DefaultButton
                      color="primary"
                      onClick={() => handleChooseSurveyType("manual")}
                      rounded
                    >
                      Crear encuesta
                    </DefaultButton>
                  </div>
                </StyledSurveyCreationContainer>
              </CardWrapper>

              <CardWrapper padding="0">
                <StyledSurveyCreationImg src={toolCreateAI} alt="create AI" />
                <StyledSurveyCreationContainer>
                  <StyledSurveyCreationTitle>
                    Inteligencia Artificial
                  </StyledSurveyCreationTitle>
                  <StyledSurveyCreationDescription>
                    Crea tu encuesta con IA.
                  </StyledSurveyCreationDescription>
                  <div className="my-1 flex justify-center">
                    <DefaultButton
                      color="primary"
                      onClick={() => handleChooseSurveyType("ai")}
                      rounded
                    >
                      Crear encuesta
                    </DefaultButton>
                  </div>
                </StyledSurveyCreationContainer>
              </CardWrapper>
            </ContentWrappers>
          </StyledSurveyModalContent>
        </StyledSurveyModalContainer>
      </ModalCustom>
    </>
  );
};

export default ToolsCreate;
