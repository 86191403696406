// Packages
import { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import useHelpers from "../../../../helpers";

// Controllers
import useControllers from "../../..";

// Actions
import useApi from "../../../../api";

const useNotificationCreate = () => {
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [listCountries, setListCountries] = useState([]);
  const [listGroups, setListGroups] = useState([]);
  const [listEntities, setListEntities] = useState([]);
  const [listEntitiesById, setListEntitiesById] = useState([]);
  const [entitySelected, setEntitySelected] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  
  const navigation = useNavigate();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useGeneral } = useQuickFunctions();
  const { newNotificationValidationShema } = useValidators();

  // Controllers HU66 Como Super Admin, quiero Formulario para así crear notificación.
  const { dateFormatUTC } = useGeneral();
  const { id } = useParams();

  // Actions
  const { useActions } = useApi();
  const { useNotificationsActions, useGeneralActions } = useActions();
  const {
    actGetNotification,
    actAddEditNotification,
    actGetMembeerGroups,
    actGetNotificationsEntities,
    actGetNotificationsEntitiesById,
  } = useNotificationsActions();

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(newNotificationValidationShema),
    defaultValues: {
      reminders: [{ date: "", time: "", ampm: "am" }],
      reminder: false,
      reminder_push: false,
      reminder_email: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "reminders",
  });

  const loadData = async (data) => {
    setValue("name", data.name);
    setValue("reminder", data.reminders && data.reminders.length > 0 ? true : false);
    setValue("publicDate", data.send_on);
    setValue("publicTime", data.send_on);
    setValue("publicAmpm", data.send_on);
    setValue(
      "publicAmpm",
      data.send_on ? (new Date(data.send_on).getHours() >= 12 ? "pm" : "am") : ""
    );
    setValue("message_push", data.push_message);
    setValue("reminder_push", data.should_send_push);
    setValue("reminder_email", data.should_send_email);
    setValue("message_email", data.email_body);
    setValue("header_image", `${process.env.REACT_APP_AI_GENERATION_URL}${data.email_header_image?.replace(/^\//, '')}`);
    setValue("group", data.membeer_group);
    setValue("entity", data.push_entity_type);
    setValue("entity_id", data.push_entity_id);
    setValue("email_subject", data.email_subject);
    if (data.membeer_id) setValue("membeer_id", data.membeer_id);
    setEntitySelected(data.push_entity_id);

    // //LOAD DATA IN LISTADO IF ENTITY IS SELECTED (EDITION)
    if (data.push_entity_type)
      actGetNotificationsEntitiesById(data.push_entity_type, loadDataEntities);

    if (data.reminders && data.reminders.length > 0) {
      remove();
      setValue(
        "reminders",
        data.reminders.map((x) => {
          return {
            id: x.id,
            date: x.send_on ? x.send_on : "",
            time: x.send_on ? dateFormatUTC([x.send_on, x.send_on]) : "",
            ampm: x.send_on ? (new Date(x.send_on).getHours() >= 12 ? "pm" : "am") : "",
          };
        })
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    resetData();
  };

  const resetData = () => {
    reset();
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleOnCreateSuccess = () => {
    handleShowModal();
    setTimeout(() => {
      navigation(-1);
    }, 2000);    
  }

  const onSubmit = (data) => {
    setSaving(true);
    let reminder = [];
    if (data.reminders && data.reminders.length > 0 && data.reminders[0].date != "") {
      reminder = data.reminders.map((x) =>
        dateFormatUTC([new Date(x.date), new Date(x.time), x.ampm])
      );
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("push_message", data.message_push);
    formData.append("email_body", data.message_email);
    formData.append("push_entity_type", data.entity);
    formData.append("push_entity_id", data.entity_id?.value);
    formData.append("email_subject", data.email_subject ? data.email_subject : "");
    formData.append("send_on", dateFormatUTC([data.publicDate, data.publicTime, data.publicAmpm]));
    formData.append("should_send_email", data.reminder_email === true ? 1 : 0);
    formData.append("should_send_push", data.reminder_push === true ? 1 : 0);
    formData.append("membeer_group_name", selectedGroupName);
    formData.append("membeer_group", id ? data.group : JSON.stringify([Number(data.group)]));
    if (reminder.length > 0) {
      formData.append("reminders", JSON.stringify(reminder));
    }
    if (!id) {
      formData.append("membeers", null);
    }
    if (getValues("membeer_id")) {
      formData.append("membeer_id", getValues("membeer_id"));
    }
    if (data?.header_image?.length > 0 && typeof data?.header_image !== 'string') {
      formData.append("email_header_image", data.header_image[0]);
    }

    actAddEditNotification({ id, datos: formData, reminders: reminder }, handleOnCreateSuccess);
  };

  const getReminderType = (push, email) => {
    if (push && email) return ["Push", "Email"];
    if (push) return "Push";
    if (email) return "Email";
    return "";
  };

  const handleEntitiesSelectChange = (data) => {
    if (data.target.value !== "Announcement")
      actGetNotificationsEntitiesById(data.target.value, loadDataEntities);
    else setListEntitiesById([]);
  };

  const handleSelectGroup = (data) => {
    setSelectedGroupName(data);
  };

  const loadDataEntities = (data) => {
    setListEntitiesById(
      data.map((x) => {
        return { label: x.name, value: x.id };
      })
    );
  };

  //SET SELECTED VALUE IN LISTADO AFTER LOAD DATA IN THE LIST
  useEffect(() => {
    if (entitySelected) {
      const entity = listEntitiesById.filter((x) => x.value === entitySelected)[0];
      if (entity) setValue("entity_id", entity);
    }
  }, [listEntitiesById]);

  useEffect(() => {
    actGetMembeerGroups((data) => {
      setListGroups(
        data.map((x) => {
          return { label: x.name, value: x.id };
        })
      );
    });
    actGetNotificationsEntities((data) => {
      setListEntities(
        data.map((x) => {
          return { label: x.name, value: x.entity };
        })
      );
    });
    if (id) actGetNotification(id, loadData);
  }, []);

  return {
    id,
    control,
    handleSubmit,
    onSubmit,
    showModal,
    saving,
    handleCloseModal,
    listCountries,
    fields,
    append,
    remove,
    listGroups,
    listEntities,
    listEntitiesById,
    handleEntitiesSelectChange,
    handleSelectGroup,
  };
};

export default useNotificationCreate;
