import {useState} from "react";

const useFilterButton = () => {
    const [isOpen, setIsOpen] = useState(false)

    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    return {
        isOpen,
        handleClose,
    }
}

export default useFilterButton