const useUsersTypes = () =>{
  const ADD_USER = 'ADD_USER';
  const REMOVE_USER = 'REMOVE_USER';

  return {
    ADD_USER,
    REMOVE_USER,
  }
};

export default useUsersTypes;
