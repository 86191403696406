// Packages
import React from "react";
import PropTypes from "prop-types";

import useIcons from "../../../assets/icons";

import {
  StyledInput,
  StytledWarpperControl,
  StyledIndicator,
  StyledSummary,
  StyledBadgeCheck,
  StyledListSummary,
} from "./CheckboxGroupControlled.styles";

import { StyledHelperText } from "../SelectField/SelectField.styles";

import useControllers from "../../../controllers";

const CheckboxGroupControlled = (props) => {
  const {
    options,
    control,
    name,
    color,
    isSelectedAll,
    showSummary,
    className,
    defaultValue,
    selectAllText,
  } = props;

  const { useComponentHooks } = useControllers();
  const { useCheckBoxGroupControlled } = useComponentHooks();

  const { checked, referenceCollapsed, value, ref, error, auxOnChange, handleRemoveItem } =
    useCheckBoxGroupControlled(control, name, defaultValue);

  const { IconPlus, IconArrowDown } = useIcons();

  return (
    <div className="relative mb-7">
      {showSummary && (
        <StyledSummary>
          <StyledListSummary>
            {_.map(value, (item, index) => {
              const data = options.find((element) => element.value === item);
              return (
                <StyledBadgeCheck key={`badge-${index}`}>
                  {data.label}
                  <button
                    onClick={(e) => {
                      handleRemoveItem(e, item);
                    }}
                    className="ml-2"
                  >
                    <IconPlus color="#363635" />
                  </button>
                </StyledBadgeCheck>
              );
            })}
          </StyledListSummary>
          <button onClick={() => {}} className="ml-2">
            <IconArrowDown />
          </button>
        </StyledSummary>
      )}
      <div className={className} ref={referenceCollapsed}>
        {_.map(
          isSelectedAll && options !== undefined
            ? [
                { label: `${selectAllText ? selectAllText : "Seleccionar Todos"}`, value: "all" },
                ...options,
              ]
            : options,
          (item, index) => {
            return (
              <StytledWarpperControl key={`abi-controller-${index}`}>
                <StyledInput
                  type="checkbox"
                  key={`kei${index}`}
                  color={color}
                  ref={ref}
                  onChange={(e) => auxOnChange(e, item)}
                  checked={
                    item.value === "all"
                      ? checked || ""
                      : value !== undefined && value.includes(item.value)
                  }
                />
                <StyledIndicator color={color} />
                <label className="text-sm text-neutral font-light">{item.label}</label>
              </StytledWarpperControl>
            );
          }
        )}
      </div>
      {error && <StyledHelperText>{error?.message}</StyledHelperText>}
    </div>
  );
};

CheckboxGroupControlled.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "error", "success"]),
  isSelectedAll: PropTypes.bool,
};

CheckboxGroupControlled.defaultProps = {
  disabled: false,
  color: "primary",
  isSelectedAll: false,
};

export default CheckboxGroupControlled;
