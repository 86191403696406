// Packages
import React from "react";

// Styles
import { StyledContentButtons } from "./Main.styles";

// Components
// Components
import useLayouts from "../../../layouts";
import useComponents from "../../../components";
import PromptForm from "../Create";

// Controllers
import useControllers from "../../../../controllers";

// Helpers
import useHelpers from "../../../../helpers";

const PromptsMain = () => {
  // Components
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    Typography,
    Badge,
    Pagination,
    SelectFieldControlled,
    useModals,
    Dropdown,
  } = useComponents();

  const { PrivateContentLayout } = useLayouts();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { usePrompt } = useScreenHooks();
  const { usePromptsMain } = usePrompt();
  const {
    control,
    handleSubmit,
    onSubmit,
    promptsList,
    paginationSettings,
    handlePagination,
    handleOpenDeletePrompt,
    modalDelete,
    handleConfirmDelete,
    handleOpenUpdatePrompt,
    formModal,
    onSubmitPrompt,
    currentPrompt,
    handleCloseModalDelete,
    handleOpenCreatePrompt,
    promptFormControl,
    promptSubmitForm,
    handleCloseFormModal,
    promptCategories,
    shouldAddCategory,
    handleShouldAddCategory,
    categoryControl,
    handleSubmitCategory,
    onSubmitCategory,
  } = usePromptsMain();

  return (
    <PrivateContentLayout>
      <ContentWrappers gridColums={["1fr"]} contentWidth="90%" as="section">
        <div className="px-8 pt-10 pb-12 bg-white rounded-lg">
          <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextFieldSeach placeholder="Buscar" name="type" id="type" control={control} />
            </form>
            <StyledContentButtons>
              <FilterButton handleSubmit={handleSubmit(onSubmit)}>
                <SelectFieldControlled
                  placeholder={"Categoria"}
                  name={`type`}
                  id={`type`}
                  control={control}
                  options={promptCategories}
                />
              </FilterButton>
              <DefaultButton
                color="secondary"
                onClick={() => handleOpenCreatePrompt()}
              >
                Crear prompt
              </DefaultButton>
            </StyledContentButtons>
          </ContentWrappers>
          <TableContent
            headerCellList={[
              "Nombre del prompt",
              "categoria",
              "Acciones",
            ]}
            gridCell={["70%","20%", "10%"]}
          >
            {promptsList?.map((prompt, index) => {
              return (
                <TableRow key={index}>
                  <div>
                    {" "}
                    <strong>
                      {prompt?.name}
                    </strong>
                  </div>
                  <div>
                    {
                      prompt?.categories?.map(category => (
                        <Badge variant={"gray"} label={category}/>
                      ))
                    }
                  </div>
                  <div className="flex justify-end">
                    <Dropdown
                      options={[
                        { id: "edit",action: () => handleOpenUpdatePrompt(prompt) },
                        { id: "delete", action: () => handleOpenDeletePrompt(prompt) },
                      ]}
                    />
                  </div>
                </TableRow>
              );
            })}
          </TableContent>
        </div>
        <div>
          <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
        </div>
      </ContentWrappers>
      <DefaultModal isOpen={modalDelete}>
        <div className="flex flex-col items-center space-y-8">
          <Typography variant="body2" className="text-center">
            Esta seguro de eliminar el prompt <b>{currentPrompt?.name}</b>.
          </Typography>
          <div className="flex items-center space-x-4">
            <DefaultButton color="secondary" onClick={handleCloseModalDelete}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="success" onClick={handleConfirmDelete}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
      <DefaultModal type={"info"} isOpen={formModal}>
        <PromptForm 
          currentPrompt={currentPrompt}
          promptCategories={promptCategories}
          promptFormControl={promptFormControl}
          promptSubmitForm={promptSubmitForm}
          handleCloseFormModal={handleCloseFormModal}
          onSubmitPrompt={onSubmitPrompt}
          shouldAddCategory={shouldAddCategory}
          handleShouldAddCategory={handleShouldAddCategory}
          categoryControl={categoryControl}
          handleSubmitCategory={handleSubmitCategory}
          onSubmitCategory={onSubmitCategory}
          />
      </DefaultModal>
    </ PrivateContentLayout>
  );
};

export default PromptsMain;
