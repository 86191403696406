const IconDots = ({ color = "#597393", className = "" }) => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.5 8.25C5.5 8.94036 4.94036 9.5 4.25 9.5C3.55964 9.5 3 8.94036 3 8.25C3 7.55964 3.55964 7 4.25 7C4.94036 7 5.5 7.55964 5.5 8.25ZM9.5 8.25C9.5 8.94036 8.94036 9.5 8.25 9.5C7.55964 9.5 7 8.94036 7 8.25C7 7.55964 7.55964 7 8.25 7C8.94036 7 9.5 7.55964 9.5 8.25ZM12.25 9.5C12.9404 9.5 13.5 8.94036 13.5 8.25C13.5 7.55964 12.9404 7 12.25 7C11.5596 7 11 7.55964 11 8.25C11 8.94036 11.5596 9.5 12.25 9.5Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.5 14.25C5.5 14.9404 4.94036 15.5 4.25 15.5C3.55964 15.5 3 14.9404 3 14.25C3 13.5596 3.55964 13 4.25 13C4.94036 13 5.5 13.5596 5.5 14.25ZM9.5 14.25C9.5 14.9404 8.94036 15.5 8.25 15.5C7.55964 15.5 7 14.9404 7 14.25C7 13.5596 7.55964 13 8.25 13C8.94036 13 9.5 13.5596 9.5 14.25ZM12.25 15.5C12.9404 15.5 13.5 14.9404 13.5 14.25C13.5 13.5596 12.9404 13 12.25 13C11.5596 13 11 13.5596 11 14.25C11 14.9404 11.5596 15.5 12.25 15.5Z" fill={color}/>
    </svg>
  );
};

export default IconDots;
