// Packages
import { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

// Helpers
import useHelpers from '../../../../../helpers';

// Actions
import useActions from '../../../../../api/actions';

import useControllers from "../../../../";

const useMembeerReport = () => {
  const { id_passive_tracking, id_membeer } = useParams();

  const [membeerInfo, setMembeerInfo] = useState({
    name: "",
    country: "",
    id: ""
  });

  const [stepsList, setStepsList] = useState([]);
  
  const [infoPassiveTracking, setInfoPassiveTracking] = useState({
    steps: [],
    name: "",
    mission: []
  });

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation } = useQuickFunctions();
  const { filtersValidationShema } = useValidators();
  const { navigateTo } = useNavigation();
  
  // Actions
  const { dispatch, useToolsActions, useGeneralActions } = useActions();
  const { actGetPassiveTrackingAnswers, actGetPassiveTrackingSteps } = useToolsActions();
  const { actExportExel } = useGeneralActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  useEffect(() => {
    const onSuccess = ({data}) => {
      if(data.length > 0) {
        const { name, lastName, país, id, steps } = data[0];

        setMembeerInfo({
          name: `${name} ${lastName}`,
          country: país,
          id: id 
        })
        setStepsList(steps)
      }else {
        navigateTo("/herramientas")
      }
    }

    const onError = (error) => {
      console.log(error);
    }

    dispatch(actGetPassiveTrackingAnswers({membeer_id: id_membeer}, onSuccess, onError ));

    // PassiveTracking Info
    const onSuccessPassiveTracking = (data) => {
      const { steps, mission } = data;
      setInfoPassiveTracking({
        steps,
        mission,
      })
    }

    const onErrorPassiveTracking = (error) => {
      console.log(error)
    }
    dispatch(actGetPassiveTrackingSteps(id_passive_tracking, onSuccessPassiveTracking, onErrorPassiveTracking))
  }, [id_membeer, id_passive_tracking]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(filtersValidationShema),
  });

  const onSubmit = useCallback(
    (data) => {
      //console.log (data)
    },
    []
  )

  const handleDownloadExel = () => {
    const filters = {
      passive_tracking_id: id_passive_tracking,
      membeer_id: id_membeer,
    };

    const filtersProcces = prepareDataFilterDownload(filters);
    actExportExel("membeerPassiveTracking", filtersProcces, (result) => {
      createDownloadExel(result);
    })
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    membeerInfo,
    stepsList,
    infoPassiveTracking,
    handleDownloadExel,
  }
}

export default useMembeerReport;