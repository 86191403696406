// Packages
import { useState, useEffect } from "react";

// Actions
import useActions from "../../../api/actions";

// Models
import useModels from "../../../models";

// Helpers
import useHelpers from "../../../helpers";

const useHeader = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [messages, setMessages] = useState([]);

  // Actions
  const { dispatch, useAuthActions, useGeneralActions } = useActions();
  const { actLogout } = useAuthActions();
  const { actGetNotification } = useGeneralActions();

  // Models
  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { replaceAndNavigateTo } = useNavigation();

  const { authToken } = useSelector(session);

  const handleShowOptions = () => setShowOptions(!showOptions);

  const sessionInfo = useSelector(session);

  const handleLogin = (data) => {
    replaceAndNavigateTo("/");
  };

  const handleOpenLogout = () => {
    setOpenLogout(true);
  };
  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  const handleLogout = () => {
    dispatch(actLogout(authToken, handleLogin));
  };

  useEffect(()=>{
    const onSuccess = data => {
      setMessages(data)
    }
    actGetNotification(onSuccess)
  }, [])

  return {
    sessionInfo,
    handleShowOptions,
    showOptions,
    handleLogout,
    openLogout,
    handleOpenLogout,
    handleCloseLogout,
    setShowNotify,
    showNotify,
    messages,
  };
};

export default useHeader;
