const Numbers = ({ border }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="40"
      fill="none"
      viewBox="0 0 200 40"
      className={`h-24 px-2 ${border && border} rounded-lg`}
    >
      <path fill="#E5B611" d="M.5 4A3.5 3.5 0 014 .5h35.5v39H4A3.5 3.5 0 01.5 36V4z"></path>
      <path
        fill="#424242"
        d="M19.997 23.51c0 .308.252.56.546.56a.55.55 0 00.546-.56v-8.876a.538.538 0 00-.546-.546h-.028c-.182 0-.378.056-.602.126l-1.61.56c-.238.084-.392.238-.392.476 0 .266.224.476.476.476.07 0 .154-.014.238-.042l1.372-.448v8.274z"
      ></path>
      <path stroke="#E5B611" d="M.5 4A3.5 3.5 0 014 .5h35.5v39H4A3.5 3.5 0 01.5 36V4z"></path>
      <path fill="#F4F4F4" d="M40.5 0.5H79.5V39.5H40.5z"></path>
      <path
        fill="#424242"
        d="M56.426 23.482c0 .308.238.518.56.518h5.894c.28 0 .518-.224.518-.504a.514.514 0 00-.518-.504h-4.746l2.618-2.324c1.722-1.498 2.478-2.38 2.478-3.808v-.028c0-1.596-1.274-2.772-3.038-2.772-1.61 0-2.506.672-3.318 1.806a.49.49 0 00-.112.308c0 .28.224.504.504.504a.497.497 0 00.406-.196c.7-.924 1.4-1.428 2.436-1.428 1.064 0 1.974.728 1.974 1.876 0 .98-.532 1.736-2.044 3.08l-3.346 2.954c-.182.154-.266.308-.266.518z"
      ></path>
      <path stroke="#F4F4F4" d="M40.5 0.5H79.5V39.5H40.5z"></path>
      <path fill="#F4F4F4" d="M80.5 0.5H119.5V39.5H80.5z"></path>
      <path
        fill="#424242"
        d="M100.061 24.168c1.82 0 3.276-1.204 3.276-2.954v-.028c0-1.792-1.526-2.618-3.234-2.758l2.884-3.206c.154-.168.266-.322.266-.546 0-.28-.224-.476-.532-.476h-5.348a.514.514 0 00-.518.504c0 .28.238.49.518.49h4.34l-2.856 3.234c-.154.154-.224.294-.224.434 0 .266.224.476.49.476h.35c1.61 0 2.744.672 2.744 1.89v.028c0 1.148-.952 1.904-2.142 1.904-1.106 0-1.946-.434-2.66-1.246a.576.576 0 00-.406-.168.556.556 0 00-.546.546c0 .154.098.308.182.392.784.882 1.918 1.484 3.416 1.484z"
      ></path>
      <path stroke="#F4F4F4" d="M80.5 0.5H119.5V39.5H80.5z"></path>
      <path fill="#F4F4F4" d="M120.5 0.5H159.5V39.5H120.5z"></path>
      <path
        fill="#424242"
        d="M141.352 23.538c0 .294.238.532.532.532a.535.535 0 00.546-.532v-1.862h1.106c.252 0 .476-.196.476-.448a.481.481 0 00-.476-.476h-1.106v-6.118a.571.571 0 00-.588-.574c-.294 0-.448.126-.616.336l-5.096 6.216c-.14.182-.21.336-.21.532 0 .308.238.532.56.532h4.872v1.862zm-4.116-2.786l4.116-5.054v5.054h-4.116z"
      ></path>
      <path stroke="#F4F4F4" d="M120.5 0.5H159.5V39.5H120.5z"></path>
      <path
        fill="#F4F4F4"
        d="M160.5.5H196a3.5 3.5 0 013.5 3.5v32a3.5 3.5 0 01-3.5 3.5h-35.5V.5z"
      ></path>
      <path
        fill="#424242"
        d="M179.97 24.168c2.03 0 3.486-1.33 3.486-3.22v-.028c0-1.876-1.484-2.996-3.388-2.996-.812 0-1.358.182-1.932.434l.21-3.136h4.228c.28 0 .504-.224.504-.504a.505.505 0 00-.504-.518h-4.704c-.28 0-.518.196-.532.532l-.224 3.836c-.014.252.056.476.238.602.196.126.35.21.56.21.252 0 .84-.476 1.988-.476 1.428 0 2.436.826 2.436 2.058v.028c0 1.274-.98 2.17-2.352 2.17-.91 0-1.75-.406-2.52-1.106a.597.597 0 00-.378-.14.538.538 0 00-.532.518c0 .196.07.336.224.462.798.714 1.918 1.274 3.192 1.274z"
      ></path>
      <path
        stroke="#F4F4F4"
        d="M160.5.5H196a3.5 3.5 0 013.5 3.5v32a3.5 3.5 0 01-3.5 3.5h-35.5V.5z"
      ></path>
    </svg>
  );
};

export default Numbers;
