// Packages
import React from "react";

// Components
import useComponents from "../../../../components";

// Controlled
import useControllers from "../../../../../controllers";

const VideoCallCreate = () => {
  // Components
  const {
    ContentWrappers,
    CardWrapper,
    Typography,
    TextFieldControlled,
    useButtons,
    useModals,
    InputFileControlled,
  } = useComponents();
  const { DefaultButton } = useButtons();
  const { DefaultModal } = useModals();

  // Controlled
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useVideoCall } = useTools();
  const { useVideoCallCreate } = useVideoCall();
  const { control, onSubmit, handleSubmit, showModal, handleCloseModal, nameRoom } =
    useVideoCallCreate();

  return (
    <ContentWrappers gridColums={["1fr"]} contentWidth="892px" as="section">
      <CardWrapper padding="2rem">
        <Typography variant="h3">1. CONFIGURACIÓN DE LA SALA</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContentWrappers
            className="mt-6"
            gridColums={["1fr", "1fr"]}
            gap={{
              row: "0px",
              column: "32px",
            }}
          >
            <TextFieldControlled
              label="Nombre de la sala"
              control={control}
              id="name"
              placeholder=""
              name="name"
            />
            <TextFieldControlled
              type="number"
              label="Número máximo de participates"
              control={control}
              placeholder=""
              id="max_user"
              name="max_user"
            />
          </ContentWrappers>
          {/* <InputFileControlled
            label="Agregar fondo videollamada (jpg, png, webp)"
            id="file"
            name="file"
            control={control}
            placeholder=""
            showPreview
          /> */}
          <div className="flex justify-center mt-4">
            <DefaultButton type="submit" color="secondary">
              Finalizar y publicar
            </DefaultButton>
          </div>
        </form>
      </CardWrapper>
      <DefaultModal
        isOpen={showModal}
        handledButton={handleCloseModal}
        children={
          <div>
            La video llamada <strong>{nameRoom}</strong> se <br /> ha creado con éxito.
          </div>
        }
      />
    </ContentWrappers>
  );
};

export default VideoCallCreate;
