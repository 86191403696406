import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useProjectsServices = () => {
  const { useProjectsProvider } = useProviders();
  const {
    listProjectsProvider,
    createProjectProvider,
    getProjectProvider,
    editProjectProvider,
    listProjectsMissionProvider,
    deleteProjectProvider,
  } = useProjectsProvider();

  const createProjectsService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createProjectProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const editProjectsService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editProjectProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };
  const listProjectsService = ({
    page,
    init_date,
    end_date,
    status,
    country_id,
    client_id,
    search,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            listProjectsProvider({
              page,
              init_date,
              end_date,
              status,
              country_id,
              client_id,
              search,
            })
          )
        );
      } catch (error) {
        reject(error);
      }
    });
  };
  const listProjectsMissionService = ({
    page,
    init_date,
    end_date,
    status,
    country_id,
    client_id,
    search,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            listProjectsMissionProvider({
              page,
              init_date,
              end_date,
              status,
              country_id,
              client_id,
              search,
            })
          )
        );
      } catch (error) {
        reject(error);
      }
    });
  };
  const getProjectsService = (projectId) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getProjectProvider(projectId)));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const deleteProjectService = (projectId) => {
    console.group(projectId)
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteProjectProvider(projectId)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    createProjectsService,
    listProjectsService,
    getProjectsService,
    editProjectsService,
    listProjectsMissionService,
    deleteProjectService,
  };
};

export default useProjectsServices;
