import React from 'react';
import PropTypes from 'prop-types';

// Components
import useComponents from '../';

// Styles
import { StyledTextField } from './TextField.styles';

const TextField = ({
  id,
  label,
  type = 'text',
  error,
  helperText,
  disabled,
  tooltipTitle,
  required,
  ...rest
}) => {
  const { FormLabel } = useComponents();

  return (
    <StyledTextField
      fullWidth={true}
      id={id}
      disabled={disabled}
      label={
        <FormLabel 
          label={label} 
          tooltipTitle={tooltipTitle}
        />
      }
      required={required}
      type={type}
      variant="standard"
      autoComplete="off"
      error={error ? true : false}
      helperText={error ? error?.message : helperText}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  tooltipTitle: PropTypes.string,
  required: PropTypes.bool,
};

export default TextField;
