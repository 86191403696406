import axios from "axios";

const useGeneralProvider = () => {
  const listCountriesProvider = () => {
    return axios({
      method: "get",
      url: `countries`,
    });
  };

  const listCountriesAllProvider = ({ search }) => {
    return axios({
      method: "post",
      url: `countries-all`,
      data: { search },
    });
  };

  const listCitiesProvider = (id, data) => {
    return axios({
      method: "get",
      url: `v1/cities`,
    });
  };

  const listCitiesByStateProvider = (id) => {
    const states = [...id];
    return axios({
      method: "post",
      url: `cities`,
      data: { states },
    });
  };

  const listRegionsProvider = () => {
    return axios({
      method: "get",
      url: `profiling-regions-list`,
    });
  };

  const listAbiRegionsProvider = () => {
    return axios({
      method: "get",
      url: `abi-regions`,
    });
  };

  const listAuxAbiRegionsProvider = () => {
    return axios({
      method: "get",
      url: `aux-abi-regions`,
    });
  };

  const listStatesByCountryProvider = (id) => {
    return axios({
      method: "get",
      url: `states/${id}`,
    });
  };

  const listLenguajesProvider = () => {
    return axios({
      method: "get",
      url: `constants/languajes`,
    });
  };

  const exportExelProvider = (url, filters, params) => {
    const baseUrl =
      url === "notifications"
        ? `https://api-ab-stage.membeers.com/core/v1/notifications/export${
            filters ? `/${filters}` : ""
          }`
        : `download/${url}${filters ? `/${filters}` : ""}`;

    return axios.get(baseUrl, {
      responseType: "blob",
      params,
    });
  };

  const exportProfileExelProvider = (url, filters) => {
    return axios({
      method: "get",
      url: `download/${url}/${filters}`,
      responseType: "blob",
    });
  };

  const getNotificationProvider = () => {
    return axios({
      method: "get",
      url: `notification/payment`,
    });
  };

  return {
    listRegionsProvider,
    listAbiRegionsProvider,
    listAuxAbiRegionsProvider,
    listCountriesProvider,
    listCountriesAllProvider,
    listCitiesProvider,
    listLenguajesProvider,
    listCitiesByStateProvider,
    listStatesByCountryProvider,
    exportExelProvider,
    exportProfileExelProvider,
    getNotificationProvider,
  };
};

export default useGeneralProvider;
