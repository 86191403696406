import useAuthServices from "./auth";
import useToolsServices from "./tools";
import useQuestionsServices from "./questions";
import useUsersServices from "./users";
import useGeneralServices from "./general";
import useMissionServices from "./mission";
import useProjectsServices from "./projects";
import useCountruesServices from "./countries";
import useMapServices from "./map";
import useBrandsServices from "./brands";
import useNewsServices from "./news";
import useNotificationsServices from "./notifications";
import usePaymentsServices from "./payments";
import useFaqsServices from "./faqs";
import useGroupsServices from "./groups";
import useProfileServices from "./profiler";
import useDasboardServices from "./dashboard";
import useMembeersServices from "./membeers";
import usePowerBiServices from "./powerbi";
import useGamificationServices from "./gamifications";
import useLegalServices from "./legal";
import usePromptsServices from "./prompts";
import useNavBarServices from "./navbar";

const useServices = () => {
  return {
    useAuthServices,
    useToolsServices,
    useUsersServices,
    useGeneralServices,
    useMissionServices,
    useQuestionsServices,
    useProjectsServices,
    useCountruesServices,
    useMapServices,
    useBrandsServices,
    useNewsServices,
    useNotificationsServices,
    usePaymentsServices,
    useProfileServices,
    useFaqsServices,
    useGroupsServices,
    useDasboardServices,
    useMembeersServices,
    usePowerBiServices,
    useGamificationServices,
    useLegalServices,
    usePromptsServices,
    useNavBarServices,
  };
};

export default useServices;
