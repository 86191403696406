import React from "react";
import useComponents from "../../../../../components";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

import _ from "lodash";


import { StyledTableCell, StyledContainerPaper } from "../FollowQuotas/TableDash.styles";

const BudgestPayments = (props) => {
  const { dataBudgestPayments } = props;

  const {  useGrafic } = useComponents();
  const { Donut } = useGrafic();

  const styleSw = {
    top: "100%",
  };

  return (
    <div className="flex justify-between items-center flex-wrap"> 
      <StyledContainerPaper>
        <TableContainer component={Paper} style={{ background: "transparent" }}>
          <Table aria-label="customized table">
            <TableBody>
              <TableRow>
                <StyledTableCell width="120px" align="left">
                Saldo mes Anterior
                </StyledTableCell>
                <StyledTableCell>{dataBudgestPayments.general?.previousMonth}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell width="120px" align="left">
                Saldo mes Actual
                </StyledTableCell>
                <StyledTableCell>{dataBudgestPayments.general?.previousMonth}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell width="120px" align="left">
                Valor Pendiente de pago
                </StyledTableCell>
                <StyledTableCell>{dataBudgestPayments.general?.net_base.value}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell width="120px" align="left">
                Valor Pagado
                </StyledTableCell>
                <StyledTableCell>{dataBudgestPayments.general?.monthly_net_base.value}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContainerPaper>
      <Donut
        data={dataBudgestPayments.chart}
        title="Pagos"
        subTitle="Habilitados"
        titleValue={`${dataBudgestPayments.general?.enable_payments}`}
        legendAling="center"
        legendStyled={styleSw}
        className="w-5/12"
      />
    </div>
  );
};

export default BudgestPayments;
