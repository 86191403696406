// Packages
import React from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';

// Styles
import { StyledFormControl } from './TimePickerField.styles';
import { StyledTextField } from '../TextField/TextField.styles';

// Components
import FormLabel from "../FormLabel";

const TimePickerField = (props) => {
  const { label, tooltipTitle, error, disabled, ...rest } = props;

  return (
    <StyledFormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        inputFormat="hh:mm"
        ampmInClock={ false }
        label={ <FormLabel label={ label } tooltipTitle={ tooltipTitle } /> }
        disabled={disabled}
        renderInput={(params) => <StyledTextField {...params} variant="standard" error={ error } />}
        { ...rest }
      />
      </LocalizationProvider>
    </StyledFormControl>
  )
}

TimePickerField.propTypes = {
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  disabled: PropTypes.bool,
}

export default TimePickerField;