import useAuth from "../auth";

import useApi from "../../../api"

import useNavigation from "../navigation"

const useRouterHelper = () => {
  const { getSession } = useAuth();
  const { authToken } = getSession;
  const { navigateTo } = useNavigation();

  const {useActions} = useApi();
  const {useAuthActions} = useActions();
  const {actValidLoginPassword} = useAuthActions();

  const requireAuth = () => {
    if (!authToken || !actValidLoginPassword()) {
      return navigateTo("/")
    }
  };

  return {
    requireAuth
  }
};

export default useRouterHelper;