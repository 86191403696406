import React from "react";
import useScreenHooks from "../../../../../../controllers/screenHooks";
import useComponents from "../../../../../components";

import _ from "lodash";

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from "recharts";

const NewMembeers = (props) => {
  const { dataMemebers, onClick } = props;

  const { Typography } = useComponents();

  return (
    <>
      <Typography className="uppercase font-gotham" variant="h4">
        Grafica de nuevos usuarios por mes
      </Typography>
      <div className="relative mt-4" style={{ width: "100%", height: 250 }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart width={500} height={300} data={dataMemebers} barSize={10} onClick={(data)=>onClick(data)}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="label"
              style={{
                fontSize: "0.8rem",
                fontFamily: "Helvetica",
              }}
            />
            <YAxis
              style={{
                fontSize: "0.8rem",
                fontFamily: "Helvetica",
              }}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip/>
            <Area dataKey="value" type="monotone" stroke="#FFA700" fill="#ffca66" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default NewMembeers;
