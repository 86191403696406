import React from "react";
import PropTypes from "prop-types";

import { StyledStadistic, StyledLabel, StyledTitle } from "./StatdisticsLabel.styles";
import useIcons from "../../../assets/icons";

const StatdisticsLabel = (props) => {
  const { type, className, label } = props;

  const { IconArrowStadisctics } = useIcons();
  return (
    <StyledStadistic className={className} label={type}>
      {type === "up" ? <IconArrowStadisctics color="#018145" /> : <IconArrowStadisctics />}
      <StyledLabel label={type}>{label}</StyledLabel>
      {type === "up" ? <StyledTitle>SUBIO</StyledTitle> : <StyledTitle>BAJO</StyledTitle>}
    </StyledStadistic>
  );
};

StatdisticsLabel.propTypes = {
  type: PropTypes.oneOf(["up", "down"]),
  className: PropTypes.string,
  label: PropTypes.string,
};

StatdisticsLabel.defaultProps = {
  type: "up",
};

export default StatdisticsLabel;
