// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledCardWrapper = styled.div.attrs({
  className: "StyledCardWrapper"
})`
  ${tw`bg-white rounded-2xl p-16`};
  max-width: ${props => props.widthContent};
  box-shadow: 4px 4px 8px rgba(62, 62, 61, 0.08), -4px -4px 8px rgba(62, 62, 61, 0.08);
  padding: ${props => props.padding};
`