import useHelpers from "../../../helpers";

const useProjectsSelectors = () => {
  // Helpers
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const projectSelected = createSelector(
    (state) => state.projectSelected,
    (projectSelected) => projectSelected
  );

  return {
    projectSelected,
  };
};

export default useProjectsSelectors;
