// Packages
import React from "react";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";

// Components
import SelectMultiField from "../SelectMultiField";

const SelectMultipleFieldControlled = (props) => {
  const {
    id,
    name,
    label,
    control,
    tooltipTitle,
    options,
    limitTags,
    placeholder,
    defaultValue,
    disabled,
    disableCloseOnSelect,
    renderchecked,
    onChangeField,
    onKeyUp,
    maxSelection,
  } = props;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue ?? [],
  });

  const auxOnChange = (value, data) => {
    if (data.length <= maxSelection) {
      onChange(data);
      onChangeField && onChangeField(data);
    }
  };

  return (
    <SelectMultiField
      error={error}
      id={id}
      name={name}
      label={label}
      tooltipTitle={tooltipTitle}
      onChange={auxOnChange}
      selectedValue={value}
      options={options}
      limitTags={limitTags}
      placeholder={placeholder}
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      renderchecked={renderchecked}
      onKeyUp={onKeyUp}
      maxSelection={maxSelection}
    />
  );
};

SelectMultipleFieldControlled.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  limitTags: PropTypes.any,
  tooltipTitle: PropTypes.string,
  disabled: PropTypes.bool,
  control: PropTypes.object.isRequired,
  renderchecked: PropTypes.bool,
  onChangeField: PropTypes.func,
  onKeyUp: PropTypes.func,
  maxSelection: PropTypes.number,
};

SelectMultipleFieldControlled.defaultProps = {
  limitTags: 10,
  onChangeField: () => {},
  onKeyUp: () => {},
  isValidNewOption: () => {},
  maxSelection: Infinity,
};

export default SelectMultipleFieldControlled;
