import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useNotificationsServices = () => {
  const { useNotificationsProvider } = useProviders();
  const { listNotificationsProvider, addNotificationProvider, addRemindersProvider, getNotificationProvider
  ,cloneNotificationProvider, editNotificationProvider, deleteNotificationProvider, getMembeerGroupsProvider,
  getNotificationsEntitiesProvider, getNotificationsEntitiesByIdProvider } = useNotificationsProvider();

  const listNotificationsService = ({page, search, state, type}) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(listNotificationsProvider({page, search, state, type})));
      }
      catch(error) {
        reject(error);
      }
    })
  }

  const getNotificationService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getNotificationProvider(id)));      
      } catch(error) {
        reject(error);
      }
    })
  }

  const getMembeerGroupsService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getMembeerGroupsProvider(id)));      
      } catch(error) {
        reject(error);
      }
    })
  }
  
  const getNotificationsEntitiesService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getNotificationsEntitiesProvider(id)));      
      } catch(error) {
        reject(error);
      }
    })
  }

  const getNotificationsEntitiesByIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getNotificationsEntitiesByIdProvider(id)));      
      } catch(error) {
        reject(error);
      }
    })
  }

  const addNotificationService = (data) => {
    return new Promise( async (resolve, reject) => {
      try {
        resolve(await trackPromise(addNotificationProvider(data)));
      } catch (error) {
        reject(error);
      }
    })
  }

  const addRemindersService = (data) => {
    return new Promise( async (resolve, reject) => {
      try {
        resolve(await trackPromise(addRemindersProvider(data)));
      } catch (error) {
        reject(error);
      }
    })
  }

  const cloneNotificationService = ({ id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(cloneNotificationProvider({ id })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const editNotificationService = ({id, datos}) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editNotificationProvider({id, datos})));
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteNotificationService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteNotificationProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    addNotificationService,
    addRemindersService,
    cloneNotificationService,
    deleteNotificationService,
    editNotificationService,
    getMembeerGroupsService,
    getNotificationService,
    listNotificationsService,
    getNotificationsEntitiesService, 
    getNotificationsEntitiesByIdService
  }
}

export default useNotificationsServices;