import { useSelector } from "react-redux";
import useAuthSelectors from "./auth";
import useToolsSelectors from "./tools";
import useUsersSelectors from "./users";
import useProjectsSelectors from "./projects";

const useSelectors = () => {
  return {
    useSelector,
    useAuthSelectors,
    useToolsSelectors,
    useUsersSelectors,
    useProjectsSelectors,
  };
};

export default useSelectors;
