import React, { useState, useRef, useEffect } from "react";

import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";

// Assets
import iconDropdown from "../../../assets/images/icon-dropdown.svg";
import iconPreview from "../../../assets/images/icon-preview.png";
import iconVideo from "../../../assets/images/icon-video.png";
import iconClose from "../../../assets/images/icon-close.svg";
import iconEdit from "../../../assets/images/icon-edit.svg";
import iconClone from "../../../assets/images/icon-copy.svg";
import iconDelete from "../../../assets/images/icon-delete.svg";
import iconApprovev from "../../../assets/images/icon-approve.svg";
import iconDownload from "../../../assets/images/icon-download.svg";

const Dropdown = (props) => {
  const { options } = props;
  const [open, setOpen] = useState(false);
  const [optionFilter, setOptions] = useState(false);
  const anchorRef = useRef(null);

  const optionsDefault = [
    {
      id: "video",
      name: "Llamada",
      icon: iconVideo,
      external_link: null,
      disabled: false,
      action: null,
    },
    {
      id: "approve",
      name: "Aprobar",
      icon: iconApprovev,
      external_link: null,
      disabled: false,
      show: true,
      action: null,
    },
    {
      id: "download",
      name: "Descargar",
      icon: iconDownload,
      external_link: null,
      disabled: false,
      show: true,
      action: null,
    },
    {
      id: "preview",
      name: "Previsualizar",
      icon: iconPreview,
      external_link: null,
      disabled: false,
      show: true,
      action: null,
    },
    {
      id: "clone",
      name: "Clonar",
      icon: iconClone,
      external_link: null,
      disabled: false,
      show: true,
      action: null,
    },
    {
      id: "edit",
      name: "Editar",
      icon: iconEdit,
      external_link: null,
      disabled: false,
      show: true,
      action: null,
    },
    {
      id: "finish",
      name: "Finalizar",
      icon: iconClose,
      external_link: null,
      disabled: false,
      show: true,
      action: null,
    },
    {
      id: "delete",
      name: "Eliminar",
      icon: iconDelete,
      external_link: null,
      disabled: false,
      show: true,
      action: null,
    },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleOnclick = (option) => {
    option.action && option.action()
    option.external_link && window.open(option.external_link, '_blank')
  }

  const merge = (a, b) => {
    for (var idx in b) {
      a[idx] = b[idx];
    }
  }

  useEffect(() => {
    const filter = optionsDefault.filter((v) => options.find((o) => o.id == v.id));
    filter.forEach((v) =>
      merge(
        v,
        options.find((o) => o.id == v.id)
      )
    );
    setOptions(filter);
  }, [options]);
  return (
    <React.Fragment>
      <Button
        size="small"
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
        ref={anchorRef}

      >
        <img src={iconDropdown} height="5" width="5"/>
      </Button>
      <Popper
        className="z-10"
        open={open}
        anchorEl={anchorRef.current}
        placement="left-start"
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "right top" : "right bottom",
            }}
          >
            <Paper style={{ backgroundColor: "white", color: "black", width: "165px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {optionFilter?.filter(v => v.show).map((option, index) => (
                    <MenuItem
                      key={option.name}
                      disabled={option.disabled}
                      onClick={()=> handleOnclick(option)}
                      sx={{ borderBottom: optionFilter?.filter(v => v.show).length - 1 > index ? "1px solid #9ca3af" : '' }}
                    >
                      <img src={option?.icon} width="20px" height="20px" alt={option.name} className="mr-4" />
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default Dropdown;
