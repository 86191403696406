// Packages
import React from "react";

// Styles
import { StyledSearch, StyledContentButtons, StyledFormFilter } from "./TaskView.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

const UsersViews = () => {

  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    useModals,
    Typography,
    Pagination,
    SelectMultiFieldControlled,
    DatePickerRangeControlled,
    Dropdown,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useTasks } = useScreenHooks();
  const { formatDate } = useGeneralHooks();
  const { useTaskView } = useTasks();
  const {
    control,
    handleSubmit,
    onSubmit,
    listTasks,
    onChangeSearch,
    listCountries,
    handleCreateNewTask,
    handleCloneTask,
    handleDownloadExcel,
    handleShowTask,
    handleDeleteTask,
    handleCloseModal,
    modalDelete,
    handleConfirmDelete,
    handlePagination,
    paginationSettings,
    handleEditTask,
    arriveToCreateProject,
    project,
    handleEditProject,
    listProject,
    onClickClear,
    navigation,
  } = useTaskView();

  return (
    <>
      {arriveToCreateProject && (
        <Typography className="px-12 pt-4 pb-8" variant="h2">
          Proyecto {project.name}
        </Typography>
      )}

      <div className="bg-white px-8 pt-10 pb-12 mx-12 rounded-lg">
        <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
          <TextFieldSeach
            placeholder="Buscar"
            name="search"
            id="search"
            control={control}
            onInputChange={onChangeSearch}
          />
          <StyledContentButtons>
            <FilterButton handleSubmit={handleSubmit(onSubmit)}>
              <StyledFormFilter>
                <SelectFieldControlled
                  label={"Proyecto"}
                  name={`abiFilterProject`}
                  id={`abi-filter-project`}
                  control={control}
                  options={listProject}
                />
                <SelectMultiFieldControlled
                  label={"Pais"}
                  placeholder={"Selecciona una opción"}
                  name={`abiCountryId`}
                  id={`abi-country-id`}
                  control={control}
                  options={listCountries}
                  limitTags={2}
                />
                <DatePickerRangeControlled
                  label="Rango de fecha"
                  name="range_date"
                  id="range_date"
                  control={control}
                  onClickClear={onClickClear}
                />
                <SelectFieldControlled
                  label={"Estado"}
                  name={`abiFilterStatus`}
                  id={`abi-filter-status`}
                  control={control}
                  options={[
                    { value: "in_course", label: "En curso" },
                    { value: "finished", label: "Finalizado" },
                    { value: "expired", label: "vencida" },
                    { value: "no_project", label: "Sin proyecto" },
                    { value: "no_tools", label: "Sin herramientas" },
                  ]}
                />
              </StyledFormFilter>
            </FilterButton>
            <DefaultButton color="secondary" onClick={handleCreateNewTask}>
              Crear Misión
            </DefaultButton>
            <DefaultButton color="third" onClick={handleDownloadExcel}>
              Exportar a Excel
            </DefaultButton>
          </StyledContentButtons>
        </ContentWrappers>
        <TableContent
          headerCellList={[
            "Id",
            "Misión Existente",
            "Proyecto",
            "Fecha de vencimiento",
            "Estado",
            "Acciones",
          ]}
          gridCell={["0.5fr", "1.2fr", "1.2fr", "0.75fr", "0.75fr", "100px"]}
        >
          {listTasks.map((task, index) => {
            return (
              <TableRow key={index}>
                <div>{task.id}</div>
                <div>
                  <b onClick={() => handleShowTask(task.id)} className="cursor-pointer">
                    {task.name}
                  </b>
                </div>
                <div> {task.project?.name} </div>
                <div>{formatDate(task.end_date, "dd-mm-Y")}</div>
                <div>
                  <p
                    className={`${
                      task.status === "Sin herramientas" || task.project_id === null || task.tools.length === 0
                        ? "text-white bg-danger-450 "
                        : task.status === "En curso"
                        ? "text-white bg-success"
                        : "text-neutral-70 bg-neutral-50"
                    } rounded-full text-center w-max px-2`}
                  >
                    {task.project_id === null ? "Sin proyecto" : task.tools.length === 0 ? 'Sin herramienta' : task.status}
                  </p>
                </div>
                <div className="flex justify-end">
                  <Dropdown options={[
                    {id: 'preview', action: ()=> navigation(`/misiones/preview/${task.id}`)},
                    {id: 'edit', action: ()=> handleEditTask(task.id)},
                    {id: 'delete', action: ()=> handleDeleteTask(task.id, task.name), show: !task.is_attitudinal_mission},
                    {id: 'clone', action: ()=> handleCloneTask(task.id), show: !task.is_attitudinal_mission}
                    ]}/>
                </div>
              </TableRow>
            );
          })}
        </TableContent>
        <div className="flex items-center justify-between mt-8">
          <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
          {arriveToCreateProject && (
            <DefaultButton color="primary" onClick={() => handleEditProject(project)}>
              Siguiente
            </DefaultButton>
          )}
        </div>

        <DefaultModal isOpen={modalDelete.show}>
          <div className="flex items-center flex-col space-y-8">
            <Typography variant="body2" className="text-center">
              Esta seguro de eliminar la misión <b>{modalDelete.name}</b>.
            </Typography>
            <div className="flex items-center space-x-4">
              <DefaultButton color="secondary" onClick={handleCloseModal}>
                Cancelar
              </DefaultButton>
              <DefaultButton color="success" onClick={handleConfirmDelete}>
                Aceptar
              </DefaultButton>
            </div>
          </div>
        </DefaultModal>
      </div>
    </>
  );
};

export default UsersViews;
