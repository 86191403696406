import axios from "axios";

const useFaqsProvider = () => {
  const listFaqsProvider = ({page, search, country_id, status}) => {
    return axios ({
      method: "get",
      url: "faq_resumes",
      params: {search, country_id, status}
    })
  }

  const getFaqProvider = (id) => {
    return axios({
      method: "get",
      url: `faq_resumes/${id}`,
    });
  }
  
  const editFaqsProvider = (id, data) => {
    return axios ({
      method: "put",
      url: `faq_resumes/${id}`,
      data
    })
  }
  
  const addFaqsProvider = (data) => {
    return axios ({
      method: "post",
      url: `faq_resumes`,
      data
    })
  }

  const addFaqsListProvider = (data) => {
    return axios ({
      method: "post",
      url: `faqs-array`,
      data
    })
  }

  const deleteFaqProvider = (id) => {
    return axios({
      method: "delete",
      url: `faq_resumes/${id}`,      
    });
  };

  return {
    listFaqsProvider,
    editFaqsProvider,
    addFaqsProvider,
    addFaqsListProvider,
    getFaqProvider,
    deleteFaqProvider
  }
}

export default useFaqsProvider;