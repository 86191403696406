import styled from "styled-components";
import tw from "twin.macro";

export const StyledInputDraftContent = styled.div.attrs({
  className: "StyledInputDraftContent",
})`
  ${tw`mb-7 relative`}
  .abi-wrapper-html {
    ${tw`border border-neutral-60 border-solid box-border rounded mt-2`}
  }
  .rdw-editor-toolbar {
    ${tw`mb-0`}
  }
  .abi-editor-html {
    ${tw`px-4 bg-neutral-30 text-neutral text-sm font-light`}
  }
`;

