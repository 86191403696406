import styled from "styled-components";
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from "@mui/material/AccordionDetails";
import tw from "twin.macro";

export const StyledNavBar = styled.aside.attrs({
  className: "StyledNavBar",
})`
${tw` bg-neutral-40`}

  width: ${(props) => (props.status ? "20%" : "73px")};
  transition: all 1s;
  max-width: 320px;
`;

export const StyledNavBarHeader = styled.header.attrs({
  className: "StyledNavBarHeader",
})`
  ${tw`px-6 py-3 flex justify-center items-center relative`}

  > div {
    ${tw`flex items-center`}
    opacity: ${(props) => (props.status ? "1" : "0")};
    transition: all 0.5s;
  }

  background-color: #FFFFFF;
`;

export const StyledButtonNabvar = styled.button.attrs({
  className: "StyledButtonNabvar",
})`
  ${tw`flex absolute py-7 lg:py-6 items-center justify-center`}
  right: 0px;
  transition: all 1s;
  height: 4.4rem;
  z-index: 1;
  svg {
    transform: ${(props) => (props.status ? "rotate(0deg)" : " rotate(180deg)")};
    width: auto;
    height: auto;
  }

  width: 4.4rem;
  
  ${(props) => (props.statusNavbar ? "visibilityt: visible;" : "visibility: hidden;")}
  ${(props) => (!props.statusNavbar && props.firstActive ? "background: #000;" : "background: #FFB62A;")}
  ${(props) => (!props.status ? "width: 4.4rem;right: -70px; z-index: 10;" : "")}
`;
export const StyledNavBarContent = styled.nav.attrs({
  className: "StyledNavBarContent",
})`
  ${tw`pt-0 relative bg-primary`}
  :hover > button {
    visibility: visible;
  }
`;

export const StyleNavBarMenu = styled.ul.attrs({
  className: "StyleNavBarMenu",
})`
`;

export const StyleNavBarItem = styled.li.attrs({
  className: "StyleNavBarItem",
})`
  span {
    ${tw`block whitespace-pre text-secondary bg-fourth`}
    font-size: 0.875vw;
    letter-spacing: -0.006em;

    with: -webkit-fill-available;
    min-height: 4.4rem;

    span {
      ${tw`inline-block align-middle`}
      padding: 16px;
      width: -webkit-fill-available;
    }

    .icon {
      ${tw`mr-7`}
      min-width: 22px;
      max-width: 24px;
    }

    &.active {
      ${tw`bg-neutral-100`}
      color: #FFFFFF;
    }
    &:hover {
      ${tw`text-secondary bg-fifth`}
    }

    &:active {
      ${tw`text-secondary bg-eighth`}
    }
  }
`;

export const StyledAccordionContent = styled(Accordion).attrs({className: "StyledAccordionContent"})`
&& {
  ${tw`text-secondary bg-primary shadow-none m-0`}

  &:hover {
    ${tw`text-secondary bg-sixth`}

    path,
    svg {
      transition: all 0.5s;
      fill: #000;
    }
    svg[data-stroke="true"] {
      fill: none;
      path {
        fill: none;
        stroke: #000;
      }
    }
  }

  &:active {
    ${tw`text-secondary bg-seventh`}

    path,
    svg {
      transition: all 0.5s;
      fill: #000;
    }
    svg[data-stroke="true"] {
      fill: none;
      path {
        fill: none;
        stroke: #000;
      }
    }
  }

  &.Mui-expanded {
    margin: 0px !important;
    ${tw`text-primary bg-secondary`}
  }
}
`;

export const StyledAccordionHeader = styled(AccordionSummary)`
  && {
    >div {
      margin: 0px !important;
    }
    &.Mui-expanded {
      margin: 0px !important;
    }
    span {
      ${tw`block whitespace-pre`}
      font-size: 0.875vw;
      letter-spacing: -0.006em;
      transition: all 0.2s;

      width: -webkit-fill-available;
      min-height: 4.4rem;
      display: flex;

      span {
        ${tw`inline-block align-middle`}
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      > span:nth-child(3) {
        align-items: end;
      }

      path,
      svg {
        transition: all 0.2s;
        fill: #000;
      }
      svg[data-stroke="true"] {
        fill: none;
        path {
          fill: none;
          stroke: #000;
        }
      }
      .icon {
        ${tw`mr-7`}
        min-width: 22px;
        max-width: 24px;
      }
    }
  }
`;

export const StyledAccordionBody = styled(AccordionDetails)`
&& {
  padding: 0px !important;

  &.contracted {
    position: absolute;
    z-index: 2;
    left: 72px;
  }
}
`;
