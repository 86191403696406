import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useBrandsServices = () => {
  const { useBrandsProviders } = useProviders();
  const {
    listBrandsProvider,
    listBrandPaginateProvider,
    createBrandProvider,
    getBrandProvider,
    editBrandProvider,
  } = useBrandsProviders();

  const listBrandsService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listBrandsProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listBrandPaginateService = ({ page, search, status, init_date, end_date }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            listBrandPaginateProvider({ page, search, status, init_date, end_date })
          )
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const createBrandService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createBrandProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getBrandService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getBrandProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };
  const editBrandService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editBrandProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listBrandsService,
    listBrandPaginateService,
    createBrandService,
    getBrandService,
    editBrandService,
  };
};
export default useBrandsServices;
