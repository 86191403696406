import { useCallback, useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useHelpers from "../../../../helpers";
import useApi from "../../../../api";
import { useNavigate, useParams } from "react-router-dom";

const brandsCreate = () => {
  const navigation = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [nameBrand, setNameBrand] = useState("");
  const [textSucces, setTextSucces] = useState("");

  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { brandSchema } = useValidators();

  const { brandId } = useParams();

  const { useActions } = useApi();
  const { useBrandsActions, dispatch } = useActions();
  const { actCreateBrand, actGetBrand, actEditBrand } = useBrandsActions();

  const { control, handleSubmit, getValues, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(brandSchema),
  });

  const handleCloseModal = () => {
    setShowModal(false);
    navigation("/marcas");
  };
  const handleSuccesCreateEdit = (data) => {
    setNameBrand(data.data.name);
    setShowModal(true);
  };

  const handleLoadData = (data) => {
    setValue("name", data.data.name);
    setValue("description", data.data.description);
    setValue("status", data.data.status === "Activo" ? "act" : "ina");
    setValue("photo", data.data.photo);
  };

  const onSubmit = useCallback((data) => {
    if (brandId) {
      const dataEdit = {
        name: data.name,
        description: data.description,
        status: data.status,
        photo: data.photo[0].file,
      };
      setTextSucces("editado");
      dispatch(actEditBrand(brandId, dataEdit, handleSuccesCreateEdit));
    } else {
      const formatData = new FormData();
      formatData.append("name", data.name);
      formatData.append("description", data.description);
      formatData.append("photo", data.photo[0]);
      formatData.append("status", data.status);
      setTextSucces("creado");
      dispatch(actCreateBrand(formatData, handleSuccesCreateEdit));
    }
  }, []);

  useEffect(() => {
    if (brandId) {
      actGetBrand(brandId, handleLoadData);
    }
  }, []);

  return {
    control,
    showModal,
    handleCloseModal,
    handleSubmit,
    onSubmit,
    getValues,
    nameBrand,
    textSucces,
  };
};

export default brandsCreate;
