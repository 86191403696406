import useApi from "../../index";

const useMissionActions = () => {
  // Services
  const { useServices } = useApi();
  const { useMissionServices } = useServices();
  const {
    listMissionsService,
    getMissionService,
    cloneMissionService,
    createMissionService,
    deleteMissionService,
    editMissionService,
    getMissionPreviewService,
    getToolPreviewService,
    getMissionMembeersService,
    approveToolService,
  } = useMissionServices();

  const actListMissions = async (
    { page, search, project_id, init_date, end_date, status, country_id },
    onSuccess,
    onError
  ) => {
    try {
      const response = await listMissionsService({
        page,
        search,
        project_id,
        init_date,
        end_date,
        status,
        country_id,
      });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actGetMission = async (id, onSuccess, onError) => {
    try {
      const response = await getMissionService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };
  const actDeleteMission = async (id, onSuccess, onError) => {
    try {
      const response = await deleteMissionService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actCloneMission = async ({ id, project_id }, onSuccess, onError) => {
    try {
      const response = await cloneMissionService({ id, project_id });
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actCreateMission = async (data, onSuccess, onError) => {
    try {
      const response = await createMissionService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actEditMission = async (id, data, onSuccess, onError) => {
    try {
      const response = await editMissionService(id, data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };
  
  const actMissionMembeers = async (data, onSuccess, onError) => {
    try {
      const response = await getMissionMembeersService(data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actMissionPreview = async (id, onSuccess, onError) => {
    try {
      const response = await getMissionPreviewService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };
  
  const actToolPreview = async (id, onSuccess, onError) => {
    try {
      const response = await getToolPreviewService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };
  const actApproveTool = async (data, onSuccess, onError) => {
    try {
      const response = await approveToolService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  return {
    actListMissions,
    actGetMission,
    actCloneMission,
    actCreateMission,
    actDeleteMission,
    actEditMission,
    actMissionPreview,
    actToolPreview,
    actMissionMembeers,
    actApproveTool,
  };
};

export default useMissionActions;
