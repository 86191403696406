import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useToolsServices = () => {
  const { useToolsProvider } = useProviders();
  const {
    addToolsProvider,
    addSectionProvider,
    addQuestionProvider,
    removeQuestionProvider,
    listToolsProvider,
    getQuestionProvider,
    updateQuestionProvider,
    listSurveySectionsProvider,
    cloneSectionProvider,
    updateToolSectionsProvider,
    getSurveyProvider,
    getSurveySectionProvider,
    orderSurveySectionProvider,
    updateConditionalsProvider,
    getToolProvider,
    updateToolProvider,
    addPassiveTrackingProvider,
    getPassiveTrackingStepsProvider,
    updateSectionProvider,
    removeSectionProvider,
    getPassiveTrackingAnswersProvider,
    getAnalyticToolsProvider,
    getPassiveTrackingAnswersPagiProvider,
    cloneToolProvider,
    updatePassiveTrackingProvider,
    getQuestionsToolProvider,
    finishToolProvider,
    cloneQuestionProvider,
    deleteToolProvider,
    getProfilingQuestionProvider,
    generateSurveyWithAI,
    cloneQuestionBetweenSections,
  } = useToolsProvider();

  const ListToolsService = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listToolsProvider(params)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getToolService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getToolProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateToolService = (data, id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(updateToolProvider(data, id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const addToolsService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(addToolsProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getAnalyticToolsService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getAnalyticToolsProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getSurveyService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getSurveyProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getSurveySectionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getSurveySectionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listSurveySectionsService = (page) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listSurveySectionsProvider(page)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const addSectionService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(addSectionProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const cloneSectionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(cloneSectionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateToolSectionsService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(updateToolSectionsProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const cloneToolService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(cloneToolProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateSectionService = (data, id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(updateSectionProvider(data, id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const removeSectionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(removeSectionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const orderSurveySectionService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(orderSurveySectionProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const addQuestionService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(addQuestionProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const removeQuestionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(removeQuestionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getQuestionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getQuestionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateQuestionService = (data, id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(updateQuestionProvider(data, id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateConditionalsService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(updateConditionalsProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const addPassiveTrackingService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(addPassiveTrackingProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const updatePassiveTrackingService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(updatePassiveTrackingProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getPassiveTrackingStepsService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getPassiveTrackingStepsProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getPassiveTrackingAnswersService = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getPassiveTrackingAnswersProvider(params)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getPassiveTrackingAnswersPagiService = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getPassiveTrackingAnswersPagiProvider(params)));
      } catch (error) {
        reject(error);
      }
    });
  };
  const getQuestionsToolService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getQuestionsToolProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const finishToolService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(finishToolProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };
  const cloneQuestionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(cloneQuestionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteToolService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteToolProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getProfilingQuestionService = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getProfilingQuestionProvider(params)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const generateSurveyWithAIService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(generateSurveyWithAI(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const cloneQuestionBetweenSectionsService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(cloneQuestionBetweenSections(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    addToolsService,
    addSectionService,
    addQuestionService,
    removeQuestionService,
    ListToolsService,
    getQuestionService,
    updateQuestionService,
    listSurveySectionsService,
    cloneSectionService,
    updateToolSectionsService,
    getSurveyService,
    getSurveySectionService,
    orderSurveySectionService,
    updateConditionalsService,
    getToolService,
    updateToolService,
    addPassiveTrackingService,
    getPassiveTrackingStepsService,
    updateSectionService,
    removeSectionService,
    getPassiveTrackingAnswersService,
    getAnalyticToolsService,
    getPassiveTrackingAnswersPagiService,
    cloneToolService,
    updatePassiveTrackingService,
    getQuestionsToolService,
    finishToolService,
    cloneQuestionService,
    deleteToolService,
    getProfilingQuestionService,
    generateSurveyWithAIService,
    cloneQuestionBetweenSectionsService,
  };
};

export default useToolsServices;
