import { useEffect, useState } from "react";

import _ from "lodash";

import useApi from "../../../../api";
import useModels from "../../../../models";
import useHelpers from "../../../../helpers";

import useControllers from "../../..";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const useDashboardView = () => {
  const { useActions } = useApi();
  const { useDashboardActions, useGeneralActions, dispatch } = useActions();
  const { getActDashboard } = useDashboardActions();

  const { actListCountries, actExportExel } = useGeneralActions();

  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();
  const { rol: role, country: country } = useSelector(session);

  const { useQuickFunctions } = useHelpers();
  const { useValidators, useGeneral, useNavigation } = useQuickFunctions();
  const { dashboardSchema } = useValidators();
  const { formatDate } = useGeneral();
  const { navigateTo } = useNavigation();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const [selectedCountry, setSelectedCountry] = useState({});
  const [listCountries, setListCountries] = useState([]);

  //Data section Quotes
  const [dataQuotesData, setDataQuotesData] = useState([]);

  //data section membeers
  const [dataMemebers, setDataMemebers] = useState([]);

  //data section BudgestPayments
  const [dataBudgestPayments, setDataBudgestPayments] = useState([]);

  //data section Missions
  const [dataFollowMissions, setDataFollowMissions] = useState([]);
  const [followMembeers, setFollowMembeers] = useState(null);

  const init_date = new Date();

  // Restar 15 días a la fecha actual
  init_date.setDate(init_date.getDate() - 15);

  const { control, handleSubmit, watch } = useForm({
    mode: "all",
    resolver: yupResolver(dashboardSchema),
    defaultValues: {
      date_start: init_date,
      date_end: new Date(),
      country_id: country.id,
    },
  });

  const onSubmit = (data) => {
    getActDashboard(
      {
        country_id: data.country_id,
        start_date: formatDate(data.date_start, "Y-mm-dd", false),
        end_date: formatDate(data.date_end, "Y-mm-dd", false),
      },
      loadData
    );

    const findCountrieSelected = listCountries.find(
      (countrie) => countrie.value == data.country_id
    );
    setSelectedCountry({ name: findCountrieSelected.label, id: data.country_id });
  };

  const dataListCountries = (data) => {
    const countries = _.map(data, (v) => {
      return { label: v.name, value: v.id };
    });
    countries.push({
      label: "MAZ",
      value: "maz",
    });
    setListCountries(countries);
  };

  const handleDownloadExcel = () => {
    let data = {
      country_id: watch("country_id"),
      start_date: formatDate(watch("date_start"), "Y-mm-dd", false),
      end_date: formatDate(watch("date_end"), "Y-mm-dd", false),
    };
    const filtersProcces = prepareDataFilterDownload(data);
    actExportExel(`ResumeInformationMembeer`, filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  const loadData = (data) => {
    setDataQuotesData({
      chart: [
        {
          name: "cuotas libres",
          value:
            parseInt(data.data.quota_monitoring.total_quotas) +
            parseInt(data.data.quota_monitoring.total_quotas_not_consumer) -
            data.data.quota_monitoring.current_quotas,
        },
        {
          name: "cuotas ocupadas",
          value: data.data.quota_monitoring.current_quotas,
          fill: "#FFA700",
        },
      ],
      list: data.data.quota_monitoring.lists,
      general: {
        total_percentage: data.data.quota_monitoring.total_percentage,
        total_quotas: data.data.quota_monitoring.total_quotas,
        total_quotas_not_consumer: data.data.quota_monitoring.total_quotas_not_consumer,
        current_quotas_consumer: data.data.quota_monitoring.current_quotas_consumer,
        current_quotas_no_consumer: data.data.quota_monitoring.current_quotas_no_consumer,
        percentage: data.data.quota_monitoring.percentage,
        percentage_no_consumer: data.data.quota_monitoring.percentage_no_consumer,
      },
    });

    setDataMemebers({
      newUser: {
        value: data.data.new_members.inThisMonth.records,
        widthBar: data.data.new_members.inThisMonth.percentage,
      },
      openApp: data.data.engagement.doNotOpenedAppLast3Months,
      notMission: data.data.engagement.notReceivedMissionsLastWeek,
      responseRate: data.data.engagement.responseRate,
      country_id: selectedCountry.id,
    });

    setDataBudgestPayments({
      chart: [
        {
          name: "No procesado",
          value: Math.round(
            parseFloat(
              (Math.round(parseFloat(data.data.budgetAndPayments?.unprocessed_payments)) *
                data.data?.budgetAndPayments.eligible_payments?.total_payment) /
                100
            )
          ),
        },
        {
          name: "Procesado",
          value: Math.round(
            parseFloat(
              (Math.round(parseFloat(data.data.budgetAndPayments?.payments_processed)) *
                data.data.budgetAndPayments?.eligible_payments?.total_payment) /
                100
            )
          ),
          fill: "#018145",
        },
      ],
      general: {
        monthly_net_base: data.data.budgetAndPayments?.monthly_net_base,
        net_base: data.data.budgetAndPayments?.net_base,
        enable_payments: data.data.budgetAndPayments?.eligible_payments?.quantity,
        inThisMonth: data.data.budgetAndPayments?.inThisMonth.payments,
        previousMonth: data.data.budgetAndPayments?.previousMonth.payments,
      },
    });
    setDataFollowMissions(data.data.missionFollowUp);
    setFollowMembeers(data.data.membeerData);
  };

  const printChart = () => {
    navigateTo("/pdf/dashboard/chart", {
      country_id: watch("country_id"),
      start_date: formatDate(watch("date_start"), "Y-mm-dd", false),
      end_date: formatDate(watch("date_end"), "Y-mm-dd", false),
    });
  };

  useEffect(() => {
    getActDashboard(
      {
        country_id: country.id,
        start_date: formatDate(init_date, "Y-mm-dd", false),
        end_date: formatDate(new Date(), "Y-mm-dd", false),
      },
      loadData
    );
    actListCountries(dataListCountries);
    setSelectedCountry({ name: country.name, id: country.id });
  }, [country]);

  return {
    dataQuotesData,
    dataMemebers,
    dataBudgestPayments,
    dataFollowMissions,
    role,
    control,
    handleSubmit,
    onSubmit,
    selectedCountry,
    listCountries,
    followMembeers,
    handleDownloadExcel,
    printChart,
    watch,
  };
};

export default useDashboardView;
