// Packages
import { useNavigate } from "react-router-dom";

import useSurvey from "./Survey";
import useToolsMain from "./Main";
import useToolsCreate from "./Create";
import useToolsCreateAI from "./CreateAI";
import usePassiveTracking from "./PassiveTracking";
import useReferred from "./Referred";
import useVideoCall from "./VideoCall"
import useToolPeview from "./Preview";

const useTools = () => {
  // State
  const navigation = useNavigate();

  const handleBack = () => {
    navigation(-1)
  }
  const header = {
    '/herramientas': ["Herramientas"],
    '/herramientas/encuesta/secciones': ["Herramientas", "Encuesta", "Secciones"],
    '/herramientas/encuesta/secciones/listado': ["Herramientas", "Encuesta", "Secciones"],
    '/herramientas/encuesta/secciones/crear-seccion': ["Herramientas", "Encuesta", "Secciones", "Crear Sección"],
    '/herramientas/encuesta/secciones/editar-seccion': ["Herramientas", "Encuesta", "Secciones", "Editar Sección"],
    '/herramientas/encuesta/secciones/preguntas': ["Herramientas", "Encuesta", "Secciones", "Preguntas"],
    '/herramientas/encuesta/secciones/pregunta': ["Herramientas", "Encuesta", "Secciones", "Preguntas"],
    '/herramientas/encuesta/secciones/condicionales': ["Herramientas", "Encuesta", "Secciones", "Condicionales"],
    '/herramientas/crear-herramienta': ["Herramientas", "Crear Herramientas"],
    '/herramientas/editar-herramienta': ["Herramientas", "Editar Herramienta"],
    '/herramientas/passive-tracking/reporte': ["Herramientas", "Reporte Passive Tracking"],
    '/herramientas/passive-tracking/reporte/pasos': ["Herramientas", "Reporte Passive Tracking", "Reporte por pasos"],
    '/herramientas/passive-tracking/reporte/membeer': ["Herramientas", "Reporte Passive Tracking", "Reporte membeer"],
    '/herramientas/passive-tracking/crear': ["Herramientas", "Passive Tracking", "Crear"],
    '/herramientas/passive-tracking/mapa-de-lugares': ["Herramientas", "Passive Tracking", "Mapa de lugares"],
    '/herramientas/referidos/crear': ["Herramientas", "Referidos", "Crear referidos"],
    '/herramientas/videollamadas/crear-videollamada': ["Herramientas", "videollamadas", "Crear videollamada"],
  }

  return {
    useSurvey,
    useToolsMain,
    useToolsCreate,
    useToolsCreateAI,
    usePassiveTracking,
    useReferred,
    useVideoCall,
    useToolPeview,
    handleBack,
    header,
  };
};

export default useTools;
