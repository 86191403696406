// Packages
import { useState, useCallback, useEffect } from 'react';

const usePlaceAutocompleteField = (defaultValue) => {
  const [ addressValue, setAddressValue ] = useState("")

  useEffect(() => {
    setAddressValue(defaultValue)
  }, [defaultValue]);

  const handleChange = useCallback((address) => {
    setAddressValue(address)
  }, [])

  return {
    handleChange,
    addressValue,
  }
}

export default usePlaceAutocompleteField;