// Packages
import React from 'react';

// Components
import useComponents from '..';

// Styles
import { StyledQuestionsTextResponse } from './Questions.styles';

const BinaryResponse = (props) => {
  const { control } = props;
  const { RadioButtonControlled } = useComponents();

  return (
    <StyledQuestionsTextResponse>
      <RadioButtonControlled
        control={ control }
        radioOptions={[
          {
            value: "1",
            label: "Si",
          },
          {
            value: "0",
            label: "No",
          }
        ]}
        id="is_incorrect"
        name="is_incorrect"
        label="Cual opcion es la incorrecta?"
      />
    </StyledQuestionsTextResponse>
  )
}

export default BinaryResponse;