import React from "react";
import useScreenHooks from "../../../../controllers/screenHooks";
import useComponents from "../../../components";

//Styles
import { StyledWrapperRadio } from "./Create.styles";

import _ from "lodash";

const Create = () => {
  const {
    ContentWrappers,
    CardWrapper,
    Typography,
    TextFieldControlled,
    InputFileControlled,
    TextAreaFieldControlled,
    RadioButtonControlled,
    useModals,
    useButtons,
    InputSwitchControlled,
  } = useComponents();

  const { DefaultButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useBrandsScreen } = useScreenHooks();
  const { brandsCreate } = useBrandsScreen();
  const { control, showModal, handleCloseModal, handleSubmit, onSubmit, nameBrand, textSucces } =
    brandsCreate();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-11/12 mt-4" as="section">
        <CardWrapper>
          <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>
          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <ContentWrappers
              gridColums={["100%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <TextFieldControlled
                label="Nombre de marca"
                name="name"
                id="abi-brand_name"
                control={control}
              />
            </ContentWrappers>

            <ContentWrappers
              gridColums={["100%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <InputFileControlled
                name="photo"
                id="abi-brand_photo"
                control={control}
                label="Agregar logo (jpg, png, webp)"
                placeholder="Cargar archivo"
                maxFiles={1}
                directPreview
                showPreview
              />
            </ContentWrappers>

            <ContentWrappers
              gridColums={["100%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <TextAreaFieldControlled
                label="Descripción"
                placeholder="Textarea placeholder"
                name="description"
                id="abi-brand_description"
                control={control}
              />
            </ContentWrappers>

            <Typography variant="h3" className="pb-6 uppercase">
              2. activación o desactivación de marca
            </Typography>

            <StyledWrapperRadio>
              <RadioButtonControlled
                id="abi-brand_status"
                name="status"
                control={control}
                radioOptions={[
                  { label: "Activar", value: "act" },
                  { label: "Desactivar", value: "ina" },
                ]}
              />
            </StyledWrapperRadio>

            <div className="flex items-center justify-center mt-4">
              <DefaultButton color="secondary" type="submit">
                Finalizar y publicar
              </DefaultButton>
            </div>
          </form>
        </CardWrapper>
      </ContentWrappers>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            La marca <strong>{nameBrand}</strong> se ha {textSucces} con éxito.
          </Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </>
  );
};

export default Create;
