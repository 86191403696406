// Packages
import React from "react";
import PropTypes from "prop-types";

// Styles
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledCheckedIcon,
  StyledFormControlLabel
} from "./Checkbox.styles";
import { FormGroup } from "@mui/material";

const Checkbox = (props) => {
  const { disabled, label, color, checked, ...rest } = props;

  return (
    <FormGroup variant="outlined">
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            color={ color }
            disabled={ disabled }
            icon={ <StyledCheckboxIcon color={ color }/> }
            checkedIcon={ <StyledCheckedIcon color={ color } /> }
            checked={ checked ?? false }
            { ...rest }
          />
        }
        label={ label }
      />
    </FormGroup>
  )
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  color:  PropTypes.oneOf(["primary", "error", "success"])
};

Checkbox.defaultProps = {
  disabled: false,
  color: "primary"
};

export default Checkbox;