import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Components
import useComponents from "../../../components";

// Assets
import useIcons from "../../../../assets/icons";

// Controllers
import useControllers from "../../../../controllers";

import {
  StyledQuestionsList,
  StyledQuestionsListItem,
  StyledQuestionsListItemMove,
  StyledQuestionsListItemNumber,
  StyledQuestionsListItemContent,
  StyledQuestionsListItemActionButtons,
} from "./FaqsCreate.styles";

const FaqsCreate = () => {
  const { useScreenHooks } = useControllers();
  const { useFaqs } = useScreenHooks();
  const { useFaqsCreate } = useFaqs();
  const {
    control,
    handleSubmit,
    onSubmit,
    showModal,
    saving,
    handleCloseModal,
    listCountries,
    questions,
    fields,
    createNewQuestion,
    remove,
    handleOnDragEnd,
    handleShowQuestion,
    showQuestionInfo,
    cloneQuestion,
    newId
  } = useFaqsCreate();

  const {
    useButtons,
    TextFieldControlled,
    SelectFieldControlled,
    Typography,
    useModals,
    TextAreaFieldControlled,
  } = useComponents();

  const { DefaultModal } = useModals();

  const { DefaultButton } = useButtons();

  const { IconDelete, IconCopy, IconEdit, IconArrowDown } = useIcons();
  return (
    <div className="bg-white px-16 pt-10 pb-12 mx-24 rounded-lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>
        <SelectFieldControlled
          label="País"
          name="country_id"
          id="abi-country_id"
          control={control}
          options={listCountries}
        />
        <div className="flex justify-between items-center">
        <Typography variant="h3">2. AGREGAR preguntas</Typography>
        <DefaultButton variant="outlined" onClick={() => createNewQuestion()}>
        + Agregar pregunta
        </DefaultButton>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="dropDrapQuestionList">
            {(provided) => (
              <StyledQuestionsList {...provided.droppableProps} ref={provided.innerRef}>                
                {fields?.map((question, index) => {                  
                  return (
                    <Draggable key={question.id} draggableId={question.id} index={index}>
                      {(provided) => (
                        <StyledQuestionsListItem
                          provided={provided}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          item={question}
                        >
                          <StyledQuestionsListItemNumber>{index + 1}</StyledQuestionsListItemNumber>
                          <StyledQuestionsListItemContent active={showQuestionInfo === index}>                            
                          <Typography className={showQuestionInfo === index ? 'hidden': ''}>{questions[index]?.question?.length > 0 ? questions[index]?.question : 'Sin rellenar'}</Typography>
                            <TextFieldControlled
                              control={control}
                              name={`faqs[${index}].question`}
                              id={`faqs[${index}].question`}
                              label={"Pregunta"}
                            />
                            <div>
                              <TextAreaFieldControlled
                                name={`faqs[${index}].answer`}
                                id={`faqs[${index}].answer`}
                                label={"Respuesta"}
                                control={control}
                                placeholder="Escribe un texto aquí"
                              />

                              <StyledQuestionsListItemActionButtons>
                                <button type="button" onClick={() => {}}>
                                  <IconEdit color="#898781" />
                                </button>
                                <button type="button" onClick={() => cloneQuestion(question)}>
                                  <IconCopy />
                                </button>
                                <button type="button" onClick={() => remove(question.id)}>
                                  <IconDelete />
                                </button>
                              </StyledQuestionsListItemActionButtons>
                            </div>
                            <button onClick={() => handleShowQuestion(index)} type="button">
                              <IconArrowDown color="#FFA700" />
                            </button>
                          </StyledQuestionsListItemContent>
                          <StyledQuestionsListItemMove {...provided.dragHandleProps}>
                            <span></span>
                            <span></span>
                            <span></span>
                          </StyledQuestionsListItemMove>
                        </StyledQuestionsListItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </StyledQuestionsList>
            )}
          </Droppable>
        </DragDropContext>
        <div className="text-center mt-4">
          <DefaultButton color="secondary" type="submit" disabled={saving}>
            Finalizar y publicar
          </DefaultButton>
        </div>
      </form>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">          
          <Typography variant="body2">
            La pregunta se ha { newId ? "editado" : "creado" } con éxito.
          </Typography>          
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </div>
  );
};

export default FaqsCreate;
