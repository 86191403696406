// Packages
import styled from "styled-components";
import tw from "twin.macro";
import { FormControl } from '@mui/material';

export const StyledFormControl = styled(FormControl)`
  ${tw``}

  .MuiInputAdornment-root {
    ${tw`absolute`}
    right: 16px;
    top: 33px;
  }

  .MuiButtonBase-root {
    padding: 2px;
  }

  .MuiSvgIcon-root {
    fill: #6D7074;
  }

  // Reset placeholder
  label[data-shrink=false] + .MuiInputBase-formControl {
    .MuiInput-input {
      &::placeholder {
        opacity:1!important;
      }
      &:-ms-input-placeholder {
        opacity:1!important;
      }
      &::-webkit-input-placeholder {
        opacity:1!important;
      }
    }
  }
`