const IconAccordionArrowUp = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1919 8.3295C11.6039 7.92396 12.2521 7.89276 12.7005 8.23592L12.8081 8.3295L19.6653 15.0795C20.1116 15.5188 20.1116 16.2312 19.6653 16.6705C19.2533 17.076 18.605 17.1072 18.1567 16.7641L18.049 16.6705L12 10.7169L5.95098 16.6705C5.539 17.076 4.89073 17.1072 4.4424 16.7641L4.33474 16.6705C3.92275 16.265 3.89106 15.6268 4.23966 15.1855L4.33474 15.0795L11.1919 8.3295Z"
        fill="#363635"
      />
    </svg>
  );
};

export default IconAccordionArrowUp;
