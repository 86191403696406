const IconMarker = ({color = "#242D31"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="46"
      fill="none"
      viewBox="0 0 38 46"
    >
      <path
        fill={ color }
        stroke={ color }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M37 19c0 14-18 26-18 26S1 33 1 19a18 18 0 1136 0z"
      ></path>
    </svg>
  );
}

export default IconMarker;