import styled from "styled-components";
import tw from "twin.macro";

import BgLogin from "../../../assets/images/bg-login.webp";

export const StyledPublicContent = styled.div.attrs({
  className: "StyledPublicContent",
})`
  && {
    ${tw`relative `}
    min-height: calc(100vh);
    background-position: left top;
    ${(props) => {
      switch (props.background) {
        case "primary":
          return `background: #6a809e;`;
        default:
          return `background: #242D31;`;
      }
    }}
    ${(props) => props.login && `
    background-image: url(${BgLogin});
    background-size: cover;
    background-repeat: no-repeat;
    `}
    @media (max-width: 768px) {
      width: 100vw;
      overflow-x: hidden;
      min-height: calc(100vh - 200px);
      background: linear-gradient(
        107.17deg,
        rgba(220, 227, 191, 0.64) -2.4%,
        rgba(150, 171, 64, 0.64) 100.98%
      );
    }
  }
`;

export const StyledInnerContent = styled.div.attrs({
  className: "StyledInnerContent",
})`
  && {
    ${tw`relative `}
    min-height: calc(100vh);
    background-repeat: no-repeat;
    background-position-x: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      background-position-x: -80px;
      background-position-y: 72px;
      background-size: 334px 400px ;
      );
    }
  }
`;

export const StyledHeaderContent = styled.header`
  background-color: #f8f9fa;
  opacity: 0.8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
`;

export const StyledImgWatermark = styled.img`
  && {
    ${tw`absolute`}
    top: 0;
    left: 200px;
    @media (max-width: 768px) {
      width: 334px;
      height: 400px;
      top: 72px;
      left: -80px;
    }
  }
`;
