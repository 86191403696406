// Packages
import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

// Components
import TimePickerField from '../TimePickerField';

const TimePickerFieldControlled = (props) => {
    const { id, name, label, control, tooltipTitle, disabled } = props;

  const {
    field: { onChange, value, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: ""
  });

  return (
    <TimePickerField
      error={ error }
      id={ id }
      name={ name }
      label={ label }
      tooltipTitle={ tooltipTitle }
      onChange={ onChange }
      value={ value }
      inputRef={ ref }
      disabled={disabled}
    />
  )
}

TimePickerFieldControlled.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

export default TimePickerFieldControlled;