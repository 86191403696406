import useApi from "../../index";

const useGroupsActions = () => {
  // Services
  const { useServices } = useApi();
  const { useGroupsServices } = useServices();
  const {
    addGroupService,
    editGroupService,
    listGroupService,
    getGroupService,
    getNumberGroupService,
    listGroupMisionService,
    deleteGroupService,
  } = useGroupsServices();

  const actListGroups = async (
    { page, country_id, search, rol_id, status },
    onSuccess,
    onError
  ) => {
    try {
      const response = await listGroupService({ page, country_id, search, rol_id, status });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };
  
  const actListGroupsMision = async (
    { search, data },
    onSuccess,
    onError
  ) => {
    try {
      const response = await listGroupMisionService({ search, data });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actGetGroups = async (id, onSuccess, onError) => {
    try {
      const response = await getGroupService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actAddGroups = async (data, onSuccess, onError) => {
    try {
      const response = await addGroupService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      console.log(error);
      onError && onError();
    }
  };

  const getNumberGroups = async (data, onSuccess, onError) => {
    try {
      const response = await getNumberGroupService(data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      console.log(error);
      onError && onError();
    }
  };

  const actEditGroups = async (id, data, onSuccess, onError) => {
    try {
      const response = await editGroupService(id, data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actDeleteGroups = async (id, onSuccess, onError) => {
    try {
      const response = await deleteGroupService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  return {
    actListGroups,
    actAddGroups,
    actEditGroups,
    actGetGroups,
    getNumberGroups,
    actListGroupsMision,
    actDeleteGroups,
  };
};

export default useGroupsActions;
