// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Componets
import Typography from '../Typography';

// Styles
import { StyledStepList, StyledStepItem } from './Steps.styles';

const Steps = ({ stepList, activeIndex, widthContent }) => {
  return (
    <StyledStepList widthContent={ widthContent }>
      {stepList.map((step, index) => {
        return (
          <StyledStepItem key={ index } className={(index + 1 ) <= activeIndex && "active"}>
            <span className="item-number">{ index + 1 }</span>
            <Typography
              variant="h4"
              className="uppercase"
            >{ step }
            </Typography>
          </StyledStepItem>
        )
      })}
    </StyledStepList>
  )
}

Steps.propTypes = {
  stepList: PropTypes.arrayOf(PropTypes.string),
  activeIndex: PropTypes.number,
  widthContent: PropTypes.string
}

Steps.defaultProps = {
  gridColums: [],
  activeIndex: 1,
  widthContent: "100%"
}

export default Steps;