import useLayouts from "../../layouts";
import useControllers from "../../../controllers";
import GoogleMapReact from "google-map-react";
import useComponents from "../../components";
import { StyledDiv, StyledWrapperForm, StyledFormFilter } from "./Maps.styles";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

const Map = () => {
  const { useScreenHooks } = useControllers();
  const { useMapScreen } = useScreenHooks();
  const { PrivateContentLayout } = useLayouts();
  const {
    center,
    locations,
    control,
    handleSubmit,
    onSubmit,
    onSubmitFilter,
    listCountries,
    handleOnChangeCountries,
    selectedCountry,
    controlFilter,
    handleSubmitFilter,
    filterData,
    onClickClear,
    handleDownloadExcel,
    onChangeSearchUser,
    listUsers,
    showModal,
    handleCloseModal,
  } = useMapScreen();

  const {
    Typography,
    SelectFieldControlled,
    DatePickerRangeControlled,
    useButtons,
    AutocompleteMultiFieldControlled,
    useModals,
  } = useComponents();
  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  return (
    <PrivateContentLayout title="Mapa">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-11/12 pb-6 mx-auto mb-8 border-b space-x-6 border-neutral-60"
      >
        <div className="flex items-center justify-between">
          <Typography variant="h3" className="uppercase">
            pais: {selectedCountry}
          </Typography>
          <StyledWrapperForm>
            <FilterButton handleSubmit={handleSubmitFilter(onSubmitFilter)}>
              <StyledFormFilter>
                <DatePickerRangeControlled
                  label="Rango de fecha"
                  name="range_date"
                  id="range_date"
                  control={controlFilter}
                  onClickClear={onClickClear}
                  plugins={[<TimePicker />]}
                  format="MM/DD/YYYY HH:mm:ss"
                  dateWithHours
                />
                <AutocompleteMultiFieldControlled
                  label="Membeer"
                  name="mapFilterPeople"
                  id="mapFilterPeople"
                  control={controlFilter}
                  options={listUsers}
                  onKeyUp={onChangeSearchUser}
                />
                <SelectFieldControlled
                  label={"Region"}
                  name={`mapFilterRegion`}
                  id={`mapFilterRegion`}
                  control={controlFilter}
                  options={filterData?.regions}
                />
                <SelectFieldControlled
                  label={"Género"}
                  name={`mapFilterGender`}
                  id={`mapFilterGender`}
                  control={controlFilter}
                  options={filterData?.genders}
                />
                <SelectFieldControlled
                  label={"Nse"}
                  name={`mapFilterNse`}
                  id={`mapFilterNse`}
                  control={controlFilter}
                  options={filterData?.nse}
                />
                <SelectFieldControlled
                  label={"Ciudad"}
                  name={`mapFitlerCity`}
                  id={`mapFitlerCity`}
                  control={controlFilter}
                  options={filterData?.citys}
                />
                <SelectFieldControlled
                  label={"Edad"}
                  name={`mapFilterAge`}
                  id={`mapFilterAge`}
                  control={controlFilter}
                  options={filterData?.ages}
                />
              </StyledFormFilter>
            </FilterButton>
            <SelectFieldControlled
              placeholder="Selecciona un pais"
              name="country_id"
              id="country_id"
              onChangeField={handleOnChangeCountries}
              control={control}
              options={listCountries}
            />
            <DefaultButton color="secondary" type="submit">
              Consultar
            </DefaultButton>
            <DefaultButton color="third" onClick={handleDownloadExcel}>
              Exportar a Excel
            </DefaultButton>
          </StyledWrapperForm>
        </div>
      </form>

      <StyledDiv>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_API_GOOGLE}`,
            libraries: ["visualization"],
          }}
          center={center}
          defaultZoom={6}
          yesIWantToUseGoogleMapApiInternals
          heatmap={{
            positions: locations,
            options: {
              radius: 20,
              opacity: 1,
            },
          }}
        />
      </StyledDiv>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2">El informe se está creando. Al finalizar se enviará un mail con una url para descargarlo</Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </PrivateContentLayout>
  );
};

export default Map;
