// Packages
import { useNavigate } from "react-router-dom";

// Steps controllers
import useUsersView from './Views'
import useUsersCreate from './Create'
import useUsersEdit from './Edit'

const useUsers = () => {
  // State
  const navigation = useNavigate();

  const handleBack = () => {
    navigation(-1)
  }

  const header = {
    '/usuarios': ["Usuarios Administradores"],
    '/usuarios/creacion': ["Usuarios Administradores", "Crear Usuario"],
    '/usuarios/editar': ["Usuarios Administradores", "Edición de perfil de usuario"],
  }

  return {
    handleBack,
    header,
    useUsersEdit,
    useUsersCreate,
    useUsersView,
  };
};

export default useUsers;
