import axios from "axios";

const useMissionProvider = () => {
  const listMissionsProvider = ({
    page,
    search,
    project_id,
    init_date,
    end_date,
    status,
    country_id,
  }) => {
    return axios({
      method: "get",
      url: `missions`,
      params: { page, search, project_id, init_date, end_date, status, country_id },
    });
  };

  const getMissionProvider = (id) => {
    return axios({
      method: "get",
      url: `missions/${id}`,
    });
  };

  const deleteMissionProvider = (id) => {
    return axios({
      method: "delete",
      url: `missions/${id}`,
    });
  };

  const cloneMissionProvider = ({ id, project_id }) => {
    return axios({
      method: "post",
      url: `missions/clone/${id}`,
      data: { is_duplicated: true, project_id },
    });
  };

  const createMissionProvider = (data) => {
    return axios({
      method: "post",
      url: `missions`,
      data: data,
    });
  };

  const editMissionProvider = (id, data) => {
    return axios({
      method: "put",
      url: `missions/${id}`,
      data: data,
    });
  };

  const getMissionMembeersProvider = (data) => {
    return axios({
      method: "get",
      url: `/missions/membeers/${data.id}`,
      params: {
        page: data.page,
        tool_id: data.tool_id,
        status: data.status,
      },
    });
  };

  // Preview mission
  const getMissionPreviewProvider = (id) => {
    return axios({
      method: "get",
      url: `missions/preview/${id}`,
    });
  };

  // Preview mission
  const getToolPreviewProvider = (id) => {
    return axios({
      method: "get",
      url: `tools/preview/${id}`,
    });
  };

  // approve tools
  const approveToolProvider = (data) => {
    return axios({
      method: "post",
      url: `missions/video_call/users`,
      data: data,
    });
  };

  return {
    listMissionsProvider,
    getMissionProvider,
    cloneMissionProvider,
    createMissionProvider,
    deleteMissionProvider,
    editMissionProvider,
    getMissionPreviewProvider,
    getToolPreviewProvider,
    getMissionMembeersProvider,
    approveToolProvider,
  };
};

export default useMissionProvider;
