// Packages
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Helpers
import useHelpers from "../../../../../helpers";

// Components
import useComponents from "../../../../../views/components";

import useActions from "../../../../../api/actions";

const useReferredCreate = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [idTools, setIdTools] = useState(null);

  // Helper
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast } = useQuickFunctions();
  const { referredValidationShema } = useValidators();
  const { navigateTo } = useNavigation();
  const { success } = useToast();

  // Actions
  const { useToolsActions, dispatch } = useActions();
  const { actAddTools, actUpdateTool } = useToolsActions();

  // Components
  const { Toast } = useComponents();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(referredValidationShema),
  });


  const handleCloseModal = () => {
    setShowModal(false);
    navigateTo(`/herramientas`);
  };

  const onSubmit = useCallback((data) => {
    const formData = {
      ...location.state,
      min_referrals: data.min_referrals,
      max_referrals: data.max_referrals,
    }
    if(location.state.tools) {
      const onSuccess = () => {
        success(<Toast text={"Herramienta editada con éxito!"} />, {
          toastId: 0,
        });
        navigateTo(`/herramientas`)
      }
      dispatch(actUpdateTool({ data: formData, id: location.state.id }, onSuccess));
    } else {
      const onSuccess = (data) => {
        setShowModal(true)
        setIdTools(data.id)
      }
      dispatch(actAddTools(formData, onSuccess));
    }
  }, []);

  const defaultData = (data) => {
    reset({
      min_referrals: data.tools.min_referrals,
      max_referrals: data.tools.max_referrals,
    });
  };

  useEffect(() => {
    if (!location.state) {
      navigation(-1);
    }
    if (location.pathname.includes("editar")) {
      defaultData(location.state);
    }
  }, []);

  return {
    control,
    onSubmit,
    handleSubmit,
    showModal,
    handleCloseModal,
    idTools
  };
};

export default useReferredCreate;
