import React from "react";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import useScreenHooks from "../../../controllers/screenHooks";
import useLayouts from "../../layouts";

// Components
import View from "./View";
import Create from "./Create";

const CountriesLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const location = useLocation();

  const { useContriesScreen } = useScreenHooks();
  const { handleBack, header } = useContriesScreen();

  return (
    <PrivateContentLayout
      breadcrumbsList={header[location.pathname]}
      handleBack={handleBack}
      showBack={location.pathname !== "/paises"}
      title="PAÍSES"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Countries = () => {
  return (
    <Routes>
      <Route path="/" element={<CountriesLayout />}>
        <Route index element={<View />} />
        <Route path="crear" element={<Create />} />
        <Route path="editar/:countrieId" element={<Create />} />
      </Route>
    </Routes>
  );
};

export default Countries;
