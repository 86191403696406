import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from 'query-string'

import useApi from "../../../../api";


const useDashboardDownload = () => {
  const {search} = useLocation()
  const params = useParams()

  const { useActions } = useApi();
  const { useDashboardActions } = useActions();
  const { getActDashboard } = useDashboardActions();

  const [followMembeers, setFollowMembeers] = useState(null);



  const loadData = (data) => {
    setFollowMembeers(data.data.membeerData);
    printChart()

  };

  const printChart = () => {
    html2canvas([document.getElementById("follow-users")], {
      onrendered: function (canvas) {
        var source = canvas.toDataURL('image/png');
        var a = document.createElement('a');

          a.download = 'img grafico seguimineto de usuarios.png';
          a.target = '_blank';
          a.href= source;

          a.click();
      }
    })
  }

  useEffect(() => {
    if(search) {
      const values = queryString.parse(search)
      getActDashboard(
        {
          country_id: values.country_id,
          start_date: values.init_date,
          end_date: values.end_date
        },
        loadData
      );
    }
  }, [search]);

  return {
    followMembeers,
  };
};

export default useDashboardDownload;
