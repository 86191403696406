import { useNavigate } from "react-router-dom";

import countriesView from "./View";
import countrieCreate from "./Create";

const useContriesScreen = () => {
  const navigation = useNavigate();

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/paises": ["paises"],
    "/paises/crear": ["paises", "crear pais "],
  };

  return { handleBack, header, countriesView, countrieCreate };
};

export default useContriesScreen;
