// Packages
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useActions from "../../../../api/actions";
import useComponents from "../../../../views/components";

const useQuestionsMain = () => {
  const [toolsList, setToolsList] = useState([]);
  const [pathParams, setPathParams] = useState({ search: "" });
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast } = useQuickFunctions();
  const { success, error } = useToast();
  const { filtersValidationShema } = useValidators();
  const { navigateTo } = useNavigation();

  // Actions
  const { useQuestionsActions, dispatch } = useActions();
  const { actListQuestions, actDeleteQuestion, actCloneQuestion } = useQuestionsActions();

  const { Toast } = useComponents();

  // Components

  useEffect(() => {
    const onSuccess = ({ data, meta }) => {
      setToolsList(data);
      setPaginationSettings({ current: meta?.current_page ?? 1, totalPages: meta?.last_page ?? 1 });
    };

    const onError = () => {
      error(<Toast text={"Error"} />, {
        toastId: 0,
      });
    };

    pathParams
      ? dispatch(actListQuestions({ search: pathParams.search }, onSuccess, onError))
      : dispatch(actListQuestions({}, onSuccess, onError));
  }, [pathParams?.search]);

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(filtersValidationShema),
  });

  const onSubmit = (data) => {
    setPathParams({
      search: data?.search,
    });
  };

  const handlePagination = (nro) => {
    const onSuccess = ({ data, meta }) => {
      setToolsList(data);
      setPaginationSettings({ current: meta?.current_page ?? 1, totalPages: meta?.last_page ?? 1 });
    };
    const onError = (error) => {
      console.log(error);
    };
    dispatch(actListQuestions({ page: nro.selected + 1, ...pathParams }, onSuccess, onError));
  };

  const handleDeleteQuestion = (id, name) => {
    setModalDelete({ show: true, name, id });
  };

  const handleConfirmDelete = () => {
    const onSuccess = ({ data, meta }) => {
      setToolsList(data);
      setPaginationSettings({ current: meta?.current_page ?? 1, totalPages: meta?.last_page ?? 1 });
    };
    const onError = (error) => {
      console.log(error);
    };
    const onSuccessDelete = () => {
      dispatch(actListQuestions({}, onSuccess, onError));
      success(<Toast text={"¡Pregunta eliminada con éxito!"} />, {
        toastId: 0,
      });
    };
    dispatch(actDeleteQuestion(modalDelete.id, onSuccessDelete));
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handleCloseModalDelete = () => {
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handleEditQuestion = (id) => {
    navigateTo(`/qualitychecks/editar/${id}`);
  };

  const handleCloneQuestion = (id) => {
    const onSuccess = ({ data, meta }) => {
      setToolsList(data);
      setPaginationSettings({ current: meta?.current_page ?? 1, totalPages: meta?.last_page ?? 1 });
      success(<Toast text={"¡Pregunta duplicada con éxito!"} />, {
        toastId: 0,
      });
    };
    const onError = (e) => {
      error(<Toast text={e} />, {
        toastId: 0,
      });
    };

    dispatch(
      actCloneQuestion(
        id,
        () => dispatch(actListQuestions({}, (data) => onSuccess(data), onError)),
        onError
      )
    );
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    toolsList,
    paginationSettings,
    handlePagination,
    handleDeleteQuestion,
    handleEditQuestion,
    modalDelete,
    handleCloseModalDelete,
    handleConfirmDelete,
    handleCloneQuestion,
  };
};

export default useQuestionsMain;
