// Packages
import { useCallback } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form'

const useMatrixResponse = (control) => {

  // FieldArray Row
  const {
    fields: matrixRowsFields,
    insert: matrixRowsInsert,
    remove: matrixRowsRemove
  } = useFieldArray({
    control,
    name: "rows",
  })

  const addOptionRowHandled = useCallback((position, id) => {
    matrixRowsInsert(position, {
      id: id,
      name: "",
      is_incorrect: false,
    })
  }, [])

  const removeOptionRowHandled = useCallback((index) =>{
    matrixRowsRemove(index)
  }, [])

  // FieldArray Columns
  const {
    fields: matrixColumnsFields,
    insert: matrixColumnsInsert,
    remove: matrixColumnsRemove 
  } = useFieldArray({
    control,
    name: "columns"
  })

  const addOptionColumnHandled = useCallback((position, id) => {
    matrixColumnsInsert(position, {
      //id: id,
      name: "",
      is_incorrect: false,
    })
  }, [])

  const removeOptionColumnHandled = useCallback((index) =>{
    matrixColumnsRemove(index)
  }, [])

  return {
    addOptionRowHandled,
    removeOptionRowHandled,
    matrixRowsFields,
    matrixColumnsFields,
    addOptionColumnHandled,
    removeOptionColumnHandled,
  }
}

export default useMatrixResponse;