// Packages
import { useState, useEffect, useCallback } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

const useFaqsCreate = () => {
  const navigation = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showQuestionInfo, setShowQuestionInfo] = useState(0);
  const [listCountries, setListCountries] = useState([]);
  const [dropDrapQuestionList, setDropDrapQuestionList] = useState([]);

  const { newId } = useParams();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { newFaqsValidationShema } = useValidators();

  // Actions
  const { useActions } = useApi();
  const { useFaqsActions, useCountriesAction } = useActions();  
  const { actListCountriesFreeFaqs } = useCountriesAction();
  const { actGetFaq, actAddFaqs, actAddFaqsList, actEditFaqs } = useFaqsActions();

  const { control, handleSubmit, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(newFaqsValidationShema),
    defaultValues: {
      faqs: [{ question: "", answer: "" }],
    },
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "faqs",
  });

  const loadData = (data) => {
    if(data.data.faqsCount > 0) remove();    
    const faqs = data.data.faqs.map(x => { return { id:x.id, question: x.question, answer: x.answer, faq_resume_id:x.faq_resume_id, index: x.index }});
    setValue("faqs", faqs)
    setValue("id", data.data.id);
    setValue("status", data.data.status);


    //ARRAY TO DISPLAY TITLES WHEN QUESTIONS ARE COLLAPSED AND INDEX EDITION ON DRAG AND DROP
    setQuestions(faqs);

    //GET COUNTRIES WITH SELECTED 
    actListCountriesFreeFaqs(data.data.country.id, prepareDataCountry);

    console.log("lunch EP")

    setValue("country_id", data.data.country.id);

  }

  const handleCloseModal = () => {
    setShowModal(false);    
  };

  const handleShowModal = () => {    
    setTimeout(() => {
      setShowModal(true);
      navigation(-1);
    }, 2000);
  };

  const onSubmit = (data) => {
    setSaving(true);
    if(newId) { 
      if(data.faqs.length > 0) saveFaqsList(data);
    }
    else {
      actAddFaqs({"status": "act", country_id: data.country_id}).then((resp) => {        
        if(resp && resp.data.id && data.faqs.length > 0) {           
          actAddFaqsList(data.faqs.map(x=> { return {...x, faq_resume_id: resp.data.id}}), handleShowModal);
        }
      })
    };
  }

  const saveFaqsList = (data) => {
    if(data && data.id && data.faqs.length > 0) {           
      actAddFaqsList(data.faqs.map(x=> { return {...x, faq_resume_id: data.id}}), handleShowModal);
    }
  }

  const prepareDataCountry = (data) => {    

    setListCountries(
      data.data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  // DragDrop
  const handleOnDragEnd = useCallback(    
    (result) => {
      if (!result.destination) return;

      const items = Array.from(dropDrapQuestionList);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const itempsUpdateOrder = items.map((item, index) => {
        return { ...item, order: index };
      });

      setDropDrapQuestionList(itempsUpdateOrder);
    },
    [dropDrapQuestionList]
  );

  const createNewQuestion = () => {
    append()
    setShowQuestionInfo(fields.length)
  }

  const cloneQuestion = obj => {
    append({question: obj.question, answer: obj.answer})
    setShowQuestionInfo(fields.length)
  }
  const handleShowQuestion = (index) => setShowQuestionInfo(index)

  useEffect(() => {                
    if(newId) { 
      actGetFaq(newId, loadData)
    }
    else actListCountriesFreeFaqs(null, prepareDataCountry);
  }, []);

  return {
    control,
    handleSubmit,
    onSubmit,
    showModal,
    saving,
    handleCloseModal,
    listCountries,
    fields,
    createNewQuestion,
    remove,
    handleOnDragEnd,
    handleShowQuestion,
    showQuestionInfo,
    cloneQuestion,
    questions,
    newId
  };
};

export default useFaqsCreate;
