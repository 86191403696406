// Packages
import React from "react";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";

// Components
import AutocompleteField from "../AutocompleteField";

const AutocompleteFieldControlled = (props) => {
  const { id, name, label, control, tooltipTitle, options, handleInputChange, disabled, onKeyUp, onChangeValue, warningLabel } = props;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const auxOnChange = (value, data) => {
    onChange(data);
    onChangeValue && onChangeValue(data)
  };
  return (
    <AutocompleteField
      error={error}
      id={id}
      name={name}
      label={label}
      tooltipTitle={tooltipTitle}
      onChange={auxOnChange}
      selectedValue={value}
      options={options}
      handleInputChange={handleInputChange}
      onKeyUp={onKeyUp}
      disabled={disabled}
      warningLabel={warningLabel}
    />
  );
};

AutocompleteFieldControlled.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  control: PropTypes.object.isRequired,
};

export default AutocompleteFieldControlled;
