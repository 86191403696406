// Packages
import React from "react";
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Components
import useComponents from "../../../components";
import useIcons from "../../../../assets/icons";

// Controllers
import useControllers from "../../../../controllers";

// Styles
import { StyledContent } from "./Preview.styles";

// Import Swiper styles
import "swiper/swiper.min.css";
/* import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css"; */

import IconRefresh from "../../../../assets/icons/iconRefresh";

const PreviewTool = () => {
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useToolPeview } = useTools();

  const {
    tools,
    control,
    section,
    questionSelect,
    handleNext,
    error,
    handleBack,
    handleRestart,
    watch,
    listPrevRanking,
    hanldeSelectPrevRanking,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    draggedIndex,
  } = useToolPeview();

  const {
    TextAreaFieldControlled,
    RadioButtonControlled,
    CheckboxControlled,
    DatePickerFieldControlled,
    InputFileControlled,
    Rangefield,
    Typography,
    useButtons,
    TextFieldControlled,
  } = useComponents();

  const { DefaultButton } = useButtons();

  const { IconDots } = useIcons();


  return (
    <StyledContent>
      <div
        className="flex space-x-2 items-center justify-end cursor-pointer"
        onClick={handleRestart}
      >
        <IconRefresh />
        <Typography variant="caption">Reiniciar</Typography>
      </div>
      {tools.map((tool, indexTool) => (
        <div className={section !== tool.id ? "hidden" : ""} key={"t" + tool.id}>
          <Typography className="pt-4 pb-8" variant="h2">
            {tool.survey_name}
          </Typography>
          <div>
            <Typography className="pt-2 pb-2 text-center" variant="h3">
              {tool.title}
            </Typography>
            {tool.questions.map((question, i) => (
              <div
                className={questionSelect !== question.id ? "hidden" : ""}
                key={"q" + question.id}
              >
                <Typography className="pt-4 pb-8" variant="h4">
                  {question.title}
                </Typography>
                {question.files && question.files.length > 0 && (
                  <div style={{ maxWidth: "300px" }} className="mx-auto">
                    <Swiper
                      modules={[Pagination, Autoplay]}
                      slidesPerView={1}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: true,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      loop={question.files.length > 1}
                    >
                      {question.files.map((file, index) => {
                        return (
                          <SwiperSlide key={index} className="py-4">
                            {/(.mp4|.mp3|.avi|.mkv|.mov)/.test(file) ? (
                              <video
                                width={
                                  question.file_width && question.file_width > 0
                                    ? question.file_width
                                    : "100%"
                                }
                                height={
                                  question.file_height && question.file_height > 0
                                    ? question.file_height
                                    : "100%"
                                }
                                controls
                              >
                                <source src={file} type={"video/mp4"} />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img
                                className="object-cover mx-auto"
                                src={file}
                                width={
                                  question.file_width && question.file_width > 0
                                    ? question.file_width
                                    : "100%"
                                }
                                height={
                                  question.file_height && question.file_height > 0
                                    ? question.file_height
                                    : "100%"
                                }
                              />
                            )}
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                )}
                {question.question_type === "AboutFile" && (
                  <div>
                    <TextAreaFieldControlled
                      id={tool.id + question.question_type + question.id}
                      name={tool.id + question.question_type + question.id}
                      control={control}
                    />
                  </div>
                )}
                {question.question_type === "Matrix" && (
                  <div>
                    {question.question.rows.map((v, i) => (
                      <div key={"M" + v}>
                        <Typography className="pt-4 pb-8 text-center" variant="body1">
                          {v}
                        </Typography>
                        <RadioButtonControlled
                          control={control}
                          radioOptions={question.question.columns.map((v) => {
                            return { label: v.name, value: v.name };
                          })}
                          id={tool.id + question.question_type + question.id + i}
                          name={tool.id + question.question_type + question.id + i}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {question.question_type === "Radio" && (
                  <div>
                    <RadioButtonControlled
                      control={control}
                      radioOptions={question.question.options.map((v) => {
                        return {
                          label: v.name,
                          value: v.name,
                          file: v?.file,
                          is_incorrect: v.is_incorrect,
                        };
                      })}
                      id={tool.id + question.question_type + question.id}
                      name={tool.id + question.question_type + question.id}
                    />
                    {question.question.add_other_field && (
                      <div>
                        {watch(tool.id + question.question_type + question.id) === "Otros" && (
                          <TextFieldControlled
                            control={control}
                            id="otherInput"
                            name="otherInput"
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
                {question.question_type === "Binary" && (
                  <div>
                    <RadioButtonControlled
                      control={control}
                      radioOptions={[
                        {
                          label: "Si",
                          value: "Si",
                        },
                        {
                          label: "No",
                          value: "No",
                        },
                      ]}
                      id={tool.id + question.question_type + question.id}
                      name={tool.id + question.question_type + question.id}
                    />
                  </div>
                )}
                {question.question_type === "Checkbox" && (
                  <div>
                    {question.question.options.map((v, i) => (
                      <div className="mb-2">
                        <CheckboxControlled
                          control={control}
                          id={tool.id + question.question_type + question.id + v.real_key}
                          name={tool.id + question.question_type + question.id + v.real_key}
                          label={v.name}
                          key={
                            "check" +
                            tool.id +
                            question.question_type +
                            question.id +
                            v.real_key +
                            i
                          }
                        />
                        {v.file && (
                          <div className="mt-2 mb-3">
                            <img src={v?.file} height="150px" width="150px" />
                          </div>
                        )}
                      </div>
                    ))}
                    {question.question.add_other_field && (
                      <div>
                        {watch(tool.id + question.question_type + question.id + "Otros") && (
                          <TextFieldControlled
                            control={control}
                            id="otherInput"
                            name="otherInput"
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
                {question.question_type === "Date" && (
                  <div>
                    <DatePickerFieldControlled
                      id={tool.id + question.question_type + question.id}
                      name={tool.id + question.question_type + question.id}
                      label=""
                      control={control}
                    />
                  </div>
                )}
                {question.question_type === "Text" && (
                  <div>
                    <TextAreaFieldControlled
                      id={tool.id + question.question_type + question.id}
                      name={tool.id + question.question_type + question.id}
                      label=""
                      control={control}
                    />
                  </div>
                )}
                {question.question_type === "File" && (
                  <div>
                    <InputFileControlled
                      id={tool.id + question.question_type + question.id}
                      name={tool.id + question.question_type + question.id}
                      label=""
                      control={control}
                    />
                  </div>
                )}
                {question.question_type === "Range" && (
                  <div className="text-center">
                    <div className="flex items-center justify-between">
                      <Typography variant="body2">{question.question.label_start}</Typography>
                      <Typography variant="body2">{question.question.label_end}</Typography>
                    </div>
                    <Rangefield
                      id={tool.id + question.question_type + question.id}
                      name={tool.id + question.question_type + question.id}
                      marks={[
                        { value: question.question.value_start },
                        { value: question.question.value_end },
                      ]}
                      min={question.question.value_start}
                      max={question.question.value_end}
                      defaultValue={
                        (question.question.value_start + question.question.value_end) / 2
                      }
                    />
                  </div>
                )}
                {question.question_type === "Mask" && (
                  <div className="text-center">
                    {question.question.type_mask === "text" && (
                      <Typography variant="body2">{question.question.text}</Typography>
                    )}
                    {question.question.type_mask === "url" && (
                      <a href={question.question.url} target="_blank">
                        <Typography variant="body2">{question.question.url}</Typography>
                      </a>
                    )}
                  </div>
                )}
                {question.question_type === "Ranking" && (
                  <div>
                    <Typography>1. Marca y/o desmarca los items deseados</Typography>
                    <div className="flex space-x-4 overflow-x-auto max-w-[400px] mt-2 mb-3 py-2">
                      {question.question.options.map((option, index) => (
                        <Typography
                          variant="body2"
                          key={index}
                          className={`border rounded-xl py-2 px-4 hover:bg-primary cursor-pointer ${
                            listPrevRanking.includes(option.name) ? "bg-primary" : ""
                          }`}
                          onClick={() => hanldeSelectPrevRanking(option.name, question.id)}
                        >
                          {option.name}
                        </Typography>
                      ))}
                    </div>
                    <Typography>2. Arrastra los items en el orden deseado</Typography>
                    <ul className="mt-2 flex flex-col gap-y-3 max-h-[250px] overflow-y-auto max-w-[400px]">
                      {listPrevRanking.map((item, index) => (
                        <li
                          key={index}
                          draggable
                          onDragStart={() => handleDragStart(index)}
                          onDragEnd={handleDragEnd}
                          onDragOver={(e) => handleDragOver(e, index)}
                          className={`border rounded-xl py-2 px-4 flex items-center justify-between ${
                            draggedIndex == index ? "cursor-grabbing" : "cursor-grab"
                          }`}
                        >
                          <Typography>{item}</Typography>
                          <IconDots />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="text-center mt-4 space-x-4 ">
                  {i !== 0 || indexTool !== 0 ? (
                    <DefaultButton variant="secondary" color="primary" onClick={() => handleBack()}>
                      Atras
                    </DefaultButton>
                  ) : (
                    ""
                  )}
                  <DefaultButton
                    variant="primary"
                    color="secondary"
                    onClick={() =>
                      handleNext(
                        question,
                        tool.id,
                        tool.questions.length - 1 === i && indexTool == tools.length - 1
                      )
                    }
                  >
                    {tool.questions.length - 1 === i && indexTool == tools.length - 1
                      ? "Finalizar"
                      : "Siguiente"}
                  </DefaultButton>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      {error && (
        <Typography className="pt-4 pb-8 text-center text-danger" variant="body2">
          {error}
        </Typography>
      )}
    </StyledContent>
  );
};

export default PreviewTool;
