import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useLegalServices = () => {
  const { useLegalProvider } = useProviders();
  const {
    listLegalProvider,
    createLegalProvider,
    editLegalProvider,
  } = useLegalProvider();

  const listLegalService = ({ page, params }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listLegalProvider({ page, params })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const createLegalService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createLegalProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const editLegalService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editLegalProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listLegalService,
    createLegalService,
    editLegalService,
  };
};

export default useLegalServices;
