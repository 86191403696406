import axios from "axios";

const usePromptsProvider = () => {
  // Get Prompts lists
  const listPromptsProvider = (params) => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/prompts`,
      params: params,
    });
  };

  // Get Prompt by id
  const getPromptProvider = (id) => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/prompt/${id}`,
    });
  };

  // update Prompts by id
  const updatePromptProvider = (data) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/prompt/edit`,
      data,
    });
  };

  // Create Prompt
  const createPromptProvider = (data) => {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/prompt`,
      data,
    });
  };

  // Remove Prompt
  const deletePromptProvider = (id) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/prompt/${id}`,
    });
  };

  const getPromptCategoryProvider = () => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/promptcategories`,
    });
  };

  const createCategoryPromptProvider = (data) => {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/promptcategories`,
      data,
    });
  }

  return {
    listPromptsProvider,
    getPromptProvider,
    updatePromptProvider,
    createPromptProvider,
    deletePromptProvider,
    getPromptCategoryProvider,
    createCategoryPromptProvider
  };
};

export default usePromptsProvider;
