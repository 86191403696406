// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import {
  StyledFormControl,
  StyledTextArea,
  StyledHelperText,
  StyledMaxLength,
} from './TextAreaFieldStyles';

// Components
import useComponents from '../';

const TextAreaField = (props) => {
  const {
    disabled,
    error,
    helperText,
    id,
    inputLength,
    label,
    maxLength,
    maxRows,
    minRows,
    placeholder,
    tooltipTitle,
    resize,
    fullHeight,
    required,
    ...rest
  } = props;
  const { FormLabel } = useComponents();

  return (
    <StyledFormControl fullWidth fullHeight>
      <label>
        <FormLabel
          label={ label }
          tooltipTitle={ tooltipTitle }
        />
      </label>
      <StyledTextArea
        className={ error ? "Mui-error" : ""}
        placeholder={ placeholder }
        maxRows={maxRows ?? 4}
        minRows={minRows ?? 4}
        disabled={disabled}
        autoComplete="off"
        resize={resize}
        fullHeight
        required={required}
        { ...rest }
      />
      {error &&
        <>
          {helperText ?
            <StyledHelperText>
              { helperText }
            </StyledHelperText>
          :
            <StyledHelperText>
              { error?.message }
            </StyledHelperText>
          }
        </>
      }
      {
        maxLength !== undefined
        ? <StyledMaxLength maxLengthReached={maxLength - inputLength === 0}>
            {inputLength}/{maxLength}
          </StyledMaxLength>
        : null
      }
    </StyledFormControl>
  )
}

TextAreaField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.any,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputLength: PropTypes.number,
  label: PropTypes.string, 
  maxLength: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  placeholder: PropTypes.string,
  resize: PropTypes.string,
  tooltipTitle: PropTypes.string,
  fullHeight: PropTypes.bool,
  required: PropTypes.bool,
}

export default TextAreaField;