// Packages
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver  } from '@hookform/resolvers/yup';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useParams, useLocation } from 'react-router-dom';

// Helpers
import useHelpers from "../../../../../helpers";

// Actions
import useActions from '../../../../../api/actions';

const usePassiveTrackingCreate  = () => {
  const [showModal, setShowModal] = useState(false)
  const { id_passive_tracking } = useParams();
  const location = useLocation();

  // Helpers
  const { useQuickFunctions, } = useHelpers();
  const { useValidators, useGeneral, useNavigation, } = useQuickFunctions();
  const { navigateTo } = useNavigation();
  const { passiveTrackingCreateShema } = useValidators();
  const { dateFormatUTC, getAmPmFormat } = useGeneral();

  // Actions
  const { dispatch, useToolsActions, } = useActions();
  const { actAddPassiveTracking, actGetPassiveTrackingSteps, actUpdatePassiveTracking } = useToolsActions();

  const countString = useMemo(() => 
    ["Uno", "Dos", "Tres", "Cuatro", "Cinco", "Seis", "Siete", "Ocho", "Nueve", "Diez", "Once", "Doce", "Trece", "Catorce", "Quince", "	Dieciséis", "Diecisiete", "Dieciocho", "Diecinueve", "Veinte"], 
  [])

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(passiveTrackingCreateShema),
    defaultValues:({
      steps: [{
        passive_tracking_id: 0,
        address: "",
        name: `Paso ${countString[0]}`,
        id: 1,
        lat: "",
        lng: "",
        init_date: "",
        end_date: "",
        init_time: "",
        end_time: "",
        init_AmPm: "am",
        end_AmPm: "am",
      }]
    })
  });


  useEffect(() => {
    const onSuccess = ({steps}) => {
      const stepsReduce = steps?.reduce(
        (previousValue, currentValue) => (
          [...previousValue, {
            name: currentValue.name,
            passive_tracking_id: currentValue.passive_tracking_id,
            address: currentValue.address,
            id: currentValue.id,
            lat: currentValue.lat,
            lng: currentValue.lng,
            init_date: currentValue.init_date,
            end_date: currentValue.end_date,
            init_time: currentValue.init_date,
            end_time: currentValue.end_date,
            init_AmPm: getAmPmFormat(currentValue.init_date),
            end_AmPm: getAmPmFormat(currentValue.init_date),
          }]
        ),[]
      );

      reset({
        steps: stepsReduce
      })
    }
    const onError = (error) => {
      console.log(error);
    }
    
    if(location.pathname.includes("editar")) {
      dispatch(actGetPassiveTrackingSteps(id_passive_tracking, onSuccess, onError));
    }
  }, [id_passive_tracking, location]);

  const { fields, insert, remove } = useFieldArray({
    control,
    name: "steps"
  })

  const handleRemoveStep = useCallback((position) => {
    remove(position)
  }, []);

  // Add step
  const handleAddStep = useCallback((position, id) => {
    insert(position, {
      passive_tracking_id: 0,
      name: `Paso ${countString[position]}`,
      address: "",
      id: id,
      lat: "",
      lng: "",
      init_date: "",
      end_date: "",
      init_time: "",
      end_time: "",
      init_AmPm: "am",
      end_AmPm: "am",
    });
  }, []);


  // Handle select address
  const handleSelectAddress = useCallback((address, index) => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setValue(`steps[${index}].address`, address);
        setValue(`steps[${index}].lat`, latLng.lat);
        setValue(`steps[${index}].lng`, latLng.lng);
      })
      .catch(error => console.error('Error', error));
  }, [countString]);

  const onSubmit = useCallback((formData) => {
    const onSuccess = () => {
      setShowModal(true)
    }

    const onError = (error) => {
      console.log(error);
    }
    
    // returns a clean array for service 
    const steps = formData.steps?.reduce(
      (previousValue, currentValue, index) => (
        [...previousValue, {
          "name": `Paso ${countString[index]}`,
          "address": currentValue.address,
          "lat": currentValue.lat,
          "lng": currentValue.lng,
          "passive_tracking_id": id_passive_tracking,
          "init_date": dateFormatUTC([currentValue.init_date, currentValue.init_time,  currentValue.init_AmPm]),
          "end_date": dateFormatUTC([currentValue.end_date, currentValue.end_time,  currentValue.end_AmPm])
        }]
      ),[]
    );
    
    if(location.pathname.includes("editar")) {
      dispatch(actUpdatePassiveTracking(id_passive_tracking,{ steps: steps }, onSuccess, onError))
    } else {
      dispatch(actAddPassiveTracking({ steps: steps }, onSuccess, onError))
    }
  }, [id_passive_tracking])

  const handleCloseModal = () => {
    setShowModal(false)
    navigateTo(`/herramientas/passive-tracking/${id_passive_tracking}/reporte`)
  };

  return {
    control,
    handleSubmit,
    fields,
    handleAddStep,
    handleRemoveStep,
    countString,
    handleSelectAddress,
    onSubmit,
    showModal,
    handleCloseModal,
    getValues,
    id_passive_tracking,
  }
}

export default usePassiveTrackingCreate;
