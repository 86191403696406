// Packages
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledContent = styled.div.attrs({
  className: "StyledContent"
})`
  ${tw`bg-white shadow relative mx-auto p-6`}
    min-height: 350px;
    border-radius: 10px;
    width: 450px;
`