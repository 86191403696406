// Packages
import { useState, useEffect, useCallback, } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

const useUsersView = () => {
  // State
  const [listUsers, setListUses] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [listRoles, setListRoles] = useState([])
  const [searchText, setSearchText] = useState("");
  const [selectFilter, setSelectFilter] = useState(null);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });
  const navigation = useNavigate();

  // Actions
  const { useActions } = useApi();
  const { useUsersActions, dispatch, useGeneralActions } = useActions();
  const { actListUsers, actSelectUser, actListRoles, actDeleteUsers } = useUsersActions();
  const { actListCountries } = useGeneralActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { FilterUserValidationShema } = useValidators();

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(FilterUserValidationShema),
  });
  
  const onSubmit = (data, e) => {
    e.preventDefault();
    setSelectFilter(data)
    actListUsers({ page: 1, search: searchText, country_id: data.abiFilterCountry, rol_id: data.abiFilterRol, status: data.abiFilterStatus }, loadData);
  };

  const loadData = (data) => {
    setListUses(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };
  const loadDataPagination = (data) => {
    setListUses(data.data);
  };

  const handleCreateNewUser = () => {
    navigation("/usuarios/creacion");
  };
  const handleEditUser = (user) => {
    dispatch(actSelectUser({ user }));
    navigation(`/usuarios/editar/${user.id}`);
  };
  const prepareDataCountry = (data) => {
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const handlePagination = (nro) => {
    actListUsers({ page: nro.selected + 1, search: searchText, country_id: selectFilter?.abiFilterCountry, rol_id: selectFilter?.abiFilterRol, status: selectFilter?.abiFilterStatus }, loadDataPagination);
  };

  const onSubmitSearch = (e) => {
    console.log(e)
    setSearchText(e)
    actListUsers({ page: 1, search: e }, loadData);
  };
  const onChangeSearch = useCallback(
    debounce(onSubmitSearch, 750)
  , []);
  const loadDatarol = data => {
    setListRoles(data.map(v => {return {label: v.name, value: v.id}}))
  }

  useEffect(() => {
    actListUsers({ page: 1 }, loadData);
    actListCountries(prepareDataCountry);
    actListRoles(loadDatarol)
  }, []);

  const handleDeleteUser = (id, name) => {
    setModalDelete({ show: true, name: name, id: id });
  };

  
  const handleConfirmDelete = () => {
    const onSuccessDelete = () => {
      actListUsers({ page: 1 }, loadData);
    }
    actDeleteUsers(modalDelete.id, onSuccessDelete);
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handleCloseModal = () => {
    setModalDelete({ show: false, name: "", id: 0 })
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    listUsers,
    handleCreateNewUser,
    handleEditUser,
    listCountries,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    listRoles,
    handleDeleteUser,
    modalDelete,
    handleCloseModal,
    handleConfirmDelete,
  };
};

export default useUsersView;
