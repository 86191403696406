// Packages
import React from "react";

// Styles
import { StyledContentButtons } from "./MainTools.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Helpers
import useHelpers from "../../../../helpers";

const QuestionsMain = () => {
  // Components
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    Typography,
    Badge,
    Pagination,
    SelectFieldControlled,
    useModals,
    Dropdown,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useQuestions } = useScreenHooks();
  const { useQuestionsMain } = useQuestions();
  const {
    control,
    handleSubmit,
    onSubmit,
    toolsList,
    paginationSettings,
    handlePagination,
    handleDeleteQuestion,
    handleEditQuestion,
    handleCloneQuestion,
    modalDelete,
    handleCloseModalDelete,
    handleConfirmDelete,
  } = useQuestionsMain();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} contentWidth="90%" as="section">
        <div className="px-8 pt-10 pb-12 bg-white rounded-lg">
          <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextFieldSeach placeholder="Buscar" name="type" id="type" control={control} />
            </form>
            <StyledContentButtons>
              <DefaultButton
                color="secondary"
                onClick={() => navigateTo("/qualitychecks/creacion")}
              >
                Crear pregunta
              </DefaultButton>
            </StyledContentButtons>
          </ContentWrappers>
          <TableContent
            headerCellList={["id", "Titulo", "Tipo de pregunta", "Acciones"]}
            gridCell={["1fr", "1fr", "1fr", "80px"]}
          >
            {toolsList?.map((tool, index) => {
              return (
                <TableRow key={index}>
                  <div> {tool.id}</div>
                  <div>
                    <strong>{tool.title}</strong>
                  </div>
                  <div> {tool.question_type} </div>
                  <div className="flex justify-end">
                    <Dropdown
                      options={[
                        {
                          id: "edit",
                          action: () => handleEditQuestion(tool.id),
                        },
                        {
                          id: "clone",
                          action: () => handleCloneQuestion(tool.id),
                        },
                        { id: "delete", action: () => handleDeleteQuestion(tool.id, tool.name) },
                      ]}
                    />
                  </div>
                </TableRow>
              );
            })}
          </TableContent>
        </div>
        <div>
          <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
        </div>
      </ContentWrappers>

      <DefaultModal isOpen={modalDelete.show}>
        <div className="flex flex-col items-center space-y-8">
          <Typography variant="body2" className="text-center">
            Esta seguro de eliminar la pregunta.
          </Typography>
          <div className="flex items-center space-x-4">
            <DefaultButton color="secondary" onClick={handleCloseModalDelete}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="success" onClick={handleConfirmDelete}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

export default QuestionsMain;
