import useApi from "../../index";
// import useTypes from "../../../strings/types";

const useNotificationsActions = () => {
  //Services
  const { useServices } = useApi();
  const { useNotificationsServices } = useServices();
  const { listNotificationsService, addNotificationService, addRemindersService, getNotificationService,
  cloneNotificationService, editNotificationService, deleteNotificationService, getMembeerGroupsService, 
  getNotificationsEntitiesService, getNotificationsEntitiesByIdService } = useNotificationsServices();

  //Strings
  // const { useNotificationsTypes } = useTypes();
  // const { LIST_NOTIFICATIONS, ADD_NOTIFICATION } = useNotificationsTypes();

  const actListNotifications = async ({page, search, state, type}, onSuccess, onError) => {        
    try {
      const response = await listNotificationsService({page, search, state, type});
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  }

  const actGetNotification = async (id, onSuccess, onError ) => {
    try {
      const response = await getNotificationService(id);
      onSuccess && onSuccess(response.data.data);      
    } catch(error) {
      console.log(error);
      onError && onError();
    } 
  }

  const actGetMembeerGroups = async (onSuccess, onError ) => {
    try {
      const response = await getMembeerGroupsService();
      onSuccess && onSuccess(response.data.data);      
    } catch(error) {
      console.log(error);
      onError && onError();
    } 
  }

  const actGetNotificationsEntities = async (onSuccess, onError ) => {
    try {
      const response = await getNotificationsEntitiesService();
      onSuccess && onSuccess(response.data.data);      
    } catch(error) {
      console.log(error);
      onError && onError();
    } 
  }

  const actGetNotificationsEntitiesById = async (id, onSuccess, onError ) => {    
    try {
      const response = await getNotificationsEntitiesByIdService(id);
      onSuccess && onSuccess(response.data.data);      
    } catch(error) {
      console.log(error);
      onError && onError();
    } 
  }

  const actAddEditNotification = async ({id, datos}, onSuccess, onError) => {        
    try {
      const response = id 
        ? await editNotificationService({id, datos}) 
        : await addNotificationService(datos);
  
      onSuccess && onSuccess(response);
    } catch (error) {
      onError && onError();
    }
  }
  

  const actCloneNotification = async ({ id }, onSuccess, onError) => {
    try {
      const response = await cloneNotificationService({ id });
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };


  const actDeleteNotification = async (id, onSuccess, onError) => {    
    try {
      const response = await deleteNotificationService(id);
      onSuccess && onSuccess(response);
    } catch (error) {
      onError && onError();
    }
  };

  return { actListNotifications, actAddEditNotification, actGetNotification, 
    actCloneNotification, actDeleteNotification, actGetMembeerGroups, 
    actGetNotificationsEntities, actGetNotificationsEntitiesById };

};

export default useNotificationsActions;