// Packages
import styled from 'styled-components';
import tw from 'twin.macro';

// Components
import ContentWrappers from '../../../../../components/ContentWrappers';

export const StyledConditionalsHeader = styled(ContentWrappers) `
  ${tw`pb-6 border-b border-neutral-60 border-solid mb-8`}
`;

export const StyledConditionalsHeaderItems = styled.div.attrs({
  className: "StyledConditionalsHeaderItems"
})`
  h5 {
    ${tw`text-neutral-90`}
  }
`

export const StyledConditionalsContent = styled.div.attrs({
  className: "StyledConditionalsContent"
})`
  ${tw`px-8`}

  > h2 {
    ${tw`mb-4`}
  }
  .MuiAccordionSummary-content {
    div {
      ${tw`flex items-center`}
    }
    span {
      ${tw`bg-third-50 border border-third-200 rounded-full flex justify-center items-center mr-2 text-third-600 text-sm`}
      width: 40px;
      height: 40px;
    }
  }
`

export const StyledAccordionQuestion = styled.div.attrs({
  className: "StyledAccordionQuestion"
})`
  ${tw`flex ml-8`}
  
  .MuiPaper-root {
    flex: 1;
  }
  > span {
    ${tw`flex justify-center items-center bg-third rounded-tl rounded-bl text-xs`}
    margin-top: 3px;
    height: 32px;
    width: 32px;
  }
`

export const StyledQuestionList = styled.ol.attrs({
  className: "StyledQuestionList"
})`
  list-style-type: upper-alpha;
  padding-left: 23px;
`

export const StyledSelect = styled.li.attrs({
  className: "StyledSelect"
})`
  ${tw`text-neutral-80`}

  > div {
    ${tw`flex justify-between items-center`}

    span {
      ${tw`text-neutral-80 text-sm font-light`}
      letter-spacing: -0.006em;
    }

    > div {
      ${tw`flex`}

      > div {
        ${tw`ml-4`}
        margin-bottom: 0!important;
        width: 160px;
      }
    }
  }
`