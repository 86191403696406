// Packages
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";
import useControllers from "../../..";

const useProfilerView = () => {
  const listProfilersType = [
    {
      type: 0,
      title: "Perfilación por integración de licencias por país",
      columnsTitle: [
        "País",
        "Licencias disponibles",
        "licencias ocupadas",
        "Licencias no ocupadas",
        "Acciones",
      ],
      cellSize: ["1fr", "2fr", "2fr", "2fr", ".5fr"],
      url: "integrations",
    },
    {
      type: 1,
      title: "Perfilación por región",
      columnsTitle: ["Región", "País", "Licencias", "Acciones"],
      cellSize: ["1fr", "1fr", "5fr", ".5fr"],
      url: "regions",
    },
    {
      type: 2,
      title: "Perfilación por región ABI",
      columnsTitle: ["Región ABI", "País", "Licencias", "Acciones"],
      cellSize: ["1fr", "1fr", "3fr", ".5fr"],
      url: "abi-regions",
    },
    {
      type: 3,
      title: "Perfilación por región ABI Auxiliar",
      columnsTitle: ["Región ABI Auxiliar", "País", "Licencias", "Acciones"],
      cellSize: ["1fr", "1fr", "2fr", ".5fr"],
      url: "aux-abi-regions",
    },
    {
      type: 4,
      title: "Perfilación por edad",
      columnsTitle: [
        "Edad",
        "Región ABI o ABI Auxiliar",
        "País",
        "Licencias",
        "Acciones",
      ],
      cellSize: ["1fr", "2fr", "1fr", "3fr", "1fr"],
      url: "ages",
    },
    {
      type: 5,
      title: "Perfilación por Género",
      columnsTitle: [
        "Genero",
        "Región ABI o ABI Auxiliar",
        "País",
        "Licencias",
        "Acciones",
      ],
      cellSize: [".6fr", "2fr", "1fr", "3fr", "1fr"],
      url: "gender",
    },
    {
      type: 6,
      title: "Perfilación por NSE",
      columnsTitle: [
        "NSE",
        "Rango",
        "Región ABI o ABI Auxiliar",
        "País",
        "Licencias",
        "Acciones",
      ],
      cellSize: ["1fr", "1fr", "3fr", "1fr", "2fr", "1fr"],
      url: "nse-ranges",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [listProfilers, setListProfilers] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectFilter, setSelectFilter] = useState(null);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const navigation = useNavigate();

  let expandedSearch = null;

  const { useActions } = useApi();
  const { useProfilerActions, useGeneralActions } = useActions();
  const { actListProfilers } =
    useProfilerActions();
  const { actExportExel } = useGeneralActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const handleCreateNewPerfilacion = (perfilacion) => {
    navigation("/perfilacion/creacion", { type: perfilacion.type });
  };

  const handleClonePerfilacion = (type) => {
    navigation(`/perfilacion/creacion/${type}`);
  };

  const handleEditPerfilacion = (id, type) => {
    navigation(`/perfilacion/editar/${id}`, { type: type });
  };

  const { control, handleSubmit } = useForm({
    mode: "all",
    // resolver: yupResolver(filterNotificationValidationShema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    // actListNotifications({page: 1, search: searchText, state: data.status, type: data.type}, loadData);
  };

  const handleDownloadExcel = () => {
    const filters = {
      search: searchText,
    };

    const filtersProcces = prepareDataFilterDownload(filters);

    actExportExel(`profiling-${listProfilersType[expanded].url}`, filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  const handlePagination = (nro) => {
    setPaginationSettings({ ...paginationSettings, current: nro.selected + 1 });
    actListProfilers(listProfilersType[expanded].url, { page: nro.selected + 1 }, loadData);
  };

  const onSubmitSearch = async (e) => {    
    setLoading(true);
    setSearchText(e);
    if(expandedSearch >= 0) {
      await actListProfilers(
        listProfilersType[expandedSearch].url,
        { page: paginationSettings.current },
        loadData
      );
    }
    setLoading(false);
  };

  const onChangeSearch = useCallback(debounce(onSubmitSearch, 750), []);

  const loadData = (data) => {   
    setListProfilers(
      data.data.map((x) => {
        return {
          ...x,
          region: x.region ? (typeof(x.region) === 'object' ? x.region.region : x.region) : "",
          abi_region: x.abi_region
            ? typeof(x.abi_region) === 'object'
              ? x.abi_region.abi_region
              : x.abi_region
            : "",
        };
      })
    );
    
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };

  const onExpand = async (e, profileType) => {
    if (profileType === expanded) return;
    setLoading(true);
    setPaginationSettings({ ...paginationSettings, page: 1 });
    setExpanded(profileType);
    expandedSearch = profileType;
    await actListProfilers(listProfilersType[profileType].url, { page: 1 }, loadData);
    setLoading(false);
  };

  return {
    control,
    listProfilersType,
    listProfilers,
    listCountries,
    loading,
    handleCreateNewPerfilacion,
    handleClonePerfilacion,
    handleEditPerfilacion,
    handleDownloadExcel,
    handleSubmit,
    onSubmit,
    handlePagination,
    paginationSettings,
    onSubmitSearch,
    onChangeSearch,
    onExpand,
    expanded,
  };
};

export default useProfilerView;
