import useApi from "../../index";

const useMembeersActions = () => {
  const { useServices } = useApi();
  const { useMembeersServices } = useServices();
  const {
    listMembeersService,
    changeStateMembeersService,
    inactiveMembeersService,
    deleteMembeersService,
    listMembeersWaitingService,
    acceptMembeersService,
  } = useMembeersServices();

  const actListMembeers = async ({ page, params }, onSuccess, onError) => {
    try {
      const response = await listMembeersService({ page, params });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };
  
  const actListMembeersWaiting = async ({ page, params }, onSuccess, onError) => {
    try {
      const response = await listMembeersWaitingService({ page, params });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actChangeStateMembeer = async (id, state, onSuccess, onError) => {
    try {
      const response = await changeStateMembeersService(id, state);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actInactiveMembeers = async (data, onSuccess, onError) => {
    try {
      const response = await inactiveMembeersService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };
  
  const actActiveMembeers = async (data, onSuccess, onError) => {
    try {
      const response = await acceptMembeersService(data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actDeleteMembeers = async (id, onSuccess, onError) => {
    try {
      const response = await deleteMembeersService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  return {
    actListMembeers,
    actChangeStateMembeer,
    actInactiveMembeers,
    actDeleteMembeers,
    actListMembeersWaiting,
    actActiveMembeers,
  };
};

export default useMembeersActions;
