// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledInputSwitchContent = styled.div.attrs({
  className: "StyledInputSwitchContent",
})`
  ${tw`flex items-center space-x-2 mt-2`}
`;

export const StyledInputSwitch = styled.label.attrs({
  className: "StyledInputSwitch",
})`
  ${tw`relative flex items-center`}
  height: 28px;
  padding-left: 56px;

  input {
    ${tw`hidden`}
  }

  input:checked + .StyledInputSwitchSpan {
    ${tw`bg-success`}
  }

  input:focus + .StyledInputSwitchSpan {
    box-shadow: 0 0 1px #ffc600;
  }

  input:checked + .StyledInputSwitchSpan:before {
    background-color: white;
    border: 1px solid #018145;
    transform: translateX(27px);
  }
`;

export const StyledInputSwitchSpan = styled.span.attrs({
  className: "StyledInputSwitchSpan",
})`
  ${tw`absolute cursor-pointer inset-0 bg-neutral-40`}
  border: 1px solid #DAE0E8;
  transition: 0.4s;
  border-radius: 34px;
  max-width: 56px;
  height: 28px;

  :before {
    border: 1px solid #dae0e8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const StyledInputSwitchText = styled.span.attrs({
  className: "StyledInputSwitchText",
})`
  ${tw`text-sm text-neutral font-light ml-4`}
  letter-spacing: -0.006em;
`;
