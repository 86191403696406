// Steps controllers
import usePasswordForgot from './Forgot'
import usePasswordRecovery from './Recovery'

const usePassword = () => {

  return {
    usePasswordForgot,
    usePasswordRecovery,
  };
};

export default usePassword;
