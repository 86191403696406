// Packages
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

// Helpers
import useHelpers from '../../../../../helpers';

// Actions
import useActions from '../../../../../api/actions';

import useControllers from "../../../../";

const useCheckpointReport = () => {
  const { id_passive_tracking, id_step } = useParams();
  const [ trackingList, setTrackingList ] = useState([])
  const [ membeersRegister, setMembeersRegister ] = useState(0)
  const [titleMembeer, setTitleMembeer] = useState({});

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { filtersValidationShema } = useValidators();

  // Actions
  const { dispatch, useToolsActions, useGeneralActions } = useActions();
  const { actGetPassiveTrackingPagiAnswers, actGetPassiveTrackingSteps } = useToolsActions();
  const { actExportExel } = useGeneralActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(filtersValidationShema),
  });
  
  const onSubmit = useCallback(
    (data) => {
      //console.log (data)
    },
    []
  )

  useEffect(() => {
    const onSuccess = (data) => {
      const {  mission, steps } = data;

      setTitleMembeer({
        tool_name: mission[0]?.name,
        mission_name: mission[0]?.mission_name,
        countries: mission[0]?.countries.reduce((prev, curr) => prev + curr.name + " ", ""),
        /* end_date: mision[0]?.end_date,
        init_date: mision[0]?.init_date, */
        step: steps.find(v => v.id == id_step)
      });

    };

    const onError = (error) => {
      console.log(error);
    };

    dispatch(actGetPassiveTrackingSteps(id_passive_tracking, onSuccess, onError));

    const onSuccessAnswers = (answerData) => {
      const { data, registered_members } = answerData;
      setTrackingList(data);
      setMembeersRegister(registered_members)
    }

    const onErrorAnswers = (error) => {
      console.log(error);
    }


    dispatch(actGetPassiveTrackingPagiAnswers({step: id_step, passive_tracking_id: id_passive_tracking}, onSuccessAnswers, onErrorAnswers ));
  }, [id_passive_tracking, id_step]);

  const handleDownloadExel = () => {
    const filters = {};

    const filtersProcces = prepareDataFilterDownload(filters);

    actExportExel(`passiveTrackingStep/${id_step}`, filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    trackingList,
    id_passive_tracking,
    handleDownloadExel,
    titleMembeer,
    membeersRegister,
  }
}

export default useCheckpointReport;