import { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";

// Steps controllers
import useNotificationView from "./Views";
import useNotificationCreate from "./Create";

const useNotification = () => {
  const navigation = useNavigate();
  const [headerKey, setHeaderKey] = useState("/notificaciones");

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/notificaciones": ["Notificaciones"],
    "/notificaciones/creacion": ["Notificaciones", "Crear notificación"],
    "/notificaciones/editar/:id": ["Notificaciones", "Edición de la notificación"],
  };

  const matches = _.mapValues(header, (item, pathname) => useMatch(pathname));

  useEffect(() => {
    const matchedKey = Object.keys(matches).find((pathname) => matches[pathname]?.pathname === pathname) || "/notificaciones";
    setHeaderKey(matchedKey);
  }, [matches]);

  return {
    handleBack,
    header,
    useNotificationCreate,
    useNotificationView,
  };
};

export default useNotification;
