const IconPlus = ({color = "#fff"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        d="M10 .88a1.2 1.2 0 00-1.2 1.2V8.8H2.08a1.2 1.2 0 100 2.4H8.8v6.72a1.2 1.2 0 002.4 0V11.2h6.72a1.2 1.2 0 100-2.4H11.2V2.08A1.2 1.2 0 0010 .88z"
      ></path>
    </svg>
  );
}

export default IconPlus;