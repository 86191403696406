import React from "react"
import PropTypes from "prop-types"


const Toast = ({ text, title, listOfErrors }) => {
  return (
    <div className="flex space-x-4 justify-around">
      <div className={"flex flex-col justify-center text-center"}>
        {title !== "" && <div>{title}</div>}
        <div>{text}</div>
        {listOfErrors.length > 0 &&
          listOfErrors.map((m, key) => <div key={key}>• {m}</div>)}
      </div>
    </div>
  )
}

Toast.defaultProps = {
  text: "",
  listOfErrors: [],
  title: "",
}

Toast.propTypes = {
  text: PropTypes.string,
  listOfErrors: PropTypes.array,
  title: PropTypes.string,
}

export default Toast
