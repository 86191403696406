const IconProject = ({color = "#B6B9BA"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="24"
      fill="none"
      viewBox="0 0 17 24"
    >
      <path
        fill={ color }
        d="M14.76 3.549h-3.44v-.327a.244.244 0 00-.246-.243h-1.019V1.96C10.055.88 9.177 0 8.098 0c-1.082 0-1.96.879-1.96 1.96V2.98h-1.02a.242.242 0 00-.242.243v.327h-3.44C.643 3.549 0 4.193 0 4.984v17.58C0 23.357.644 24 1.435 24H14.76c.792 0 1.435-.644 1.435-1.435V4.985c0-.792-.643-1.436-1.435-1.436zM8.098 1.322a.424.424 0 010 .847.423.423 0 010-.847zm-2.98 4.813h5.956a.243.243 0 00.245-.246v-.158h2.918v16.09H1.958V5.73h2.919v.158c0 .137.108.246.243.246z"
        opacity="0.4"
      ></path>
      <path
        fill={ color }
        d="M6.992 18.7H12.9a.244.244 0 100-.488H6.992a.244.244 0 000 .489zM6.992 10.243H12.9a.245.245 0 100-.49H6.992a.244.244 0 000 .49zM6.992 14.472H12.9a.245.245 0 000-.49H6.992a.244.244 0 000 .49z"
        opacity="0.4"
      ></path>
      <path
        fill={ color }
        d="M3.05 9.997a1.46 1.46 0 002.918 0A1.46 1.46 0 004.51 8.538a1.46 1.46 0 00-1.459 1.46zM4.51 12.768a1.46 1.46 0 00-1.46 1.459 1.46 1.46 0 002.918 0 1.46 1.46 0 00-1.459-1.46zM4.51 16.997a1.46 1.46 0 000 2.919 1.46 1.46 0 001.458-1.46 1.46 1.46 0 00-1.459-1.459z"
      ></path>
    </svg>
  );
}

export default IconProject;