import React from 'react';
import PropTypes from 'prop-types';
import { StyledTypography } from './Typography.styles';

const Typography = ({
  children,
  variant = 'body1',
  className,
  color,
  onClick = () => {},
  sx,
  bulledTitle = false,
  bulledColor = "#FFA700",
}) => {
  return (
    <StyledTypography
      bulledtitle={bulledTitle.toString()}
      bulledcolor={bulledColor}
      variant={variant}
      className={className}
      color={color}
      onClick={onClick}
      sx={sx}
    >
      {children}
    </StyledTypography>
  );
};

Typography.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  sx: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2',
  ]),
  bulledTitle: PropTypes.bool,
  bulledColor: PropTypes.string,
};

export default Typography;
