import React from 'react';

const TableProfilerRegion = (props) => {
  return (
    <>
      <div>{props.item.region}</div>
      <div>{props.item.country_name}</div>
      <div>{props.item.licenses}</div>      
    </>
  )
}

export default TableProfilerRegion;