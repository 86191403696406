// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";

const PromptForm = (props) => {
  // Components
  const {
    TextFieldControlled,
    TextAreaFieldControlled,
    SelectMultiFieldControlled,
    useButtons,
    Typography,
  } = useComponents();

  const { DefaultButton } = useButtons();
  const { IconPlus } = useIcons();

  const {
    currentPrompt,
    promptFormControl,
    handleCloseFormModal,
    promptCategories,
    promptSubmitForm,
    onSubmitPrompt,
    shouldAddCategory,
    handleShouldAddCategory,
    categoryControl,
    handleSubmitCategory,
    onSubmitCategory,
  } = props;

  // Controllers
  const { useScreenHooks } = useControllers();
  const { usePrompt } = useScreenHooks();
  const { usePromptsMain } = usePrompt();
  const {} = usePromptsMain();

  return (
    <div className="max-w-screen-xs max-w-lg rounded-xl text-start">
      <Typography variant="h3">{currentPrompt?.id ? "Editar Prompt" : "Nuevo Prompt"}</Typography>
      <form className="mt-5" onSubmit={promptSubmitForm(onSubmitPrompt)}>
        <TextFieldControlled
          label={"Nombre"}
          id={"name"}
          name={"name"}
          control={promptFormControl}
          required={true}
        />
        <TextAreaFieldControlled
          label={"Prompt"}
          id={"prompt"}
          name={"prompt"}
          control={promptFormControl}
          resize={"false"}
          required={true}
        />
        <div className="flex flex-row items-center">
          <DefaultButton color={shouldAddCategory ? "secondary" : "primary"} onClick={() => handleShouldAddCategory()}>
            {!shouldAddCategory ? <IconPlus /> : "X"}
          </DefaultButton>
          <SelectMultiFieldControlled
            label={"Categorias"}
            id={"categories"}
            name={"categories"}
            control={promptFormControl}
            options={promptCategories}
            limitTags={3}
            renderchecked={true}
            maxSelection={3}
          />
        </div>
        <div className="space-x-2 text-center">
          <DefaultButton color={"secondary"} rounded onClick={() => handleCloseFormModal()}>
            Cancelar
          </DefaultButton>
          <DefaultButton rounded type={"submit"}>
            {currentPrompt?.id ? "Guardar" : "Crear prompt"}
          </DefaultButton>
        </div>
      </form>
      {shouldAddCategory && (
        <form className="mt-4" onSubmit={handleSubmitCategory(onSubmitCategory)}>
          <TextFieldControlled
            label={"Nueva categoria"}
            id={"name"}
            name={"name"}
            control={categoryControl}
            placeholder={"categoria"}
          />
          <div className="flex justify-center">
            <DefaultButton rounded type={"submit"}>
              Agregar
            </DefaultButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default PromptForm;
