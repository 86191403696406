// Packages
import React from 'react';

// Components
import useComponents from '..';

// Controllers
import useControllers from '../../../controllers';

const UploadFileResponse = (props) => {
  const { control } = props;

  // Components
  const { TextFieldControlled, ContentWrappers, InputSwitch } = useComponents();

  // Controllers
  const { useComponentHooks } = useControllers();
  const { useQuestions } = useComponentHooks();
  const { useUploadFilesResponse } = useQuestions();
  const { handleShowFileTypes, showFileTypes } = useUploadFilesResponse();

  return (
    <>
      <InputSwitch
        className="mb-8 mt-1"
        name="typeFile"
        id="typeFile"
        label="Restringir tipos de archivo"
        defaultChecked={ true }
        onChange={ handleShowFileTypes }
      />
      {showFileTypes &&
        <TextFieldControlled 
          label="Tipos de archivo aceptados (separados por coma)"
          name="file_types"
          id="file_types"
          placeholder=".jpg, .png, .pdf"
          control={ control }
        />
      }
      <ContentWrappers
        gridColums={["1fr", "1fr"]}
        gap={{
          row: "0px",
          column: "31px"
        }}
      >
        <TextFieldControlled 
          label="Número máximo de archivos"
          name="max_files"
          placeholder="1"
          type="number"
          id="max_files"
          control={ control }
        />
        <TextFieldControlled 
          label="Tamaño máximo de archivo (Mb)"
          name="max_file_size"
          placeholder="1"
          type="number"
          id="max_file_size"
          control={ control }
        />
      </ContentWrappers>
    </>
  )
}

export default UploadFileResponse;