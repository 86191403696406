// Packages
import React from "react";
import PropTypes from "prop-types";

// Components
import TextResponse from "./TextResponse";
import MaskResponse from "./MaskResponse";
import UploadFileResponse from "./UploadFileResponse";
import MultipleChoiceResponse from "./MultipleChoiceResponse";
import RangeResponse from "./RangeResponse";
import DateResponse from "./DateResponse";
import AboutFileResponse from "./AboutFileResponse";
import MatrixResponse from "./MatrixResponse";
import BinaryResponse from "./BinaryResponse";

const Questions = (props) => {
  const { questionType, control, hideOptions, qualityChecks, typeSelection, setTypeSelection } =
    props;

  return (
    <div>
      {questionType === "Binary" && <BinaryResponse control={control} />}
      {questionType === "Text" && <TextResponse control={control} />}
      {questionType === "File" && <UploadFileResponse control={control} />}
      {questionType === "Checkbox" && (
        <MultipleChoiceResponse
          control={control}
          hideOptions={hideOptions}
          qualityChecks={qualityChecks}
        />
      )}
      {questionType === "Radio" && (
        <MultipleChoiceResponse
          control={control}
          type="radio"
          hideOptions={hideOptions}
          qualityChecks={qualityChecks}
        />
      )}
      {questionType === "Range" && (
        <RangeResponse
          control={control}
          typeSelection={typeSelection}
          setTypeSelection={setTypeSelection}
        />
      )}
      {questionType === "Date" && <DateResponse control={control} />}
      {questionType === "AboutFile" && <AboutFileResponse control={control} />}
      {questionType === "Matrix" && <MatrixResponse control={control} />}
      {questionType === "Mask" && <MaskResponse control={control} />}
      {questionType === "Ranking" && <MultipleChoiceResponse
          control={control}
          type="ranking"
          hideOptions={true}
          qualityChecks={qualityChecks}
        />}
    </div>
  );
};

Questions.propTypes = {
  questionType: PropTypes.string,
  hideOptions: PropTypes.bool,
  qualityChecks: PropTypes.bool,
};

export default Questions;
