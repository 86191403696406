import styled from "styled-components";
import tw from "twin.macro";

export const StyledFooter = styled.footer.attrs({
  className: "StyledFooter",
})`
  ${tw`w-full pt-12 px-8 pb-6 flex justify-between bg-neutral-40 items-center`}
`;

export const StyledFooterInfo = styled.div.attrs({
  className: "StyledFooterInfo",
})`
  ${tw`text-xs font-light text-neutral-80 flex items-center flex-col`}
`;

export const StyledFooterNav = styled.nav.attrs({
  className: "StyledFooterNav",
})`
  ul {
    ${tw`flex`}
  }
  li {
    ${tw`ml-9`}
  }
  a {
    ${tw`text-xs text-neutral-80`}
  }
`;

export const StyledImageBrand = styled.img.attrs({
  className: "StyledImageBrand",
})`
  ${tw` mb-1`}
`;
