const IconDelete = ({ color = "#898781" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98565 21.139L7.98883 20.136C8.2677 20.4147 8.6459 20.5714 9.04027 20.5714H14.9597C15.3541 20.5714 15.7323 20.4147 16.0111 20.1358C16.245 19.9021 16.3942 19.597 16.4352 19.2689L17.7723 8.5714H6.22761L7.56481 19.2689C7.60581 19.597 7.75502 19.9021 7.98883 20.1358L6.98565 21.139ZM9.04027 22.2857H14.9597C15.8087 22.2857 16.623 21.9485 17.2233 21.3481C17.7267 20.8447 18.048 20.1879 18.1363 19.4815L19.6661 7.24251C19.6917 7.03787 19.5321 6.85712 19.3258 6.85712H4.67409C4.46786 6.85712 4.3083 7.03787 4.33387 7.24251L5.86375 19.4815C5.95206 20.1879 6.27328 20.8447 6.77666 21.3481C7.37701 21.9485 8.19126 22.2857 9.04027 22.2857Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.80681 10.7157C10.2794 10.6886 10.6845 11.0498 10.7116 11.5224L11.0552 17.5224C11.0823 17.995 10.7211 18.4001 10.2484 18.4272C9.77583 18.4543 9.37076 18.0931 9.3437 17.6204L9.00008 11.6204C8.97302 11.1478 9.3342 10.7427 9.80681 10.7157ZM14.2001 10.7157C14.6727 10.7427 15.0339 11.1478 15.0068 11.6204L14.6632 17.6204C14.6361 18.0931 14.2311 18.4543 13.7584 18.4272C13.2858 18.4001 12.9246 17.995 12.9517 17.5224L13.2953 11.5224C13.3224 11.0498 13.7275 10.6886 14.2001 10.7157Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57935 4.34402C5.57935 3.87062 5.96311 3.48688 6.43649 3.48688H17.5702C18.0436 3.48688 18.4274 3.87062 18.4274 4.34402C18.4274 4.8174 18.0436 5.20116 17.5702 5.20116H6.43649C5.96311 5.20116 5.57935 4.8174 5.57935 4.34402Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.005 5.14286C11.5316 5.14377 11.1471 4.76074 11.1462 4.28734L11.1429 2.57306C11.1421 2.09968 11.5251 1.7152 11.9985 1.7143C12.4718 1.7134 12.8563 2.09642 12.8572 2.5698L12.8605 4.28409C12.8614 4.75749 12.4784 5.14197 12.005 5.14286Z"
        fill={color}
      />
    </svg>
  );
};

export default IconDelete;
