// Packages
import React from "react";

// Styles
import { StyledContentButtons } from "./MembeerReport.styles";

// Components
import useComponents from "../../../../components";

// Controllers
import useControllers from "../../../../../controllers";

// Helpers
import useHelpers from "../../../../../helpers";

// Assets
import useIcons from "../../../../../assets/icons";

const MembeerReport = () => {
  const { IconDownload } = useIcons();

  // Components
  const { ContentWrappers, TableContent, TableRow, useButtons, Typography, Badge } =
    useComponents();

  const { DefaultButton } = useButtons();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { usePassiveTracking } = useTools();
  const { useMembeerReport } = usePassiveTracking();
  const { membeerInfo, stepsList, infoPassiveTracking, handleDownloadExel } = useMembeerReport();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} contentWidth="1200px" as="section">
        <div className="bg-white px-8 pt-10 pb-12 rounded-lg">
          <div className="border-b border-solid border-netral-60 pb-6 mb-16">
            <div className="mb-4 uppercase">
              <Typography variant="h3">REPORTE DE {membeerInfo.name}</Typography>
            </div>
            <div>
              <ContentWrappers
                gridColums={["250px", "80px"]}
                gap={{
                  row: "0",
                  column: "50px",
                }}
                align="left"
              >
                <div>
                  <Typography variant="h5">PASSIVE TRACKING</Typography>
                  <Typography variant="caption">{infoPassiveTracking.mission[0]?.name}</Typography>
                </div>
                <div>
                  <Typography variant="h5">PAÍS</Typography>
                  <Typography variant="caption">
                    {infoPassiveTracking.mission[0]?.countries.reduce(
                      (prev, curr) => prev + curr.name + " ",
                      ""
                    )}
                  </Typography>
                </div>
              </ContentWrappers>
            </div>
          </div>
          <ContentWrappers gridColums={["1fr"]} className="mb-10">
            <StyledContentButtons>
              <DefaultButton color="info" onClick={handleDownloadExel}>
                Exportar CSV
              </DefaultButton>
            </StyledContentButtons>
          </ContentWrappers>
          <TableContent
            headerCellList={[
              "Pasos de tranckig",
              "Fecha de tranckig",
              "Estado",
              "Descargar foto o video",
            ]}
            gridCell={["197px", "201px", "1fr", "176px"]}
          >
            {infoPassiveTracking.steps?.map((stepInfo, index) => {
              const filterStep = stepsList.filter((step) => step.id === stepInfo.id);
              return (
                <TableRow key={index}>
                  <div> {stepInfo.name} </div>
                  {filterStep.length > 0 ? (
                    <>
                      <div> {filterStep[0]["date-answer"]} </div>
                      <div>
                        <Badge
                          disabled={false}
                          type="light"
                          variant={
                            filterStep[0].status === "Finalizado"
                              ? "success"
                              : filterStep[0].status === "Pendiente de revision" || filterStep[0].status === "Pendiente"
                              ? "info"
                              : "danger"
                          }
                          label={filterStep[0].status}
                        />
                      </div>
                      <div className="flex justify-center">
                        <a href={filterStep[0]["file-answer"]} target="_blank" download>
                          <IconDownload />
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div> </div>
                      <div>
                        <Badge
                          disabled={false}
                          type="light"
                          variant={"danger"}
                          label={"No cumplido"}
                        />
                      </div>
                      <div className="flex justify-center">
                        <button type="button"></button>
                      </div>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableContent>
        </div>
        {/* <Pagination pageCount={3} /> */}
      </ContentWrappers>
    </>
  );
};

export default MembeerReport;
