import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FFA700",
    },
    secondary: {
      main: "#242D31",
    },
    third: {
      main: "#CECAC1",
    },
    fourth: {
      main: "#A9D1B2",
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: [
      "Gotham"
    ],
    h1: {
      fontFamily: "Oswald",
      fontSize: "2.42rem",
      fontWeight: 400,
      lineHeight: "56px",
      letterSpacing: "-0.022rem",
      color: "#242d31"
    },
    h2: {
      fontFamily: "Oswald",
      fontSize: "2rem",
      fontWeight: 400,
      lineHeight: "40px",
      letterSpacing: "-0.021rem",
      color: "#242D31"
    },
    h3: {
      fontFamily: "Oswald",
      fontSize: "1.4rem",
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: "-0.017rem",
      color: "#242D31"
    },
    h4: {
      fontFamily: "Oswald",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "-0.00011rem",
      color: "#242D31"
    },
    h5: {
      fontFamily: "Oswald",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "17px",
      color: "#454340"
    },
    h6: {
      fontFamily: "Oswald",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "17px",
      color: "#454340"
    },
    caption: {
      fontSize: "0.875rem",
      fontWeight: 300,
      lineHeight: "17px",
      color: "#363635",
      letterSpacing: "-0.006em",
    }
  },
});

export default theme;