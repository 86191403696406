// Packages
import React from "react";
import PropTypes from "prop-types";
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Components
import useComponents from "../../../../../components";
import useIcons from "../../../../../../assets/icons";

// Styles
import { StyledModalPreview } from "./Questions.styles";

// Import Swiper styles
import "swiper/swiper.min.css";

const CreateQuestions = (props) => {
  // Components
  const {
    ContentWrappers,
    Typography,
    useButtons,
    SelectFieldControlled,
    CheckboxControlled,
    Questions,
    InputFileControlled,
    InputDraft,
  } = useComponents();

  const { DefaultButton } = useButtons();

  const {
    editId,
    handleSubmitQuestion,
    onSubmitQuestion,
    controlQuestion,
    resetField,
    questionType,
    setDraftRef,
    setTypeSelection,
    typeSelection,
    handleClose,
    disableCreateButton,
  } = props;

  return (
    <>
      <div className="bg-white w-50 justify-center px-8 pt-10 pb-12 rounded-lg mt-10 max-w-[80%] mx-auto mb-4 flex flex-col">
        <Typography variant="h4" bulledTitle={true}>
          {!editId ? <>NUEVA PREGUNTA</> : <>PREGUNTA</>}
        </Typography>

        <form onSubmit={handleSubmitQuestion(onSubmitQuestion)}>
          <ContentWrappers
            className="mt-4"
            gridColums={["1fr", "1fr"]}
            gap={{ row: "0px", column: "32px" }}
          >
            <SelectFieldControlled
              name="question_type"
              id="question_type"
              control={controlQuestion}
              label="Tipo de respuesta esperada"
              tooltipTitle="Texto para respuesta"
              onChangeField={resetField}
              options={[
                { label: "Tipo texto", value: "Text" },
                { label: "Carga de archivos", value: "File" },
                { label: "Selección múltiple", value: "Checkbox" },
                { label: "Selección única - Radiobuttons", value: "Radio" },
                { label: "Rango de valor", value: "Range" },
                { label: "Fecha", value: "Date" },
                { label: "Opinión sobre un archivo", value: "AboutFile" },
                { label: "Si/No", value: "Binary" },
                { label: "Matriz", value: "Matrix" },
                { label: "Máscara", value: "Mask" },
                { label: "Ranking", value: "Ranking" },
              ]}
            />
            {questionType !== "Mask" && (
              <div className="flex items-center">
                <CheckboxControlled
                  control={controlQuestion}
                  name="required"
                  id="required"
                  label="Campo obligatorio"
                />
              </div>
            )}
            {questionType === "Text" && (
              <SelectFieldControlled
                name="input_type"
                id="input_type"
                control={controlQuestion}
                label="Tipo de campo de texto"
                onChangeField={resetField}
                options={[
                  { label: "Letras", value: "text_only" },
                  { label: "Números", value: "number" },
                  { label: "Alfanumérico", value: "text" },
                  { label: "Email", value: "email" },
                  { label: "Url", value: "url" },
                  { label: "Dirección", value: "address" },
                  { label: "Teléfono", value: "phone" },
                ]}
              />
            )}
          </ContentWrappers>
          {questionType && (
            <div className="max-w-[950px]">
              <InputDraft
                name="title"
                id="title"
                placeholder="Ingresa tu nueva pregunta"
                control={controlQuestion}
                label="Texto de la pregunta"
                inputRef={setDraftRef}
              />
            </div>
          )}
          {questionType && !["AboutFile", "Mask", "Ranking"].includes(questionType) && (
            <InputFileControlled
              id="file"
              name="file"
              control={controlQuestion}
              label="Cargar archivo"
              placeholder="Selecciona un archivo"
              textHelper="Puedes ajustar las dimensiones de la imagen cargada. Formatos aceptados: <b>jpg, png con un ancho mínimo de 320px</b>."
              setCustomSize
              showPreview
            />
          )}
          <Questions
            control={controlQuestion}
            questionType={questionType}
            setTypeSelection={setTypeSelection}
            typeSelection={typeSelection}
            qualityChecks={true}
          />
          {questionType && (
            <div className="flex justify-end mb-4 space-x-4">
              <DefaultButton disabled={disableCreateButton} rounded onClick={handleClose}>
                Cancelar
              </DefaultButton>
              <DefaultButton disabled={disableCreateButton} rounded type="submit">
                Guardar
              </DefaultButton>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

CreateQuestions.propTypes = {
  editId: PropTypes.string,
  handleSubmitQuestion: PropTypes.func.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  controlQuestion: PropTypes.object.isRequired,
  resetField: PropTypes.func.isRequired,
  questionType: PropTypes.string,
  setDraftRef: PropTypes.func,
  setTypeSelection: PropTypes.func.isRequired,
  typeSelection: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

export default CreateQuestions;
