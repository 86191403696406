// Packages
import React from "react";
import PropTypes from "prop-types";

// Components
import useComponents from "..";

// Controllers
import useControllers from "../../../controllers";

// Styles
import {
  StyledMultipleChoiceItem,
  StyledMultipleChoiceItemLabel,
  StyledMultipleChoiceAddButton,
  StyledMultipleChoiceRadioButton,
  StyledMultipleChoiceUpdateImage,
} from "./Questions.styles";

// Assets
import useIcons from "../../../assets/icons";

const MultipleChoiceResponse = (props) => {
  const { control, type, hideOptions, qualityChecks } = props;

  // Components
  const { TextFieldControlled, CheckboxControlled, InputFileControlled, TextAreaFieldControlled } =
    useComponents();

  // Assets
  const { IconDelete, IconPlus, IconUpdateImage } = useIcons();

  // Controllers
  const { useComponentHooks } = useControllers();
  const { useQuestions } = useComponentHooks();
  const { useMultipleChoiceResponse } = useQuestions();
  const {
    addOptionHandled,
    removeOptionHandled,
    fields,
    radioButtonHandled,
    fileChangeHandled,
    handlechangeMulti,
    optionsWatch
  } = useMultipleChoiceResponse(control);

  return (
    <div>
      <TextAreaFieldControlled
        placeholder="Escribe las opciones"
        control={control}
        name={"masiveOptions"}
        id={`masiveOptions`}
        helperText="Escribe cada opcion separado por un espacio"
        label="Carga masiva de opciones"
        minRows={1}
        onInputChange={handlechangeMulti}
      />
      {fields?.map((item, index) => {
        return (
          <StyledMultipleChoiceItem
            gridColums={
              type === "radio" || type === "checkbox" 
                ? ["1fr", "44px", "96px", "44px"]
                : ["1fr", "44px", "1fr"]
            }
            gap={{
              row: "0",
              column: "30px",
            }}
            key={item.name + '-' + index}
          >
            <div>
              <StyledMultipleChoiceItemLabel>
                <span>Respuesta {index + 1}</span>
                {fields?.length > 1 && (
                  <button type="button" onClick={() => removeOptionHandled(index)}>
                    <IconDelete />
                  </button>
                )}
              </StyledMultipleChoiceItemLabel>
              <TextFieldControlled
                placeholder="Escribe una opción de repuesta"
                control={control}
                name={`options[${index}].name`}
                id={`options[${index}].name`}
              />
            </div>

            {type !== 'ranking' && <StyledMultipleChoiceUpdateImage>
              <InputFileControlled
                control={control}
                inputTypeIcon={<IconUpdateImage />}
                label=""
                accept=".png,.jpg,.svg,.jpeg,.gif"
                name={`options[${index}].file`}
                id={`options[${index}].file`}
                //inputChange={(e) => fileChangeHandled(e, index)}
              />
            </StyledMultipleChoiceUpdateImage>}
            {/* {type === "radio" && (
              <StyledMultipleChoiceRadioButton>
                <input
                  checked={item.is_incorrect}
                  onChange={() => radioButtonHandled(index)}
                  type="radio"
                  name="radio-button"
                  id={item.id}
                />
                <label htmlFor={item.id}>Incorrecta</label>
              </StyledMultipleChoiceRadioButton>
            )} */}

            {(type === "checkbox" || type === "radio") && (
              <div className="flex self-end">
                <CheckboxControlled
                  control={control}
                  name={`options[${index}].is_incorrect`}
                  id={`options[${index}].is_incorrect`}
                  label="Incorrecta"
                />
              </div>
            )}

            <StyledMultipleChoiceAddButton>
              <button onClick={() => addOptionHandled(index + 1, item.id + item.name + index)} type="button">
                <IconPlus />
              </button>
            </StyledMultipleChoiceAddButton>
            {optionsWatch && optionsWatch[index]?.file && (
              <div className="mt-2">
                <img
                  src={typeof optionsWatch[index].file !== "string" ? optionsWatch[index]?.file[0].preview : optionsWatch[index]?.file}
                  height="150px"
                  width="150px"
                />
              </div>
            )}
          </StyledMultipleChoiceItem>
        );
      })}
      {!hideOptions && (
        <>
          <CheckboxControlled
            control={control}
            name="add_other_field"
            id="add_other_field"
            label="Incluir otros"
          />
          <CheckboxControlled
            control={control}
            name="other_is_incorrect_field"
            id="other_is_incorrect_field"
            label="Otros es incorrecta?"
          />
          <CheckboxControlled
            control={control}
            name="in_random_order"
            id="in_random_order"
            label="Orden aleatorio"
          />
        </>
      )}
      {/* {type === "checkbox" && (
        <div className="mt-4">
          <CheckboxControlled
            control={control}
            name="exclusion"
            id="exclusion"
            label="Siguiente pregunta con exclusión"
          />
        </div>
      )} */}
    </div>
  );
};

MultipleChoiceResponse.propTypes = {
  type: PropTypes.oneOf(["radio", "checkbox"]),
  hideOptions: PropTypes.bool,
};

MultipleChoiceResponse.defaultProps = {
  type: "checkbox",
  hideOptions: false,
  qualityChecks: false,
};

export default MultipleChoiceResponse;
