import React from "react";
import PropTypes from "prop-types";

import { PieChart, Pie, ResponsiveContainer, Tooltip, Legend, Label } from "recharts";

import { StyledTitle } from "./Donut.styles";
import useControllers from "../../../../controllers";

const Donut = (props) => {
  const {
    data,
    title,
    subTitle,
    titleValue,
    titlePosition,
    className,
    toltip,
    legend,
    legendAling,
    legendStyled,
    heightWrapper,
    legendType,
    corX,
    corY,
  } = props;

  const { useComponentHooks } = useControllers();
  const { useHookGrafic } = useComponentHooks();

  const { CustomToltip, totalPercentage, CustomLegend, CustomLabel } = useHookGrafic({ data });

  return (
    <div className={`${className} relative`}>
      {title && titlePosition === "top" && (
        <StyledTitle titlePosition={titlePosition} className="">
          {title}
        </StyledTitle>
      )}
      <div className="relative" style={{ width: "100%", height: heightWrapper }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              cx={corX}
              cy={corY}
              innerRadius="80%"
              outerRadius="100%"
              fill="#F8F9FA"
              dataKey="value"
            >
              {title && (
                <Label
                  offset={0}
                  position="center"
                  content={<CustomLabel title={title} value={titleValue} subTitle={subTitle}/>}
                />
              )}
              {titleValue && (
                <Label
                  offset={0}
                  position="center"
                  content={<CustomLabel title={title} value={titleValue} subTitle={subTitle}/>}
                />
              )}
            </Pie>
            {toltip && <Tooltip content={<CustomToltip totalPercentage={totalPercentage} />} />}
            {legend && (
              <Legend
                wrapperStyle={legendStyled}
                content={<CustomLegend legendAling={legendAling} legendType={legendType} />}
              />
            )}
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

Donut.propTypes = {
  data: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titlePosition: PropTypes.oneOf(["top", "center", "bottom"]),
  className: PropTypes.string,
  toltip: PropTypes.bool,
  legendAling: PropTypes.oneOf(["top", "center", "bottom"]),
  legendStyled: PropTypes.object,
  heightWrapper: PropTypes.number,
  legendType: PropTypes.string,
  corX: PropTypes.string,
  corY: PropTypes.string,
  titleValue: PropTypes.string,
  subTitle: PropTypes.string,
};

Donut.defaultProps = {
  data: [],
  titlePosition: "center",
  toltip: true,
  heightWrapper: 250,
  corX: "50%",
  corY: "50%",
};

export default Donut;
