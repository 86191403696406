import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useModels from "../../../../models";
import useHelpers from "../../../../helpers";
import { useParams, useNavigate } from "react-router-dom";

import useApi from "../../../../api";

const useProjectsCreate = () => {
  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();

  const { rol: role, country: country } = useSelector(session);
  const navigation = useNavigate();
  const { projectId } = useParams();

  const { useQuickFunctions } = useHelpers();
  const { useGeneral, useValidators } = useQuickFunctions();
  const { newProjectSchema } = useValidators();
  const { formatDate } = useGeneral();

  const { useActions } = useApi();
  const { useGeneralActions, useProjectsActions, useMissionActions, dispatch } = useActions();
  const { actListCountries } = useGeneralActions();
  const { actCreateProject, actAddProject, actSeletedProject, actEditProject } =
    useProjectsActions();

  const { actDeleteMission } = useMissionActions();

  const [listCountries, setListCountries] = useState();
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [listMissions, setlistMissions] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [disabledFields, setDisabledFields] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(newProjectSchema),
    defaultValues: {
      country_id: [],
    },
  });

  const trackingWatch = watch('tracking')

  const handleCloseModal = () => {
    setShowModal(false);
    navigation("/proyectos");
  };

  const handleSuccesEdit = (data) => {
    setProjectName(data.name);
    setShowModal(true);
  };

  const handleGoToMissions = () => {
    //navigation("/proyectos/crear/misiones", { from: "create_project" });
    navigation("/proyectos");
  };

  const onSubmit = useCallback((data) => {
    setSaving(true);
    const onError = () => {
      setSaving(false);
    }
    if (projectId) {
      dispatch(
        actEditProject(
          projectId,
          {
            ...data,
            country_id: _.map(data.country_id, (v) => {
              return [v.value];
            }),
            tracking: !!data.tracking,
            tracking_count: data.tracking && data.tracking_count ? data.tracking_count : 0,
            //expiration_date: formatDate(new Date(data.expiration_date), "Y-mm-dd", false),
          },
          handleSuccesEdit,
          onError
        )
      );
    } else {
      dispatch(
        actCreateProject(
          {
            ...data,
            country_id: _.map(data.country_id, (v) => {
              return [v.value];
            }),
            tracking: !!data.tracking,
            tracking_count: data.tracking && data.tracking_count ? data.tracking_count : 0,
            //expiration_date: formatDate(new Date(data.expiration_date), "Y-mm-dd", false),
            status: "act",
          },
          handleGoToMissions,
          onError
        )
      );
    }
  }, []);

  const dataListCountries = (data) => {
    setListCountries(
      _.map(data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  useEffect(() => {
    actListCountries(dataListCountries);
  }, []);

  const handleLoadData = (data) => {
    const listActveCountries = _.map(data.countries, (v) => {
      return { label: v.name, value: v.id };
    });
    setValue("name", data.name);
    setValue("country_id", listActveCountries);
    setValue("client_id", data.client_id);
    setValue("tracking", data.tracking);
    setValue("tracking_count", data.tracking_count);
    //setValue("expiration_date", data.expiration_date);
    setValue("objective", data.objective);
    setValue("decision", data.decision);
    setlistMissions(data.missions);
  };

  const onSubmitHandleDeletedMission = () => {
    dispatch(actSeletedProject(projectId, handleLoadData));
  };
  const handleDeletedMission = (mission) => {
    actDeleteMission(mission.id, onSubmitHandleDeletedMission);
  };

  useEffect(() => {
    if (projectId) {
      setDisabledFields(true);
      dispatch(actSeletedProject(projectId, handleLoadData));
    }
  }, []);

  return {
    listCountries,
    control,
    role,
    handleSubmit,
    onSubmit,
    errors,
    showModal,
    saving,
    handleCloseModal,
    getValues,
    projectId,
    listMissions,
    handleDeletedMission,
    handleGoToMissions,
    projectName,
    country,
    disabledFields,
    trackingWatch,
  };
};

export default useProjectsCreate;
