import FormGamificationName from "./FormGamificationName"
import FormGamificationDetails from "./FormGamificationDetails"


const useFormsProfiler = () => {
  return {
    FormGamificationName,
    FormGamificationDetails
  }
}

export default useFormsProfiler;