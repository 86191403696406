const IconAccordionArrowDown = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.33474 8.3295C4.74672 7.92396 5.39498 7.89276 5.84331 8.23592L5.95098 8.3295L12 14.2831L18.049 8.3295C18.461 7.92396 19.1093 7.89276 19.5576 8.23592L19.6653 8.3295C20.0772 8.73505 20.1089 9.37318 19.7603 9.81451L19.6653 9.9205L12.8081 16.6705C12.3961 17.076 11.7479 17.1072 11.2995 16.7641L11.1919 16.6705L4.33474 9.9205C3.88842 9.48116 3.88842 8.76884 4.33474 8.3295Z"
        fill="#363635"
      />
    </svg>
  );
};

export default IconAccordionArrowDown;