import styled from "styled-components";
import tw from "twin.macro";

export const StyledInputFileContent = styled.div.attrs({
  className: "StyledInputFileContent",
})`
  ${tw`mb-7 relative`}
`;
export const StyledInputFileButton = styled.label.attrs({
  className: "StyledInputFileButton",
})`
  ${tw`
    text-neutral-70
    block
    w-full
    font-light
    text-sm
    box-border
    mt-2
    bg-neutral-30
    border border-neutral-60 border-solid
    rounded
    relative
    h-auto
    cursor-pointer
  `}
  letter-spacing: -0.006em;
  padding: 11px 135px 11px 1rem;
  min-height: 45px;

  .button-update {
    ${tw`
      bg-third
      absolute
      text-black
      text-sm
      font-medium
      text-center
      rounded-tr
      rounded-br
      top-0
      right-0
    `}
    letter-spacing: -0.006em;
    line-height: 20px;
    padding: 11px 22px 13px;
  }
  ${(props) => props.directPreview && 'width: 360px; margin-bottom: 12px'}

  ${(props) => props.defaultValues > 1 && `width:auto`}
`;

export const StyledInputFileButtonName = styled.span.attrs({
  className: "StyledInputFileButtonName",
})`
  ${tw`truncate block`}
  width: calc(100%-130px);
`;

export const StyledHelperTextFile = styled.div.attrs({
  className: "StyledHelperTextFile",
})`
  margin-top: 28px;
  font-weight: 325;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #1a1a1a;
`;

export const StyledConatinerImg = styled.div.attrs({
  className: "StyledConatinerImg",
})`
  width: 216px;
  height: 216px;
  border-radius: 8px;
  img {
    max-height: 216px;
  }
`;

export const StyleLabelHeightWidth = styled.label.attrs({
  className: "StyleLabelHeightWidth",
})`
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #424242;
`;

export const StyleInputHeightWidth = styled.input.attrs({
  className: "StyleInputHeightWidth",
})`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;

  width: 120px;
  height: 44px;
  background: #f4f4f4;

  border: 1px solid #dddddd;
  border-radius: 4px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;
