const Range = ({ border }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="40"
      fill="none"
      viewBox="0 0 200 40"
      className={`h-24 px-2 rounded-lg ${border}`}
    >
      <rect width="199" height="11" x="0.5" y="14.5" fill="#F4F4F4" stroke="#DDD" rx="5.5"></rect>
      <path
        fill="#E5B611"
        stroke="#DDD"
        d="M.5 20A5.5 5.5 0 016 14.5h93.5v11H6A5.5 5.5 0 01.5 20z"
      ></path>
      <g filter="url(#filter0_d_4309_20629)">
        <circle cx="100" cy="20" r="16" fill="#E5B611"></circle>
      </g>
      <defs>
        <filter
          id="filter0_d_4309_20629"
          width="44"
          height="44"
          x="78"
          y="-1"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4309_20629"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_4309_20629" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default Range;
