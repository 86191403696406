const IconTooltipForm = ({color = "#FFA700"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={ color }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 14A6 6 0 108 2a6 6 0 000 12z"
      ></path>
      <path
        fill={ color }
        d="M8 11.875a.625.625 0 100-1.25.625.625 0 000 1.25z"
      ></path>
      <path
        stroke={ color }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 9v-.5a1.75 1.75 0 10-1.75-1.75"
      ></path>
    </svg>
  );
}

export default IconTooltipForm;
