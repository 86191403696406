// Packages
import { useCallback, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

// Helpers
import useHelpers from '../../../../../helpers';

// Actions
import useActions from '../../../../../api/actions';

const useQuestions = () => {
  const { id_survey } = useParams();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, useToast } = useQuickFunctions();
  const { navigateTo } = useNavigation();
  const { success, error } = useToast();

  // Actions
  const { useToolsActions, dispatch } = useActions();
  const {
    actRemoveQuestion,
    actOrderSurveySection,
    actGetSurvey,
    actCloneQuestion,
  } = useToolsActions();

  const [ sectionList, setSectionList ] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [questionPreview, setQuestionPreview] = useState({});

  const [ sectionInfo, setSectionInfo ] = useState({
    surveyName: "",
    missionName: "",
    isActiveMissionFlag: false,
  })

  const [draggedIndex, setDraggedIndex] = useState(null);
  const [listPrevRanking, setListPrevRanking] = useState([]);

  const { control, watch } = useForm();

  useEffect(() => {
    const onSuccess = ({sections}) => {
      // Set order questions by order
      sections.map((section, index) => {
          if(index === 0) {
            setSectionInfo({
              surveyName: section.survey_name,
              missionName: section.mission_name,
              countryName: section.country_name,
              isActiveMissionFlag: section.isActiveMissionFlag,
            })
          }

          const questions = section.questions.sort(function (a, b) {
            if (a.order > b.order) {
              return 1;
            }
            if (a.order < b.order) {
              return -1;
            }
            return 0;
          })
          return {...section, questions}

      })
      setSectionList(sections)
    }

    const onError = (error) => {
      console.log(error)
    }
    dispatch(actGetSurvey(id_survey, onSuccess, onError))
  }, [id_survey]);

  // DragDrop
  const handleOnDragEnd = useCallback((result, id) => {
    if (!result.destination) return;
    const filtetArray = sectionList.filter(section => section.id === id)
    const items = Array.from(filtetArray[0].questions);
    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const itemsUpdateOrder = items.map((item, index) => {
      return {...item, order: index}
    });

    const setItemOrderQuestions = sectionList.map((section) => {
      if(section.id === id) {
        return {...section, questions: itemsUpdateOrder}
      }
      return section;
    })

    setSectionList(setItemOrderQuestions)

  }, [sectionList]);


  // Remove question
  const handleRemoveQuestion = useCallback((id, sectionId) => {
    const onSuccess = () => {
      const filtetArray = sectionList.filter(section => section.id === sectionId)
      const items = Array.from(filtetArray[0]?.questions);
      items.splice(items.findIndex(el => el.id === id), 1);

      const itemsUpdateOrder = items.map((item, index) => {
        return {...item, order: index}
      });

      const setItemQuestions = sectionList.map((section) => {
        if(section.id === sectionId) {
          return {...section, questions: itemsUpdateOrder}
        }
        return section;
      })

      setSectionList(setItemQuestions)
      success("La pregunta fue eliminada exitosamente");
    }

    const onError = () => {
      error("No se pudo eliminar la pregunta");
    };

    dispatch(actRemoveQuestion(id, onSuccess, onError));
  },[sectionList]);

  const handleGetQuestion = useCallback((id, sectionId) => {
    navigateTo(`/herramientas/encuesta/${id_survey}/secciones/${sectionId}/pregunta/${id}`);
  }, []);

  const handleFinalize = useCallback(() => {
    let promiseList = []
    const PromiseArray = sectionList.map((section) => {
      const orderQuestios = section.questions.reduce(
        (previousValue, currentValue) => (
          [...previousValue, {question_id: currentValue.id, order: currentValue.order }]
        ), []
      )

      return [
        ...promiseList,
        dispatch(actOrderSurveySection({
          survey_section_id: section.id,
          questions: orderQuestios
        }))
      ]
    });

    Promise.all(PromiseArray).then(() => {
      navigateTo(`/herramientas/encuesta/${id_survey}/secciones/condicionales`);
    })
  }, [sectionList, id_survey])

  const handlePreview = (id, sectionId) => {
    setQuestionPreview(sectionList.find(v => v.id === sectionId).questions.find(v=> v.id == id))
    setListPrevRanking([])
    setShowModal(true)
  }

  const handleClose = () => {setShowModal(false)}

  const handleCloneQuestion = id => {
    const onSuccess = ({ title, question_type, id, order, question_type_es, question }) => {
      const onSuccessSuvery = ({sections}) => {
        // Set order questions by order
        sections.map((section, index) => {
            if(index === 0) {
              setSectionInfo({
                surveyName: section.survey_name,
                missionName: section.mission_name,
                countryName: section.country_name,
                isActiveMissionFlag: section.isActiveMissionFlag
              })
            }

            const questions = section.questions.sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            })
            return {...section, questions}

        })
        setSectionList(sections)
      }

      const onError = (error) => {
        console.log(error)
      }
      dispatch(actGetSurvey(id_survey, onSuccessSuvery, onError))
    };
    dispatch(actCloneQuestion(id, onSuccess))
  }

  const hanldeSelectPrevRanking = (element) => {
    const stringExists = listPrevRanking.includes(element);

    if (stringExists) {
      const updatedList = listPrevRanking.filter(item => item !== element);
      setListPrevRanking(updatedList);
    } else {
      const updatedList = [...listPrevRanking, element];
      setListPrevRanking(updatedList);
    }
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();

    if (draggedIndex !== null) {
      const updatedList = [...listPrevRanking];
      const draggedItem = updatedList.splice(draggedIndex, 1)[0];
      updatedList.splice(index, 0, draggedItem);
      setListPrevRanking(updatedList);
      setDraggedIndex(index);
    }
  };

  return {
    handleOnDragEnd,
    handleRemoveQuestion,
    handleGetQuestion,
    handleFinalize,
    sectionInfo,
    sectionList,
    id_survey,
    handlePreview,
    questionPreview,
    showModal,
    handleClose,
    control,
    handleCloneQuestion,
    watch,
    listPrevRanking,
    hanldeSelectPrevRanking,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    draggedIndex,
  }
}

export default useQuestions;