import React from "react";
import { Routes, Route, Outlet, useLocation, useMatch } from "react-router-dom";
import useLayouts from "../../layouts";
import useControllers from "../../../controllers";

// Components
import LegalView from "./Views";

const LegalLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const { useScreenHooks } = useControllers();
  const { useLegal } = useScreenHooks();
  const { handleBack, header } = useLegal();

  const location = useLocation();
  const match = useMatch("/legales/*");
  const basePath = match ? match.pathnameBase : "/legales";
  const isExact = basePath === location.pathname;

  const breadcrumbsList = location.pathname.split("/").length > 2
    ? header[Object.keys(header).filter((header) => location.pathname.includes(header))[1]]
    : header[Object.keys(header).filter((header) => location.pathname.includes(header))];

  return (
    <PrivateContentLayout
      breadcrumbsList={breadcrumbsList}
      handleBack={handleBack}
      showBack={!isExact}
      title="Legales"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Legal = () => (
  <Routes>
    <Route path="/" element={<LegalLayout />}>
      <Route index element={<LegalView />} />
    </Route>
  </Routes>
);

export default Legal;
