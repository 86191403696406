// Packages
import React from "react";

// Styles
import { StyledContentButtons } from "../../Membeers/Views/MembeersView.styles";

// Components
import useComponents from "../../../components";
import NewMembeers from "../View/Components/NewMembeers"

// Controllers
import useControllers from "../../../../controllers";


const NewMembeersList = () => {

  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    Pagination,
    Typography,
  } = useComponents();

  const { DefaultButton } = useButtons();  

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { formatDate } = useGeneralHooks();
  const { useDashboardScreens } = useScreenHooks();
  const { useDashboardMembeer } = useDashboardScreens();

  const {   
    listMembeers,
    paginationSettings,
    handlePagination,
    handleDownloadExcel,
    dataMemebers,
    handleClickChart,
    configPage,
    type,
  } = useDashboardMembeer();

  return (
    <div className="bg-white px-8 pt-10 pb-12 mx-12 rounded-lg">
      <ContentWrappers
        gridColums={["1fr 210px"]}
        className="mb-8 items-center border-b border-neutral-60 pb-8">
        <Typography variant="h3">{configPage[type].title}</Typography>
      </ContentWrappers>
      {configPage[type].graph && <NewMembeers dataMemebers={dataMemebers} onClick={handleClickChart}/>}
      <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10 mt-4">
        <div></div>
        <StyledContentButtons>
          <DefaultButton color="third" onClick={handleDownloadExcel}>
            Exportar a Excel
          </DefaultButton>
        </StyledContentButtons>
      </ContentWrappers>
      <TableContent
        headerCellList={[        
          "Fecha de suscripción",
          "Ultimo ingreso",
          "Membeer",
          "Estado membeer", 
          "Misiones KO",
          "Email",
          "NSE",
          "Género",
          "País",
          "Consumidor",
          "Etiqueta",
        ]}
        gridCell={["1fr", "1fr", "2fr", "1fr", "1fr", "2fr", "1fr", "1fr", "1fr", "1fr", "1fr" ]}
      >
        {listMembeers.length > 0 ? (
          listMembeers.map((membeer) => {
            return (
              <TableRow key={membeer.id}>
                <div>{formatDate(new Date(membeer?.created_at), "dd-mm-Y", false)}</div>
                <div>{membeer.last_date_entry ? formatDate(new Date(membeer?.last_date_entry), "dd-mm-Y", false) : formatDate(new Date(membeer?.created_at), "dd-mm-Y", false)}</div>
                <div>{membeer.membeer_name}</div>
                <div>{membeer.active}</div>
                <div>{membeer.missions_completed}</div>
                <div>{membeer.email}</div>
                <div>{membeer.nse}</div>
                <div>{membeer.gender === 'M' ? 'Masculino' : 'Femenino'}</div>
                <div>{membeer.country_name}</div>
                <div>{membeer.consumer}</div>
                <div>{membeer.label ? membeer.label.name : 'Sin Etiqueta'}</div>
              </TableRow>
            );
          })):(<div>Sin datos para mostrar</div>)
        }
      </TableContent>
      <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
    </div>
  )
}

export default NewMembeersList;