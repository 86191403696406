// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledSpinner = styled.div.attrs({
  className: "StyledSpinner",
})`
  ${
    (props) =>
      props.nested
        ? tw`h-full bg-opacity-100 mb-2 left-0 top-0 overflow-hidden flex justify-center items-center opacity-0 pointer-events-none z-10`
        : tw`absolute w-full h-full bg-secondary bg-opacity-80 left-0 top-0 overflow-hidden flex justify-center items-center opacity-0 pointer-events-none z-10`
  }

  ${(props) => {
    return props.isOpen && tw`pointer-events-auto opacity-100`;
  }}
  transition: all 0.5s;
`;

export const StyledDescription = styled.div.attrs({
  className: "StyledDescription",
})`
  ${tw` `}
`;

export const StyledCenterAnimation = styled.div.attrs({
  className: "StyledCenterAnimation",
})`
  ${tw` relative`}
`;

export const StyledImage = styled.img.attrs({
  className: "StyledImage",
})`
  ${tw` absolute top-1/2 mx-auto left-0 right-0`}
  transform: translate(0, -50%);
`;

export const StyledSmallBall = styled.img.attrs({
  className: "StyledSmallBall",
})`
  ${tw` w-1/3 m-auto`}
  width: 63.333333%;

  max-width: 270px;

  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    to {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    to {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  &.nested {
    width: 20%;
  }
`;

export const StyledContetAll = styled.div.attrs({
  className: "StyledContetAll",
})`
  ${tw` `}
`;
export const StyledIconOne = styled.img.attrs({
  className: "StyledIconOne",
})`
  ${tw` absolute`}
  opacity:1;
  transition: all 0.5s;
  top: 30%;
  left: 29%;
`;

export const StyledIconTwo = styled.img.attrs({
  className: "StyledIconTwo",
})`
  ${tw` absolute`}
  opacity:1;
  transition: all 0.5s;
  bottom: 37%;
  right: 26%;
`;

export const StyledIconTr = styled.img.attrs({
  className: "StyledIconTr",
})`
  ${tw` absolute`}
  opacity:1;
  transition: all 0.5s;
  bottom: 15%;
  right: 35%;
`;
