const IconBack = ({ color = "#018145" }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M47.6 60C49.5353 59.9974 51.3905 59.2274 52.759 57.859C54.1274 56.4905 54.8974 54.6353 54.9 52.7V32.3H49.9V52.7C49.9 53.31 49.6577 53.895 49.2264 54.3263C48.795 54.7577 48.21 55 47.6 55H9.20002C8.59003 55 8.00501 54.7577 7.57368 54.3263C7.14234 53.895 6.90002 53.31 6.90002 52.7V14.3C6.90002 13.998 6.95952 13.6989 7.0751 13.4198C7.19069 13.1408 7.3601 12.8872 7.57368 12.6737C7.78725 12.4601 8.0408 12.2907 8.31985 12.1751C8.5989 12.0595 8.89798 12 9.20002 12H39.2V7H9.20002C7.26476 7.00265 5.40951 7.7726 4.04107 9.14104C2.67262 10.5095 1.90267 12.3647 1.90002 14.3V52.7C1.90267 54.6353 2.67262 56.4905 4.04107 57.859C5.40951 59.2274 7.26476 59.9974 9.20002 60H47.6Z"
        fill={ color }
      />
      <path
        d="M33.2 33L24.2 24L20.66 27.54L31.46 38.34C31.6922 38.5728 31.9681 38.7575 32.2718 38.8836C32.5755 39.0096 32.9011 39.0745 33.23 39.0745C33.5588 39.0745 33.8844 39.0096 34.1881 38.8836C34.4919 38.7575 34.7677 38.5728 35 38.34L61.4 11.94L57.83 8.33L33.2 33Z"
        fill={ color }
      />
    </svg>
  );
};

export default IconBack;
