// Packages
import React, {useEffect} from "react";

const LaunchApp = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.replace(
              "https://play.google.com/store/apps/details?id=abi.membeer.com"
            );
          }, 1000);
    }, [])

    return <></>
}


export default LaunchApp