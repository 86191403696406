import axios from "axios";

const useLegalProvider = () => {
  const listLegalProvider = ({page, params}) => {        
    return axios ({
      method: "get",
      url: `legal?page=${page}`,
      params: {...params}
    })
  }

  const createLegalProvider = (data) => {
    return axios({
      method: "post",
      url: `legal`,
      data: data
    });
  };

  const editLegalProvider = (id, data) => {
    return axios({
      method: "put",
      url: `legal/${id}`,
      data: data
    });
  };

  return {
    listLegalProvider,
    createLegalProvider,
    editLegalProvider,
  }
}

export default useLegalProvider;