// Packages
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledStepList = styled.ul.attrs({
  className: "StyledStepsList"
})`
  ${tw`flex justify-between w-full mx-auto relative mb-10`}
  max-width: ${props => props.widthContent};

  &:after {
    ${tw`absolute left-0 block w-full bg-neutral-70`}
    content: "";
    top: 20px;
    height: 1px;
  }

  > li {
    &:first-child {
      &:before {
        ${tw`absolute left-0 top-0 w-2/4 h-full block bg-neutral-40`}
        content: ""
      }
    }
    &:last-child {
      &:after {
        ${tw`absolute right-0 top-0 w-2/4 h-full block bg-neutral-40`}
        content: ""
      }
    }
  }
`

export const StyledStepItem = styled.li.attrs({
  className: "StyledStepItem"
})`
  ${tw`flex items-center flex-col relative z-10`}

  .item-number {
    ${tw`
      bg-neutral-40
      border-2 border-neutral-70 border-solid
      rounded-full
      text-neutral-70
      flex justify-center items-center
      relative z-10
    `}
    letter-spacing: -0.00011em;
    height: 40px;
    width: 40px;
  }

  h4 {
    ${tw`relative z-10 text-neutral-70 mt-4`}
  }

  &.active {
    .item-number {
      ${tw`
        bg-secondary
        border-2 border-primary border-solid
        text-primary
      `}
    }

    h4 {
      ${tw`text-secondary`}
    }
  }
`