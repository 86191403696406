// Packages
import React from "react";
import PropTypes from "prop-types";

// Styles
import { StyledFormControl, StyledHelperText } from "./DatePikerRange.styles";

import DatePicker from "react-multi-date-picker";

// Components
import FormLabel from "../FormLabel";

const DatePickerRange = (props) => {
  const {
    label,
    tooltipTitle,
    error,
    disabled,
    minDate,
    maxDate,
    auxValue,
    onChange,
    placeholder,
    onClickClear,
    ...rest
  } = props;

  return (
    <StyledFormControl fullWidth>
      <div className="flex justify-between items-center">
        <FormLabel data-shrink="dd" label={label} />
        <FormLabel data-shrink="dd" className="text-filter cursor-pointer" label={"Limpiar filtro"} onClick={onClickClear} />
      </div>
      <DatePicker
        value={auxValue}
        onChange={onChange}
        range
        {...rest}
        minDate={minDate}
        maxDate={maxDate}
        inputClass="StyledInput"
        placeholder={placeholder}
        className="StyledRange"
      />
      {error && <StyledHelperText>{error?.message}</StyledHelperText>}
    </StyledFormControl>
  );
};

DatePickerRange.propTypes = {
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  onClickClear: PropTypes.func,
};

export default DatePickerRange;
