// Packages
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Helpers
import useHelpers from "../../../../helpers";

import useComponents from "../../../../views/components";

// Actions
import useApi from "../../../../api";

const useGamificationView = () => {
  const listGamificationType = [
    {
      type: 0,
      title: "Insignias",
      columnsTitle: ["Nombre", "Acciones"],
      cellSize: ["1fr", "80px"],
      url: "badges",
    },
    {
      type: 1,
      title: "Tier",
      columnsTitle: ["Nombre", "Insignia", "Acciones"],
      cellSize: ["1fr", "1fr", "80px"],
      url: "tiers",
    },
    {
      type: 2,
      title: "Misiones",
      columnsTitle: ["Insignia", "Tier", "Minimo", "Maximo", "Acciones"],
      cellSize: ["1fr", "1fr", "1fr", "1fr", "80px"],
      url: "missionMinMax",
    },
    {
      type: 3,
      title: "Personajes",
      columnsTitle: ["Nombre", "Insignia", "Tier", "Acciones"],
      cellSize: ["1fr", "1fr", "1fr", "80px"],
      url: "characters",
    },
    {
      type: 4,
      title: "Categorías",
      columnsTitle: ["Nombre", "Avatar", "Orden capa", "Acciones"],
      cellSize: ["1fr", "1fr", "1fr", "80px"],
      url: "categories",
    },
    {
      type: 5,
      title: "Accesorios",
      columnsTitle: ["Nombre", "Categoría", "Insignia", "Tier", "Acciones"],
      cellSize: ["1fr", "1fr", "1fr", "1fr", "80px"],
      url: "missionImg",
    },
  ];

  let expandedSearch = null;
  const [loading, setLoading] = useState(false);
  const [listGamifications, setListGamifications] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });
  const navigation = useNavigate();

   // Helpers
   const { useQuickFunctions } = useHelpers();
   const { useToast } = useQuickFunctions();

  const { Toast } = useComponents();
  const { success } = useToast();

  const { useActions } = useApi();
  const { useGamificationActions } = useActions();
  const { actListGamification, actDeleteGamification } = useGamificationActions();

  const handleCreateNewGamification = (gamification) => {
    navigation("/gamification/creacion", gamification);
  };

  const handleEditGamification = (id, gamification) => {
    navigation(`/gamification/editar/${id}`, gamification);
  };
  
  const handleDeleteGamification = (item, gamification) => {
    setModalDelete({ show: true, name: item.name, id: item.id, url: gamification.url })
  };

  const handlePagination = (nro) => {
    setPaginationSettings({ ...paginationSettings, current: nro.selected + 1 });
    actListGamification(listGamificationType[expanded].url, { page: nro.selected + 1 }, loadData);
  };

  const loadData = (data) => {
    setListGamifications(data?.data ?? []);

    setPaginationSettings({ current: data?.meta?.current_page, totalPages: data?.meta?.last_page });
  };

  const onExpand = async (e, profileType) => {
    if (profileType === expanded) return;
    setLoading(true);
    setPaginationSettings({ ...paginationSettings, page: 1 });
    setExpanded(profileType);
    expandedSearch = profileType;
    await actListGamification(listGamificationType[profileType].url, { page: 1 }, loadData);
    setLoading(false);
  };

  const handleCloseModalDelete = () => {
    setModalDelete({ show: false, name: "", id: 0 })
  };

  const handleConfirmDelete = () => {
    const onSuccess = () => {
      success(<Toast text={`El accesorio se eliminó correctamente`} />, {
        toastId: 0,
      });
      actListGamification(modalDelete.url, { page: 1 }, loadData);
    }
    handleCloseModalDelete()
    actDeleteGamification({id: modalDelete.id, url: modalDelete.url}, onSuccess);
  };

  return {
    listGamificationType,
    listGamifications,
    loading,
    handleCreateNewGamification,
    handleEditGamification,
    handlePagination,
    paginationSettings,
    onExpand,
    expanded,
    handleDeleteGamification,
    modalDelete,
    handleCloseModalDelete,
    handleConfirmDelete,
  };
};

export default useGamificationView;
