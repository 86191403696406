// Packages
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useActions from "../../../../api/actions";

// Components
import useComponents from "../../../../views/components";

const usePromptsMain = () => {
  const [promptsList, setPromptsList] = useState([]);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [modalDelete, setModalDelete] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(null);
  const [promptCategories, setPromptCategories] = useState(null);
  const [shouldAddCategory, setShouldAddCategory] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pathParams, setPathParams] = useState({
    search: "",
    prompt_category: "",
  });

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast } = useQuickFunctions();
  const { filtersValidationShema, promptValidationSchema, categoryValidationSchema } = useValidators();
  const { success, error } = useToast();

  // Actions
  const { usePromptsActions, dispatch } = useActions();
  const {
    actListPrompts,
    actUpdatePrompt,
    actCreatePrompt,
    actDeletePrompt,
    actGetPromptCategories,
    actCreateCategoryPrompt
  } = usePromptsActions();

  // Components
  const { Toast } = useComponents();

  useEffect(() => {
    const onSuccess = ({ data, meta }) => {
      setPromptsList(data);
      setPaginationSettings({ current: meta.current_page, totalPages: meta.last_page });
    };
    const onError = (error) => {};
    dispatch(actListPrompts({ page: 1, ...pathParams }, onSuccess, onError));
  }, [pathParams]);

  useEffect(() => {
    const onSuccess = ({ data }) => {
      setPromptCategories(data);
    };
    const onError = (error) => {};
    dispatch(actGetPromptCategories(onSuccess, onError));
  }, [refresh]);

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(filtersValidationShema),
  });

  const { control: categoryControl, handleSubmit: handleSubmitCategory, reset } = useForm({
    mode: "all",
    resolver: yupResolver(categoryValidationSchema),
  });

  const generalCategory = promptCategories?.find(category => category.label.toLowerCase() === "general");
  const initialValues = {
    name: "",
    prompt: "",
    categories: generalCategory ? [generalCategory] : [],
  };

  const {
    control: promptFormControl,
    handleSubmit: promptSubmitForm,
    reset: resetPromptForm,
  } = useForm({
    mode: "all",
    resolver: yupResolver(promptValidationSchema),
  });

  const onSubmit = useCallback((data) => {
    const { search, type } = data;
    setPathParams({
      search,
      prompt_category: type ?? "",
    });
  }, []);

  const handlePagination = (nro) => {
    const onSuccess = ({ data, meta }) => {
      setPromptsList(data);
      setPaginationSettings({ current: meta.current_page, totalPages: meta.last_page });
    };
    const onError = (error) => {};
    dispatch(actListPrompts({ page: nro.selected + 1, ...pathParams }, onSuccess, onError));
  };

  const handleCloseModalDelete = () => setModalDelete(false);

  const handleOpenDeletePrompt = (currentPrompt) => {
    setCurrentPrompt(currentPrompt);
    setModalDelete(true);
  };

  const handleOpenCreatePrompt = () => {
    setCurrentPrompt(null);
    resetPromptForm(initialValues);
    setFormModal(true);
  };

  const handleOpenUpdatePrompt = (currentPrompt) => {
    setCurrentPrompt(currentPrompt);
    resetPromptForm({
      name: currentPrompt.name,
      prompt: currentPrompt.prompt,
      categories: promptCategories
      .filter(promptCategory => currentPrompt.categories.includes(promptCategory.label))
      .map(({ id, label, value }) => ( {id,label,value} ))
    });
    setFormModal(true);
  };

  const handleConfirmDelete = () => {
    const onSuccess = ({ data, meta }) => {
      setPromptsList(data);
      success(<Toast text={`El prompt se eliminó correctamente`} />, {
        toastId: 0,
      });
      setPaginationSettings({ current: meta.current_page, totalPages: meta.last_page });
    };
    const onError = (error) => {
      error(<Toast text={error} />, {
        toastId: 0,
      });
    };
    const onSuccessDelete = () => {
      dispatch(actListPrompts({ page: 1, ...pathParams }, onSuccess, onError));
    };
    dispatch(actDeletePrompt(currentPrompt?.id, onSuccessDelete));
    setModalDelete(false);
  };

  const onSubmitPrompt = useCallback((formData) => {
    const onSuccess = ({ data, meta }) => {
      setPromptsList(data);
      success(<Toast text={`El prompt se actualizo correctamente`} />, {
        toastId: 0,
      });
      setPaginationSettings({ current: meta.current_page, totalPages: meta.last_page });
    };
    const onError = () => {
      error(<Toast text={"ocurrio algo"} />, {
        toastId: 0,
      });
    };
    const onSuccessSubmit = () => {
      dispatch(actListPrompts({ page: 1, ...pathParams }, onSuccess, onError));
    };

    if (currentPrompt?.id) {
      const data = {
        id: currentPrompt.id,
        name: formData.name,
        prompt: formData.prompt,
        categories: formData.categories?.map((category) => {
          return category.value;
        }),
      };

      dispatch(actUpdatePrompt({ id: currentPrompt.id, data }, onSuccessSubmit));
      setFormModal(false);
    } else {
      const data = {
        name: formData.name,
        prompt: formData.prompt,
        categories: formData.categories?.map((category) => {
          return category.value;
        }),
      };
      dispatch(actCreatePrompt({ data }, onSuccessSubmit));
      setFormModal(false);
    }
  },[currentPrompt, dispatch, actUpdatePrompt, actCreatePrompt, success, error]);

  const handleCloseFormModal = () => {
    setFormModal(false);
  };

  const handleShouldAddCategory = () => {
    setShouldAddCategory(!shouldAddCategory);
  }

  const onSubmitCategory = (formData) => {
    const onSucess = () => {
      setRefresh(!refresh)
      handleShouldAddCategory();
      reset({name: ""});
      success(<Toast text={`Se agrego una nueva categoria`} />, {
        toastId: 0,
      });
    }
    const onError = () => {
      error(<Toast text={`No se pudo crear la categoria`} />, {
        toastId: 0,
      });
    }

    dispatch(actCreateCategoryPrompt({ data: formData }, onSucess, onError));
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    promptsList,
    paginationSettings,
    handlePagination,
    handleOpenDeletePrompt,
    modalDelete,
    handleConfirmDelete,
    handleOpenUpdatePrompt,
    formModal,
    onSubmitPrompt,
    currentPrompt,
    handleCloseModalDelete,
    handleOpenCreatePrompt,
    promptFormControl,
    promptSubmitForm,
    handleCloseFormModal,
    promptCategories,
    shouldAddCategory,
    handleShouldAddCategory,
    categoryControl,
    handleSubmitCategory,
    onSubmitCategory
  };
};

export default usePromptsMain;
