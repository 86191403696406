const IconArrowFilter = ({ color = "#E14731", className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.12052 1.29L6.00052 5.17L9.88052 1.29C10.2705 0.899998 10.9005 0.899998 11.2905 1.29C11.6805 1.68 11.6805 2.31 11.2905 2.7L6.70052 7.29C6.31052 7.68 5.68052 7.68 5.29052 7.29L0.70052 2.7C0.31052 2.31 0.31052 1.68 0.70052 1.29C1.09052 0.909998 1.73052 0.899998 2.12052 1.29Z"
        fill={color}
      />
    </svg>
  );
};

export default IconArrowFilter;
