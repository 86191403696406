// Packages
import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

// Components
import DatePickerField from '../DatePickerField';

const DatePickerFieldControlled = (props) => {
  const { id, name, label, control, tooltipTitle, disabled, minDate, maxDate } = props;

  const {
    field: { onChange, value, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: ""
  });

  return (
    <DatePickerField
      minDate={ minDate }
      maxDate={ maxDate }
      error={ error }
      id={ id }
      name={ name }
      label={ label }
      tooltipTitle={ tooltipTitle }
      onChange={ onChange }
      value={ value }
      inputRef={ ref }
      disabled={disabled}
    />
  )
}

DatePickerFieldControlled.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
}

export default DatePickerFieldControlled;