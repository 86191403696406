import useHome from "./home";
import useLogin from "./Login";
import useUsers from "./Users";
import useUsersGroup from "./UsersGroup";
import useProjectsScreen from "./Projects";
import useTools from "./Tools";
import useQuestions from "./Questions";
import useTasks from "./Tasks";
import usePassword from "./Password";
import useNotification from "./Notification";
import useNewsScreens from "./News";
import useContriesScreen from "./countries";
import useMapScreen from "./Map";
import useBrandsScreen from "./Brands";
import usePaymentsScreen from "./Payments";
import useFaqs from "./Faqs";
import useProfiler from "./Profiler";
import useDashboardScreens from "./Dashboard";
import useAnalytic from "./Analytic";
import useMembeers from "./Membeers";
import usePowerBi from "./PoweBi";
import useGamification from "./Gamification";
import useLegal from "./Legal";
import usePrompt from "./Prompts";

const useScreenHooks = () => {
  return {
    useHome,
    useTools,
    useQuestions,
    useLogin,
    useUsers,
    useTasks,
    useProjectsScreen,
    usePassword,
    useUsersGroup,
    useNotification,
    useNewsScreens,
    useContriesScreen,
    useBrandsScreen,
    usePaymentsScreen,
    useFaqs,
    useProfiler,
    useDashboardScreens,
    useAnalytic,
    useMembeers,
    usePowerBi,
    useGamification,
    useMapScreen,
    useLegal,
    usePrompt,
  };
};

export default useScreenHooks;
