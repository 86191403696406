import React from "react";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

// Styles
import { StyledFormControl, StyledSelectField, StyledHelperText } from "./SelectField.styles";

// Assets
import useIcons from "../../../assets/icons";

// Components
import useComponents from "../";

const SelectField = ({
  id,
  label,
  tooltipTitle,
  options = [],
  selectedValue,
  placeholder,
  error,
  helperText,
  disabled,
  ...rest
}) => {
  const { IconArrowDown } = useIcons();
  const { FormLabel } = useComponents();

  return (
    <StyledFormControl fullWidth>
      <label htmlFor={id}>
        <FormLabel label={label} tooltipTitle={tooltipTitle} />
      </label>
      <StyledSelectField
        error={Boolean(error)}
        variant="standard"
        displayEmpty
        labelId={id}
        disabled={disabled}
        IconComponent={IconArrowDown}
        value={selectedValue ?? ""}
        {...rest}
      >
        <MenuItem value="">
          <span className="text-red text-neutral-70 font-light text-sm">
            {placeholder ? placeholder : label}
          </span>
        </MenuItem>
        {options?.map((option) => (
          <MenuItem key={option?.value} value={option?.value} disabled={option?.disabled}>
            {option?.label}
          </MenuItem>
        ))}
      </StyledSelectField>
      {error && (
        <StyledHelperText>{helperText || error?.message}</StyledHelperText>
      )}
    </StyledFormControl>
  );
};

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  error: PropTypes.object,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SelectField;
