// Package
import React from 'react';
import PropType from 'prop-types';

//Stytes
import { StyledCardWrapper } from './CardWrapper.styles'

const CardWrapper = (props) => {
  const { children, widthContent, className, padding } = props;

  return (
    <StyledCardWrapper
      padding={ padding }
      className={ className }
      widthContent={ widthContent }
    >
      { children }
    </StyledCardWrapper>
  )
}

CardWrapper.propType = {
  widthContent: PropType.string,
  className: PropType.string,
  padding: PropType.string
}

CardWrapper.defaultProps = {
  widthContent: "100%",
  padding: "4rem"
}
export default CardWrapper;