// Packages
import { useNavigate } from "react-router-dom";

// Steps controllers
import useTaskView from "./Views";
import useTaskCreate from "./Create";
import useTasksClone from "./Clone";
import useAnswersMission from "./Answers";

const useTasks = () => {
  // State
  const navigation = useNavigate();
  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/misiones": ["Misiones"],
    "/misiones/clonacion": ["Misiones", "Asociar Misión"],
    "/misiones/creacion": ["Misiones", "crear Misión"],
    "/misiones/detalle": ["Misiones", "Detalle de la Misión"],
    "/misiones/editar": ["Misiones", "Editar Misión"],
    "/misiones/preview": ["Misiones", "Resultados"],
  };

  return {
    handleBack,
    useTasksClone,
    useTaskView,
    useTaskCreate,
    useAnswersMission,
    header,
  };
};

export default useTasks;
