import * as yup from "yup";
import { mixed } from "yup/lib/locale";

/**
 *
 * @author Serempre
 * @name useValidators
 * @type {Function}
 * @description Custom hook to manage the inputs validation schema
 * @param {} -
 *
 * @return {Function}
 */
const useValidators = () => {
  /**
   * Common validations functions
   */

  const string = yup.string();
  const email = yup.string().email("Debe ser un correo electrónico válido");
  const number = yup
    .number()
    .min(0, "El valor no puede ser menor a 0")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr));
  const password = yup
    .string()
    .min(8, "La contraseña debe tener 8 o más caracteres")
    .max(15, "La contraseña debe tener 15 o menos caracteres");
  const passwordLogin = yup
    .string()
    .min(8, "La contraseña debe tener 8 o más caracteres")
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      "Formato no coincide"
    );
  const confirmPassword = yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden");
  // const alphaNumericStringInput = yup.string();
  const alphaLettersStringInput = yup
    .string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ ,.'-]+$/i, "Debe contener solo letras");
  const isCheck = yup.boolean();
  const date = yup
    .date()
    .typeError("Formato invalido")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr));
  const time = yup
    .date()
    .typeError("Formato invalido")
    .nullable()
    .default(undefined)
    .transform((curr, orig) => (orig === "" ? null : curr));
  const autoComplete = yup
    .object()
    .shape({
      id: string,
      label: string,
    })
    .nullable();

  // Schemas
  const loginValidatorSchema = yup.object().shape({
    email: email.required("Este campo es requerido"),
    password: passwordLogin.required("Este campo es requerido"),
  });
  const recoveryPasswordValidatorSchema = yup.object().shape({
    password: passwordLogin.required("Este campo es requerido"),
    confirmPassword: confirmPassword.required("Este campo es requerido"),
  });

  const forgotValidatorSchema = yup.object().shape({
    email: email.required("Se requiere la dirección de correo electrónico"),
  });

  const toolsValidationSchema = yup.object().shape({
    typeTool: string.required("Este campo es requerido"),
    validation: isCheck,
    profiling: isCheck,
    name: string.required("Debe especificar un nombre"),
    language: string.required("Este campo es requerido"),
    description: string.required("Este campo es requerido"),
    startDate: date,
    endingDate: date,
    startTime: time,
    endingTime: time,
    startAmPm: string,
    endingAmPm: string,
    mission: autoComplete,
  });

  const toolsAIValidationSchema = yup.object().shape({
    ai_prompt: string.required("Este campo es requerido"),
  });

  const sectionValidationShema = yup.object().shape({
    search: string,
    type: string,
  });

  const filtersValidationShema = yup.object().shape({
    search: string,
    type: string,
  });

  const createSectionValidationShema = yup.object().shape({
    title: string.required("Campo requerido"),
    description: string.required("Campo requerido"),
  });

  const questionValidationShema = yup.object().shape({
    question_type: string,
    required: isCheck,
    title: string,
    textQuestion: string,
    textResponse: string
      .typeError("Campo requerido")
      .default(undefined)
      .transform((curr, orig) => (orig === "" ? null : curr)),
    // UploadFileResponse
    file_types: string,
    max_files: number,
    max_file_size: number,
    // MultipleChoiceResponse
    options: yup.array().of(
      yup.object().shape({
        name: string.required("Campo requerido"),
        is_correct: isCheck,
        file: yup.mixed(),
      })
    ),
    in_random_order: isCheck,
    // Range
    value_start: number,
    value_end: number,
    label_start: string,
    label_end: string,
    // Date
    init_date: date,
    end_date: date,
    inclusive_date: isCheck,
    // File
    file: yup.mixed(),
    // Matrix
    rows: yup.array().of(
      yup.object().shape({
        name: string.required("Campo requerido"),
      })
    ),
    columns: yup.array().of(
      yup.object().shape({
        name: string.required("Campo requerido"),
        value: string.required("Campo requerido"),
      })
    ),
  });

  const passiveTrackingCreateShema = yup.object().shape({
    requestEvidence: isCheck,
    steps: yup.array().of(
      yup.object().shape({
        passive_tracking_id: yup.number().required("Campo requerido"),
        name: string,
        address: string,
        lat: yup.number().required("Campo requerido"),
        lng: yup.number().required("Campo requerido"),
        init_date: date,
        end_date: date,
        init_time: time,
        end_time: time,
        init_AmPm: string,
        end_AmPm: string,
      })
    ),
  });

  const referredValidationShema = yup.object().shape({
    min_referrals: number.required("Campo requerido"),
    max_referrals: number.required("Campo requerido"),
  });

  const videoCallValidationShema = yup.object().shape({
    name: string.required("Campo requerido"),
    max_users: number,
    file: yup.mixed(),
  });

  const FilterUserValidationShema = yup.object().shape({
    abiFilterCountry: string,
  });
  const FilterTaskValidationShema = yup.object().shape({
    abiFilterProject: string,
    abiFilterStatus: string,
  });
  const FilterMapValidationSchema = yup.object().shape({
    mapFilterRegion: string,
    mapFilterGender: string,
    mapFilterNse: string,
    mapFitlerCity: string,
    mapFilterAge: string,
  });
  const cloneTaskValidationShema = yup.object().shape({
    project_id: string,
  });

  const editUserValidationShema = yup.object().shape({
    country_id: string.required("Campo requerido"),
    name: string.required("Campo requerido"),
    last_name: string.required("Campo requerido"),
    email: email.required("Campo requerido"),
    birth_date: date.required("Campo requerido"),
    abi_team: string.required("Campo requerido"),
    rol: number,
  });

  const newUserValidationShema = yup.object().shape({
    country_id: string.required("Campo requerido"),
    name: string.required("Campo requerido"),
    last_name: string.required("Campo requerido"),
    email: email.required("Campo requerido"),
    birth_date: date.required("Campo requerido"),
    abi_team: string.required("Campo requerido"),
    rol: number.required("Campo requerido"),
  });

  const newTaskValidationShema = yup.object().shape({
    name: string.required("Campo requerido"),
    project_id: yup.object().nullable().default(null),
    country_id: yup.array().of(
      yup.object().shape({
        label: string,
        value: string,
      })
    ),
    description: string,
    app_init_text: string.required("Campo requerido"),
    app_end_text: string.required("Campo requerido"),
    mission_completed_text: string.required("Campo requerido"),
    mission_not_completed_text: string.required("Campo requerido"),

    full_members_text: string
      .nullable()
      .default(null)
      .transform((curr, orig) => (orig === "" ? null : curr)),
    startDate: date.required("Campo requerido"),
    startTime: time.required("Campo requerido"),
    startAmpm: string,
    endDate: date.required("Campo requerido"),
    endTime: time.required("Campo requerido"),
    endAmpm: string,
    points: yup.array().of(
      yup.object().shape({
        currency: string.required("Campo requerido"),
        app_points: number.required("Campo requerido"),
        points_conversion: number.required("Campo requerido"),
      })
    ),
    reminders: yup.array().of(
      yup.object().shape({
        message: string.required("Campo requerido"),
        date: date.required("Campo requerido"),
        time: time.required("Campo requerido"),
        ampm: string,
      })
    ),
    sample_size: yup.array().of(
      yup.object().shape({
        autoclose_cap: string
          .nullable()
          .default(null)
          .transform((curr, orig) => (orig === "" ? null : curr)),
        sample_proportion: yup.boolean(),
      })
    ),
  });

  const newGroupValidationShema = yup.object().shape({
    name: string.required("Campo requerido"),
    countries: yup.array(),
    departments: yup.array(),
    regions: yup.array(),
    abi_regions: yup.array(),
    nse: yup.array(),
    ages: yup.array(),
    genders: yup.array(),
    parameters: yup.array().of(
      yup.object().shape({
        tool_id: yup.array(),
        question_id: string.nullable(),
        answer: yup.array(),
      })
    ),
  });

  const newProjectSchema = yup.object().shape({
    name: string.required("Campo requerido"),
    client_id: string.required("Campo requerido"),
    //expiration_date: date.required("Campo requerido"),
    objective: string.required("Campo requerido"),
    decision: string.required("Campo requerido"),
    country_id: yup.array().min(1, "Debe seleccionar uno").nullable().required("Campo requerido"),
    tracking_count: number
      .when("tracking", {
        is: true,
        then: number.required("Campo requerido"),
      })
      .max(10, "Numero maximo permitido hasta 10")
      .min(0, "Numero minimo permitido es 0"),
  });

  const newGamificationBadgeSchema = yup.object().shape({
    name: string.required("Campo requerido"),
    file: yup.mixed().required("Campo requerido"),
  });

  const newGamificationTierSchema = yup.object().shape({
    name: string.required("Campo requerido"),
    badge_id: number.required("Campo requerido"),
    file: yup.mixed().required("Campo requerido"),
  });
  const newGamificationCategorySchema = yup.object().shape({
    name: string.required("Campo requerido"),
    title: string.required("Campo requerido"),
    medal_character_id: number.required("Campo requerido"),
    colors: string.nullable(),
  });

  const newGamificationMissionSchema = yup.object().shape({
    badge_id: number.required("Campo requerido"),
    tier_id: number.required("Campo requerido"),
    missions_min: number.required("Campo requerido").min(0, "Solo números positivos"),
    missions_max: number.required("Campo requerido").min(0, "Solo números positivos"),
  });

  const newGamificationCharacterSchema = yup.object().shape({
    badge_id: number,
    tier_id: number,
    name: string.required("Campo requerido"),
    file: yup.mixed().required("Campo requerido"),
  });

  const newGamificationImgSchema = yup.object().shape({
    badge_id: number,
    tier_id: number,
    category_id: number.required("Campo requerido"),
    name: string.required("Campo requerido"),
    file: yup.mixed().required("Campo requerido"),
    file_preview: yup.mixed().required("Campo requerido"),
  });

  const newNewsSchema = yup.object().shape({
    name: string.required("Campo requerido"),
    text: string.required("Campo requerido"),
    country_id: yup.array().required("Campo requerido"),
    author: string.required("Campo requerido"),
    //date: string.required("Campo requerido"),
    //time: string.required("Campo requerido"),
    //ampm: string.required("Campo requerido"),
    index: number.required("Campo requerido"),
    image_intern_url: yup.mixed().required("Campo requerido"),
    image_home_url: yup.mixed().required("Campo requerido"),
    status: string.required("Campo requerido"),
  });

  const filterptojectValidationShema = yup.object().shape({
    name: string,
    client_id: string,
    country_id: string,
  });
  const filterNewsValidationShema = yup.object().shape({
    search: string,
    status: string,
    country_id: string,
  });

  const filterNotificationValidationShema = yup.object().shape({
    // group_id: string,
    // range_date: string,
    status: string,
    type: string,
  });

  const filterMembeersValidationShema = yup.object().shape({
    status: string,
  });

  const newNotificationValidationShema = yup.object().shape(
    {
      name: string.required("Campo requerido"),
      group: string.required("Campo requerido"),
      membeer_id: string,
      reminder: yup.boolean(),
      publicDate: date.required("Campo requerido"),
      publicTime: time.required("Campo requerido"),
      publicAmpm: string,
      message_push: string,
      reminder_push: yup.boolean().when("reminder_email", {
        is: (reminder_email) => !reminder_email,
        then: yup.boolean().oneOf([true], "Un tipo de notificación es requerido"),
      }),
      reminder_email: yup.boolean().when("reminder_push", {
        is: (reminder_push) => !reminder_push,
        then: yup.boolean().oneOf([true], "Un tipo de notificación es requerido"),
      }),
      entity: string,
      entity_id: yup.object().nullable().shape({
        label: string,
        value: number,
      }),
      email_subject: string.nullable(),
      message_email: string,
      header_image: yup.mixed(),
      reminders: yup.array().of(
        yup.object().shape({
          id: number,
          date: string.required("Campo requerido"),
          time: string.required("Campo requerido"),
          ampm: string.required("Campo requerido"),
        })
      ),
    },
    [
      ["reminder_email", "reminder_push"],
      ["reminder_push", "reminder_email"],
    ]
  );

  const countriesSchema = yup.object().shape({
    country_id: string.required("Campo requerido"),
    documents: yup.array().of(
      yup.object().shape({
        type: string.required("Campo requerido"),
        acronym: string.required("Campo requerido"),
      })
    ),
    abi_regions: yup.array().of(
      yup.object().shape({
        region: string.required("Campo requerido"),
        population_distribution: string.required("Campo requerido"),
      })
    ),
    banks: yup.array().of(
      yup.object().shape({
        name: string.required("Campo requerido"),
        validations: yup.object().shape({
          nit: yup.object().shape({
            min: yup.number()
              .min(1, "Solo numeros positivos")
              .nullable()
              .default(null)
              .transform((curr, orig) => (orig === "" ? undefined : curr)),
            max: yup.number()
              .min(1, "Solo numeros positivos")
              .nullable()
              .default(null)
              .transform((curr, orig) => (orig === "" ? undefined : curr))
              .when('min', (min, schema) => {
                return schema.test({
                  test: function (max) {
                    return max >= min || this.createError({ message: 'El campo debe ser mayor o igual' });
                  }
                });
              }),
          }),
          mobile: yup.object().shape({
            min: yup
              .number()
              .min(1, "Solo numeros positivos")
              .nullable()
              .default(null)
              .transform((curr, orig) => (orig === "" ? undefined : curr)),
            max: yup
              .number()
              .min(1, "Solo numeros positivos")
              .nullable()
              .default(null)
              .transform((curr, orig) => (orig === "" ? undefined : curr))
              .when('min', (min, schema) => {
                return schema.test({
                  test: function (max) {
                    return max >= min || this.createError({ message: 'El campo debe ser mayor o igual' });
                  }
                });
              }),
          }),
          account_number: yup.object().shape({
            min: yup
              .number()
              .nullable()
              .default(null)
              .min(1, "Solo numeros positivos")
              .transform((curr, orig) => (orig === "" ? undefined : curr)),
            max: yup
              .number()
              .min(1, "Solo numeros positivos")
              .nullable()
              .default(null)
              .transform((curr, orig) => (orig === "" ? undefined : curr))
              .when('min', (min, schema) => {
                return schema.test({
                  test: function (max) {
                    return max >= min || this.createError({ message: 'El campo debe ser mayor o igual' });
                  }
                });
              }),
          }),
        }),
      })
    ),
    brands: yup.array().min(1, "Debe seleccionar uno").nullable().required("Campo requerido"),
    active: yup.number().nullable().required("Campo requerido "),
    nse: yup.array().of(
      yup.object().shape({
        low: number.typeError("Este campo debe ser numerico").required("Campo requerido"),
        mid: number.typeError("Este campo debe ser numerico").required("Campo requerido"),
        high: number.typeError("Este campo debe ser numerico").required("Campo requerido"),
      })
    ),
    age_distribution: yup.array().of(
      yup.object().shape({
        small: number.typeError("Este campo debe ser numerico").required("Campo requerido"),
        medium: number.typeError("Este campo debe ser numerico").required("Campo requerido"),
        normal: number.typeError("Este campo debe ser numerico").required("Campo requerido"),
        big: number.typeError("Este campo debe ser numerico").required("Campo requerido"),
      })
    ),
    currency_membeer_point: string.required("Campo requerido"),
    languaje: string.required("Campo requerido"),
  });

  const paymetsSchemaCreate = yup.object().shape({
    pay_type: string.required("Campo requerido"),
    date: string.required("Campo requerido"),
    time: string.required("Campo requerido"),
    ampm: string.required("Campo requerido"),
    concept: string.required("Campo requerido"),
    mission_id: string.required("Campo requerido"),
    point_mission: string.required("Campo requerido"),
    mail_subject: string.required("Campo requerido"),
    mail_message: string.required("Campo requerido"),
    active: string.required("Campo requerido"),
  });

  const filterCountriesShema = yup.object().shape({
    iso: string,
    lenguaje: string,
    currency_name: string,
    currency_symbol: string,
  });

  const brandSchema = yup.object().shape({
    name: string.required("Campo requerido"),
    description: string.required("Campo requerido"),
    photo: yup.mixed().required("Campo requerido"),
    status: string.required("Campo requerido"),
  });

  const filterBrandsShema = yup.object().shape({
    status: string,
    init_date: string,
    end_date: string,
  });
  const newFaqsValidationShema = yup.object().shape({
    id: number,
    country_id: string.required("Campo requerido"),
    status: string,
    faqs: yup.array().of(
      yup.object().shape({
        id: string,
        question: string.required("Campo requerido"),
        answer: string.required("Campo requerido"),
        index: number,
        faq_resume_id: number,
      })
    ),
  });

  const profilerLicenceValidationSchema = yup.object().shape({
    country_id: string.required("Campo requerido"),
    total_licenses: number.required("Campo requerido"),
    in_use_licenses: number,
    unused_licenses: number,
  });

  const profilerRegionValidationSchema = yup.object().shape({
    region: string.required("Campo requerido"),
    country_id: string.required("Campo requerido"),
    state_id: yup
      .array()
      .of(
        yup.object().shape({
          label: string,
          value: number,
        })
      )
      .min(1, "Campo requerido"),
    // city_id: yup.array().of(yup.object().shape({
    //   label: string, value:number
    // })).min(1, "Campo requerido"),
    licenses: number.required("Campo requerido"),
  });

  const profilerRegionABIValidationSchema = yup.object().shape({
    abi_region: string.required("Campos requerido"),
    // region: string.required("Campo requerido"),
    country_id: string.required("Campo requerido"),
    state_id: yup
      .array()
      .of(
        yup.object().shape({
          label: string,
          value: number,
        })
      )
      .min(1, "Campo requerido"),
    // city_id: yup.array().of(yup.object().shape({
    //   label: string, value:number
    // })).min(1, "Campo requerido"),
    licenses: number.required("Campo requerido"),
  });

  // const profilerRegionABIValidationSchema = yup.object().shape({
  //   abi_region: string.required("Campo requerido"),
  //   region: string.required("Campo requerido"),
  //   country_id: string.required("Campo requerido"),
  //   state_id: yup.array().of(yup.string()).required("Campo requerido"),
  //   cities: yup.array().of(yup.string()),
  //   licenses: number.required("Campo requerido"),
  // })

  const profilerAgeValidationSchema = yup.object().shape({
    country_id: string.required("Campo requerido"),
    // region: string.required("Campo requerido"),
    abi_region: string.required("Campo requerido"),
    min: number.required("Campo requerido"),
    max: number.required("Campo requerido"),
    licenses: number.required("Campo requerido"),
  });

  const profilerGenderValidationSchema = yup.object().shape({
    country_id: string.required("Campo requerido"),
    // region: string.required("Campo requerido"),
    abi_region: string.required("Campo requerido"),
    gender: string.required("Campo requerido"),
    licenses: number.required("Campo requerido"),
  });

  const profilerNSEValidationSchema = yup.object().shape({
    country_id: string.required("Campo requerido"),
    // region: string.required("Campo requerido"),
    abi_region: string.required("Campo requerido"),
    nse: number.typeError("Debe ingresar un valor numerico").required("Campo requerido"),
    min: number.typeError("Debe ingresar un valor numerico").required("Campo requerido"),
    max: number.typeError("Debe ingresar un valor numerico").required("Campo requerido"),
    licenses: number.typeError("Debe ingresar un valor numerico").required("Campo requerido"),
  });

  const paymentsCountryShema = yup.object().shape({
    country_id: string.required("Campo requerido"),
  });

  const filterPaymentsValidationShema = yup.object().shape({});
  const filterPaymentsDetailValidationShema = yup.object().shape({});

  const dashboardSchema = yup.object().shape({
    country_id: string.required("Campo requerido"),
  });

  const mapSchema = yup.object().shape({
    country_id: string.required("Campo requerido"),
  });

  const filterLegalShema = yup.object().shape({
    country_id: string,
  });

  const legalValidationShema = yup.object().shape({
    country_id: string.required("Campo requerido"),
    file_tyc: yup.mixed().required("Campo requerido"),
    file_privacy: yup.mixed().required("Campo requerido"),
  });

  const promptValidationSchema = yup.object().shape({
    name: string.required("Campo requerido"),
    prompt: string.required("Campo requerido"),
    categories: yup.array().of(
      yup.object().shape({
        label: string,
        value: number,
      })
    )
    .min(1, "Debe haber al menos una categoría")
  })

  const promptByCategoryValidationSchema = yup.object().shape({
    categories: yup.array().of(
      yup.object().shape({
        label: string,
        value: number,
      })
    )
    .min(1, "Debe haber al menos una categoría")
  })

  const categoryValidationSchema = yup.object().shape({
    name: string.required("Proporcione un nombre"),
  })

  return {
    loginValidatorSchema,
    toolsAIValidationSchema,
    toolsValidationSchema,
    sectionValidationShema,
    createSectionValidationShema,
    questionValidationShema,
    FilterUserValidationShema,
    newUserValidationShema,
    editUserValidationShema,
    FilterTaskValidationShema,
    FilterMapValidationSchema,
    cloneTaskValidationShema,
    newTaskValidationShema,
    forgotValidatorSchema,
    recoveryPasswordValidatorSchema,
    newGroupValidationShema,
    passiveTrackingCreateShema,
    newProjectSchema,
    filterptojectValidationShema,
    filterNewsValidationShema,
    newNewsSchema,
    referredValidationShema,
    videoCallValidationShema,
    filterNotificationValidationShema,
    newNotificationValidationShema,
    countriesSchema,
    filterCountriesShema,
    filtersValidationShema,
    brandSchema,
    filterBrandsShema,
    filterPaymentsValidationShema,
    paymetsSchemaCreate,
    filterPaymentsDetailValidationShema,
    newFaqsValidationShema,
    profilerLicenceValidationSchema,
    profilerRegionValidationSchema,
    profilerRegionABIValidationSchema,
    profilerAgeValidationSchema,
    profilerGenderValidationSchema,
    profilerNSEValidationSchema,
    paymentsCountryShema,
    filterMembeersValidationShema,
    dashboardSchema,
    mapSchema,
    newGamificationBadgeSchema,
    newGamificationTierSchema,
    newGamificationMissionSchema,
    newGamificationImgSchema,
    newGamificationCategorySchema,
    newGamificationCharacterSchema,
    legalValidationShema,
    filterLegalShema,
    promptValidationSchema,
    promptByCategoryValidationSchema,
    categoryValidationSchema,
  };
};

export default useValidators;
