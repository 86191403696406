// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

const UsersCreate = () => {
  const { useScreenHooks } = useControllers();
  const { useUsers } = useScreenHooks();
  const { useUsersCreate } = useUsers();
  const {
    control,
    handleSubmit,
    onSubmit,
    showModal,
    handleCloseModal,
    activateUser,
    handleChangeActivate,
    listRoles,
    listCountries,
  } = useUsersCreate();

  const {
    ContentWrappers,
    DatePickerFieldControlled,
    RadioButtonControlled,
    useButtons,
    TextFieldControlled,
    SelectFieldControlled,
    FormLabel,
    Typography,
    InputSwitch,
    useModals,
  } = useComponents();

  const { DefaultModal } = useModals();

  const { DefaultButton } = useButtons();

  return (
    <div className="bg-white px-16 pt-10 pb-12 mx-24 rounded-lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentWrappers
          maxCol='48%'
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
          <TextFieldControlled
            type="text"
            id="abi-user-name"
            control={control}
            label="Nombre"
            placeholder="Nombre"
            name="name"
          />
          <TextFieldControlled
            type="text"
            id="abi-user-lastname"
            control={control}
            label="Apellido"
            placeholder="Apellido"
            name="last_name"
          />
        </ContentWrappers>
        <ContentWrappers
          gridColums={["48%", "15vw"]}
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
          <TextFieldControlled
            type="email"
            id="abi-user-email"
            control={control}
            label="Email"
            placeholder="Email"
            name="email"
          />
          <div>
            <FormLabel label="Fecha de nacimiento" />
            <DatePickerFieldControlled
              name="birth_date"
              id="abi-user-date"
              control={control}
              maxDate={new Date().toString()}
            />
          </div>
        </ContentWrappers>
        <ContentWrappers
          maxCol='48%'
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
          <SelectFieldControlled
            label={"Equipo ABI"}
            placeholder={"Selecciona una opción"}
            name={`abi_team`}
            id={`abi-user-team`}
            control={control}
            options={[
              { label: "Global", value: "Global" },
            ]}
          />
          <SelectFieldControlled
            label={"País"}
            placeholder={"Selecciona una opción"}
            name={`country_id`}
            id={`abi-country`}
            control={control}
            options={listCountries}
          />
        </ContentWrappers>
        <FormLabel label="Rol" />
        <div className="mt-4 mb-7">
          <RadioButtonControlled
            id="abi-user-rol"
            name="rol"
            control={control}
            radioOptions={listRoles}
          />
        </div>
        <InputSwitch
          id={"abi-user-active"}
          name={"active"}
          onChange={handleChangeActivate}
          label={activateUser}
        />
        <div className="text-center">
          <DefaultButton color="secondary" type="submit">
            Crear Usuario
          </DefaultButton>
        </div>
      </form>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2">El usuario ha sido creado con éxito</Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </div>
  );
};

export default UsersCreate;
