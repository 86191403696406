// Packages
import React from "react";

// Styles
import { StyledSearch, StyledContentButtons } from "./UsersView.styles";
import { StyledFormFilter } from "../../Tasks/Views/TaskView.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

const UsersViews = () => {

  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    SelectFieldControlled,
    Pagination,
    TextFieldSeach,
    Typography,
    useModals,
    Dropdown,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useUsers } = useScreenHooks();
  const { useUsersView } = useUsers();
  const {
    control,
    handleSubmit,
    onSubmit,
    listUsers,
    handleCreateNewUser,
    handleEditUser,
    listCountries,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    listRoles,
    handleDeleteUser,
    modalDelete,
    handleCloseModal,
    handleConfirmDelete,
  } = useUsersView();

  return (
    <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-11/12" as="section">
      <div className="bg-white px-8 pt-10 pb-12 rounded-lg">
        <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
          <TextFieldSeach
            placeholder="Buscar"
            name="search"
            id="search"
            control={control}
            onInputChange={onChangeSearch}
          />
          <StyledContentButtons>
            <FilterButton handleSubmit={handleSubmit(onSubmit)}>
              <StyledFormFilter>
                <SelectFieldControlled
                  placeholder={"Pais"}
                  label={"Pais"}
                  name={`abiFilterCountry`}
                  id={`abi-filter-country`}
                  control={control}
                  options={listCountries}
                />
                <SelectFieldControlled
                  placeholder={"Rol"}
                  label={"Rol"}
                  name={`abiFilterRol`}
                  id={`abi-filter-rol`}
                  control={control}
                  options={listRoles}
                />
                <SelectFieldControlled
                  placeholder={"Estado"}
                  label={"Estado"}
                  name={`abiFilterStatus`}
                  id={`abi-filter-status`}
                  control={control}
                  options={[
                    { label: "Activo", value: true },
                    { label: "Inactivo", value: false },
                  ]}
                />
              </StyledFormFilter>
            </FilterButton>
            <DefaultButton color="secondary" onClick={handleCreateNewUser}>
              Crear Usuario
            </DefaultButton>
          </StyledContentButtons>
        </ContentWrappers>
        <TableContent
          headerCellList={[
            "Id",
            "Nombre completo",
            "Email",
            "Pais",
            "Equipo Abi",
            "Rol",
            "Estado",
            "Acciones",
          ]}
          gridCell={["0.5fr", "1.5fr", "1.5fr", "0.5fr", "1fr", "1.2fr", "0.75fr", "66px"]}
        >
          {listUsers.map((user, index) => {
            return (
              <TableRow key={index}>
                <div>{user?.id}</div>
                <div>
                  <b>{`${user?.name} ${user?.last_name}`}</b>
                </div>
                <div> {user?.email} </div>
                <div> {user.country?.iso2} </div>
                <div> {user?.abi_team} </div>
                <div> {user?.rol[0]?.name} </div>
                <div>
                  <p
                    className={`${
                      user.active
                        ? "text-success-600 border-success-200 bg-success-50"
                        : "text-danger-600 border-danger-200 bg-danger-150"
                    } border rounded-full text-center w-min px-2`}
                  >
                    {user.active ? "Activo" : "Inactivo"}
                  </p>
                </div>
                <div className="flex justify-end">
                  <Dropdown options={[
                    {id: 'edit', action: ()=> handleEditUser(user)},
                    {id: 'delete', action: ()=> handleDeleteUser(user.id)},
                    ]}/>
                </div>
              </TableRow>
            );
          })}
        </TableContent>
      </div>
      <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      <DefaultModal isOpen={modalDelete.show}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            Esta seguro de eliminar el usuario <b>{modalDelete.name}</b>.
          </Typography>
          <div className="flex items-center space-x-4">
            <DefaultButton color="secondary" onClick={handleCloseModal}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="success" onClick={handleConfirmDelete}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
    </ContentWrappers>
  );
};

export default UsersViews;
