import { useEffect, useState, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useController } from "react-hook-form";

const useInputFile = (onChange, accept, maxFiles, maxSize, control, id) => {
  const [files, setFiles] = useState([]);
  const [filesName, setFilesName] = useState([]);
  const [errorFile, setErrorFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [relation, setRelation] = useState(1);
  const inputHeight = useRef(null);
  const inputWidth = useRef(null);
  const {
    field: { value: valueBlock },
  } = useController({
    name: `${id}_block`,
    control,
  });
  const {
    field: { onChange: onChangeFileWidth, value: valueWidth},
  } = useController({
    name: `${id}_width`,
    control,
  });
  const {
    field: { onChange: onChangeFileHeight, value: valueHeight},
  } = useController({
    name: `${id}_height`,
    control,
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setFiles([...acceptedFiles]);

      let name = [];

      if (
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ).length > 0
      ) {
        loadImage(acceptedFiles[0]);
        onChange(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        acceptedFiles
          .map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
          .map((file) => {
            return name.push(file.name);
          });
      }

      setFilesName(name.join(", "));
    },
    [files]
  );

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept,
    maxFiles: maxFiles,
    maxSize: maxSize,
    onDrop,
  });

  const removeFiles = useCallback(
    (file) => {
      const newFiles = Array.from(files);
      newFiles.splice(newFiles.indexOf(file), 1);
      setFiles(newFiles);
      onChange(newFiles)

      if (newFiles.length < 1) {
        setShowModal(false);
        setFilesName([])
      }
    },
    [files]
  );

  const messages = {
    "too-many-files": `Solo puede seleccionar ${maxFiles} archivo`,
    "file-too-large": `El tamaño del archivo exede los limites permitidos`,
  };

  const messagesError = (errors) => {
    const errorsList = errors?.map((error) => {
      return { code: error.code, message: messages[error.code] };
    });
    return errorsList;
  };

  useEffect(() => {
      if(valueBlock) {
        setRelation(valueWidth/valueHeight)
      }
  }, [valueBlock])
  
  useEffect(() => {
    if (fileRejections?.length !== 0) {
      fileRejections?.map(({ errors }) => {
        setErrorFile(messagesError(errors));
      });
    } else {
      setErrorFile([]);
    }
  }, [fileRejections]);

  // Show Preview modal
  const hadledModalPreview = useCallback(() => {
    if (files.length > 0) {
      setShowModal(true);
    }
  }, [files]);

  const handledCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const loadImage = (imagen) => {
    var _URL = window.URL || window.webkitURL;
    var img = new Image();
    img.src = _URL.createObjectURL(imagen);
    if(inputHeight.current){
      img.onload = function () {
        inputHeight.current.value = img.height;
        inputWidth.current.value = img.width;
        onChangeFileWidth(img.width)
        onChangeFileHeight(img.height)
        setRelation(img.width/img.height)
      };
    }
  };

  const handleChangeRelation = (e) => {
    if(!!valueBlock) {
      if(e.target.name === "file_width") {
        inputHeight.current.value =  parseInt(e.target.value / relation)
        onChangeFileWidth(e)
        onChangeFileHeight(parseInt(e.target.value / relation))
      } else {
        inputWidth.current.value = parseInt(e.target.value * relation)
        onChangeFileWidth(parseInt(e.target.value * relation))
        onChangeFileHeight(e)
      }
    }
    if(e.target.name === "file_width") {
      onChangeFileWidth(e)
    } else {
      onChangeFileHeight(e)
    }
  }

  return {
    getRootProps,
    getInputProps,
    open,
    files,
    removeFiles,
    filesName,
    errorFile,
    hadledModalPreview,
    showModal,
    handledCloseModal,
    inputHeight,
    inputWidth,
    handleChangeRelation,
    valueWidth,
    valueHeight,
  };
};

export default useInputFile;
