import React from "react";

// Components
import useComponents from "../../../../../components";

// Controllers
import useControllers from "../../../../../../controllers";

const FormGamificationName = () => {
  const {
    useButtons,
    TextFieldControlled,
    Typography,
    InputFileControlled,
    SelectFieldControlled,
    AutocompleteFieldControlled,
  } = useComponents();

  const { DefaultButton } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useGamification } = useScreenHooks();
  const { useGamificationCreate } = useGamification();

  const { title, control, handleSubmit, onSubmit, saving, type, listCharacter, listBadges, onChangeSearchCategory, listCategory } =
    useGamificationCreate();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3" className="uppercase">
        1. Configuración general de {title}
      </Typography>
      <div className="mt-4">
        <TextFieldControlled control={control} name="name" id="name" label={"Nombre"} />
        {type === 1 && (
          <SelectFieldControlled
            label="Insignia"
            name="badge_id"
            id="badge_id"
            control={control}
            options={listBadges}
          />
        )}
      </div>
      {(type === 0 || type === 1) && (
        <InputFileControlled
          label={"Agregar accesorio (svg)"}
          placeholder={"image.jpg"}
          name="file"
          id="file"
          accept=".svg"
          control={control}
          directPreview
          maxFiles={1}
          showPreview
        />
      )}
      {type === 4 && (
        <div className="mt-2">
          <TextFieldControlled
            control={control}
            name="title"
            id="title"
            label={"Título"}
            placeholder="Título de la categoria"
          />
          <SelectFieldControlled
            label="Personaje"
            name="medal_character_id"
            id="medal_character_id"
            control={control}
            options={listCharacter}
          />
          <TextFieldControlled
            control={control}
            name="sort_list"
            id="sort_list"
            label={"Orden de la capa"}
          />
          <AutocompleteFieldControlled
            label={"Sub Categoria"}
            name={`sub_category_id`}
            id={`sub_category_id`}
            control={control}
            options={listCategory}
            handleInputChange={onChangeSearchCategory}
          />
          <TextFieldControlled
            control={control}
            name="colors"
            id="colors"
            label={"Colores"}
            placeholder="#000000,#FFFFFF"
            helperText="Ingrese los hexadecimanles separados por ,"
          />
        </div>
      )}
      <div className="text-center mt-4">
        <DefaultButton color="secondary" type="submit" disabled={saving}>
          Finalizar y publicar
        </DefaultButton>
      </div>
    </form>
  );
};

export default FormGamificationName;
