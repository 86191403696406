import useMultipleChoiceResponse from "./useMultipleChoiceResponse";
import useQuestionList from "./useQuestionList";
import useUploadFilesResponse from "./useUploadFilesResponse";
import useDateResponse from "./useDateResponse";
import useMatrixResponse from "./useMatrixResponse";

const useQuestions = () => {
  return {
    useMultipleChoiceResponse,
    useQuestionList,
    useUploadFilesResponse,
    useDateResponse,
    useMatrixResponse,
  };
};

export default useQuestions;
