// Packages
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

// Assets
import useIcons from "../../../../assets/icons";

// Controllers
import useControllers from "../../..";

const useFaqsView = () => {
  const { IconDelete } = useIcons();

  // State

  const [listFaqs, setListFaqs] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });
  const [searchText, setSearchText] = useState("");
  const [valuesFilter, setValuesFilter] = useState({});
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const navigation = useNavigate();

  // Actions
  const { useActions } = useApi();
  const { useFaqsActions, useGeneralActions } = useActions();
  const { actExportExel, actListCountries } = useGeneralActions();
  const { actListFaqs, actDeleteFaq } = useFaqsActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast } = useQuickFunctions();
  const { filterNotificationValidationShema } = useValidators();
  const { success } = useToast();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(filterNotificationValidationShema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const formData = {
      status: data.status,
      country_id: data.country_id,
    };

    setValuesFilter(formData);
    actListFaqs({ ...formData, search: searchText, page: 1 }, loadData);
  };

  const loadData = (data) => {
    setListFaqs(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };

  const loadDataPagination = (data) => {
    setListGroup(data.data);
  };

  const handleCreateNewFaq = () => {
    navigation("/faq/creacion");
  };

  const handleEditFaq = (faq) => {
    navigation(`/faq/editar/${faq.id}`);
  };

  const handleCloseModal = () => {
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handleRemoveFaq = (id, name) => {
    setModalDelete({ show: true, name: name, id: id });
  };

  const handleConfirmDelete = () => {
    actDeleteFaq(modalDelete.id, onSubmitSuccess());
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const onSubmitSuccess = () => {
    success("Faq eliminada con éxito!");
    setTimeout(() => {
      actListFaqs({ page: 1 }, loadData);
    }, 1000);
  };

  const handlePagination = (nro) => {
    setPaginationSettings({...paginationSettings, current: nro.selected + 1});
    actListFaqs({page: nro.selected + 1,  params: { ...valuesFilter, search: searchText }}, loadData);
  };

  const onSubmitSearch = (e) => {
      setSearchText(e);
      actListFaqs({ ...valuesFilter, page: 1, search: e}, loadData);
  };

  const onChangeSearch = useCallback(debounce(onSubmitSearch, 750), []);

  const prepareDataCountry = (data) => {
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const handleDownloadExcel = () => {
    const filtersProcces = prepareDataFilterDownload(valuesFilter);
    actExportExel(`faq_resumes`, filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  useEffect(() => {
    actListFaqs({ page: 1 }, loadData);
    actListCountries(prepareDataCountry);
  }, []);

  return {
    control,
    handleSubmit,
    onSubmit,
    listFaqs,
    handleCreateNewFaq,
    handleEditFaq,
    listCountries,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    handleRemoveFaq,
    modalDelete,
    handleCloseModal,
    handleConfirmDelete,
    IconDelete,
    handleDownloadExcel,
  };
};

export default useFaqsView;
