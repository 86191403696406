// Packages
import React from 'react';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Components
import useComponents from '../../../../../components';

// Controllers
import useControllers from '../../../../../../controllers'

// Styles
import {
  StyledQuestionsHeader,
  StyledQuestionsHeaderItems,
  StyledQuestionsContent,
  StyledQuestionsList,
  StyledModalPreview
} from './Questions.styles';

// Import Swiper styles
import "swiper/swiper.min.css";
/* import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css"; */

// Assets
import useIcons from '../../../../../../assets/icons';

// Helper
import useHelpers from '../../../../../../helpers';

const CreateQuestions = () => {
  const { IconPlus, IconDots } = useIcons();

  // Components
  const {
    CardWrapper,
    ContentWrappers,
    Typography,
    Steps,
    useButtons,
    QuestionList,
    useAccordion,
    useModals,
    RadioButtonControlled,
    DatePickerFieldControlled,
    InputFileControlled,
    Rangefield,
    CheckboxControlled,
    TextAreaFieldControlled,
    TextFieldControlled,
  } = useComponents();

  const { DefaultButton } = useButtons();
  const { ModalCustom } = useModals();
  const { AccordionContent, AccordionHeader, AccordionBody } = useAccordion();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useSurvey } = useTools();
  const { useQuestions } = useSurvey();

  const {
    handleOnDragEnd,
    handleRemoveQuestion,
    handleGetQuestion,
    handleFinalize,
    sectionInfo,
    sectionList,
    id_survey,
    handlePreview,
    questionPreview,
    showModal,
    handleClose,
    control,
    handleCloneQuestion,
    watch,
    listPrevRanking,
    hanldeSelectPrevRanking,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    draggedIndex,
  } = useQuestions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();

  return (
    <>
      <ContentWrappers
        gridColums={["1fr",]}
        gap={{
          row: "0px",
          column: "20px"
        }}
        contentWidth="892px"
        as="section"
      >
        <Steps
          stepList={["Secciones", "Condicionales"]}
          activeIndex={2}
        />
        <CardWrapper
          padding="2rem"
        >
          <StyledQuestionsHeader
            gridColums={["330px", "395px", "102px"]}
          >
            <StyledQuestionsHeaderItems>
              <Typography variant="h5">MISIÓN</Typography>
              <Typography variant="caption">{sectionInfo.missionName}</Typography>
            </StyledQuestionsHeaderItems>
            <StyledQuestionsHeaderItems>
              <Typography variant="h5">ENCUESTA</Typography>
              <Typography variant="caption">{sectionInfo.surveyName}</Typography>
            </StyledQuestionsHeaderItems>
            <StyledQuestionsHeaderItems>
              <Typography variant="h5">PAÍS</Typography>
              <Typography variant="caption">{sectionInfo.countryName}</Typography>
            </StyledQuestionsHeaderItems>
          </StyledQuestionsHeader>

          <StyledQuestionsContent>
            <div className="flex justify-between">
              <Typography variant="h2">
                SECCIONES
              </Typography>
              {!sectionInfo.isActiveMissionFlag && (
                <DefaultButton
                  variant="outlined"
                  color="primary"
                  startIcon={<IconPlus color="#FFA700" />}
                  onClick={() => navigateTo(`/herramientas/encuesta/${id_survey}/secciones/listado`)}
                >
                  Agregar sección
                </DefaultButton>
              )}
            </div>

            {sectionList.map((section, index) => {
              return (
                <AccordionContent key={index}>
                  <AccordionHeader>
                    <div>
                      <span>{index + 1}</span>
                      <h4 className="uppercase text-base font-oswald">{section.title}</h4>
                    </div>
                  </AccordionHeader>
                  <AccordionBody>
                    <div className="px-8">
                      <div className="flex justify-end">
                        {!section.isActiveMissionFlag && (
                          <DefaultButton
                            variant="outlined"
                            color="primary"
                            startIcon={<IconPlus color="#FFA700" />}
                            onClick={() => navigateTo(`/herramientas/encuesta/${id_survey}/secciones/${section.id}/pregunta`)}
                          >
                            Agregar pregunta
                          </DefaultButton>
                        )}
                      </div>
                      <DragDropContext onDragEnd={(e) => handleOnDragEnd(e, section.id)}>
                        <Droppable droppableId="dropDrapQuestionList">
                          {(provided) => (
                            <StyledQuestionsList {...provided.droppableProps} ref={provided.innerRef}>
                              {section.questions.map((question, index) => {
                                return (
                                  <Draggable isDragDisabled={section.isActiveMissionFlag} key={question.id} draggableId={question.id.toString()} index={index}>
                                    {(provided) => (
                                      <QuestionList
                                        id={question.id.toString()}
                                        provided={provided}
                                        title={question.title}
                                        itemNumber={index + 1}
                                        questionType={question.question_type_es}
                                        handleRemoveQuestion={(id) => handleRemoveQuestion(id, section.id)}
                                        handleGetQuestion={(id) => handleGetQuestion(id, section.id)}
                                        handleCloneQuestion={(id) => handleCloneQuestion(id)}
                                        preview
                                        handlePreview={(id) => handlePreview(id, section.id)}
                                        question={question}
                                        isActiveMissionFlag={section.isActiveMissionFlag}
                                      />
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </StyledQuestionsList>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </AccordionBody>
                </AccordionContent>
              )
            })}
            <div className="flex justify-center mt-8">
              <DefaultButton type="button" color="secondary" onClick={handleFinalize}>
                Finalizar secciones
              </DefaultButton>
            </div>
          </StyledQuestionsContent>
        </CardWrapper>
      </ContentWrappers>
      <ModalCustom
        isOpen={showModal}
        children={<StyledModalPreview>
          <div className="pt-4 pb-8 text-center" dangerouslySetInnerHTML={{ __html: questionPreview?.html }} />
          {questionPreview.files && questionPreview.files.length > 0 &&
            <div style={{ maxWidth: "600px" }}>
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                pagination={{
                  clickable: true,
                }}
                loop={questionPreview.files.length > 1}
              >
                {questionPreview.files.map((file, index) => {
                  return (
                    <SwiperSlide key={index} className="py-4">
                      {/(.mp4|.mp3|.avi|.mkv|.mov)/.test(file) ? (
                        <video width={questionPreview.file_width && questionPreview.file_width > 0 ? questionPreview.file_width : '100%'} height={questionPreview.file_height && questionPreview.file_height > 0 ? questionPreview.file_height : '100%'} controls>
                          <source src={file} type={"video/mp4"} />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          className="object-cover mx-auto"
                          src={file}
                          width={questionPreview.file_width && questionPreview.file_width > 0 ? questionPreview.file_width : '100%'} height={questionPreview.file_height && questionPreview.file_height > 0 ? questionPreview.file_height : '100%'}
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>}
          {questionPreview.question_type === "AboutFile" && (
            <div>
              <TextAreaFieldControlled
                id={questionPreview.question_type + questionPreview.id}
                name={questionPreview.question_type + questionPreview.id}
                control={control}
              />
            </div>
          )}
          {questionPreview.question_type === "Matrix" && (
            <div>
              {questionPreview.question.rows.map((v, i) => (
                <div key={'M' + v}>
                  <Typography className="pt-4 pb-8 text-center" variant="body1">
                    {v}
                  </Typography>
                  <RadioButtonControlled
                    control={control}
                    radioOptions={questionPreview.question.columns.map((v) => {
                      return { label: v.name, value: v.name };
                    })}
                    id={questionPreview.question_type + questionPreview.id + v + i}
                    name={questionPreview.question_type + questionPreview.id + v + i}
                  />
                </div>
              ))}
            </div>
          )}
          {questionPreview.question_type === "Radio" && (
            <div>
              <RadioButtonControlled
                control={control}
                radioOptions={questionPreview.question.options.map((v) => {
                  return { label: v.name, value: v.name, file: v?.file };
                })}
                id={questionPreview.question_type + questionPreview.id}
                name={questionPreview.question_type + questionPreview.id}
              />
              {questionPreview.question.add_other_field &&
                <div>

                  {watch(questionPreview.question_type + questionPreview.id) === 'Otros' && <TextFieldControlled
                    control={control}
                    id="otherInput"
                    name="otherInput"
                  />}
                </div>
              }
            </div>
          )}
          {questionPreview.question_type === "Binary" && (
            <div>
              <RadioButtonControlled
                control={control}
                radioOptions={[
                  {
                    label: "Si",
                    value: "Si",
                  },
                  {
                    label: "No",
                    value: "No",
                  },
                ]}
                id={questionPreview.question_type + questionPreview.id}
                name={questionPreview.question_type + questionPreview.id}
              />
            </div>
          )}
          {questionPreview.question_type === "Checkbox" && (
            <div>
              {questionPreview.question.options.map((v, i) => (
                <div className="mb-2">
                  <CheckboxControlled
                    control={control}
                    id={questionPreview.question_type + questionPreview.id + v.name}
                    name={questionPreview.question_type + questionPreview.id + v.name}
                    label={v.name}
                    key={"check" + v.name + i}
                  />
                  {v.file && (
                    <div className="mt-2 mb-3">
                      <img src={v?.file} height="150px" width="150px" />
                    </div>
                  )}
                </div>
              ))}
              {questionPreview.question.add_other_field &&
                <div>
                  {watch(questionPreview.question_type + questionPreview.id + 'Otros') && <TextFieldControlled
                    control={control}
                    id="otherInput"
                    name="otherInput"
                  />}
                </div>
              }
            </div>
          )}
          {questionPreview.question_type === "Date" && (
            <div>
              <DatePickerFieldControlled
                id={questionPreview.question_type + questionPreview.id}
                name={questionPreview.question_type + questionPreview.id}
                label=""
                control={control}
              />
            </div>
          )}
          {questionPreview.question_type === "Ranking" && (
            <div>
              <Typography>1. Marca y/o desmarca los items deseados</Typography>
              <div className="flex space-x-4 overflow-x-auto max-w-[350px] mt-2 mb-3 py-2">
                {questionPreview.question.options.map((option, index) =>
                  <Typography
                    variant="body2"
                    key={index}
                    className={`border rounded-xl py-2 px-4 hover:bg-primary cursor-pointer ${listPrevRanking.includes(option.name) ? 'bg-primary' : ''}`}
                    onClick={() => hanldeSelectPrevRanking(option.name)}
                  >{option.name}</Typography>)}
              </div>
              <Typography>2. Arrastra los items en el orden deseado</Typography>

              <ul className="mt-2 flex flex-col gap-y-3 max-h-[250px] overflow-y-auto">
                {listPrevRanking.map((item, index) => (
                  <li
                    key={index}
                    draggable
                    onDragStart={() => handleDragStart(index)}
                    onDragEnd={handleDragEnd}
                    onDragOver={(e) => handleDragOver(e, index)}
                    className={`border rounded-xl py-2 px-4 flex items-center justify-between ${draggedIndex == index ? 'cursor-grabbing' : 'cursor-grab'}`}
                  >
                    <Typography>{item}</Typography>
                    <IconDots />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {questionPreview.question_type === "Text" && (
            <div>
              <TextAreaFieldControlled
                id={questionPreview.question_type + questionPreview.id}
                name={questionPreview.question_type + questionPreview.id}
                label=""
                control={control}
              />
            </div>
          )}
          {questionPreview.question_type === "File" && (
            <div>
              <InputFileControlled
                id={questionPreview.question_type + questionPreview.id}
                name={questionPreview.question_type + questionPreview.id}
                label=""
                control={control}
                showPreview
              />
            </div>
          )}
          {questionPreview.question_type === "Range" && (
            <div className="text-center">
              <div className="flex items-center justify-between">
                <Typography variant="body2">{questionPreview.question.label_start}</Typography>
                <Typography variant="body2">{questionPreview.question.label_end}</Typography>
              </div>
              <Rangefield
                id={questionPreview.question_type + questionPreview.id}
                name={questionPreview.question_type + questionPreview.id}
                marks={[
                  { value: questionPreview.question.value_start },
                  { value: questionPreview.question.value_end },
                ]}
                min={questionPreview.question.value_start}
                max={questionPreview.question.value_end}
                defaultValue={
                  (questionPreview.question.value_start + questionPreview.question.value_end) / 2
                }
              />
            </div>
          )}
          {questionPreview.question_type === "Mask" && (
            <div className="text-center">
              {questionPreview.question.type_mask === 'text' &&
                <Typography variant="body2">{questionPreview.question.text}</Typography>
              }
              {questionPreview.question.type_mask === 'url' &&
                <a href={questionPreview.question.url} target="_blank"><Typography variant="body2">{questionPreview.question.url}</Typography></a>
              }
            </div>
          )}
          <DefaultButton
            variant="primary"
            color="secondary"
            onClick={handleClose}
          >
            Cerrar
          </DefaultButton>
        </StyledModalPreview>
        }
      />
    </>
  );
};

export default CreateQuestions;