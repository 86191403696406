import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//Helpers
import useHelpers from "../../../../helpers";

import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import useApi from "../../../../api";
import useComponents from "../../../../views/components";
import useControllers from "../../..";

const newsRoot = () => {
  // Components
  const { Toast } = useComponents();

  const [valueTextSearch, setValueTextSearch] = useState("");
  const [valueStatus, setValueStatus] = useState("");
  const [valueCountryId, setValueCountryId] = useState("");
  const [valueAuthor, setValueAuthor] = useState("");
  const [valuInitDate, setValuInitDate] = useState("");
  const [valueEndDate, setValueEndDate] = useState("");

  const [listCountries, setListCountries] = useState([]);
  const [listNews, setListNews] = useState([]);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });

  const { useActions } = useApi();
  const { useNewsActions, useGeneralActions } = useActions();
  const { actListNews, actCloneNew, actDeleteNew } = useNewsActions();
  const { actListCountries, actExportExel } = useGeneralActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const navigation = useNavigate();

  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast, useGeneral } = useQuickFunctions();
  const { filterNewsValidationShema } = useValidators();
  const { success } = useToast();

  const { formatDate } = useGeneral();

  const { control, handleSubmit, resetField } = useForm({
    mode: "all",
    resolver: yupResolver(filterNewsValidationShema),
    defaultValues: {
      range_date: null
    }
  });

  const onClickClear = () => resetField("range_date");

  const onSubmitFilter = (data, e) => {
    e.preventDefault();

    setValueStatus(data.status);
    setValueCountryId(data.country_id);
    setValueAuthor(data.author);
    setValuInitDate(!!data.range_date ? formatDate(data.range_date[0], "Y-mm-dd", false) : null);
    setValueEndDate(!!data.range_date ? formatDate(data.range_date[1], "Y-mm-dd", false) : null);

    actListNews(
      {
        page: 1,
        search: valueTextSearch,
        status: data.status,
        country_id: data.country_id,
        author: data.author,
        init_date: !!data.range_date ? formatDate(data.range_date[0], "Y-mm-dd", false) : null,
        end_date: !!data.range_date ? formatDate(data.range_date[1], "Y-mm-dd", false) : null,
      },
      loadDataNews
    );
  };

  const handleSubmitSearch = (e) => {
    setValueTextSearch(e);
    actListNews({ page: 1, search: e }, loadDataNews);
  };

  const handleChangeSearch = useCallback(debounce(handleSubmitSearch, 750), []);

  const handlePagination = (nro) => {
    actListNews(
      {
        page: nro.selected + 1,
        search: valueTextSearch,
        status: valueStatus,
        country_id: valueCountryId,
        author: valueAuthor,
        init_date: valuInitDate,
        end_date: valueEndDate,
      },
      loadDataNews
    );
  };

  const handleLoadData = (data) => {
    success(<Toast text={"Noticia duplicada con éxito!"} />, {
      toastId: 0,
    });

    actListNews({ page: paginationSettings.current }, loadDataNews);
  };

  const handleGoToCreate = () => {
    navigation("/noticias/crear");
  };

  const handleEditNew = (itemNew) => {
    navigation(`/noticias/editar/${itemNew.id}`);
  };

  const handleCloneNew = (itemNew) => {
    actCloneNew(itemNew.id, handleLoadData);
  };

  const handleDownloadExel = () => {
    const filters = {
      country_id: valueCountryId,
      search: valueTextSearch,
      status: valueStatus,
      author: valueAuthor,
      init_date: valuInitDate,
      end_date: valueEndDate,
    };

    const filtersProcces = prepareDataFilterDownload(filters);

    actExportExel("articles", filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  const loadDataNews = (data) => {
    setListNews(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };

  const dataListCountries = (data) => {
    setListCountries(
      _.map(data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  useEffect(() => {
    actListNews({ page: 1 }, loadDataNews);
    actListCountries(dataListCountries);
  }, []);

  const handleDeleteNew = (id, name) => {
    setModalDelete({ show: true, name: name, id: id });
  };

  const handleConfirmDelete = () => {
    const onSuccessDelete = () => {
      actListNews({ page: 1 }, loadDataNews);
    }
    actDeleteNew(modalDelete.id, onSuccessDelete);
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handleCloseModal = () => {
    setModalDelete({ show: false, name: "", id: 0 })
  };

  return {
    listNews,
    handleSubmit,
    control,
    handleChangeSearch,
    handleSubmitSearch,
    onSubmitFilter,
    handlePagination,
    handleGoToCreate,
    paginationSettings,
    listCountries,
    handleEditNew,
    handleCloneNew,
    handleDownloadExel,
    handleDeleteNew,
    modalDelete,
    handleCloseModal,
    handleConfirmDelete,
    onClickClear,
  };
};

export default newsRoot;
