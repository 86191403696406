import useProjectsRoot from "./Root";
import useProjectsCreate from "./Create";
import useProjectsEdit from "./Edit";
import { useNavigate } from "react-router-dom";

const useProjectsScreen = () => {
  const navigation = useNavigate();

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/proyectos": ["proyectos"],
    "/proyectos/crear": ["proyectos", "crear proyecto "],
    "/proyectos/editar": ["proyectos", "editar proyecto"],
  };
  return {
    useProjectsRoot,
    useProjectsCreate,
    header,
    handleBack,
    useProjectsEdit,
  };
};

export default useProjectsScreen;
