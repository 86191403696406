import { useEffect, useState } from "react";
import _ from "lodash";

const useHookGrafic = ({ data }) => {
  const [totalPercentage, setTotalPercentage] = useState();
  const CustomToltip = ({ active, payload, totalPercentage }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center text-center shadow-md">
          <p className="text-lg">{`${((payload[0].value * 100) / totalPercentage).toFixed(2)}%`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomLegend = ({ payload, legendAling, legendType }) => {
    return (
      <ul
        className={`${legendAling === "center" ? "w-full justify-center" : "justify-end"} ${
          legendType === "secondary" ? "flex-col space-y-3" : "space-x-3"
        } flex `}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            className="flex items-center text-sm font-gotham font-thin uppercase"
          >
            <span
              style={{ backgroundColor: entry.color }}
              className={` flex rounded-full mr-2 ${
                legendType === "secondary" ? "w-1 h-full" : "w-3 h-3"
              }`}
            ></span>
            {entry.value}
            <span className="font-bold ml-1">{`${(entry.payload.percent * 100).toFixed(0)}%`}</span>
          </li>
        ))}
      </ul>
    );

    return null;
  };

  const CustomLabel = (props) => {
    const {
      viewBox: { cx, cy },
      title,
      value,
      subTitle,
    } = props;
    const positioningProps = {
      x: cx,
      y: cy,
      textAnchor: "middle",
      //verticalAnchor: "middle",
    };

    return (
      <text {...positioningProps}>
        <tspan
          dy={title ? "-5" : "10"}
          x="50%"
          className={`font-bold ${title ? "text-sm" : "text-4xl"}`}
        >
          {value}
        </tspan>
        <tspan dy={title ? "15" : "20"} x="50%" className="block font-light text-oswald text-xs">{title}</tspan>
        <tspan dy={title ? "15" : "20"} x="50%" className="block font-light text-oswald text-xs">{subTitle}</tspan>
      </text>
    );
  };

  useEffect(() => {
    setTotalPercentage(
      _.reduce(
        data,
        function (prev, next) {
          return prev + next.value;
        },
        0
      )
    );
  }, [data]);

  return { CustomToltip, totalPercentage, CustomLegend, CustomLabel };
};

export default useHookGrafic;
