import { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";

// Steps controllers
import useFaqsView from "./Views";
import useFaqsCreate from "./Create";

const useFaqs = () => {
  const navigation = useNavigate();
  const [headerKey, setHeaderKey] = useState("/faq");

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/faq": ["faq's"],
    "/faq/creacion": ["faq's", "Crear FAQ"],
    "/faq/editar/:id": ["faq's", "Edición de la FAQ"],
  };

  const matches = _.mapValues(header, (item, pathname) => useMatch(pathname));

  useEffect(() => {
    const matchedKey = Object.keys(matches).find((pathname) => matches[pathname]?.pathname === pathname) || "/faq";
    setHeaderKey(matchedKey);
  }, [matches]);

  return {
    handleBack,
    header,
    useFaqsView,
    useFaqsCreate,
    setHeaderKey,
    headerKey,
  };
};

export default useFaqs;
