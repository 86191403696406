// Packages
import React from 'react';

// Components
import useComponents from '..';

// Styles
import { StyledQuestionsTextResponse } from './Questions.styles';

const TextResponse = (props) => {
  const { control } = props;
  const { RadioButtonControlled } = useComponents();

  return (
    <StyledQuestionsTextResponse>
      <RadioButtonControlled
        control={ control }
        radioOptions={[
          {
            value: "short",
            label: "Respuesta corta",
          },
          {
            value: "long",
            label: "Respuesta larga",
          }
        ]}
        id="textResponse"
        name="textResponse"
      />
    </StyledQuestionsTextResponse>
  )
}

export default TextResponse;