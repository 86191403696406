import useAuthInitialStates from "./auth";
import useToolsInitialStates from "./tools";
import useUserInitialStates from "./users";
import useProjectIntialstate from "./projects";

const useInitialStates = () => {
  return {
    useAuthInitialStates,
    useToolsInitialStates,
    useUserInitialStates,
    useProjectIntialstate,
  };
};

export default useInitialStates;
