import { useDispatch } from "react-redux";
import useAuthActions from "./auth";
import useToolsActions from "./tools";
import useQuestionsActions from "./questions";
import useUsersActions from "./users";
import useGeneralActions from "./general";
import useMissionActions from "./mission";
import useProjectsActions from "./projects";
import useCountriesAction from "./countries";
import useMapActions from "./map";
import useBrandsActions from "./brands";
import useNewsActions from "./news";
import useNotificationsActions from "./notifications";
import usePaymentsActions from "./payments";
import useFaqsActions from "./faqs";
import useGroupsActions from "./groups";
import useProfilerActions from "./profiler";
import useDashboardActions from "./dashboard";
import useMembeersActions from "./membeers";
import usePowerBiActions from "./powerbi";
import useGamificationActions from "./gamifications";
import useLegalActions from "./legal";
import usePromptsActions from "./prompts";
import useNavBarActions from "./navbar";

const useActions = () => {
  const dispatch = useDispatch();

  return {
    dispatch,
    useAuthActions,
    useToolsActions,
    useUsersActions,
    useGeneralActions,
    useMissionActions,
    useProjectsActions,
    useCountriesAction,
    useMapActions,
    useBrandsActions,
    useNewsActions,
    useNotificationsActions,
    usePaymentsActions,
    useGroupsActions,
    useProfilerActions,
    useFaqsActions,
    useGroupsActions,
    useDashboardActions,
    useMembeersActions,
    usePowerBiActions,
    useGamificationActions,
    useQuestionsActions,
    useLegalActions,
    usePromptsActions,
    useNavBarActions,
  };
};

export default useActions;
