import useNavBar from "./useNavBar";
import useQuestions from "./useQuestions";
import useButtonsHooks from "./useButtons";
import useHeader from "./useHeader";
import useInputFile from "./useInputFile";
import usePlaceAutocompleteField from "./usePlaceAutocompleteField";
import useCheckBoxGroupControlled from "./useCheckboxGroupControlled";
import useHookGrafic from "./useGrafic";

const useComponentHooks = () => {
  return {
    useNavBar,
    useButtonsHooks,
    useQuestions,
    useHeader,
    useInputFile,
    usePlaceAutocompleteField,
    useCheckBoxGroupControlled,
    useHookGrafic,
  };
};

export default useComponentHooks;
