import useApi from "../../index";

const useLegalActions = () => {
  const { useServices } = useApi();
  const { useLegalServices } = useServices();
  const {
    listLegalService,
    createLegalService,
    editLegalService,
  } = useLegalServices();

  const actListLegal = async ({ page, params }, onSuccess, onError) => {
    try {
      const response = await listLegalService({ page, params });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };
  
  const actCreateLegal = async (data, onSuccess, onError) => {
    try {
      const response = await createLegalService(data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actEditLegal = async ({id, data}, onSuccess, onError) => {
    try {
      const response = await editLegalService(id, data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  return {
    actListLegal,
    actCreateLegal,
    actEditLegal,
  };
};

export default useLegalActions;
