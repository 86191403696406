import TableOnlyName from "./TableOnlyName";
import TableMission from "./TableMission";
import TableImage from "./TableImage";
import TableCharacter from "./TableCharacter";

const useComponentsProfiler = () => {
  return { 
    TableOnlyName, 
    TableMission,
    TableImage,
    TableCharacter,
   };
};
export default useComponentsProfiler;
