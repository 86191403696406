// Packages
import React from "react";

// Controllers
import useControllers from "../../../../controllers";

//Forms 
import useFormsProfiler from "./forms"


const GamificationCreate = () => {
  const { useScreenHooks } = useControllers();
  const { useGamification } = useScreenHooks();
  const { useGamificationCreate } = useGamification();

  const {type} = useGamificationCreate();


  const { FormGamificationName, FormGamificationDetails} = useFormsProfiler();
  
  return (
    <div className="bg-white px-16 pt-10 pb-12 mx-24 rounded-lg">      
        {type < 5 && type !== 2 && type !== 3 ? <FormGamificationName/> : <FormGamificationDetails type={type}/>}
    </div>
  )

}

export default GamificationCreate;