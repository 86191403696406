const IconFilter = ({color = "#FFA700"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        fill={ color }
        d="M12.546 9.257V.587A.582.582 0 0011.96 0a.582.582 0 00-.588.587v8.67a2.1 2.1 0 00-1.503 2.02c0 .94.634 1.763 1.503 1.998v2.138c0 .329.259.587.588.587a.582.582 0 00.587-.587v-2.138a2.093 2.093 0 000-4.018zm-.587 2.913a.917.917 0 010-1.832.917.917 0 010 1.832zM7.847 2.209V.587A.582.582 0 007.26 0a.582.582 0 00-.587.587V2.21a2.1 2.1 0 00-1.504 2.02c0 .94.634 1.762 1.504 1.997v9.187c0 .329.258.587.587.587a.582.582 0 00.587-.587V6.226a2.093 2.093 0 000-4.017zM7.26 5.122a.917.917 0 010-1.833.917.917 0 010 1.833zM2.678 6.438V.588A.582.582 0 002.091 0a.582.582 0 00-.587.587v5.85A2.1 2.1 0 000 8.459c0 .94.634 1.762 1.504 1.997v4.958c0 .329.258.587.587.587a.582.582 0 00.587-.587v-4.958a2.093 2.093 0 000-4.017zM2.091 9.35a.917.917 0 010-1.833.917.917 0 010 1.833z"
      ></path>
    </svg>
  );
}

export default IconFilter;
