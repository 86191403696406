import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useNewsServices = () => {
  const { useNewsProviders } = useProviders();
  const {
    listNewsProvider,
    createNewProvider,
    editNewProvider,
    getNewProvider,
    getTotalNewsProvider,
    cloneNewProvider,
    deleteNewProvider,
  } = useNewsProviders();

  const listNewsService = ({ page, search, status, country_id, author, init_date, end_date }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            listNewsProvider({ page, search, status, country_id, author, init_date, end_date })
          )
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const createNewService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createNewProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const editNewService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editNewProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getNewService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getNewProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getTotalNewsService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getTotalNewsProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const cloneNewService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(cloneNewProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const deleteNewService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteNewProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listNewsService,
    createNewService,
    editNewService,
    getNewService,
    getTotalNewsService,
    cloneNewService,
    deleteNewService,
  };
};
export default useNewsServices;
