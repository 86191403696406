import React from "react";
import { Route, Routes, useMatch, useLocation, Outlet } from "react-router-dom";

// Components
import useLayouts from "../../layouts";

// Controllers
import useControllers from "../../../controllers";

// Views
import ProjectsRoot from "./Root";
import CreateProject from "./Create";
import EditProject from "./Edit";
import TaskView from "../Tasks/Views";

const ProjectsLayout = () => {
  const { PrivateContentLayout } = useLayouts();

  const { useScreenHooks } = useControllers();
  const { useProjectsScreen } = useScreenHooks();
  const { handleBack, header } = useProjectsScreen();

  const match = useMatch("/proyectos/*");
  const basePath = match ? match.pathnameBase : "/proyectos";
  const location = useLocation();
  const isExact = basePath === location.pathname;

  const breadcrumbsList = location.pathname.split("/").length > 2
    ? header[Object.keys(header).filter((key) => location.pathname.includes(key))[1]]
    : header[Object.keys(header).filter((key) => location.pathname.includes(key))];

  return (
    <PrivateContentLayout
      breadcrumbsList={breadcrumbsList}
      handleBack={handleBack}
      showBack={!isExact}
      title="Proyectos"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Projects = () => (
  <Routes>
    <Route path="/" element={<ProjectsLayout />}>
      <Route index element={<ProjectsRoot />} />
      <Route path="crear" element={<CreateProject />} />
      <Route path="crear/misiones" element={<TaskView />} />
      <Route path="editar/:projectId" element={<EditProject />} />
    </Route>
  </Routes>
);

export default Projects;
