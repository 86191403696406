// Packages
import { useNavigate } from "react-router-dom";

// Steps controllers
import useGroupView from './Views'
import useGroupCreate from './Create'

const useUsersGroup = () => {
  // State
  const navigation = useNavigate();

  const handleBack = () => {
    navigation(-1)
  }

  const header = {
    '/grupos': ["Grupos Membeer"],
    '/grupos/creacion': ["Grupos Membeer", "Crear Grupo"],
    '/grupos/editar': ["Grupos Membeer", "Edición de Grupo"],
    '/grupos/detalle': ["Grupos Membeer", "Detalle del Grupo"],
  }

  return {
    handleBack,
    header,
    useGroupCreate,
    useGroupView,
  };
};

export default useUsersGroup;
