// Packages
import React from "react";
import PropTypes from "prop-types";

// Styles
import {
  StyledRadio,
  StyledRadioIcon,
  StyledCheckedIcon,
  StyledFormControlLabel,
} from "./RadiuButton.styles";

const RadioButton = (props) => {
  const { value, label, disabled, variantColor } = props;
  return (
    <StyledFormControlLabel
      value={value}
      label={label}
      control={
        <StyledRadio
          icon={<StyledRadioIcon variantColor={variantColor} />}
          checkedIcon={<StyledCheckedIcon variantColor={variantColor} />}
        />
      }
      disabled={disabled}
    />
  );
};

RadioButton.prototype = {
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  variantColor: PropTypes.oneOf(["primary", "error", "success"]),
};

RadioButton.defaultProps = {
  disabled: false,
  variantColor: "primary",
};

export default RadioButton;
