// Packages
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-toastify"

// Models
import useModels from '../../../models';

// Helpers
import useHelpers from '../../../helpers';

// Actions
import useActions from '../../../api/actions';

const useLogin = () => {
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation } = useQuickFunctions();
  const { loginValidatorSchema } = useValidators();
  const { replaceAndNavigateTo, navigateTo } = useNavigation();

  // Actions
  const { dispatch, useAuthActions } = useActions();
  const { actLogin, actValidLoginPassword } = useAuthActions();

  // Models
  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();

  const {authToken} = useSelector(session);

  const {
    control,
    handleSubmit,
    watch
  } = useForm({
    mode: 'all',
    resolver: yupResolver(loginValidatorSchema),
  });

  const watchFieldsEmail = watch(["email"]);

  const handleForgot = () => {
    replaceAndNavigateTo("/contrasena/olvidar", {email: watchFieldsEmail})
  }

  const onSubmit = useCallback(
    ({email, password}) => {

      const onSuccessLogin = (data) => {
        navigateTo("/dashboard")
      }

      dispatch(actLogin({ email, password }, onSuccessLogin));
    }, []
  )

  // Activar cuando este la funcion logout

  useEffect(()=> {
    if(authToken){
      if (actValidLoginPassword()){
        replaceAndNavigateTo("/dashboard")
      }
    }
  }, [])

  return {
    control,
    handleSubmit,
    onSubmit,
    handleForgot,
  }
}

export default useLogin;