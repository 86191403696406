// Packages
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";

// Helpers
import useHelpers from "../../../../../helpers";

// Actions
import useActions from "../../../../../api/actions";

import useControllers from "../../../../";

const usePassiveTrackingReport = () => {
  const { id_passive_tracking } = useParams();
  const [membeersInfo, setMembeersInfo] = useState([]);
  const [titleMembeer, setTitleMembeer] = useState({});
  const [searchText, setSearchText] = useState(null);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [configTableSteps, setConfigTableSteps] = useState({
    headerCellList: [],
    gridCell: [],
    headerCentersItem: [],
    stepsId: [],
  });

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { sectionValidationShema } = useValidators();

  // Actions
  const { dispatch, useToolsActions, useGeneralActions } = useActions();
  const { actGetPassiveTrackingSteps, actGetPassiveTrackingPagiAnswers } = useToolsActions();
  const { actExportExel } = useGeneralActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  useEffect(() => {
    const onSuccess = (data) => {
      const { steps, mission } = data;
      const stepSpace = 450;
      const headerCellList = ["Membeer", "Email", "Estado", "Reporte"];
      const gridCell = ["176px", "222px", "150px", "60px"];
      const headerCentersItem = [];
      const stepsId = [];

      setTitleMembeer({
        tool_name: mission[0]?.name,
        mission_name: mission[0]?.mission_name,
        countries: mission[0]?.countries.reduce((prev, curr) => prev + curr.name + " ", ""),
      });

      steps.forEach((step, index) => {
        headerCentersItem.splice(1, 0, index + 2);
        headerCellList.splice(2 + index, 0, step.name);
        gridCell.splice(index + 2, 0, `${stepSpace / steps.length}px`);
        stepsId.splice(0 + index, 0, step.id);
      });

      setConfigTableSteps({
        headerCellList,
        gridCell,
        headerCentersItem,
        stepsId,
      });
    };

    const onError = (error) => {
      console.log(error);
    };

    dispatch(actGetPassiveTrackingSteps(id_passive_tracking, onSuccess, onError));

    // Answers
    const onSuccessAnswers = (dataAnswers) => {
      const { data, meta } = dataAnswers;
      setMembeersInfo(data);
      setPaginationSettings({current: meta.current_page, totalPages: meta.total})
    };

    const onErrorAnswers = (error) => {
      console.log(error);
    };
    dispatch(
      actGetPassiveTrackingPagiAnswers(
        { passive_tracking_id: id_passive_tracking },
        onSuccessAnswers,
        onErrorAnswers
      )
    );
  }, [id_passive_tracking]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(sectionValidationShema),
  });

  const onSubmit = useCallback((data) => {
    //console.log(data);
  }, []);

  const handleDownloadExel = () => {
    const filters = {
      passive_tracking_id: id_passive_tracking
    };

    const filtersProcces = prepareDataFilterDownload(filters);

    actExportExel("membeerPassiveTracking", filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  const handleIndividualDownloadExel = (id) => {
    const filters = {
      passive_tracking_id: id_passive_tracking,
      membeer_id: id,
    };

    const filtersProcces = prepareDataFilterDownload(filters);
    actExportExel("membeerPassiveTracking", filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  const onSubmitSearch = (e) => {
    setSearchText(e);
    const onSuccessAnswers = (dataAnswers) => {
      const { data, meta } = dataAnswers;
      setMembeersInfo(data);
      setPaginationSettings({current: meta.current_page, totalPages: meta.total})
    };

    const onErrorAnswers = (error) => {
      console.log(error);
    };

    dispatch(
      actGetPassiveTrackingPagiAnswers(
        { page: 1, passive_tracking_id: id_passive_tracking, search: e },
        onSuccessAnswers,
        onErrorAnswers
      )
    );
  };


  const onChangeSearch = useCallback(debounce(onSubmitSearch, 750), []);

  const handlePagination = (nro) => {
    const onSuccessAnswers = (dataAnswers) => {
      const { data, meta } = dataAnswers;
      setMembeersInfo(data);
      setPaginationSettings({current: meta.current_page, totalPages: meta.total})
    };

    const onErrorAnswers = (error) => {
      console.log(error);
    };

    dispatch(
      actGetPassiveTrackingPagiAnswers(
        { page: nro.selected + 1, passive_tracking_id: id_passive_tracking, search: searchText },
        onSuccessAnswers,
        onErrorAnswers
      )
    );

  };

  return {
    control,
    handleSubmit,
    onSubmit,
    membeersInfo,
    titleMembeer,
    configTableSteps,
    id_passive_tracking,
    handleIndividualDownloadExel,
    handleDownloadExel,
    paginationSettings,
    handlePagination,
    onChangeSearch,
  };
};

export default usePassiveTrackingReport;
