// Packages
import styled from "styled-components/macro";
import tw from "twin.macro";

export const StyledBadge = styled.div.attrs((props) => ({
  className: `${props.className} `,
}))`
  ${tw`inline-block px-3 text-xs py-2 rounded-2xl`}
  line-height: 0.75rem;
  ${(props) =>
    props.type === "solid"
      ? `${
          props.disabled
            ? "bg-neutral-50 text-neutral-70"
            : `
        bg-${props.variant}${props.colorLevel !== "500" ? "-" + props.colorLevel : ""} text-${
                props.variant
              }-contrast${props.colorLevel !== "500" && "-" + props.colorLevel}
        `
        } `
      : props.variant === "success"
      ? tw`bg-success-50 text-success-600 border border-success-200`
      : props.variant === "danger"
      ? tw`bg-danger-150 text-danger-600 border border-danger-200`
      : props.variant === "warning"
      ? tw`bg-warning-50 text-warning-600 border border-warning-200`
      : props.variant === "info"
      ? tw`bg-info-150 text-info-400 border border-info-100`
      : tw`bg-neutral-50 text-neutral-70 border border-neutral-70`}
`;
