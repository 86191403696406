// Packages
import React from "react";
import { Link } from "react-router-dom";

// Styles
import { StyledContentButtons } from "./PassiveTrackingReport.styles";

// Components
import useComponents from "../../../../components";

// Controllers
import useControllers from "../../../../../controllers";

// Helpers
import useHelpers from "../../../../../helpers";

// Assets
import useIcons from "../../../../../assets/icons";

const PassiveTrackingReport = () => {
  const { IconUserStatus, IconReport, IconFilter } = useIcons();

  // Components
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    Typography,
    Badge,
    Pagination,
  } = useComponents();

  const { DefaultButton } = useButtons();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { usePassiveTracking } = useTools();
  const { usePassiveTrackingReport } = usePassiveTracking();
  const {
    control,
    handleSubmit,
    onSubmit,
    membeersInfo,
    titleMembeer,
    configTableSteps,
    id_passive_tracking,
    handleIndividualDownloadExel,
    handleDownloadExel,
    paginationSettings,
    handlePagination,
    onChangeSearch,
  } = usePassiveTrackingReport();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} contentWidth="1200px" as="section">
        <div className="bg-white px-8 pt-10 pb-12 rounded-lg">
          <div className="border-b border-solid border-netral-60 pb-6 mb-16">
            <div className="mb-4">
              <Typography variant="h3">REPORTE PASSIVE TRACKING</Typography>
              <Typography variant="caption" className="uppercase">
                {titleMembeer.tool_name}
              </Typography>
            </div>
            <ContentWrappers gridColums={["1fr", "210px"]}>
              <ContentWrappers
                gridColums={["245px", "159px", "1fr"]}
                gap={{
                  row: "0",
                  column: "64px",
                }}
                align="left"
              >
                <div>
                  <Typography variant="h5">MISIÓN</Typography>
                  <Typography variant="caption" className="uppercase">
                    {titleMembeer.mission_name}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h5">PAÍS</Typography>
                  <Typography variant="caption" className="uppercase">
                    {titleMembeer.countries}
                  </Typography>
                </div>
              </ContentWrappers>

              <DefaultButton
                color="secondary"
                onClick={() =>
                  navigateTo(
                    `/herramientas/passive-tracking/${id_passive_tracking}/mapa-de-lugares`
                  )
                }
              >
                Ver mapa de lugares
              </DefaultButton>
            </ContentWrappers>
          </div>
          <ContentWrappers gridColums={["1fr", "1fr"]} className="mb-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextFieldSeach placeholder="Buscar" name="search" id="search" control={control} onInputChange={onChangeSearch} />
            </form>
            <StyledContentButtons>

              <DefaultButton color="third" onClick={handleDownloadExel}>
                Exportar CSV
              </DefaultButton>
            </StyledContentButtons>
          </ContentWrappers>
          <TableContent
            headerCentersItem={configTableSteps.headerCentersItem}
            headerCellList={configTableSteps.headerCellList}
            gridCell={configTableSteps.gridCell}
          >
            {membeersInfo.map((membeer, index) => {
              return (
                <TableRow key={index}>
                  <div>
                    <Link
                      to={`/herramientas/passive-tracking/${id_passive_tracking}/reporte/membeer/${membeer.id}`}
                    >
                      <b>
                        {membeer.name} {membeer.lastName}
                      </b>
                    </Link>
                  </div>
                  <div> {membeer.email} </div>
                  {configTableSteps.stepsId?.map((item) => {
                    const filterStep = membeer.steps.filter((step) => item === step.id);
                    return (
                      <React.Fragment key={item}>
                        {filterStep.length > 0 ? (
                          <div className="flex justify-center items-center">
                            <Link
                              to={`/herramientas/passive-tracking/${id_passive_tracking}/reporte/pasos/${filterStep[0].id}`}
                            >
                              {filterStep[0].status === "Finalizado" ? (
                                <IconUserStatus color="#000000" />
                              ) : filterStep[0].status === "Pendiente de revision" ? (
                                <IconUserStatus color="#000000" type="hold-on" />
                              ) : filterStep[0].status === "Pendiente" ? (
                                <IconUserStatus color="#000000" type="hold-on" />
                              ) : (
                                filterStep[0].status === "Denegado" && (
                                  <IconUserStatus type="x" color="#000000" />
                                )
                              )}
                            </Link>
                          </div>
                        ) : (
                          <div className="flex justify-center items-center">
                            <Link
                              to={`/herramientas/passive-tracking/${id_passive_tracking}/reporte/pasos/${item}`}
                            >
                              <IconUserStatus type="x" />
                            </Link>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                  <div>
                    <Badge
                      disabled={false}
                      type="light"
                      variant={
                        membeer.status === "Finalizado" ? "success"
                          : membeer.status === "Pendiente de revision" ||
                            membeer.status === "Pendiente"
                          ? "info"
                          : "danger"
                      }
                      label={membeer.status}
                    />
                  </div>
                  <div className="flex justify-center">
                    <button
                      onClick={() => {
                        handleIndividualDownloadExel(membeer.id);
                      }}
                    >
                      <IconReport />
                    </button>
                  </div>
                </TableRow>
              );
            })}
          </TableContent>
        </div>
        <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      </ContentWrappers>
    </>
  );
};

export default PassiveTrackingReport;
