import React from "react";
import { Route, Routes, useMatch, useLocation, Outlet } from "react-router-dom";

// Components
import useLayouts from "../../layouts";

// Controllers
import useControllers from "../../../controllers";

// Steps Views
import ProfilerView from "./Views";
import ProfilerCreate from "./Create";

const ProfilerLayout = () => {
  const { PrivateContentLayout } = useLayouts();

  const { useScreenHooks } = useControllers();
  const { useProfiler } = useScreenHooks();
  const { handleBack, header } = useProfiler();

  const match = useMatch("/perfilacion/*");
  const basePath = match ? match.pathnameBase : "/perfilacion";
  const location = useLocation();
  const isExact = basePath === location.pathname;

  const breadcrumbsList = location.pathname.split("/").length > 2
    ? header[Object.keys(header).filter((key) => location.pathname.includes(key))[1]]
    : header[Object.keys(header).filter((key) => location.pathname.includes(key))];

  return (
    <PrivateContentLayout
      breadcrumbsList={breadcrumbsList}
      handleBack={handleBack}
      showBack={!isExact}
      title="Perfilación"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Profiler = () => (
  <Routes>
    <Route path="/" element={<ProfilerLayout />}>
      <Route index element={<ProfilerView />} />
      <Route path="creacion" element={<ProfilerCreate />} />
      <Route path="editar/:id" element={<ProfilerCreate />} />
    </Route>
  </Routes>
);

export default Profiler;
