import axios from "axios";

const useMapProviders = () => {
  const locationsProvider = () => {
    return axios({
      method: "post",
      url: `countries-all`,
    });
  };

  const locationsPointsProvider = (params) => {
    return axios({
      method: "get",
      url: `analytics/membeers/geo-position`,
      params: params,
    });
  };

  return { locationsProvider, locationsPointsProvider };
};

export default useMapProviders;
