import styled from "styled-components";
import tw from "twin.macro";

export const StyledDiv = styled.div`
  padding: 20px 20px;
  height: 90vh;
  width: 100%;
`;

export const StyledWrapperForm = styled.div.attrs({
  className: "StyledWrapperForm",
})`
  ${tw` flex items-start space-x-4`}
  .MuiInputBase-formControl, .MuiFormControl-root {
    ${tw` m-0`}
  }
`;

export const StyledFormFilter = styled.div.attrs({
  className: "StyledFormFilter",
})`
  .MuiFormControl-root {
    ${tw`mb-2`}
  }
`;
