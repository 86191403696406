// Packages
import React from "react";

// Styles
import { StyledContentButtons } from "../../Membeers/Views/MembeersView.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

const TaskPreview = () => {
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    Pagination,
    Typography,
    useButtons,
    Checkbox,
    //SelectFieldControlled,
    useGrafic,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useTasks } = useScreenHooks();
  const { useAnswersMission } = useTasks();
  const { Donut } = useGrafic();

  const {
    paginationSettings,
    handlePagination,
    handleDownloadExcel,
    checkAll,
    handleChangeAllCheck,
    valuesChecks,
    handleChangeCheck,
    isVideoCall,
    listHeaderTable,
    listGridCell,
    handleSubmitApprove,
    /* handleSubmit,
    onSubmit,
    control, */
    dataQuotesData,
  } = useAnswersMission();

  return (
    <div className="px-8 pt-10 pb-12 mx-12 bg-white rounded-lg relative">
      <Typography className="pb-4 uppercase border-b" variant="h3">
        Misiones Completadas
      </Typography>
      <Donut
        data={dataQuotesData.chart}
        titleValue={dataQuotesData.general?.total_percentage}
        className="my-6"
      />
      <div className="absolute top-24 right-20">
        <Typography variant="h3">
          Completados:{" "}
          {dataQuotesData.general?.completed}
        </Typography>
        <Typography variant="h3">
          Por hacer:{" "}
          {dataQuotesData.general?.todo}
        </Typography>
      </div>

      <ContentWrappers
        gridColums={["1fr 1fr"]}
        className="items-center pb-8 mb-8 border-b border-neutral-60"
      >
        <Typography variant="h3">Listado de usuarios</Typography>
        <StyledContentButtons>
          {isVideoCall && (
            <DefaultButton color="secondary" onClick={handleSubmitApprove}>
              Aprobar
            </DefaultButton>
          )}
          {/* <FilterButton handleSubmit={handleSubmit(onSubmit)}>
            <StyledFormFilter>
              <SelectFieldControlled
                label={"Estado"}
                name={`abiFilterStatus`}
                id={`abi-filter-status`}
                control={control}
                options={[
                  { value: 0, label: "No" },
                  { value: 1, label: "Si" },
                ]}
              />
            </StyledFormFilter>
          </FilterButton> */}
          <DefaultButton color="third" onClick={handleDownloadExcel}>
            Exportar a Excel
          </DefaultButton>
        </StyledContentButtons>
      </ContentWrappers>
      <TableContent
        headerCellList={
          isVideoCall
            ? [
                <Checkbox label="" checked={checkAll} onChange={handleChangeAllCheck} />,
                ...listHeaderTable,
              ]
            : listHeaderTable
        }
        gridCell={isVideoCall ? ["1fr", ...listGridCell] : listGridCell}
      >
        {dataQuotesData?.list?.length > 0 ? (
          dataQuotesData.list?.map((membeer) => {
            return (
              <TableRow key={membeer.id}>
                {isVideoCall && (
                  <Checkbox
                    label=""
                    id={`${membeer.id}`}
                    value={membeer.id}
                    name={`${membeer.id}`}
                    onChange={handleChangeCheck}
                    checked={valuesChecks?.includes(membeer.id)}
                  />
                )}
                {/* <div>{formatDate(membeer?.created_at, "dd-mm-Y")}</div>
                <div>{formatDate(membeer?.last_date_entry, "dd-mm-Y")}</div> */}
                <div title={membeer.membeer_name}>{membeer.membeer_name}</div>
                <div>{membeer.active}</div>
                <div>
                  {!isVideoCall
                    ? membeer.missions_completed
                    : membeer.approved_tool
                    ? "Aprobado"
                    : "No aprobado"}
                </div>
                <div title={membeer.email}>{membeer.email}</div>
                <div>{membeer.nse}</div>
                <div>{membeer.gender === "M" ? "Masculino" : "Femenino"}</div>
                <div>{membeer.country_name}</div>
                <div>{membeer.consumer}</div>
              </TableRow>
            );
          })
        ) : (
          <div>Sin datos para mostrar</div>
        )}
      </TableContent>
      <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
    </div>
  );
};

export default TaskPreview;
