import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";

import newsRoot from "./View";
import newsCreate from "./Create";

const useNewsScreens = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [headerAux, setHeaderAux] = useState({});

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/noticias": ["noticias"],
    "/noticias/crear": ["noticias", "crear noticia"],
    "/noticias/editar/:Id": ["noticias", "editar noticia"],
  };

  const matches = _.mapValues(header, (item, pathname) => useMatch(pathname));

  useEffect(() => {
    _.forEach(matches, (match, pathname) => {
      if (match) {
        setHeaderAux((prevHeaderAux) => ({
          ...prevHeaderAux,
          [pathname]: header[pathname],
        }));
      }
    });
  }, [location.pathname, matches]);

  return { newsRoot, newsCreate, handleBack, header, headerAux };
};

export default useNewsScreens;
