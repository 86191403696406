import React from "react";

// Controllers
import useScreenHooks from "../../../../controllers/screenHooks";

// Components
import useComponents from "../../../components";

const View = () => {
  const {
    ContentWrappers,
    Typography,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    Pagination,
    Dropdown,
  } = useComponents();
  const { DefaultButton, FilterButton } = useButtons();

  const { useContriesScreen } = useScreenHooks();
  const { countriesView } = useContriesScreen();
  const {
    listCountries,
    handleGoToCreate,
    handleChangeSearch,
    control,
    handleSubmit,
    onSubmitFilter,
    handlePagination,
    paginationSettings,
    handleEditCountrie,
    listLanguajes,
    isoFilter,
    currency,
    currencySymbol,
    handleDownloadExel,
    handleIndividualDownloadExel,
  } = countriesView();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-11/12" as="section">
        <div className="bg-white px-8 pt-10 pb-12 rounded-lg">
          <ContentWrappers
            gridColums={["1fr 210px"]}
            className="mb-8 items-center border-b border-neutral-60 pb-8"
          >
            <Typography variant="h3">LISTADO DE PAISES</Typography>
            <DefaultButton color="secondary" onClick={handleGoToCreate}>
              Crear País
            </DefaultButton>
          </ContentWrappers>
          <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
            <TextFieldSeach
              placeholder="Buscar"
              name="search"
              id="search"
              control={control}
              onInputChange={handleChangeSearch}
            />

            <div className="flex justify-end space-x-3">
              <FilterButton handleSubmit={handleSubmit(onSubmitFilter)}>
                <div>
                  <SelectFieldControlled
                    name="iso"
                    id="abi-iso"
                    control={control}
                    label="Iso"
                    className="mb-2"
                    options={isoFilter}
                  />
                  <SelectFieldControlled
                    name="languaje"
                    id="abi-languaje"
                    control={control}
                    label="Idioma"
                    options={listLanguajes}
                  />
                  <SelectFieldControlled
                    name="currency"
                    id="abi-currency"
                    control={control}
                    label="Moneda"
                    options={currency}
                  />
                  <SelectFieldControlled
                    name="symbol"
                    id="abi-symbol"
                    control={control}
                    label="Simbolo"
                    options={currencySymbol}
                  />
                </div>
              </FilterButton>
              <DefaultButton color="third" onClick={handleDownloadExel}>
                Exportar a Excel
              </DefaultButton>
            </div>
          </ContentWrappers>
          <TableContent
            headerCellList={[
              "ID",
              "PAÍS",
              "ISO",
              "IDIOMA",
              "MONEDA",
              "SÍMBOLICO ",
              "DINERO POR UNIDAD PUNTO ",
              "MEMBERS ACTIVOS",
              "ACCIONES",
            ]}
            gridCell={["96px", "1.2fr", "0.8fr", "0.8fr", "1fr", "1fr", "1fr", "1fr", "66px"]}
          >
            {_.map(listCountries, (countrie, index) => {
              return (
                <TableRow key={index}>
                  <div>{countrie?.id}</div>
                  <div>{countrie?.country?.name}</div>
                  <div>{countrie?.country?.iso2}</div>
                  <div>{countrie?.languaje}</div>

                  <div> {countrie?.country?.currency_name} </div>
                  <div> {countrie?.country?.currency_symbol} </div>
                  <div> {countrie?.currency_membeer_point} </div>

                  <div>{countrie?.brands.length}</div>
                  <div className="flex justify-end">
                    <Dropdown options={[
                      {id: 'download', action: ()=>handleIndividualDownloadExel(countrie?.id)},
                      {id: 'edit', action: ()=> handleEditCountrie(countrie)},
                      ]}/>
                  </div>
                </TableRow>
              );
            })}
          </TableContent>
        </div>
        <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      </ContentWrappers>
    </>
  );
};

export default View;
