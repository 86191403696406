import useServices from "../../services";

const usePowerBiActions = () => {
  const { usePowerBiServices } = useServices();
  const {
    getPowerBiService
  } = usePowerBiServices();

  const actGetPowerBi = async (onSuccess, onError) => {
    try {
      const response = await getPowerBiService();
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };


  return {
    actGetPowerBi
  };
};
export default usePowerBiActions;
