import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const usePowerBiServices = () => {
  const { usePowerBiProvider } = useProviders();
  const { getPowerBiProvider } = usePowerBiProvider();

  const getPowerBiService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getPowerBiProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };


  return {
    getPowerBiService,
  };
};
export default usePowerBiServices;
