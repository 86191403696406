// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledContentWrappers = styled.div.attrs({
  className: "StyledContentWrappers"
})`
  ${tw`grid mx-auto`};
  grid-template-columns: ${props => props.gridColums?.join(' ')};
  ${props => props.repeatGrid &&
  `grid-template-columns: repeat(auto-${props.autoFillFit}, minmax(${props.minCol ? `min(${props.minCol}, 100%)` : 'min(15rem, 100%)'}, ${props.maxCol ? props.maxCol : '1fr'}));`
  }
  gap: ${props => `${props.gap?.row} ${props.gap?.column}`};
  width: ${props => props.contentWidth};
  margin-left:  ${props => props.align === "left" ? "0" : "auto"};
  margin-right:  ${props => props.align === "right" ? "0" : "auto"};
`