import FormProfilerLabels from "./FormProfilerLabels/FormProfilerLabels"
import FormProfilerNoLabels from "./FormProfilerNoLabels/FormProfilerNoLabels"


const useFormsProfiler = () => {
  return {
    FormProfilerLabels,
    FormProfilerNoLabels
  }
}

export default useFormsProfiler;