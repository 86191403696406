import React, { useMemo } from "react";
import { Route, Routes, useLocation, Outlet } from "react-router-dom";
import useLayouts from "../../layouts";
import useControllers from "../../../controllers";

// Views
import TaskView from "./Views";
import TaskCreate from "./Create";
import TaskPreview from "./Answers";
import TasksClone from "./Clone";

const TasksLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const location = useLocation();
  const { useTasks } = useControllers().useScreenHooks();
  const { handleBack, header } = useTasks();

  const pathWithOutId = useMemo(() => {
    const pathArray = location.pathname.split("/");
    const filterArray = pathArray.filter((path) => isNaN(path));
    return filterArray.join("/");
  }, [location.pathname]);

  return (
    <PrivateContentLayout
      breadcrumbsList={
        location.pathname.split("/").length > 2
          ? header[Object.keys(header).filter((header) => location.pathname.includes(header))[1]]
          : header[Object.keys(header).filter((header) => location.pathname.includes(header))]
      }
      handleBack={handleBack}
      showBack={location.pathname !== "/misiones"}
      title="Misiones"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Users = () => {
  return (
    <Routes>
      <Route path="/" element={<TasksLayout />}>
        <Route index element={<TaskView />} />
        <Route path="creacion" element={<TaskCreate />} />
        <Route path="detalle/:id" element={<TaskCreate />} />
        <Route path="preview/:id/:tool_id?" element={<TaskPreview />} />
        <Route path="editar/:id" element={<TaskCreate />} />
        <Route path="clonacion" element={<TasksClone />} />
      </Route>
    </Routes>
  );
};

export default Users;
