// Packages
import styled from "styled-components";
import tw from "twin.macro";

// Styles
import ContentWrappers from "../../components/ContentWrappers"


export const StyledMultipleChoiceItem = styled(ContentWrappers)`
  ${tw`mb-8`}
  .MuiFormControl-root {
    ${tw`mb-0`}
  }

  .MuiFormGroup-root {
    ${tw`justify-end`}
  }
  .MuiFormControlLabel-root {
    margin-bottom: 0.75rem;
  }
`;

export const StyledMultipleChoiceAddButton = styled.div.attrs({
  className: "StyledMultipleChoiceAddButton"
})`
  ${tw`flex items-end`}

  button {
    ${tw`bg-third flex justify-center items-center rounded`}
    height: 44px;
    width: 44px;
  }
`;

export const StyledMultipleChoiceRadioButton = styled.div.attrs({
  className: "StyledMultipleChoiceRadioButton"
})`
  ${tw`flex items-end text-sm text-neutral font-light`}
  letter-spacing: -0.006em;
  margin-bottom: 11px;

  label {
    ${tw`relative flex`}
    padding-left: 30px;

    &:before {
      ${tw`rounded absolute block left-0 top-0 bg-white`}
      border: 1px solid #B6BBC1;
      content: "";
      width: 24px;
      height: 24px;
    }
  }

  input {
    ${tw`hidden`}
    &:checked {
      + label {
        &:after {
          ${tw`absolute block`}
          background-color:#FFA700;
          content: "";
          height: 12px;
          left: 6px;
          top: 6px;
          width: 12px;
        }
      }
    }
  }
`;

export const StyledMultipleChoiceItemLabel = styled.span.attrs({
  className: "StyledMultipleChoiceItemLabel"
})`
  ${tw`flex items-center`}

  span {
    ${tw`text-sm text-neutral-90 mr-1`}
    letter-spacing: -0.006em;
  }
  button {
    margin-top: -3px;
  }
`;

export const StyledMultipleChoiceUpdateImage = styled.div.attrs({
  className: "StyledMultipleChoiceUpdateImage"
})`
  ${tw`flex items-end`}
  .button-update {
    ${tw`bg-third flex justify-center items-center rounded`}
    height: 44px;
    width: 44px;
  }

  .StyledInputFileContent {
    ${tw`mb-0`}
  }
`;

export const StyledQuestionsListItem = styled.li.attrs({
  className: "StyledQuestionsListItem"
})`
  ${tw`flex mb-2`}

  
  > button {
    transition: 0.5s;
  }
`;

export const StyledQuestionsListItemContent = styled.div.attrs({
  className: "StyledQuestionsListItemContent"
})`
  ${tw`flex-1 border border-solid border-third-200 rounded bg-white relative overflow-hidden`}
  padding: 10px 16px;
  height: ${props => props.active ? "auto" : "42px"};
  
  p {
    ${tw`text-sm truncate`}
    white-space: ${props => props.active ? "initial" : "nowrap"};
    width: ${props => props.active ? "100%" : "400px"};
    letter-spacing: -0.006em;
    line-height: 20px;
  }

  > div {

    > span {
      ${tw`text-xs`}
    }

    > p {
      ${tw`text-sm font-light`}
      margin-top: 2px;
      letter-spacing: -0.006em;
    }
  }

  > button {
    ${tw`absolute`}
    right: 16px;
    top: 17px;
    transition: 0.5s;
    transform: rotate(${props => props.active ? "180deg" : "0"});
  }
`;

export const StyledQuestionsListItemNumber = styled.span.attrs({
  className: "StyledQuestionsListItemNumber"
})`
  ${tw`flex justify-center items-center bg-third rounded-tl rounded-bl text-xs`}
  margin-top: 3px;
  height: 32px;
  width: 32px;
`;

export const StyledQuestionsListItemMove = styled.span.attrs({
  className: "StyledQuestionsListItemMove"
})`
  ${tw`bg-third-200 flex justify-center items-center flex-col rounded-tr rounded-br`}
  margin-top: 3px;
  height: 32px;
  width: 32px;

  span {
    ${tw`block border-2 border-solid border-third-600 rounded-full`}
    height: 4.75px;
    width: 4.75px;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledQuestionsListItemActionButtons = styled.div.attrs({
  className: "StyledQuestionsListItemActionButtons"
})`
  ${tw`flex justify-end`}

  button {
    margin-left: 20px;
  }
`;

export const StyledQuestionsTextResponse = styled.div.attrs({
  className: "StyledQuestionsTextResponse"
})`
  .MuiFormControl-root {
    ${tw`w-full`}
  }
  .MuiFormGroup-root {
    ${tw`w-full grid`}
    gap: 0px 32px;
    grid-template-columns: 1fr 1fr;
  }

  .MuiFormLabel-root, .MuiFormLabel-root.Mui-focused {
    ${tw `text-secondary-500`}
  }
`;

export const StyledOptionEdit = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const StyledQuestionEditInput = styled.input`
  ${tw`bg-neutral-30 border border-neutral-60 text-neutral rounded`}
  font-size: 0.875rem;
  font-weight: 300;
  height: 2.75rem;
  padding: 0.6rem 1rem;
  width: 25rem;
`;

export const StyledOptionsHeader = styled.p`
  font-size: 0.75rem !important;
  margin-top: 0.5rem;
`;

export const StyledOptionDelete = styled.button`
  margin-left: 0.5rem;
`;
