import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "lodash.debounce";

import useHelpers from "../../../helpers";
import useApi from "../../../api";
import useModels from "../../../models";
import useControllers from "../..";

import { map } from "lodash";

const useMapScreen = () => {
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useGeneral } = useQuickFunctions();
  const { mapSchema, FilterMapValidationSchema } = useValidators();
  const { formatDate } = useGeneral();

  const { useGeneralHooks } = useControllers();
  const { createDownloadExel, prepareDataFilterDownload } = useGeneralHooks();

  const { useActions } = useApi();
  const { useMapActions, useGeneralActions, useMembeersActions } = useActions();
  const { actExportExel } = useGeneralActions();
  const { actLocations, actLocationsPoints } = useMapActions();
  const { actListMembeers } = useMembeersActions();

  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();
  const { country } = useSelector(session);

  const [filterDataOnSubmitExcel, setFilterDataOnSubmitExcel] = useState({});
  const [filterData, setFilterData] = useState({});
  const [listCountries, setListCountries] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [dataAllCountries, setDataAllCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [locations, setLocations] = useState([]);

  const [center, setCenter] = useState({});
  const [showModal, setShowModal] = useState(false);

  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(mapSchema),
  });

  const FilterForm = useForm({
    mode: "all",
    resolver: yupResolver(FilterMapValidationSchema),
    defaultValues: {
      range_date: null,
    },
  });

  const orderDataArray = (arr, paramOne, paramTwo, bool) => {
    return arr[0][paramOne]?.map((item) => ({
      label: item[paramTwo],
      value: bool ? item.id : item[paramTwo],
    }));
  };

  const onClickClear = () => FilterForm.resetField("range_date");

  const orderDataAge = (arr) => {
    const keys = Object.keys(arr[0]?.parameters?.age_distribution);
    const values = Object.values(arr[0]?.parameters?.age_distribution);
    return values?.map((val, i) => ({ label: keys[i], value: keys[i] }));
  };

  const orderDataNse = (obj) => {
    const arr = [...Object.values(obj)];
    return arr?.map(({ nse }) => ({ label: nse, value: nse }));
  };

  const orderDataCitys = (arr) => {
    return arr[0]?.departments?.map(({ name }) => ({ label: name, value: name }));
  };

  const updateDataFilter = (arr, value) => {
    const countrySelected = arr?.filter(({ id }) => id === value);
    const object = {
      genders: orderDataArray(countrySelected, "genders", "gender", false),
      regions: orderDataArray(countrySelected, "abi_regions", "abi_region", true),
      nse: orderDataNse(countrySelected[0]?.nse_ranges),
      ages: orderDataAge(countrySelected),
      citys: orderDataCitys(countrySelected),
    };
    setFilterData(object);
  };

  const setLocationsCountry = (arr) => {
    const locations = arr?.map(({ latitude, longitude }) => ({
      lat: Number(latitude),
      lng: Number(longitude),
    }));
    setLocations(locations);
  };

  const setCenterCountry = (arr, value) => {
    const country = arr?.filter(({ id }) => id == value);
    setCenter({ lat: Number(country[0]?.lat), lng: Number(country[0]?.lon) });
  };

  useEffect(() => {
    actLocations({}, (data) => {
      setDataAllCountries(data?.data);
      dataListCountries(data?.data);
      setValue("country_id", `${country?.id}`);
      setSelectedCountry(country?.name);
    });
  }, []);

  const dataListCountries = (data) => {
    const listCountry = map(data, (v) => ({ label: v.name, value: v.id }));
    setListCountries(listCountry);
    updateDataFilter(data, country?.id);
    actLocationsPoints({ country_id: country?.id }, (info) => {
      setLocationsCountry(info?.data?.data);
      setCenterCountry(data, country?.id);
    });
  };

  const onSubmit = ({ country_id }, e) => {
    e.preventDefault();
    actLocationsPoints({ country_id }, (info) => {
      setLocationsCountry(info?.data?.data);
      setCenterCountry(dataAllCountries, country_id);
    });
  };

  const addZeroToHour = (hour) => {
    const string = hour?.toString();
    return string?.length < 2 ? `0${hour}` : hour;
  };

  const orderDateWithHours = (date) => {
    const start = `${date[0]?.year}-${addZeroToHour(date[0]?.month?.number)}-${addZeroToHour(date[0]?.day)} ${addZeroToHour(
      date[0]?.hour
    )}:${addZeroToHour(date[0]?.minute)}:${addZeroToHour(date[0]?.second)}`;

    const end = `${date[1]?.year}-${addZeroToHour(date[1]?.month?.number)}-${addZeroToHour(date[1]?.day)} ${addZeroToHour(
      date[1]?.hour
    )}:${addZeroToHour(date[1]?.minute)}:${addZeroToHour(date[1]?.second)}`;

    return { start, end };
  };

  const onSubmitFilter = ({
    mapFilterNse,
    mapFilterGender,
    mapFilterAge,
    mapFilterRegion,
    mapFitlerCity,
    mapFilterPeople,
    range_date,
  }) => {
    const country_id = getValues()?.country_id;

    const filters = {
      country_id,
      ...(mapFilterNse && { nse: mapFilterNse?.toLowerCase() }),
      ...(mapFilterGender && { gender: mapFilterGender?.slice(0, 1) }),
      ...(mapFilterAge && { age: mapFilterAge }),
      ...(mapFilterRegion && { region_id: mapFilterRegion }),
      ...(mapFitlerCity && { city_id: mapFitlerCity }),
      ...(mapFilterPeople && { users: mapFilterPeople.map((v) => v.id) }),
      ...(!!range_date && {
        startDate: !!range_date ? orderDateWithHours(range_date)?.start : null,
      }),
      ...(!!range_date && { endDate: !!range_date ? orderDateWithHours(range_date)?.end : null }),
    };
    setFilterDataOnSubmitExcel(filters);
    actLocationsPoints(filters, (data) => {
      setLocationsCountry(data?.data?.data);
      setCenterCountry(dataAllCountries, country_id);
    });
  };

  const handleOnChangeCountries = ({ target }) => {
    const findCountrieSelected = listCountries.find((countrie) => countrie.value === target.value);
    updateDataFilter(dataAllCountries, findCountrieSelected?.value);
    setSelectedCountry(findCountrieSelected?.label);
  };

  const handleDownloadExcel = () => {
    const keys = Object.keys(filterDataOnSubmitExcel);
    const country_id = getValues()?.country_id;
    const filtersProcces = prepareDataFilterDownload(
      keys?.length > 0 ? filterDataOnSubmitExcel : { country_id }
    );
    actExportExel(`analytics/membeers/geo-position`, filtersProcces, (result) => {
      //createDownloadExel(result);
      setShowModal(true);
    });
  };

  const prepareDataUser = (data) => {
    setListUsers(
      data.data.map((v) => {
        return { ...v, label: v.membeer_name, value: v.id };
      })
    );
  };

  const onSubmitSearchUsers = (e) => {
    if (e.target.value.length >= 3) {
      actListMembeers({ page: 1, params: { search: e.target.value } }, prepareDataUser);
    }
  };

  const onChangeSearchUser = useCallback(debounce(onSubmitSearchUsers, 750), []);

  const handleCloseModal = () => setShowModal(false);

  return {
    center,
    locations,
    control,
    controlFilter: FilterForm.control,
    handleSubmitFilter: FilterForm.handleSubmit,
    onSubmit,
    onSubmitFilter,
    handleSubmit,
    listCountries,
    handleOnChangeCountries,
    selectedCountry,
    filterData,
    onClickClear,
    handleDownloadExcel,
    onChangeSearchUser,
    listUsers,
    handleCloseModal,
    showModal,
  };
};

export default useMapScreen;
