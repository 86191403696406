// Packages
import React from "react";
import { Route, Routes } from "react-router-dom";

// Components
import useViews from "../../views";
import DocumentFollowUsers from "../../views/screens/Dashboard/View/Components/DocumentFollowUsers";

const PublicRouting = () => {
  const { useScreens } = useViews();
  const { Login, Password, LaunchApp } = useScreens();
  const { ForgotPassword, RecoveryPassword } = Password();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/contrasena/olvidar" element={<ForgotPassword />} />
      <Route path="/contrasena/restablecer/:token" element={<RecoveryPassword />} />
      <Route path="/contrasena/restablecer/app/:tokenApp" element={<RecoveryPassword />} />
      <Route path="/launch" element={<LaunchApp />} />
      <Route path="/pdf/dashboard/chart" element={<DocumentFollowUsers />} />
    </Routes>
  );
};

export default PublicRouting;
