import { useState, useEffect } from "react";
import {useLocation} from "react-router-dom"

import useControllers from "../../..";

// Actions
import useApi from "../../../../api";

const useDashboardMembeer = () => {
  const location = useLocation()
  const [listMembeers, setListMembeers] = useState([]);
  const [dataMemebers, setdataMembers] = useState([]);
  const [filterList, setFilterList] = useState({});
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const type = location.state.type;
  const configPage = {
    newUser: {
      title: 'Listado de nuevos membeers',
      graph: true,
    },
    responseRate: {
      title: 'Listado de tasa de respuesta',
      graph: false,
    },
    notReceivedMissionsLastWeek: {
      title: 'Listado de sin recibir misiones',
      graph: false,
    },
    doNotOpenedAppLast3Months: {
      title: 'Listado de sin abrir app',
      graph: false,
    },
  }

  // Actions
  const { useActions } = useApi();
  const { useGeneralActions, useDashboardActions } = useActions();
  const { actExportExel } = useGeneralActions();
  const { getActInfoMembeer } = useDashboardActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const loadData = (data) => {
    setListMembeers(data?.data);
    setPaginationSettings({ current: data?.meta.current_page, totalPages: data?.meta.last_page });
    setdataMembers(data?.graph)
  };

  const handleDownloadExcel = () => {
    const filtersProcces = prepareDataFilterDownload(filterList);
    actExportExel(`getUserInformationMembeer/engagement`, filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  const handlePagination = (nro) => {
    getActInfoMembeer(
      {
        page: nro.selected + 1,
        ...filterList
      },
      loadData
    );
  };

  const handleClickChart = data => {
    setFilterList({...filterList, month: data.activeTooltipIndex + 1})
    getActInfoMembeer({countryId: location.state.countryId, month: data.activeTooltipIndex + 1, type: location.state.type}, loadData)
  }

  useEffect(() => {
    getActInfoMembeer({countryId: location.state.countryId, month: new Date().getMonth() + 1, type: location.state.type}, loadData)
    setFilterList({countryId: location.state.countryId, month: new Date().getMonth() + 1, type: location.state.type})
  }, []);

  return {
    handleDownloadExcel,
    listMembeers,
    paginationSettings,
    dataMemebers,
    handlePagination,
    handleClickChart,
    configPage,
    type,
  };
};

export default useDashboardMembeer;
