import styled from 'styled-components';
import tw from 'twin.macro';
import Typography from '../../components/Typography';
import Button from '../Buttons/DefaultButton';

export const StyledWrapper = styled.div.attrs({
  className: 'StyledWrapper',
})`
  && {
    ${tw`relative flex flex-col bg-white px-16 py-20 mx-auto rounded-2xl w-2/4`}

    ${(props) => {
      return `
        ${props.maxWidth && `max-width: ${props.maxWidth};`}
      `;
    }}
    
    @media (max-width: 767.98px) {
      border-radius: 8px;
      padding: 32px;
      padding-bottom: 54px;
    }
    ${(props) => {
      return `
        ${
          props.isReview &&
          `
            padding-top: 132px;
        `
        }
      `;
    }}
  }
`;

export const StyledImageContainer = styled.div.attrs({
  className: 'StyledImageContainer',
})`
  && {
    ${tw` absolute`}
    top: 0;
    left: 50%;
    width: 120px;
    max-width: 120px;
    height: 120px;
    max-height: 120px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    transform: translate(-50%, -50%);
    z-index: 1;
    @media (max-width: 768px) {
      top: 32px;
      left: 100%;
      width: 80px;
      max-width: 80px;
      height: 80px;
      max-height: 80px;
      transform: translate(calc(-100% - 40px), 0);
    }
  }
`;

export const StyledWrapperImage = styled.img.attrs({
  className: 'StyledWrapperImage',
})`
  && {
    ${tw` relative`}
    width: 120px;
    max-width: 120px;
    height: 120px;
    max-height: 120px;
    border-radius: 8px;
    @media (max-width: 768px) {
      width: 80px;
      max-width: 80px;
      height: 80px;
      max-height: 80px;
    }
  }
`;

export const StyledButtonImage = styled(Button).attrs({
  className: 'StyledButtonImage',
})`
  && {
    ${tw` absolute`}
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    padding: 0;
    top: 100%;
    left: 100%;
    transform: translate(-80%, -80%);
  }
`;

export const StyledLogoContainer = styled.img.attrs({})`
  ${tw`w-36 mx-auto mb-4`}
`;
export const StyledWrapperTitle = styled(Typography).attrs({
  className: 'StyledWrapperTitle',
})`
  ${tw`text-center`}
`;
export const StyledWrapperSubTitle = styled(Typography).attrs({
  className: 'StyledWrapperSubTitle StyledTypographyBold',
})`
  && {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: black;
    margin: 0.75rem auto;
    max-width: 50%;
    text-align: center;
    @media (max-width: 768px) {
      margin-top: 0px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledWrapperDescription = styled(Typography).attrs({
  className: 'StyledWrapperDescription',
})`
  && {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #6d7074;

    @media (max-width: 768px) {
      color: #494b4d;
    }
  }
`;

export const StyledChildrenContainer = styled.div.attrs({
  className: 'StyledChildrenContainer',
})`
  && {
    ${tw` relative`}
  }
`;
