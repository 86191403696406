import React from "react";
import PropTypes from "prop-types";

// Styles
import {
  StyledInputSwitch,
  StyledInputSwitchSpan,
  StyledInputSwitchContent,
  StyledInputSwitchText,
} from "./InputSwitch.styles";

/**
 * Input Routes component
 */
export const InputSwitch = (props) => {
  const { id, name, label, onChange, defaultChecked, className, value } = props;

  return (
    <StyledInputSwitchContent className={className}>
      <StyledInputSwitch htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          name={name}
          onChange={onChange}
          defaultChecked={defaultChecked}
          value={value}
          checked={value}
        />
        <StyledInputSwitchSpan />
        <StyledInputSwitchText>{label}</StyledInputSwitchText>
      </StyledInputSwitch>
    </StyledInputSwitchContent>
  );
};

InputSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  className: PropTypes.string,
};

InputSwitch.defaultProps = {
  id: "",
  name: "",
  label: "Inactivo",
  defaultChecked: false,
  onChange: () => {},
};

export default InputSwitch;
