import useApi from "../../index";

const useNavBarActions = () => {
  const { useServices } = useApi();
  const { useNavBarServices } = useServices();
  const {
    getNavBarItemsService
  } = useNavBarServices();

  const actGetNavBarOptions = (onSuccess, onError) => async () => {
    try {
      const response = await getNavBarItemsService();
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };


  return {
    actGetNavBarOptions
  };
};

export default useNavBarActions;
