const IconUpdateImage = ({ color = '#898781' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={ color }
        d="M23.997 4.497v9.426a.9.9 0 01-1.8 0V4.497A2.701 2.701 0 0019.5 1.798H4.497A2.701 2.701 0 001.8 4.497v14.535l9.797-7.883a4.497 4.497 0 015.627.002l.985.787a.9.9 0 01-1.124 1.406l-.99-.792a2.657 2.657 0 00-3.369-.003L2.245 20.98a2.696 2.696 0 002.252 1.217h9.768a.9.9 0 110 1.8H4.497a4.493 4.493 0 01-4.194-2.913.87.87 0 01-.06-.193A4.447 4.447 0 010 19.5V4.497A4.503 4.503 0 014.497 0H19.5a4.502 4.502 0 014.497 4.497zM21.64 16.262a2.492 2.492 0 00-1.612-.844.871.871 0 00-.725 0 2.494 2.494 0 00-1.614.844l-2.146 2.567a.9.9 0 001.38 1.154l1.843-2.204v5.322a.9.9 0 101.799 0v-5.32l1.841 2.202a.9.9 0 001.38-1.154l-2.146-2.567zM7.216 4.664a2.894 2.894 0 110 5.788 2.894 2.894 0 010-5.788zm0 1.8a1.096 1.096 0 100 2.19 1.096 1.096 0 000-2.19z"
      ></path>
    </svg>
  );
};

export default IconUpdateImage;
