import React from "react";
import { Routes, Route, useLocation, useNavigate, Outlet } from "react-router-dom";
import useScreenHooks from "../../../controllers/screenHooks";
import useLayouts from "../../layouts";

import View from "./View";
import NewMembeers from "./NewMembeers";

const DashboardLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const { useDashboardScreens } = useScreenHooks();
  const { header } = useDashboardScreens();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <PrivateContentLayout
      breadcrumbsList={header[location.pathname]}
      showBack={location.pathname !== "/dashboard"}
      handleBack={() => navigate(-1)}
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Dashboard = () => (
  <Routes>
    <Route path="/" element={<DashboardLayout />}>
      <Route index element={<View />} />
      <Route path="engament" element={<NewMembeers />} />
    </Route>
  </Routes>
);

export default Dashboard;
