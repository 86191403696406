import React from "react";
import useIcons from "../../../../assets/icons";
import useControllers from "../../../../controllers";
import useComponents from "../../../components";

//Styles
import { StyledContentUploadFile, StyledTableScroll } from "./Detail.styles";

const Detail = () => {
  const { IconUserRemove, IconArrowFilter } = useIcons();

  const {
    ContentWrappers,
    Typography,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    InputFile,
    Badge,
    Pagination,
    Checkbox,
    useModals,
    DatePickerRangeControlled,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { usePaymentsScreen } = useScreenHooks();
  const { paymentsDetail } = usePaymentsScreen();
  const {
    paymentList,
    handleChangeSearch,
    handleSubmit,
    onSubmitFilter,
    control,
    handlePagination,
    paginationSettings,
    handleChangeAllCheck,
    valuesChecks,
    handleChangeCheck,
    checkAll,
    handleSendDocument,
    handleDownloadExel,
    //handleEditStatus,
    showModal,
    handleCloseModal,
    showModalLoad,
    handleCloseModalLoad,
    valueCountryId,
    tableHeader,
    tableHeaderWidth,
    handleChangeStatusIndividual,
    countrieName,
    onClickClear,
    fileInputRef
  } = paymentsDetail();

  const { formatDate } = useGeneralHooks();

  return (
    <>
      <StyledTableScroll
        gridColums={["1fr"]}
        className="max-w-screen-2xl w-11/12 flex flex-col mx-auto"
        as="section"
      >
        <div className="bg-white px-8 pt-10 pb-12 rounded-lg z-10">
          <ContentWrappers
            gridColums={["1fr"]}
            className="mb-8 items-center border-b border-neutral-60 pb-8 relative z-10"
          >
            <div className="flex justify-between items-center w-full">
              <Typography variant="h3" className="uppercase">
                LISTADO DE PAGOS {countrieName}
              </Typography>

              <div className="flex items-center space-x-4">
                <TextFieldSeach
                  placeholder="Buscar"
                  name="membeer_name"
                  id="abi-membeer_name"
                  control={control}
                  onInputChange={handleChangeSearch}
                  className="w-20"
                />

                <div className="flex justify-end space-x-3">
                  <FilterButton handleSubmit={handleSubmit(onSubmitFilter)}>
                    <>
                      <div>
                        <SelectFieldControlled
                          name="member_state"
                          id="abi-member_state"
                          control={control}
                          label="Estado membeer"
                          options={[
                            {
                              label: "Activo",
                              value: 1,
                            },
                            {
                              label: "Sin banco",
                              value: 3,
                            },
                            {
                              label: "Inactivo",
                              value: 2,
                            },
                          ]}
                        />
                      </div>
                      <div>
                        <SelectFieldControlled
                          name="payment_status"
                          id="abi-payment_status"
                          control={control}
                          label="Estado pago"
                          options={[
                            {
                              label: "Pagado",
                              value: 1,
                            },
                            {
                              label: "No pagado",
                              value: 2,
                            },
                            {
                              label: "No procesable",
                              value: 9,
                            },
                          ]}
                        />
                      </div>
                      <div className="flex items-center mb-4">
                        <DatePickerRangeControlled
                          label="Rango fecha de misión"
                          name="range_date"
                          id="range_date"
                          control={control}
                          onClickClear={onClickClear}
                        />
                      </div>
                    </>
                  </FilterButton>
                </div>
              </div>
            </div>
          </ContentWrappers>

          <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
            <div className="flex items-center space-x-4">
              {/* <DefaultButton color="secondary" onClick={handleEditStatus}>
                Marcar como no procesable
              </DefaultButton> */}

              <StyledContentUploadFile>
                <InputFile
                  placeholder={"image.jpg"}
                  name={`image_email`}
                  id={`abi-notification-image-email`}
                  inputTypeIcon={"Cargar pagos de dispersora"}
                  onChange={(event) => {
                    handleSendDocument(event);
                  }}
                  control={control}
                  maxFiles={1}
                  maxSize={10485760}
                  accept="text/csv,text/xlsx,text/xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  inputFileRef={fileInputRef}
                />
              </StyledContentUploadFile>
            </div>
            <div className="flex justify-end space-x-3 relative">
              <DefaultButton color="third" onClick={handleDownloadExel}>
                Exportar a Excel
              </DefaultButton>
            </div>
          </ContentWrappers>

          <TableContent
            headerCellList={[
              //<Checkbox label="" checked={checkAll} onChange={handleChangeAllCheck} />,
              ...tableHeader,
            ]}
            gridCell={tableHeaderWidth}
          >
            {paymentList != undefined && paymentList.length > 0
              ? _.map(paymentList, (itemPayment, index) => {
                  return (
                    <TableRow key={index}>
                      {/* <div>
                        <Checkbox
                          label=""
                          id={`${itemPayment.id}`}
                          value={itemPayment.id}
                          name={`${itemPayment.id}`}
                          onChange={handleChangeCheck}
                          checked={valuesChecks.includes(itemPayment.id)}
                        />
                      </div> */}
                      <div>{formatDate(itemPayment?.mission_created)}</div>
                      <div className="truncate">{itemPayment?.membeer_name}</div>
                      <div>
                        <Badge
                          disabled={itemPayment?.member_state.toLowerCase() === "inactivo"}
                          variant={
                            itemPayment?.member_state.toLowerCase() === "activo"
                              ? "success"
                              : itemPayment?.member_state.toLowerCase() === "sin banco"
                              ? "warning"
                              : "none"
                          }
                          label={itemPayment?.member_state}
                          type="light"
                        />
                      </div>
                      <div>{itemPayment?.mission_code}</div>
                      <div>{itemPayment?.mision_name}</div>
                      <div>{itemPayment?.amount}</div>

                      {valueCountryId === "multizonal" && <div>{itemPayment?.currency}</div>}
                      <div>
                        <Badge
                          disabled={itemPayment?.payment_status.toLowerCase() === "no pagado"}
                          variant={
                            itemPayment?.payment_status.toLowerCase() === "pagado"
                              ? "success"
                              : itemPayment?.payment_status.toLowerCase() === "no procesable"
                              ? "warning"
                              : "none"
                          }
                          label={itemPayment?.payment_status}
                          type="light"
                        />
                      </div>
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={() => {
                            handleChangeStatusIndividual(itemPayment.id);
                          }}
                          className={
                            itemPayment?.payment_status.toLowerCase() === "no procesable"
                              ? "cursor-auto"
                              : null
                          }
                          disabled={itemPayment?.payment_status.toLowerCase() === "no procesable"}
                        >
                          <IconUserRemove
                            color={
                              itemPayment?.payment_status.toLowerCase() !== "no procesable"
                                ? "#000"
                                : "#DAE0E8"
                            }
                          />
                        </button>
                      </div>
                    </TableRow>
                  );
                })
              : "No tiene pagos cargados "}
          </TableContent>
          <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
        </div>
      </StyledTableScroll>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center w-80 ">
            El pago de la misiones seleccionadas han cambiado su estado a{" "}
            <strong>NO PROCESABLES.</strong>
          </Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
      <DefaultModal isOpen={showModalLoad}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center w-80 ">
            La importación del archivo de pagos se ha subido con exito, se le informará por correo
            una vez sea procesado
          </Typography>
          <DefaultButton color="success" onClick={handleCloseModalLoad}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </>
  );
};

export default Detail;
