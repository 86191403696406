const IconBrand = ({ color = "#B6B9BA", strokeHover }) => {
  return (
    <svg
      data-stroke={strokeHover}
      width="24"
      height="24"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2455_4547)" style={{ transform: "scale(1.3) translate(-6px, 0px)" }}>
        <path
          d="M9.92893 9.89944L17 2.82837L24.0711 9.89944L24.0711 23.2496C24.0695 23.778 23.8589 24.2843 23.4853 24.6579C23.1117 25.0315 22.6054 25.2421 22.077 25.2437H11.923C11.3946 25.2421 10.8883 25.0315 10.5147 24.6579C10.1411 24.2843 9.9305 23.778 9.92893 23.2496L9.92893 9.89944Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.4"
          d="M17 9.89954L17.0071 9.90661"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2455_4547">
          <rect width="34" height="34" fill="white" transform="translate(17) rotate(45)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconBrand;
