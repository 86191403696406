// Packages
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useActions from "../../../../api/actions";

// Components
import useComponents from "../../../../views/components";

const usePasswordForgot = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false)

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast } = useQuickFunctions();
  const { forgotValidatorSchema } = useValidators();
  const { replaceAndNavigateTo } = useNavigation();
  const { warn, success } = useToast();

  // Actions
  const { useAuthActions } = useActions();
  const { actForgotPassword } = useAuthActions();

  // Components
  const { Toast } = useComponents();

  const { control, handleSubmit } = useForm({
    mode: "all",
    defaultValues: {
      email: location?.state?.email[0],
    },
    resolver: yupResolver(forgotValidatorSchema),
  });

  const handleBack = () => {
    replaceAndNavigateTo("/");
  };

  const handleCloseModal = () => {handleBack()}

  const onSubmit = useCallback(({ email }) => {
    const onSuccessLogin = (data) => {
      if (data.status === 200) {
        setShowModal(true)
      } else {
        warn(<Toast text={data.data.message} />, {
          toastId: 0,
        });
      }
    };
    actForgotPassword({ email }, onSuccessLogin);
  }, []);

  return {
    control,
    handleSubmit,
    onSubmit,
    handleBack,
    showModal,
    handleCloseModal,
  };
};

export default usePasswordForgot;
