const IconArrowLeft = ({color = "#FFA700"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="18"
      fill="none"
      viewBox="0 0 10 18"
    >
      <path
        stroke={ color }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.8"
        strokeWidth="2"
        d="M9 1L1 9l8 8"
      ></path>
    </svg>
  );
}

export default IconArrowLeft;
