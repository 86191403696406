// Packages
import styled from 'styled-components';

export const StyledSurveyModalContainer = styled.div`
  height: 534px;
  padding: 0;
  width: 697px;
`;

export const StyledSurveyModalClose = styled.img`
  cursor: pointer;
  height: 1.125rem
  width: 1.125rem
`;

export const StyledSurveyModalContent = styled.div`
  margin: auto;
  padding: 1rem 0;
  width: 585px;
`;

export const StyledModalTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
`;

export const StyledModalDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  margin: 1rem 0 3rem;
`;

export const StyledSurveyCreationImg = styled.img`
  border-radius: 10px 10px 0 0;
  height: 10rem;
  object-fit: cover;
  width: 100%;
`;

export const StyledSurveyCreationContainer = styled.div`
  padding: 1rem;
`;

export const StyledSurveyCreationTitle = styled.p`
  line-height: 1.375rem;
  font-size: 1rem;
  font-weight: 700;
  margin: 1rem 0 0.75rem;
`;

export const StyledSurveyCreationDescription = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;
