// Packages
import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { useController } from "react-hook-form";

// Styles
import { StyledInputDraftContent } from "./InputDraft.styles";
import "react-quill/dist/quill.snow.css";

// Components
import useComponents from "..";

import { StyledHelperText } from "../SelectField/SelectField.styles";

const InputDraft = (props) => {
  const { label, name, control, placeholder, helperText, inputRef } = props;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  // Component
  const { FormLabel } = useComponents();


  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }, {size: ['small', 'normal', 'large', 'huge']}],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", {color: ["black", "red", "green", "yellow", "blue", "orange"]}],
    ],
  };

  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "link",
  ];

  return (
    <StyledInputDraftContent>
      <FormLabel label={label} />
      <ReactQuill
        ref={el => inputRef(el)}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        theme="snow"
        modules={modules}
        formats={formats}
      />
      {error && (
        <>
          {helperText ? (
            <StyledHelperText>{helperText}</StyledHelperText>
          ) : (
            <StyledHelperText>{error?.message}</StyledHelperText>
          )}
        </>
      )}
    </StyledInputDraftContent>
  );
};

InputDraft.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.any.isRequired,
  helperText: PropTypes.any,
};

export default InputDraft;
