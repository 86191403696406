import useValidators from './validators';
import useNavigation from './navigation';
import useAuth from './auth';
import useRouterHelper from './routerHelper'
import useToast from './toast'
import useGeneral from './general'

const useQuickFunctions = () => {
  return {
    useValidators,
    useNavigation,
    useAuth,
    useRouterHelper,
    useToast,
    useGeneral,
  };
};

export default useQuickFunctions;
