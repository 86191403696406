import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const usePaymentsServices = () => {
  const { usePaymentsProvider } = useProviders();
  const {
    listPaymentsPrivider,
    createPaymentsProvider,
    editPaymentsProvider,
    getPaymentsProvider,
    getMissionToPay,
    editPaymentStatusProvider,
    loadFilePaymentsProvider,
  } = usePaymentsProvider();

  const listPaymentsService = ({
    page,
    country_id,
    member_state,
    payment_status,
    membeer_name,
    mision_name,
    init_date_approval,
    end_date_approval,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            listPaymentsPrivider({
              page,
              country_id,
              member_state,
              payment_status,
              membeer_name,
              mision_name,
              init_date_approval,
              end_date_approval,
            })
          )
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const createPaymentsService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createPaymentsProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const editPaymentsService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editPaymentsProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getPaymentsService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getPaymentsProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listMissionToPayService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getMissionToPay()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const editPaymentsStatusService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editPaymentStatusProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const loadFilePaymentsService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(loadFilePaymentsProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listPaymentsService,
    createPaymentsService,
    editPaymentsService,
    getPaymentsService,
    listMissionToPayService,
    editPaymentsStatusService,
    loadFilePaymentsService,
  };
};
export default usePaymentsServices;
