import useDashboardView from "./View";
import useDashboardMembeer from "./NewMembeer";
import useDashboardDownload from "./download"

const useDashboardScreens = () => {
  const header = {
    "/dashboard": ["Home", "dashboard"],
  };

  return { header, useDashboardView, useDashboardMembeer, useDashboardDownload };
};

export default useDashboardScreens;
