import ForgotPassword from './Forgot';
import RecoveryPassword from './Recovery';

const Password = () => {
    return  {
        ForgotPassword,
        RecoveryPassword,
    }
}

export default Password;
