import React from "react";

// Assets
import iconUserCircle from "../../../../../../assets/images/icon-user-circle.svg";
import bgChart from "../../../../../../assets/images/bg-chart-dash.png";

import useScreenHooks from "../../../../../../controllers/screenHooks";

import {
  StyledGridDash,
  StyledChartDegra,
  StyledGridRow,
  BootstrapTooltip,
} from "../../View.styles";

const DocumentFollowUsers = () => {
  const { useDashboardScreens } = useScreenHooks();
  const { useDashboardDownload } = useDashboardScreens();
  const { followMembeers } = useDashboardDownload();

  return (
    <StyledGridDash id="follow-users">
      <div>
        <p className="text-xs text-neutral-80">TASA DE CONVERSIÓN</p>
      </div>
      <div>
        {/* <p className="bg-surface rounded text-center text-sm rounded-sm py-1">
            28,500 Total descargas app
          </p> */}
      </div>
      <div className="col-span-3">
        <img src={iconUserCircle} alt="Usuarios" width="40" height="40" className="ml-3" />
      </div>
      <StyledGridRow>
        <div className="abi-rate-convertion">
          <p className="border-success-500 text-success-500">
            {followMembeers?.percentageStartOnFinish}
          </p>
        </div>
        <div className="abi-rate-convertion">
          <p className="border-success-500 text-success-500">
            {followMembeers?.percentageActiveOnFinish}
          </p>
        </div>
        <div className="abi-rate-convertion">
          <p className="border-success-500 text-success-500">
            {followMembeers?.percentageActiveOnMissionCompleted}
          </p>
        </div>
        <div className="abi-rate-convertion">
          <p className="border-success-500 text-success-500">
            {followMembeers?.percentagePaidOnMissionCompleted}
          </p>
        </div>
      </StyledGridRow>
      <StyledChartDegra>
        <img
          src={bgChart}
          className="absolute inset-x-0 bottom-0 top-4 -z-1"
          width="516px"
          height="380px"
        />
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p>Inicio de perfilamiento</p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p>Perfilamiento completo</p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p>Usuario activos</p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p className="max-w-[200px]">Usuarios con misión completada</p>
        </div>
        <div>
          <p>Usuarios pagados</p>
        </div>
      </StyledChartDegra>
      <StyledGridRow>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p className="text-base bg-surface rounded-sm py-4 px-2 w-20 text-center">
            {followMembeers?.startedSurvey}
          </p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p className="text-base bg-surface-600 rounded-sm py-4 px-2 w-20 text-center">
            {followMembeers?.completedSurvey}
          </p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p className="text-base bg-surface-700 rounded-sm py-4 px-2 w-20 text-center">
            {followMembeers?.activeUsers}
          </p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p className="text-base bg-surface-800 rounded-sm py-4 px-2 w-20 text-center">
            {followMembeers?.missionCompleted}
          </p>
        </div>
        <div>
          <p className="text-base bg-surface-900 rounded-sm py-4 px-2 w-20 text-center">
            {followMembeers?.paidUsers}
          </p>
        </div>
      </StyledGridRow>
      <StyledGridRow>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center w-full">
          <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface py-4 px-2 h-56">
            {followMembeers?.migratedSocialLabs}
          </p>
          <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
            Migración social labs
          </p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center w-full">
          <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-600 py-4 px-2 h-56">
            {followMembeers?.successRate}
          </p>
          <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
            % de éxito
          </p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center w-full">
          <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-700 py-4 px-2 h-56">
            {followMembeers?.profiledRate}
          </p>
          <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
            % del Perfilamiento
          </p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center w-full">
          <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-800 py-4 px-2 h-56">
            {followMembeers?.inactiveUsers}
          </p>
          <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
            Usuarios inactivos en rango de fecha
          </p>
        </div>
        <div className="flex w-full">
          <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-900 py-4 px-2">
            {followMembeers?.averagePayment}
          </p>
          <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
            Pago Prom. usuario
          </p>
        </div>
      </StyledGridRow>
      <StyledGridRow>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-700 py-4 px-2 h-56">
            {followMembeers?.waitingUsers}
          </p>
          <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
            Usuarios No Aceptados
          </p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center"></div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
          <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-700 py-4 px-2 h-56">
            {followMembeers?.waitingUsers}
          </p>
          <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
            Usuarios en Lista de Espera
          </p>
        </div>
        <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center"></div>
        <div className="flex">
          <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-900 py-4 px-2">
            {followMembeers?.totalPayment}
          </p>
          <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
            Pago Total USD
          </p>
        </div>
      </StyledGridRow>
    </StyledGridDash>
  );
};

export default DocumentFollowUsers;
