import React from 'react';

const TableImage = (props) => {
  return (
    <>
      <div>{props.item.name}</div>
      <div>{props.item.badge}</div>
      <div>{props.item.tier}</div>
    </>
  )
}

export default TableImage;