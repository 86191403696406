import React from 'react';

const TableCharacter = (props) => {
  return (
    <>
      <div>{props.item.name}</div>
      <div>{props.item.category}</div>
      <div>{props.item.badge}</div>
      <div>{props.item.tier}</div>
    </>
  )
}

export default TableCharacter;