import axios from "axios";

const useAuthProvider = () => {
  const loginProvider = ({ email, password }) => {
    return axios({
      method: "post",
      url: `auth/login`,
      data: {
        email,
        password,
      },
    });
  };

  const forgotPasswordProvider = ({ email }) => {
    return axios({
      method: "post",
      url: `auth/email-forgot-password`,
      data: { email },
    });
  };

  const recoveryPasswordProvider = ({ email, password, token }) => {
    return axios({
      method: "post",
      url: `auth/reset-password`,
      data: { email, password, token },
    });
  };
  const recoveryPasswordProviderApp = ({ email, password, tokenApp }) => {
    return axios({
      method: "post",
      url: `v1/auth/reset-password`,
      data: { email, password, token: tokenApp },
    });
  };

  const validLoginProvider = () => {
    return axios({
      method: "get",
      url: `user`,
    });
  };

  const logoutProvider = ({ token }) => {
    return axios({
      method: "post",
      url: `auth/logout`,
      data: {
        token,
      },
    });
  };

  return {
    loginProvider,
    forgotPasswordProvider,
    recoveryPasswordProvider,
    validLoginProvider,
    logoutProvider,
    recoveryPasswordProviderApp,
  };
};

export default useAuthProvider;
