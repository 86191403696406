// Packages
import React from "react";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from "prop-types";

// Styles
import { StyledFormControl, StyledHelperText } from "./DatePickerField.styles";
import { StyledTextField } from "../TextField/TextField.styles";

// Components
import FormLabel from "../FormLabel";

const DatePickerField = (props) => {
  const { label, tooltipTitle, error, disabled, minDate, maxDate, ...rest } = props;

  return (
    <StyledFormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat="dd/MM/yyyy"
          minDate={ minDate ? new Date(minDate) : new Date('01/01/1900') }
          maxDate={ maxDate ? new Date(maxDate) : new Date('01/01/2100') }
          mask="__/__/____"
          disabled={ disabled }
          label={<FormLabel data-shrink="dd" label={label} tooltipTitle={tooltipTitle} />}
          renderInput={(params) => <StyledTextField {...params} variant="standard" error={error} />}
          {...rest}
        />
      </LocalizationProvider>
      {error && <StyledHelperText>{error?.message}</StyledHelperText>}
    </StyledFormControl>
  );
};

DatePickerField.propTypes = {
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  error: PropTypes.object,
};

export default DatePickerField;
