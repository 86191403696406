// Packages
import React from 'react';

// Components
import useComponents from '..';

// Controllers
import useControllers from '../../../controllers';

// Styles
import { StyledMultipleChoiceAddButton, StyledMultipleChoiceItemLabel, StyledMultipleChoiceItem } from './Questions.styles'

// Assets
import useIcons from '../../../assets/icons';

const MatrixResponse = (props) => {
  const { control } = props;
  const { TextFieldControlled, Typography, CheckboxControlled } = useComponents();
  const { IconDelete, IconPlus } = useIcons();

  // Controllers
  const { useComponentHooks } = useControllers();
  const { useQuestions } = useComponentHooks();
  const { useMatrixResponse } = useQuestions();
  const {
    addOptionRowHandled,
    removeOptionRowHandled,
    matrixRowsFields,
    matrixColumnsFields,
    addOptionColumnHandled,
    removeOptionColumnHandled,
  } = useMatrixResponse(control);

  return (
    <div>
      <Typography variant="h4">
        RESPUESTA NIVEL 1 (OPCÍON INDEPENDIENTE)
      </Typography>
      <div className="mt-4">
        {matrixRowsFields?.map((item, index) => {
          return (
            <StyledMultipleChoiceItem
              gridColums={["1fr", "44px"]}
              gap={{
                row: "0",
                column: "32px"
              }}
              key={item.id}
            >
              <div>
                <StyledMultipleChoiceItemLabel>
                  <span>Opción { index + 1}</span>
                  {matrixRowsFields.length > 1 &&
                    <button type="button" onClick={() => removeOptionRowHandled(index)}> 
                      <IconDelete />
                    </button>
                  }
                </StyledMultipleChoiceItemLabel>
                <TextFieldControlled
                  placeholder=""
                  control={ control }
                  name={`rows[${index}].name`}
                  id={`rows[${index}].name`}
                />
              </div>
              <StyledMultipleChoiceAddButton>
                <button onClick={() => addOptionRowHandled(index+1, item.id)} type="button">
                  <IconPlus />
                </button>
              </StyledMultipleChoiceAddButton>
            </StyledMultipleChoiceItem>
          )
        })}
      </div>
      <Typography variant="h4">
        RESPUESTA NIVEL 1 (OPCÍON INDEPENDIENTE)
      </Typography>
      <div className="mt-4">
        {matrixColumnsFields?.map((item, index) => {
          return (
            <StyledMultipleChoiceItem
              gridColums={["1fr", "171px", "96px", "44px"]}
              gap={{
                row: "0",
                column: "32px"
              }}
              key={item.id}
            >
              <div>
                <StyledMultipleChoiceItemLabel>
                  <span>Opción { String.fromCharCode(65 + (index))}</span>
                  {matrixColumnsFields.length > 1 &&
                    <button type="button" onClick={() => removeOptionColumnHandled(index)}> 
                      <IconDelete />
                    </button>
                  }
                </StyledMultipleChoiceItemLabel>
                <TextFieldControlled
                  placeholder=""
                  control={ control }
                  name={`columns[${index}].name`}
                  id={`columns[${index}].name`}
                />
              </div>
              <TextFieldControlled
                placeholder=""
                label="Ponderación"
                control={ control }
                name={`columns[${index}].value`}
                id={`columns[${index}].value`}
              />
              <div className="flex self-end">
                <CheckboxControlled
                  control={control}
                  name={`columns[${index}].is_incorrect`}
                  id={`columns[${index}].is_incorrect`}
                  label="Incorrecta"
                />
              </div>
              <StyledMultipleChoiceAddButton>
                <button onClick={() => addOptionColumnHandled(index+1, item.id)} type="button">
                  <IconPlus />
                </button>
              </StyledMultipleChoiceAddButton>
            </StyledMultipleChoiceItem>
          )
        })}
      </div>
    </div>
  )
}

export default MatrixResponse;