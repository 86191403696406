import useScreenHooks from "./screenHooks";
import useComponentHooks from "./componentHooks"
import useGeneralHooks from "./generalHooks"

const useControllers = () => {
  return {
    useComponentHooks,
    useScreenHooks,
    useGeneralHooks,
  };
};

export default useControllers;
