// Packages
import { useCallback, useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useLocation } from "react-router-dom";

// Helpers
import useHelpers from "../../../../../helpers";

// Actions
import useActions from "../../../../../api/actions";

const useCreateQuestions = () => {
  const { id_section, id_survey, id_question } = useParams();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [draftRef, setDraftRef] = useState(null);
  const [typeSelection, setTypeSelection] = useState(null);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [listPrevRanking, setListPrevRanking] = useState([]);

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast, useGeneral } = useQuickFunctions();
  const { questionValidationShema } = useValidators();
  const { navigateTo } = useNavigation();
  const { success, error } = useToast();
  const { formatDate } = useGeneral();

  // Actions
  const { useToolsActions, dispatch } = useActions();
  const {
    actAddQuestion,
    actRemoveQuestion,
    actGetQuestion,
    actUpdateQuestion,
    actGetSurveySection,
    actOrderSurveySection,
    actCloneQuestion,
  } = useToolsActions();

  const [dropDrapQuestionList, setDropDrapQuestionList] = useState([]);
  const [isActiveMissionFlag, setIsActiveMissionFlag] = useState([]);
  const [orderDefault, setOrderDefault] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [editId, setEditId] = useState("");
  const [questionPreview, setQuestionPreview] = useState({});

  const [sectionInfo, setSectionInfo] = useState({
    sectionName: "",
    surveyName: "",
    missionName: "",
    id: "",
  });

  const { control, handleSubmit, watch, reset, setValue, setError } = useForm({
    mode: "all",
    resolver: yupResolver(questionValidationShema),
    defaultValues: {
      required: true,
      in_random_order: false,
    },
  });

  const questionType = watch("question_type");

  // Validate if saved questions are shown
  const showQuestionsList = useMemo(() => {
    return location.pathname.includes("preguntas");
  }, [location]);

  // Get Question list
  useEffect(() => {
    const onSuccess = ({ id, title, survey_name, mission_name, questions, country_name, isActiveMissionFlag }) => {
      setIsActiveMissionFlag(isActiveMissionFlag)
      const questionList = questions?.reduce(
        (previousValue, currentValue) => [
          ...previousValue,
          {
            title: currentValue.title,
            html: currentValue.html,
            files: currentValue.files,
            order: currentValue.order,
            questionType: currentValue.question_type,
            question_type_es: currentValue.question_type_es,
            id: currentValue.id.toString(),
            question: currentValue.question,
            required: currentValue.required,
            file_width: currentValue.file_width,
            file_height: currentValue.file_height,
          },
        ],
        []
      );

      questionList.sort(function (a, b) {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });

      setDropDrapQuestionList(questionList);

      setSectionInfo({
        sectionName: title,
        surveyName: survey_name,
        missionName: mission_name,
        countryName: country_name,
        id: id,
      });
    };
    const onError = (error) => {
      console.log(error);
    };

    dispatch(actGetSurveySection(id_section, onSuccess, onError));
  }, [id_section, refresh]);

  watch((data, { name, type }) => {
    if (name === "question_type" && type === "change") {
      if (data.question_type === "Checkbox" || data.question_type === "Radio") {
        reset({
          question_type: data.question_type,
          locked_ratio: null,
          file_height: null,
          file_width: null,
          in_random_order: false,
          options: [{ name: "", is_incorrect: false, id: 1 }],
        });
      } else if (data.question_type === "Matrix") {
        reset({
          question_type: data.question_type,
          locked_ratio: null,
          file_height: null,
          file_width: null,
          rows: [{ name: "", id: 1 }],
          columns: [{ name: "", value: "", is_incorrect: false, id: 1 }],
        });
      } else if (data.question_type === "Ranking") {
        reset({
          question_type: data.question_type,
          options: [{ name: "", is_incorrect: false, id: 1 }],
        });
      } else {
        reset({
          question_type: data.question_type,
          locked_ratio: null,
          file_height: null,
          file_width: null,
        });
      }
      setValue("title", "");
      setEditId("");
    }
  });

  const resetField = () => {
    setValue("file", null);
  };

  // Submit save form question
  const onSubmit = useCallback(
    (data) => {
      const { textResponse, init_date, rows, file, end_date, inclusive_date } = data;
      const onSuccess = ({
        title,
        question_type,
        id,
        order,
        question_type_es,
        question,
        files,
        file_width,
        file_height,
        html,
      }) => {
        if (showQuestionsList) {
          setDropDrapQuestionList((prevState) => {
            return [
              ...prevState,
              {
                title: title,
                html: html,
                files: files,
                order: order,
                question: question,
                questionType: question_type,
                question_type_es: question_type_es,
                file_width: file_width,
                file_height: file_height,
                id: id.toString(),
              },
            ];
          });
          reset({
            question_type: "",
          });
          setOrderDefault(null);
          setTypeSelection(null);
          success("Se creo la pregunta");
        } else {
          navigateTo(`/herramientas/encuesta/${id_survey}/secciones/preguntas`);
        }
      };

      const onError = () => {
        // error("Error al crear la pregunta")
        //IS ALREADY SHOWING THE REQUEST RESPONSE ERROR (IN ENGLISH)
      };

      const onSuccessEdit = (data) => {
        if (showQuestionsList) {
          reset({
            question_type: "",
          });
          success(`La pregunta ${editId} fue editada`);
          setRefresh(!refresh);
          setOrderDefault(null);
          setTypeSelection(null);
        } else {
          navigateTo(`/herramientas/encuesta/${id_survey}/secciones/preguntas`);
        }
      };

      const onErrorEdit = () => {
        error(`No se puede editar la pregunta ${editId}`);
      };

      const editor = draftRef?.getEditor();
      const unprivilegedEditor = draftRef?.makeUnprivilegedEditor(editor);

      const formData = new FormData();
      formData.append("question_type", data.question_type);
      formData.append("title", unprivilegedEditor?.getText());
      formData.append("html", data.title);
      formData.append("required", data.required);
      formData.append("survey_section_id", Number(id_section));
      formData.append("order", orderDefault !== null ? orderDefault : dropDrapQuestionList.length);
      if (textResponse) {
        formData.append("short", textResponse === "short");
        formData.append("long", textResponse === "long");
      }

      if (data.question_type === "Date") formData.append("inclusive_date", inclusive_date);
      if (data.question_type === "Binary") formData.append("is_incorrect", data.is_incorrect);

      if (init_date && inclusive_date) {
        formData.append("init_date", formatDate(new Date(init_date), "Y-mm-dd HH:MM:SS", false));
      }

      if (data.input_type) {
        formData.append("input_type", data.input_type);
      }

      if (end_date && inclusive_date) {
        formData.append("end_date", formatDate(new Date(end_date), "Y-mm-dd HH:MM:SS", false));
      }

      if (rows) {
        rows.map((option, index) => {
          formData.append(`rows[${index}]`, option.name);
        });
        data.columns.map((option, index) => {
          formData.append(`columns[${index}][name]`, option.name);
          formData.append(`columns[${index}][value]`, option.value);
          formData.append(`columns[${index}][is_incorrect]`, option.is_incorrect ? 1 : 0);
        });
      }
      if (data.question_type === "Range") {
        formData.append("label_end", data.label_end);
        formData.append("label_start", data.label_start);
        formData.append("value_end", data.value_end);
        formData.append("value_start", data.value_start);
        formData.append("input_type", typeSelection);
      }

      if (data.question_type === "File") {
        formData.append("file_types", data.file_types);
        formData.append("max_files", data.max_files);
        formData.append("max_size_file", data.max_file_size);
      }

      if (data.file && data.file.filter((v) => typeof v === "string").length === 0) {
        data.file.map((file, index) => {
          formData.append(`files[${index}]`, file);
        });
      }
      formData.append(`file_width`, data.file_width ?? 0);
      formData.append(`file_height`, data.file_height ?? 0);
      formData.append(`locked_ratio`, data.file_block ? 1 : 0);

      if (data.question_type === "Mask") {
        formData.append("type_mask", data.type_mask);
        if (data.type_mask !== "file") {
          formData.append(data.type_mask, data[data.type_mask]);
        }
      }

      if (data.question_type === "Checkbox" || data.question_type === "Radio" || data.question_type === "Ranking") {
        data.options.map((option, index) => {
          if(data.question_type === "Checkbox" || data.question_type === "Radio"){
            formData.append(`options[${index}][is_incorrect]`, option?.is_incorrect ? "1" : 0);
          }
          formData.append(`options[${index}][name]`, option?.name);

          if (option?.file && typeof option?.file !== "string") {
            formData.append(`options[${index}][file]`, option.file[0]);
          }
        });
      }

      if (data.question_type === "Checkbox" || data.question_type === "Radio") {
        formData.append("in_random_order", data.in_random_order ? "1" : "0");
        formData.append("add_other_field", data.add_other_field ? "1" : "0");
        formData.append("other_is_incorrect_field", data.other_is_incorrect_field ? "1" : "0");
      }

      if (data.question_type === "AboutFile" && file) {
        if (file[0].size / 1024 / 1024 > 300) {
          setError("file", { type: "custom", message: "El archivo no puede superar los 300mb" });
        } else {
          if (file && typeof file !== "string") {
            formData.append("file", file[0]);
          }
          if (editId) {
            dispatch(
              actUpdateQuestion(
                {
                  id: editId,
                  data: formData,
                },
                onSuccessEdit,
                onErrorEdit
              )
            );
          } else {
            dispatch(actAddQuestion(formData, onSuccess, onError));
          }
        }
      } else if (data.question_type !== "AboutFile") {
        if (editId) {
          dispatch(
            actUpdateQuestion(
              {
                id: editId,
                data: formData,
              },
              onSuccessEdit,
              onErrorEdit
            )
          );
        } else {
          dispatch(actAddQuestion(formData, onSuccess, onError));
        }
      } else {
        setError("file", { type: "custom", message: "Campo requerido" });
      }
    },
    [
      dropDrapQuestionList,
      editId,
      id_section,
      showQuestionsList,
      id_survey,
      orderDefault,
      draftRef,
      typeSelection,
    ]
  );

  // DragDrop
  const handleOnDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      const items = Array.from(dropDrapQuestionList);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const itempsUpdateOrder = items.map((item, index) => {
        return { ...item, order: index };
      });

      setDropDrapQuestionList(itempsUpdateOrder);
    },
    [dropDrapQuestionList]
  );

  const handlePreview = (id) => {
    setQuestionPreview(dropDrapQuestionList.find((v) => v.id === id));
    setListPrevRanking([])
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  // Remove question
  const handleRemoveQuestion = useCallback(
    (id) => {
      const onSuccess = () => {
        const items = Array.from(dropDrapQuestionList);
        items.splice(
          items.findIndex((el) => el.id === id),
          1
        );

        const itempsUpdateOrder = items.map((item, index) => {
          return { ...item, order: index };
        });

        reset({
          question_type: "",
          required: false,
          title: "",
        });

        setDropDrapQuestionList(itempsUpdateOrder);
        success("La pregunta fue eliminada exitosamente");
      };

      const onError = (e) => {
        error("No se pudo eliminar la pregunta");
      };

      dispatch(actRemoveQuestion(id, onSuccess, onError));
    },
    [dropDrapQuestionList]
  );

  const handleGetQuestion = useCallback((id) => {
    const onSuccess = (data) => {
      setEditId(id);
      const { question, html, required, question_type, order, files, file_width, file_height, locked_ratio } =
        data;
      const { rows, long, short, columns, options, init_date, end_date, inclusive_date } = question;

      if (question_type === "Range") {
        setTypeSelection(question.input_type);
      }

      setOrderDefault(order);

      // Matrix
      const rowsReduce = rows?.reduce(
        (previousValue, currentValue, currentIndex) => [
          ...previousValue,
          { name: currentValue, id: currentIndex },
        ],
        []
      );

      // Option
      const optionsReduce = options
        ?.filter((v) => !v.is_other_field)
        .reduce(
          (previousValue, currentValue, currentIndex) => [
            ...previousValue,
            {
              name: currentValue.name,
              id: currentIndex,
              is_incorrect: currentValue.is_incorrect === "1",
              file: currentValue.file,
            },
          ],
          []
        );

      // Set default value
      reset({
        question_type: question_type,
        required: required,
        title: html ?? "<p></p>",
        file_width: file_width,
        file_height: file_height,
        file_block: !!parseInt(locked_ratio),
        ...question,
        ...(typeof question.is_incorrect === "boolean" && { is_incorrect: question.is_incorrect ? "1" : "0"}),
        ...(question.input_type && { input_type: question.input_type }),
        ...(init_date && { init_date: new Date(init_date?.replace("-", "/")) }),
        ...(end_date && { end_date: new Date(end_date?.replace("-", "/")) }),
        ...(options && { options: optionsReduce }),
        ...(rows && { rows: rowsReduce }),
        ...(columns && { columns: columns.map(v => { return {...v, is_incorrect: v.is_incorrect === "1"}}) }),
        ...(long && { textResponse: "long" }),
        ...(short && { textResponse: "short" }),
        ...(typeof inclusive_date === "boolean" && { inclusive_date: inclusive_date }),
        ...(files && { file: files }),
        ...(typeof inclusive_date === "boolean" && { inclusive_date: inclusive_date }),
      });
    };

    const onError = (error) => {
      console.log(error);
    };
    dispatch(actGetQuestion(id, onSuccess, onError));
  }, []);

  const hanldeSelectPrevRanking = (element) => {
    const stringExists = listPrevRanking.includes(element);

    if (stringExists) {
      const updatedList = listPrevRanking.filter(item => item !== element);
      setListPrevRanking(updatedList);
    } else {
      const updatedList = [...listPrevRanking, element];
      setListPrevRanking(updatedList);
    }
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();

    if (draggedIndex !== null) {
      const updatedList = [...listPrevRanking];
      const draggedItem = updatedList.splice(draggedIndex, 1)[0];
      updatedList.splice(index, 0, draggedItem);
      setListPrevRanking(updatedList);
      setDraggedIndex(index);
    }
  };

  // Load question by path id
  useEffect(() => {
    if (id_question) {
      handleGetQuestion(id_question);
    }
  }, [id_question]);

  const handleFinalize = useCallback(() => {
    const onSuccess = () => {
      navigateTo(`/herramientas/encuesta/${id_survey}/secciones/preguntas`);
    };
    const onError = (error) => {
      console.log(error);
    };

    const orderQuestios = dropDrapQuestionList.reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        { question_id: currentValue.id, order: currentValue.order },
      ],
      []
    );

    dispatch(
      actOrderSurveySection(
        {
          survey_section_id: id_section,
          questions: orderQuestios,
        },
        onSuccess,
        onError
      )
    );
  }, [dropDrapQuestionList, id_section]);

  const handleCloneQuestion = (id) => {
    const onSuccess = ({ title, question_type, id, order, question_type_es, question, html }) => {
      if (showQuestionsList) {
        setDropDrapQuestionList((prevState) => {
          return [
            ...prevState,
            {
              title: title,
              order: order,
              question: question,
              questionType: question_type,
              question_type_es: question_type_es,
              html: html,
              id: id.toString(),
            },
          ];
        });
        reset({
          question_type: "",
        });
        setOrderDefault(null);
        success("Se creo la pregunta");
      } else {
        navigateTo(`/herramientas/encuesta/${id_survey}/secciones/preguntas`);
      }
    };
    dispatch(actCloneQuestion(id, onSuccess));
  };

  return {
    control,
    questionType,
    handleSubmit,
    onSubmit,
    isActiveMissionFlag,
    dropDrapQuestionList,
    handleOnDragEnd,
    handleRemoveQuestion,
    handleGetQuestion,
    handleFinalize,
    sectionInfo,
    showQuestionsList,
    handlePreview,
    questionPreview,
    showModal,
    handleClose,
    handleCloneQuestion,
    watch,
    resetField,
    setDraftRef,
    setTypeSelection,
    typeSelection,
    listPrevRanking,
    hanldeSelectPrevRanking,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    draggedIndex,
  };
};

export default useCreateQuestions;
