// Packages
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Components
import useComponents  from '../../../views/components'

// Helpers
import useHelpers from "../../../helpers";

// Api
import useApi from "../../../api";

// Constants
import { PATHS_WITH_COMPRESSED_NAVBAR } from "../../../config/constants/toolsAI";

const useNavBar = () => {
  const navigation = useNavigate();
  const [statusNavBar, setStatusNavBar] = useState(true);
  const [elementActive, setElementActive] = useState("");
  const [navBarItems, setNavBarItems] = useState([]);

  const { useActions } = useApi();
  const { useNavBarActions } = useActions();
  const { actGetNavBarOptions } = useNavBarActions();
  const { useQuickFunctions } = useHelpers();
  const { useToast } = useQuickFunctions();
  const { error } = useToast();

  const { Toast } = useComponents();

  useEffect(() => {
    const onSuccess = (data) => {
      setNavBarItems(data.data);
    }
    const onError = () => {
      error(<Toast text={"Error al cargar los items del menu"} />, {
        toastId: 0,
      });
    }
    const fetchNavBarItems = actGetNavBarOptions(onSuccess, onError);

    fetchNavBarItems();
  }, []);


  useEffect(() => {
    const currentPath = window.location.pathname;
    if (PATHS_WITH_COMPRESSED_NAVBAR.includes(currentPath)) {
      setStatusNavBar(false);
    }
  }, [window.location.pathname]);


  const handledButton = useCallback(() => {
    setStatusNavBar(!statusNavBar);
  }, [statusNavBar]);

  const handleClickElement = useCallback(
    (itemName) => {
      if (itemName === elementActive) {
        setElementActive(null);
        localStorage.setItem('activeElement', null);
      } else {
        setElementActive(itemName);
        localStorage.setItem('activeElement', itemName);
      }
    },
    [elementActive]
  );

  const handleNavigationToSubItems = ({ url, optionSuboption }) => {
    if (optionSuboption?.length) {
      navigation("/sub-items", { state: { optionSuboption } });
    } else {
      url.startsWith("http")
        ? window.open(url, '_blank')
        : navigation(url);
    }
  };
  

  const handleNavigateTo = (item) => {
    if (!item.optionSuboption.length) {
      item.url.includes("http") 
        ? window.open(item.url, '_blank') 
        : navigation(item.url);
      
      localStorage.setItem('activeElement', item.name);
    }
  };
  

  useEffect(() => {
    const activeElement = localStorage.getItem('activeElement');
    if (activeElement) {
      setElementActive(activeElement);
    }
  }, []);

  return {
    statusNavBar,
    handledButton,
    navBarItems,
    elementActive,
    handleClickElement,
    handleNavigationToSubItems,
    handleNavigateTo,
  };
};

export default useNavBar;
