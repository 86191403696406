import axios from "axios";

const useMembeersProvider = () => {
  const listMembeersProvider = ({page, params}) => {        
    return axios ({
      method: "get",
      url: `getUserInformationMembeer?page=${page}`,
      params: {...params}
    })
  }
  const listMembeersWaitingProvider = ({page, params}) => {        
    return axios ({
      method: "get",
      url: `getMembeersWaitingList?page=${page}`,
      params: {...params}
    })
  }

  const changeStateMembeersProvider = ({id, state}) => {
    return axios({
      method: "post",
      url: `changeMemberState`,
      params: {membeer_id: id, state:state}
    });
  };
  
  const inactiveMembeersProvider = (data) => {
    return axios({
      method: "post",
      url: `membeers/inactive`,
      data: data
    });
  };

  const acceptMembeersProvider = (data) => {
    return axios({
      method: "post",
      url: `membeer/accept`,
      data: data
    });
  };

  return {
    listMembeersProvider,
    changeStateMembeersProvider,
    inactiveMembeersProvider,
    listMembeersWaitingProvider,
    acceptMembeersProvider,
  }
}

export default useMembeersProvider;