// Packages
import { useCallback } from "react";
import { useFieldArray, useWatch } from "react-hook-form";

const useMultipleChoiceResponse = (control) => {
  const { fields, insert, remove, update, replace } = useFieldArray({
    control,
    name: "options",
  });

  const optionsWatch = useWatch({
    control,
    name: "options",
  });

  const addOptionHandled = useCallback((position, id) => {
    insert(position, {
      id: id,
      name: "",
      is_incorrect: false,
      file: null,
    });
  }, []);

  const removeOptionHandled = useCallback((index) => {
    remove(index);
  }, []);

  const radioButtonHandled = useCallback(
    (indexHandled) => {
      const items = Array.from(optionsWatch);
      items.map((item, index) => {
        if (index === indexHandled) {
          return update(index, { ...item, is_incorrect: true });
        } else {
          return update(index, { ...item, is_incorrect: false });
        }
      });
    },
    [optionsWatch]
  );

  const fileChangeHandled = (e, indexHandled) => {
    update(indexHandled, { ...optionsWatch[indexHandled], file: e[0].preview });
  };

  const handlechangeMulti = (value) => {
    const listOptions = value.split(/\r?\n/);
    replace(
      listOptions.map((option) => {
        return {
          name: option,
          is_incorrect: false,
          file: null,
        };
      })
    );
  };

  const nameHandle = useCallback(
    (value, indexHandled) => {
      update(indexHandled, { ...optionsWatch[indexHandled], name: value });
    },
    [optionsWatch]
  );

  return {
    addOptionHandled,
    removeOptionHandled,
    fields,
    radioButtonHandled,
    fileChangeHandled,
    nameHandle,
    handlechangeMulti,
    optionsWatch,
  };
};

export default useMultipleChoiceResponse;
