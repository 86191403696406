import { toast } from "react-toastify"

// Assets
import IconCheck from "../../../assets/icons/iconCheck"

const useToast = () => {
    const success = (msg, options = {}) => {
        return toast.success(msg, {
            // Merge additionals options
            ...options,
            position: "bottom-right",
            className: "bg-success",
            icon: <IconCheck color="white"/>
        })
    }

    const error = (msg, options = {}) => {
        return toast.error(msg, {
            ...options,
            position: "bottom-right",
            className: "bg-danger text-white",
        })
    }
    const info = (msg, options = {}) =>{
        return toast.info(msg, {
            ...options,
            position: "bottom-right",
            className: "bg-black",
        })
    }

    const warn = (msg, options = {}) => {
        return toast.warn(msg, {
            ...options,
            position: "bottom-right",
            className: "bg-warning",
        })
    }

    return {
        success,
        error,
        info,
        warn,
    }
};
export default useToast;
