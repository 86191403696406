// Packages
import { useState, useCallback } from 'react';

const useQuestionList = () => {
  const [showQuestionInfo, setShowQuestionInfo] = useState(false);

  const handleShowQuestion = useCallback(() => {
    setShowQuestionInfo(prevState => !prevState)
  }, [])

  return {
    handleShowQuestion,
    showQuestionInfo,
  }
}

export default useQuestionList;