import useApi from "../../index";

const useQuestionsActions = () => {
  const { useServices } = useApi();
  const { useQuestionsServices } = useServices();
  const {
    ListQuestionsService,
    deleteQuestionService,
    getQuestionService,
    createQuestionService,
    cloneQuestionService,
    updateQuestionService,
  } = useQuestionsServices();

  const actListQuestions = (params, onSuccess, onError) => async () => {
    try {
      const response = await ListQuestionsService(params);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actDeleteQuestion = (id, onSuccess, onError) => async () => {
    try {
      const response = await deleteQuestionService(id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actGetQuestion = (id, onSuccess, onError) => async () => {
    try {
      const response = await getQuestionService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  };

  const actUpdateQuestion =
    ({ id, data, headers }, onSuccess, onError) =>
    async () => {
      try {
        const response = await updateQuestionService(id, data, headers);
        onSuccess && onSuccess(response.data.data);
      } catch (error) {
        console.error(error);
        onError && onError();
      }
    };

  const actCreateQuestion =
    ({ data, headers }, onSuccess, onError) =>
    async () => {
      try {
        const response = await createQuestionService({ data, headers });
        onSuccess && onSuccess(response.data.data);
      } catch (error) {
        console.error(error);
        onError && onError();
      }
    };

  const actCloneQuestion = (id, onSuccess, onError) => async () => {
    try {
      const data = await cloneQuestionService(id);
      onSuccess && data && onSuccess();
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  };

  return {
    actListQuestions,
    actDeleteQuestion,
    actGetQuestion,
    actCreateQuestion,
    actCloneQuestion,
    actUpdateQuestion,
  };
};

export default useQuestionsActions;
