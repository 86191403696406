import React from "react";
import PropTypes from "prop-types";
import { StyledBadge } from "./Badge.styles";

const DefaultBadge = ({
  label,
  type,
  variant,
  disabled,
  className,
  colorLevel,
  onClick,
}) => {
  return (
    <StyledBadge
      className={className}
      disabled={disabled}
      variant={variant}
      type={type}
      colorLevel={colorLevel}
      onClick={onClick}
    >
      {label}
    </StyledBadge>
  );
};

DefaultBadge.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "neutral",
    "danger",
    "warning",
    "success",
    "none",
    "info",
  ]),
  type: PropTypes.oneOf(["light", "solid"]),
  colorLevel: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default DefaultBadge;
