import axios from "axios";

const useProjectsProvider = () => {
  const createProjectProvider = (data) => {
    return axios({
      method: "post",
      url: `projects`,
      data,
    });
  };
  const editProjectProvider = (id, data) => {
    return axios({
      method: "put",
      url: `projects/${id}`,
      data,
    });
  };

  const listProjectsProvider = ({
    page,
    init_date,
    end_date,
    status,
    country_id,
    client_id,
    search,
  }) => {
    return axios({
      method: "get",
      url: `projects?page=${page}`,
      params: { status, init_date, end_date, country_id, client_id, search },
    });
  };

  const listProjectsMissionProvider = ({
    page,
    init_date,
    end_date,
    status,
    country_id,
    client_id,
    search,
  }) => {
    return axios({
      method: "get",
      url: `project/mission?page=${page}`,
      params: { status, init_date, end_date, country_id, client_id, search },
    });
  };

  const getProjectProvider = (id) => {
    return axios({
      method: "get",
      url: `projects/${id}`,
    });
  };

  const deleteProjectProvider = (id) => {
    return axios({
      method: "delete",
      url: `projects/${id}`,
    });
  };

  return {
    createProjectProvider,
    listProjectsProvider,
    getProjectProvider,
    editProjectProvider,
    listProjectsMissionProvider,
    deleteProjectProvider
  };
};

export default useProjectsProvider;
