import React from 'react';

const TableMission = (props) => {
  return (
    <>
      <div>{props.item.badge}</div>
      <div>{props.item.tier}</div>
      <div>{props.item.missions_min}</div>
      <div>{props.item.missions_max}</div>
    </>
  )
}

export default TableMission;