import { lazy } from 'react';

/* const SpinnerLoading = lazy(() => import("./SpinnerLoading")) */
import SpinnerLoading  from './SpinnerLoading';

const useSpinners = () => {
  return { SpinnerLoading };
};

export default useSpinners;
