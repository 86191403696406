import React from 'react';

// Components
import useComponents from "../../../../../components";

// Controllers
import useControllers from "../../../../../../controllers";

const FormProfilerNoLabels = (props) => {

  const {
    ContentWrappers,    
    useButtons,
    TextFieldControlled,
    SelectFieldControlled,
    SelectMultiFieldControlled,
    Typography
  } = useComponents();

  const { DefaultButton } = useButtons();  

  const { useScreenHooks } = useControllers();
  const { useProfiler } = useScreenHooks();
  const { useProfilerCreate } = useProfiler();

  const {    
    type,
    control,
    listCountries,
    listCities,
    listStates,
    listRegions,
    handleSubmit,
    onSubmit,
    saving,
    handleChangeCountry,
    handleChangeState
  } = useProfilerCreate();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>  
    <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>
    { type === 1 ? 
      <>
        <ContentWrappers
          gridColums={["1fr", "1fr"]}
          gap={{
            row: "0px",
            column: "16px",
          }}
          className="mt-4 items-center">     
          <TextFieldControlled
              control={control}
              name="region"
              id="region"
              label={"Región"}/>
          <SelectFieldControlled
            onChangeField={(e) => handleChangeCountry(e)}
            label="País"
            name="country_id"
            id="country_id"
            control={control}
            options={listCountries}/>
        </ContentWrappers>
        <ContentWrappers
        gridColums={["1fr", "1fr"]}
        gap={{
          row: "0px",
          column: "16px",
        }}
        className="mt-4 items-center">    
          <SelectMultiFieldControlled
            label="Estado / Departamento"
            name="state_id"
            id="state_id"
            // onChangeField={(e) => handleChangeState(e)}
            autocomplete = "off"
            control={control}
            options={listStates}
            disableCloseOnSelect
            renderchecked
          />
          {/* <SelectMultiFieldControlled
            label="Ciudad / Municipio"
            name="city_id"
            id="city_id"
            control={control}
            options={listCities}
            disableCloseOnSelect
            renderchecked
          /> */}        
        <TextFieldControlled
        control={control}
        name="licenses"
        id="licenses"
        label={"Licencias disponibles"}/>
        </ContentWrappers>
      </>
      :
      <>
        <ContentWrappers
          gridColums={["1fr", "1fr"]}
          gap={{
            row: "0px",
            column: "16px",
          }}
          className="mt-4 items-center">     
          <TextFieldControlled
            control={control}
            name="abi_region"
            id="abi_region"
            label={"Nombre de la Región ABI"}/>
          {/* <SelectFieldControlled
            label="Región"
            name="region"
            id="region"
            control={control}
            options={listRegions}/>        */}          
         <SelectFieldControlled
            onChangeField={(e) => handleChangeCountry(e)}
            label="País"
            name="country_id"
            id="country_id"
            control={control}
            options={listCountries}/>          
        </ContentWrappers>
        <ContentWrappers
        gridColums={["1fr", "1fr"]}
        gap={{
          row: "0px",
          column: "16px",
        }}
        className="mt-4 items-center">    
        <SelectMultiFieldControlled
            label="Estado / Departamento"
            name="state_id"
            id="state_id"
            // onChangeField={(e) => handleChangeState(e)}
            autocomplete = "off"
            control={control}
            options={listStates}
            disableCloseOnSelect
            renderchecked
          /> 
          {/* <SelectMultiFieldControlled
            label="Ciudad / Municipio"
            name="city_id"
            id="city_id"
            control={control}
            options={listCities}
            disableCloseOnSelect
            renderchecked
          /> */}
           <TextFieldControlled
        control={control}
        name="licenses"
        id="licenses"
        label={"Licencias disponibles"}/>      
        </ContentWrappers>
        </>
    }    
    <div className="text-center mt-4">
          <DefaultButton color="secondary" type="submit" disabled={saving}>
            Finalizar y publicar
          </DefaultButton>
        </div>
    </form>)
}

export default FormProfilerNoLabels