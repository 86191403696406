// Packages
import styled from "styled-components";
import tw from "twin.macro";

// Components
import CardWrapper from '../../../../../components/CardWrapper';
import ContentWrappers from '../../../../../components/ContentWrappers';

export const StyledContainer = styled.div`
  margin: 0 2rem;
  min-height: 110vh;
`;

export const StyledWrapper = styled(ContentWrappers)`
  display: grid;
  grid-template-columns: ${(props) => props.gridColums.join(' ')};
  gap: ${(props) => `${props.gap.row} ${props.gap.column}`};
  margin: 0 auto;
  max-width: 113rem;
  min-width: 88rem;
  height: 100%;

  > div:nth-child(2) > form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const StyledCardContainerPrompt = styled.div`
display: flex;
flex-direction: column;
height: 100%;

> *:first-child {
  flex: 1;
}

> *:nth-child(2) {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
`;

export const StyledLogoContainer = styled.div`
  margin: 0.5rem 0;
`;

export const StyledHeaderPrompt = styled.p`
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const StyledDescriptionPrompt = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
`;

export const StyledSurveyBackground = styled.div`
  background-color: #f0f1f1;
  border-radius: 1rem 1rem 0 0;
  padding: 1.5rem;
  flex: 10;
`;

export const StyledWrapperSurvey = styled(ContentWrappers)`
  max-width: 55rem;
  width: 80%;
`;

export const StyledHeaderSurvey = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const StyledCardWrapperPrompt = styled(CardWrapper)`
  max-height: 42rem;
  overflow-y: auto;
`;

export const StyledQuestionsHeader = styled(ContentWrappers)`
  ${tw`pb-6 border-b border-neutral-60 border-solid mb-8`}
`;

export const StyledQuestionsHeaderItems = styled.div`
  h5 {
    ${tw`text-neutral-90`}
  }
`;

export const StyledQuestionsContent = styled.div`
  ${tw`px-8`}
  h2 {
    ${tw`mb-1`}
  }
  h3 {
    ${tw`mb-7`}

    span {
      ${tw`text-third-600`}
    }
  }
`;

export const StyledQuestionsList = styled.ul`
  ${tw`mt-7`}
`;

export const StyledSubmitButtonContainer = styled.div`
  background-color: #fff;
  border-radius: 0 0 1rem 1rem;
  flex: 1;
`;
