// Packages
import React from "react";
import PropTypes from "prop-types";

// Assets
import imageLoader from "../../../../assets/images/bg-loader.svg";
import IconLoader from "../../../../assets/images/icon-loader.png";

// Styled Components
import {
  StyledSpinner,
  StyledDescription,
  StyledCenterAnimation,
  StyledImage,
  StyledSmallBall,
  StyledContetAll,
} from "./SpinnerLoading.styles";

// Hooks
import useComponents from "../..";

const SpinnerLoader = ({
  className,
  text = "Cargando",
  isOpen = false,
  nested = false,
}) => {
  const { Typography } = useComponents();

  return (
    <StyledSpinner className={className} nested={nested} isOpen={isOpen}>
      <StyledContetAll>
        <StyledCenterAnimation>
          <StyledImage src={imageLoader} />
          <StyledSmallBall className={nested ? "nested" : ""} src={IconLoader} />
        </StyledCenterAnimation>
        <StyledDescription>
          <Typography
            className={`${nested ? "text-secondary" : "text-white font-bold"} w-full text-center mt-8`}
          >
            {text}
          </Typography>
        </StyledDescription>
      </StyledContetAll>
    </StyledSpinner>
  );
};

SpinnerLoader.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  nested: PropTypes.bool,
};

export default SpinnerLoader;
