// Packages
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

// Custom hooks
import useConfig from "./config";
import useRoutes from "./routes";
import useComponents from "./views/components";

// Styles
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  // Config
  const { useStoreConfig, useInterceptor } = useConfig();
  const { store, persistor } = useStoreConfig();
  useInterceptor(store);

  // Routers
  const { Routing } = useRoutes();

  // Components
  const { useSpinners } = useComponents();
  const { SpinnerLoading } = useSpinners();

  // Toast container properties
  const toastContainerProps = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: false,
    limit: 10,
    pauseOnHover: true,
    theme: "colored",
  };

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <ToastContainer {...toastContainerProps} />
          <Suspense fallback={<SpinnerLoading isOpen={true} text={"Cargando..."} />}>
            <Router>
              <Routing />
            </Router>
          </Suspense>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
