// Packages
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

// Hook
import useControllers from "../../..";

import useModels from "../../../../models";

const useGroupView = () => {
  // State
  const [listGroups, setListGroup] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [countrySelectFilter, setCountrySelectFilter] = useState(null);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });
  const navigation = useNavigate();

  // Actions
  const { useActions } = useApi();
  const { useGroupsActions, useGeneralActions } = useActions();
  const { actListGroups, actDeleteGroups } = useGroupsActions();
  const { actListCountries, actExportExel } = useGeneralActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { FilterUserValidationShema } = useValidators();

  // Hooks
  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();


  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(FilterUserValidationShema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    setCountrySelectFilter(data.abiFilterCountry);
    actListGroups({ page: 1, search: searchText, country_id: data.abiFilterCountry }, loadData);
  };

  const loadData = (data) => {
    setListGroup(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };
  const loadDataPagination = (data) => {
    setListGroup(data.data);
  };

  const handleCreateNewGroup = () => {
    navigation("/grupos/creacion");
  };
  const handleEditGroup = (group) => {
    navigation(`/grupos/editar/${group.id}`);
  };
  const prepareDataCountry = (data) => {
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const handlePagination = (nro) => {
    actListGroups(
      { page: nro.selected + 1, search: searchText, country_id: countrySelectFilter },
      loadDataPagination
    );
  };

  const onSubmitSearch = (e) => {
    setSearchText(e);
    actListGroups({ page: 1, search: e }, loadData);
  };
  const onChangeSearch = useCallback(debounce(onSubmitSearch, 750), []);

  const handleShowGroup = (id) => {
    navigation(`/grupos/detalle/${id}`);
  };

  const handleDownloadExel = () => {
    const filters = {
      country: countrySelectFilter || "",
      search: searchText,
    };

    const filtersProcces = prepareDataFilterDownload(filters);

      actExportExel("membeer_groups", filtersProcces, (result) => {
        createDownloadExel(result);
      });
  };

  const handleIndividualDownloadExel = (id) => {
      actExportExel(`membeerGroupsByID`, id, (result) => {
        createDownloadExel(result);
      });
  };

  useEffect(() => {
    actListGroups({ page: 1 }, loadData);
    actListCountries(prepareDataCountry);
  }, []);

  const handleDeleteGroup = (id, name) => {
    setModalDelete({ show: true, name: name, id: id });
  };

  const handleConfirmDelete = () => {
    const onSuccessDelete = () => {
      actListGroups({ page: 1 }, loadData);
    }
    actDeleteGroups(modalDelete.id, onSuccessDelete);
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handleCloseModal = () => {
    setModalDelete({ show: false, name: "", id: 0 })
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    listGroups,
    handleCreateNewGroup,
    handleEditGroup,
    listCountries,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    handleShowGroup,
    handleDownloadExel,
    handleIndividualDownloadExel,
    handleDeleteGroup,
    modalDelete,
    handleCloseModal,
    handleConfirmDelete
  };
};

export default useGroupView;
