// Packages
import React from "react";

// Components
import useComponents from "..";

//Icons
import Range from "../../../assets/icons/iconRange";
import Numbers from "../../../assets/icons/iconNumbers";
import Stars from "../../../assets/icons/iconStars";
import { useController } from "react-hook-form";

const RangeResponse = (props) => {
  const { control, typeSelection, setTypeSelection } = props;
  const { ContentWrappers, TextFieldControlled, Typography } = useComponents();

  return (
    <div>
      <ContentWrappers gridColums={["1fr", "1fr"]} gap={{ row: "", column: "32px" }}>
        <div>
          <Typography variant="h4">DESDE</Typography>
          <ContentWrappers
            className="mt-4"
            gridColums={["96px", "1fr"]}
            gap={{ row: "0", column: "16px" }}
          >
            <TextFieldControlled
              control={control}
              name="value_start"
              id="value_start"
              label="Valor"
              placeholder="0"
              type="number"
            />
            <TextFieldControlled
              control={control}
              name="label_start"
              id="label_start"
              label="Etiqueta"
              placeholder=""
            />
          </ContentWrappers>
        </div>
        <div>
          <Typography variant="h4">HASTA</Typography>
          <ContentWrappers
            className="mt-4"
            gridColums={["96px", "1fr"]}
            gap={{ row: "0", column: "16px" }}
          >
            <TextFieldControlled
              control={control}
              name="value_end"
              id="value_end"
              label="Valor"
              placeholder="0"
              type="number"
            />
            <TextFieldControlled
              control={control}
              name="label_end"
              id="label_end"
              label="Etiqueta"
              placeholder=""
            />
          </ContentWrappers>
        </div>
      </ContentWrappers>
      {setTypeSelection && (
        <div>
          <Typography variant="h4">TIPO DE SELECCIÓN</Typography>
          <div className="flex justify-between mt-2 mb-10">
            <div
              onClick={() => setTypeSelection("range")}
              className={`flex flex-col cursor-pointer rounded-lg ${
                typeSelection === "range" && "border-2 border-primary"
              }`}
            >
              <Range />
              <p className="mx-auto">Slider</p>
            </div>
            <div
              onClick={() => setTypeSelection("stars")}
              className={`flex flex-col cursor-pointer rounded-lg ${
                typeSelection === "stars" && "border-2 border-primary"
              }`}
            >
              <Stars />
              <p className="mx-auto">Estrellas</p>
            </div>
            <div
              onClick={() => setTypeSelection("numbers")}
              className={`flex flex-col cursor-pointer rounded-lg ${
                typeSelection === "numbers" && "border-2 border-primary"
              }`}
            >
              <Numbers />
              <p className="mx-auto">Números</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RangeResponse;
