// Steps controllers
import usePromptsMain from './Main'

const usePrompt = () => {

  return {
    usePromptsMain,
  };
};

export default usePrompt;
