// Packages
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";

// Helpers
import useHelpers from '../../../../helpers';

// Controllers
import useControllers from '../../../'

// Models
import useModels from '../../../../models';

// Actions
import useApi from "../../../../api";

const useUsersEdit = () => {
  const [showModal, setShowModal] = useState(false)
  const [listRoles, setListRoles] = useState([])
  const [listCountries, setListCountries] = useState([]);
  const [activateUser, setActivateUser] = useState({label: "Inactivo", value: false})

  const navigation = useNavigate();

  // Controllers
  const {useGeneralHooks} = useControllers()
  const {formatDate} = useGeneralHooks()

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { editUserValidationShema } = useValidators();

  // Models
  const { useSelectors } = useModels();
  const { useSelector, useUsersSelectors } = useSelectors();
  const { userSelected } = useUsersSelectors();

  const userData = useSelector(userSelected)

  // Actions
  const { useActions } = useApi();
  const { useUsersActions, useGeneralActions } = useActions();
  const { actEditUsers, actListRoles } = useUsersActions();
  const { actListCountries } = useGeneralActions();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(editUserValidationShema),
    defaultValues: {
      country_id: userData.country.id,
      name: userData.name,
      last_name: userData.last_name,
      email: userData.email,
      birth_date: userData.birth_date,
      abi_team: userData.abi_team,
      rol: userData.rol[0].id,
    }
  });

  const handleCloseModal = () => {
    setShowModal(false)
    navigation(-1)
  }

  const handleChangeActivate = () => {
    setActivateUser({label: !activateUser.value ? "Activo" : "Inactivo", value: !activateUser.value})
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const loadDatarol = data => {
    setListRoles(data.map(v => {return {label: v.name, value: v.id}}))
  }
  const prepareDataCountry = (data) => {
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };


  const onSubmit = (data) => {
    actEditUsers(userData.id, {...data, active: activateUser.value, birth_date: formatDate(data.birth_date, "Y-mm-dd", false)}, handleShowModal);
  };

  useEffect(() => {
    setActivateUser({label: userData.active ? "Activo" : "Inactivo", value: userData.active})
  }, [userData])

  useEffect(() => {
    actListRoles(loadDatarol)
    actListCountries(prepareDataCountry);
  }, [])

  return {
    control,
    handleSubmit,
    onSubmit,
    showModal,
    handleCloseModal,
    userData,
    errors,
    activateUser,
    handleChangeActivate,
    listRoles,
    listCountries,
  };
};

export default useUsersEdit;
