// Packages
import styled from "styled-components";
import tw from "twin.macro";

import Radio from "@mui/material/Radio";
import FormControlLabel from '@mui/material/FormControlLabel';

// Label styles
export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    ${tw`text-sm text-neutral font-light`}
    letter-spacing: -0.006em;
  }
`;

// Focus and hover styles
export const StyledRadio = styled(Radio)`
  .MuiTouchRipple-root {
    display: none;
  }

  &:hover {
    background-color: transparent!important;

    .StyledRadioIcon {
      ${(props) => {
        switch (props.variantColor) {
          case "error":
            return `
              border: 1px solid #FF2D55;
            `
            break;
          case "success":
            return `
              border: 1px solid #018145;
            `
            break;
          default:
            return `
              border: 1px solid #FFA700;
            `
            break;
        }
      }}
    }
  }
  &.Mui-focusVisible {
    background: none;

    .StyledRadioIcon {      
      ${(props) => {
        switch (props.variantColor) {
          case "error":
            return `
              border: 1px solid #FF2D55;
              outline: 4px solid #f5d4dd;
            `
            break;
          case "success":
            return `
              border: 1px solid #018145;
              outline: 4px solid #cce6da;
            `
            break;
          default:
            return `
              border: 1px solid #FFA700;
              outline: 4px solid #fdedcc;
            `
            break;
        }
      }}
    }
  }
`
// Styles when radio is not checked
export const StyledRadioIcon = styled.span.attrs({
  className: "StyledRadioIcon"
})`
  ${tw`rounded-full relative`}
  ${(props) => {
    switch (props.variantColor) {
      case "error":
        return `
          border: 1px solid #FF2D55;
        `
        break;
      case "success":
        return `
          border: 1px solid #018145;
        `
        break;
      default:
        return `
          border: 1px solid #B6BBC1;
        `
        break;
    }
  }}
  height: 24px;
  width: 24px;
`

// Styles when radio is active
export const StyledCheckedIcon = styled(StyledRadioIcon)`
  &:before {
    ${tw`absolute block rounded-full`}
    content: "";
    height: 12px;
    left: 5px;
    top: 5px;
    width: 12px;
    ${(props) => {
      switch (props.variantColor) {
        case "error":
          return `
            background-color: #FF2D55;
          `
          break;
        case "success":
          return `
            background-color: #018145;
          `
          break;
        default:
          return `
            background-color:#FFA700;
          `
          break;
      }
    }}
  }
`