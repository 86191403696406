// Packages
import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

// Styles
import { StyledLabel } from "./FormLabel.styles";

// Assets
import useIcons from "../../../assets/icons";

const FormLabel = ({
  label = "",
  tooltipTitle = null,
  className = "",
  onClick = () => {}
}) => {
  const { IconTooltipForm } = useIcons();

  return (
    <div className="flex items-center">
      <div onClick={onClick} className="w-full">
        <StyledLabel className={className}>{label}</StyledLabel>
      </div>
      {tooltipTitle && (
        <Tooltip title={tooltipTitle} arrow>
          <IconButton>
            <IconTooltipForm color="#E5B611" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

FormLabel.propTypes = {
  label: PropTypes.any,
  tooltipTitle: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default FormLabel;
