import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledPublicContent,
  StyledInnerContent,
} from './publicContent.styles';

const PublicContentLayout = ({ children, background, login, }) => {
  return (
    <StyledPublicContent background={background} login={login}>
      <StyledInnerContent>{children}</StyledInnerContent>
    </StyledPublicContent>
  );
};

PublicContentLayout.propTypes = {
  children: PropTypes.any,
  background: PropTypes.string,
  login: PropTypes.bool,
};

PublicContentLayout.defaultProps = {
  background: 'primary',
  login: false,
};

export default PublicContentLayout;
