import useAuthProvider from "./auth";
import useToolsProvider from "./tools";
import useQuestionsProvider from "./questions";
import useUsersProvider from "./users";
import useGeneralProvider from "./general";
import useMissionProvider from "./mission";
import useProjectsProvider from "./projects";
import useCountriesProviders from "./countries";
import useMapProviders from "./map";
import useBrandsProviders from "./brands";
import useNewsProviders from "./news";
import useNotificationsProvider from "./notifications";
import usePaymentsProvider from "./payments";
import useFaqsProvider from "./faqs";
import useGroupMembeerProvider from "./groups";
import useProfilerProvider from "./profiler";
import useDashboardProvider from "./dashboard";
import useMembeersProvider from "./membeers";
import usePowerBiProvider from "./powerBi";
import useGamificationProvider from "./gamification";
import useLegalProvider from "./legal";
import usePromptsProvider from "./prompts";
import useNavBarProvider from "./navbar";

const useProviders = () => {
  return {
    useAuthProvider,
    useToolsProvider,
    useUsersProvider,
    useGeneralProvider,
    useQuestionsProvider,
    useMissionProvider,
    useProjectsProvider,
    useCountriesProviders,
    useMapProviders,
    useBrandsProviders,
    useNewsProviders,
    useNotificationsProvider,
    usePaymentsProvider,
    useFaqsProvider,
    useGroupMembeerProvider,
    useProfilerProvider,
    useDashboardProvider,
    useMembeersProvider,
    usePowerBiProvider,
    useGamificationProvider,
    useLegalProvider,
    usePromptsProvider,
    useNavBarProvider,
  };
};

export default useProviders;
