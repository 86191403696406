import useServices from "../../services";

const useCountriesAction = () => {
  const { useCountruesServices } = useServices();
  const {
    listCountriesService,
    listCountriesFreeService,
    listCountriesFreeFaqsService,
    listLanguagesService,    
    createCountrieService,
    getCountrieService,
    editCountrieService,
    getExistingCurrencyService,
    getGeneralCountrieService,
  } = useCountruesServices();

  const actListCountries = async (
    { page, search, iso, languaje, currency_name, currency_symbol },
    onSuccess,
    onError
  ) => {
    try {
      const response = await listCountriesService({
        page,
        search,
        iso,
        languaje,
        currency_name,
        currency_symbol,
      });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListCountriesFree = async (onSuccess, onError) => {
    try {
      const response = await listCountriesFreeService();
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListCountriesFreeFaqs = async (id, onSuccess, onError) => {    
    try {
      const response = await listCountriesFreeFaqsService(id);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListLanguages = async (onSuccess, onError) => {
    try {
      const response = await listLanguagesService();
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actCreateCountrie = (data, onSuccess, onError) => async () => {
    try {
      const response = await createCountrieService(data);

      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actGetCountrie = (countrieId, onSuccess, onError) => async () => {
    try {
      const response = await getCountrieService(countrieId);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actGetGeneralCountrie = (countrieId, onSuccess, onError) => async () => {
    try {
      const response = await getGeneralCountrieService(countrieId);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actEditCountrie = (countrieId, data, onSuccess, onError) => async () => {
    try {
      const response = await editCountrieService(countrieId, data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actgetExistingCurrency = async (onSuccess, onError) => {
    try {
      const response = await getExistingCurrencyService();
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  return {
    actListCountries,
    actListCountriesFree,
    actListCountriesFreeFaqs,
    actListLanguages,
    actCreateCountrie,
    actGetCountrie,
    actEditCountrie,
    actgetExistingCurrency,
    actGetGeneralCountrie,
  };
};
export default useCountriesAction;
