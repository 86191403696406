// Packages
import React from "react";
import PropTypes from "prop-types";

// Styles
import {
  StyledFormControl,
  StyledAutocomplete,
  StyledHelperText,
} from "./AutocompleteMultiField.styles";
import { StyledTextField } from "../TextField/TextField.styles";


// Components
import FormLabel from "../FormLabel";

const AutocompleteMultiField = (props) => {
  const {
    label,
    tooltipTitle,
    options,
    selectedValue,
    error,
    handleInputChange,
    disabled,
    ...rest
  } = props;

  return (
    <StyledFormControl fullWidth>
      <StyledAutocomplete
        multiple={true}
        options={options}
        value={selectedValue || null}
        disabled={disabled}
        getOptionLabel={(option) => option.label}
        filterOptions={(x) => x}
        filterSelectedOptions
        renderInput={(params) => (
          <StyledTextField
            onChange={handleInputChange}
            label={<FormLabel label={label} tooltipTitle={tooltipTitle} error={error} />}
            {...params}
            variant="standard"
          />
        )}
        {...rest}
      />
      {error && <StyledHelperText>{error?.message}</StyledHelperText>}
    </StyledFormControl>
  );
};

AutocompleteMultiField.propTypes = {
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  error: PropTypes.any,
  selectedValue: PropTypes.array,
};

AutocompleteMultiField.defaultProps = {
  options: [],
  selectedValue: []
};

export default AutocompleteMultiField;
