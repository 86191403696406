// Packages
import React from 'react';

// Components
import useComponents from '..';

// Controllers
import useControllers from '../../../controllers';

const DateResponse = (props) => {
  const { control } = props;

  // Components
  const { DatePickerFieldControlled, InputSwitchControlled, ContentWrappers } = useComponents();

  // Controllers
  const { useComponentHooks } = useControllers();
  const { useQuestions } = useComponentHooks();
  const { useDateResponse } = useQuestions();
  const { handleShowDate, showDates } = useDateResponse(control);

  return (
    <div>
      
      <InputSwitchControlled
        label="Restringir fechas"
        id="inclusive_date"
        name="inclusive_date"
        control={ control }
        onChange={handleShowDate}
      />
      {showDates &&
        <ContentWrappers gridColums={["1fr", "1fr"]} className="mt-8">
          <ContentWrappers gridColums={["1fr"]} contentWidth="160px" align="left">
            <DatePickerFieldControlled
              name="init_date"
              id="init_date"
              label="Fecha mínima"
              control={ control }
            />
          </ContentWrappers>
          <ContentWrappers gridColums={["1fr"]} contentWidth="160px" align="left">
            <DatePickerFieldControlled
              name="end_date"
              id="end_date"
              label="Fecha máxima"
              control={ control }
            />
          </ContentWrappers>
        </ContentWrappers>
      }
    </div>
  )
}

export default DateResponse;