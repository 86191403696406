import React from "react";
import useScreenHooks from "../../../../controllers/screenHooks";
import useComponents from "../../../components";

//Styles
import { StyledWrapperRadio } from "./Create.styles";

const Create = () => {
  const {
    ContentWrappers,
    CardWrapper,
    Typography,
    TextFieldControlled,
    SelectFieldControlled,
    SelectMultiFieldControlled,
    DatePickerFieldControlled,
    TimePickerFieldControlled,
    FormLabel,
    InputFileControlled,
    TextAreaFieldControlled,
    RadioButtonControlled,
    useModals,
    useButtons,
  } = useComponents();

  const { DefaultButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useNewsScreens } = useScreenHooks();
  const { newsCreate, newsRoot } = useNewsScreens();
  const {
    control,
    showModal,
    saving,
    handleCloseModal,
    handleSubmit,
    onSubmit,
    getValues,
    nameNew,
    textSucces,
  } = newsCreate();
  const { listCountries } = newsRoot();

  //console.log(getValues());

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-11/12 mt-4" as="section">
        <CardWrapper>
          <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>
          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <TextFieldControlled
              label="Nombre de la noticia"
              placeholder="Nombre de la noticia"
              name="name"
              id="abi-name"
              control={control}
            />

            <ContentWrappers
              gridColums={["48%", "48%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <TextFieldControlled
                label="Autor de la noticia"
                placeholder="Autor de la noticia"
                name="author"
                id="author"
                control={control}
              />
              <SelectMultiFieldControlled
                label="País"
                name="country_id"
                id="abi-country_id"
                control={control}
                options={listCountries}
              />
            </ContentWrappers>

            <ContentWrappers
              gridColums={["48%", "48%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
              className="border-b border-neutral-60 pb-4 mb-8"
            >
              {/* <div>
                <FormLabel label="Fecha de publicación" />

                <ContentWrappers
                  gridColums={["140px", "100px", "81px"]}
                  gap={{
                    row: "0px",
                    column: "16px",
                  }}
                  className="items-end"
                >
                  <DatePickerFieldControlled
                    minDate={`${new Date()}`}
                    name={`date`}
                    id={`abi-date`}
                    control={control}
                  />

                  <TimePickerFieldControlled name={`time`} id={`abi-time`} control={control} />

                  <SelectFieldControlled
                    name={`ampm`}
                    id={`abi-ampm`}
                    control={control}
                    defaultValue={"am"}
                    options={[
                      {
                        value: "am",
                        label: "a.m",
                      },
                      {
                        value: "pm",
                        label: "p.m",
                      },
                    ]}
                  />
                </ContentWrappers>
              </div> */}
              <div>
                <FormLabel label="Orden del banner home" />
                <ContentWrappers
                  gridColums={["126px", "25px", "76px"]}
                  gap={{
                    row: "0px",
                    column: "16px",
                  }}
                  className="items-center"
                >
                  <TextFieldControlled name="index" id="abi-index" control={control} />
                  <Typography className="pb-4" variant="body1">
                    de
                  </Typography>
                  <TextFieldControlled name="total" id="total" control={control} disabled />
                </ContentWrappers>
              </div>
            </ContentWrappers>

            <Typography variant="h3" className="pb-6">
              2. AGREGAR CONTENIDO
            </Typography>
            <ContentWrappers
              gridColums={["100%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <InputFileControlled
                name="image_intern_url"
                id="abi-image_intern_url"
                control={control}
                label="Agregar imagen interna (jpg, png, webp,gif)"
                accept="image/jpeg,image/png,image/webp,image/gif"
                placeholder="Cargar archivo"
                maxFiles={1}
                directPreview
                showPreview
              />
            </ContentWrappers>
            <ContentWrappers
              gridColums={["100%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <InputFileControlled
                name="image_home_url"
                id="abi-image_home_url"
                control={control}
                label="Agregar imagen home (jpg, png, webp, gif)"
                accept="image/jpeg,image/png,image/webp,image/gif"
                placeholder="Cargar archivo"
                maxFiles={1}
                directPreview
                showPreview
              />
            </ContentWrappers>

            <ContentWrappers
              gridColums={["100%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
              className="border-b border-neutral-60 pb-4 mb-8"
            >
              <TextAreaFieldControlled
                id="abi-text"
                control={control}
                label="Mensaje"
                placeholder="Escribe un texto aquí"
                name="text"
              />
            </ContentWrappers>

            <Typography variant="h3" className="pb-6 uppercase">
              3. Activación o inactivación de noticia
            </Typography>

            <ContentWrappers
              gridColums={["50px", "60px"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <StyledWrapperRadio>
                <RadioButtonControlled
                  id="abi-status"
                  name="status"
                  control={control}
                  radioOptions={[
                    { label: "Activar", value: "act" },
                    { label: "Desactivar", value: "ina" },
                  ]}
                />
              </StyledWrapperRadio>
            </ContentWrappers>
            <div className="flex items-center justify-center mt-4">
              <DefaultButton color="secondary" type="submit" disabled={saving}>
                Finalizar y publicar
              </DefaultButton>
            </div>
          </form>
        </CardWrapper>
      </ContentWrappers>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            La noticia {nameNew} se ha {textSucces} con éxito.
          </Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </>
  );
};

export default Create;
