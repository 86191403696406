import React from 'react';

const TablePerfilacionAgeGenderNSE = (props) => {

  const renderColumns = () => {        
    switch (props.type) {
      case 4: return <div>{props.item.age_range}</div>        
      case 5: return <div>{props.item.gender}</div>                
      case 6: return <>
        <div>{props.item.nse}</div>
        <div>{props.item.min} - {props.item.max}</div>
      </>
    }
  }
  
  return (
    <>    
      {renderColumns()}          
      <div>{props.item.abi_region}</div>
      {/* <div>{props.item.region}</div> */}
      <div>{props.item.country_name}</div>
      <div>{props.item.licenses}</div>
    </>
  )
}

export default TablePerfilacionAgeGenderNSE;