// Packages
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";

// Components
import useComponents from "../../../../views/components";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";
import useModels from "../../../../models";

import useControllers from "../../..";

const useTaskView = () => {
  // State
  const [listCountries, setListCountries] = useState([]);
  const [listProject, setListProject] = useState([]);
  const [listTasks, setListTasks] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });
  const [selectFilter, setSelectFilter] = useState({
    project_id: null,
    init_date: null,
    end_date: null,
    status: null,
  });
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [arriveToCreateProject, setArriveToCreateProject] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();

  // Actions
  const { useActions } = useApi();
  const { useGeneralActions, useMissionActions, useProjectsActions } = useActions();
  const { actListCountries, actExportExel } = useGeneralActions();
  const { actListMissions, actDeleteMission, actCloneMission } = useMissionActions();
  const { actListProjects } = useProjectsActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast, useGeneral } = useQuickFunctions();
  const { FilterTaskValidationShema } = useValidators();
  const { success } = useToast();
  const { formatDate } = useGeneral();

  // Components
  const { Toast } = useComponents();

  //Selectors
  const { useSelectors } = useModels();
  const { useSelector, useProjectsSelectors } = useSelectors();
  const { projectSelected } = useProjectsSelectors();
  const project = useSelector(projectSelected);

  const { control, handleSubmit, resetField, watch } = useForm({
    mode: "all",
    resolver: yupResolver(FilterTaskValidationShema),
    defaultValues: {
      range_date: null,
    },
  });

  const onClickClear = () => resetField("range_date");

  const onSubmit = (data, e) => {
    e.preventDefault();

    setSelectFilter({
      ...(!!data.range_date && { init_date: formatDate(data.range_date[0], "Y-mm-dd", false) }),
      ...(!!data.range_date &&
        !!data.range_date[1] && {
          end_date: formatDate(data.range_date[1], "Y-mm-dd", false),
        }),
      ...(data.abiFilterProject && { project_id: data.abiFilterProject }),
      ...(data.abiFilterStatus && { status: data.abiFilterStatus }),
      country_id: data.abiCountryId?.map((v) => v.value),
    });
    actListMissions(
      {
        page: 1,
        ...(searchText && { search: searchText }),
        ...(!!data.range_date && { init_date: formatDate(data.range_date[0], "Y-mm-dd", false) }),
        ...(!!data.range_date &&
          !!data.range_date[1] && {
            end_date: formatDate(data.range_date[1], "Y-mm-dd", false),
          }),
        ...(data.abiFilterProject && { project_id: data.abiFilterProject }),
        ...(data.abiFilterStatus && { status: data.abiFilterStatus }),
        country_id: data.abiCountryId?.map((v) => v.value),
      },
      loadData
    );
  };

  const translateStatus = {
    in_course: "En curso",
    finished: "Finalizada",
    no_tools: "Sin herramientas",
  };

  const loadData = (data) => {
    setListTasks(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };
  const loadDataPagination = (data) => {
    setListTasks(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };

  const onSubmitSearch = (e) => {
    if (e.length >= 3) {
      setSearchText(e);
      actListMissions({ page: 1, search: e }, loadData);
    }
    else {
      setSearchText(null);
    }
  };

  const onChangeSearch = useCallback(debounce(onSubmitSearch, 750), []);

  const handleCreateNewTask = () => {
    arriveToCreateProject
      ? navigation("/misiones/creacion", { from: "create_project" })
      : navigation("/misiones/creacion");
  };

  const handleShowTask = (id) => {
    navigation(`/misiones/detalle/${id}`);
  };

  const handleEditTask = (id) => {
    navigation(`/misiones/editar/${id}`);
  };

  const onSubmitSuccess = () => {
    success(<Toast text={"¡Misión eliminada con éxito!"} />, {
      toastId: 0,
    });
    actListMissions({ page: paginationSettings.current, ...selectFilter }, loadData);
  };

  const handleDeleteTask = (id, name) => {
    setModalDelete({ show: true, name: name, id: id });
  };

  const handleConfirmDelete = () => {
    actDeleteMission(modalDelete.id, onSubmitSuccess);
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handleCloneTask = (id) => {
    //navigation("/misiones/clonacion", { idClone: id });
    if (location.state?.from === "create_project") {
      actCloneMission({ id: id, project_id: project.id }, onSubmitClonetaskFromProject);
    } else {
      actCloneMission({ id: id }, onSubmitSuccessClone);
    }
  };

  const prepareDataCountry = (data) => {
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };
  const prepareDataProject = (data) => {
    setListProject(
      data.data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const onSubmitClonetaskFromProject = () => {
    success(<Toast text={"¡Misión duplicada con éxito!"} />, {
      toastId: 0,
    });
    actListMissions({ page: paginationSettings.current, ...selectFilter }, loadData);
  };

  const onSubmitSuccessClone = () => {
    success(<Toast text={"¡Misión duplicada con éxito!"} />, {
      toastId: 0,
    });
    actListMissions({ page: paginationSettings.current, ...selectFilter }, loadData);
  };

  const handleCloseModal = () => {
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const handlePagination = (nro) => {
    const { project_id, init_date, end_date, status, tools } = selectFilter;
    actListMissions(
      {
        page: nro.selected + 1,
        ...(searchText && { search: searchText }),
        ...(project_id && { project_id }),
        ...(init_date && { init_date }),
        ...(end_date && { end_date }),
        ...(status && { status }),
        ...(tools && { tools }),
      },
      loadDataPagination
    );
  };

  const handleEditProject = (project) => {
    navigation(`/proyectos/crear/${project.id}`);
  };

  const handleDownloadExcel = () => {
    let data = {
      ...selectFilter
    };
    const filtersProcces = prepareDataFilterDownload(data);
    actExportExel(`missions`, filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  useEffect(() => {
    actListCountries(prepareDataCountry);
    actListProjects({ page: 1 }, prepareDataProject);
    actListMissions({ page: 1 }, loadData);
  }, []);

  useEffect(() => {
    setArriveToCreateProject(location.state?.from === "create_project");
  }, [setArriveToCreateProject, location]);

  return {
    control,
    handleSubmit,
    onSubmit,
    listTasks,
    onChangeSearch,
    listCountries,
    handleCreateNewTask,
    handleCloneTask,
    translateStatus,
    handleShowTask,
    handleDeleteTask,
    handleCloseModal,
    modalDelete,
    handleConfirmDelete,
    handlePagination,
    paginationSettings,
    handleEditTask,
    arriveToCreateProject,
    project,
    handleEditProject,
    listProject,
    onClickClear,
    handleDownloadExcel,
    navigation,
  };
};

export default useTaskView;
