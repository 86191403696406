import useStrings from "../../../strings";
import useServices from "../../services";

const useProjectsActions = () => {
  const { useProjectsServices } = useServices();
  const {
    listProjectsService,
    createProjectsService,
    getProjectsService,
    editProjectsService,
    listProjectsMissionService,
    deleteProjectService,
  } = useProjectsServices();

  const { useTypes } = useStrings();
  const { useProjectTypes } = useTypes();
  const { CREATE } = useProjectTypes();

  const actSeletedProject = (projectId, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await getProjectsService(projectId);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListProjects = async (
    { page, init_date, end_date, status, country_id, client_id, search },
    onSuccess,
    onError
  ) => {
    try {
      const response = await listProjectsService({
        page,
        init_date,
        end_date,
        status,
        country_id,
        client_id,
        search,
      });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListProjectsMission = async (
    { page, init_date, end_date, status, country_id, client_id, search },
    onSuccess,
    onError
  ) => {
    try {
      const response = await listProjectsMissionService({
        page,
        init_date,
        end_date,
        status,
        country_id,
        client_id,
        search,
      });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actCreateProject = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await createProjectsService(data);

      dispatch({
        type: CREATE,
        payload: response.data.data,
      });

      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actEditProject = (id, data, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await editProjectsService(id, data);
      dispatch({
        type: CREATE,
        payload: response.data.data,
      });
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actDeleteProject = async (id, onSuccess, onError) => {
    try {
      const response = await deleteProjectService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actAddProject = (data) => (dispatch) => {
    dispatch({
      type: CREATE,
      payload: data,
    });
  };

  return {
    actListProjects,
    actCreateProject,
    actSeletedProject,
    actAddProject,
    actEditProject,
    actListProjectsMission,
    actDeleteProject,
  };
};

export default useProjectsActions;
