// Packages
import React from "react";

// Styles
import { StyledSearch, StyledContentButtons } from "./NotificationView.styles";
import { StyledFormFilter } from "../../Tasks/Views/TaskView.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

const NotificationView = () => {

  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    useModals,
    TextFieldSeach,
    SelectFieldControlled,
    Pagination,
    Typography,
    Badge,
    Dropdown,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { useNotification } = useScreenHooks();
  const { formatDate } = useGeneralHooks();
  const { useNotificationView } = useNotification();
  const {
    control,
    handleSubmit,
    onSubmit,
    listNotifications,
    handleCreateNewNotification,
    handleEditNotification,
    handleCloneNotification,
    handleCloseModal,
    handleConfirmDelete,
    handleDeleteNotification,
    modalDelete,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    handleDownloadExcel,
  } = useNotificationView();

  return (
    <div className="bg-white px-8 pt-10 pb-12 mx-12 rounded-lg">
      <ContentWrappers
        gridColums={["1fr 210px"]}
        className="mb-8 items-center border-b border-neutral-60 pb-8"
      >
        <Typography variant="h3">Listado de notificaciones</Typography>
        <DefaultButton color="secondary" onClick={handleCreateNewNotification}>
          Crear Notificación
        </DefaultButton>
      </ContentWrappers>
      <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
        <TextFieldSeach
          placeholder="Buscar"
          name="search"
          id="search"
          control={control}
          onInputChange={onChangeSearch}
        />
        <StyledContentButtons>
          <FilterButton handleSubmit={handleSubmit(onSubmit)}>
            <StyledFormFilter>
              {/* <SelectFieldControlled
                placeholder={"Grupo"}
                label="Grupo"
                name={`group_id`}
                id={`abi-filter-group`}
                control={control}
                options={listCountries}
              /> */}
              {/* <div className="flex items-center">
                <DatePickerRangeControlled
                  label="Rango de fecha"
                  name="range_date"
                  id="range_date"
                  control={control}
                />
              </div> */}
              <SelectFieldControlled
                label={"Estado"}
                name={`status`}
                id={`abi-filter-status`}
                control={control}
                options={[
                  { value: "0", label: "Enviado" },
                  { value: "1", label: "Activo" },
                ]}
              />
              <SelectFieldControlled
                label={"Tipo"}
                name={`type`}
                id={`abi-filter-type`}
                control={control}
                options={[
                  { value: "Push", label: "Push" },
                  { value: "Email", label: "Email" },
                ]}
              />
            </StyledFormFilter>
          </FilterButton>
          <DefaultButton color="third" onClick={handleDownloadExcel}>
            Exportar a Excel
          </DefaultButton>
        </StyledContentButtons>
      </ContentWrappers>
      <TableContent
        headerCellList={[
          "Id",
          "Nombre de notificación",
          "Nombre de grupo",
          "Mensaje",
          "Fecha de creación",
          "Fecha de envío",
          "Estado",
          "Tipo de notificación",
          "Acciones",
        ]}
        gridCell={["0.5fr", "1.2fr", "1fr", "1.25fr", "1fr", "0.75fr", "0.5fr", "1fr", "66px"]}
      >
        {listNotifications.length > 0 ? (
          listNotifications.map((list, index) => {
            return (
              <TableRow key={index}>
                <div>{list?.id}</div>
                <div>
                  <b>{list?.name}</b>
                </div>
                <div>{list.membeer_group_name} </div>
                <div className="truncate">{list.push_message} </div>
                <div> {formatDate(list?.send_on, "dd-mm-Y")} </div>
                <div> {formatDate(list?.send_on, "dd-mm-Y")} </div>
                <div>
                  <Badge
                    disabled={list?.active === false}
                    variant={list?.active === false ? "success" : "info"}
                    label={list?.active === false ? "Enviado" : "Activo"}
                  />
                </div>
                <div>
                  {list.should_send_push && list.should_send_email
                    ? "Push, Email"
                    : list.should_send_push
                    ? "Push"
                    : list.should_send_email
                    ? "Email"
                    : ""}
                </div>
                <div className="flex justify-end">
                  <Dropdown
                    options={[
                      { id: "delete", action: () => handleDeleteNotification(list.id, list.name), show: list?.active === true },
                      { id: "edit", action: () => handleEditNotification(list), show: list?.active === true },
                      { id: "clone", action: () => handleCloneNotification(list.id) },
                    ]}
                  />
                </div>
              </TableRow>
            );
          })
        ) : (
          <div>Sin datos para mostrar</div>
        )}
      </TableContent>
      <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      <DefaultModal isOpen={modalDelete.show}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            Esta seguro de eliminar la notificación <b>{modalDelete.name}</b>.
          </Typography>
          <div className="flex items-center space-x-4">
            <DefaultButton color="secondary" onClick={handleCloseModal}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="success" onClick={handleConfirmDelete}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default NotificationView;
