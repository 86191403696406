import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useMembeersServices = () => {
  const { useMembeersProvider } = useProviders();
  const {
    listMembeersProvider,
    changeStateMembeersProvider,
    inactiveMembeersProvider,
    deleteMembeerProvider,
    listMembeersWaitingProvider,
    acceptMembeersProvider,
  } = useMembeersProvider();

  const listMembeersService = ({ page, params }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listMembeersProvider({ page, params })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listMembeersWaitingService = ({ page, params }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listMembeersWaitingProvider({ page, params })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const changeStateMembeersService = ({ id, state }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(changeStateMembeersProvider({ id, state })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const inactiveMembeersService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(inactiveMembeersProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const deleteMembeersService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteMembeerProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const acceptMembeersService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(acceptMembeersProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listMembeersService,
    changeStateMembeersService,
    inactiveMembeersService,
    deleteMembeersService,
    listMembeersWaitingService,
    acceptMembeersService,
  };
};

export default useMembeersServices;
