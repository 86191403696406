import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useProfilerServices = () => {
  const { useProfilerProvider } = useProviders();
  const { listProfilersProvider, getProfilerProvider, addProfilerProvider, editProfilerProvider } = useProfilerProvider();

  const listProfilersService = (url, { page }) => {    
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(listProfilersProvider(url, { page })));
      }
      catch(error) {
        reject(error);
      }
    })
  }

  const getProfilersService = (id, url) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getProfilerProvider(id, url)));      
      } catch(error) {
        reject(error);
      }
    })
  }

  const addProfilersService = (data, url) => {
    return new Promise( async (resolve, reject) => {
      try {
        resolve(await trackPromise(addProfilerProvider(data, url)));
      } catch (error) {
        reject(error);
      }
    })
  }

  const editProfilerService = ({id, data, url}) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editProfilerProvider({id, data, url})));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listProfilersService,
    addProfilersService,
    getProfilersService,
    editProfilerService
  }
}

export default useProfilerServices;