// Packages
import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

import Checkbox from "../Checkbox";

import { StyledHelperText, StyledFormControl } from "./CheckBoxControlled.styles.js"

const CheckboxControlled = (props) => {
  const { id, label, disabled, name, control, color, inputChange, justifyContent } = props;

  const {
    field: { onChange, value, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
  });

  const handleChange = e => {
    inputChange && inputChange(e)
    return onChange(e);
  }

  return (
    <StyledFormControl fullWidth justifyContent={justifyContent}>
      <Checkbox
        id={id}
        label={label}
        value={value}
        inputRef={ ref }
        onChange={ handleChange }
        disabled={ disabled }
        checked={ value }
        color={ color }
        defaultValue={false}
      />
      {error && <StyledHelperText>{error?.message}</StyledHelperText>}
    </StyledFormControl>
  );
};

CheckboxControlled.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["primary", "error", "success"]),
};

export default CheckboxControlled;
