import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

// Input
import TextField from "../TextField";

const TextFieldControlled = ({
  control,
  name,
  id,
  label,
  type = "text",
  helperText,
  onInputChange = () => {},
  inputValue = "",
  disabled = false,
  tooltipTitle,
  placeholder,
  required,
}) => {

  const {
    field: { onChange, value, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules: {
      required: true,
    },
    defaultValue: inputValue,
  });

  const auxOnChange = (data) => {
    onChange(data);
    onInputChange && onInputChange(data.target.value);
  };

  return (
    <TextField
      id={id}
      error={error}
      label={label}
      disabled={disabled}
      type={type}
      onChange={auxOnChange}
      value={value}
      inputRef={ref}
      helperText={helperText}
      name={name}
      tooltipTitle={tooltipTitle}
      placeholder={placeholder}
      required={required}
    />
  );
};

TextFieldControlled.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  helperText: PropTypes.string,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  inputValue: PropTypes.string,
  tooltipTitle: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default TextFieldControlled;
