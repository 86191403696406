// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Styles
import { StyledQuestionsContent } from "./Create.styles.js";

// Import Swiper styles
import "swiper/swiper.min.css";
/* import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css"; */

const Create = ({}) => {
  // Components
  const {
    CardWrapper,
    ContentWrappers,
    Typography,
    useButtons,
    SelectFieldControlled,
    Questions,
    InputFileControlled,
    InputDraft,
  } = useComponents();

  const { DefaultButton } = useButtons();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useQuestions } = useScreenHooks();
  const { useCreateQuestions } = useQuestions();

  const { control, questionType, handleSubmit, onSubmit, setDraftRef } = useCreateQuestions();

  return (
    <>
      <ContentWrappers
        gridColums={["1fr"]}
        gap={{
          row: "0px",
          column: "20px",
        }}
        contentWidth="892px"
        as="section"
      >
        <CardWrapper padding="2rem">
          <StyledQuestionsContent>
            <Typography variant="h2">PREGUNTAS</Typography>

            <Typography variant="h4" bulledTitle={true}>
              NUEVA PREGUNTA
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <ContentWrappers
                className="mt-4"
                gridColums={["1fr", "1fr"]}
                gap={{
                  row: "0px",
                  column: "32px",
                }}
              >
                <SelectFieldControlled
                  name="question_type"
                  id="question_type"
                  control={control}
                  label="Tipo de respuesta esperada"
                  tooltipTitle="Texto para respuesta"
                  options={[
                    {
                      label: "Selección múltiple",
                      value: "Checkbox",
                    },
                    {
                      label: "Selección única - Radiobuttons",
                      value: "Radio",
                    },
                  ]}
                />
              </ContentWrappers>
              {questionType && (
                <InputDraft
                  name="title"
                  id="title"
                  placeholder="Ingresa tu nueva pregunta"
                  control={control}
                  label="Texto de la pregunta"
                  inputRef={setDraftRef}
                />
              )}
              {questionType && (
                <InputFileControlled
                  id="file"
                  name="file"
                  control={control}
                  label="Cargar archivo"
                  placeholder="Selecciona un archivo"
                  showPreview
                />
              )}
              <Questions control={control} questionType={questionType} hideOptions qualityChecks />
              {questionType && (
                <div className="flex justify-end mb-4">
                  <DefaultButton type="submit">Guardar</DefaultButton>
                </div>
              )}
            </form>
          </StyledQuestionsContent>
        </CardWrapper>
      </ContentWrappers>
    </>
  );
};

export default Create;
