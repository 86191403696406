// Packages
import styled from "styled-components";
import tw from "twin.macro";
import { FormControl, Autocomplete } from "@mui/material";

export const StyledFormControl = styled(FormControl)`
  ${tw`!mb-7 `}
  && {
    .MuiInputBase-input {
      ${tw`p-8 `}
    }
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  && {
    svg {
      fill: #363635;
    }
    .MuiTextField-root {
      ${tw`mb-0`}
    }

    .MuiAutocomplete-inputRoot {
      ${tw`
      box-border
      bg-neutral-30
      border border-neutral-60 border-solid
      rounded
      px-2
      h-auto
      text-neutral font-light text-sm mt-2
    `}
      .MuiChip-root {
        ${tw`bg-third-200 text-third-800 border border-third`}
        .MuiSvgIcon-root {
          ${tw`text-third-800`}
        }
      }
    }
    .MuiInputBase-input {
      ${tw`border-0 m-0`}
    }
    .MuiInput-root {
      padding-right: 0 !important;

      .MuiInputBase-input {
        ${tw`px-4`}
        padding-bottom: 9.5px;
        padding-top: 9.5px;
      }
    }

    .MuiAutocomplete-endAdornment {
      right: 15px;
      top: 20px;
    }
  }
`;
