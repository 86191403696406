import axios from "axios";

const usePaymentsProvider = () => {
  const listPaymentsPrivider = ({
    page,
    country_id,
    member_state,
    payment_status,
    membeer_name,
    mision_name,
    init_date_approval,
    end_date_approval,
  }) => {
    return axios({
      method: "get",
      url: `paymentMembers?page=${page}`,
      params: {
        country_id,
        member_state,
        payment_status,
        membeer_name,
        mision_name,
        init_date_approval,
        end_date_approval,
      },
    });
  };

  const createPaymentsProvider = (data) => {
    return axios({
      method: "post",
      url: "mission_payments",
      data,
    });
  };

  const editPaymentsProvider = (id, data) => {
    return axios({
      method: "put",
      url: `mission_payments/${id}`,
      data,
    });
  };

  const getPaymentsProvider = (id) => {
    return axios({
      method: "get",
      url: `mission_payments/${id}`,
    });
  };

  const getMissionToPay = () => {
    return axios({
      method: "get",
      url: `missions-for-pay`,
    });
  };

  const editPaymentStatusProvider = (data) => {
    return axios({
      method: "put",
      url: "markPaymentsUnprocessable/paymentMembers",
      data,
    });
  };

  const loadFilePaymentsProvider = (data) => {
    return axios({
      method: "post",
      url: "paymentMembers",
      data,
    });
  };

  return {
    listPaymentsPrivider,
    createPaymentsProvider,
    editPaymentsProvider,
    getPaymentsProvider,
    getMissionToPay,
    editPaymentStatusProvider,
    loadFilePaymentsProvider,
  };
};
export default usePaymentsProvider;
