const IconSend = ({color = "#6D7074"}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.547871 5.77427L0.452457 5.81405C-0.15364 6.11029 -0.158978 7.00745 0.485868 7.29405L7.56098 10.4382L10.7059 17.5141C11.0069 18.1912 11.981 18.1515 12.2257 17.4521L17.9465 1.10701C17.9554 1.08334 17.9631 1.05933 17.9697 1.03509C18.053 0.73501 17.9577 0.445408 17.7667 0.24922C17.7641 0.246532 17.7615 0.243857 17.7588 0.241196C17.756 0.238388 17.7532 0.235605 17.7503 0.232846C17.5542 0.0421441 17.2648 -0.052956 16.9649 0.0302905C16.9407 0.0368851 16.9167 0.0446156 16.893 0.0534822L0.547871 5.77427ZM14.0681 2.77494L3.03521 6.63644L7.99993 8.84308L14.0681 2.77494ZM9.15689 10.0001L11.3627 14.964L15.2234 3.93362L9.15689 10.0001Z"
        fill={color}
      />
    </svg>
  );
};

export default IconSend;
