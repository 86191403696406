import { lazy } from "react"

const DefaultButton = lazy(() => import("./DefaultButton"))
const FilterButton = lazy(() => import("./FilterButton"))

const useButtons = () => {
  return {
    DefaultButton,
    FilterButton,
  }
}

export default useButtons;