const IconTools = ({color = "#B6B9BA"}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="24"
    fill="none"
    viewBox="0 0 15 24"
    >
      <path
        fill={ color }
        fillRule="evenodd"
        d="M0 9.005h14.81L13.023 23.63H1.79L0 9.005z"
        clipRule="evenodd"
        opacity="0.32"
      ></path>
      <path
        fill={ color }
        fillRule="evenodd"
        d="M5.866 8.636V3.358L4.5 0 3.13 3.358V8.636h2.736zM3.633 4.369h.192v3.777h-.192V4.37zm.77 0h.192v3.777h-.193V4.37zm.769 0h.192v3.777h-.192V4.37zm-.314-3L4.5.49l-.36.88h.718zM11.68 8.636V.677H7.84v7.959h3.84zM9.602 7.469a.185.185 0 000 .37h1.58a.185.185 0 000-.37h-1.58zm0-1.901a.185.185 0 000 .369h1.58a.185.185 0 000-.37h-1.58zm0-1.925a.185.185 0 000 .37h1.58a.185.185 0 000-.37h-1.58zm0-1.902a.185.185 0 000 .369h1.58a.185.185 0 000-.37h-1.58z"
        clipRule="evenodd"
        opacity="0.8"
      ></path>
      <path
        fill={ color }
        d="M.764 23.262h13.284V24H.764v-.738z"
        opacity="0.32"
      ></path>
    </svg>
  );
}

export default IconTools;