// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";

// Styles
import { StyledMultipleChoiceAddButton } from "./GroupCreate.styles";

const GroupCreate = () => {
  const { useScreenHooks } = useControllers();
  const { useUsersGroup } = useScreenHooks();
  const { useGroupCreate } = useUsersGroup();
  const {
    control,
    handleSubmit,
    onSubmit,
    saving,
    showModal,
    handleCloseModal,
    listCountries,
    fields,
    append,
    remove,
    onChangeCountry,
    onChangeDeparment,
    onChangeRegion,
    onChangeRegionABI,
    onChangeSearch,
    listOptionsTool,
    onChangeTool,
    listSelectTool,
    watch,
    onChangeSection,
    listQuestions,
    onChangeQuestion,
    listAnswer,
    listResumen,
    listSelectCounties,
    listAgeRanges, 
    listNSE,
    listSection,
    id_group,
    disableFields,
    groupName,
    handleGoBack,
    numberMembeers,
    onSetNumber,
    handleUploadFile,
    sendImport,
    handleDownloadExel,
  } = useGroupCreate();

  const {
    ContentWrappers,
    useButtons,
    TextFieldControlled,
    SelectFieldControlled,
    Typography,
    useModals,
    TableContent,
    TableRow,
    SelectMultiFieldControlled,
    InputFileControlled,
    CheckboxControlled,
  } = useComponents();

  const { DefaultModal } = useModals();

  const { DefaultButton } = useButtons();

  const { IconDelete, IconPlus } = useIcons();

  return (
    <div className="bg-white px-16 pt-10 pb-12 mx-24 rounded-lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>
        <ContentWrappers
          maxCol="48%"
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
          className="mt-4"
        >
          <TextFieldControlled
            type="text"
            id="abi-user-name"
            control={control}
            label="Nombre de grupo"
            placeholder="Nombre de grupo"
            name="name"
            disabled={disableFields}
          />
          <div className="flex items-center">
            <CheckboxControlled
              label="Lista de difusión"
              name="typeList"
              id="typeList"
              control={control}
              disabled={disableFields}
            />
            <CheckboxControlled
              label="Grupo Membeer"
              name="typeGroup"
              id="typeGroup"
              control={control}
              disabled={disableFields}
            />
          </div>
        </ContentWrappers>
        <ContentWrappers
          maxCol="48%"
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
          <SelectMultiFieldControlled
            label={"País"}
            placeholder={"Selecciona una opción"}
            name={`countries`}
            id={`abi-country`}
            control={control}
            disableCloseOnSelect
            renderchecked
            onChangeField={onChangeCountry}
            options={listCountries}
            disabled={!!id_group}
          />
          <SelectMultiFieldControlled
            label={"Región ABI"}
            placeholder={"Selecciona una opción"}
            name={`abi_regions`}
            id={`abi-region-abi`}
            control={control}
            onChangeField={onChangeRegionABI}
            disableCloseOnSelect
            renderchecked
            options={listSelectCounties
              ?.reduce((prev, curr) => prev.concat(curr.abi_regions), [])
              .map((v) => {
                return { label: v.abi_region, value: v.id };
              })}
            disabled={disableFields}
          />
        </ContentWrappers>
        {/* <ContentWrappers
          maxCol="48%"
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
           <SelectMultiFieldControlled
            label={"Estado / Departamento"}
            placeholder={"Selecciona una opción"}
            name={`departments`}
            id={`abi-state`}
            control={control}
            disableCloseOnSelect
            onChangeField={onChangeDeparment}
            renderchecked
            options={listSelectCounties
              ?.reduce((prev, curr) => prev.concat(curr.departments), [])
              .map((v) => {
                return { label: v.name, value: v.id };
              })}
            disabled={disableFields}
          />
          <SelectMultiFieldControlled
            label={"Región"}
            placeholder={"Selecciona una opción"}
            name={`regions`}
            id={`abi-region`}
            disableCloseOnSelect
            renderchecked
            control={control}
            onChangeField={onChangeRegion}
            options={
              watch("departments")?.length > 0
                ? listSelectCounties
                    ?.reduce((prev, curr) => prev.concat(curr.regions), [])
                    .filter((region) => {
                      if (
                        watch("departments").filter((department) => {
                          return department.value == region.state_id;
                        }).length > 0
                      ) {
                        return { label: region.name, value: region.id };
                      }
                      return;
                    })
                    .map((v) => {
                      return { label: v.name, value: v.id };
                    })
                : []
            }
            disabled={disableFields}
          />
        </ContentWrappers> */}
        <ContentWrappers
          maxCol="48%"
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
          <SelectMultiFieldControlled
            label={"NSE"}
            placeholder={"Selecciona una opción"}
            name={`nse`}
            id={`abi-nse`}
            control={control}
            options={listNSE}
            // options={listSelectCounties
            //   ?.reduce((prev, curr) => prev.concat(curr.nse_ranges), [])
            //   .map((v) => {
            //     return { label: v.nse, value: v.nse };
            //   })}
            disabled={disableFields}
            onChangeField={onSetNumber}
          />
          <SelectMultiFieldControlled
            label={"Rango de edad"}
            placeholder={"Selecciona una opción"}
            name={`ages`}
            id={`abi-range`}
            control={control}
            options={listAgeRanges}
            // options={listSelectCounties
            //   ?.reduce((prev, curr) => prev.concat(curr.ages), [])
            //   .map((v) => {
            //     return { label: v.age_range, value: v.age_range };
            //   })}
            disabled={disableFields}
            onChangeField={onSetNumber}
          />
        </ContentWrappers>
        <ContentWrappers
          maxCol="48%"
          repeatGrid
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
          <SelectMultiFieldControlled
            label={"Género"}
            placeholder={"Selecciona una opción"}
            name={`genders`}
            id={`abi-gender`}
            control={control}
            options={listSelectCounties
              ?.reduce((prev, curr) => prev.concat(curr.genders), [])
              .map((v) => {
                return { label: v.gender, value: v.gender };
              })}
            disabled={disableFields}
            onChangeField={onSetNumber}
          />
          <SelectMultiFieldControlled
            label={"Audiencias"}
            placeholder={"Selecciona una opción"}
            name={`audiences`}
            id={`abi-audiences`}
            control={control}
            options={listSelectCounties
              ?.reduce((prev, curr) => prev.concat(curr.audiences ?? ''), [])
              ?.map((v) => {
                return { label: v.name, value: v.id };
              })}
            disabled={disableFields}
            onChangeField={onSetNumber}
            disableCloseOnSelect
            renderchecked
          />
        </ContentWrappers>
        <Typography variant="h3">2. AGREGAR PARAMETROS</Typography>
        {fields?.map((item, index) => (
          <div key={item.id} className="py-4">
            <div className="flex items-center space-x-2">
              <Typography variant="h4">PARAMETRO {index + 1}</Typography>
              <div
                className={`cursor-pointer ${index === 0 ? "hidden" : undefined}`}
                onClick={() => remove(index)}
                disabled={disableFields}
              >
                <IconDelete />
              </div>
            </div>

            <ContentWrappers
              maxCol="48%"
              repeatGrid
              gap={{
                row: "0px",
                column: "4%",
              }}
              className="mt-4"
            >
              <SelectMultiFieldControlled
                label="Nombre de Herramienta"
                placeholder={"Selecciona una opción"}
                name={`parameters[${index}].tool_id`}
                id={`parameters[${index}].tool_id`}
                control={control}
                options={listOptionsTool?.list ? listOptionsTool.list[index] : []}
                onKeyUp={(data) => onChangeSearch(index, data)}
                onChangeField={(data) => onChangeTool(index, data)}
                disabled={disableFields}
              />
              <SelectFieldControlled
                label={"Sección de la herramienta"}
                placeholder={"Selecciona una opción"}
                name={`parameters[${index}].section`}
                id={`parameters[${index}].section`}
                disabled={
                  watch(`parameters[${index}].tool_id[0].type`) !== "Survey" || disableFields
                }
                control={control}
                options={listSection?.list ? listSection?.list[index] : []}
                onChangeField={(data) => onChangeSection(index, data)}
              />
            </ContentWrappers>
            <SelectFieldControlled
              label={"Pregunta"}
              placeholder={"Selecciona una opción"}
              name={`parameters[${index}].question_id`}
              id={`parameters[${index}].question_id`}
              disabled={watch(`parameters[${index}].tool_id[0].type`) !== "Survey" || disableFields}
              control={control}
              options={listQuestions?.list ? listQuestions?.list[index] : []}
              onChangeField={(data) => onChangeQuestion(index, data)}
            />
            <ContentWrappers
              gridColums={["90%", "8%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
              className="items-center"
            >
              <SelectMultiFieldControlled
                label={"Respuesta"}
                placeholder={"Selecciona una opción"}
                name={`parameters[${index}].answer`}
                id={`parameters[${index}].answer`}
                control={control}
                disabled={
                  watch(`parameters[${index}].tool_id[0].type`) !== "Survey" || disableFields
                }
                options={listAnswer?.list ? listAnswer?.list[index] : []}
                onChangeField={() => onSetNumber()}
              />
              <StyledMultipleChoiceAddButton>
                <button onClick={() => append({ tool_id: [], section: "", question_id: "", answer: [] })} type="button">
                  <IconPlus />
                </button>
              </StyledMultipleChoiceAddButton>
            </ContentWrappers>
          </div>
        ))}
        <div className="flex justify-between">
        <Typography variant="h3">3. IMPORTACION</Typography>
        <Typography variant="overline" className="cursor-pointer underline" onClick={handleDownloadExel}>Descargar plantilla</Typography>
        </div>
          <InputFileControlled
            id={'listGroup'}
            name={'listGroup'}
            label=""
            control={control}
            showPreview={false}
            inputChange={handleUploadFile}
          />
        <Typography variant="h3">4. RESUMEN</Typography>
        <TableContent gridCell={["1.2fr", "1fr"]}>
          {listResumen.map((v, i) => (
            <TableRow key={i}>
              <div>{v.label}</div>
              <div>{v.value}</div>
            </TableRow>
          ))}
        </TableContent>
        <div className="text-center mt-4">
          {disableFields ? (
            <DefaultButton color="secondary" type="button" onClick={handleGoBack}>
              Volver
            </DefaultButton>
          ) : (
            <DefaultButton color="secondary" type="submit" disabled={(numberMembeers === 0 || saving) && !sendImport}>
              {id_group ? "Editar" : "Crear"} Grupo
            </DefaultButton>
          )}
        </div>
      </form>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2">
            El grupo <b>{groupName}</b> se ha sido creado con éxito
          </Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </div>
  );
};

export default GroupCreate;
