import React from 'react';

const TableProfilerLicence = (props) => {
  return (
    <>
      <div>{props.item.country_name}</div>
      <div>{props.item.total_licenses}</div>
      <div>{props.item.in_use_licenses}</div>
      <div>{props.item.unused_licenses}</div>
    </>
  )
}

export default TableProfilerLicence;