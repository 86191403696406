import axios from "axios";

const useDashboardProvider = () => {
  const getDasboardProvider = (params) => {
    return axios({
      method: "get",
      url: `getDashboard`,
      params: params,
    });
  };
  const getInfoMembeerProvider = (params) => {
    return axios({
      method: "get",
      url: `getUserInformationMembeer/engagement`,
      params: params,
    });
  };
  return { getDasboardProvider, getInfoMembeerProvider };
};
export default useDashboardProvider;
