// Packages
import styled from "styled-components";
import tw from "twin.macro";

// Components
import Button from "@mui/material/Button";

export const StyledButton = styled(Button)`
  && {
    ${tw`normal-case px-6 py-3 text-sm`};
    ${(props) => (props.rounded === "true" ? tw`rounded-full` : tw`rounded`)}
    ${(props) => (props.fullWidth ? tw`w-full` : tw`w-auto`)}
    ${(props) => props.size === "large" && tw`px-8 py-4 text-base`}
    box-shadow: 0px 2px 4px rgba(62, 62, 61, 0.08);
    height: 44px;

    ${(props) => {
      switch (props.color) {
        case "primary":
          return tw`
            text-secondary
          `;
        case "secondary":
          return tw`
              text-primary bg-secondary
            `;
        case "third":
          return tw`
            text-third-800 bg-third
          `;
        case "success":
          return tw`
            bg-success text-white
          `;
        case "info":
          return tw`
            bg-info text-white
          `;
        case "error":
          return tw`
            bg-danger-600 text-white
          `;
        case "warning":
          return tw`
            bg-[#F58025] text-white
          `;
        case "gray":
          return tw`
            bg-gray-100 text-black
          `;
        default:
          return tw`
            text-white
          `;
      }
    }}

    ${(props) => {
      switch (props.variant) {
        case "outlined":
          return tw`
            text-primary
            border-primary
            border-2
          `;
      }
    }}
    ${(props) =>
      props.variant === "outlined" &&
      props.color === "secondary" &&
      tw`text-secondary
    border-secondary bg-transparent`}
  }

  &.MuiButton-outlined {
    &:hover {
      ${(props) => {
        switch (props.variant) {
          case "outlined":
            return tw`
            border-2
          `;
        }
      }}
    }
  }

  &&.Mui-disabled {
    ${tw`bg-neutral-30 text-neutral-70`}
  }
`;

export const StyleLoading = styled.div`
  && {
    ${tw` py-[12px]`}
  }
`;
