// Packages
import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';

// Styles
import { StyledSectionListItemMove, StyledAddButton, StyledModalPreview } from "./Sections.styles";
import 'swiper/swiper.min.css';

// Components
import useComponents from "../../../../../components";
import CreateSection from "../Create";
import CreateQuestions from "../../Questions/Create";

// Controllers
import useControllers from "../../../../../../controllers";

// Assets
import useIcons from "../../../../../../assets/icons";

const Sections = () => {
  const { IconPlus } = useIcons();

  // Components
  const {
    ContentWrappers,
    useButtons,
    TextFieldControlled,
    Steps,
    useAccordion,
    QuestionList,
    Typography,
    CheckboxControlled,
    InputFileControlled,
    useModals,
    DatePickerFieldControlled,
    TextAreaFieldControlled,
    Dropdown,
    RadioButtonControlled,
    Rangefield,
  } = useComponents();

  const { ModalCustom, DefaultModal } = useModals();
  const { DefaultButton } = useButtons();
  const { AccordionContent, AccordionHeader, AccordionBody } = useAccordion();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useSurvey } = useTools();
  const { useSections } = useSurvey();
  const {
    onSubmitQuestion,
    handledClone,
    handleRemoveSection,
    reorderQuestionsAndSave,
    questionType,
    controlQuestion,
    handleSubmitQuestion,
    handleGetQuestion,
    resetField,
    typeSelection,
    setTypeSelection,
    handleRemoveQuestion,
    setDraftRef,
    editId,
    showQuestionForm,
    handleAddQuestion,
    handleCloneQuestion,
    sections,
    questionPreview,
    handlePreview,
    handleClose,
    showModal,
    reorderSectionsAndSave,
    handleFinalize,
    handleOpenConfirmationModal,
    handleCloseConfirmationModal,
    showConfirmation,
    currentSection,
    onSubmitSection,
    editSection,
    handleEditSection,
    handleCancelEditSection,
    handleAddSection,
    controlSection,
    handleSubmitSection,
    sectionId,
    watch,
    handleDragStart,
    handleDragEnd,
    handleSelectPrevRanking,
    draggedIndex,
    handleDragOver,
    listPrevRanking,
    handleCloseQuestionForm,
    handleForwarQuestion,
    disableButton,
  } = useSections();

  const onDragEnd = (result) => {
    const { destination, source, type } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === "section") {
      reorderSectionsAndSave(result);
      return;
    } else if (type === "question") {
      reorderQuestionsAndSave(result);
      return;
    }
  };

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} as="section">
        <Steps stepList={["Secciones y preguntas", "condicionales"]} widthContent="700px" />
        {showQuestionForm ? (
          <div className="z-10 transition-opacity pt-10 flex items-start justify-center">
            <CreateQuestions
              editId={editId}
              handleSubmitQuestion={handleSubmitQuestion}
              onSubmitQuestion={onSubmitQuestion}
              controlQuestion={controlQuestion}
              resetField={resetField}
              questionType={questionType}
              setDraftRef={setDraftRef}
              setTypeSelection={setTypeSelection}
              typeSelection={typeSelection}
              handleClose={handleCloseQuestionForm}
              disableCreateButton={disableButton}
            />
          </div>
        ) : null}
        <div className="w-4/5 flex flex-col justify-center px-8 pt-10 pb-12 rounded-lg relative mx-auto">
          <DragDropContext suppressScrollOnWheel={true} onDragEnd={onDragEnd}>
            <Droppable droppableId={"sections"} type="section">
              {(providedSections) => (
                <ul
                  className="flex w-fill-available flex-col"
                  {...providedSections.droppableProps}
                  ref={providedSections.innerRef}
                >
                  {sections?.map((section, index) => (
                    <Draggable
                      isDragDisabled={false}
                      key={section?.id}
                      draggableId={section?.id.toString()}
                      index={index}
                    >
                      {(providedSection) => (
                        <li
                          className="bg-white rounded-tl-lg rounded-tr-lg w-fill-available flex ml-5 mb-2"
                          ref={providedSection.innerRef}
                          {...providedSection.draggableProps}
                        >
                          <StyledSectionListItemMove {...providedSection.dragHandleProps}>
                            <div>
                              <span></span>
                              <span></span>
                            </div>
                            <div>
                              <span></span>
                              <span></span>
                            </div>
                            <div>
                              <span></span>
                              <span></span>
                            </div>
                          </StyledSectionListItemMove>
                          <div
                            className={`w-fill-available  ${
                              section.isEmpty ? "border border-red-500" : ""
                            }`}
                          >
                            <AccordionContent className="w-fill-available">
                              <div className="flex flex-row w-fill-available justify-between">
                                <AccordionHeader className="w-fill-available">
                                  <div className="flex flex-col w-fill-available">
                                    <Typography>{section.title}</Typography>
                                    <Typography>{section.description}</Typography>
                                  </div>
                                </AccordionHeader>
                                <div className="flex justify-end">
                                  <Dropdown
                                    options={[
                                      { id: "edit", action: () => handleEditSection(section) },
                                      { id: "clone", action: () => handledClone(section.id) },
                                      {
                                        id: "delete",
                                        action: () => handleOpenConfirmationModal(section),
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                              <AccordionBody>
                                <Droppable droppableId={`${section.id}`} type="question">
                                  {(providedQuestions) => (
                                    <ul
                                      {...providedQuestions.droppableProps}
                                      ref={providedQuestions.innerRef}
                                    >
                                      {section?.questions?.length === 0 && (
                                        <div className="m-auto flex justify-center">
                                          <p>Esta sección aún no tiene preguntas asignadas</p>
                                        </div>
                                      )}
                                      {section.questions
                                        ?.sort((a, b) => a.order - b.order)
                                        .map((question, questionIndex) => (
                                          <Draggable
                                            isDragDisabled={false}
                                            key={question.id}
                                            draggableId={question.id.toString()}
                                            index={questionIndex}
                                          >
                                            {(providedQuestion) => (
                                              <QuestionList
                                                id={question.id}
                                                sectionId={section.id}
                                                provided={providedQuestion}
                                                title={question.title}
                                                itemNumber={questionIndex + 1}
                                                questionType={question.question_type_es}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                handleGetQuestion={handleGetQuestion}
                                                handleCloneQuestion={handleCloneQuestion}
                                                preview
                                                handlePreview={handlePreview}
                                                question={question}
                                                isActiveMissionFlag={false}
                                                sections={sections}
                                                handleForwarQuestion={handleForwarQuestion}
                                              />
                                            )}
                                          </Draggable>
                                        ))}
                                      {providedQuestions.placeholder}
                                    </ul>
                                  )}
                                </Droppable>
                                <StyledAddButton>
                                  <button
                                    className="mt-4"
                                    onClick={() => {
                                      const maxOrder = Math.max(0, ...section.questions.map(q => q.order));
                                      const newOrder = section.questions.length > 0 ? maxOrder + 1 : 0;
                                      handleAddQuestion(section.id, newOrder);
                                    }}
                                  >
                                    <IconPlus color="#000000" />
                                    Agregar pregunta
                                  </button>
                                </StyledAddButton>
                              </AccordionBody>
                            </AccordionContent>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {providedSections.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          <StyledAddButton>
            <button onClick={() => handleAddSection()}>
              <IconPlus color="#000000" />
              Agregar seccion
            </button>
          </StyledAddButton>
        </div>
      </ContentWrappers>
      <div className="flex justify-center mt-8">
        <DefaultButton type="button" color="secondary" onClick={handleFinalize}>
          Continuar
        </DefaultButton>
      </div>
      <ModalCustom
        isOpen={showModal}
        children={
          <StyledModalPreview>
            <div
              className="pt-4 pb-8 text-center"
              dangerouslySetInnerHTML={{ __html: questionPreview?.html ? questionPreview.html : questionPreview.title }}
            />
            {questionPreview.files && questionPreview.files.length > 0 && (
              <div style={{ maxWidth: "600px" }}>
                <Swiper
                  modules={[Pagination, Autoplay]}
                  slidesPerView={1}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  loop={questionPreview.files.length > 1}
                >
                  {questionPreview.files.map((file, index) => {
                    return (
                      <SwiperSlide key={index} className="py-4">
                        {/(.mp4|.mp3|.avi|.mkv|.mov)/.test(file) ? (
                          <video
                            width={
                              questionPreview.file_width && questionPreview.file_width > 0
                                ? questionPreview.file_width
                                : "100%"
                            }
                            height={
                              questionPreview.file_height && questionPreview.file_height > 0
                                ? questionPreview.file_height
                                : "100%"
                            }
                            controls
                          >
                            <source src={file} type={"video/mp4"} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className="object-cover mx-auto"
                            src={file}
                            width={
                              questionPreview.file_width && questionPreview.file_width > 0
                                ? questionPreview.file_width
                                : "100%"
                            }
                            height={
                              questionPreview.file_height && questionPreview.file_height > 0
                                ? questionPreview.file_height
                                : "100%"
                            }
                          />
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            )}
            {questionPreview.question_type === "AboutFile" && (
              <div>
                <TextAreaFieldControlled
                  id={questionPreview.question_type + questionPreview.id}
                  name={questionPreview.question_type + questionPreview.id}
                  control={controlQuestion}
                />
              </div>
            )}
            {questionPreview.question_type === "Matrix" && (
              <div>
                {questionPreview.question.rows.map((v, i) => (
                  <div key={"M" + v}>
                    <Typography className="pt-4 pb-8 text-center" variant="body1">
                      {v}
                    </Typography>
                    <RadioButtonControlled
                      control={controlQuestion}
                      radioOptions={questionPreview.question.columns.map((v) => {
                        return { label: v.name, value: v.name };
                      })}
                      id={questionPreview.question_type + questionPreview.id + v + i}
                      name={questionPreview.question_type + questionPreview.id + v + i}
                    />
                  </div>
                ))}
              </div>
            )}
            {questionPreview.question_type === "Radio" && (
              <div>
                <RadioButtonControlled
                  control={controlQuestion}
                  radioOptions={questionPreview.question.options
                    .sort(() =>
                      questionPreview.question.in_random_order ? Math.random() - 0.5 : 1
                    )
                    .map((v) => {
                      return { label: v.name, value: v.name, file: v?.file };
                    })}
                  id={questionPreview.question_type + questionPreview.id}
                  name={questionPreview.question_type + questionPreview.id}
                />
                {questionPreview.question.add_other_field && (
                  <div>
                    {watch(questionPreview.question_type + questionPreview.id) === "Otros" && (
                      <TextFieldControlled
                        control={controlQuestion}
                        id="otherInput"
                        name="otherInput"
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            {questionPreview.question_type === "Binary" && (
              <div>
                <RadioButtonControlled
                  control={controlQuestion}
                  radioOptions={[
                    {
                      label: "Si",
                      value: "Si",
                    },
                    {
                      label: "No",
                      value: "No",
                    },
                  ]}
                  id={questionPreview.question_type + questionPreview.id}
                  name={questionPreview.question_type + questionPreview.id}
                />
              </div>
            )}
            {questionPreview.question_type === "Checkbox" && (
              <div>
                {questionPreview.question.options
                  .sort(() => (questionPreview.question.in_random_order ? Math.random() - 0.5 : 1))
                  .map((v, i) => (
                    <div className="mb-2">
                      <CheckboxControlled
                        control={controlQuestion}
                        id={questionPreview.question_type + questionPreview.id + v.name}
                        name={questionPreview.question_type + questionPreview.id + v.name}
                        label={v.name}
                        key={"check" + v.name + i}
                      />
                      {v.file && (
                        <div className="mt-2 mb-3">
                          <img src={v?.file} height="150px" width="150px" />
                        </div>
                      )}
                    </div>
                  ))}
                {watch(questionPreview.question_type + questionPreview.id + "Otros") && (
                  <TextFieldControlled
                    control={controlQuestion}
                    id="otherInput"
                    name="otherInput"
                  />
                )}
              </div>
            )}
            {questionPreview.question_type === "Date" && (
              <div>
                <DatePickerFieldControlled
                  id={questionPreview.question_type + questionPreview.id}
                  name={questionPreview.question_type + questionPreview.id}
                  label=""
                  control={controlQuestion}
                />
              </div>
            )}
            {questionPreview.question_type === "Text" && (
              <div>
                <TextAreaFieldControlled
                  id={questionPreview.question_type + questionPreview.id}
                  name={questionPreview.question_type + questionPreview.id}
                  label=""
                  control={controlQuestion}
                />
              </div>
            )}
            {questionPreview.question_type === "File" && (
              <div>
                <InputFileControlled
                  id={questionPreview.question_type + questionPreview.id}
                  name={questionPreview.question_type + questionPreview.id}
                  label=""
                  control={controlQuestion}
                />
              </div>
            )}
            {questionPreview.question_type === "Ranking" && (
              <div>
                <Typography>1. Marca y/o desmarca los items deseados</Typography>
                <div className="flex space-x-4 overflow-x-auto max-w-[350px] mt-2 mb-3 py-2">
                  {questionPreview.question.options.map((option, index) => (
                    <Typography
                      variant="body2"
                      key={index}
                      className={`border rounded-xl py-2 px-4 hover:bg-primary cursor-pointer ${
                        listPrevRanking.includes(option.name) ? "bg-primary" : ""
                      }`}
                      onClick={() => handleSelectPrevRanking(option.name)}
                    >
                      {option.name}
                    </Typography>
                  ))}
                </div>
                <Typography>2. Arrastra los items en el orden deseado</Typography>

                <ul className="mt-2 flex flex-col gap-y-3 max-h-[250px] overflow-y-auto">
                  {listPrevRanking.map((item, index) => (
                    <li
                      key={index}
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      onDragEnd={handleDragEnd}
                      onDragOver={(e) => handleDragOver(e, index)}
                      className={`border rounded-xl py-2 px-4 flex items-center justify-between ${
                        draggedIndex == index ? "cursor-grabbing" : "cursor-grab"
                      }`}
                    >
                      <Typography>{item}</Typography>
                      <IconDots />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {console.log('questionPreview', questionPreview)}
            {questionPreview.question_type === "Range" && (
              <div className="text-center">
                <div className="flex items-center justify-between">
                  <Typography variant="body2">{questionPreview.question.label_start}</Typography>
                  <Typography variant="body2">{questionPreview.question.label_end}</Typography>
                </div>
                <Rangefield
                  id={questionPreview.question_type + questionPreview.id}
                  name={questionPreview.question_type + questionPreview.id}
                  marks={[
                    { value: questionPreview.question.value_start },
                    { value: questionPreview.question.value_end },
                  ]}
                  min={questionPreview.question.value_start}
                  max={questionPreview.question.value_end}
                  defaultValue={
                    (questionPreview.question.value_start + questionPreview.question.value_end) / 2
                  }
                />
              </div>
            )}
            {questionPreview.question_type === "Mask" && (
              <div className="text-center">
                {questionPreview.question.type_mask === "text" && (
                  <Typography variant="body2">{questionPreview.question.text}</Typography>
                )}
                {questionPreview.question.type_mask === "url" && (
                  <a href={questionPreview.question.url} target="_blank">
                    <Typography variant="body2">{questionPreview.question.url}</Typography>
                  </a>
                )}
              </div>
            )}
            <DefaultButton variant="primary" color="secondary" onClick={handleClose}>
              Cerrar
            </DefaultButton>
          </StyledModalPreview>
        }
      />
      <DefaultModal type={"info"} isOpen={editSection}>
        <CreateSection
          sectionId={sectionId}
          handleClose={handleCancelEditSection}
          handleSubmit={handleSubmitSection}
          onSubmit={onSubmitSection}
          control={controlSection}
          disableCreateButton={disableButton}
        />
      </DefaultModal>
      <DefaultModal type={"info"} isOpen={showConfirmation}>
        <div className="flex items-start flex-col space-y-8">
          <Typography variant={"h3"}>Eliminar</Typography>
          {currentSection?.questions.length ? (
            <Typography>
              La sección que quieres eliminar contiene preguntas que se borrarán ¿Deseas continuar
              con esta acción?
            </Typography>
          ) : (
            <Typography>
              Estás a punto de eliminar una sección. ¿Quieres continuar con esta acción?
            </Typography>
          )}
          <div className="flex justify-center space-x-4 w-fill-available">
            <DefaultButton color="secondary" rounded onClick={handleCloseConfirmationModal}>
              Cancelar
            </DefaultButton>
            <DefaultButton
              color="error"
              rounded
              onClick={() => handleRemoveSection(currentSection?.id)}
            >
              Eliminar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

export default Sections;
