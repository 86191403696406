// Packages
import { useNavigate } from "react-router-dom";

// Steps controllers
import useLegalView from "./Views";

const useLegal = () => {
  // State
  const navigation = useNavigate();

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/legal": ["Legales"],  
  };

  return {
    handleBack,
    header,
    useLegalView,
  };
};

export default useLegal;
