import { useState, useRef } from "react";
import { useController } from "react-hook-form";

const useCheckBoxGroupControlled = (control, name, defaultValue) => {
  const [checked, setChecked] = useState();
  const referenceCollapsed = useRef();

  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue ?? [],
  });

  const auxOnChange = (e, item) => {
    if (item.value === "all") {
      if (e.target.checked) {
        setChecked(true);

        const allValues = _.map(options, (item) => {
          return item.value;
        });
        onChange(allValues);
      } else {
        setChecked(false);
        onChange([]);
      }
    } else {
      setChecked(false);
      if (!value.includes(item.value)) {
        onChange([...value, item.value]);
        return;
      }
      const newValues = value.filter((v) => v !== item.value);
      onChange(newValues);
    }
  };

  const handleRemoveItem = (e, item) => {
    e.preventDefault();
    const dataDeleted = value.filter((v) => v !== item);
    onChange(dataDeleted);
    setChecked(false);
  };

  return { checked, referenceCollapsed, value, ref, error, auxOnChange, handleRemoveItem };
};
export default useCheckBoxGroupControlled;
