import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useCountruesServices = () => {
  const { useCountriesProviders } = useProviders();
  const {
    listCountriesProvider,
    listCountriesFree,
    listCountriesFreeFaqsProvider,
    listLanguajesProvider,
    createCountrieProvider,
    getCountrieProvider,
    editCountrieProvider,
    getExistingCurrencyProvider,
    getGeneralCountrieProvider,
  } = useCountriesProviders();

  const listCountriesService = ({
    page,
    search,
    iso,
    languaje,
    currency_name,
    currency_symbol,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            listCountriesProvider({
              page,
              search,
              iso,
              languaje,
              currency_name,
              currency_symbol,
            })
          )
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const listCountriesFreeService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listCountriesFree()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listCountriesFreeFaqsService = (id) => {    
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listCountriesFreeFaqsProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listLanguagesService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listLanguajesProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const createCountrieService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createCountrieProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getCountrieService = (countrieId) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getCountrieProvider(countrieId)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getGeneralCountrieService = (countrieId) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getGeneralCountrieProvider(countrieId)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const editCountrieService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editCountrieProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getExistingCurrencyService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getExistingCurrencyProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listCountriesService,
    listCountriesFreeService,
    listCountriesFreeFaqsService,
    listLanguagesService,
    createCountrieService,
    getCountrieService,
    editCountrieService,
    getExistingCurrencyService,
    getGeneralCountrieService,
  };
};

export default useCountruesServices;
