import { useState, useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import debounce from "lodash.debounce";
import useHelpers from "../../../../helpers";
import { useParams } from "react-router-dom";

import _ from "lodash";
import useApi from "../../../../api";
import useControllers from "../../..";

const paymentsDetail = () => {
  //Actions
  const { useActions } = useApi();
  const { usePaymentsActions, dispatch, useCountriesAction, useGeneralActions } = useActions();
  const { actListPayments, actEditPaymetsStatus, actLoadFilesPayments, actDownloadPayment } =
    usePaymentsActions();
  const { actGetGeneralCountrie } = useCountriesAction();

  const { actExportExel } = useGeneralActions();

  const { useQuickFunctions } = useHelpers();
  const { useValidators, useGeneral } = useQuickFunctions();
  const { filterPaymentsValidationShema } = useValidators();
  const { formatDate } = useGeneral();

  const [valueTextSearch, setValueTextSearch] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });

  const [showModal, setShowModal] = useState(false);
  const [showModalLoad, setShowModalLoad] = useState(false);

  const [valuesChecks, setValuesChecks] = useState([]);
  const [valueStatusMembeer, setValueStatusMembeer] = useState("");
  const [valueStatusPayment, setValueStatusPayment] = useState("");
  const [valueCountryId, setValueCountryId] = useState();
  const [valuInitDate, setValuInitDate] = useState("");
  const [valueEndDate, setValueEndDate] = useState([]);
  const [tableHeader, setTableHeader] = useState([
    "FECHA DE MISIÓN",
    "MEMBEEER",
    "ESTADO MEMBEER",
    "CÓDIGO DE MISIÓN",
    "misión",
    "VALOR",
    "ESTADO",
    "ACCIONES",
  ]);
  const [tableHeaderWidth, setTableHeaderWidth] = useState([
    "80px",
    "1.5fr",
    "1.5fr",
    "1.5fr",
    "1.5fr",
    "1.5fr",
    "1fr",
    "150px",
    "45px",
  ]);

  const [checkAll, setCheckAll] = useState(false);
  const [list, setList] = useState([]);
  const [paymentList, setPaymentList] = useState();
  const [countrieName, setCountrieName] = useState("");

  const { countryId } = useParams();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const { control, handleSubmit, resetField, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(filterPaymentsValidationShema),
    defaultValues: {
      range_date: null,
    },
  });

  const fileInputRef = useRef(null);

  const onSubmitFilter = (data, e) => {
    e.preventDefault();

    setValueStatusMembeer(data.member_state);
    setValueStatusPayment(data.payment_status);

    setValuInitDate(!!data.range_date ? formatDate(data.range_date[0], "Y-mm-dd", false) : null);
    setValueEndDate(
      !!data.range_date && !!data.range_date[1]
        ? formatDate(data.range_date[1], "Y-mm-dd", false)
        : null
    );

    actListPayments(
      {
        page: 1,
        country_id: countryId,
        membeer_name: valueTextSearch,
        ...(data.membeer_name && { membeer_name: data.membeer_name }),
        ...(data.member_state && { member_state: data.member_state }),
        ...(data.payment_status && { payment_status: data.payment_status }),
        ...(!!data.range_date && {
          init_date_approval: formatDate(data.range_date[0], "Y-mm-dd", false),
        }),
        ...(!!data.range_date &&
          !!data.range_date[1] && {
            end_date_approval: formatDate(data.range_date[1], "Y-mm-dd", false),
          }),
      },
      loadDataPayments
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModalLoad = () => {
    setShowModalLoad(false);
  };
  const onClickClear = () => resetField("range_date");

  const handleSubmitSearch = (e) => {
    setValueTextSearch(e);

    actListPayments(
      {
        page: 1,
        membeer_name: e,
        country_id: countryId === "multizonal" ? "" : countryId,
        ...(getValues("member_state") && { member_state: getValues("member_state") }),
        ...(getValues("payment_status") && { payment_status: getValues("payment_status") }),
        ...(!!getValues("range_date") && {
          init_date_approval: formatDate(getValues("range_date")[0], "Y-mm-dd", false),
        }),
        ...(!!getValues("range_date") &&
          !!getValues("range_date")[1] && {
            end_date_approval: formatDate(getValues("range_date")[1], "Y-mm-dd", false),
          }),
      },
      loadDataPayments
    );
  };

  const loadDataPayments = (data) => {
    setPaymentList(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };

  const handleChangeSearch = useCallback(debounce(handleSubmitSearch, 750), []);

  const handlePagination = (nro) => {
    actListPayments(
      {
        page: nro.selected + 1,
        ...(valueTextSearch && { membeer_name: valueTextSearch }),
        ...(valueStatusMembeer && { member_state: valueStatusMembeer }),
        ...(valueStatusPayment && { payment_status: valueStatusPayment }),
        ...(valuInitDate && { init_date_approval: valuInitDate }),
        ...(valueEndDate && { end_date_approval: valueEndDate }),
        country_id: countryId === "multizonal" ? "" : countryId,
      },
      loadDataPayments
    );
  };

  const handleChangeAllCheck = () => {
    setCheckAll(!checkAll);

    setValuesChecks(list);

    if (checkAll) {
      setValuesChecks([]);
    }
  };

  const handleChangeCheck = (e) => {
    const { id, checked } = e.target;

    setValuesChecks([...valuesChecks, parseInt(id)]);

    if (!checked) {
      setValuesChecks(valuesChecks.filter((item) => item !== parseInt(id)));
    }
  };

  const handleSuccesLoadFile = (data) => {
    setShowModalLoad(true);
    actListPayments({ page: 1, country_id: countryId }, loadDataPayments);
    if (fileInputRef.current) {
      console.log("fileInputRef.current", fileInputRef.current);
      fileInputRef.current.value = "";
    }
  };

  const handleSendDocument = (file) => {
    const formatData = new FormData();
    formatData.append("file", file[0]);

    dispatch(actLoadFilesPayments(formatData, handleSuccesLoadFile));
  };

  const handleDownloadExel = () => {
    const filters = {
      ...(valueTextSearch && { membeer_name: valueTextSearch }),
      ...(valueStatusMembeer && { member_state: valueStatusMembeer }),
      ...(valueStatusPayment && { payment_status: valueStatusPayment }),
      ...(valuInitDate && { init_date_approval: valuInitDate }),
      ...(valueEndDate && { end_date_approval: valueEndDate }),
      country_id: countryId === "multizonal" ? "" : countryId,
    };

    const filtersProcces = prepareDataFilterDownload(filters);

    actExportExel("paymentMembers", filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  const handleListPayments = () => {
    actListPayments({ page: 1 }, loadDataPayments);
    setShowModal(true);
  };

  const handleEditStatus = () => {
    if (!!valuesChecks.length) {
      const data = {
        payment_members: _.map(valuesChecks, (item) => {
          return { id: item };
        }),
      };

      dispatch(actEditPaymetsStatus(data, handleListPayments));
    }
  };

  const handleChangeStatusIndividual = (id) => {
    const data = {
      payment_members: [{ id: id }],
    };

    dispatch(actEditPaymetsStatus(data, handleListPayments));
  };

  useEffect(() => {
    setValueCountryId(countryId);

    if (countryId === "multizonal") {
      setTableHeader((pre) => {
        return [...pre.slice(0, 6 + 1), ["moneda"], ...pre.slice(6 + 1, tableHeader.length)];
      });
      setTableHeaderWidth((pre) => {
        return [...pre.slice(0, 6 + 1), ["1fr"], ...pre.slice(6 + 1, tableHeaderWidth.length)];
      });

      actListPayments({ page: 1 }, loadDataPayments);
    } else {
      actListPayments({ page: 1, country_id: countryId }, loadDataPayments);
    }
  }, [countryId]);

  const handleLoadNameCountry = (data) => {
    setCountrieName(data.name);
  };

  useEffect(() => {
    countryId === "multizonal"
      ? setCountrieName("multizonal")
      : dispatch(actGetGeneralCountrie(countryId, handleLoadNameCountry));
  }, [countryId]);

  return {
    paymentList,
    handleChangeSearch,
    handleSubmit,
    control,
    onSubmitFilter,
    handlePagination,
    paginationSettings,
    handleChangeAllCheck,
    valuesChecks,
    handleChangeCheck,
    checkAll,
    handleSendDocument,
    handleDownloadExel,
    handleEditStatus,
    showModal,
    handleCloseModal,
    handleCloseModalLoad,
    showModalLoad,
    valueCountryId,
    tableHeader,
    tableHeaderWidth,
    handleChangeStatusIndividual,
    countrieName,
    onClickClear,
    fileInputRef,
  };
};
export default paymentsDetail;
