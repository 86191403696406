// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledAddButton = styled.div.attrs({
  className: "StyledAddButton"
})`
  ${tw`flex items-center`}
  margin-top: 3px;

  button {
    ${tw`bg-third flex justify-center items-center rounded`}
    height: 44px;
    width: 44px;
  }
`