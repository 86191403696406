import { createReducer } from "@reduxjs/toolkit";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useProjectReducer = () => {
  const { useProjectIntialstate } = useInitialStates();
  const { projectCreateInitialState } = useProjectIntialstate();

  const { useTypes } = useStrings();
  const { useProjectTypes } = useTypes();
  const { CREATE, ADD_MISSION } = useProjectTypes();

  const projectSelected = createReducer(projectCreateInitialState, (builder) => {
    builder
      .addCase(CREATE, (state, action) => {
        const { payload } = action;

        const {
          id,
          name,
          countries,
          client_id,
          expiration_date,
          objective,
          decision,
          status,
          created_at,
          updated_at,
        } = payload;

        state.id = id;
        state.name = name;
        state.countries = countries;
        state.client_id = client_id;
        state.expiration_date = expiration_date;
        state.objective = objective;
        state.decision = decision;
        state.status = status;
        state.created_at = created_at;
        state.updated_at = updated_at;
      })
      .addCase(ADD_MISSION, (state, action) => {
        const { payload } = action;

        const { missions } = payload;

        state.missions = missions;
      });
  });

  return { projectSelected };
};

export default useProjectReducer;
