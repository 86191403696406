// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledWarpperSum = styled.div.attrs({
  className: "StyledWarpperSum",
})`
  ${tw` relative border-b border-neutral-60 pb-4 mb-8 `}
  .StyledHelperText {
    ${tw` mb-8 `}
  }
`;

export const StyledAddOptions = styled.div.attrs({
  className: "StyledAddOptions",
})`
  ${tw`flex items-start mb-7`}

  button {
    ${tw`bg-third flex justify-center items-center rounded`}
    height: 44px;
    width: 44px;
  }
`;

export const StyledListBrands = styled.div.attrs({
  className: "StyledListBrands",
})`
  ${tw` `}
`;

export const StyledListAllBrand = styled.div.attrs({
  className: "StyledListAllBrand",
})`
  ${tw` `}
  div {
    &:first-child {
      ${tw` col-span-3	`}
    }
  }
`;
export const StyledBankCheckList = styled.div.attrs({
  className: "StyledBankCheckList",
})`
  ${tw` grid grid-cols-3 space-y-3 mb-7 `}
`;
export const StyledWrapperRadio = styled.div.attrs({
  className: "StyledWrapperRadio",
})`
  ${tw` flex items-center `}
  .MuiFormGroup-root {
    ${tw` flex items-center flex-row flex-nowrap`}
  }
`;
