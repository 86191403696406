const Stars = ({ border }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="40"
      fill="none"
      viewBox="0 0 201 40"
      className={`h-24 px-2 ${border && border} rounded-lg`}
    >
      <path
        fill="#E5B611"
        d="M20.5 4l3.592 11.056h11.625l-9.405 6.833 3.593 11.055-9.405-6.833-9.405 6.833 3.593-11.056-9.405-6.832h11.625L20.5 4z"
      ></path>
      <path
        fill="#F4F4F4"
        stroke="#F4F4F4"
        d="M60.5 5.618l3.117 9.592.112.346h10.449l-8.16 5.928-.293.214.112.345 3.117 9.592-8.16-5.928-.294-.214-.294.214-8.16 5.928 3.117-9.592.112-.345-.293-.214-8.16-5.928H57.27l.112-.346L60.5 5.618zM100.5 5.618l3.117 9.592.112.346h10.449l-8.16 5.928-.293.214.112.345 3.117 9.592-8.16-5.928-.294-.214-.294.214-8.16 5.928 3.117-9.592.112-.345-.293-.214-8.16-5.928H97.27l.112-.346 3.117-9.592zM140.5 5.618l3.117 9.592.112.346h10.449l-8.16 5.928-.293.214.112.345 3.117 9.592-8.16-5.928-.294-.214-.294.214-8.16 5.928 3.117-9.592.112-.345-.293-.214-8.16-5.928h10.449l.112-.346 3.117-9.592zM180.5 5.618l3.117 9.592.112.346h10.449l-8.16 5.928-.293.214.112.345 3.117 9.592-8.16-5.928-.294-.214-.294.214-8.16 5.928 3.117-9.592.112-.345-.293-.214-8.16-5.928h10.449l.112-.346 3.117-9.592z"
      ></path>
    </svg>
  );
};

export default Stars;
