import { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import _ from "lodash";

// Steps controllers
import useProfilerView from "./Views";
import useProfilerCreate from "./Create";

const useProfiler = () => {
  const navigation = useNavigate();

  // Llama useMatch fuera del useEffect
  const matches = _.mapValues({
    "/perfilacion": useMatch("/perfilacion"),
    "/perfilacion/creacion": useMatch("/perfilacion/creacion"),
    "/perfilacion/editar/:id": useMatch("/perfilacion/editar/:id"),
  }, (match) => match);

  const [headerKey, setHeaderKey] = useState("/perfilacion");

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/perfilacion": ["Perfilación"],
    "/perfilacion/creacion": ["Perfilación", "Crear perfilación"],
    "/perfilacion/editar/:id": ["Perfilación", "Edición de la perfilación"],
  };

  useEffect(() => {
    _.forEach(matches, (match, pathname) => {
      if (match) {
        setHeaderKey((prevHeaderKey) => (
          Object.keys(header).find((key) => key === pathname) || prevHeaderKey
        ));
      }
    });
  }, [matches]);

  return {
    handleBack,
    header,
    useProfilerView,
    useProfilerCreate,
    headerKey,
  };
};

export default useProfiler;
