import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useGroupsServices = () => {
  const { useGroupMembeerProvider } = useProviders();
  const {
    listGroupProvider,
    editGroupProvider,
    addGroupProvider,
    getGroupProvider,
    getNumberGroupProvider,
    listGroupMisionProvider,
    deleteGroupProvider,
  } = useGroupMembeerProvider();

  const addGroupService = (data) => {
    return new Promise( async (resolve, reject) => {
      try {
        resolve(await trackPromise(addGroupProvider(data)));
      } catch (error) {
        reject(error);
      }
    })
  }
  
  const getNumberGroupService = (data) => {
    return new Promise( async (resolve, reject) => {
      try {
        resolve(await trackPromise(getNumberGroupProvider(data)));
      } catch (error) {
        reject(error);
      }
    })
  }

  const editGroupService = (id, data) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(editGroupProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    })
  }

  const listGroupService = ({page, country_id, search, rol_id, status}) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(listGroupProvider({page, country_id, search, rol_id, status})));
      } catch (error) {
        reject(error);
      }
    })
  }
  
  const listGroupMisionService = ({search, data}) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(listGroupMisionProvider({search, data})));
      } catch (error) {
        reject(error);
      }
    })
  }
  
  const getGroupService = (id) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(getGroupProvider(id)));
      } catch (error) {
        reject(error);
      }
    })
  }
  
  const deleteGroupService = (id) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteGroupProvider(id)));
      } catch (error) {
        reject(error);
      }
    })
  }

  return {
    addGroupService,
    editGroupService,
    listGroupService,
    getGroupService,
    getNumberGroupService,
    listGroupMisionService,
    deleteGroupService,
  }
}

export default useGroupsServices;