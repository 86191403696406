// Packages
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledContentText = styled.div.attrs({
  className: "StyledContentText"
})`
  ${tw`mb-7`}

  h2 {
    ${tw`mb-3`}
  }
`