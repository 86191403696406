import useApi from "../../index";

const useGeneralActions = () => {
  // Services
  const { useServices } = useApi();
  const { useGeneralServices } = useServices();

  const {
    listRegionsService,
    listAbiRegionsService,
    listAuxAbiRegionsService,
    listCountriesService,
    listCitiesService,
    listLenguajesService,
    listCountriesAllService,
    exportExelService,
    exportExelProfileService,
    listCitiesByStateService,
    listStatesByCountryService,
    getNotificationService,
  } = useGeneralServices();

  const actListCountries = async (onSuccess, onError) => {
    try {
      const response = await listCountriesService();
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListAllCountries = async (data, onSuccess, onError) => {
    try {
      const response = await listCountriesAllService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListCities = async (onSuccess, onError) => {
    try {
      const response = await listCitiesService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListCitiesByState = async (id, onSuccess, onError) => {
    try {
      const response = await listCitiesByStateService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListStatesByCountry = async (id, onSuccess, onError) => {
    try {
      const response = await listStatesByCountryService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListLenguajes = async (onSuccess, onError) => {
    try {
      const response = await listLenguajesService();
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListRegions = async (onSuccess, onError) => {
    try {
      const response = await listRegionsService();
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListAbiRegions = async (onSuccess, onError) => {
    try {
      const response = await listAbiRegionsService();
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListAuxAbiRegions = async (onSuccess, onError) => {
    try {
      const response = await listAuxAbiRegionsService();
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actExportExel = async (url, filters, onSuccess, onError, params) => {
    try {
      const response = await exportExelService(url, filters, params);

      onSuccess && onSuccess(response);
    } catch (error) {
      onError && onError();
    }
  };

  const actExportExelProfile = async (url, filters, onSuccess, onError) => {
    try {
      const response = await exportExelProfileService(url, filters);

      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };
  
  const actGetNotification = async (onSuccess, onError) => {
    try {
      const response = await getNotificationService();

      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  return {
    actListCountries,
    actListCities,
    actListCitiesByState,
    actListLenguajes,
    actListAllCountries,
    actListStatesByCountry,
    actListRegions,
    actListAbiRegions,
    actListAuxAbiRegions,
    actExportExel,
    actExportExelProfile,
    actGetNotification,
  };
};

export default useGeneralActions;
