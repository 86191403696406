import { useNavigate } from 'react-router-dom';

const useNavigation = () => {
  const navigate = useNavigate();

  const navigateTo = (url, state) => navigate(url, { state });
  const replaceAndNavigateTo = (url, state) => navigate(url, { replace: true, state });
  const goBack = () => navigate(-1);

  return {
    navigateTo,
    replaceAndNavigateTo,
    goBack
  };
};

export default useNavigation;
