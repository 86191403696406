import axios from "axios";

const useUsersProvider = () => {
  const listUsersProvider = ({page, country_id, search, rol_id, status}) => {
    return axios ({
      method: "get",
      url: `users?page=${page}`,
      params: {country_id, search, rol_id, status}
    })
  }
  
  const listRolesProvider = () => {
    return axios ({
      method: "get",
      url: `roles`,
    })
  }

  const editUserProvider = (id, data) => {
    return axios ({
      method: "put",
      url: `users/${id}`,
      data
    })
  }
  
  const addUserProvider = (data) => {
    return axios ({
      method: "post",
      url: `users`,
      data
    })
  }

  const deleteUserProvider = (id) => {
    return axios ({
      method: "delete",
      url: `users/${id}`
    })
  }

  return {
    listUsersProvider,
    editUserProvider,
    addUserProvider,
    listRolesProvider,
    deleteUserProvider,
  }
}

export default useUsersProvider;