import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import _ from "lodash";

import useLayouts from "../../layouts";
import useComponents from "../../components";
import useControllers from "../../../controllers";

import useModels from "../../../models";
import { useSelector } from "react-redux";

import CopilotInform from "./CopilotInform";

import { StyledTableCell, StyledContainerPaper, ConversationContentContainer, ScrollableDiv, StyledSurveyModalClose } from "./Analytic.styles";
import iconCloseCircle from "../../../assets/images/icon-close-circle.svg";

const Analytic = () => {
  const { useSelectors } = useModels();
  const { useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();
  const { loader } = useSelector(session);

  const { PrivateContentLayout } = useLayouts();
  const {
    Typography,
    useButtons,
    AutocompleteFieldControlled,
    SelectFieldControlled,
    DatePickerRangeControlled,
    CheckboxControlled,
    RadioButtonControlled,
    TextAreaFieldControlled,
    useModals,
    useSpinners
  } = useComponents();


  const { DefaultButton } = useButtons();
  const { DefaultModal } = useModals();
  const { SpinnerLoading } = useSpinners();

  const { useScreenHooks } = useControllers();
  const { useAnalytic } = useScreenHooks();
  const {
    control,
    listReport,
    onChangeSearchSurvey,
    listSurvey,
    onSelectSurvey,
    handleSubmit,
    onSubmit,
    listCountries,
    handleDownloadExcel,
    watch,
    listQuestions,
    onClickClear,
    listProfilingQuestion,
    warningQuestionRequired,
    toolTranscription,
    taskInProgress,
    handleGetAnalyticTools,
    showDocumentTypeModal,
    handleOpenDocumentTypeModal,
    modalControl,
    handleDownloadAnalytics,
    handleCopilotSubmit,
    onSubmitCopilot,
    showCopilotModal,
    handleShowCopilotModal,
    requestFinished,
    copilotModalControl,
    showCopilotBox,
    copilotAnalyticsContainer,
    toolAnalytics,
    isRefined,
  } = useAnalytic();

  return (
    <PrivateContentLayout
      breadcrumbsList={[]}
      showBack={location.pathname !== "/analytics"}
      title="analytics"
    >
      <div className="px-8 py-10 mx-6 mb-4 bg-white rounded-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-start justify-between gap-8">
            <SelectFieldControlled
              control={control}
              label="Tipo de reporte"
              name="reportType"
              id="reportType"
              options={[
                { label: "Muestreo Detallado Misión", value: "detailedSampling" },
                { label: "Muestreo Total Misión", value: "totalSampling" },
                { label: "Muestreo MAZ", value: "maz" },
                { label: "Muestreo Misión", value: "default" },
                { label: "Muestreo Detallado Perfilación", value: "detailedSamplingProfile" },
                { label: "Muestreo Total Perfilación", value: "totalSamplingProfile" },
                { label: "Muestreo Perfilación", value: "defaultProfile" },
                { label: "Reporte de Perfilación", value: "reportProfile" },
                { label: "Reporte de Video Llamada", value: "videocallTranscription" },
              ]}
            />
            {watch("reportType") !== "reportProfile" && (
              <AutocompleteFieldControlled
                control={control}
                label="Escribe una herramienta"
                name="search"
                id="search"
                className="my-0"
                options={listSurvey}
                onKeyUp={onChangeSearchSurvey}
                onChangeValue={onSelectSurvey}
              />
            )}
            {watch("reportType") !== "maz" && (
              <SelectFieldControlled
                control={control}
                label="Selecciona un pais"
                name="country_id"
                id="country_id"
                options={listCountries}
              />
            )}
            {(watch("reportType") === "detailedSampling" ||
              watch("reportType") === "detailedSamplingProfile") && (
                <SelectFieldControlled
                  control={control}
                  label="Selecciona una pregunta"
                  name="question_id"
                  id="question_id"
                  options={listQuestions}
                />
              )}
            {watch("reportType") === "reportProfile" && (
              <DatePickerRangeControlled
                label="Rango de fecha"
                name="range_date"
                id="range_date"
                control={control}
                onClickClear={onClickClear}
              />
            )}
          </div>
          {watch("reportType") === "reportProfile" && (
            <div className="space-y-4">
              <Typography>
                Seleccione las preguntas específicas que quiere ver en el reporte. Si no selecciona
                ninguna, el reporte las incluirá todas.
              </Typography>
              {listProfilingQuestion.demographic &&
                listProfilingQuestion.demographic.length > 0 && (
                  <Typography variant="body2">Selecciona las preguntas demograficas</Typography>
                )}
              <div className="grid grid-cols-2">
                {listProfilingQuestion.demographic?.map((v, i) => (
                  <CheckboxControlled
                    control={control}
                    key={i}
                    name={`question-demographic_${v.id}`}
                    id={`question-demographic_${v.id}`}
                    label={v.title}
                  />
                ))}
              </div>
              {listProfilingQuestion.attitudinal &&
                listProfilingQuestion.attitudinal.length > 0 && (
                  <Typography variant="body2">Selecciona las preguntas actitudinales</Typography>
                )}
              <div className="grid grid-cols-2">
                {listProfilingQuestion.attitudinal?.map((v, i) => (
                  <CheckboxControlled
                    control={control}
                    key={i}
                    name={`question-attitudinal_${v.id}`}
                    id={`question-attitudinal_${v.id}`}
                    label={v.title}
                  />
                ))}
              </div>
            </div>
          )}
          {warningQuestionRequired.show && (
            <div className="mb-5">
              <Typography variant="body2" color="error">
                {warningQuestionRequired.demographic && warningQuestionRequired.attitudinal
                  ? "Debe seleccionar al menos una pregunta demográfica y una actitudinal."
                  : warningQuestionRequired.demographic
                    ? "Debe seleccionar al menos una pregunta demográfica."
                    : warningQuestionRequired.attitudinal
                      ? "Debe seleccionar al menos una pregunta actitudinal."
                      : null}
              </Typography>
            </div>
          )}
          <div className="flex justify-center mt-2 space-x-2">
            {(watch("reportType") === "default" || watch("reportType") === "videocallTranscription") && (
              <DefaultButton color="secondary" type="submit" className="!rounded-full">
                {watch("reportType") === "videocallTranscription" ? "Consultar transcripción" : "Consultar"}
              </DefaultButton>
            )}
            {watch("reportType") !== "videocallTranscription" && (
              <DefaultButton color="third" onClick={handleDownloadExcel} className="!rounded-full">
                Exportar a Excel
              </DefaultButton>
            )}
            <DefaultButton disabled={taskInProgress || !requestFinished} color="grey" onClick={handleGetAnalyticTools} className="!rounded-full">
              Membeer AI Copilot
            </DefaultButton>
          </div>
        </form>
      </div>
      {
        (showCopilotBox || toolTranscription) && (
          <div className="px-8 py-10 mx-6 mb-4 bg-white h-[750px] rounded-lg flex flex-col justify-between">
            {showCopilotBox ? (
              (toolAnalytics) ? (
                <>
                  {!requestFinished ? (
                    <SpinnerLoading
                      isOpen={!requestFinished}
                      nested={true}
                      text={"¡Deja que la IA de Membeer haga su magia!"}
                    />
                  ) : (
                    <ScrollableDiv>
                      <div className="px-4">
                        <Typography variant="body2" color={"gray"} className="mb-4">
                          {"Membeer Copilot puede cometer errores. Considera verificar la información mostrada antes de utilizarla."}
                        </Typography>
                      </div>
                      <div className="h-full" ref={copilotAnalyticsContainer}>
                        <CopilotInform
                          htmlContent={toolAnalytics}
                        />
                      </div>
                    </ScrollableDiv>
                  )}
                  <div className="flex justify-center mt-8 space-x-2">
                    <DefaultButton
                      rounded={true}
                      color="primary"
                      disabled={!requestFinished}
                      onClick={handleOpenDocumentTypeModal}
                    >
                      Descargar Análisis
                    </DefaultButton>
                    <DefaultButton
                      rounded={true}
                      color="primary"
                      onClick={handleShowCopilotModal}
                      disabled={isRefined || !requestFinished}
                    >
                      Refinar Análisis
                    </DefaultButton>
                  </div>
                </>
              ) : (
                <SpinnerLoading
                  isOpen={!requestFinished}
                  nested={true}
                  text={"¡Deja que la IA de Membeer haga su magia!"}
                />
              )
            ) : (
              <>
                <ScrollableDiv>
                  <Typography variant="body1" className="mb-4">
                    <ConversationContentContainer
                      dangerouslySetInnerHTML={{ __html: toolTranscription }}
                    />
                  </Typography>
                </ScrollableDiv>
                <div className="flex justify-center mt-8 space-x-2">
                  <DefaultButton
                    rounded={true}
                    color="primary"
                    onClick={handleDownloadExcel}
                  >
                    Descargar Transcripción
                  </DefaultButton>
                </div>
              </>
            )}
          </div>
        )
      }

      {_.map(listReport, (report, key) => (
        <StyledContainerPaper key={key}>
          <TableContainer component={Paper} style={{ background: "transparent" }}>
            <Typography className="text-black">{report?.question}</Typography>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={2} align="center" />
                  <StyledTableCell align="center" colSpan={2}>
                    Genero
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={4}>
                    Edad
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={3}>
                    NSE
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={4}>
                    Región
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={2}>
                    Consumo
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={3}>
                    Audiencias
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  {_.map(report.columns, (column, key) => {
                    return (
                      <StyledTableCell key={key} font={column === "Total" ? "bold" : "400"}>
                        {column}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell colSpan="17" align="left" font="bold">
                    {report.title}
                  </StyledTableCell>
                </TableRow>
                {_.map(report.data, (data, key) => (
                  <TableRow key={key}>
                    {_.map(data, (v, key) => (
                      <StyledTableCell key={key} font={key <= 1 ? "bold" : "400"}>
                        {v.toString()?.includes("file:///") ? "Imagen" : v}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledContainerPaper>
      ))}

      <DefaultModal isOpen={showDocumentTypeModal} showIcon={false}>
        <div className="flex items-start flex-col space-y-5 w-[588px] py-10 px-14 rounded-[32px]">
          <div className="flex flex-col items-start w-full">
            <div className="mb-4">
              <Typography variant="h3" className="text-center">
                {"Seleccion el formato"}
              </Typography>
            </div>
            <div className="mb-8">
              <Typography variant="body1" className="text-justify">
                {
                  "¿En que formato quieres descargar el analisis?"
                }
              </Typography>
            </div>
          </div>
          <div className="w-full flex flex-col items-start mt-4">
            <RadioButtonControlled control={modalControl} radioOptions={[
              {
                value: "word",
                label: "Word",
              },
              {
                value: "pdf",
                label: "Pdf",
              }
            ]}
              id="type_document"
              name="type_document"
            />
          </div>
        </div>
        <div className="flex justify-center mt-8 space-x-2">
          <DefaultButton rounded={true} color="secondary" onClick={handleOpenDocumentTypeModal}>
            Cancelar
          </DefaultButton>
          <DefaultButton rounded={true} color="primary" onClick={handleDownloadAnalytics}>
            Descargar
          </DefaultButton>
        </div>
      </DefaultModal>
      <DefaultModal isOpen={showCopilotModal} showIcon={false}>
        <div className="flex items-start flex-col w-[588px] py-8 px-2 rounded-[32px]">
          <div className="flex justify-end w-full mb-4">
            <StyledSurveyModalClose
              alt="cerrar"
              onClick={handleShowCopilotModal}
              src={iconCloseCircle}
            />
          </div>
          <div className="flex flex-col items-start w-full">
            <div className="mb-4 items-center">
              <Typography variant="h3" className="text-center">
                {"Complementa el análisis del membeer AI Copilot"}
              </Typography>
            </div>
            <div className="mb-8">
              <Typography variant="body1" className="text-justify">
                {
                  "Aqui puedes complementar el análisis que ya hicieste o profundizar en alguno de los insights que encontraste. No necesitas empezar desde cero."
                }
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="body1" className="text-justify mt-4">
                {
                  "Nota: ten en cuenta que solo prodras refinar el análisis una vez."
                }
              </Typography>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-start mt-4">
            <form className="w-full" onSubmit={handleCopilotSubmit(onSubmitCopilot)}>
              <TextAreaFieldControlled
                className="w-full"
                label={"Escribe lo que necesitas"}
                placeholder="Ej. Profundiza el analisis en el segmento de 18 a 24 años"
                control={copilotModalControl}
                name={`copilot_prompt`}
                id={`copilot_prompt`}
                minRows={4}
                resize="none"
              />
              <div className="flex justify-center mt-8 space-x-2">
                <DefaultButton rounded={true} color="secondary" type="submit">
                  Volver a analizar
                </DefaultButton>
              </div>
            </form>
          </div>
        </div>

      </DefaultModal>
    </PrivateContentLayout>
  );
};

export default Analytic;
