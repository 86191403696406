const IconWarning = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64">
      <path
        fill="#F58025"
        d="M32 62.1A30.1 30.1 0 101.9 32 30.13 30.13 0 0032 62.1zm0-55.2A25.1 25.1 0 116.9 32 25.13 25.13 0 0132 6.9z"
      ></path>
      <path
        fill="#F58025"
        d="M32 47.6a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8zM34.5 16.4h-5v19.2h5V16.4z"
      ></path>
    </svg>
  );
};

export default IconWarning;
