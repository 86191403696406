import styled from 'styled-components';
import tw from 'twin.macro';
import ArrowDownImage from "../../../assets/images/down-arrow-image.svg";

// Components
import TextField from '../TextField';

export const StyledDropdownContainer = styled.ul.attrs({
  className: "StyledDropdownContainer"
})`
  ${tw`rounded absolute z-10`}
  background-color: #2e2e2e;
  li {
    span {
      ${tw`text-white px-4 py-2 block`}
    }
    &.active {
      background-color: #504227;
    }
  }
`

export const StyledInput = styled(TextField)`
  && {
    ${tw`mb-0`}
    input {
      ${tw`bg-no-repeat`}
      background-position: 98% center;
      background-image: url(${ArrowDownImage});
    }
  }
`