const IconUserGroup = ({ color = "#B6B9BA" }) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9999 16.0542V14.9542C17.4666 14.125 18.6666 12.0625 18.6666 10C18.6666 6.6875 18.6666 4 14.6666 4C10.6666 4 10.6666 6.6875 10.6666 10C10.6666 12.0625 11.8666 14.1292 13.3333 14.9542V16.0542C8.80825 16.425 5.33325 18.6458 5.33325 21.3333H23.9999C23.9999 18.6458 20.5249 16.425 15.9999 16.0542Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M6.81667 16.5708C7.97083 15.8167 9.4 15.2458 10.9792 14.8958C10.6667 14.525 10.3792 14.1125 10.1375 13.6667C9.5 12.5125 9.16667 11.2458 9.16667 10C9.16667 8.20833 9.16667 6.5125 9.80417 5.12917C10.425 3.78333 11.5375 2.95417 13.1208 2.64167C12.7708 1.04583 11.8292 0 9.33333 0C5.33333 0 5.33333 2.6875 5.33333 6C5.33333 8.0625 6.53333 10.1292 8 10.9542V12.0542C3.475 12.425 0 14.6458 0 17.3333H5.8125C6.11667 17.0625 6.45 16.8083 6.81667 16.5708Z"
        fill={color}
      />
    </svg>
  );
};

export default IconUserGroup;
