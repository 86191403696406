import axios from "axios";

const useNavBarProvider = () => {
  const getNavBarItemsProvider = () => {
    return axios({
      method: "get",
      url:`${process.env.REACT_APP_AI_GENERATION_URL}core/v1/sidebars`,
    });
  };

  return {
    getNavBarItemsProvider
  };
};

export default useNavBarProvider;
