// Packages
import React from 'react';

// Components
import useComponents from '../../../../components';

// Controlled
import useControllers from '../../../../../controllers';

// Assets
import useIcons from '../../../../../assets/icons';

// Styles
import { StyledAddButton } from './PassiveTrackingCreate.styles';

const PassiveTrackingCreate = () => {
  // Components
  const {
    ContentWrappers,
    CardWrapper,
    Typography,
    CheckboxControlled,
    DatePickerFieldControlled,
    TimePickerFieldControlled,
    SelectFieldControlled,
    FormLabel,
    useButtons,
    PlaceAutocompleteField,
    useModals,
  } = useComponents();
  const { DefaultButton } = useButtons();
  const { DefaultModal } = useModals()

  // Assets
  const { IconDelete, IconPlus } = useIcons();

  // Controlled
  const { useScreenHooks } = useControllers()
  const { useTools } = useScreenHooks();
  const { usePassiveTracking } = useTools();
  const { usePassiveTrackingCreate } = usePassiveTracking();
  const {
    control,
    handleSubmit,
    fields,
    handleAddStep,
    handleRemoveStep,
    //countString,
    onSubmit,
    handleSelectAddress,
    showModal,
    handleCloseModal,
    getValues,
    id_passive_tracking,
  } = usePassiveTrackingCreate();

  return (
    <>
      <ContentWrappers
        gridColums={["1fr"]}
        gap={{
          row: "0px",
          column: "20px"
        }}
        contentWidth="892px"
        as="section"
      >
        <CardWrapper
          padding="2rem"
        >
          <form  onSubmit={ handleSubmit(onSubmit) }>
            <div className="flex justify-between items-center mb-8">
              <Typography variant="h3" className="flex-1">
                PASOS DE RESTREO
              </Typography>
              <div className="flex-1">
                <CheckboxControlled
                  label="Solicitar foto y video por paso"
                  control={ control }
                  name="requestEvidence"
                  id="requestEvidence"
                />
              </div>

            </div>
            
            <div>
              {fields?.map((field, index) => {
                return (
                  <div
                    key={field.id}
                  >
                    <Typography variant="h4" className="uppercase items-center flex">
                      PASO {index + 1}
                      <button type="button" onClick={() => handleRemoveStep(index)}>
                        <IconDelete />
                      </button>
                    </Typography>
                    <ContentWrappers
                      gridColums={["1fr"]}
                      gap={{
                        row: "0px",
                        column: "20px"
                      }}
                      contentWidth="288px"
                      align="left"
                      className="mb-8 mt-8"
                    >
                      <PlaceAutocompleteField
                        control={ control }
                        inputId={`steps[${index}].address`}
                        defaultValue={getValues(`steps[${index}].address`)}
                        searchOptions={{
                          types: ['address'],
                          componentRestrictions: {
                            country: 'CO'
                          }
                        }}
                        handleSelect={(address) => handleSelectAddress(address, index)}           
                      />
                    </ContentWrappers>
                    <ContentWrappers
                      gridColums={["1fr","1fr", "44px"]}
                      gap={{
                        row: "0px",
                        column: "42px"
                      }}
                    >
                      <div>
                        <FormLabel
                          label="Fecha y hora de inicio"
                        />
                        <ContentWrappers
                          gridColums={["143px","96px","81px"]}
                          gap={{
                            row: "0px",
                            column: "16px"
                          }}
                        >
                          <DatePickerFieldControlled
                            name={`steps[${index}].init_date`}
                            id={`steps[${index}].init_date`}
                            control={ control }
                          />
                          <TimePickerFieldControlled
                            name={`steps[${index}].init_time`}
                            id={`steps[${index}].init_time`}
                            control={ control }
                          />
                          <SelectFieldControlled
                            placeholder="am"
                            name={`steps[${index}].init_AmPm`}
                            id={`steps[${index}].init_AmPm`}
                            control={ control }
                            options={[
                              {
                                value: "am",
                                label: "am"
                              },
                              {
                                value: "pm",
                                label: "pm"
                              }
                            ]}
                          />
                        </ContentWrappers>
                      </div>
                      <div>
                        <FormLabel
                          label="Fecha y hora de finalización"
                        />
                        <ContentWrappers
                          gridColums={["143px","96px","81px"]}
                          gap={{
                            row: "0px",
                            column: "16px"
                          }}
                        >
                          <DatePickerFieldControlled
                            name={`steps[${index}].end_date`}
                            id={`steps[${index}].end_date`}
                            control={ control }
                          />
                          <TimePickerFieldControlled
                            name={`steps[${index}].end_time`}
                            id={`steps[${index}].end_time`}
                            control={ control }
                          />
                          <SelectFieldControlled
                            placeholder="am"
                            name={`steps[${index}].end_AmPm`}
                            id={`steps[${index}].end_AmPm`}
                            control={ control }
                            options={[
                              {
                                value: "am",
                                label: "am"
                              },
                              {
                                value: "pm",
                                label: "pm"
                              }
                            ]}
                          />
                        </ContentWrappers>
                      </div>
                      <StyledAddButton>
                        <button onClick={() => handleAddStep(index+1, field.id)} type="button">
                          <IconPlus />
                        </button>
                      </StyledAddButton>
                    </ContentWrappers>
                  </div>
                )
              })}
            </div>
            <div className="flex justify-center mt-4">
              <DefaultButton type="submit" color="secondary">
                Finalizar y publicar
              </DefaultButton>
            </div>
          </form>
        </CardWrapper>
        <DefaultModal 
          isOpen={showModal}
          handledButton={handleCloseModal}
          children={<div>El Passive tracking <strong>{id_passive_tracking}</strong> se <br/> ha creado con éxito.</div>}
        />
      </ContentWrappers>
    </>
  )
};

export default PassiveTrackingCreate;
