import { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import _ from "lodash";

// Steps controllers
import useMembeersView from "./Views";
import useMembeersWaiting from "./Waiting";

const useMembeers = () => {
  const navigation = useNavigate();

  const matches = _.mapValues({
    "/membeers": useMatch("/membeers"),
    "/membeers/espera": useMatch("/membeers/espera"),
  }, (match) => match);

  const [headerKey, setHeaderKey] = useState("/membeers");

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/membeers": ["Membeers"],  
    "/membeers/espera": ["Membeers", "Usuarios en espera"],  
  };

  useEffect(() => {
    _.forEach(matches, (match, pathname) => {
      if (match) {
        setHeaderKey((prevHeaderKey) => (
          Object.keys(header).find((key) => key === pathname) || prevHeaderKey
        ));
      }
    });
  }, [matches]);

  return {
    handleBack,
    header,
    useMembeersView,
    useMembeersWaiting,
    headerKey,
  };
};

export default useMembeers;
