// Packages
import React from "react";

// Controllers
import useControllers from "../../../../../../controllers";

// Components
import SelectField from "../../../../../components/SelectField";

// Styles
import { StyledSelect } from "./Conditionals.styles";

const SelectComponent = (props) => {
  const {
    sectionList,
    optionName,
    questionId,
    condition,
    questionSelected,
    sectionSelected,
    endSurvey,
    error,
    index,
    disabled
  } = props;

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useSurvey } = useTools();
  const { useSelectComponent } = useSurvey();
  const {
    handleSelectChange,
    questionsList,
    valueSectionList,
    optionsSectionsList,
    handleQuestionsChange,
    valueQuestionList,
  } = useSelectComponent(
    sectionList,
    condition,
    questionId,
    sectionSelected,
    questionSelected,
    endSurvey
  );

  return (
    <StyledSelect>
      <div>
        <span>{optionName}</span>
        <div>
          <SelectField
            selectedValue={valueSectionList}
            id="section"
            onChange={handleSelectChange}
            placeholder="Ir a seccíon X"
            options={optionsSectionsList}
            error={error ?? false}
            disabled={disabled}
          />

          <SelectField
            selectedValue={valueQuestionList}
            id="question"
            onChange={(e) => handleQuestionsChange(e, index)}
            placeholder="Pregunta X"
            options={questionsList}
            error={error ?? false}
            disabled={disabled}
          />
        </div>
      </div>
    </StyledSelect>
  );
};

export default SelectComponent;
