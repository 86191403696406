// Packages
import { useState, useCallback, useEffect } from 'react';
import { useController } from "react-hook-form";

const useDateResponse = (control) => {
  const [showDates, setshowDates] = useState(false);

  const {field:{value}} = useController({
    control,
    name: 'inclusive_date',
    id: 'inclusive_date',
  });

  const handleShowDate = useCallback(() => {
    setshowDates(prevState => !prevState)
  }, [])

  useEffect(() => {
    setshowDates(value)
  }, [control, value])

  return {
    handleShowDate,
    showDates,
  }
}

export default useDateResponse;