// Packages
import styled from "styled-components";
import tw from "twin.macro";
import { FormControl } from "@mui/material";

export const StyledFormControl = styled(FormControl)`
  .StyledInput {
    ${tw`text-sm font-light w-full  box-border mt-2 bg-neutral-30 border border-neutral-60 border-solid rounded px-4 h-auto flex justify-start`}
    padding-top: 9.5px;
    padding-bottom: 9.5px;
  }

  .text-filter {
    ${tw`text-red-500`}
  }

  .StyledRange {
    ${tw` `}
    background-color:#F9F9F9;
    &.rmdp-ep-arrow {
      ${tw` bg-transparent`}
      &:after {
        ${tw` bg-secondary`}
      }
      &[direction="top"] {
        border-bottom: 1px solid #242d31;
      }
    }

    .rmdp-header-values {
      ${tw` text-secondary`}
    }
    .rmdp-month-picker,
    .rmdp-year-picker {
      ${tw` bg-secondary`}
      .rmdp-day {
        ${tw` text-secondary`}
        span {
          &:hover {
            ${tw` bg-primary`}
          }
        }
      }
      .rmdp-selected {
        span {
          ${tw` bg-primary`}
        }
      }
      .rmdp-today {
        span {
          ${tw` bg-primary-600 text-secondary`}
        }
      }
    }
    .rmdp-day-picker {
      .rmdp-week {
        &-day {
          color: #858585;
        }
        .rmdp-day {
          ${tw` text-secondary`}
          span {
            &:hover {
              ${tw` bg-primary`}
            }
          }
        }
        .rmdp-range {
          ${tw` bg-third shadow-none`}
        }
        .rmdp-today {
          span {
            ${tw` bg-third text-secondary`}
          }
        }
      }
    }
    .rmdp-arrow-container {
      &:hover {
        ${tw` bg-primary`}
      }
      .rmdp-arrow {
        border: solid #000;
        border-width: 0 2px 2px 0;
      }
    }
  }
`;
export const StyledHelperText = styled.span.attrs({
  className: "StyledHelperText",
})`
  ${tw`text-xs font-light text-danger -mt-6 w-full`}
`;
