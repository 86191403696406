import axios from "axios";

const useQuestionsProvider = () => {
  const listQuestionsProvider = (params) => {
    return axios({
      method: "get",
      url: "questions/quality-check",
      params: params,
    });
  };

  const deleteQuestionProvider = (id) => {
    return axios({
      method: "delete",
      url: `questions/quality-check/${id}`,
    });
  };

  const getQuestionProvider = (id) => {
    return axios({
      method: "get",
      url: `questions/quality-check/${id}`,
    });
  };

  const updateQuestionProvider = (id, data, headers) => {
    return axios({
      method: "post",
      url: `questions/quality-check/${id}`,
      headers,
      data,
    });
  };

  const createQuestionProvider = ({ data, headers }) => {
    return axios({
      method: "post",
      url: "questions/quality-check",
      headers,
      data,
    });
  };

  const cloneQuestionProvider = (id) => {
    return axios({
      method: "post",
      url: `questions/quality-check/${id}/clone`,
    });
  };

  return {
    listQuestionsProvider,
    deleteQuestionProvider,
    getQuestionProvider,
    createQuestionProvider,
    cloneQuestionProvider,
    updateQuestionProvider,
  };
};

export default useQuestionsProvider;
