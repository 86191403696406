import TableProfilerLicence from "./ProfilerLicence";
import TableProfilerRegion from "./ProfilerRegion";
import TableProfilerRegionABI from "./ProfilerRegionABI";
import TableProfilerAgeGenderNSE from "./ProfilerAgeGenderNSE";

const useComponentsProfiler = () => {
  return { 
    TableProfilerLicence, 
    TableProfilerRegion,
    TableProfilerRegionABI,
    TableProfilerAgeGenderNSE
   };
};
export default useComponentsProfiler;
