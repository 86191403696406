import { lazy } from "react";

import Footer from "./Footer";
import Header from "./Header";
import Wrapper from "./Wrapper";
import useButtons from "./Buttons";
import useModals from "./Modals";
import Typography from "./Typography";
import TextFieldControlled from "./TextFieldControlled";
import NavBar from "./NavBar";
import CheckboxControlled from "./CheckboxControlled";
import RadioButtonControlled from "./RadioButtonControlled";
import FormLabel from "./FormLabel";
import CardWrapper from "./CardWrapper";
import ContentWrappers from "./ContentWrappers";
import SelectFieldControlled from "./SelectFieldControlled";
import DatePickerFieldControlled from "./DatePickerFieldControlled";
import DatePickerRangeControlled from "./DatePikerRangeControlled";
import TimePickerFieldControlled from "./TimePickerFieldControlled";
import TextAreaFieldControlled from "./TextAreaFieldControlled";
import AutocompleteFieldControlled from "./AutocompleteFieldControlled";
import { TableContent, TableRow } from "./Table";
import Steps from "./Steps";
import Questions from "./Questions";
import useSpinners from "./Spinners";
import InputSwitch from "./InputSwitch";
import QuestionList from "./Questions/QuestionList";
import QuestionListAI from "./Questions/QuestionListAI";
import TextFieldSeach from "./TextFieldSeach";
import Badge from "./Badge";
import InputFileControlled from "./InputFileControlled";
import InputFile from "./InputFile";
import Toast from "./Toast";
const Pagination = lazy(() => import("./Pagination"));
import useMarkerMap from "./MarkerMap";
import SelectMultiFieldControlled from "./SelectMultipleFieldControlled";
import CheckboxGroupControlled from "./CheckboxGroupControlled";
import useAccordion from "./Accordion";
import PlaceAutocompleteField from "./PlaceAutocompleteField";
import InputSwitchControlled from "./InputSwitchControlled";
import Checkbox from "./Checkbox";
import useGrafic from "./Grafics";
import StatdisticsLabel from "./StatdisticsLabel";
import Rangefield from './RangeField'
import Dropdown from './Dropdown'
import InputDraft from './InputDraft'
import AutocompleteMultiFieldControlled from './AutocompleteMultiFieldControlled'
import Card from "./Card";

const useComponents = () => {
  return {
    Footer,
    Header,
    useButtons,
    Wrapper,
    Typography,
    NavBar,
    TextFieldControlled,
    TextFieldSeach,
    CheckboxControlled,
    RadioButtonControlled,
    FormLabel,
    CardWrapper,
    ContentWrappers,
    SelectFieldControlled,
    DatePickerFieldControlled,
    TimePickerFieldControlled,
    TextAreaFieldControlled,
    AutocompleteFieldControlled,
    TableContent,
    TableRow,
    Steps,
    Questions,
    useSpinners,
    useModals,
    InputSwitch,
    QuestionList,
    QuestionListAI,
    Badge,
    InputFileControlled,
    Pagination,
    Toast,
    useMarkerMap,
    SelectMultiFieldControlled,
    CheckboxGroupControlled,
    useAccordion,
    DatePickerRangeControlled,
    PlaceAutocompleteField,
    InputSwitchControlled,
    Checkbox,
    InputFile,
    useGrafic,
    StatdisticsLabel,
    Rangefield,
    Dropdown,
    InputDraft,
    AutocompleteMultiFieldControlled,
    Card,
  };
};

export default useComponents;
