// Packages
import { useState, useEffect } from "react";
import { models } from 'powerbi-client';

// Actions
import useActions from '../../../api/actions';

const usePowerBi = () => {
  const [textError, setTextError] = useState(null);
  const [configPowerBi, setConfig] = useState(null);

  // Actions
  const { usePowerBiActions } = useActions();
  const { actGetPowerBi } = usePowerBiActions();

  useEffect(() => {
    const onSuccess = (data) => {
      setConfig({
        type: 'report',
        
        // Make changes Start
        id: data.data?.id, // the report ID
        // Make changes End
        
        embedUrl: data.data.embedUrl,
        accessToken: data.data.token,
        tokenType: models.TokenType.Embed,
        permissions: models.Permissions.Read,
        settings: {
            background: models.BackgroundType.Transparent,
            panes:{
                bookmarks: {
                    visible: false
                },
                fields: {
                    expanded: false
                },
                filters: {
                    expanded: false,
                    visible: false
                },
                pageNavigation: {
                    visible: true
                },
                selection: {
                    visible: false
                },
                syncSlicers: {
                    visible: false
                },
                visualizations: {
                    expanded: false
                }
            }
        }
      })
      setTextError(null)
    }
    const onError = () => {
      setTextError("Sin Resultados")
    }
    actGetPowerBi(onSuccess, onError)
  }, [])

  return {
    textError,
    configPowerBi,
  };
};

export default usePowerBi;
