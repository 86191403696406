// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Helpers
import useHelpers from "../../../../helpers";

//Styles
import { StyledFormFilter } from "./Projects.styles";
import _ from "lodash";

const ProjectsRoot = () => {

  // Components
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    Badge,
    Pagination,
    DatePickerRangeControlled,
    useModals,
    Typography,
    Dropdown,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useProjectsScreen } = useScreenHooks();
  const { useProjectsRoot } = useProjectsScreen();
  const {
    listProjects,
    listCountries,
    control,
    paginationSettings,
    handlePagination,
    handleChangeSearch,
    handleEditProject,
    handleSubmit,
    onSubmitFilter,
    handleDeleteProject,
    modalDelete,
    handleCloseModal,
    handleConfirmDelete,
    onClickClear,
  } = useProjectsRoot();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-11/12" as="section">
        <div className="bg-white px-8 pt-10 pb-12 rounded-lg">
          <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
            <TextFieldSeach
              placeholder="Buscar"
              name="search"
              id="search"
              control={control}
              onInputChange={handleChangeSearch}
            />

            <div className="flex justify-end space-x-3">
              <FilterButton handleSubmit={handleSubmit(onSubmitFilter)}>
                <StyledFormFilter>
                  <SelectFieldControlled
                    name="status"
                    id="abi-status"
                    control={control}
                    label="Estado"
                    className="mb-2"
                    options={[
                      {
                        label: "Activo",
                        value: "act",
                      },
                      {
                        label: "Finalizado",
                        value: "fin",
                      },
                    ]}
                  />
                  <SelectFieldControlled
                    name="country_id"
                    id="country_id"
                    control={control}
                    label="País"
                    options={listCountries}
                  />
                  <SelectFieldControlled
                    name="client_id"
                    id="client_id"
                    control={control}
                    label="Cliente interno"
                    options={[
                      {
                        label: "GLOBAL",
                        value: 0,
                      },
                    ]}
                  />
                  <div className="flex items-center">
                    <DatePickerRangeControlled
                      label="Rango de fecha"
                      name="range_date"
                      id="range_date"
                      control={control}
                      onClickClear={onClickClear}
                      placeholder="Rango de fecha"
                    />
                  </div>
                </StyledFormFilter>
              </FilterButton>
              <DefaultButton color="secondary" onClick={() => navigateTo("/proyectos/crear")}>
                CREAR PROYECTO
              </DefaultButton>
            </div>
          </ContentWrappers>
          <TableContent
            headerCellList={[
              "ID",
              "NOMBRE",
              "PAÍS",
              "CLIENTE INTERNO",
              "OBJETIVO",
              "DECISIÓN",
              "TRACKING",
              "ESTADO",
              "ACCIONES",
            ]}
            gridCell={["96px", "1.2fr", "0.8fr", "0.8fr", "1fr", "1fr", "1fr", "1fr", "66px"]}
          >
            {_.map(listProjects, (project, index) => {
              return (
                <TableRow key={index}>
                  <div>{project?.id}</div>
                  <div>
                    <strong>{project?.name}</strong>
                  </div>
                  <div>
                    {_.map(project.countries, (countrie, idx) => {
                      return <div key={`listCountries-${idx}`}>{countrie.name}</div>;
                    })}
                  </div>
                  <div> {project?.client_id} </div>
                  <div> {project?.objective} </div>
                  <div> {project?.decision} </div>
                  <div> {project?.tracking ? 'Si' : 'No'} </div>
                  <div>
                    <Badge
                      disabled={project?.status === "Finalizado"}
                      variant={project?.status === "Activo" ? "success" : "none"}
                      label={project?.status}
                      type="light"
                    />
                  </div>
                  <div className="flex justify-end">
                    <Dropdown options={[
                      {id: 'edit', action: ()=> handleEditProject(project)},
                      {id: 'delete', action: ()=> handleDeleteProject(project.id, project.name)},
                      ]}/>
                  </div>
                </TableRow>
              );
            })}
          </TableContent>
        </div>

        <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      </ContentWrappers>
      <DefaultModal isOpen={modalDelete.show}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            Esta seguro de eliminar el proyecto <b>{modalDelete.name}</b>.
          </Typography>
          <div className="flex items-center space-x-4">
            <DefaultButton color="secondary" onClick={handleCloseModal}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="success" onClick={handleConfirmDelete}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

export default ProjectsRoot;
