// Packages
import React from "react";

// Styles
import { StyledTableRow } from "./Table.styles";
const TableRow = ({ children }) => {
  return <StyledTableRow>{children}</StyledTableRow>;
};

export default TableRow;
