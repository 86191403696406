const useAuthTypes = () =>{
  const LOGIN = 'LOGIN';
  const LOGOUT = 'LOGOUT';
  const LOADER_OFF = 'LOADER_OFF';
  const LOADER_ON = 'LOADER_ON';

  return {
    LOGIN,
    LOGOUT,
    LOADER_OFF,
    LOADER_ON,
  }
};

export default useAuthTypes;
