// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledWrapperRadio = styled.div.attrs({
  className: "StyledWrapperRadio",
})`
  .MuiFormGroup-root{
    ${tw` flex items-center flex-row flex-nowrap`}
  }
`;