import axios from "axios";

const useGroupMembeerProvider = () => {
  const listGroupProvider = ({page, country_id, search, rol_id, status}) => {
    return axios ({
      method: "get",
      url: `membeer_groups?page=${page}`,
      params: {country_id, search, rol_id, status}
    })
  }
  
  const listGroupMisionProvider = ({search, data}) => {
    return axios ({
      method: "get",
      url: `membeer_groups-search`,
      params: {search},
      data
    })
  }
  
  const getGroupProvider = (id) => {
    return axios ({
      method: "get",
      url: `membeer_groups/${id}`,
    })
  }

  const editGroupProvider = (id, data) => {
    return axios ({
      method: "post",
      url: `membeer_groups/${id}`,
      data
    })
  }
  
  const addGroupProvider = (data) => {
    return axios ({
      method: "post",
      url: `membeer_groups`,
      data
    })
  }
  
  const getNumberGroupProvider = (data) => {
    return axios ({
      method: "post",
      url: `membeer_groups-numbers`,
      data
    })
  }

  const deleteGroupProvider = (id) => {
    return axios ({
      method: "delete",
      url: `membeer_groups/${id}`,
    })
  }

  return {
    listGroupProvider,
    editGroupProvider,
    addGroupProvider,
    getGroupProvider,
    getNumberGroupProvider,
    listGroupMisionProvider,
    deleteGroupProvider,
  }
}

export default useGroupMembeerProvider;