import useHelpers from "../../../helpers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useUserReducers = () => {
  // Helpers
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //strings
  const { useTypes } = useStrings();
  const { useUsersTypes } = useTypes();
  const { ADD_USER, REMOVE_USER, } = useUsersTypes();

  // State
  const { useUserInitialStates } = useInitialStates();
  const { userInitialState } = useUserInitialStates();

  const userSelected = createReducer(userInitialState, {
    [ADD_USER](state, action) {
      const { payload } = action;
      return payload;
    },
    [REMOVE_USER]() {
      return userInitialState;
    },
  });

  return {
    userSelected,
  };
};

export default useUserReducers;
