import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useQuestionsServices = () => {
  const { useQuestionsProvider } = useProviders();
  const {
    listQuestionsProvider,
    deleteQuestionProvider,
    getQuestionProvider,
    createQuestionProvider,
    cloneQuestionProvider,
    updateQuestionProvider,
  } = useQuestionsProvider();

  const ListQuestionsService = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listQuestionsProvider(params)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteQuestionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteQuestionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getQuestionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getQuestionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateQuestionService = (id, data, headers) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(updateQuestionProvider(id, data, headers)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const createQuestionService = ({ data, headers }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createQuestionProvider({ data, headers })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const cloneQuestionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(cloneQuestionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    ListQuestionsService,
    deleteQuestionService,
    getQuestionService,
    createQuestionService,
    cloneQuestionService,
    updateQuestionService,
  };
};

export default useQuestionsServices;
