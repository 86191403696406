import React from "react";
import PropTypes from "prop-types";
import {
  StyledWrapper,
  StyledWrapperTitle,
  StyledWrapperSubTitle,
  StyledWrapperDescription,
  StyledChildrenContainer,
  StyledLogoContainer,
} from "./Wrapper.styles";
import Logo from "../../../assets/images/logo.svg";

const Wrapper = (props) => {
  const { className, children, title, subTitle, description, isReview, maxWidth, showLogo } = props;

  return (
    <StyledWrapper maxWidth={maxWidth} className={className} isReview={isReview}>
      <>
        {showLogo && <StyledLogoContainer src={Logo} />}
        <StyledWrapperTitle
          color="secondary"
          variant="h3"
          className="uppercase"
          sx={{ fontSize: "20px", lineHeight: "28px", letter: "-1.7%", color: "#000" }}
        >
          {title}
        </StyledWrapperTitle>
        <StyledWrapperSubTitle variant="body2">{subTitle}</StyledWrapperSubTitle>
        <StyledWrapperDescription variant="body2">{description}</StyledWrapperDescription>
      </>

      <StyledChildrenContainer>{children}</StyledChildrenContainer>
    </StyledWrapper>
  );
};
Wrapper.propTypes = {
  className: PropTypes.string,
  /**
   * Image used for the profile screen
   */
  isProfile: PropTypes.bool,
  /**
   * Ajust margin for wizzard at low resolutions
   */
  isReview: PropTypes.bool,
  title: PropTypes.any,
  subTitle: PropTypes.any,
  description: PropTypes.any,
  maxWidth: PropTypes.string,
  children: PropTypes.any,
  showLogo: PropTypes.bool,
};

Wrapper.defaultProps = {
  className: "",
  title: "",
  subTitle: "",
  description: "",
  isProfile: false,
  showLogo: false,
};
export default Wrapper;
