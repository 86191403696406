const IconLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#07090A"
        d="M19.936 40c11.01 0 19.935-8.954 19.935-20S30.946 0 19.936 0C8.926 0 0 8.954 0 20s8.925 20 19.936 20z"
      ></path>
      <path
        fill="#FFA700"
        d="M14.636 12.507v18.734h3.68V12.507h-3.68zm-6.631 0V31.14l-.013.16h3.692c.018-.292.031-.59.026-.905V12.507H8.004zM29.279 12.68c-1.813 0-3.186.919-4.12 2.792v-2.965h-3.654V23.17c0 5.416 2.278 8.106 6.81 8.073 4.504-.103 6.76-3.134 6.76-9.13 0-6.131-1.923-9.261-5.796-9.433zm-1.072 14.714c-2.088 0-3.074-1.839-3.049-5.486.112-3.473 1.125-5.174 3.104-5.174s2.994 1.701 3.078 5.174c0 3.647-1.044 5.486-3.133 5.486z"
      ></path>
      <path
        fill="#F3F5F7"
        d="M11.712 7.557v4.95H8.005V9.375c0-1.156.601-1.733 1.81-1.816h1.897v-.002zM18.324 7.557c-.003.053-.009.107-.009.16v4.79h-3.68V7.664a.715.715 0 00-.008-.107h3.697zM25.159 10.09v2.417h-3.654V7.808h1.841c1.209 0 1.813.786 1.813 2.283z"
      ></path>
    </svg>
  );
}

export default IconLogo;
