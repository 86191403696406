import React from "react";

// Controllers
import useControllers from "../../../../controllers";

// Components
import useComponents from "../../../components";

const View = () => {

  const {
    ContentWrappers,
    Typography,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    Badge,
    Pagination,
    DatePickerRangeControlled,
    TextFieldControlled,
    useModals,
    Dropdown,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useGeneralHooks, useScreenHooks } = useControllers();
  const { formatDate } = useGeneralHooks();

  const { useNewsScreens } = useScreenHooks();
  const { newsRoot } = useNewsScreens();
  const {
    listNews,
    control,
    handleChangeSearch,
    onSubmitFilter,
    handleSubmit,
    handlePagination,
    handleGoToCreate,
    paginationSettings,
    listCountries,
    handleEditNew,
    handleCloneNew,
    handleDownloadExel,
    handleDeleteNew,
    modalDelete,
    handleCloseModal,
    handleConfirmDelete,
    onClickClear,
  } = newsRoot();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-11/12" as="section">
        <div className="bg-white px-8 pt-10 pb-12 rounded-lg">
          <ContentWrappers
            gridColums={["1fr 210px"]}
            className="mb-8 items-center border-b border-neutral-60 pb-8"
          >
            <Typography variant="h3">Listado de noticias</Typography>
            <DefaultButton color="secondary" onClick={handleGoToCreate}>
              Crear noticia
            </DefaultButton>
          </ContentWrappers>
          <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
            <TextFieldSeach
              placeholder="Buscar"
              name="search"
              id="search"
              control={control}
              onInputChange={handleChangeSearch}
            />

            <div className="flex justify-end space-x-3">
              <FilterButton handleSubmit={handleSubmit(onSubmitFilter)}>
                <div>
                  <SelectFieldControlled
                    name="status"
                    id="abi-status"
                    control={control}
                    label="Estado"
                    className="mb-2"
                    options={[
                      {
                        label: "Activo",
                        value: "act",
                      },
                      {
                        label: "Inactivo",
                        value: "ina",
                      },
                    ]}
                  />
                  <SelectFieldControlled
                    name="country_id"
                    id="country_id"
                    control={control}
                    label="País"
                    options={listCountries}
                  />
                  <div className="flex items-center mb-2">
                    <DatePickerRangeControlled
                      label="Rango de fecha"
                      name="range_date"
                      id="range_date"
                      control={control}
                      onClickClear={onClickClear}
                    />
                  </div>
                  <TextFieldControlled
                    label="Autor"
                    placeholder="Autor"
                    name="author"
                    id="abi-author"
                    control={control}
                  />
                </div>
              </FilterButton>
              {/* <DefaultButton color="third" onClick={handleDownloadExel}>
                Exportar a Excel
              </DefaultButton> */}
            </div>
          </ContentWrappers>
          <TableContent
            headerCellList={[
              "ID",
              "NOMBRE DE NOTICIA",
              "AUTOR",
              "PAÍS",
              "MENSAJE",
              "FECHA DE CREACIÓN",
              /* "FECHA DE PUBLICACIÓN", */
              "ESTADO",
              "ORDEN DE SLIDER",
              "ACCIONES",
            ]}
            gridCell={[
              "96px",
              "1.2fr",
              "0.8fr",
              "0.8fr",
              "1fr",
              "1fr",
              /* "1fr", */
              "1fr",
              "1fr",
              "90px",
            ]}
          >
            {_.map(listNews, (itemNew, index) => {
              return (
                <TableRow key={index}>
                  <div>{itemNew?.id}</div>
                  <div>
                    <strong>{itemNew?.name}</strong>
                  </div>
                  <div>{itemNew?.author}</div>
                  <div>{itemNew?.countries.map(v =>{return v.name}).join(',')}</div>
                  <div className="truncate"> {itemNew?.text} </div>
                  <div> {formatDate(itemNew?.created_at, "dd-mm-Y")} </div>
                  {/* <div> {formatDate(itemNew?.published_at, "dd-mm-Y")} </div> */}

                  <div>
                    <Badge
                      disabled={itemNew?.status === "ina"}
                      variant={itemNew?.status === "act" ? "success" : "none"}
                      label={itemNew?.status === "act" ? "Activo" : "Inactivo"}
                      type="light"
                    />
                  </div>
                  <div>{`${itemNew?.index}/${itemNew?.active_count}`}</div>
                  <div className="flex justify-end">
                    <Dropdown options={[
                      {id: 'delete', action: ()=> handleDeleteNew(itemNew.id, itemNew.name)},
                      {id: 'edit', action: ()=> handleEditNew(itemNew)},
                      {id: 'clone', action: ()=> handleCloneNew(itemNew)},
                      ]}/>
                  </div>
                </TableRow>
              );
            })}
          </TableContent>
          <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
        </div>
      </ContentWrappers>
      <DefaultModal isOpen={modalDelete.show}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            Esta seguro de eliminar la noticia <b>{modalDelete.name}</b>.
          </Typography>
          <div className="flex items-center space-x-4">
            <DefaultButton color="secondary" onClick={handleCloseModal}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="success" onClick={handleConfirmDelete}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

export default View;
