// Packages
import React from "react";

// Styles
import { StyledContentButtons } from "./CheckpointReport.styles";

// Components
import useComponents from "../../../../components";

// Controllers
import useControllers from "../../../../../controllers";

// Helpers
import useHelpers from "../../../../../helpers";

// Assets
import useIcons from "../../../../../assets/icons";

const CheckpointReport = () => {
  const { IconDownload } = useIcons();

  // Components
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    Typography,
    Badge,
    Pagination,
  } = useComponents();

  const { DefaultButton } = useButtons();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { usePassiveTracking } = useTools();
  const { useCheckpointReport } = usePassiveTracking();
  const {
    trackingList,
    id_passive_tracking,
    handleDownloadExel,
    titleMembeer,
    membeersRegister,
  } = useCheckpointReport();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} contentWidth="1200px" as="section">
        <div className="bg-white px-8 pt-10 pb-12 rounded-lg">
          <div className="border-b border-solid border-netral-60 pb-6 mb-16">
            <div className="mb-4">
              <Typography variant="h3">REPORTE PASSIVE TRACKING</Typography>
              <Typography variant="caption">
                {titleMembeer?.mission_name} / {titleMembeer?.step?.name}
              </Typography>
            </div>
            <ContentWrappers gridColums={["1fr", "210px"]}>
              <ContentWrappers
                gridColums={["1fr", "138px", "142px", "116px", "111px"]}
                gap={{
                  row: "0",
                  column: "60px",
                }}
                align="left"
              >
                <div>
                  <Typography variant="h5">DIRECCIÓN / LOCAL</Typography>
                  <Typography variant="caption">{titleMembeer?.step?.address}</Typography>
                </div>
                <div>
                  <Typography variant="h5">FECHA DE INICIO</Typography>
                  <Typography variant="caption">{titleMembeer?.step?.init_date}</Typography>
                </div>
                <div>
                  <Typography variant="h5">FECHA DE FINALIZACIÓN</Typography>
                  <Typography variant="caption">{titleMembeer?.step?.end_date}</Typography>
                </div>
                <div>
                  <Typography variant="h5">MEMBEERS INSCRITOS</Typography>
                  <Typography variant="caption" className="text-center inline-block w-full">
                    {membeersRegister}
                  </Typography>
                </div>
              </ContentWrappers>

              <DefaultButton
                color="secondary"
                onClick={() =>
                  navigateTo(
                    `/herramientas/passive-tracking/${id_passive_tracking}/mapa-de-lugares`
                  )
                }
              >
                Ver mapa de lugares
              </DefaultButton>
            </ContentWrappers>
          </div>
          <ContentWrappers gridColums={["1fr"]} className="mb-10">
            <StyledContentButtons>
              <DefaultButton color="info" onClick={handleDownloadExel}>
                Exportar CSV
              </DefaultButton>
            </StyledContentButtons>
          </ContentWrappers>
          <TableContent
            headerCellList={[
              "Membeer",
              "Email",
              "Fecha de tracking",
              "Estado",
              "Descargar foto o video",
            ]}
            gridCell={["176px", "222px", "1fr", "158px", "176px"]}
          >
            {trackingList.map((tracking, index) => {
              return (
                <TableRow key={index}>
                  <div> {tracking.name} </div>
                  <div> {tracking.email} </div>
                  <div> {tracking.steps[0]["date-answer"]} </div>
                  <div>
                    <Badge
                      disabled={false}
                      type="light"
                      variant={
                        tracking.steps[0].status === "Finalizado"
                          ? "success"
                          : tracking.steps[0].status === "Pendiente de revision" || tracking.steps[0].status === "Pendiente"
                          ? "info"
                          : "danger"
                      }
                      label={tracking.steps[0].status}
                    />
                  </div>
                  <div className="flex justify-center">
                    <a href={tracking.steps[0]["file-answer"]} target="_blank" download>
                      <IconDownload />
                    </a>
                  </div>
                </TableRow>
              );
            })}
          </TableContent>
        </div>
      </ContentWrappers>
    </>
  );
};

export default CheckpointReport;
