import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "lodash.debounce";

import useComponents from "../../../../views/components";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

const useLegalView = () => {
  const [listLegal, setListLegals] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [valuesFilter, setValuesFilter] = useState({});
  const [idLegal, setIdLegal] = useState()
  const [showModal, setShowModal] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });

  // Actions
  const { useActions } = useApi();
  const { useLegalActions, useGeneralActions } = useActions();
  const { actListLegal, actCreateLegal, actEditLegal } = useLegalActions();
  const { actListCountries } = useGeneralActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast } = useQuickFunctions();
  const { filterLegalShema, legalValidationShema } = useValidators();
  const { success, error } = useToast();

  const { Toast } = useComponents();

  const {
    control: controlFilter,
    handleSubmit: handleSubmitFilter,
  } = useForm({
    mode: "all",
    resolver: yupResolver(filterLegalShema),
    defaultValues: {
      country_id: undefined,
    },
  });

  const { control, handleSubmit, setValue, reset } = useForm({
    mode: "all",
    resolver: yupResolver(legalValidationShema),
    defaultValues: {
      country_id: undefined,
      file_tyc: null,
      file_privacy: null,
    },
  });

  const loadData = (data) => {
    setListLegals(data?.data);
    setPaginationSettings({ current: data?.meta.current_page, totalPages: data?.meta.last_page });
  };

  const loadDataPagination = (data) => {
    setListLegals(data?.data);
  };

  const handlePagination = (nro) => {
    setPaginationSettings({ ...paginationSettings, current: nro.selected + 1 });
    actListLegal(
      { page: nro.selected + 1, params: { ...valuesFilter, search: searchText } },
      loadDataPagination
    );
  };

  const handleOnCreateSuccess = () => {
    success(<Toast text={`Datos guardados con exito!`} />, {
      toastId: 0,
    });
    actListLegal({ page: 1 }, loadData);
    setIdLegal()
    reset()
    handleCloseModal()
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("country_id", data.country_id);
    formData.append("file_privacy", data.file_privacy[0]);
    formData.append("file_tyc", data.file_tyc[0]);

    if(idLegal) {
      actEditLegal({id: idLegal, data: formData}, handleOnCreateSuccess);
    } else {
      actCreateLegal(formData, handleOnCreateSuccess)
    }
  }

  const onSubmitFilter = (data, e) => {
    e.preventDefault();
    setValuesFilter({
      ...(data.country_id && { country_id: data.country_id }),
    });

    actListLegal(
      {
        page: 1,
        params: {
          ...(data.country_id && { country_id: data.country_id }),
        },
      },
      loadData
    );
  };

  const onSubmitSearch = (e) => {
    setSearchText(e);
    actListLegal({ page: 1, params: { ...valuesFilter, search: e } }, loadData);
  };

  const onChangeSearch = useCallback(debounce(onSubmitSearch, 750), []);

  const dataListCountries = (data) => {
    setListCountries(
      _.map(data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const handleEditLegal = (id, country_id) => {
    setIdLegal(id)
    handleCreateLegal()
    setValue('country_id', country_id);
  }

  const handleCreateLegal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    actListLegal({ page: 1 }, loadData);
    actListCountries(dataListCountries);
  }, []);

  return {
    listLegal,
    control,
    handleSubmit,
    onSubmitFilter,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    listCountries,
    showModal,
    handleCloseModal,
    handleCreateLegal,
    controlFilter,
    handleSubmitFilter,
    onSubmit,
    handleEditLegal,
  };
};

export default useLegalView;
