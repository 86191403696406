import styled from "styled-components";
import tw from "twin.macro";

export const StyledPrivateContent = styled.main.attrs({
  className: "StyledPrivateContent",
})`
  ${tw`relative flex flex-col`}
  background-position: left top;
  ${(props) => {
    switch (props.background) {
      case "primary":
        return `background: #FFFFFF;`;
      case "secondary":
        return ` 
        background: #F8F9FA;`;
      case "tertiary":
        return ` background: #E5E5E5;`;
      default:
        return tw`bg-white`;
    }
  }}
  @media (max-width: 768px) {
    width: 100vw;
    overflow-x: hidden;
    min-height: calc(100vh - 200px);
    background: linear-gradient(
      107.17deg,
      rgba(220, 227, 191, 0.64) -2.4%,
      rgba(150, 171, 64, 0.64) 100.98%
    );
  }
`;

export const StyledInnerContent = styled.section.attrs({
  className: "StyledInnerContent",
})`
  ${tw`relative flex-1 w-4/5`}
  background-color: #E5E5E5;
  z-index: 1;
  min-height: 140vh;
`;

export const StyledInnerContentChildren = styled.div.attrs({
  className: "StyledInnerContentChildren",
})`
  min-height: calc(91.5% - (112px + 72px));
  width: 100%;
  padding-top: 0.75rem;
  height: auto;
`;

export const StyledInnerContentBackuser = styled.div.attrs({
  className: "StyledInnerContentBackuser",
})`
  ${tw`relative `}
  display: grid;
  grid-template-columns: 194px auto;
  @media (max-width: 768px) {
    display: flex;
  }
`;
export const StyledHeaderContent = styled.header`
  background-color: #f8f9fa;
  opacity: 0.8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
`;

export const StyledImgWatermark = styled.img`
  && {
    ${tw`absolute`}
    top: 0;
    left: 200px;
    @media (max-width: 768px) {
      width: 334px;
      height: 400px;
      top: 72px;
      left: -80px;
    }
  }
`;

export const StyledContainerBack = styled.div`
  ${tw`-mt-6 flex items-center ml-4 space-x-1 cursor-pointer absolute z-10`}

  span {
    ${tw`font-bold text-secondary`}
  }

  svg {
    margin-top: -3px;
  }
`;

export const StyledWarpperContent = styled.section`
  ${tw`flex`}
  min-height: 100vh;
  height: auto;
`;
