// Packages
import styled from "styled-components";
import tw from "twin.macro";
import { FormControl, Autocomplete } from '@mui/material';

export const StyledFormControl = styled(FormControl)`
  && {
    .MuiInputBase-input {
      ${tw`p-8`}
    }
  }
`

export const StyledAutocomplete = styled(Autocomplete)`
  && {
    .MuiInput-root {
      padding-right: 0!important;

      .MuiInputBase-input {
        ${tw`px-4 mt-2`}
        padding-bottom: 9.5px; 
        padding-top: 9.5px; 
      }
    }

    .MuiAutocomplete-endAdornment {
      right: 15px;

      .MuiButtonBase-root {
        transform: none;
    }
  }
`

export const StyledHelperText = styled.span.attrs({
  className: "StyledHelperText"
})`
  ${tw`absolute left-0 text-xs font-light text-danger bottom-0`}
`