// Packages
import styled from "styled-components";
import tw from "twin.macro";
import TableCell from "@mui/material/TableCell";

export const StyledTableCell = styled(TableCell)`
  ${props => props.font ? `font-weight: ${props.font} !important;` : 'font-weight: 300 !important;'}
`;

export const StyledContainerPaper = styled.div`
  .MuiPaper-root {
    background: white !important;
    padding: 2rem 1rem;
    margin: 0 1.5rem 2rem;
    border-radius: 10px;
    max-width: 96%;
  }
  thead {
    tr {
        ${tw`uppercase`}
        th {
            color: #B6BBC1 !important;
            font-weight: 600 !important;
            font-size: 0.75rem !important;
            ${tw`p-1 truncate`}
        }
    }
  }
  tbody {
    tr {
        ${tw`truncate`}
        letter-spacing: -0.006em;
        td {
            color: #363635 !important;
            font-size: 0.75rem !important;
            ${tw`border-dashed border-b border-neutral-70`}
        }
    }
    tr:nth-of-type(even) {
        td {
            ${tw`bg-neutral-20`}
        }
        
    }
  }
`;
