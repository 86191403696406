// Packages
import styled from "styled-components";
import tw from "twin.macro";
import { GlobalStyles } from "twin.macro";

export const StyledSearch = styled.div.attrs({
  className: "StyledSearch",
})`
  ${tw`relative`}
  max-width: 288px;

  button {
    ${tw`absolute top-1/2`}
    transform: translateY(-50%);
    right: 18px;
  }

  .MuiFormControl-root {
    margin-bottom: 0;

    input {
      margin-top: 0;
    }
  }
`;
