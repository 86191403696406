import { useCallback, useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useHelpers from "../../../../helpers";

import useApi from "../../../../api";
import { useParams, useNavigate } from "react-router-dom";

const newsCreate = () => {
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [textSucces, setTextSucces] = useState("");
  const [nameNew, setNameNew] = useState("");
  const [totalNews, setTotalNews] = useState(null);

  const navigation = useNavigate();

  const { useQuickFunctions } = useHelpers();
  const { useValidators, useGeneral } = useQuickFunctions();
  const { newNewsSchema } = useValidators();

  const { dateFormatUTC } = useGeneral();

  const { newId } = useParams();

  const { useActions } = useApi();
  const { useNewsActions, dispatch } = useActions();
  const { actCreateNew, actGetNew, actTotalNews, actEditNew } = useNewsActions();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(newNewsSchema),
  });

  const handleCloseModal = () => {
    setShowModal(false);
    navigation("/noticias");
  };

  const handleSuccesCreateEdit = (data) => {
    setNameNew(data.data.name);
    setShowModal(true);
  };

  const onSubmit = useCallback(    
    (data) => {    
    setSaving(true);
    const formatData = new FormData();
    formatData.append("name", data.name);
    formatData.append("text", data.text);
    formatData.append("index", data.index);
    formatData.append("author", data.author);
    formatData.append("status", data.status);
    data.country_id.map((v) =>formatData.append("countries[]", v.value))
    //formatData.append("published_at", dateFormatUTC([data.date, data.time, data.ampm]));

    if (newId) {
      if (data.image_intern_url[0] !== "h") {
        formatData.append("image_intern_url", data.image_intern_url[0]);
      }

      if (data.image_home_url[0] !== "h") {
        formatData.append("image_home_url", data.image_home_url[0]);
      }

      setTextSucces("editado");

      dispatch(actEditNew(newId, formatData, handleSuccesCreateEdit));
    } else {
      formatData.append("image_intern_url", data.image_intern_url[0]);
      formatData.append("image_home_url", data.image_home_url[0]);
      setTextSucces("creado");
      dispatch(actCreateNew(formatData, handleSuccesCreateEdit));
    }
  }, []);

  const loadData = (data) => {
    setValue("name", data.data.name);
    setValue("author", data.data.author);
    setValue("country_id", data.data.countries?.map((v) => {
      return { label: v.name, value: v.id };
    }));
    setValue("index", data.data.index);
    setValue("image_intern_url", data.data.image_intern_url);
    setValue("image_home_url", data.data.image_home_url);
    setValue("text", data.data.text);
    setValue("status", data.data.status);
    setValue("date", data.data.published_at);
    setValue("time", data.data.published_at);
    setValue(
      "ampm",
      parseInt(data.data.published_at.split(" ")[0].split(":")[0]) > 12 ? "pm" : "am"
    );
  };

  const loadTotal = (data) => {
    setValue("total", data.data);
    setTotalNews(data.data);
  };

  useEffect(() => {
    actTotalNews(loadTotal);

    if (newId) {
      actGetNew(newId, loadData);
    } else {
      actTotalNews((data) => {
        setValue("index", parseInt(data.data + 1));
      });
    }
  }, []);

  return {
    control,
    showModal,
    saving,
    handleCloseModal,
    handleSubmit,
    onSubmit,
    getValues,
    textSucces,
    nameNew,
  };
};

export default newsCreate;
