// Packages
import React from "react";

import useComponents from "../../components";
import useLayouts from "../../layouts";

// Controllers
import useControllers from "../../../controllers";

const Login = () => {
  const { Wrapper, TextFieldControlled, useButtons, Typography, FormLabel } = useComponents();
  const { PublicContentLayout } = useLayouts();
  const { DefaultButton } = useButtons();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useLogin } = useScreenHooks();
  const { control, handleSubmit, onSubmit, handleForgot } = useLogin();

  return (
    <PublicContentLayout login>
      <Wrapper showLogo={true} description={""} maxWidth={"582px"} className="container" title={'Bienvenido a Membeer'}>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <TextFieldControlled
            id={"email"}
            name={"email"}
            label={"Email"}
            type={"email"}
            placeholder={"mail@mail.com"}
            control={control}
          />
          <FormLabel
            label={
              <div className="text-center cursor-pointer mt-2 flex items-center justify-between">
                <Typography variant="caption" sx={{ fontWeight: 400 }}>
                  Contraseña
                </Typography>
                <Typography
                  variant="caption"
                  className="hover:text-primary underline"
                  sx={{ fontSize: "12px", fontWeight: 300, lineHeight: "17px" }}
                  onClick={() => handleForgot()}
                >
                  ¿Olvidaste tu contraseña?
                </Typography>
              </div>
            }
          />
          <TextFieldControlled
            id="password"
            name={"password"}
            type={"password"}
            placeholder={"********"}
            control={control}
          />
          <div className="text-center mt-4">
            <DefaultButton type="submit" color="secondary">
              Continuar
            </DefaultButton>
          </div>
        </form>
      </Wrapper>
    </PublicContentLayout>
  );
};

export default Login;
