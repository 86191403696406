// Packages
import React from "react";
import PropTypes from "prop-types";

// Assets
import useIcons from "../../../assets/icons";

// Styles
import { StyledSearch } from "./TextFieldSeach.styles";

// Components
import TextFieldControlled from "../TextFieldControlled";

const TextFieldSeach = ({
  control,
  label,
  type,
  helperText,
  disabled = false,
  tooltipTitle,
  onInputChange = () => {},
}) => {
  const { IconSearch } = useIcons();

  return (
    <StyledSearch>
      <TextFieldControlled
        label={label}
        disabled={disabled}
        type={type}
        helperText={helperText}
        tooltipTitle={tooltipTitle}
        placeholder="Buscar"
        name="search"
        id="search"
        control={control}
        onInputChange={onInputChange}
      />
      <button type="submit">
        <IconSearch />
      </button>
    </StyledSearch>
  );
};

TextFieldSeach.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  helperText: PropTypes.string,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  inputValue: PropTypes.string,
  tooltipTitle: PropTypes.string,
  placeholder: PropTypes.string,
};

export default TextFieldSeach;
