// Packages
import React from 'react';
import PropTypes from 'prop-types'
import PlacesAutocomplete from 'react-places-autocomplete';

// Styles
import { StyledDropdownContainer, StyledInput } from './PlaceAutocompleteField.styles';

// Controllers
import useControllers from '../../../controllers';

const PlaceAutocompleteField = (props) => {
  const { handleSelect, inputId, searchOptions, defaultValue } = props;

  // Controllers
  const { useComponentHooks } = useControllers();
  const { usePlaceAutocompleteField } = useComponentHooks();
  const { addressValue, handleChange } = usePlaceAutocompleteField(defaultValue);

  const auxOnSelect = (address) => {
    handleChange(address)
    handleSelect && handleSelect(address)
  }
  const auxOnChange = (string) => {
    handleChange(string)
  }

  return (
    <div>
      <PlacesAutocomplete
        value={addressValue}
        onChange={auxOnChange}
        onSelect={auxOnSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <StyledInput
                {...getInputProps({
                  id: inputId,
                  label :"Dirección / Local",
                  placeholder: 'Buscar Lugar / Direccion...',
                  className: 'location-search-input',
              })}
            />
            <StyledDropdownContainer>
              { loading && 
                <li><span>Cargando...</span></li>
              }
              {suggestions.map((suggestion, index) => {
                return (
                  <li
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                      className: suggestion.active ? "active" : "",
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </li>
                );
              })}
            </StyledDropdownContainer>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  )
}

PlaceAutocompleteField.prototype = {
  inputId: PropTypes.string,
  defaultValue: PropTypes.string,
  //visit this link to know what options you can send https://www.npmjs.com/package/react-places-autocomplete#searchOptions
  searchOptions: PropTypes.object,
}

export default PlaceAutocompleteField;