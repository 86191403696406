// Packages
import styled from "styled-components";
import tw from "twin.macro";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled as styledMui } from '@mui/material/styles';

import bgChart from "../../../../assets/images/bg-chart-dash.png";

export const StyledWrapperForm = styled.div.attrs({
  className: "StyledWrapperForm",
})`
  ${tw`w-64`}
  .MuiInputBase-formControl, .MuiFormControl-root {
    ${tw` m-0`}
  }
`;

export const StyledWrapperDateForm = styled.div.attrs({
  className: "StyledWrapperDateForm",
})`
  ${tw`w-36`}
  .MuiInputBase-formControl, .MuiFormControl-root {
    ${tw` m-0`}
  }
`;

export const StyledGridDash = styled.div.attrs({
  className: "StyledGridDash",
})`
  ${tw` grid gap-x-4 pb-2 items-end`}
  grid-template-columns: 143px 500px 64px 256px 256px;
  overflow: auto;
  
  .w-80 {
    width: 80px;
  }
  @media (min-width: 1920px) {
    ${tw`justify-center`}
  }
`;

export const StyledChartDegra = styled.div.attrs({
  className: "StyledChartDegra",
})`
  ${tw`grid mt-3 justify-center items-center relative`}
  grid-template-rows: repeat(5, 76px);
  background-image: url(${bgChart});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .h-76 {
    height: 76px;
  }
  img {
    z-index: -1;
    width: 516px;
    height: 380px;
    top: 12px;
  }
  div {
    width: 500px;
  }
  p {
    ${tw` text-center uppercase text-sm m-0`}
  }
`;

export const StyledGridRow = styled.div.attrs({
  className: "StyledGridRow",
})`
  ${tw`grid mt-3 justify-center items-center`}
  grid-template-rows: repeat(5, 76px);
  .h-56 {
    height: 56px;
  }
  .h-76 {
    height: 76px;
  }
  .abi-rate-convertion {
    ${tw`relative border-b border-neutral-70 border-dashed`}
    width: 143px;
    height: 76px;
    p {
      ${tw`rounded absolute border p-1 mt-20 bg-white inset-x-0 mx-auto flex justify-center items-center space-x-4`}
      bottom: -18px;
      width: 55px;
    }
  }
`;

export const BootstrapTooltip = styledMui(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '20px',
    letterSpacing: '-0.006em',
    padding: '8px 12px',
    borderRadius: '8px',
  },
}));
