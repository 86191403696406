const useProjectTypes = () => {
  const CREATE = "CREATE";
  const ADD_MISSION = "ADD_MISSION";

  return {
    CREATE,
    ADD_MISSION,
  };
};

export default useProjectTypes;
