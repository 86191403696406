import React, { useMemo } from "react";
import { Route, Routes, useLocation, Outlet } from "react-router-dom";
import useLayouts from "../../layouts";
import useControllers from "../../../controllers";

// Views
import UsersViews from "./Views";
import UsersCreate from "./Create";
import UsersEdit from "./Edit";

const UsersLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const location = useLocation();
  const { useUsers } = useControllers().useScreenHooks();
  const { handleBack, header } = useUsers();

  const pathWithOutId = useMemo(() => {
    const pathArray = location.pathname.split("/");
    const filterArray = pathArray.filter((path) => isNaN(path));
    return filterArray.join("/");
  }, [location.pathname]);

  return (
    <PrivateContentLayout
      breadcrumbsList={
        location.pathname.split("/").length > 2
          ? header[Object.keys(header).filter((header) => location.pathname.includes(header))[1]]
          : header[Object.keys(header).filter((header) => location.pathname.includes(header))]
      }
      handleBack={handleBack}
      showBack={location.pathname !== "/usuarios"}
      title="Usuarios administradores"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Users = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersLayout />}>
        <Route index element={<UsersViews />} />
        <Route path="creacion" element={<UsersCreate />} />
        <Route path="editar/:id" element={<UsersEdit />} />
      </Route>
    </Routes>
  );
};

export default Users;
