// Packages
import styled from "styled-components";
import tw from "twin.macro";
import { FormControl } from '@mui/material';

export const StyledFormControl = styled(FormControl)`  
.MuiInputAdornment-root {
  ${tw`absolute`}
  right: 16px;
  top: 33px;
}

.MuiButtonBase-root {
  padding: 2px;
}

.MuiSvgIcon-root {
  fill: #6D7074;
}

.MuiFormControlLabel-root {
  margin-bottom: 1.75rem;
  justify-content: ${(props) => {return props.justifyContent ? props.justifyContent : 'flex-start'}};
}

// Reset placeholder
label[data-shrink=false] + .MuiInputBase-formControl {
  .MuiInput-input {
    &::placeholder {
      opacity:1!important;
    }
    &:-ms-input-placeholder {
      opacity:1!important;
    }
    &::-webkit-input-placeholder {
      opacity:1!important;
    }
  }
}
`
export const StyledHelperText = styled.span.attrs({
className: "StyledHelperText"
})`
${tw`text-xs font-light text-danger -mt-6 w-full`}
`