// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledContentButtons = styled.div.attrs({
  className: "StyledContentButtons",
})`
  ${tw`flex justify-end items-center`}

  button {
    margin-left: 14px;
  }
`;
