// Packages
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useActions from "../../../../api/actions";
import useComponents from "../../../../views/components";

const useCreateQuestions = () => {
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast } = useQuickFunctions();
  const { success, error } = useToast();
  const { questionValidationShema } = useValidators();
  const { navigateTo } = useNavigation();
  const [draftRef, setDraftRef] = useState(null);

  // Actions
  const { useQuestionsActions, dispatch } = useActions();
  const { actCreateQuestion } = useQuestionsActions();

  const { Toast } = useComponents();

  const { control, handleSubmit, watch } = useForm({
    mode: "all",
    resolver: yupResolver(questionValidationShema),
    defaultValues: {
      required: false,
      in_random_order: false,
    },
  });

  const questionType = watch("question_type");

  const handleCreateSuccess = () => {
    success(<Toast text={"¡Pregunta creada con éxito!"} />, {
      toastId: 0,
    });
    navigateTo("/qualitychecks");
  };

  const handleCreateError = () => {
    error(<Toast text={"Check de respuesta correcta obligatorio"} />, {
      toastId: 0,
    });
  };

  const onSubmit = (data) => {
    const title = data?.title?.replace(/(<([^>]+)>)/gi, "");
    const form = new FormData();
    form.append("question_type", data?.question_type);
    form.append("title", title);
    form.append("html", data?.title);
    form.append("required", data?.required);
    form.append("order", 1);
    form.append("in_random_order", data?.in_random_order ? 1 : 0);
    form.append("add_other_field", data?.add_other_field ? 1 : 0);
    form.append("quality_check", 1);
    form.append("survey_section_id", null);
    data?.options?.map(({ name, is_correct, file }, i) => {
      form.append(`options[${i}][name]`, name);
      form.append(`options[${i}][is_correct]`, is_correct ? 1 : 0);
      file && form.append(`options[${i}][file]`, file[0]);
    });
    data?.file?.map((file, i) => {
      form.append(`files[${i}]`, file);
    });

    data?.options?.some(({ is_correct }) => is_correct)
      ? dispatch(
          actCreateQuestion(
            { data: form },
            handleCreateSuccess
          )
        )
      : handleCreateError();
  };

  return {
    control,
    questionType,
    handleSubmit,
    onSubmit,
    setDraftRef,
  };
};

export default useCreateQuestions;
