export const AI_TOOL_DESCRIPTION = "Encuesta generada por inteligencia artificial";

export const AI_SECTION_TITLE = "Sección única";
export const AI_SECTION_DESCRIPTION = "Sección generada por inteligencia artificial";

export const AI_PROMPT_ID = "ai-prompt";
export const AI_PROMPT_LABEL = "Escribe las instrucciones para generar la encuesta con IA";
export const AI_PROMPT_MAX_LENGTH = 300;

export const AI_PROMPT_EXAMPLES = [
  "Ejemplo: Genera una encuesta para evaluar la satisfacción de los usuarios",
];

export const ERRORS = {
  "0x1": "Hay una falla del lado del servidor. Contacta al equipo de soporte para solucionarlo.",
  "0x2": "El sistema puede estar recibiendo demasiadas solicitudes. Espera un poco e inténtalo de nuevo.",
  "I0x1": "El prompt no puede estar vacío. Escribe las instrucciones e inténtalo de nuevo.",
  "I0x2": "El prompt que introdujiste es inválido. Revísalo e inténtalo de nuevo.",
  GENERAL_ERROR: "Ha ocurrido un error al generar la encuesta. Inténtalo de nuevo.",
};

export const QUESTION_TYPES = {
  Text: {
    en: "Text",
    es: "Texto",
  },
  Binary: {
    en: "Binary",
    es: "Si/No",
  },
  Radio: {
    en: "Radio",
    es: "Selección única",
  },
  Ranking: {
    en: "Ranking",
    es: "Ranking",
  },
  Checkbox: {
    en: "Checkbox",
    es: "Selección múltiple",
  },
  Range: {
    en: "Range",
    es: "Rango de valor",
  },
};

export const QUESTION_TYPES_WITH_OPTIONS = [
  QUESTION_TYPES.Radio.en,
  QUESTION_TYPES.Ranking.en,
  QUESTION_TYPES.Checkbox.en,
];

export const PATHS_WITH_COMPRESSED_NAVBAR = [
  "/herramientas/encuesta/ia",
];