// Packages
import styled from "styled-components";
import tw from "twin.macro";
import { FormControl, Autocomplete } from "@mui/material";

export const StyledFormControl = styled(FormControl)`
  && {
    .MuiInput-root {
      ${tw`bg-neutral-30 border border-neutral-60 rounded`}
    }

    .MuiInputBase-input {
      ${tw`p-8`}
    }

    .MuiChip-root {
      ${tw`bg-primary`}
      svg {
        fill: white;
      }
    }
    .MuiAutocomplete-endAdornment {
      svg {
        fill: black;
      }
    }
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  && {
    .MuiInput-root {
      padding-right: 0!important;

      .MuiInputBase-input {
        ${tw`px-4 mt-0 border-0 rounded-none`}
        padding-bottom: 9.5px; 
        padding-top: 9.5px; 
      }
    }

    .MuiAutocomplete-endAdornment {
      right: 12px;
      top: 20px;

      .MuiButtonBase-root {
        transform: none;
    }
  }
`;

export const StyledHelperText = styled.span.attrs({
  className: "StyledHelperText",
})`
  ${tw`absolute left-0 text-xs font-light text-danger bottom-0`}
`;
