import useApi from "../../index";
import useTypes from "../../../strings/types";

const useUsersActions = () => {
  // Services
  const { useServices } = useApi();
  const { useUsersServices } = useServices();
  const { addUsersService, editUserService, listUserService, listRolesService, deleteUserService } =
    useUsersServices();

  // Strings
  const { useUsersTypes } = useTypes();
  const { ADD_USER, REMOVE_USER } = useUsersTypes();

  const actSelectUser =
    ({ user }) =>
    (dispatch) => {
      dispatch({
        type: ADD_USER,
        payload: user,
      });
    };

  const actARemoveUser = () => () => {
    dispatch({
      type: REMOVE_USER,
    });
  };

  const actListUsers = async ({ page, country_id, search, rol_id, status }, onSuccess, onError) => {
    try {
      const response = await listUserService({ page, country_id, search, rol_id, status });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListRoles = async (onSuccess, onError) => {
    try {
      const response = await listRolesService();
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actAddUsers = async (data, onSuccess, onError) => {
    try {
      const response = await addUsersService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      console.log(error);
      onError && onError();
    }
  };

  const actEditUsers = async (id, data, onSuccess, onError) => {
    try {
      const response = await editUserService(id, data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actDeleteUsers = async (id, onSuccess, onError) => {
    try {
      const response = await deleteUserService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  return {
    actSelectUser,
    actARemoveUser,
    actListUsers,
    actAddUsers,
    actEditUsers,
    actListRoles,
    actDeleteUsers,
  };
};

export default useUsersActions;
