// Packages
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledContentCloseUser = styled.div.attrs({
  className: "StyledContentCloseUser"
})`
  ${tw`flex items-center justify-start space-x-2`}

  .MuiFormGroup-root {
    label {
    ${tw`m-0`}
    }
  }
  .MuiFormControl-root  {
    width: auto;
  }
  .MuiInput-root {
    ${tw`m-0`}
    width: 80px;
    input {
    ${tw`text-center`}
    }
  }
`

export const StyledMultipleChoiceAddButton = styled.div.attrs({
  className: "StyledMultipleChoiceAddButton"
})`
  ${tw`flex items-start mt-2`}

  button {
    ${tw`bg-third flex justify-center items-center rounded`}
    height: 44px;
    width: 44px;
  }
`