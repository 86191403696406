import { useCallback, useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useModels from "../../../../models";
import useHelpers from "../../../../helpers";

import useGeneralActions from "../../../../api/actions/general";
import { useParams } from "react-router-dom";

import useApi from "../../../../api";

const useProjectsEdit = () => {
  const { projectId } = useParams();

  //Actions
  const { useActions } = useApi();
  const { dispatch, useProjectsActions } = useActions();
  const { actSeletedProject } = useProjectsActions();

  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();

  const { rol: role } = useSelector(session);

  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { newProjectSchema } = useValidators();

  const { actListCountries } = useGeneralActions();

  const [listCountries, setListCountries] = useState();

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(newProjectSchema),
  });

  const onSubmit = useCallback((data) => {
    /* console.log(getValues);
    console.log(data); */
  }, []);

  const dataListCountries = (data) => {
    setListCountries(
      _.map(data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  useEffect(() => {
    actListCountries(dataListCountries);
  }, []);

  const handleLoadData = (data) => {
    const listActveCountries = _.map(data.countries, (v) => {
      return v.id;
    });
    setValue("name", data.name);
    setValue("country_id", listActveCountries);
    setValue("client_id", data.client_id);
    setValue("expiration_date", data.expiration_date);
    setValue("objective", data.objective);
    setValue("decision", data.decision);
  };

  useEffect(() => {
    dispatch(actSeletedProject(projectId, handleLoadData));
  }, [projectId]);

  return {
    listCountries,
    control,
    role,
    handleSubmit,
    onSubmit,
    errors,
    getValues,
  };
};

export default useProjectsEdit;
