// Packages
import React from 'react';

// Components
import useComponents from '..';

const AboutFileResponse = (props) => {
  const { control } = props;
  const { InputFileControlled } = useComponents();

  return (
    <div>
      <InputFileControlled 
        id="file"
        name="file"
        control={control}
        label="Cargar archivo"
        placeholder="Selecciona un archivo"
        showPreview
        setCustomSize
      />
    </div>
  )
}

export default AboutFileResponse;