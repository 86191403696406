// Packages
import React from "react";

// Styles
import { StyleLoading } from "./GamificationView.styles";

// import useAssets from '../../../assets'
import { CircularProgress } from "@mui/material";

// Components
import useComponents from "../../../components";
import useComponentsProfiler from "./tables";

// Controllers
import useControllers from "../../../../controllers";

const GamificationView = () => {

  const {
    TableContent,
    TableRow,
    useButtons,
    useAccordion,
    Pagination,
    Dropdown,
    useModals,
    Typography,
  } = useComponents();

  const { TableOnlyName, TableMission, TableImage, TableCharacter } = useComponentsProfiler();

  const { AccordionContent, AccordionHeader, AccordionBody } = useAccordion();

  const { DefaultButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useGamification } = useScreenHooks();
  const { useGamificationView } = useGamification();

  const {
    listGamificationType,
    listGamifications,
    loading,
    handleCreateNewGamification,
    handleEditGamification,
    handlePagination,
    paginationSettings,
    onExpand,
    expanded,
    handleDeleteGamification,
    modalDelete,
    handleCloseModalDelete,
    handleConfirmDelete,
  } = useGamificationView();

  const renderSwitch = (type, item) => {
    switch (type) {
      case 0:
        return <TableOnlyName item={item} type={type}/>;
      case 1:
        return <TableOnlyName item={item} type={type} />;
      case 2:
        return <TableMission item={item} />;
      case 3:
        return <TableCharacter item={item} />;
      case 4:
        return <TableOnlyName item={item} type={type} />;
      case 5:
        return <TableImage item={item} />;
    }
  };

  return (
    <div>
      {listGamificationType.map((gamification, index) => {
        return (
          <div key={index}>
            <AccordionContent type="secondary" expanded={gamification.type === expanded}>
              <AccordionHeader
                arrowColor="#FFA700"
                borderExpanded={true}
                onExpand={onExpand}
                profiler={gamification.type}
              >
                <div className="flex justify-between w-full px-5">
                  <h4 className="uppercase text-base font-oswald mt-2.5">{gamification.title}</h4>
                  <DefaultButton
                    color="secondary"
                    onClick={() => {
                      handleCreateNewGamification(gamification);
                    }}
                  >
                    Crear nuevo
                  </DefaultButton>
                </div>
              </AccordionHeader>
              <AccordionBody>
                {loading && (
                  <StyleLoading
                    style={{ color: "red !important", display: "flex", justifyContent: "center" }}
                  >
                    <CircularProgress />
                  </StyleLoading>
                )}
                {!loading && (
                  <>
                    <TableContent
                      headerCellList={[...gamification.columnsTitle]}
                      gridCell={[...gamification.cellSize]}
                    >
                      {listGamifications.length > 0 ? (
                        listGamifications.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              {renderSwitch(gamification.type, item)}
                              <div className="flex justify-end">
                                <Dropdown options={[
                                  {id: 'edit', action: ()=> handleEditGamification(item.id, gamification)},
                                  {id: 'delete', action: ()=> handleDeleteGamification(item, gamification), show: gamification.type === 5},
                                  ]}/>
                              </div>
                            </TableRow>
                          );
                        })
                      ) : (
                        <div className="text-center py-2">Sin datos para mostrar</div>
                      )}
                    </TableContent>
                    {(gamification.type >= 3) && (
                      <Pagination
                        onPageChange={handlePagination}
                        pageCount={paginationSettings.totalPages}
                      />
                    )}
                  </>
                )}
              </AccordionBody>
            </AccordionContent>
          </div>
        );
      })}
      <DefaultModal isOpen={modalDelete.show}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            Esta seguro de eliminar el accesorio <b>{modalDelete.name}</b>.
          </Typography>
          <div className="flex items-center space-x-4">
            <DefaultButton color="secondary" onClick={handleCloseModalDelete}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="success" onClick={handleConfirmDelete}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default GamificationView;
