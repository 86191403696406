import useQuestions from "./Questions/useQuestions";
import useSections from "./Sections/useSections";
import useCreateQuestions from "./Questions/useCreateQuestions";
import useConditionals from "./Conditionals/useConditionals";
import useSelectComponent from "./Conditionals/useSelectComponent";

const useSurvey = () => {
  return {
    useQuestions,
    useSections,
    useCreateQuestions,
    useConditionals,
    useSelectComponent,
  };
};

export default useSurvey;
