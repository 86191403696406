import { WindowSharp } from "@mui/icons-material";

const useGeneral = () => {
  const convertDateForIos = (date) => {
    const arr = date.split(/[- : T .]/);
    date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return date;
  };
  // convert date in whatever format that you want
  const formatDate = (date, format = "Y-mm-dd", convert = true) => {
    if (convert) date = new Date(convertDateForIos(date));

    const map = {
      dd: date.getDate().toString().padStart(2, "0"),
      mm: date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1),
      Y: date.getFullYear(),
      yy: date.getFullYear().toString().slice(-2),
      HH: date.getHours() > 9 ? date.getHours() : "0" + date.getHours(),
      MM: date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes(),
      SS: date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds(),
    };

    return format.replace(/dd|mm|yy|Y|HH|MM|SS/gi, (matched) => map[matched]);
  };

  // convert date to this string format (yyyy-mm-dd hh:mm:ss) to send to the back
  const dateFormatUTC = (FieldsDate) => {
    const date = formatDate(new Date(FieldsDate[0]), "Y-mm-dd", false);
    const time = new Date(FieldsDate[1]);
    const hour =
      FieldsDate[2] === "pm" && time.getHours() < 12
        ? time.getHours() + 12
        : time.getHours() > 12 && FieldsDate[2] === "am"
        ? time.getHours() - 12
        : time.getHours();

    return `${date} ${hour.toString().padStart(2, "0")}:${time
      .getMinutes()
      .toString()
      .padStart(2, "0")}:00`;
  };

  const getAmPmFormat = (date) => {
    const time = new Date(date);
    const hour = time.getHours();

    return hour >= 12 ? "pm" : "am";
  };

  const openApp = () => {
    window.location = "membeer://";
  };
  return { dateFormatUTC, formatDate, getAmPmFormat, openApp };
};

export default useGeneral;

