import styled from "styled-components";
import tw from "twin.macro";

export const StyledHeader = styled.header.attrs({
  className: "StyledHeader",
})`
  ${tw`bg-neutral-40 mb-8`}
  min-height: 80px;
`;

export const StyledUserInfo = styled.div.attrs({
  className: "StyledUserInfo",
})`
  ${tw`flex p-4 justify-between items-center relative z-10`}

  &:before {
    content: "";
    ${tw`absolute  bottom-0 right-0`}
    background: linear-gradient(90deg, #e5e5e5 1.55%, #d3a238 14.48%, #f5e003 99.31%);
    height: 1px;
    max-width: 1885px;
    width: 96%;
  }

  .user-name {
    ${tw`text-sm block text-neutral`}
    letter-spacing: -0.006em;
  }
  .user-role {
    ${tw`text-neutral-80 text-xs`}
  }

  .sb-avatar__text {
    ${tw`rounded-lg`}
  }
  .user-image {
    ${tw`ml-4`}

    img {
      ${tw`rounded-lg`}
    }
  }
`;

export const StyledPageInfo = styled.div.attrs({
  className: "StyledPageInfo",
})`
  ${tw``}
  padding: 14px 18px 14px 2.75rem;
`;

export const StyledBreadcrumbs = styled.ul.attrs({
  className: "StyledBreadcrumbs",
})`
  ${tw`flex items-center`}

  li {
    ${tw`relative flex items-center`}
    margin-right: 10px;

    &:before {
      ${tw`block rounded-full bg-neutral-80`}
      margin-right: 10px;
      content: "";
      height: 4px;
      width: 4px;
    }

    h4 {
      ${tw`uppercase text-neutral-80`}
    }

    &:first-child {
      &:before {
        content: none;
      }
    }

    &:last-child {
      h4 {
        ${tw`text-xl text-neutral`}
      }
      &:before {
        ${tw`bg-primary`}
      }
    }
  }
`;

export const StyledNavBar = styled.div.attrs({
  className: "StyledNavBar",
})`
  && {
    ${tw` relative flex flex-row space-x-3.5 `}
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledLogoContainer = styled.div.attrs({
  className: "StyledLogoContainer",
})`
  && {
    ${tw` relative cursor-pointer`}
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledInnerContentChildren = styled.div.attrs({
  className: "StyledInnerContentChildren",
})`
  min-height: calc(91.5% - (112px + 72px));
  width: 100%;
  overflow: auto;
  padding-top: 0.75rem;
`;

export const StyledInnerContentBackuser = styled.div.attrs({
  className: "StyledInnerContentBackuser",
})`
  ${tw`relative `}
  display: grid;
  grid-template-columns: 194px auto;
  @media (max-width: 768px) {
    display: flex;
  }
`;
export const StyledContainerBack = styled.div`
  ${tw` flex items-center ml-4 space-x-1 cursor-pointer z-10`}

  span {
    ${tw`font-bold text-secondary`}
  }

  svg {
    margin-top: -3px;
  }
`;

export const StyledNotification = styled.div.attrs({
  className: "StyledNotification",
})`
  ${tw`py-4 px-2 right-0 transition-all ease-linear mt-2`}
  min-width: 220px;
  .container-message:not(:first-child)  {
    ${tw`mt-2 pt-1 border-t`}
  }
`;