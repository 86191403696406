const useNotificationsTypes = () => {
  const LIST_NOTIFICATIONS = 'LIST_NOTIFICATIONS';
  const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

  return {
    LIST_NOTIFICATIONS,
    ADD_NOTIFICATION
  }
};

export default useNotificationsTypes;