import React from "react";

// Components
import useComponents from "../../../../../components";

// Controllers
import useControllers from "../../../../../../controllers";

const FormGamificationDetails = () => {
  const {
    ContentWrappers,
    useButtons,
    TextFieldControlled,
    SelectFieldControlled,
    Typography,
    InputFileControlled,
    InputSwitchControlled
  } = useComponents();

  const { DefaultButton } = useButtons();

  const { useScreenHooks } = useControllers();
  const { useGamification } = useScreenHooks();
  const { useGamificationCreate } = useGamification();

  const {
    title,
    type,
    control,
    listBadges,
    listTierFilter,
    listCategory,
    handleSubmit,
    onSubmit,
    saving,
    handleGetTierFilter,
  } = useGamificationCreate();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3" className="uppercase">
        1. Configuración general de {title}
      </Typography>
      <ContentWrappers
        gridColums={["1fr", "1fr"]}
        gap={{
          row: "0px",
          column: "16px",
        }}
        className="mt-4 items-start"
      >
        <SelectFieldControlled
          label="Insignia"
          name="badge_id"
          id="badge_id"
          control={control}
          options={listBadges}
          onChangeField={handleGetTierFilter}
        />
        <SelectFieldControlled
          label="Tier"
          name="tier_id"
          id="tier_id"
          control={control}
          options={listTierFilter}
        />
      </ContentWrappers>
      <Typography variant="h3" className="uppercase">
        2. Asignación de valores
      </Typography>
      {type === 2 && (
        <>
          <ContentWrappers
            gridColums={["1fr", "1fr"]}
            gap={{
              row: "0px",
              column: "16px",
            }}
            className="mt-4 items-start"
          >
            <TextFieldControlled
              label="Misión mínima"
              type="number"
              name="missions_min"
              id="missions_min"
              control={control}
            />
            <TextFieldControlled
              label="Misión máxima"
              type="number"
              name="missions_max"
              id="missions_max"
              control={control}
            />
          </ContentWrappers>
        </>
      )}
      {type === 3 && (
        <>
        <ContentWrappers
          gridColums={["1fr", "1fr"]}
          gap={{
            row: "0px",
            column: "16px",
          }}
          className="mt-4 items-start"
        >
          <TextFieldControlled
            label="Nombre del personaje"
            type="text"
            name="name"
            id="name"
            control={control}
          />
        </ContentWrappers>
        <Typography variant="h3" className="uppercase">
            3. Imagenes
          </Typography>
          <ContentWrappers
            gridColums={["1fr", "1fr"]}
            gap={{
              row: "0px",
              column: "16px",
            }}
            className="mt-4 items-start"
          >
            <InputFileControlled
              label={"Agregar accesorio (svg)"}
              placeholder={"image.jpg"}
              name="file"
              id="file"
              accept=".svg"
              control={control}
              directPreview
              maxFiles={1}
              showPreview
            />
            <InputFileControlled
              label={"Agregar preview (svg)"}
              placeholder={"image.jpg"}
              name="file_preview"
              id="file_preview"
              accept=".svg"
              control={control}
              directPreview
              maxFiles={1}
              showPreview
            />
          </ContentWrappers>
          <Typography variant="h3" className="uppercase">
            4. Configuraciones
          </Typography>
          <InputSwitchControlled
            label="Ocultar Personaje"
            name="hidden"
            id="hidden"
            control={control}
          />
          <InputSwitchControlled
            label="Imagen por defecto"
            name="default"
            id="default"
            control={control}
          />
        </>
      )}
      {type === 5 && (
        <>
        <ContentWrappers
          gridColums={["1fr", "1fr"]}
          gap={{
            row: "0px",
            column: "16px",
          }}
          className="mt-4 items-start"
        >
          <TextFieldControlled
            label="Nombre del accesorio"
            type="text"
            name="name"
            id="name"
            control={control}
          />
          <SelectFieldControlled
            label="Categoría"
            name="category_id"
            id="category_id"
            control={control}
            options={listCategory}
          />
        </ContentWrappers>
          <Typography variant="h3" className="uppercase">
            3. Imagenes
          </Typography>
          <ContentWrappers
            gridColums={["1fr", "1fr"]}
            gap={{
              row: "0px",
              column: "16px",
            }}
            className="mt-4 items-start"
          >
            <InputFileControlled
              label={"Agregar accesorio (svg)"}
              placeholder={"image.jpg"}
              name="file"
              id="file"
              accept=".svg"
              control={control}
              directPreview
              maxFiles={1}
              showPreview
            />
            <InputFileControlled
              label={"Agregar preview (svg)"}
              placeholder={"image.jpg"}
              name="file_preview"
              id="file_preview"
              accept=".svg"
              control={control}
              directPreview
              maxFiles={1}
              showPreview
            />
          </ContentWrappers>
          <Typography variant="h3" className="uppercase">
            4. Configuraciones
          </Typography>
          <InputSwitchControlled
            label="Ocultar Accesorio"
            name="hidden"
            id="hidden"
            control={control}
          />
          <InputSwitchControlled
            label="Requiere color"
            name="applyColor"
            id="applyColor"
            control={control}
          />
          <InputSwitchControlled
            label="Imagen por defecto"
            name="default"
            id="default"
            control={control}
          />
          <InputSwitchControlled
            label="Es un marco"
            name="frame"
            id="frame"
            control={control}
          />
        </>
      )}
      <div className="text-center mt-4">
        <DefaultButton color="secondary" type="submit" disabled={saving}>
          Finalizar y publicar
        </DefaultButton>
      </div>
    </form>
  );
};

export default FormGamificationDetails;
