import useServices from "../../services";

const useBrandsActions = () => {
  const { useBrandsServices } = useServices();
  const {
    listBrandsService,
    listBrandPaginateService,
    createBrandService,
    getBrandService,
    editBrandService,
  } = useBrandsServices();

  const actListBrands = async (onSuccess, onError) => {
    try {
      const response = await listBrandsService();
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actListBrandPaginate = async (
    { page, search, status, init_date, end_date },
    onSuccess,
    onError
  ) => {
    try {
      const response = await listBrandPaginateService({
        page,
        search,
        status,
        init_date,
        end_date,
      });

      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actCreateBrand = async (data, onSuccess, onError) => {
    try {
      const response = await createBrandService(data);

      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actGetBrand = async (id, onSuccess, onError) => {
    try {
      const response = await getBrandService(id);

      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };
  const actEditBrand = (brandId, data, onSuccess, onError) => async () => {
    try {
      const response = await editBrandService(brandId, data);

      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  return { actListBrands, actListBrandPaginate, actCreateBrand, actGetBrand, actEditBrand };
};
export default useBrandsActions;
