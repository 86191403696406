import { useCallback, useState, useEffect } from "react";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useHelpers from "../../../../helpers";

import useApi from "../../../../api";

import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";

const countrieCreate = () => {
  const navigation = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [listCountriesFree, setListCountriesFree] = useState([]);
  const [listCountriesFreeCompleteData, setListCountriesFreeCompleteData] = useState([]);
  const [listLanguages, setListLanguages] = useState([]);
  const [listBrands, setListBrands] = useState([]);
  const [countrieName, setCountrieName] = useState("");
  const [textTypeOfAction, setTextTypeOfAction] = useState("creado");

  const { countrieId } = useParams();

  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { countriesSchema } = useValidators();

  //Actions
  const { useActions } = useApi();
  const { useCountriesAction, useBrandsActions, dispatch } = useActions();
  const {
    actListCountriesFree,
    actListLanguages,
    actCreateCountrie,
    actGetCountrie,
    actEditCountrie,
  } = useCountriesAction();
  const { actListBrands } = useBrandsActions();

  const defaultValuesCreate = () => {
    const defaultValuesCreateAndEdit = {
      documents: [{ type: "", acronym: "" }],
      abi_regions: [{ region: "", population_distribution: "" }],
      nse: [{ low: "", mid: "", high: "" }],
      age_distribution: [{ small: "", medium: "", normal: "", big: "" }],
      brands: [],
      active: null,
    };
    if (!countrieId) {
      return {
        ...defaultValuesCreateAndEdit,
        banks: [
          {
            name: "",
            nacionality: false,
            mobile: false,
            nit: false,
            spouse: false,
            address: false,
            pay_form: false,
          },
        ],
      };
    } else {
      return { defaultValuesCreateAndEdit };
    }
  };

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(countriesSchema),
    defaultValues: defaultValuesCreate(),
  });

  const watchFieldCountry = watch(["country_id"]);

  const {
    fields: documentFields,
    append: documentAppend,
    remove: documentRemove,
  } = useFieldArray({ control, name: "documents" });

  const {
    fields: regionFields,
    append: regionAppend,
    remove: regionRemove,
  } = useFieldArray({ control, name: "abi_regions" });

  const {
    fields: bankFields,
    append: bankAppend,
    remove: bankARemove,
  } = useFieldArray({ control, name: "banks" });

  const { fields: nseFields } = useFieldArray({ control, name: "nse" });
  const { fields: distributionFields } = useFieldArray({ control, name: "age_distribution" });

  const handleCloseModal = () => {
    setShowModal(false);
    navigation("/paises");
  };

  const handleCreateAndEdit = (data) => {
    setTextTypeOfAction(countrieId ? "editado" : "creado");
    setCountrieName(data.country.name);
    setShowModal(true);
  };

  const onSubmit = useCallback((data) => {
    if (countrieId) {
      dispatch(
        actEditCountrie(
          countrieId,
          {
            ...data,
            age_distribution: {
              "18-24": data.age_distribution[0].small,
              "25-34": data.age_distribution[0].medium,
              "35-44": data.age_distribution[0].normal,
              "45-64": data.age_distribution[0].big,
            },
            nse: {
              low: data.nse[0].low,
              mid: data.nse[0].mid,
              high: data.nse[0].high,
            },
            brands: _.map(data.brands, (v) => {
              return [v.value];
            }),
          },
          handleCreateAndEdit
        )
      );
    } else {
      dispatch(
        actCreateCountrie(
          {
            ...data,
            age_distribution: {
              "18-24": data.age_distribution[0].small,
              "25-34": data.age_distribution[0].medium,
              "35-44": data.age_distribution[0].normal,
              "45-64": data.age_distribution[0].big,
            },
            nse: {
              low: data.nse[0].low,
              mid: data.nse[0].mid,
              high: data.nse[0].high,
            },
            brands: _.map(data.brands, (v) => {
              return [v.value];
            }),
          },
          handleCreateAndEdit
        )
      );
    }
  }, []);

  const loadDataSelected = (data) => {
    setListCountriesFree(
      _.map(data.data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
    setListCountriesFreeCompleteData(data.data);
  };

  const loadDataSelectLanguages = (data) => {
    setListLanguages(
      _.map(data.data, (v) => {
        return { label: v.name, value: v.iso };
      })
    );
  };

  const loadDataBrands = (data) => {
    setListBrands(
      _.map(data.data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const handleLoadData = (data) => {
    setListCountriesFree([{ label: data.country?.name, value: data.country?.id }]);

    reset({
      country_id: data.country?.id,
      country_iso: data.country?.iso2,
      country_symbol: data.country?.currency_symbol,
      country_currency: data.country?.currency,
      currency_membeer_point:
        data.currency_membeer_point !== null ? data.currency_membeer_point : "",
      languaje: data.languaje,
      brands: _.map(data.brands, (v) => {
        return { label: v.name, value: v.id };
      }),
      active: data.active ? 1 : 0,
      documents:
        data.documents !== null &&
        data.documents.map((doc) => {
          return {
            type: doc.type,
            acronym: doc.acronym,
          };
        }),
      abi_regions:
        data.abi_regions !== null &&
        data.abi_regions.map((region) => {
          return {
            region: region.region !== null ? region.region : "",
            population_distribution:
              region.population_distribution !== null ? region.population_distribution : "",
          };
        }),
      nse: [
        {
          high: data.nse.length !== 0 ? data.nse.high : "",
          mid: data.nse.length !== 0 ? data.nse.mid : "",
          low: data.nse.length !== 0 ? data.nse.low : "",
        },
      ],
      age_distribution: [
        {
          small: data.age_distribution !== null ? data.age_distribution["18-24"] : "",
          medium: data.age_distribution !== null ? data.age_distribution["25-34"] : "",
          normal: data.age_distribution !== null ? data.age_distribution["35-44"] : "",
          big: data.age_distribution !== null ? data.age_distribution["45-64"] : "",
        },
      ],
      banks:
        data.banks ? data.banks : [],
    });
  };

  useEffect(() => {
    actListLanguages(loadDataSelectLanguages);
    actListBrands(loadDataBrands);

    if (countrieId) {
      dispatch(actGetCountrie(countrieId, handleLoadData));
    } else {
      actListCountriesFree(loadDataSelected);
    }
  }, []);

  useEffect(() => {
    if (!countrieId) {
      if (watchFieldCountry[0] !== undefined) {
        const dataCountryFree = listCountriesFreeCompleteData.find(
          (countrie) => countrie.id === watchFieldCountry[0]
        );

        setValue("country_iso", dataCountryFree?.iso2);
        setValue("country_symbol", dataCountryFree?.currency_symbol);
        setValue("country_currency", dataCountryFree?.currency_name);
      }
    }
  }, [watchFieldCountry]);

  return {
    control,
    showModal,
    handleCloseModal,
    handleSubmit,
    onSubmit,
    getValues,
    documentFields,
    documentAppend,
    documentRemove,
    regionFields,
    regionAppend,
    regionRemove,
    bankFields,
    bankAppend,
    bankARemove,
    nseFields,
    distributionFields,
    listCountriesFree,
    listLanguages,
    listBrands,
    errors,
    countrieName,
    textTypeOfAction,
    watch,
  };
};

export default countrieCreate;
