import React from "react";

import useComponents from "../../../components";

//Components
import FollowQuotas from "./Components/FollowQuotas";
import FollowMissions from "./Components/FollowMissions";
import UserEngaments from "./Components/UserEngaments";
import BudgestPayments from "./Components/BudgetsPayments";
import FollowUsers from "./Components/FollowUsers";
import useScreenHooks from "../../../../controllers/screenHooks";

const View = () => {
  const { ContentWrappers, Typography } = useComponents();

  const { useDashboardScreens } = useScreenHooks();
  const { useDashboardView } = useDashboardScreens();
  const {
    role,
    handleSubmit,
    onSubmit,
    control,
    listCountries,
    selectedCountry,
    dataQuotesData,
    dataFollowMissions,
    dataBudgestPayments,
    dataMemebers,
    followMembeers,
    handleDownloadExcel,
    printChart,
    watch,
  } = useDashboardView();

  return (
    <>
      <div className="px-8 py-10 bg-white rounded-lg w-11/12 mx-auto mb-8">
        <Typography className="pb-4 uppercase" variant="h3">
          Seguimiento de usuarios
        </Typography>
        <FollowUsers
          role={role}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          listCountries={listCountries}
          followMembeers={followMembeers}
          handleDownloadExcel={handleDownloadExcel}
          printChart={printChart}
          watch={watch}
        />
      </div>
      <ContentWrappers gridColums={["60%", "40%"]} className="w-11/12  space-x-6" as="section">
        <div className="h-full px-8 pt-10 pb-12 bg-white rounded-lg">
          <Typography className="pb-4 uppercase border-b" variant="h3">
            Seguimiento de cuotas
          </Typography>
          <FollowQuotas dataQuotesData={dataQuotesData} />
        </div>
        <div className="space-y-4">
          <div className="px-6 py-10 bg-white rounded-lg">
            <Typography className="pb-4 uppercase border-b" variant="h3">
              Seguimiento de misiones
            </Typography>
            <FollowMissions dataFollowMissions={dataFollowMissions} />
          </div>
          <div className="px-8 py-10 bg-white rounded-lg">
            <Typography className="uppercase" variant="h3">
              SEGUIMIENTO DE presupuesto & pagos
            </Typography>
            <BudgestPayments dataBudgestPayments={dataBudgestPayments} />
          </div>
          <div className="px-8 py-10 bg-white rounded-lg">
            <Typography className="uppercase" variant="h3">
              Engagement de usuarios
            </Typography>
            <UserEngaments dataMemebers={dataMemebers} countrySelect={selectedCountry} />
          </div>
        </div>
      </ContentWrappers>
    </>
  );
};

export default View;
