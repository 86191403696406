// Packages
import styled from "styled-components";
import tw from "twin.macro";

import Slider from '@mui/material/Slider';

// Label styles
export const StyledRange = styled(Slider)`
  .MuiSlider-markLabel {
    ${tw`text-sm text-neutral font-light`}
    letter-spacing: -0.006em;
  }

`;