const IconSearch = ({color = "#6D7074"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      fill="none"
      viewBox="0 0 20 19"
    >
      <path
        fill={ color }
        fillRule="evenodd"
        d="M0 8c0 4.444 3.556 8 8 8a7.98 7.98 0 005.205-1.907c.036.053.078.104.124.15l4.353 4.353a1 1 0 001.414-1.414l-4.352-4.353a1.002 1.002 0 00-.225-.169A7.99 7.99 0 0016 8c0-4.444-3.556-8-8-8S0 3.556 0 8zm2 0c0-3.467 2.533-6 6-6s6 2.533 6 6-2.533 6-6 6-6-2.533-6-6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IconSearch;
