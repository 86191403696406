// Packages
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useActions from "../../../../api/actions";
import useComponents from "../../../../views/components";

const useEditQuestions = () => {
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast } = useQuickFunctions();
  const { success, error } = useToast();
  const { questionValidationShema } = useValidators();
  const { navigateTo } = useNavigation();
  const [draftRef, setDraftRef] = useState(null);
  const [file, setFile] = useState();
  const { id } = useParams();

  const { Toast } = useComponents();
  // Actions
  const { useQuestionsActions, dispatch } = useActions();
  const { actGetQuestion, actUpdateQuestion } = useQuestionsActions();

  const { control, handleSubmit, watch, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(questionValidationShema),
    defaultValues: {
      required: false,
      in_random_order: false,
    },
  });

  const removeOthersOption = (data) => {
    return data?.question?.options
      ?.map((el) => {
        if (el.name !== "Otros") {
          return { ...el, is_correct: Boolean(parseInt(el.is_correct)) };
        }
      })
      .filter((el) => el !== undefined);
  };

  const updateQuestion = (data) => {
    setValue("question_type", data?.question_type);
    setValue("title", data?.html);
    setValue("file", data?.files[0]);
    setFile(data?.files[0]);
    setValue("add_other_field", data?.question?.add_other_field);
    setValue("in_random_order", data?.question?.in_random_order);
    data?.question?.options?.map(({ name, file }, i) => {
      setValue(`options[${i}].name`, name);
      file && setValue(`options[${i}].file`, file);
    });
    setValue("options", removeOthersOption(data));
  };

  useEffect(() => {
    dispatch(actGetQuestion(id, (data) => updateQuestion(data)));
  }, [id]);

  const questionType = watch("question_type");

  const onSuccessUpdate = () => {
    success(<Toast text={"¡Pregunta Actualizada con éxito!"} />, {
      toastId: 0,
    });
    navigateTo("/qualitychecks");
  };

  const handleCreateError = () => {
    error(<Toast text={"Check de respuesta correcta obligatorio"} />, {
      toastId: 0,
    });
  };

  const onSubmit = (data) => {
    const title = data?.title?.replace(/(<([^>]+)>)/gi, "");
    const form = new FormData();
    form.append("question_type", data?.question_type);
    form.append("title", title);
    form.append("html", data?.title);
    form.append("required", data?.required);
    form.append("order", 1);
    form.append("in_random_order", data?.in_random_order ? 1 : 0);
    form.append("add_other_field", data?.add_other_field ? 1 : 0);
    form.append("quality_check", 1);
    form.append("survey_section_id", null);
    data?.options?.map(({ name, is_correct, file }, i) => {
      form.append(`options[${i}][name]`, name);
      form.append(`options[${i}][is_correct]`, is_correct ? 1 : 0);
      file && file[0]?.path && form.append(`options[${i}][file]`, file[0]);
    });
    file !== data?.file && form.append(`files[${0}]`, data?.file[0]);
    data?.options?.some(({ is_correct }) => is_correct)
      ? dispatch(
          actUpdateQuestion(
            { id, data: form },
            onSuccessUpdate
          )
        )
      : handleCreateError();
  };

  return {
    control,
    questionType,
    handleSubmit,
    onSubmit,
    setDraftRef,
  };
};

export default useEditQuestions;
