import React from "react";
import useComponents from "../../../../../components";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

import _ from "lodash";
import useIcons from "../../../../../../assets/icons";
import useControllers from "../../../../../../controllers";

import { StyledTableCell, StyledContainerPaper } from "../FollowQuotas/TableDash.styles";

const FollowMissions = (props) => {
  const { dataFollowMissions } = props;

  const { useGeneralHooks } = useControllers();
  const { formatDate } = useGeneralHooks();

  const { Typography } = useComponents();

  return (
    <>
      <StyledContainerPaper className="h-2/5">
        <Typography variant="h4" className="uppercase pt-2">En curso</Typography>
        <TableContainer component={Paper} style={{ background: "transparent" }} className="h-56">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">
                  Nombre
                </StyledTableCell>
                <StyledTableCell align="left">
                  Fecha fin
                </StyledTableCell>
                <StyledTableCell align="left">
                % RESPUESTAS
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(dataFollowMissions?.activeMission, (itemRegion, index) => {
                return <TableRow key={index}>
                <StyledTableCell width="45%" align="left" className="word-break">{itemRegion.name}</StyledTableCell>
                <StyledTableCell className="bg-neutral-40">{formatDate(itemRegion.end_date, "dd/mm/Y, HH:MM")}</StyledTableCell>
                <StyledTableCell className="bg-neutral-40">{itemRegion.percentage}</StyledTableCell>
              </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContainerPaper>
      {/* <StyledContainerPaper className="h-2/5 mt-11">
        <Typography variant="h4" className="uppercase">Programadas</Typography>
        <TableContainer component={Paper} style={{ background: "transparent" }} className="h-full">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" width="90px">
                  Nombre
                </StyledTableCell>
                <StyledTableCell align="left">
                  Fecha Inicio
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(dataFollowMissions?.scheduledMission, (itemRegion, index) => {
                return <TableRow key={index}>
                <StyledTableCell width="50%" align="left" className="word-break">{itemRegion.name}</StyledTableCell>
                <StyledTableCell className="bg-neutral-40">{formatDate(itemRegion.init_date, "dd-mm-Y")}</StyledTableCell>
              </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContainerPaper> */}
    </>
  );
};

export default FollowMissions;
