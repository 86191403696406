import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";
import brandsView from "./View";
import brandsCreate from "./Create";

const useBrandsScreen = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const [headerAux, setHeaderAux] = useState({});

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/marcas": ["marcas"],
    "/marcas/crear": ["marcas", "crear marca"],
    "/marcas/editar/:brandId": ["marcas", "editar marca"],
  };

  const matches = _.mapValues(header, (item, pathname) => useMatch(pathname));

  useEffect(() => {
    const matchedHeaders = _.pickBy(header, (value, key) => matches[key]?.pathname === key);
    setHeaderAux(matchedHeaders);
  }, [location.pathname, matches]);

  return { handleBack, header, headerAux, brandsView, brandsCreate };
};

export default useBrandsScreen;
