import React from 'react';

const TableProfilerRegionABI = (props) => {
  return (
    <>      
    {/* { props.type === 3 ? 
    <div>{props.item.aux_abi_region}</div> :   */}
      <div>{props.item.abi_region}</div>       
      {/* <div>{props.item.region}</div> */}
      <div>{props.item.country_name}</div>
      <div>{props.item.licenses}</div>  
    </>
  )
}

export default TableProfilerRegionABI;


