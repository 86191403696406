// Packages
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Actions
import useActions from '../../../../../api/actions';

import useControllers from "../../../../";

const usePassiveTrackingMap = () => {
  const { id_passive_tracking } = useParams();
  const [ steps, setSteps] = useState([]);
  const [ cordCenter, setCordCenter] = useState({lat:  4.6956544, lng: -74.0524032,});

  // Actions
  const { dispatch, useToolsActions, useGeneralActions } = useActions();
  const { actGetPassiveTrackingSteps } = useToolsActions();
  const { actExportExel } = useGeneralActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();


  useEffect(() => {
    const onSuccess = (data) => {
      setSteps(data.steps)
      setCordCenter({
        lat:
        data.steps.length > 0
            ? data.steps.reduce((sum, step) => {
                return sum + parseFloat(step.lat);
              }, 0) / data.steps.length
            : 4.6956544,
        lng: data.steps.length > 0
            ? data.steps.reduce((sum, step) => {
                return sum + parseFloat(step.lng);
              }, 0) / data.steps.length
            : -74.0524032,
      })
    }

    const onError = (error) => {
      console.log(error)
    }
    dispatch(actGetPassiveTrackingSteps(id_passive_tracking, onSuccess, onError))
  }, [id_passive_tracking]);

  const handleIndividualDownloadExel = (id) => {
    const filters = {};

    const filtersProcces = prepareDataFilterDownload(filters);
    actExportExel(`passiveTrackingStep/${id}`, filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  return {
    steps,
    cordCenter,
    handleIndividualDownloadExel
  }
}

export default usePassiveTrackingMap;