// Packages
import React from "react";
import { Routes, Route } from "react-router-dom";

// Views
import useViews from "../../views";

const PrivateRouting = () => {
  // Views
  const { useScreens } = useViews();

  const {
    Tools,
    Users,
    Tasks,
    Projects,
    UsersGroup,
    News,
    Notifications,
    Countries,
    Brands,
    Faqs,
    Payments,
    Profiler,
    Dashboard,
    Analytic,
    Membeer,
    PowerBI,
    Gamification,
    Map,
    Questions,
    Legal,
    PromptsMain,
    SubItems
  } = useScreens();

  return (
    
    <Routes>
      <Route path="/herramientas/*" element={<Tools />} />
      <Route path="/usuarios/*" element={<Users />} />
      <Route path="/misiones/*" element={<Tasks />} />
      <Route path="/grupos/*" element={<UsersGroup />} />
      <Route path="/proyectos/*" element={<Projects />} />
      <Route path="/notificaciones/*" element={<Notifications />} />
      <Route path="/noticias/*" element={<News />} />
      <Route path="/paises/*" element={<Countries />} />
      <Route path="/map/*" element={<Map />} />
      <Route path="/qualitychecks/*" element={<Questions />} />
      <Route path="/marcas/*" element={<Brands />} />
      <Route path="/pagos/*" element={<Payments />} />
      <Route path="/faq/*" element={<Faqs />} />
      <Route path="/perfilacion/*" element={<Profiler />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/analytics/*" element={<Analytic />} />
      <Route path="/membeers/*" element={<Membeer />} />
      <Route path="/powerbi/*" element={<PowerBI />} />
      <Route path="/gamification/*" element={<Gamification />} />
      <Route path="/legales/*" element={<Legal />} />
      <Route path="/prompts/*" element={<PromptsMain />} />
      <Route path="/sub-items/*" element={<SubItems />} />
    </Routes>
  );
};

export default PrivateRouting;
