import useHelpers from "../../../helpers";

const useUsersSelectors = () => {
  // Helpers
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const userSelected = createSelector(
    state => state.userSelected,
    userSelected => userSelected
  );

  return {
    userSelected
  }
};

export default useUsersSelectors;