// Packages
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";

// Helpers
import useHelpers from "../../../../helpers";

// Components
import useComponents from "../../../../views/components";

// Actions
import useApi from "../../../../api";

const useTasksClone = () => {
  const location = useLocation();
  const [listProject, setListProject] = useState([]);
  
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast } = useQuickFunctions();
  const { cloneTaskValidationShema } = useValidators();
  const { success } = useToast();

  // Actions
  const { useActions } = useApi();
  const { useMissionActions, useProjectsActions } = useActions();
  const { actCloneMission } = useMissionActions();
  const { actListProjects,  } = useProjectsActions();

  // Components
  const { Toast } = useComponents();

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(cloneTaskValidationShema),
  });

  const onSubmitSuccess = () => {
    success(<Toast text={"¡Misión duplicada con éxito!"} />, {
      toastId: 0,
    });
    //navigation(-1);
  };

  const prepareDataProject = (data) => {
    setListProject(
      data.data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    actCloneMission({ id: location.state.idClone, project_id: data.project_id === '' ? null : data.project_id }, onSubmitSuccess);
  };

  useEffect(() => {
    actListProjects({},prepareDataProject);
  }, []);

  return {
    control,
    handleSubmit,
    onSubmit,
    listProject,
  };
};

export default useTasksClone;
