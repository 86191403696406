//Packages
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// Helpers
import useHelpers from "../../../../helpers";

import useComponents from "../../../../views/components";

// Controllers
import useControllers from "../../..";

// Actions
import useApi from "../../../../api";


const useProfilerCreate = () => {

  // const listProfilersType = [
  //   {type:0, title: "Perfilación por integración de licencias por país", 
  //     columnsTitle:["País", "Licencias disponibles", "licencias ocupadas", "Licencias no ocupadas", "Acciones"], 
  //     cellSize:["1fr", "2fr", "2fr", "2fr", "1fr"], url: 'integrations'},
  //   {type:1, title: "Perfilación por región", columnsTitle:["Región", "País", "Licencias", "Acciones"], cellSize:["1fr", "1fr", "1fr", "1fr", "1fr"], url:'regions'},
  //   {type:2, title: "Perfilación por región ABI", columnsTitle:["Región ABI", "Región", "País", "Licencias", "Acciones"], cellSize:["1fr", "1fr", "1fr", "1fr", "fr"], url:'regions'},
  //   {type:3, title: "Perfilación por región ABI Auxiliar", columnsTitle:["Región ABI Auxiliar", "Región ABI", "Región", "País", "Licencias", "Acciones"], cellSize:["1fr", "1fr", "1fr", "1fr", "1fr", "1fr"], url:'regions'},
  //   {type:4, title: "Perfilación por edad", columnsTitle:["Edad", "Región ABI o ABI Auxiliar", "Región", "País", "Licencias", "Acciones"], cellSize:["1fr", "2fr", "1fr", "1fr", "1fr", "1fr"], url:'ages'},
  //   {type:5, title: "Perfilación por Género", columnsTitle:["Genero", "Región ABI o ABI Auxiliar", "Región", "País", "Licencias", "Acciones"], cellSize:["1fr", "2fr", "1fr", "1fr", "1fr", "1fr"], url:'gender'},
  //   {type:6, title: "Perfilación por NSE", columnsTitle:["NSE","Rango", "Región ABI o ABI Auxiliar", "Región", "País", "Licencias", "Acciones"], cellSize:["1fr", "1fr", "2fr", "1fr", "1fr", "1fr", "1fr"], url:'nse-ranges'},
  // ] 

  const listProfilersType = [
    {type:0, title: "Perfilación por integración de licencias por país", 
      columnsTitle:["País", "Licencias disponibles", "licencias ocupadas", "Licencias no ocupadas", "Acciones"], 
      cellSize:["1fr", "2fr", "2fr", "2fr", ".5fr"], url: 'integrations'},
    {type:1, title: "Perfilación por región", columnsTitle:["Región", "País", "Licencias", "Acciones"], cellSize:["1fr", "1fr", "5fr", ".5fr"], url:'regions'},
    {type:2, title: "Perfilación por región ABI", columnsTitle:["Región ABI", "País", "Licencias", "Acciones"], cellSize:["1fr", "1fr", "1fr", "3fr", ".5fr"], url:'abi-regions'},
    {type:3, title: "Perfilación por región ABI Auxiliar", columnsTitle:["Región ABI Auxiliar", "País", "Licencias", "Acciones"], cellSize:["1fr", "1fr", "1fr", "2fr", ".5fr"], url:'aux-abi-regions'},
    {type:4, title: "Perfilación por edad", columnsTitle:["Edad", "Región ABI o ABI Auxiliar", "País", "Licencias", "Acciones"], cellSize:[".6fr", "1.3fr", "1fr", "1fr", "3fr", ".5fr"], url:'ages'},
    {type:5, title: "Perfilación por Género", columnsTitle:["Genero", "Región ABI o ABI Auxiliar", "Región", "País", "Licencias", "Acciones"], cellSize:[".6fr", "1fr", "1fr", "1fr", "3fr", ".5fr"], url:'gender'},
    {type:6, title: "Perfilación por NSE", columnsTitle:["NSE","Rango", "Región ABI o ABI Auxiliar", "Región", "País", "Licencias", "Acciones"], cellSize:["1fr", ".6fr", "2fr", "1fr", "2fr", "2fr", ".5fr"], url:'nse-ranges'},
  ] 

  const [listCountries, setListCountries] = useState([]);
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listRegions, setListRegions] = useState([]);
  const [saving, setSaving] = useState(false);
  const navigation = useNavigate();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast, useGeneral } = useQuickFunctions();
  const { profilerLicenceValidationSchema, 
    profilerRegionValidationSchema, 
    profilerRegionABIValidationSchema,
    profilerAgeValidationSchema, 
    profilerGenderValidationSchema, 
    profilerNSEValidationSchema } = useValidators();

  const { Toast } = useComponents();
  const { success } = useToast();

  const { useActions } = useApi();
  const { useProfilerActions, useGeneralActions } = useActions();
  const { actListCountries, actListStatesByCountry, actListCitiesByState, actListRegions, actListAbiRegions, actListAuxAbiRegions } = useGeneralActions();
  const { actAddProfiler, actGetProfiler, actEditProfiler, actListProfilers } = useProfilerActions();
  
  const { id } = useParams();

  const location = useLocation();
  const type = location.state? location.state.type : null;
  
  // const type = 1;  

  const loadData = (data) => {          
    if(type === 1 || type === 2 || type === 3 && data.country_id) 
    {      
      actListStatesByCountry(data.country_id, prepareDataStates)
      // if(data.state_id.length > 0) actListCitiesByState(data.state_id.map(x=>id), prepareDataCities);
    }

    setValue("country_id", data.country_id);
    setValue("abi_region", data.abi_region); 
    setValue("total_licenses", data.total_licenses);
    setValue("in_use_licenses", data.in_use_licenses);
    setValue("unused_licenses", data.unused_licenses);  
    setValue("region", data.region);    
    if(data.state_id && data.state_id.length > 0 )
    {
      setValue("state_id", data.state_id.map(s=> {return {value:s.id, label: s.name}}))
    }

    // if(data.city_id && data.city_id.length > 0 )
    // {
    //   setValue("city_id", data.city_id.map(s=> {return {value:s.id, label: s.name}}))
    // }
    
    setValue("licenses", data.licenses);   
    setValue("nse", data.nse);      
    setValue("min", data.min);      
    setValue("max", data.max);          
    setValue("gender", data.gender);      
  }

  // const updatedAvailableLicenses = () => {    
  //   if(getValues("unused_licenses") > 0) setValue("total_licenses", 'X')
  //   else setValue("total_licenses", '-')
  // }

  const handleChangeCountry = (e) => {    
    if(e) actListStatesByCountry(e.target.value, prepareDataStates)
    setValue("state_id", []);
    // setValue("city_id", []);
  }

  // const handleChangeState = (e) => {    
  //   if(e) actListCitiesByState(e.map(x=> x.value), prepareDataCities)
  //   setValue("city_id", []);
  // }

  useEffect(() => {
    if(type === null) { 
      navigation(`/perfilacion`);
      return;
    }

    if(id) {
      actGetProfiler(id, listProfilersType[type].url, loadData)        
    }
    else {

    }
    
    actListCountries(prepareDataCountry);      
    
    if(type === 2 || type === 3 || type === 4) {      
      // actListProfilers(listProfilersType[2].url, { page: 1 }, prepareProfilerRegions);    
      actListRegions(prepareProfilerRegions);
    }    

    if(type === 4 || type === 5 || type === 6) {
      actListAbiRegions(prepareProfilerRegions)
    }

  }, []);

  const prepareProfilerRegions = (data) => {       
    setListRegions(data.map(r => { return { label:r.region, value: r.id }}));
  }

  const prepareDataCountry = (data) => {
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const prepareDataCities = (data) => {
    setListCities(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const prepareDataStates = (data) => {
    setListStates(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };
  
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(
      type === 0 ? profilerLicenceValidationSchema : 
      type === 1 ? profilerRegionValidationSchema :
      type === 2 || type === 3 ?  profilerRegionABIValidationSchema :       
      type === 4 ? profilerAgeValidationSchema :
      type === 5 ? profilerGenderValidationSchema : 
      profilerNSEValidationSchema),
      defaultValues: {
        unused_licenses: 0,
        in_use_licenses: 0
      }
  });

  const handleOnCreateSuccess = (data) => {    
    success(<Toast text={`Perfilación guardada con exito!`} />, {
      toastId: 0,
    });
    setTimeout(() => {
      navigation(-1);
    }, 1000);    
  }

  const onSubmit = (data) => {          
    setSaving(true);
    if(type < 4) {
      data = { ...data, 
        // city_id: data.city_id && data.city_id.length > 0 ? data.city_id.map(x=> x.value) : ['0'], 
        state_id: data.state_id && data.state_id.length > 0 ? data.state_id.map(x=> x.value) : ['0'],  
        // abi_region: data.abi_region ? data.abi_region : "value"
      };      
    }
    if(type === 4) {
      data = { ...data, age_range: "min-max"}
    }
    
    if(id) actEditProfiler({id, data, url:listProfilersType[type].url}, handleOnCreateSuccess)
    else actAddProfiler(data, listProfilersType[type].url, handleOnCreateSuccess);    
  };

  return {
    id,
    type,
    control,
    handleSubmit,
    onSubmit,
    saving,
    listCountries,
    listStates,
    listCities,
    listRegions,
    handleChangeCountry,
    // handleChangeState 
  };

}

export default useProfilerCreate;