import React from "react";
import { Route, Routes, useLocation, Outlet } from "react-router-dom";

// Components
import useLayouts from "../../layouts";

// Controllers
import useControllers from "../../../controllers";

// Steps Views
import FaqsView from "./Views";
import FaqsCreate from "./Create";

const FaqsLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const { useScreenHooks } = useControllers();
  const { useFaqs } = useScreenHooks();
  const { handleBack, header, headerKey } = useFaqs();
  
  const location = useLocation();
  const isExact = location.pathname === "/faqs";

  return (
    <PrivateContentLayout
      breadcrumbsList={header[headerKey]}
      handleBack={handleBack}
      showBack={!isExact}
      title="FAQ"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Faqs = () => {
  return (
    <Routes>
      <Route path="/" element={<FaqsLayout />}>
        <Route index element={<FaqsView />} />
        <Route path="creacion" element={<FaqsCreate />} />
        <Route path="editar/:newId" element={<FaqsCreate />} />
      </Route>
    </Routes>
  );
};

export default Faqs;
