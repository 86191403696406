// Packages
import React from "react";
import _ from "lodash";

// Components
import useComponents from "../../../components";
import useComponentsProjects from "../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";

const CreateProject = () => {
  //Icons
  const { IconCheck } = useIcons();

  // Components
  const {
    ContentWrappers,
    useButtons,
    FormLabel,
    TextFieldControlled,
    SelectFieldControlled,
    TextAreaFieldControlled,
    DatePickerFieldControlled,
    CardWrapper,
    Typography,
    SelectMultiFieldControlled,
    useModals,
    CheckboxControlled,
  } = useComponents();

  const { DefaultButton } = useButtons();
  const { DefaultModal } = useModals();

  const { TableMisions } = useComponentsProjects();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useProjectsScreen } = useScreenHooks();
  const { useProjectsCreate } = useProjectsScreen();

  const {
    listCountries,
    control,
    role,
    handleSubmit,
    onSubmit,
    errors,
    showModal,
    handleCloseModal,
    projectId,
    listMissions,
    handleDeletedMission,
    handleGoToMissions,
    projectName,
    country,
    disabledFields,
    saving,
    trackingWatch,
  } = useProjectsCreate();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-7/12" as="section">
        <CardWrapper>
          <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>
          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <ContentWrappers
              gridColums={["48%", "48%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <TextFieldControlled
                label="Nombre"
                placeholder="Nombre"
                name="name"
                id="name"
                control={control}
              />
              <ContentWrappers
                gridColums={["48%", "48%"]}
                gap={{
                  row: "0px",
                  column: "4%",
                }}
              >
                <div className="flex items-center mt-4">
                  <CheckboxControlled
                    label="Proyecto Tracking"
                    name="tracking"
                    id="tracking"
                    control={control}
                    className="justify-center"
                  />
                </div>
                <TextFieldControlled
                  label="Ciclo de misiones"
                  placeholder="Nro. de misiones"
                  name="tracking_count"
                  id="tracking_count"
                  control={control}
                  type='number'
                  disabled={!trackingWatch}
                />
              </ContentWrappers>
            </ContentWrappers>

            <ContentWrappers
              gridColums={["48%", "48%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              {/*  <div className="flex items-center">
               <DatePickerFieldControlled
                  label="Fecha de vencimiento"
                  name="expiration_date"
                  id="expiration_date"
                  control={control}
                  disabled={role !== "Súper Administrador" && projectId}
                /> 
              
              </div>*/}
              {role === "Súper Administrador" ? (
                <SelectMultiFieldControlled
                  label={"Paises"}
                  placeholder={"Selecciona una opción"}
                  name={`country_id`}
                  id={`abi-task-country`}
                  control={control}
                  options={listCountries}
                  limitTags={3}
                />
              ) : (
                  <SelectMultiFieldControlled
                    label={"País"}
                    placeholder={"Selecciona una opción"}
                    name={`country_id`}
                    id={`abi-task-country`}
                    control={control}
                    options={[{ label: country.name, value: country.id }]}
                    limitTags={3}
                    disabled={role !== "Súper Administrador" && projectId}
                  />
              )}
              <SelectFieldControlled
                label="Cliente interno"
                helperText="Debes seleccionar un Cliente interno"
                name="client_id"
                id="client_id"
                control={control}
                options={[
                  {
                    value: 0,
                    label: "Global",
                  },
                ]}
                disabled={role !== "Súper Administrador" && projectId}
              />
            </ContentWrappers>

            <TextAreaFieldControlled
              label="Objetivo"
              placeholder="Textarea placeholder"
              name="objective"
              id="objective"
              control={control}
            />

            <TextAreaFieldControlled
              label="Decisión a evaluar"
              placeholder="Textarea placeholder"
              name="decision"
              id="decision"
              control={control}
            />

            {projectId && (
              <TableMisions
                handleDeletedMission={handleDeletedMission}
                missions={listMissions}
                handleGoToMissions={handleGoToMissions}
              />
            )}

            <div className="justify-center flex mt-6">
              <DefaultButton color="secondary" type="submit" disabled={saving}>
                {projectId ? "Guardar Proyecto" : "Siguiente"}
              </DefaultButton>
            </div>
          </form>
        </CardWrapper>
      </ContentWrappers>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            El proyecto {projectName} ha sido guardado con éxito!
          </Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </>
  );
};

export default CreateProject;
