import React from "react";

// Controllers
import useControllers from "../../../../controllers";

// Components
import useComponents from "../../../components";

const View = () => {
  const {
    ContentWrappers,
    Typography,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    Badge,
    Pagination,
    DatePickerRangeControlled,
    Dropdown,
  } = useComponents();
  const { DefaultButton, FilterButton } = useButtons();

  const { useGeneralHooks, useScreenHooks } = useControllers();
  const { formatDate } = useGeneralHooks();

  const { useBrandsScreen } = useScreenHooks();
  const { brandsView } = useBrandsScreen();
  const {
    handleGoToCreate,
    handleChangeSearch,
    control,
    handleSubmit,
    onSubmitFilter,
    handlePagination,
    listBrands,
    paginationSettings,
    handleEdit,
    handleDownloadExel,
    handleIndividualDownloadExel,
    onClickClear,
  } = brandsView();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-11/12" as="section">
        <div className="bg-white px-8 pt-10 pb-12 rounded-lg">
          <ContentWrappers
            gridColums={["1fr 210px"]}
            className="mb-8 items-center border-b border-neutral-60 pb-8"
          >
            <Typography variant="h3">LISTADO DE MARCAS</Typography>
            <DefaultButton color="secondary" onClick={handleGoToCreate}>
              Crear nueva marca
            </DefaultButton>
          </ContentWrappers>
          <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
            <TextFieldSeach
              placeholder="Buscar"
              name="search"
              id="search"
              control={control}
              onInputChange={handleChangeSearch}
            />

            <div className="flex justify-end space-x-3">
              <FilterButton handleSubmit={handleSubmit(onSubmitFilter)}>
                <div>
                  <SelectFieldControlled
                    name="status"
                    id="abi-status"
                    control={control}
                    label="Estado"
                    options={[
                      {
                        label: "Activo ",
                        value: "act",
                      },
                      {
                        label: "Inactivo",
                        value: "ina",
                      },
                    ]}
                  />
                  <div className="flex items-center">
                    <DatePickerRangeControlled
                      label="Rango de fecha"
                      name="range_date"
                      id="range_date"
                      control={control}
                      onClickClear={onClickClear}
                    />
                  </div>
                </div>
              </FilterButton>
              <DefaultButton color="third" onClick={handleDownloadExel}>
                Exportar a Excel
              </DefaultButton>
            </div>
          </ContentWrappers>
          <TableContent
            headerCellList={[
              "ID",
              "FECHA DE CREACIÓN",
              "MARCA",
              "DESCRIPCIÓN",
              "ESTADO",
              "ACCIONES",
            ]}
            gridCell={["96px", "1.2fr", "0.8fr", "0.8fr", "1fr", "66px"]}
          >
            {_.map(listBrands, (brand, index) => {
              return (
                <TableRow key={index}>
                  <div>{brand?.id}</div>
                  <div> {formatDate(brand?.created_at, "dd-mm-Y")} </div>
                  <div>{brand?.name}</div>
                  <div>{brand?.description}</div>

                  <div>
                    <Badge
                      // disabled={brand?.status === "Enviado"}
                      variant={brand?.status === "Activo" ? "success" : "info"}
                      label={brand?.status}
                      type="light"
                    />
                  </div>

                  <div className="flex justify-end">
                    <Dropdown options={[
                      {id: 'download', action: ()=>handleIndividualDownloadExel(brand?.id)},
                      {id: 'edit', action: ()=> handleEdit(brand)},
                      ]}/>
                  </div>
                </TableRow>
              );
            })}
          </TableContent>
          <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
        </div>
      </ContentWrappers>
    </>
  );
};

export default View;
