import React from "react";
import { Route, Routes, useLocation, useMatch, Outlet } from "react-router-dom";
// Layout
import useLayouts from "../../layouts";

// Views
import View from "./View";
import Detail from "./Detail";

// Controllers
import useControllers from "../../../controllers";

const PaymentsLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const location = useLocation();

  const { useScreenHooks } = useControllers();
  const { usePaymentsScreen } = useScreenHooks();
  const { handleBack, headerAux } = usePaymentsScreen();

  const match = useMatch("/pagos/*");
  const basePath = match ? match.pathnameBase : "/pagos";
  const isExact = basePath === location.pathname;

  const breadcrumbsList = headerAux[location.pathname];

  return (
    <PrivateContentLayout
      breadcrumbsList={breadcrumbsList}
      handleBack={handleBack}
      showBack={!isExact}
      title="Pagos"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Payments = () => (
  <Routes>
    <Route path="/" element={<PaymentsLayout />}>
      <Route index element={<View />} />
      <Route path="detalle/:countryId" element={<Detail />} />
    </Route>
  </Routes>
);

export default Payments;
