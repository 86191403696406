import { lazy } from "react"

const DefaultModal = lazy(() => import("./DefaultModal"))
const ModalCustom = lazy(() => import("./CustomModal"))

const useModals = () => {
  return {
    DefaultModal,
    ModalCustom,
  }
}

export default useModals;