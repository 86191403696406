import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useMapServices = () => {
  const { useMapProviders } = useProviders();
  const { locationsProvider, locationsPointsProvider } = useMapProviders();

  const locationsService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(locationsProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const locationsPointsService = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            locationsPointsProvider(params)
          )
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  return { locationsService, locationsPointsService };
};

export default useMapServices;
