// Packages
import React from "react";

// Styles
import { StyledSearch, StyledContentButtons } from "./GroupView.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

const GroupView = () => {

  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    Pagination,
    Typography,
    useModals,
    Dropdown,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useUsersGroup } = useScreenHooks();
  const { useGroupView } = useUsersGroup();
  const {
    control,
    handleSubmit,
    onSubmit,
    listGroups,
    handleCreateNewGroup,
    handleEditGroup,
    listCountries,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    handleShowGroup,
    handleDownloadExel,
    handleIndividualDownloadExel,
    handleDeleteGroup,
    modalDelete,
    handleCloseModal,
    handleConfirmDelete
  } = useGroupView();

  return (
    <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-11/12" as="section">
      <div className="bg-white px-8 pt-10 pb-12 rounded-lg">
        <ContentWrappers
          gridColums={["1fr 210px"]}
          className="mb-8 items-center border-b border-neutral-60 pb-8"
        >
          <Typography variant="h3">Listado de grupos membeer</Typography>
          <DefaultButton color="secondary" onClick={handleCreateNewGroup}>
            Crear grupo membeer
          </DefaultButton>
        </ContentWrappers>
        <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
          <TextFieldSeach
            placeholder="Buscar"
            name="search"
            id="search"
            control={control}
            onInputChange={onChangeSearch}
          />
          <StyledContentButtons>
            <FilterButton handleSubmit={handleSubmit(onSubmit)}>
              <SelectFieldControlled
                placeholder={"Pais"}
                name={`abiFilterCountry`}
                id={`abi-filter-country`}
                control={control}
                options={listCountries}
              />
            </FilterButton>
            <DefaultButton color="third" onClick={handleDownloadExel}>
              Exportar a Excel
            </DefaultButton>
          </StyledContentButtons>
        </ContentWrappers>
        <TableContent
          headerCellList={[
            "Id",
            "Grupo",
            "Pais",
            "Estado/Departamento",
            "Región",
            "Región ABI",
            "NSE",
            "Rango de edad",
            "Genero",
            "Acciones",
          ]}
          gridCell={[
            "0.5fr",
            "1.2fr",
            "1fr",
            "1.25fr",
            "0.5fr",
            "0.75fr",
            "0.5fr",
            "1fr",
            "0.75fr",
            "100px",
          ]}
        >
          {listGroups.length > 0 ? (
            listGroups.map((group, index) => {
              return (
                <TableRow key={index}>
                  <div>{group?.id}</div>
                  <div>
                    <b onClick={() => handleShowGroup(group.id)} className="cursor-pointer">
                      {group?.name}
                    </b>
                  </div>
                  <div>{group.countries.reduce((prev, curr) => prev + curr.name + ",", "")} </div>
                  <div>{group.departments.reduce((prev, curr) => prev + curr.name + ",", "")} </div>
                  <div>{group?.regions.reduce((prev, curr) => prev + curr.name + ",", "")} </div>
                  <div>{group?.abi_regions.reduce((prev, curr) => prev + curr.name + ",", "")}</div>
                  <div>
                    {group?.nse ? group.nse
                      .replace("low", "Bajo")
                      .replace("mid", "Medio")
                      .replace("hight", "Alto") : ''}
                  </div>
                  <div>{group?.ages ? group?.ages : ''} </div>                  
                  <div>{group?.genders ? group.genders.replace("M", "Masculino").replace("F", "Femenino") : ''} </div>
                  <div className="flex justify-end">
                    <Dropdown options={[
                      {id: 'download', action: ()=>handleIndividualDownloadExel(group?.id)},
                      {id: 'edit', action: ()=> handleEditGroup(group)},
                      {id: 'delete', action: ()=> handleDeleteGroup(group.id, group.name), show: group.can_delete},
                      ]}/>
                  </div>
                </TableRow>
              );
            })
          ) : (
            <div>Sin datos para mostrar</div>
          )}
        </TableContent>
      </div>
      <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      <DefaultModal isOpen={modalDelete.show}>
          <div className="flex items-center flex-col space-y-8">
            <Typography variant="body2" className="text-center">
              Esta seguro de eliminar el grupo <b>{modalDelete.name}</b>.
            </Typography>
            <div className="flex items-center space-x-4">
              <DefaultButton color="secondary" onClick={handleCloseModal}>
                Cancelar
              </DefaultButton>
              <DefaultButton color="success" onClick={handleConfirmDelete}>
                Aceptar
              </DefaultButton>
            </div>
          </div>
        </DefaultModal>
    </ContentWrappers>
  );
};

export default GroupView;
