const IconFace = ({ color = "#B6B9BA" }) => {
  return (
    <svg
      className="svg-icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M384 501.333333a53.333333 53.333333 0 0 0 0 106.666667 53.333333 53.333333 0 0 0 0-106.666667z m256 0a53.333333 53.333333 0 0 0 0 106.666667 53.333333 53.333333 0 0 0 0-106.666667zM512 85.333333C276.266667 85.333333 85.333333 276.266667 85.333333 512s190.933333 426.666667 426.666667 426.666667 426.666667-190.933333 426.666667-426.666667S747.733333 85.333333 512 85.333333z m0 768c-188.16 0-341.333333-153.173333-341.333333-341.333333 0-12.373333 0.853333-24.533333 2.133333-36.48 100.48-44.586667 180.693333-126.933333 222.293333-229.12 77.226667 108.8 204.16 180.266667 347.946667 180.266667 33.066667 0 65.28-4.053333 96-11.306667 9.173333 30.72 14.293333 63.146667 14.293333 96.64 0 188.16-153.173333 341.333333-341.333333 341.333333z" />
    </svg>
  );
};

export default IconFace;
