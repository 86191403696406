// Packages
import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

import InputSwitch from "../InputSwitch";

const InputSwitchControlled = (props) => {
  const { id, label, disabled, name, control, onChangeInput } = props;

  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    id,
  });

  const handleChange = (e) => {
    onChange(e)
    onChangeInput && onChangeInput()
  }

  return (
    <InputSwitch
      id={id}
      name={name}
      label={label}
      value={value}
      inputRef={ref}
      onChange={handleChange}
      disabled={disabled}
      defaultChecked={value}
    />
  );
};

InputSwitchControlled.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default InputSwitchControlled;
