import { useState, useEffect } from "react";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import _ from "lodash";

// Steps controllers
import useGamificationView from "./Views";
import useGamificationCreate from "./Create";

const useGamification = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const [headerAux, setHeaderAux] = useState([]);

  const handleBack = () => {
    navigation(-1);
  };

  const header = {
    "/gamification": ["Gamification"],
    "/gamification/creacion": ["Gamification", "Crear Gamification"],
    "/gamification/editar/:id": ["Gamification", "Edición Gamification"]
  };

  const matches = _.mapValues(header, (item, pathname) => useMatch(pathname));

  useEffect(() => {
    const matchedHeader = _.find(header, (value, key) => matches[key]?.pathname === key) || [];
    setHeaderAux(matchedHeader);
  }, [location.pathname, matches]);

  return {
    handleBack,
    headerAux,
    useGamificationView,
    useGamificationCreate,
  };
}

export default useGamification;
