// Packages
import styled from "styled-components";
import tw from "twin.macro";
import TableCell from "@mui/material/TableCell";

export const StyledTableCell = styled(TableCell)`
  ${props => props.font ? `font-weight: ${props.font} !important;` : 'font-weight: 300 !important;'}
  ${props => props.color && `color: ${props.color} !important;`}
  ${props => props.border && `border: ${props.border} !important;`}
  ${props => props.align && `text-align: ${props.align} !important;`}
  ${props => props.width && `width: ${props.width} !important;`}
`;

export const StyledContainerPaper = styled.div`
    ${tw`mb-4`}
  .MuiPaper-root {
    background: white !important;
    box-shadow: none;
  }
  thead {
    tr {
        ${tw`uppercase`}
        th {
            color: #858585;
            font-weight: 300;
            font-size: 12px;
            line-height: 17px;
            height: 2rem;
            ${tw`p-1 truncate text-center border-neutral-70`}
        }
    }
  }
  tbody {
    tr {
        ${tw`truncate`}
        letter-spacing: -0.006em;
        td {
            color: #363635 !important;
            font-size: 0.75rem !important;
            height: 2rem;
            ${tw`border-dashed border-b text-center py-0 px-1`}
        }
    }
    tr:nth-of-type(even) {
        td {
            ${tw`bg-white`}
        }
        
    }
  }
`;
