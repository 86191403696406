import React, { useMemo } from "react";
import { Route, Routes, useMatch, useLocation, Outlet } from "react-router-dom";
import useLayouts from "../../layouts";
import useScreenHooks from "../../../controllers/screenHooks";

// Views
import QuestionsMain from "./Main";
import Create from "./Create";
import Edit from "./Edit";

const QuestionsLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const location = useLocation();
  const { useQuestions } = useScreenHooks();
  const { header } = useQuestions();

  const pathWithOutId = useMemo(() => {
    const pathArray = location.pathname.split("/");
    const filterArray = pathArray.filter((path) => isNaN(path));
    return filterArray.join("/");
  }, [location.pathname]);

  return (
    <PrivateContentLayout
      showBack={location.pathname !== "/qualitychecks"}
      title="Preguntas"
      breadcrumbsList={header[`/${pathWithOutId}`]}
      handleBack={() => {
        navigation(-1);
      }}
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Questions = () => {
  return (
    <Routes>
      <Route path="/" element={<QuestionsLayout />}>
        <Route index element={<QuestionsMain />} />
        <Route path="creacion" element={<Create />} />
        <Route path="editar/:id" element={<Edit />} />
      </Route>
    </Routes>
  );
};

export default Questions;
