import useApi from "../../index";

const useFaqsActions = () => {
  const { useServices } = useApi();
  const { useFaqsServices } = useServices();
  const { listFaqsService, editFaqsService, addFaqsService, 
    addFaqsListService, getFaqService, deleteFaqsService } = useFaqsServices();

  const actListFaqs = async ({page, search, country_id, status}, onSuccess, onError) => {
    try {
      const response = await listFaqsService({page, search, country_id, status});
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  }

  const actGetFaq = async (id, onSuccess, onError) => {
    try {
      const response = await getFaqService(id);
      onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };  

  const actEditFaqs = async (id, data, onSuccess, onError) => {
    try {
      const response = await editFaqsService(id, data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  }

  const actAddFaqs = async (data, onSuccess, onError) => {
    try {
      const response = await addFaqsService(data);
      // onSuccess && onSuccess(response);
      return response.data;
    } catch (error) {
      console.log(error)
      onError && onError();
    }
  }

  const actAddFaqsList = async (data, onSuccess, onError) => {
    try {
      const response = await addFaqsListService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  }

  const actDeleteFaq = async (id, onSuccess, onError) => {    
    try {
      const response = await deleteFaqsService(id);
      onSuccess && onSuccess(response);
    } catch (error) {
      onError && onError();
    }
  };


  return {
    actListFaqs,
    actAddFaqsList,
    actAddFaqs,
    actEditFaqs,
    actGetFaq,
    actDeleteFaq
  }

};

export default useFaqsActions;