import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";

import debounce from "lodash.debounce";
import useApi from "../../../../api";
import useHelpers from "../../../../helpers";
import useControllers from "../../..";

const countriesView = () => {
  const navigation = useNavigate();
  const [valueTextSearch, setValueTextSearch] = useState("");

  const [valueIso, setValueIso] = useState("");
  const [valueLaguaje, setValueLaguaje] = useState("");
  const [valueCurrencyName, setValueCurrencyName] = useState("");
  const [valueCurrencySymbol, setValueCurrencySymbol] = useState("");
  const [listLanguajes, setListLanguages] = useState([]);
  const [listCountries, setListCountries] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [isoFilter, setIsoFilter] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState([]);

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  //Actions
  const { useActions } = useApi();
  const { useCountriesAction, useGeneralActions } = useActions();
  const { actListCountries, actListLanguages, actgetExistingCurrency } = useCountriesAction();
  const { actExportExel } = useGeneralActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, useValidators } = useQuickFunctions();
  const { filterCountriesShema } = useValidators();
  const { navigateTo } = useNavigation();

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(filterCountriesShema),
  });

  const loadDataCountries = (data) => {
    setListCountries(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };

  const onSubmitFilter = (data, e) => {
    e.preventDefault();

    setValueIso(data.iso);
    setValueLaguaje(data.languaje);
    setValueCurrencyName(data.currency);
    setValueCurrencySymbol(data.symbol);

    actListCountries(
      {
        page: 1,
        search: valueTextSearch,
        iso: data.iso,
        languaje: data.languaje,
        currency_name: data.currency,
        currency_symbol: data.symbol,
      },
      loadDataCountries
    );
  };

  const handleSubmitSearch = (e) => {
    setValueTextSearch(e);
    actListCountries({ page: 1, search: e }, loadDataCountries);
  };

  const handleChangeSearch = useCallback(debounce(handleSubmitSearch, 750), []);

  const handlePagination = (nro) => {
    actListCountries(
      {
        page: nro.selected + 1,
        search: valueTextSearch,
        iso: valueIso,
        languaje: valueLaguaje,
        currency_name: valueCurrencyName,
        currency_symbol: valueCurrencySymbol,
      },
      loadDataCountries
    );
  };

  const handleGoToCreate = () => {
    navigation("/paises/crear");
  };

  const handleEditCountrie = (countrie) => {
    navigateTo(`/paises/editar/${countrie.id}`);
  };

  const loadDataSelectLanguages = (data) => {
    setListLanguages(
      _.map(data.data, (v) => {
        return { label: v.name, value: v.iso };
      })
    );
  };

  const loadDataFilter = (data) => {
    setIsoFilter(
      _.map(data.data, (v) => {
        return {
          label: v.iso2,
          value: v.iso2,
        };
      })
    );
    setCurrency(
      _.map(data.data, (v) => {
        return {
          label: v.currency_name,
          value: v.currency_name,
        };
      })
    );
    setCurrencySymbol(
      _.map(data.data, (v) => {
        return {
          label: v.currency_symbol,
          value: v.currency_symbol,
        };
      })
    );
  };

  const handleDownloadExel = () => {
    const filters = {
      symbol: valueCurrencySymbol,
      search: valueTextSearch,
      currency: valueCurrencyName,
      languaje: valueLaguaje,
      iso: valueIso,
    };

    const filtersProcces = prepareDataFilterDownload(filters);

    actExportExel("countries", filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  const handleIndividualDownloadExel = (id) => {
    const filters = {
      id: id,
    };
    const filtersProcces = prepareDataFilterDownload(filters);

    actExportExel("countries", filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  useEffect(() => {
    actListCountries({ page: 1 }, loadDataCountries);
    actListLanguages(loadDataSelectLanguages);
    actgetExistingCurrency(loadDataFilter);
  }, []);

  return {
    listCountries,
    handleGoToCreate,
    handleChangeSearch,
    control,
    handleSubmit,
    onSubmitFilter,
    handlePagination,
    paginationSettings,
    handleEditCountrie,
    listLanguajes,
    isoFilter,
    currency,
    currencySymbol,
    handleDownloadExel,
    handleIndividualDownloadExel,
  };
};
export default countriesView;
