import useApi from "../../index";
import useTypes from "../../../strings/types";

const useAuthActions = () => {
  // Services
  const { useServices } = useApi();
  const { useAuthServices } = useServices();
  const {
    loginService,
    forgotPasswordService,
    recoveryPasswordService,
    validLoginService,
    logOutService,
    recoveryPassworAppdService,
  } = useAuthServices();

  // Strings
  const { useAuthTypes } = useTypes();
  const { LOGIN, LOGOUT } = useAuthTypes();

  const actLogin =
    ({ email, password }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await loginService({ email, password });
        dispatch({
          type: LOGIN,
          payload: response.data.data,
        });
        onSuccess && onSuccess(response.data.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actForgotPassword = async ({ email }, onSuccess, onError) => {
    try {
      const response = await forgotPasswordService({ email });
      onSuccess && onSuccess(response);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actRecoveryPassword = async ({ email, password, token }, onSuccess, onError) => {
    try {
      const response = await recoveryPasswordService({ email, password, token });
      onSuccess && onSuccess(response);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actRecoveryPasswordApp = async ({ email, password, tokenApp }, onSuccess, onError) => {
    try {
      const response = await recoveryPassworAppdService({ email, password, tokenApp });
      onSuccess && onSuccess(response);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actValidLoginPassword = async () => {
    try {
      const response = await validLoginService();
      return response.data.data.success;
    } catch (e) {
      return false;
    }
  };

  const actLogout = (token, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await logOutService({ token });
      dispatch({
        type: LOGOUT,
      });
      onSuccess && onSuccess(response.data);
    } catch (e) {
      onError && onError(e);
    }
  };

  return {
    actLogin,
    actForgotPassword,
    actRecoveryPassword,
    actValidLoginPassword,
    actLogout,
    actRecoveryPasswordApp,
  };
};

export default useAuthActions;
