import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const usePromptsServices = () => {
  const { usePromptsProvider } = useProviders();
  const {
    listPromptsProvider,
    getPromptProvider,
    updatePromptProvider,
    createPromptProvider,
    deletePromptProvider,
    getPromptCategoryProvider,
    createCategoryPromptProvider,
  } = usePromptsProvider();

  const listPromptsService = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listPromptsProvider(params)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getPromptService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getPromptProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const updatePromptService = (data, id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(updatePromptProvider(data, id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const createPromptService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createPromptProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const deletePromptService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deletePromptProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getPromptCategoryService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getPromptCategoryProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const createcategoryPromptService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createCategoryPromptProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listPromptsService,
    getPromptService,
    updatePromptService,
    createPromptService,
    deletePromptService,
    getPromptCategoryService,
    createcategoryPromptService
  };
};

export default usePromptsServices;
