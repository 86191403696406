//Packages
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";

// Helpers
import useHelpers from "../../../../helpers";

import useComponents from "../../../../views/components";

// Actions
import useApi from "../../../../api";

const useGamificationCreate = () => {
  const [listBadges, setListBadges] = useState([]);
  const [listTier, setListTier] = useState([]);
  const [listTierFilter, setListTierFilter] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [listCharacter, setListCharacter] = useState([]);
  const [saving, setSaving] = useState(false);
  const [badge_id, setBadge_id] = useState(0);
  const navigation = useNavigate();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast } = useQuickFunctions();
  const {
    newGamificationBadgeSchema,
    newGamificationTierSchema,
    newGamificationMissionSchema,
    newGamificationImgSchema,
    newGamificationCategorySchema,
    newGamificationCharacterSchema,
  } = useValidators();

  const { Toast } = useComponents();
  const { success } = useToast();

  const { useActions } = useApi();
  const { useGamificationActions } = useActions();
  const { actAddProfiler, actListGamification, actGetGamification, actEditGamification } =
    useGamificationActions();

  const { id } = useParams();

  const location = useLocation();
  const type = location.state ? location.state.type : null;
  const title = location.state ? location.state.title : null;

  const { control, handleSubmit, reset, getValues, watch } = useForm({
    mode: "all",
    resolver: yupResolver(
      type === 0
        ? newGamificationBadgeSchema
        : type === 1
        ? newGamificationTierSchema
        : type === 2
        ? newGamificationMissionSchema
        : type === 4
        ? newGamificationCategorySchema
        : type === 3
        ? newGamificationCharacterSchema
        : newGamificationImgSchema
    ),
  });

  const loadData = (data) => {
    reset({...data, ...(type === 4 && {sub_category_id: data?.sub_category ?? null})});
    if(data.badge_id) setBadge_id(data.badge_id)

  };

  useEffect(async () => {
    if (type === null) {
      navigation(`/gamification`);
      return;
    }

    if (type === 2 || type === 5 || type === 3) {
      await actListGamification("badges", { page: 1 }, prepareDataBadge);
      await actListGamification("tiers", { page: 1 }, prepareDataTier);
    }
    if (type === 1) {
      actListGamification("badges", { page: 1 }, prepareDataBadge);
    }
    if (type === 5) {
      actListGamification("categories", { page: 1 }, prepareDataCategory);
    }
    if (type === 4) {
      actListGamification("characters", { page: 1 }, prepareDataCharacter);
      actListGamification("categories", { page: 1 }, prepareDataCategory);
    }
    if (id) {
      await actGetGamification(id, location.state.url, loadData);
    }
  }, []);

  useEffect(()=>{
    if (id && badge_id) {
      handleGetTierFilter()
    }
  }, [listTier, badge_id])

  const prepareDataBadge = (data) => {
    setListBadges(
      data.data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const prepareDataTier = (data) => {
    setListTier(
      data.data.map((v) => {
        return { label: v.name, value: v.id, badge_id: v.badge_id };
      })
    );
  };

  const prepareDataCategory = (data) => {
    setListCategory(
      data.data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };
  const prepareDataCharacter = (data) => {
    setListCharacter(
      data.data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const handleGetTierFilter = () => {
      setListTierFilter(listTier.filter((v) => v.badge_id === getValues("badge_id")))
  }

  const handleOnCreateSuccess = () => {
    success(<Toast text={`Datos guardados con exito!`} />, {
      toastId: 0,
    });
    setTimeout(() => {
      navigation(-1);
    }, 1000);
  };

  const onSubmit = () => {
    const onError = () => {
      setSaving(false);
    };
    setSaving(true);
    let formData = getValues();
    if (type === 4) {
      formData.sub_category_id = getValues("sub_category") ? getValues("sub_category")?.id ?? null : getValues("sub_category_id")?.value ?? null;
    }
    if (type === 5 || type === 3) {
      formData = new FormData();
      formData.append("name", getValues("name"));
      formData.append("category_id", getValues("category_id"));
      if (getValues("badge_id")) {
        formData.append("badge_id", getValues("badge_id"));
        formData.append("tier_id", getValues("tier_id"));
      }
      formData.append("hidden", getValues("hidden") ? 1 : 0);
      formData.append("applyColor", getValues("applyColor") ? 1 : 0);
      formData.append("default", getValues("default") ? 1 : 0);
      formData.append("frame", getValues("frame") ? 1 : 0);
      if (getValues("file")?.length > 0 && typeof getValues("file") !== "string") {
        formData.append("file", getValues("file")[0]);
      }
      if (getValues("file_preview")?.length > 0 && typeof getValues("file_preview") !== "string") {
        formData.append("file_preview", getValues("file_preview")[0]);
      }
    } else if (type === 0 || type === 1) {
      formData = new FormData();
      formData.append("name", getValues("name"));
      if (type === 1) {
        formData.append("badge_id", getValues("badge_id"));
      }
      if (getValues("file")?.length > 0 && typeof getValues("file") !== "string") {
        formData.append("file", getValues("file")[0]);
      }
    }
    if (id) {
      actEditGamification(
        {
          id,
          data: formData,
          url: type !== 2 && type !== 4 ? location.state.url + "/update" : location.state.url,
          method: type !== 2 && type !== 4 ? "post" : "put",
        },
        handleOnCreateSuccess,
        onError
      );
    } else {
      actAddProfiler(formData, location.state.url, handleOnCreateSuccess, onError);
    }
  };

  const onSubmitSearchCategory = (e) => {
    if (e.target.value.length > 0) {
      actListGamification("categories", { page: 1, search: e.target.value }, prepareDataCategory);
    } else {
      setListProject([]);
    }
  };

  const onChangeSearchCategory = useCallback(debounce(onSubmitSearchCategory, 750), []);

  return {
    type,
    title,
    control,
    handleSubmit,
    onSubmit,
    saving,
    listBadges,
    listTier,
    listCategory,
    listCharacter,
    listTierFilter,
    handleGetTierFilter,
    onChangeSearchCategory,
  };
};

export default useGamificationCreate;
