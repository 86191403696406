const IconNotifications = ({ color = "#B6B9BA", strokeHover }) => {
  return (
    <svg
      data-stroke={strokeHover}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.387 12C19.198 15.799 21 17 21 17H3C3 17 6 15 6 8.00004C6.00001 7.12749 6.19033 6.26544 6.5577 5.47399C6.92506 4.68255 7.46062 3.98075 8.12705 3.41752C8.79347 2.8543 9.57472 2.4432 10.4163 2.2129C11.2579 1.98259 12.1397 1.93862 13 2.08404"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 2V8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 5H15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconNotifications;
