// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledSearch = styled.div.attrs({
  className: "StyledSearch",
})`
  ${tw`relative`}
  max-width: 288px;

  button {
    ${tw`absolute`}
    top: 16px;
    right: 18px;
  }

  .MuiFormControl-root {
    margin-bottom: 0;

    input {
      margin-top: 0;
    }
  }
`;

export const StyledContentButtons = styled.div.attrs({
  className: "StyledContentButtons",
})`
  ${tw`flex justify-end items-center`}

  button {
    margin-left: 14px;
  }
`;

export const StyledButtonFile = styled.label.attrs({
  className: "StyledButtonFile",
})`
  ${tw`cursor-pointer bg-secondary-500 text-white rounded px-6 text-center`}
  height: 44px;
  font-size: 0.875rem;
  line-height: 44px;
  input {
    ${tw`hidden`}
  }
`;

export const StyledFormFilter = styled.div.attrs({
  className: "StyledFormFilter",
})`
  .MuiFormControl-root {
    ${tw`mb-2`}
  }
`;

