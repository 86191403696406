import React from "react";

//Components
import useComponents from "../../../../components";

// Assets
import useIcons from "../../../../../assets/icons";

const TableMisions = (props) => {
  const { missions, handleDeletedMission, handleGoToMissions } = props;

  // Components
  const { useButtons, TableContent, TableRow, Typography, useModals, Badge } = useComponents();

  const { DefaultButton } = useButtons();

  //Icons
  const { IconPlus, IconDelete } = useIcons();

  return (
    <>
      <div className="flex items-center w-full justify-between">
        <Typography variant="h3">2. MISIONES VINCULADAS</Typography>
        <DefaultButton
          onClick={() => handleGoToMissions()}
          variant="outlined"
          color="primary"
          startIcon={<IconPlus color="#FFA700" />}
        >
          Agregar Misión
        </DefaultButton>
      </div>
      <TableContent
        headerCellList={["NOMBRE", "HERRAMIENTAS", "ESTADO", "ACCIONES"]}
        gridCell={["1fr", "1fr", "1fr", "66px"]}
      >
        {missions.length === 0 ? (
          <div className="text-center">Agregar Misiones</div>
        ) : (
          _.map(missions, (mision, index) => {
            return (
              <TableRow key={index}>
                <div>{mision.name}</div>
                <div>
                  {_.map(mision.tools, (tool, index) => {
                    return `${tool.name} ${index !== mision.tools.length - 1 ? "/" : ""} `;
                  })}
                </div>
                <div>
                  <Badge
                    disabled={mision?.status !== "no_tools" || mision?.status !== "in_course"}
                    variant={mision?.status === "no_tools" ? "danger" : "success"}
                    colorLevel={mision?.status === "no_tools" && "450"}
                    label={
                      mision?.status === "no_tools"
                        ? "Sin herramientas"
                        : mision?.status === "in_course"
                        ? "En curso"
                        : "Finalizada"
                    }
                  />
                </div>
                <div>
                  <button
                    onClick={() => {
                      handleDeletedMission(mision);
                    }}
                    title="Eliminar"
                  >
                    <IconDelete />
                  </button>
                </div>
              </TableRow>
            );
          })
        )}
      </TableContent>
    </>
  );
};

export default TableMisions;
