// Packages
import React from "react";

// Styles
import { StyledContentButtons } from "./MembeersView.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";

const LegalView = () => {
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    InputFileControlled,
    Pagination,
    Typography,
    useModals,
    Dropdown,
  } = useComponents();

  const { IconEye } = useIcons();

  const { DefaultButton, FilterButton } = useButtons();
  const { ModalCustom } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { formatDate } = useGeneralHooks();
  const { useLegal } = useScreenHooks();
  const { useLegalView } = useLegal();

  const {
    listLegal,
    control,
    handleSubmit,
    onSubmitFilter,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    listCountries,
    showModal,
    handleCloseModal,
    handleCreateLegal,
    controlFilter,
    handleSubmitFilter,
    onSubmit,
    handleEditLegal,
  } = useLegalView();

  return (
    <div className="bg-white px-8 pt-10 pb-12 mx-12 rounded-lg">
      <ContentWrappers
        gridColums={["1fr 210px"]}
        className="mb-4 items-center border-b border-neutral-60 pb-8"
      >
        <Typography variant="h3">Legales</Typography>
      </ContentWrappers>

      <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
        <TextFieldSeach
          placeholder="Buscar"
          name="search"
          id="search"
          control={controlFilter}
          onInputChange={onChangeSearch}
        />
        <StyledContentButtons>
          <FilterButton handleSubmit={handleSubmitFilter(onSubmitFilter)}>
            <SelectFieldControlled
              name="country_id"
              id="country_id"
              control={controlFilter}
              label="País"
              options={listCountries}
            />
          </FilterButton>
          <DefaultButton color="secondary" onClick={handleCreateLegal}>
            Crear legales
          </DefaultButton>
        </StyledContentButtons>
      </ContentWrappers>
      <TableContent
        headerCellList={[
          "País",
          "Terminos y condiciones",
          "Privacidad",
          "Fecha creación",
          //"Acciones",
        ]}
        gridCell={["1fr", "1fr", "1fr", "1fr"/* , ".5fr" */]}
      >
        {listLegal.length > 0 ? (
          listLegal.map((legal) => {
            return (
              <TableRow key={legal.id}>
                <div>{legal.country?.name}</div>
                <div>
                  <a href={legal.fileTyc} target="_blank">
                    <IconEye color="#898781" />
                  </a>
                </div>
                <div>
                  <a href={legal.filePrivacy} target="_blank">
                    <IconEye color="#898781" />
                  </a>
                </div>
                <div>
                  {legal?.createdAt}
                </div>
                {/* <div className="flex justify-end">
                  <Dropdown options={[{ id: "edit", action: () => handleEditLegal(legal.id, legal.country?.id) }]} />
                </div> */}
              </TableRow>
            );
          })
        ) : (
          <div>Sin datos para mostrar</div>
        )}
      </TableContent>
      <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      <ModalCustom isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2">Carga los documentos legales de cada pais</Typography>
          <form className="mt-6 w-full mx-8" onSubmit={handleSubmit(onSubmit)}>
            <SelectFieldControlled
              name="country_id"
              id="country_id"
              control={control}
              label="País"
              options={listCountries}
            />
            <InputFileControlled
              name="file_tyc"
              id="file_tyc"
              control={control}
              label="Terminos y condiciones"
              accept={"application/pdf"}
            />
            <InputFileControlled
              name="file_privacy"
              id="file_privacy"
              control={control}
              label="Politicas y privacidad"
              accept={"application/pdf"}
            />
            <div className="flex items-center justify-around space-x-4">
              <DefaultButton color="primary" type="submit">
                Guardar
              </DefaultButton>
              <DefaultButton color="third" onClick={handleCloseModal}>
                cancelar
              </DefaultButton>
            </div>
          </form>
        </div>
      </ModalCustom>
    </div>
  );
};

export default LegalView;
