import React from 'react';

const TableOnlyName = ({item, type}) => {
  return (
    <>
      <div>{item.name}</div>
      {type === 1 && <div>{item.badge}</div>}
      {type === 4 && <div>{item.medal_character}</div>}
      {type === 4 && <div>{item.sort_list}</div>}
    </>
  )
}

export default TableOnlyName;