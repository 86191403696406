// Packages
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledSearch = styled.div.attrs({
  className: "StyledSearch"
})`
  ${tw`relative`}
  max-width: 288px;

  button {
    ${tw`absolute`}
    top: 16px;
    right: 18px;
  }

  .MuiFormControl-root {
    margin-bottom: 0;

    input {
      margin-top: 0;
    }
  }
`

export const StyledContentButtons = styled.div.attrs({
  className: "StyledContentButtons"
})`
  ${tw`flex justify-end items-center`}

  button {
    margin-left: 14px;
  }
`

export const StyledSectionListItemMove = styled.span.attrs({
  className: "StyledQuestionsListItemMoves"
})`
  ${tw`flex justify-center self-center items-center flex-col rounded-tr rounded-br`}
  margin-top: 3px;
  height: 32px;
  width: 35px;

  div {
    ${tw`flex flex-row justify-between items-center`}
    width: 35%;
    span {
      ${tw`block border-2 border-solid border-third-600 rounded-full`}
      height: 4.75px;
      width: 4.75px;
      margin-bottom: 2px;
    }
  }
`;

export const StyledAddButton = styled.div.attrs({
  className: "StyledAddButton"
})`
  ${tw`flex justify-center ml-5`}

  button {
    ${tw`flex justify-center items-center rounded border-2 border-black`}
    height: 44px;
    width: -webkit-fill-available;
  }
`;

export const StyledModalPreview = styled.div.attrs({
  className: "StyledModalPreview space-y-10 px-8 mx-8 flex flex-col justify-between"
})`
  min-width: 350px;
  min-height: 350px;
`