// Packages
import React from 'react';
import PropTypes from "prop-types";

// Styles
import { StyledContentWrappers } from './ContentWrappers.styles';

const ContentWrappers = ({
  children,
  gridColums = ["1fr"],
  minCol = null,
  maxCol = '1fr',
  gap = { row: "0", column: "0" },
  contentWidth,
  as = "div",
  className,
  align = "center",
  repeatGrid = false,
  autoFillFit = 'fill'
}) => {
  return (
    <StyledContentWrappers
      as={as} 
      align={align}
      maxCol={maxCol}
      minCol={minCol}
      repeatGrid={repeatGrid}
      gridColums={gridColums}
      autoFillFit={autoFillFit}
      gap={gap}
      contentWidth={contentWidth}
      className={className}
    >
      {children}
    </StyledContentWrappers>
  );
}

ContentWrappers.propTypes = {
  gridColums: PropTypes.arrayOf(PropTypes.string),
  gap: PropTypes.shape({
    row: PropTypes.string,
    column: PropTypes.string
  }),
  contentWidth: PropTypes.string,
  children: PropTypes.any,
  as: PropTypes.string,
  className: PropTypes.string,
  maxCol: PropTypes.string,
  minCol: PropTypes.string,
  autoFillFit: PropTypes.string,
  align: PropTypes.oneOf(["center", "left", "right"]),
  repeatGrid: PropTypes.bool,
}

export default ContentWrappers;
