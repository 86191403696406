import useQuestionsMain from "./Main";
import useCreateQuestions from "./Create";
import useEditQuestions from "./Edit";

const useQuestions = () => {
  const header = {
    "/qualitychecks": ["Preguntas"],
    "/qualitychecks/editar": ["Preguntas", "Editar Pregunta"],
    "/qualitychecks/creacion": ["Preguntas", "Crear Pregunta"],
  };
  return { useQuestionsMain, useCreateQuestions, useEditQuestions, header };
};

export default useQuestions;
