import React from "react";
import { Routes, Route, Outlet, useLocation, useMatch } from "react-router-dom";
import useLayouts from "../../layouts";
import useControllers from "../../../controllers";

// Components
import MembeersView from "./Views";
import MembeersWaiting from "./Waiting";

const MembeersLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const { useScreenHooks } = useControllers();
  const { useMembeers } = useScreenHooks();
  const { handleBack, header } = useMembeers();

  const location = useLocation();
  const match = useMatch("/membeers/*");
  const basePath = match ? match.pathnameBase : "/membeers";
  const isExact = basePath === location.pathname;

  const breadcrumbsList = location.pathname.split("/").length > 2
    ? header[Object.keys(header).filter((header) => location.pathname.includes(header))[1]]
    : header[Object.keys(header).filter((header) => location.pathname.includes(header))];

  return (
    <PrivateContentLayout
      breadcrumbsList={breadcrumbsList}
      handleBack={handleBack}
      showBack={!isExact}
      title="Membeers"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Membeers = () => (
  <Routes>
    <Route path="/" element={<MembeersLayout />}>
      <Route index element={<MembeersView />} />
      <Route path="espera" element={<MembeersWaiting />} />
    </Route>
  </Routes>
);

export default Membeers;
