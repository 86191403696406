import styled from 'styled-components';
import tw from 'twin.macro';
import { Typography } from '@mui/material';
export const StyledTypography = styled(Typography).attrs({
  className: 'StyledTypography',
})`
  && {
    ${tw`relative`}
    ${props => props.bulledtitle === "true" && `
      padding-left: 16px;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        display: block;
        left: 0;
        top: 50%;
        margin-top: -4px;
        border-radius: 50%;
      }
    `}

    &:before {
      background-color: ${props => props.bulledcolor};
    }
  }
`;
