import axios from "axios";

const useCountriesProviders = () => {
  const listCountriesProvider = ({
    page,
    search,
    iso,
    languaje,
    currency_name,
    currency_symbol,
  }) => {
    return axios({
      method: "get",
      url: `country-parameters?page=${page}`,
      params: { iso, languaje, currency_symbol, currency_name, search },
    });
  };

  const listCountriesFree = () => {
    return axios({
      method: "get",
      url: `countries-free`,
    });
  };

  const listCountriesFreeFaqsProvider = (id) => {    
    return axios({
      method: "get",
      url: id ? `countries-free-faq?country_id=${id}` : 'countries-free-faq',
    });
  };

  const listLanguajesProvider = () => {
    return axios({
      method: "get",
      url: `constants/languajes`,
    });
  };

  const createCountrieProvider = (data) => {
    return axios({
      method: "post",
      url: `country-parameters`,
      data,
    });
  };
  const getCountrieProvider = (id) => {
    return axios({
      method: "get",
      url: `country-parameters/${id}`,
    });
  };

  const editCountrieProvider = (id, data) => {
    return axios({
      method: "put",
      url: `country-parameters/${id}`,
      data,
    });
  };

  const getGeneralCountrieProvider = (id) => {
    return axios({
      method: "get",
      url: `countries/${id}`,
    });
  };

  const getExistingCurrencyProvider = () => {
    return axios({
      method: "get",
      url: `existing-currencies`,
    });
  };

  return {
    listCountriesProvider,
    listCountriesFree,
    listCountriesFreeFaqsProvider,
    listLanguajesProvider,
    createCountrieProvider,
    getCountrieProvider,
    editCountrieProvider,
    getExistingCurrencyProvider,
    getGeneralCountrieProvider,
  };
};
export default useCountriesProviders;
