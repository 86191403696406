const IconBack = ({ color = "#FFA700",  weight = 1}) => {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={weight === 1 ? "M5 9L1 5L5 1" : "M5 9L1 5l4-4"}
        stroke={color}
        strokwidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconBack;
