import React from "react";
import Avatar from "react-avatar";
import PropTypes from "prop-types";

// Styles
import {
  StyledHeader,
  StyledUserInfo,
  StyledPageInfo,
  StyledBreadcrumbs,
  StyledNotification,
  StyledContainerBack,
} from "./Header.styles";

// Components
import Typography from "../Typography";
import Countdown from "react-countdown";

// Controllers
import useControllers from "../../../controllers";
import useModals from "../Modals";

// Assets
import brand from "../../../assets/images/logo.svg";
import useButtons from "../Buttons";
import IconBack from "../../../assets/icons/iconBack";

const renderer = ({ hours, minutes, seconds, days }) => {
  return (
    <span>
      {days}D {hours.toString().padStart(2, "0")}H {minutes.toString().padStart(2, "0")}M{" "}
      {seconds.toString().padStart(2, "0")}S
    </span>
  );
};

const Header = ({
  breadcrumbsList = [],
  title,
  showBack,
  handleBack = () => {},
}) => {
  // Controllers
  const { useComponentHooks } = useControllers();
  const { useHeader } = useComponentHooks();
  const {
    sessionInfo,
    handleShowOptions,
    showOptions,
    handleLogout,
    handleOpenLogout,
    handleCloseLogout,
    openLogout,
    messages,
  } = useHeader();

  const { ModalCustom } = useModals();
  const { DefaultButton } = useButtons();

  return (
    <StyledHeader>
      <StyledUserInfo>
        <StyledPageInfo>
          <StyledBreadcrumbs>
            <li>
              <Typography variant="h4">{title}</Typography>
            </li>
          </StyledBreadcrumbs>
        </StyledPageInfo>
        <div className="flex items-center space-x-4">
          {messages.map((message, index) => (
            <StyledNotification key={index}>
              <div>
                <Typography variant="h3" color={message.color}>
                  {message.text}
                </Typography>
                <Typography variant="h3" color={message.color}>
                  <Countdown overtime={true} renderer={renderer} date={message.date_limit} />
                </Typography>
              </div>
            </StyledNotification>
          ))}
          <div className="text-right leading-none">
            <span className="user-name">
              {sessionInfo.name} {sessionInfo.lastname}
            </span>
            <span className="user-role">{sessionInfo.rol}</span>
          </div>
          <div className="user-image relative">
            <Avatar
              color="#1E2529"
              maxInitials={4}
              name={`${sessionInfo.name} ${sessionInfo.lastname}`}
              size="48"
              className="cursor-pointer"
              onClick={handleShowOptions}
            />
            <div
              className={`absolute bg-white w-32 right-0 py-4 px-6 border border-neutral-100 rounded ${
                !showOptions && "hidden"
              }`}
            >
              <Typography variant="h5" className="cursor-pointer" onClick={handleOpenLogout}>
                Cerrar Sesión
              </Typography>
            </div>
          </div>
        </div>
      </StyledUserInfo>
      <StyledPageInfo className="flex items-center justify-between">
        {showBack && (
          <StyledContainerBack onClick={handleBack}>
            <IconBack weight={2} />
            <Typography variant="caption">Volver</Typography>
          </StyledContainerBack>
        )}
        {showBack && (
          <StyledBreadcrumbs>
            {breadcrumbsList.map((item, index) => (
              <li key={index}>
                <Typography variant="h4">{item}</Typography>
              </li>
            ))}
          </StyledBreadcrumbs>
        )}
      </StyledPageInfo>

      <ModalCustom isOpen={openLogout}>
        <div className="px-8">
          <img src={brand} alt="Memberr" className="mx-auto mb-10" />
          <Typography variant="h3" className="uppercase text-center">
            Hola {sessionInfo.name}
          </Typography>
          <div className="max-w-xs">
            <Typography
              className="text-center"
              variant="body2"
              sx={{ lineHeight: "22px", letter: "-1.1%", fontWeight: 400, marginTop: "2rem" }}
            >
              ¿Está segur@ que desea cerrar su sesión?
            </Typography>
            <Typography
              className="text-center"
              variant="body2"
              sx={{ lineHeight: "22px", letter: "-1.1%", fontWeight: 400, marginTop: "1rem" }}
            >
              Si la cierra, debe ingresar nuevamente con su usuario y contraseña.
            </Typography>
          </div>
          <div className="text-center flex space-x-4 items-center justify-center mt-6">
            <DefaultButton variant="outlined" type="button" onClick={handleCloseLogout}>
              Cancelar
            </DefaultButton>
            <DefaultButton type="submit" color="secondary" onClick={handleLogout}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </ModalCustom>
    </StyledHeader>
  );
};

Header.propTypes = {
  breadcrumbsList: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  showBack: PropTypes.bool,
  handleBack: PropTypes.func,
};

export default Header;
