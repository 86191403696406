import useHelpers from "../../../helpers";

const useAuthSelectors = () => {
  // Helpers
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const session = createSelector(
    state => state.session,
    session => session
  );

  return {
    session
  }
};

export default useAuthSelectors;