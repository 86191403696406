// Packages
import React from "react";
import PropTypes from "prop-types";

// Styles
import {
  StyledTableBody,
  StyledTableHeader,
  StyledTableRowHeader,
  StyledTable,
} from "./Table.styles";

const TableContent = ({
  children,
  gridCell = ["1fr"],
  headerCellList = [],
  headerCentersItem = [],
  modeTable,
}) => {
  return (
    <StyledTable>
      <StyledTableHeader gridCell={gridCell} modeTable={modeTable}>
        {headerCellList?.map((name, index) => {
          return (
            <StyledTableRowHeader
              key={index}
              className={headerCentersItem.find((item) => item === index) ? "text-center" : ""}
            >
              {name}
            </StyledTableRowHeader>
          );
        })}
      </StyledTableHeader>

      <StyledTableBody modeTable={modeTable} gridCell={gridCell}>
        {children}
      </StyledTableBody>
    </StyledTable>
  );
};

TableContent.propTypes = {
  gridCell: PropTypes.arrayOf(PropTypes.string),
  headerCellList: PropTypes.array,
  headerCentersItem: PropTypes.array,
  modeTable: PropTypes.oneOf(["solid"]),
};

export default TableContent;
