import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useUsersServices = () => {
  const { useUsersProvider } = useProviders();
  const {
    listUsersProvider,
    editUserProvider,
    addUserProvider,
    listRolesProvider,
    deleteUserProvider,
  } = useUsersProvider();

  const addUsersService = (data) => {
    return new Promise( async (resolve, reject) => {
      try {
        resolve(await trackPromise(addUserProvider(data)));
      } catch (error) {
        reject(error);
      }
    })
  }

  const editUserService = (id, data) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(editUserProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    })
  }

  const listUserService = ({page, country_id, search, rol_id, status}) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(listUsersProvider({page, country_id, search, rol_id, status})));
      } catch (error) {
        reject(error);
      }
    })
  }
  
  const listRolesService = () => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(listRolesProvider()));
      } catch (error) {
        reject(error);
      }
    })
  }
  
  const deleteUserService = (id) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteUserProvider(id)));
      } catch (error) {
        reject(error);
      }
    })
  }

  return {
    addUsersService,
    editUserService,
    listUserService,
    listRolesService,
    deleteUserService,
  }
}

export default useUsersServices;