import useApi from "../../index";
import useTypes from "../../../strings/types";

const usePromptsActions = () => {
  // Services
  const { useServices } = useApi();
  const { usePromptsServices } = useServices();
  const {
    listPromptsService,
    getPromptService,
    updatePromptService,
    createPromptService,
    deletePromptService,
    getPromptCategoryService,
    createcategoryPromptService,
  } = usePromptsServices();


  const actListPrompts = (params, onSuccess, onError) => async () => {
    try {
      const response = await listPromptsService(params);

      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actGetPrompt = (id, onSuccess, onError) => async () => {
    try {
      const response = await getPromptService(id);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actUpdatePrompt =
    ({ data, id }, onSuccess, onError) =>
    async () => {
      try {
        const response = await updatePromptService(data, id);
        onSuccess && onSuccess(response.data);
      } catch {
        onError && onError();
      }
    };

  const actCreatePrompt = ({ data, id }, onSuccess, onError) => async () => {
    try {
      const response = await createPromptService(data, id);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actDeletePrompt = (id, onSuccess, onError) => async () => {
    try {
      const response = await deletePromptService(id);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actGetPromptCategories = (onSuccess, onError) => async () => {
    try {
      const response = await getPromptCategoryService();
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actCreateCategoryPrompt = ({ data }, onSuccess, onError) => async () => {
    try {
      await createcategoryPromptService(data);
      onSuccess && onSuccess();
    } catch {
      onError && onError();
    }
  };

  return {
    actListPrompts,
    actGetPrompt,
    actUpdatePrompt,
    actCreatePrompt,
    actDeletePrompt,
    actGetPromptCategories,
    actCreateCategoryPrompt,
  };
};

export default usePromptsActions;
