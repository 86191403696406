import useApi from "../../index";

const useGamificationActions = () => {
  const { useServices } = useApi();
  const { useGamificationServices } = useServices();

  const {
    listGamificationService,
    addGamificationService,
    getGamificationService,
    editGamificationService,
    deleteGamificationService,
  } = useGamificationServices();

  const actListGamification = async (url, { page }, onSuccess, onError) => {
    try {
      const response = await listGamificationService(url, { page });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actGetGamification = async (id, url, onSuccess, onError) => {
    try {
      const response = await getGamificationService(id, url);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      console.log(error);
      onError && onError();
    }
  };

  const actEditGamification = async ({ id, data, url, method }, onSuccess, onError) => {
    try {
      const response = await editGamificationService({ id, data, url, method });
      onSuccess && onSuccess(response.data?.data);
    } catch (error) {
      onError && onError();
    }
  };
  
  const actDeleteGamification = async ({ id, url }, onSuccess, onError) => {
    try {
      const response = await deleteGamificationService({ id, url });
      onSuccess && onSuccess(response.data?.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actAddProfiler = async (data, url, onSuccess, onError) => {
    try {
      const response = await addGamificationService(data, url);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      console.log(error);
      onError && onError();
    }
  };

  return { actListGamification, actAddProfiler, actGetGamification, actEditGamification, actDeleteGamification, };
};

export default useGamificationActions;
