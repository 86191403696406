// Packages
import React from "react";

// Styles
import { StyledContentButtons } from "./MembeersWaiting.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";

const MembeersWaiting = () => {
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    Pagination,
    Typography,
    useModals,
  } = useComponents();

  const { IconUserStatus, IconUserRemove } = useIcons();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { formatDate } = useGeneralHooks();
  const { useMembeers } = useScreenHooks();
  const { useMembeersWaiting } = useMembeers();

  const {
    listMembeers,
    control,
    handleSubmit,
    onSubmit,
    handleChangeStatusMembeer,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    showModal,
    handleCloseModal,
    listCountries,
  } = useMembeersWaiting();

  return (
    <div className="bg-white px-8 pt-10 pb-12 mx-12 rounded-lg">
      <ContentWrappers
        gridColums={["1fr 210px"]}
        className="mb-4 items-center border-b border-neutral-60 pb-8"
      ></ContentWrappers>
      <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
        <TextFieldSeach
          placeholder="Buscar"
          name="search"
          id="search"
          control={control}
          onInputChange={onChangeSearch}
        />
        <StyledContentButtons>
          <FilterButton handleSubmit={handleSubmit(onSubmit)}>
            <>
              <SelectFieldControlled
                name="country_id"
                id="country_id"
                control={control}
                label="País"
                options={listCountries}
              />
              <SelectFieldControlled
                name="state"
                id="state"
                control={control}
                label="Estado"
                options={[
                  { label: "En espera", value: true },
                  { label: "Apto", value: false },
                ]}
              />
            </>
          </FilterButton>
        </StyledContentButtons>
      </ContentWrappers>
      <TableContent
        headerCellList={[
          "Membeer",
          "Edad",
          "Género",
          "Región",
          "Pais",
          "NSE",
          "Consumidor",
          "Estado",
          "Fecha",
          "Acciones",
        ]}
        gridCell={["2fr", "1fr", "1fr", "1fr", "1fr", "1fr", "1fr", "1fr", "1fr", ".5fr"]}
      >
        {listMembeers.length > 0 ? (
          listMembeers.map((membeer) => {
            return (
              <TableRow key={membeer.id}>
                <div>{membeer.membeer_name}</div>
                <div>{membeer.age}</div>
                <div>{membeer.gender}</div>
                <div>{membeer.abi_region?.abi_region}</div>
                <div>{membeer.country?.name}</div>
                <div>{membeer.nse}</div>
                <div>{membeer.consumer}</div>
                <div>
                  {!!membeer.has_quota ? (
                    <p className="text-success">Apto</p>
                  ) : (
                    <p className="text-neutral">En espera</p>
                  )}
                </div>
                <div title={formatDate(membeer.is_waiting_at, "Y-mm-dd HH:MM:SS")}>
                  {formatDate(membeer.is_waiting_at, "Y-mm-dd HH:MM:SS")}
                </div>
                <div>
                  {!!membeer.has_quota && (
                    <button type="button" onClick={() => handleChangeStatusMembeer(membeer)}>
                      <IconUserStatus />
                    </button>
                  )}
                </div>
              </TableRow>
            );
          })
        ) : (
          <div>Sin datos para mostrar</div>
        )}
      </TableContent>
      <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2">Datos cargados con exito</Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </div>
  );
};

export default MembeersWaiting;
