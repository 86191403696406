// Packages
import React from "react";

import useComponents from "../../../components";
import useLayouts from "../../../layouts";

// Controllers
import useControllers from "../../../../controllers";

const RecoveryPassword = () => {
  const { Wrapper, TextFieldControlled, useButtons, Typography } = useComponents();
  const { PublicContentLayout } = useLayouts();
  const { DefaultButton } = useButtons();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { usePassword } = useScreenHooks();
  const { usePasswordRecovery } = usePassword();
  const { control, handleSubmit, onSubmit, successApp, messageApp } = usePasswordRecovery();

  return (
    <PublicContentLayout login>
      <Wrapper
        showLogo={true}
        description={""}
        maxWidth={"582px"}
        className="container"
        title={"Cambio de contraseña."}
      >
        {!successApp ? <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <TextFieldControlled
              id={"password"}
              name={"password"}
              label={"Contraseña"}
              type={"password"}
              placeholder="******"
              control={control}
              helperText="La contraseña debe tener mínimo 8 caracteres y contener 1 número, 1 mayúscula y un carácter especial"
          />
          <TextFieldControlled
              id={"confirmPassword"}
              name={"confirmPassword"}
              label={"Confirmar contraseña"}
              type={"password"}
              placeholder="******"
              control={control}
          />
          <div className="text-center">
            <DefaultButton type="submit">Continuar</DefaultButton>
          </div>
        </form> :
            <div className={"text-center"}>
                <Typography variant="caption">{messageApp}</Typography>
                <a className="mt-4 block" href={`intent://${window.location.host}/launch#Intent;scheme=https;package=abi.membeer.com;end`} target="_blank">
                  <DefaultButton>Ir al app</DefaultButton>
                </a>
            </div>
        }
        {/* <div className="text-center cursor-pointer mt-2 hover:text-primary" onClick={()=>handleBack()}>
          <Typography variant="overline" className="underline">Log In</Typography>
        </div> */}
      </Wrapper>
    </PublicContentLayout>
  );
};

export default RecoveryPassword;
