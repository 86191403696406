import { useNavigate, useLocation, useMatch } from "react-router-dom";
import paymentsCreate from "./Create";
import paymentsRoot from "./View";
import paymentsDetail from "./Detail";
import { useEffect, useState } from "react";

const usePaymentsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [headerAux, setHeaderAux] = useState({});

  const handleBack = () => {
    navigate(-1);
  };

  const header = {
    "/pagos": ["Home", "Listado de pagos"],
    "/pagos/detalle/:countryId": ["Home", "Pagos", "Listado de pagos"],
  };

  const matchPagos = useMatch("/pagos");
  const matchPagosDetalle = useMatch("/pagos/detalle/:countryId");

  useEffect(() => {
    if (matchPagos) {
      setHeaderAux(header["/pagos"]);
    } else if (matchPagosDetalle) {
      setHeaderAux(header["/pagos/detalle/:countryId"]);
    }
  }, [location.pathname, matchPagos, matchPagosDetalle]);

  return { handleBack, header, paymentsRoot, paymentsCreate, paymentsDetail, headerAux };
};

export default usePaymentsScreen;
