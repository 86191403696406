import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import useControllers from "../../..";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

const useMembeersView = () => {
  const navigation = useNavigate();
  const [listMembeers, setListMembeers] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [valuesFilter, setValuesFilter] = useState({});
  const [showModal, setShowModal] = useState(false);
  // const [membeerChecked, setMembeerChecked] = useState(null);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });

  // Actions
  const { useActions } = useApi();
  const { useMembeersActions, useGeneralActions } = useActions();
  const { actListMembeers, actChangeStateMembeer, actInactiveMembeers } = useMembeersActions();
  const { actListCountries, actExportExel } = useGeneralActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast, useGeneral } = useQuickFunctions();
  const { filterMembeersValidationShema } = useValidators();
  const { success, error } = useToast();
  const { formatDate } = useGeneral();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const { control, handleSubmit, resetField } = useForm({
    mode: "all",
    resolver: yupResolver(filterMembeersValidationShema),
    defaultValues: {
      created_at: null,
    },
  });

  const loadData = (data) => {
    setListMembeers(data?.data);
    setPaginationSettings({ current: data?.meta.current_page, totalPages: data?.meta.last_page });
  };

  const loadDataPagination = (data) => {
    setListMembeers(data?.data);
  };

  const onChangeSuccess = (membeer) => {
    if (membeer.active === "Inactivo") {
      success(`Membeer ${membeer.membeer_name} ha sido habilitado.`);
    } else {
      error(`Membeer ${membeer.membeer_name} ha sido deshabilitado.`);
    }
    actListMembeers({ page: 1 }, loadData);
  };

  const handleChangeStatusMembeer = async (membeer) => {
    const state = membeer.active === "Activo" ? 0 : 1;
    await actChangeStateMembeer({ id: membeer.id, state: state }, onChangeSuccess(membeer));
    actListMembeers({ page: 1 }, loadData);
  };

  const onClickClear = () => resetField("created_at");

  const handlePagination = (nro) => {
    setPaginationSettings({ ...paginationSettings, current: nro.selected + 1 });
    actListMembeers(
      { page: nro.selected + 1, params: { ...valuesFilter, search: searchText } },
      loadDataPagination
    );
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setValuesFilter({
      ...(data.country_id && { country_id: data.country_id }),
      ...(data.gender && { gender: data.gender }),
      ...(data.state && { state: data.state }),
      ...(data.consumer && { consumer: data.consumer }),
      ...(data.nse && { nse: data.nse }),
      ...(!!data.created_at && { init_date: formatDate(data.created_at[0], "Y-mm-dd", false) }),
      ...(!!data.created_at &&
        !!data.created_at[1] && {
          end_date: formatDate(data.created_at[1], "Y-mm-dd", false),
        }),
      ...(data.last_date_entry && { last_date_entry: formatDate(data.last_date_entry, "Y-mm-dd", false) }),
    });

    actListMembeers(
      {
        page: 1,
        params: {
          ...(data.country_id && { country_id: data.country_id }),
          ...(data.gender && { gender: data.gender }),
          ...(data.state && { state: data.state }),
          ...(data.consumer && { consumer: data.consumer }),
          ...(data.nse && { nse: data.nse }),
          ...(!!data.created_at && { init_date: formatDate(data.created_at[0], "Y-mm-dd", false) }),
          ...(!!data.created_at &&
            !!data.created_at[1] && {
              end_date: formatDate(data.created_at[1], "Y-mm-dd", false),
            }),
          ...(data.last_date_entry && { last_date_entry: formatDate(data.last_date_entry, "Y-mm-dd", false) }),
          ...(searchText && { search: searchText }),
        },
      },
      loadData
    );
  };

  const onSubmitSearch = (e) => {
    setSearchText(e);
    actListMembeers({ page: 1, params: { ...valuesFilter, search: e } }, loadData);
  };

  const onChangeSearch = useCallback(debounce(onSubmitSearch, 750), []);

  const dataListCountries = (data) => {
    setListCountries(
      _.map(data, (v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const handleDownloadExcel = () => {
    const filtersProcces = prepareDataFilterDownload(valuesFilter);
    actExportExel(`UserInformationMember`, filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };
  const handleDownloadTemplate = () => {
    actExportExel(`impor`, "groupMembeer", (result) => {
      createDownloadExel(result);
    });
  };

  const handleCloseMembeers = (e) => {
    const onSuccess = () => {
      actListMembeers({ page: 1 }, loadData);
      setShowModal(true);
    };
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    actInactiveMembeers(formData, onSuccess);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleGoToWaiting = () => {
    navigation("/membeers/espera");
  };

  useEffect(() => {
    actListMembeers({ page: 1 }, loadData);
    actListCountries(dataListCountries);
  }, []);

  return {
    listMembeers,
    listCountries,
    control,
    handleSubmit,
    onSubmit,
    handleChangeStatusMembeer,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    handleDownloadExcel,
    onClickClear,
    handleCloseMembeers,
    handleCloseModal,
    showModal,
    handleDownloadTemplate,
    handleGoToWaiting,
  };
};

export default useMembeersView;
