import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useFaqsServices = () => {
  const { useFaqsProvider } = useProviders();
  const {
    listFaqsProvider,
    getFaqProvider,
    editFaqsProvider,
    addFaqsProvider,
    addFaqsListProvider,
    deleteFaqProvider
  } = useFaqsProvider();

  const listFaqsService = ({page, country_id, search, rol_id, status}) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(listFaqsProvider({page, search, country_id, status})));
      } catch (error) {
        reject(error);
      }
    })
  }

  const getFaqService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getFaqProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const editFaqsService = (id, data) => {
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(editFaqsProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    })
  }

  const addFaqsService = (data) => {
    return new Promise( async (resolve, reject) => {
      try {
        resolve(await trackPromise(addFaqsProvider(data)));
      } catch (error) {
        reject(error);
      }
    })
  }

  const addFaqsListService = (data) => {
    return new Promise( async (resolve, reject) => {
      try {
        resolve(await trackPromise(addFaqsListProvider({"faqs":data})));
      } catch (error) {
        reject(error);
      }
    })
  }

  const deleteFaqsService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteFaqProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listFaqsService,
    editFaqsService,
    addFaqsService,
    addFaqsListService,
    getFaqService,
    deleteFaqsService
  }

}

export default useFaqsServices;