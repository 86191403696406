import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import useApi from "../../../../api";
import useHelpers from "../../../../helpers";
import _ from "lodash";

const paymentsCreate = () => {
  const [showModal, setShowModal] = useState(false);
  const [textSucces, setTextSucces] = useState("");
  const [nameMission, setNameMission] = useState("");
  const [optionsMissions, setOptionsMissions] = useState([]);
  const [dataMissionToPay, setDataMissionToPay] = useState([]);
  const [disabledFields, setDisabledFields] = useState(false);

  const { useActions } = useApi();
  const { usePaymentsActions, dispatch } = useActions();
  const { actGetMissionToPay, actCreatePayments, actGetPayment, actEditPaymets } =
    usePaymentsActions();

  const { useQuickFunctions } = useHelpers();
  const { useValidators, useGeneral } = useQuickFunctions();
  const { paymetsSchemaCreate } = useValidators();

  const { dateFormatUTC } = useGeneral();

  const navigation = useNavigate();

  const { pagoId } = useParams();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(paymetsSchemaCreate),
  });

  const watchFieldMission = watch(["mission_id"]);

  const handleCloseModal = () => {
    setShowModal(false);
    navigation("/pagos");
  };

  const handleSuccesCreateEdit = (data) => {
    setNameMission(data.data.mission?.name);
    setShowModal(true);
  };

  const onSubmit = useCallback((data) => {
    const dataPayment = {
      pay_type: data.pay_type,
      pay_date: dateFormatUTC([data.date, data.time, data.ampm]),
      mission_id: data.mission_id,
      mail_subject: data.mail_subject,
      mail_message: data.mail_message,
      concept: data.concept,
      active: data.active === "1" ? true : false,
    };

    if (pagoId) {
      dispatch(actEditPaymets(pagoId, dataPayment, handleSuccesCreateEdit));
      setTextSucces("editado");
    } else {
      setTextSucces("creado");
      dispatch(actCreatePayments({ ...dataPayment, status: "nop" }, handleSuccesCreateEdit));
    }
  }, []);

  const loadData = (data) => {
    setOptionsMissions([
      {
        label: data.data.mission?.name,
        value: data.data.mission?.id,
      },
    ]);

    setValue("pay_type", `${data.data.pay_type}`);
    setValue("concept", data.data.concept);
    setValue("mail_message", `${data.data.mail_message}`);
    setValue("point_mission", `${data.data.mission?.app_points}`);
    setValue("mail_subject", data.data.mail_subject);
    setValue("mission_id", data.data.mission?.id);
    setValue("date", data.data.pay_date);
    setValue("time", data.data.pay_date);
    setValue("ampm", parseInt(data.data.pay_date.split(" ")[0].split(":")[0]) > 12 ? "pm" : "am");
    setValue("active", data.data.active ? "1" : "0");
  };

  const loadOptionsMission = (data) => {
    setOptionsMissions(
      _.map(data.data, (item) => {
        return {
          label: item.name,
          value: item.id,
        };
      })
    );

    setDataMissionToPay(data.data);
  };

  useEffect(() => {
    if (!pagoId) {
      const missionPoints = dataMissionToPay.find((mission) => mission.id === watchFieldMission[0]);

      setValue("point_mission", missionPoints?.app_points);
    }
  }, [watchFieldMission]);

  useEffect(() => {
    if (pagoId) {
      actGetPayment(pagoId, loadData);
      setDisabledFields(true);
    } else {
      setDisabledFields(false);
    }

    actGetMissionToPay(loadOptionsMission);
  }, []);

  return {
    control,
    handleSubmit,
    getValues,
    onSubmit,
    showModal,
    textSucces,
    nameMission,
    optionsMissions,
    disabledFields,
    handleCloseModal,
  };
};
export default paymentsCreate;
