import Home from "./Home";
import Login from "./Login";
import Tools from "./Tools";
import Password from "./Password";
import Users from "./Users";
import Projects from "./Projects";
import Tasks from "./Tasks";
import UsersGroup from "./UsersGroup";
import Notifications from "./Notification";
import News from "./News";
import Countries from "./Countries";
import Map from "./Map";
import Questions from "./Questions";
import Brands from "./Brands";
import Payments from "./Payments";
import Faqs from "./Faqs";
import Profiler from "./Profiler";
import Dashboard from "./Dashboard";
import Analytic from "./Analytic";
import Membeer from "./Membeers";
import PowerBI from "./PowerBI";
import LaunchApp from "./LaunchApp";
import Gamification from "./Gamification";
import Legal from "./Legal";
import PromptsMain from "./Prompts/Main";
import SubItems from "./SubItems";

const useScreens = () => {
  return {
    Login,
    Home,
    Tools,
    Users,
    Tasks,
    Projects,
    Password,
    UsersGroup,
    Notifications,
    News,
    Countries,
    Map,
    Questions,
    Brands,
    Payments,
    Faqs,
    Profiler,
    Dashboard,
    Analytic,
    Membeer,
    PowerBI,
    LaunchApp,
    Gamification,
    Legal,
    PromptsMain,
    SubItems
  };
};

export default useScreens;
