const useToolsInitialStates = () => {
  const addToolsInitialState = {
    toolsId: "",
    name: "",
    mission: "",
    sectionId: ""
  };

  const organizeToolsInitialStateAI = {
    id: "",
    name: "",
    questions: []
  };

  const savedQuestionsInitialState = [];

  const conditionalsListState = [];

  return {
    addToolsInitialState,
    organizeToolsInitialStateAI,
    savedQuestionsInitialState,
    conditionalsListState,
  }
};

export default useToolsInitialStates;