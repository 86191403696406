import React, { useMemo } from "react";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import useLayouts from "../../layouts";
import useControllers from "../../../controllers";

// Steps Views
import ToolsMain from "./Main";
import ToolsCreate from "./Create";
import PreviewTool from "./Preview";
import { AISurvey, Sections, Conditionals } from "./Survey";
import {
  PassiveTrackingReport,
  CheckpointReport,
  MembeerReport,
  PassiveTrackingCreate,
  PassiveTrackingMap,
} from "./PassiveTracking";
import { ReferredCreate } from "./Referred";
import { VideoCallCreate } from "./VideoCall";

const ToolsLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { handleBack, header } = useTools();

  const location = useLocation();

  const pathWithoutId = useMemo(() => {
    const pathArray = location.pathname.split("/");
    const filterArray = pathArray.filter((segment) => isNaN(segment));
    return filterArray.join("/");
  }, [location.pathname]);

  return (
    <PrivateContentLayout
      title="Herramientas"
      breadcrumbsList={header[`/${pathWithoutId}`]}
      handleBack={handleBack}
      showBack={location.pathname !== "/herramientas"}
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Tools = () => (
  <Routes>
    <Route path="/" element={<ToolsLayout />}>
      <Route index element={<ToolsMain />} />
      <Route path="crear-herramienta" element={<ToolsCreate />} />
      <Route path="preview/:id" element={<PreviewTool />} />
      <Route path="editar-herramienta/:id_tool" element={<ToolsCreate />} />
      <Route path="encuesta/ia" element={<AISurvey />} />
      <Route path="encuesta/:id_survey/secciones" element={<Sections />} />
      <Route path="encuesta/:id_survey/secciones/listado" element={<Sections />} />
      <Route path="encuesta/:id_survey/secciones/condicionales" element={<Conditionals />} />
      <Route path="passive-tracking/:id_passive_tracking/reporte" element={<PassiveTrackingReport />} />
      <Route path="passive-tracking/:id_passive_tracking/reporte/pasos/:id_step" element={<CheckpointReport />} />
      <Route path="passive-tracking/:id_passive_tracking/reporte/membeer/:id_membeer" element={<MembeerReport />} />
      <Route path="passive-tracking/:id_passive_tracking/crear" element={<PassiveTrackingCreate />} />
      <Route path="passive-tracking/:id_passive_tracking/editar" element={<PassiveTrackingCreate />} />
      <Route path="passive-tracking/:id_passive_tracking/mapa-de-lugares" element={<PassiveTrackingMap />} />
      <Route path="referidos/crear" element={<ReferredCreate />} />
      <Route path="referidos/editar" element={<ReferredCreate />} />
      <Route path="videollamadas/crear" element={<VideoCallCreate />} />
      <Route path="videollamadas/editar" element={<VideoCallCreate />} />
    </Route>
  </Routes>
);

export default Tools;
