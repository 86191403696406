const IconList = ({ color = "#6D7074" }) => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.809 1.525C1.809 1.02794 1.40606 0.625 0.909 0.625L0.795041 0.631055C0.347436 0.683044 0 1.06345 0 1.525C0 2.02206 0.402944 2.425 0.909 2.425L1.01396 2.41895C1.46156 2.36696 1.809 1.98655 1.809 1.525ZM18 1.525C18 1.02794 17.5971 0.625 17.1 0.625H5.4L5.29504 0.631055C4.84744 0.683044 4.5 1.06345 4.5 1.525C4.5 2.02206 4.90294 2.425 5.4 2.425H17.1L17.205 2.41895C17.6526 2.36696 18 1.98655 18 1.525ZM18 6.925C18 6.42794 17.5971 6.025 17.1 6.025H5.4L5.29504 6.03105C4.84744 6.08304 4.5 6.46345 4.5 6.925C4.5 7.42206 4.90294 7.825 5.4 7.825H17.1L17.205 7.81894C17.6526 7.76696 18 7.38655 18 6.925ZM17.1 11.425C17.5971 11.425 18 11.8279 18 12.325C18 12.7866 17.6526 13.167 17.205 13.2189L17.1 13.225H5.4C4.90294 13.225 4.5 12.8221 4.5 12.325C4.5 11.8634 4.84744 11.483 5.29504 11.4311L5.4 11.425H17.1ZM0.909 6.025C1.40606 6.025 1.809 6.42794 1.809 6.925C1.809 7.38655 1.46156 7.76696 1.01396 7.81894L0.909 7.825C0.402944 7.825 0 7.42206 0 6.925C0 6.46345 0.347436 6.08304 0.795041 6.03105L0.909 6.025ZM1.809 12.325C1.809 11.8279 1.40606 11.425 0.909 11.425L0.795041 11.4311C0.347436 11.483 0 11.8634 0 12.325C0 12.8221 0.402944 13.225 0.909 13.225L1.01396 13.2189C1.46156 13.167 1.809 12.7866 1.809 12.325Z"
        fill={color}
      />
    </svg>
  );
};

export default IconList;
