import useApi from "../../index";

const useProfilerActions = () => {
  const { useServices } = useApi();
  const { useProfileServices } = useServices(); 
  
  const { listProfilersService, addProfilersService, getProfilersService, editProfilerService } = useProfileServices();


    const actListProfilers = async (url, { page }, onSuccess, onError) => {                    
      try {
        const response = await listProfilersService(url, { page });        
        onSuccess && onSuccess(response.data);
      } catch (error) {
        onError && onError();
      }
    }
  
    const actGetProfiler = async (id, url, onSuccess, onError ) => {
      try {
        const response = await getProfilersService(id, url);
        onSuccess && onSuccess(response.data.data);      
      } catch(error) {
        console.log(error);
        onError && onError();
      } 
    }
  
    const actEditProfiler = async ({id, data, url}, onSuccess, onError) => {
      try {
        const response = await editProfilerService({id, data, url});
        onSuccess && onSuccess(response.data?.data);
      } catch (error) {
        onError && onError();
      }
    }

    const actAddProfiler = async (data, url, onSuccess, onError) => {
      try {
        const response = await addProfilersService(data, url);
        onSuccess && onSuccess(response.data.data);
      } catch (error) {
        console.log(error)
        onError && onError();
      }
    }
  
    return { actListProfilers, actGetProfiler, actAddProfiler, actEditProfiler };
  
  };
  
  export default useProfilerActions;