// Packages
import React from "react";

// Controllers
import useControllers from "../../../../controllers";

// Components
import useComponents from "../../../components";

const TasksClone = () => {
  const { useScreenHooks } = useControllers();
  const { useTasks } = useScreenHooks();
  const { useTasksClone } = useTasks();
  const {
    control,
    handleSubmit,
    onSubmit,
    listProject,
  } = useTasksClone();

  const {
    useButtons,
    SelectFieldControlled,
    Typography,
  } = useComponents();

  const { DefaultButton } = useButtons();

  return (
    <div className="bg-white px-16 pt-10 pb-12 mx-auto mt-12 rounded-lg max-w-xl">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-6">
        <Typography variant="h2">ASOCIAR MISIÓN A UN PROYECTO</Typography>
        <Typography variant="caption">Selecciona el proyecto al cual estará asociada la misión o haz click en el botón <b>siguiente</b> si deseas asociar el proyecto posteriormente</Typography>
        <SelectFieldControlled
          label={"Asociar al proyecto"}
          placeholder={"Nombre del proyecto"}
          name={`project_id`}
          id={`abi-task-project`}
          control={control}
          options={listProject}
        />
        <div className="text-center">
          <DefaultButton color="success" type="submit">
            Siguiente
          </DefaultButton>
        </div>
      </form>
    </div>
  );
};

export default TasksClone;
