// Packages
import React from 'react';

import useComponents from '../../components';
import useLayouts from '../../layouts';

// Controllers
import useControllers from '../../../controllers'

const Home = () => {
  const { Wrapper, TextFieldControlled, useButtons } = useComponents();
  const {DefaultButton} = useButtons();
  const { PublicContentLayout } = useLayouts();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useLogin } = useScreenHooks();
  const { control, handleSubmit, onSubmit } = useLogin();

  return (
    <PublicContentLayout>
      <Wrapper
        showLogo={true}
        title={'Bienvenido a member'}
        description={''}
        maxWidth={'580px'}
        className="container"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextFieldControlled
            id={'email'}
            name={'email'}
            label={'Usuario'}
            type={'email'}
            control={control}
          />
          <TextFieldControlled
            id="password"
            label="Contraseña"
            name={'password'}
            type={'password'}
            control={control}
          />

          <DefaultButton
            type="submit"
          >
            INGRESAR
          </DefaultButton>
        </form>
      </Wrapper>
    </PublicContentLayout>
  );
};

export default Home;
