import useHelpers from "../../../helpers";

const useToolsSelectors = () => {
  // Helpers
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const addToolsSelected = createSelector(
    state => state.addTools,
    addToolsSelected => addToolsSelected
  );

  const savedQuestionsSelector = createSelector(
    state => state.savedQuestions,
    savedQuestionsSelector => savedQuestionsSelector
  );

  const conditionalListSelector = createSelector(
    state => state.conditionalsList,
    conditionalListSelector => conditionalListSelector
  );

  return {
    addToolsSelected,
    savedQuestionsSelector,
    conditionalListSelector,
  }
};

export default useToolsSelectors;