import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useMissionServices = () => {
  const { useMissionProvider } = useProviders();
  const {
    listMissionsProvider,
    getMissionProvider,
    cloneMissionProvider,
    createMissionProvider,
    deleteMissionProvider,
    editMissionProvider,
    getMissionPreviewProvider,
    getToolPreviewProvider,
    getMissionMembeersProvider,
    approveToolProvider,
  } = useMissionProvider();

  const listMissionsService = ({ page, search, project_id, init_date, end_date, status, country_id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            listMissionsProvider({ page, search, project_id, init_date, end_date, status, country_id })
          )
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const getMissionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getMissionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };
  const deleteMissionService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteMissionProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const cloneMissionService = ({ id, project_id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(cloneMissionProvider({ id, project_id })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const createMissionService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createMissionProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const editMissionService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editMissionProvider(id, data)));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const getMissionMembeersService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getMissionMembeersProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const getMissionPreviewService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getMissionPreviewProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const getToolPreviewService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getToolPreviewProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const approveToolService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(approveToolProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listMissionsService,
    getMissionService,
    cloneMissionService,
    createMissionService,
    deleteMissionService,
    editMissionService,
    getMissionPreviewService,
    getToolPreviewService,
    getMissionMembeersService,
    approveToolService,
  };
};

export default useMissionServices;
