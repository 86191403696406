// Packages
import { useCallback, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useLocation } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useActions from "../../../../api/actions";

// Components
import useComponents from "../../../../views/components";

const usePasswordRecovery = () => {
  const { token, tokenApp } = useParams();
  const location = useLocation();

  const [successApp, setSuccessApp] = useState(false)
  const [messageApp, setMessageApp] = useState('')

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast, NativeAppLauncher } = useQuickFunctions();
  const { recoveryPasswordValidatorSchema } = useValidators();
  const { replaceAndNavigateTo } = useNavigation();
  const { warn, success } = useToast();

  // Actions
  const { useAuthActions } = useActions();
  const { actRecoveryPassword, actRecoveryPasswordApp } = useAuthActions();

  // Components
  const { Toast } = useComponents();

  const handleBack = () => {
    replaceAndNavigateTo("/");
  };

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(recoveryPasswordValidatorSchema),
  });

  const onSubmit = useCallback(({ password }) => {
    const onSuccessLogin = (data) => {
      if (data.status === 200) {
        success(<Toast text={data.data.message} />, {
          toastId: 0,
        });
        replaceAndNavigateTo("/");
      } else {
        warn(<Toast text={data.data.message} />, {
          toastId: 0,
        });
      }
    };

    const onSuccessLoginApp = (data) => {
      if (data.status === 200) {
        success(<Toast text={data.data.message} />, {
          toastId: 0,
        });
        setSuccessApp(true)
        setMessageApp(data.data.message)
        handleOpenApp()
      } else {
        warn(<Toast text={data.data.message} />, {
          toastId: 0,
        });
      }
    }

    if (tokenApp === undefined) {
      actRecoveryPassword(
        { email: location.search.split("=")[1], password, token },
        onSuccessLogin
      );
    } else {
      actRecoveryPasswordApp(
        { email: location.search.split("=")[1], password, tokenApp },
        onSuccessLoginApp
      );
    }
  }, []);

  const handleOpenApp = () => {
    if (isAndroid) {
      window.location.replace(`intent://${window.location.host}/launch#Intent;scheme=https;package=abi.membeer.com;end`);
    } else if (isIOS) {
      /* window.location.replace("instagram://");

      setTimeout(() => {
        window.location.replace(
          "https://apps.apple.com/us/app/instagram/id389801252"
        );
      }, 10000); */
      alert("Lo sentimos, pero aun no contamos con app para IOS")
      handleBack()
    }
  }

  useEffect(() => {
    if ((!token && !tokenApp) || !location.search.split("=")[1]) replaceAndNavigateTo("/");    
  }, []);

  return {
    control,
    handleSubmit,
    onSubmit,
    handleBack,
    successApp,
    messageApp
  };
};

export default usePasswordRecovery;
