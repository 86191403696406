const useAuthInitialStates = () => {
  const sessionInitialState = {
    authToken: "",
    email: "",
    name: "",
    lastname: "",
    rol: "",
    country: [],
    loader: false
  };

  return {
    sessionInitialState,
  };
};

export default useAuthInitialStates;
