// Packages
import React, { Fragment } from "react";

// Components
import useComponents from "../../../../../components";
import SelectComponent from "./SelectComponent";

// Styles
import {
  StyledConditionalsContent,
  StyledConditionalsHeaderItems,
  StyledConditionalsHeader,
  StyledAccordionQuestion,
  StyledQuestionList,
} from "./Conditionals.styles";

// Controllers
import useControllers from "../../../../../../controllers";

const Conditionals = () => {
  // Components
  const { CardWrapper, ContentWrappers, Steps, Typography, useButtons, useAccordion, useModals } =
    useComponents();
  const { DefaultButton } = useButtons();
  const { AccordionContent, AccordionHeader, AccordionBody } = useAccordion();
  const { DefaultModal } = useModals();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useSurvey } = useTools();
  const { useConditionals } = useSurvey();
  const {
    sectionList,
    sectionInfo,
    handleConditionals,
    questionsList,
    handleCloseModal,
    showModal,
    showModalError,
    searchQuestionId,
    handleCloseModalError,
    idError,
  } = useConditionals();

  return (
    <>
      <ContentWrappers
        gridColums={["1fr"]}
        gap={{
          row: "0px",
          column: "20px",
        }}
        contentWidth="892px"
        as="section"
      >
        <Steps stepList={["Secciones y preguntas", "Condicionales"]} activeIndex={3} />
        <CardWrapper padding="2rem">
          <StyledConditionalsHeader gridColums={["330px", "395px", "102px"]}>
            <StyledConditionalsHeaderItems>
              <Typography variant="h5">MISIÓN</Typography>
              <Typography variant="caption">{sectionInfo.missionName}</Typography>
            </StyledConditionalsHeaderItems>
            <StyledConditionalsHeaderItems>
              <Typography variant="h5">ENCUESTA</Typography>
              <Typography variant="caption">{sectionInfo.surveyName}</Typography>
            </StyledConditionalsHeaderItems>
            <StyledConditionalsHeaderItems>
              <Typography variant="h5">PAÍS</Typography>
              <Typography variant="caption">{sectionInfo.countryName}</Typography>
            </StyledConditionalsHeaderItems>
          </StyledConditionalsHeader>

          <StyledConditionalsContent>
            <Typography variant="h2">3. CONDICIONALES</Typography>
            {questionsList.map((section, index) => {
              return (
                <div key={index}>
                  <AccordionContent>
                    <AccordionHeader>
                      <div>
                        <span>{index + 1}</span>
                        <h4 className="text-base uppercase font-oswald">{section.title}</h4>
                      </div>
                    </AccordionHeader>
                    <AccordionBody>
                      {section && section.questions.length > 0 ? (
                        section.questions.map((question, indexQuestion) => {
                          return (
                            <Fragment key={indexQuestion}>
                              <StyledAccordionQuestion>
                                <span>{indexQuestion + 1}</span>
                                <AccordionContent type="secondary">
                                  <AccordionHeader arrowColor="#FFA700">
                                    <h4 className="pl-4 text-sm">{question.title}</h4>
                                  </AccordionHeader>
                                  <AccordionBody>
                                    {question.question_type === "Radio" ||
                                    question.question_type === "Checkbox" ? (
                                      <StyledQuestionList type="A">
                                        {question.question?.options.map((option, indexOption) => {
                                          return (
                                            <SelectComponent
                                              index={indexOption}
                                              condition={option.name}
                                              optionName={option.name}
                                              questionId={question.id}
                                              key={`multiple-${indexOption}`}
                                              error={searchQuestionId(
                                                question.id,
                                                indexOption,
                                                indexQuestion,
                                                index
                                              )}
                                              sectionList={sectionList}
                                              questionSelected={
                                                question.condition[option.name]?.question
                                              }
                                              sectionSelected={
                                                question.condition[option.name]?.section
                                              }
                                              endSurvey={
                                                question.condition[option.name]?.end_survey
                                              }
                                              disabled={section.isActiveMissionFlag}
                                            />
                                          );
                                        })}
                                      </StyledQuestionList>
                                    ) : (
                                      <>
                                        {question.question_type === "Matrix" ? (
                                          <div>
                                            {question.question?.rows.map((row, indexRow) => {
                                              return (
                                                <div key={`row-${indexRow}`} className="mb-6">
                                                  <h4 className="text-sm">
                                                    {indexRow + 1}. {row}
                                                  </h4>
                                                  <StyledQuestionList type="A">
                                                    {question.question?.columns.map(
                                                      (column, indexColumn) => {
                                                        return (
                                                          <SelectComponent
                                                            condition={`row:${row} - answer:${column.name}`}
                                                            optionName={column.name}
                                                            questionId={question.id}
                                                            key={`column-${indexColumn}`}
                                                            sectionList={sectionList}
                                                            questionSelected={
                                                              question.condition[
                                                                `row:${row} - answer:${column.name}`
                                                              ]?.question
                                                            }
                                                            sectionSelected={
                                                              question.condition[
                                                                `row:${row} - answer:${column.name}`
                                                              ]?.section
                                                            }
                                                            endSurvey={
                                                              question.condition[
                                                                `row:${row} - answer:${column.name}`
                                                              ]?.end_survey
                                                            }
                                                            disabled={section.isActiveMissionFlag}
                                                          />
                                                        );
                                                      }
                                                    )}
                                                  </StyledQuestionList>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        ) : (
                                          <StyledQuestionList type="A">
                                            <SelectComponent
                                              condition="Si"
                                              optionName="Si"
                                              questionId={question.id}
                                              sectionList={sectionList}
                                              questionSelected={question.condition["Si"]?.question}
                                              sectionSelected={question.condition["Si"]?.section}
                                              endSurvey={question.condition["Si"]?.end_survey}
                                              disabled={section.isActiveMissionFlag}
                                            />
                                            <SelectComponent
                                              condition="No"
                                              optionName="No"
                                              questionId={question.id}
                                              sectionList={sectionList}
                                              questionSelected={question.condition["No"]?.question}
                                              sectionSelected={question.condition["No"]?.section}
                                              endSurvey={question.condition["No"]?.end_survey}
                                              disabled={section.isActiveMissionFlag}
                                            />
                                          </StyledQuestionList>
                                        )}
                                      </>
                                    )}
                                  </AccordionBody>
                                </AccordionContent>
                              </StyledAccordionQuestion>
                            </Fragment>
                          );
                        })
                      ) : (
                        <Typography variant="caption">
                          Esta sección no tiene condicionales
                        </Typography>
                      )}
                    </AccordionBody>
                  </AccordionContent>
                </div>
              );
            })}
            <div className="flex justify-center mt-8">
              <DefaultButton type="button" color="secondary" onClick={handleConditionals}>
                Finalizar Herramienta
              </DefaultButton>
            </div>
          </StyledConditionalsContent>
        </CardWrapper>
        <DefaultModal
          isOpen={showModal}
          handledButton={handleCloseModal}
          children={
            <div>
              La encuesta <strong>{sectionInfo.surveyName}</strong> <br /> ha sido publicada con
              éxito.
            </div>
          }
        />
        <DefaultModal
          isOpen={showModalError?.bool}
          type="warning"
          handledButton={handleCloseModalError}
          textButton="Corregir"
          children={
            <div>
              Hay condicionales cíclicos o irregulares en la pregunta{" "}
              <strong>
                {idError.current.index}-{idError.current.id}
              </strong>{" "}
              <br />
              Revísa e intenta de nuevo.
            </div>
          }
        />
      </ContentWrappers>
    </>
  );
};

export default Conditionals;
