// Packages
import React from 'react';
import { useController } from "react-hook-form";

// Components
import useComponents from '..';

// Styles
import { StyledQuestionsTextResponse } from './Questions.styles';

const MaskResponse = (props) => {
  const { control } = props;

  const {
    field: { value },
  } = useController({
    name: 'type_mask',
    control,
  });

  const { RadioButtonControlled, Typography, InputFileControlled, TextAreaFieldControlled, TextFieldControlled } = useComponents();

  return (
    <StyledQuestionsTextResponse>
      <Typography variant="caption" >Tipo de información</Typography>
      <RadioButtonControlled
        control={ control }
        radioOptions={[
          {
            value: "file",
            label: "Multimedia",
          },
          {
            value: "text",
            label: "Texto",
          },
          {
            value: "url",
            label: "Enlace",
          }
        ]}
        id="type_mask"
        name="type_mask"
      />
      {value === 'file' && <InputFileControlled 
        id="file"
        name="file"
        control={control}
        label="Cargar archivo"
        placeholder="Selecciona un archivo"
      />}
      {value === 'text' && <TextAreaFieldControlled
        name="text"
        id="text"
        placeholder="Ingresa el texto"
        control={ control }
        label="Texto informativo"
      />}
      {value === 'url' && <TextFieldControlled 
          label="URL"
          name="url"
          placeholder="Ingresa la url"
          type="text"
          id="url"
          control={ control }
      />}
    </StyledQuestionsTextResponse>
  )
}

export default MaskResponse;