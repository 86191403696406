import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useGeneralServices = () => {
  const { useGeneralProvider } = useProviders();
  const {
    listCountriesProvider,
    listCitiesProvider,
    listCountriesAllProvider,
    listLenguajesProvider,
    listCitiesByStateProvider,
    listStatesByCountryProvider,
    exportExelProvider,
    exportProfileExelProvider,
    listRegionsProvider,
    listAbiRegionsProvider,
    listAuxAbiRegionsProvider,
    getNotificationProvider,
  } = useGeneralProvider();

  const listCountriesService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listCountriesProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listCountriesAllService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listCountriesAllProvider(data)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listCitiesService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listCitiesProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listCitiesByStateService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listCitiesByStateProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listStatesByCountryService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listStatesByCountryProvider(id)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listLenguajesService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listLenguajesProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listRegionsService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listRegionsProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listAbiRegionsService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listAbiRegionsProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const listAuxAbiRegionsService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(listAuxAbiRegionsProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const exportExelService = (url, filters, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(exportExelProvider(url, filters, params)));
      } catch (error) {
        reject(error);
      }
    });
  };

  const exportExelProfileService = (url, filters) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(exportProfileExelProvider(url, filters)));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const getNotificationService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getNotificationProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listRegionsService,
    listAbiRegionsService,
    listAuxAbiRegionsService,
    listCountriesService,
    listCitiesService,
    listLenguajesService,
    listCountriesAllService,
    listCitiesByStateService,
    listStatesByCountryService,
    exportExelService,
    exportExelProfileService,
    getNotificationService,
  };
};

export default useGeneralServices;
