import React from "react";
import { Routes, Route, useMatch, useLocation, Outlet } from "react-router-dom";

// Components
import useLayouts from "../../layouts";

// Controllers
import useControllers from "../../../controllers";

// Steps Views
import NotificationView from "./Views";
import NotificationCreate from "./Create";

const NotificationsLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const location = useLocation();

  const { useScreenHooks } = useControllers();
  const { useNotification } = useScreenHooks();
  const { handleBack, header } = useNotification();

  const match = useMatch("/notificaciones/*");
  const basePath = match ? match.pathnameBase : "/notificaciones";
  const isExact = basePath === location.pathname;

  const breadcrumbsList = location.pathname.split("/").length > 2
    ? header[Object.keys(header).filter((header) => location.pathname.includes(header))[1]]
    : header[Object.keys(header).filter((header) => location.pathname.includes(header))];

  return (
    <PrivateContentLayout
      breadcrumbsList={breadcrumbsList}
      handleBack={handleBack}
      showBack={!isExact}
      title="Notificaciones"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Notifications = () => (
  <Routes>
    <Route path="/" element={<NotificationsLayout />}>
      <Route index element={<NotificationView />} />
      <Route path="creacion" element={<NotificationCreate />} />
      <Route path="editar/:id" element={<NotificationCreate />} />
    </Route>
  </Routes>
);

export default Notifications;
