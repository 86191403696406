import useServices from "../../services";

const useMapActions = () => {
  const { useMapServices } = useServices();
  const { locationsService, locationsPointsService } = useMapServices();

  const actLocations = async ({}, onSuccess, onError) => {
    try {
      const response = await locationsService();
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actLocationsPoints = async (params,
    onSuccess,
    onError
  ) => {
    try {
      const response = await locationsPointsService(params);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };
  return { actLocations, actLocationsPoints };
};

export default useMapActions;
