const IconArrowStadisctics = ({ color = "#E14731", className }) => {
  return (
    <svg
      className={className}
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 4.33398L0.5 0.333984H8.5L4.5 4.33398Z" fill={color} />
    </svg>
  );
};

export default IconArrowStadisctics;
