// Packages
import { useState, useEffect, useCallback } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "lodash.debounce";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

// Components
import useComponents from "../../../../views/components";

// Hook
import useControllers from "../../..";

const useGroupCreate = () => {
  const navigation = useNavigate();
  const { id_group } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sendImport, setSendImport] = useState(false);
  const [auxCountries, setAuxCountries] = useState([]);
  const [listSelectCounties, setListSelectCounties] = useState([]);
  const [listAgeRanges, setListAgeRanges] = useState([]);
  const [listNSE, setListNSE] = useState([]);
  const [listSelectTool, setListSelectTool] = useState({ index: null, list: [] });
  const [listSection, setListSection] = useState({ index: null, list: [] });
  const [listOptionsTool, setListOptionsTool] = useState({ index: null, list: [] });
  const [listQuestions, setListQuestions] = useState({ index: null, list: [] });
  const [listAnswer, setListAnswer] = useState({ index: null, list: [] });
  const [listCountries, setListCountries] = useState([]);
  const [numberMembeers, setNumberMembeers] = useState(0);
  const [activateUser, setActivateUser] = useState("Inactivo");
  const [disableFields, setDisableFields] = useState(false);
  const [groupName, setGroupName] = useState(null);

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast } = useQuickFunctions();
  const { newGroupValidationShema } = useValidators();
  const { success } = useToast();

  // Actions
  const { useActions } = useApi();
  const { useGroupsActions, useGeneralActions, useToolsActions, dispatch } = useActions();
  const { actAddGroups, actGetGroups, actEditGroups, getNumberGroups } = useGroupsActions();
  const { actListAllCountries, actExportExel } = useGeneralActions();
  const { actListTools, actGetTool } = useToolsActions();

  // Components
  const { Toast } = useComponents();

  // Hooks
  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const { control, handleSubmit, reset, watch, getValues, setValue } = useForm({
    mode: "all",
    resolver: yupResolver(newGroupValidationShema),
    defaultValues: {
      parameters: [{ tool_id: [], section: "", question_id: "", answer: [] }],
    },
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "parameters",
  });

  const handleCloseModal = () => {
    setShowModal(false);
    navigation(-1);
  };

  const handleChangeActivate = () => {
    setActivateUser(activateUser === "Inactivo" ? "Activo" : "Inactivo");
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const onSubmitEditSuccess = () => {
    success(<Toast text={"Grupo editado con éxito!"} />, {
      toastId: 0,
    });
    navigation(-1);
  };

  const onSubmit = async (data) => {
    setGroupName(data.name);
    const formData = new FormData();
    formData.append('name', data.name)
    formData.append('type', data.typeList ? (data.typeGroup ? "gyl" : "ldd") : data.typeGroup ? "gpm" : "")
    formData.append('countries', data.countries?.reduce((prev, curr) => prev + curr.value + ",", ""))
    formData.append('departments', data.departments?.reduce((prev, curr) => prev + curr.value + ",", ""))
    formData.append('regions', data.regions?.reduce((prev, curr) => prev + curr.value + ",", ""))
    formData.append('abi_regions', data.abi_regions?.reduce((prev, curr) => prev + curr.value + ",", ""))
    formData.append('nse', data.nse?.reduce((prev, curr) => prev + curr.value + ",", ""))
    formData.append('ages', data.ages?.reduce((prev, curr) => prev + curr.value + ",", ""))
    formData.append('genders', data.genders?.reduce((prev, curr) => prev + curr.value + ",", ""))
    formData.append('audiences', data.audiences?.reduce((prev, curr) => prev + curr.value + ",", ""))
    data.parameters.forEach((v) => {
      formData.append('parameters[]', JSON.stringify({
        tool_id: v.tool_id[0]?.value ?? "",
        question_id: v.question_id,
        answer: v.answer?.reduce((prev, curr) => prev + curr.value + ",", ""),
      }))
    })
    if(data.listGroup)
      formData.append('file', data.listGroup[0])

    if (id_group) {
      setSaving(true);
      await actEditGroups(id_group, formData, onSubmitEditSuccess);
      setSaving(false);
    } else {
      setSaving(true);
      await actAddGroups(formData, handleShowModal);
      setSaving(false);
    }
  };

  const prepareDataCountry = (data) => {
    setAuxCountries(data);
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };
  const prepareDataCountryEdit = (country) => (data) => {
    setAuxCountries(data);
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
    const auxIdArr = country.map((v) => {
      return v.id;
    });
    const countriesSelected = data.filter((country) => auxIdArr.find((v) => v === country.id));    
    setListSelectCounties(countriesSelected);
    prepareAgeRangeData(countriesSelected);
    prepareNSEData(countriesSelected);
  };

  const prepareAgeRangeData = (data) => {
    const ranges = data.map(x=> x.ages).map(item => Object.entries(item).map(x => { return { label:x[1].age_range, value:x[1].age_range } })).reduce((prev, curr) => prev.concat(curr), [])
    setListAgeRanges(ranges);
  }

  const prepareNSEData = (data) => {
    setListNSE(
      data.map(x=> x.nse_ranges).map(item => Object.entries(item).map(x => { return { label:x[1].nse, value:x[1].nse } } )).reduce((prev, curr) => prev.concat(curr), [])
      )
  }

  const loadDataOption = (index, data) => {
    const auxListOptionsTools = listOptionsTool.list;
    auxListOptionsTools[index] = data.data.map((v) => {
      return { label: v.name, value: v.id, type: v.type_tool };
    });
    setListOptionsTool({ index: index, list: auxListOptionsTools });
  };

  const onSubmitSearch = (index, e) => {
    if (e.length >= 3) {
      dispatch(actListTools({ page: 1, search: e }, (data) => loadDataOption(index, data)));
    }
  };

  const onChangeTool = async (index, data) => {
    const onSuccess = (data) => {
      const auxListSectionTool = listSelectTool.list,
        auxListSection = listSection.list;
      auxListSectionTool[index] = data;
      auxListSection[index] = data?.details?.sections?.map((v) => {
        return { label: v.title, value: v.id };
      });
      setListSelectTool({ index: index, list: auxListSectionTool });
      setListSection({ index: index, list: auxListSection });
      onSetNumber()
    };
    if (data[0]?.type === "Survey") {
      await dispatch(actGetTool(data[0].value, onSuccess));
    }
  };

  const onChangeSection = async (index, data) => {
    const auxListQuestion = listQuestions.list;
    auxListQuestion[index] = listSelectTool?.list[index]?.details?.sections
      ?.find((v) => v.id === data.target.value)
      ?.questions?.map((v) => {
        return { ...v, label: v.title, value: v.id };
      });
    await setListQuestions({ index: index, list: auxListQuestion });
    onSetNumber()
  };

  const onChangeQuestion = async (index, data) => {
    const auxListAnswer = listAnswer.list;
    if(data.target.value !== '' && data.target.value !== null && !isNaN(data.target.value)) {
      const listFilter = listQuestions.list[index].find(
        (v) => v.id === data.target.value
      )
      const typeQuestion = listFilter.question_type;
      if (typeQuestion === "Binary") {
        auxListAnswer[index] = [
          { label: "Si", value: true },
          { label: "No", value: false },
        ];
      } else if (typeQuestion === "Checkbox" || typeQuestion === "Radio") {
        auxListAnswer[index] = listQuestions.list[index]
          .find((v) => v.id === data.target.value)
          .question.options.map((v) => {
            return { label: v.name, value: v.name };
          });
      } else if (typeQuestion === "Matrix") {
        auxListAnswer[index] = listQuestions.list[index]
          .find((v) => v.id === data.target.value)
          .question.options.map((v) => {
            return { label: v.name, value: v.value };
          });
      } else if (typeQuestion === "Range") {
        auxListAnswer[index] = Array.from({length: listFilter.question.value_end - listFilter.question.value_start + 1}, (_, i) => {return {label: (listFilter.question.value_start + i).toString(), value: listFilter.question.value_start + i}})
        //console.log(listFilter, index + listFilter.title, auxListAnswer[index])
      } else {
        auxListAnswer[index] = [];
      }
      await setListAnswer({ index: index + listFilter.title, list: auxListAnswer });
    }else {
      auxListAnswer[index] = [];
      await setListAnswer({ index: index, list: auxListAnswer });
    }
    onSetNumber()
  };

  const onChangeSearch = useCallback(debounce(onSubmitSearch, 750), []);

  const onChangeCountry = (data) => {
    setValue("departments", []);
    setValue("regions", []);
    setValue("abi_regions", []);
    setValue("nse", []);
    setValue("ages", []);
    setValue("genders", []);

    const auxIdArr = data.map((v) => {
      return v.value;
    });
    const countriesSelected = auxCountries.filter((country) =>
      auxIdArr.find((v) => v === country.id)
    );    
    setListSelectCounties(countriesSelected);
    prepareAgeRangeData(countriesSelected);
    prepareNSEData(countriesSelected);
    onSetNumber()
  };

  const onChangeDeparment = () => {
    setValue("regions", []);    
    setValue("nse", []);
    setValue("ages", []);
    setValue("genders", []);
    onSetNumber()
  }

  const onChangeRegion = () => {
    setValue("nse", []);
    setValue("ages", []);
    setValue("genders", []);
    onSetNumber()
  }

  const onChangeRegionABI = () => {
    setValue("departments", []);
    setValue("regions", []);
    setValue("nse", []);
    setValue("ages", []);
    setValue("genders", []);
    onSetNumber()
  }

  const handleGoBack = () => {
    navigation(-1);
  };

  const listResumen = [
    { label: "Nombre del grupo", value: watch("name") },
    {
      label: "Pais",
      value: watch("countries")?.reduce((prev, curr) => prev + curr.label + ", ", ""),
    },
    {
      label: "Estado/Departamento",
      value: watch("departments")?.reduce((prev, curr) => prev + curr.label + ", ", ""),
    },
    {
      label: "Región",
      value: watch("regions")?.reduce((prev, curr) => prev + curr.label + ", ", ""),
    },
    {
      label: "Región ABI",
      value: watch("abi_regions")?.reduce((prev, curr) => prev + curr.label + ", ", ""),
    },
    { label: "NSE", value: watch("nse")?.reduce((prev, curr) => prev + curr.label + ", ", "") },
    {
      label: "Rango de edad",
      value: watch("ages")?.reduce((prev, curr) => prev + curr.label + ", ", ""),
    },
    {
      label: "Genero",
      value: watch("genders")?.reduce((prev, curr) => prev + curr.label + ", ", ""),
    },
    {
      label: "Audiencias",
      value:watch("audiences")?.reduce((prev, curr) => prev + curr.label + ", ", ""),
    },
    {
      label: "Heramientas",
      value: watch("parameters")?.reduce((prev, curr) => {
        const tool = curr?.tool_id[0] ? curr?.tool_id[0]?.label ? curr?.tool_id[0]?.label : '' : ''
        return prev + tool + ", "
      }, ""),
    },
    {
      label: "Preguntas",
      value: watch("parameters")?.reduce((prev, curr, index) => {
        const question = listQuestions?.list[index]?.find(
          (v) => v.id === curr?.question_id
        )?.label ?? ''
        return prev + question + ", "
      }, ""),
    },
    {
      label: "Respuestas",
      value: watch("parameters")?.reduce((prev, curr) => {
        return prev + curr.answer?.map( v => v.label).join(', ') + ", "
      }, ""),
    },
    { label: "Cantidad Membeer", value: numberMembeers },
  ];

  const resetData = async (data) => {
    const parametersData = data.parameters.map((v) => {
      const auxAnswerName = v.answer_name?.split(",")?.filter((v) => v !== "");
      return {
        tool_id: v.tool_id
          ? [{ label: v.tool_name ?? "", value: v.tool_id, type: v.tool_type }]
          : [],
        section: v.section_id,
        question_id: v.question_id,
        answer:
          v.question_type === "Checkbox" || v.question_type === "Radio" || v.question_type === "Range"
            ? v.answer?.split(",")
                .filter((v) => v !== "")
                .map((v) => {
                  return { label: v, value: v };
                })
            : v.question_type === "Binary"
            ? v.answer?.split(",")
                .filter((v) => v !== "")
                .map((v) => {
                  return { label: v == "true" ? "Si" : "No", value: v == "true" };
                })
            : v.question_type === "Matrix"
            ? v.answer?.split(",")
                .filter((v) => v !== "")
                .map((v, i) => {
                  return { label: auxAnswerName[i], value: v };
                })
            : [],
      };
    });
    await actListAllCountries({}, prepareDataCountryEdit(data.countries));
    setNumberMembeers(data.membeers);
    await reset({
      name: data.name,
      ages: data.ages
        ?.split(",")
        .filter((v) => v !== "")
        .map((v) => {
          return { label: v, value: v };
        }),
      departments: data.departments?.map((v) => {
        return { label: v.name, value: v.id };
      }),
      regions: data.regions?.map((v) => {
        return { label: v.name, value: v.id };
      }),
      countries: data.countries?.map((v) => {
        return { label: v.name, value: v.id };
      }),
      abi_regions: data.abi_regions
        ?.map((v) => {
          return { label: v.name, value: v.id };
        }),
      audiences: data.audiences
        ?.map((v) => {
          return { label: v.name, value: v.id };
        }),
      genders: data.genders
        ?.split(",")
        .filter((v) => v !== "")
        .map((v) => {
          return { label: v, value: v };
        }),
      nse: data.nse
        ?.split(",")
        .filter((v) => v !== "")
        .map((v) => {
          return { label: v === "Low" ? "Bajo" : v === "Mid" ? "Medio" : "Alto", value: v };
        }),
      typeGroup: data.type === "gpm" || data.type === "gyl",
      typeList: data.type === "ldd" || data.type === "gyl",
    });

    await parametersData.forEach(async (v, i) => {
      await insert(i, v);
      await onChangeTool(
        i,
        v.tool_id.length > 0 ? [{ type: v.tool_id[0].type, value: v.tool_id[0].value }] : []
      );
      if (v.tool_id[0]?.type === "Survey") {
        await onChangeSection(i, { target: { value: v.section } });
        await onChangeQuestion(i, { target: { value: parseInt(v.question_id) } });
      }
    });
  };

  const onSetNumber = () => {
    const onSuccess = (data) => {
      setNumberMembeers(data.data);
    };
    const value = watch()
    const sendData = {
      name: value.name?.length > 0 ? value.name : null,
      type: value.typeList ? (value.typeGroup ? "gyl" : "ldd") : value.typeGroup ? "gpm" : null,
      countries: value.countries?.reduce((prev, curr) => prev + curr.value + ",", ""),
      departments:
          value.departments?.length > 0
              ? value.departments?.reduce((prev, curr) => prev + curr.value + ",", "")
              : null,
      regions:
          value.regions?.length > 0
              ? value.regions?.reduce((prev, curr) => prev + curr.value + ",", "")
              : null,
      abi_regions:
          value.abi_regions?.length > 0
              ? value.abi_regions?.reduce((prev, curr) => prev + curr.value + ",", "")
              : null,
      audiences:
          value.audiences?.length > 0
              ? value.audiences?.reduce((prev, curr) => prev + curr.value + ",", "")
              : null,
      nse: value.nse?.reduce((prev, curr) => prev + curr.value + ",", ""),
      ages: value.ages?.reduce((prev, curr) => prev + curr.value + ",", ""),
      genders: value.genders?.reduce((prev, curr) => prev + curr.value + ",", ""),
      parameters: value.parameters?.map((v) => {
        return {
          tool_id: v?.tool_id[0]?.value,
          question_id: v?.question_id,
          answer: v?.answer?.reduce((prev, curr) => prev + curr.value + ",", ""),
        };
      }),
    };
    if (
        value.countries?.length > 0 ||
        value.departments?.length > 0 ||
        value.regions?.length > 0 ||
        value.abi_regions?.length > 0 ||
        value.nse?.length > 0 ||
        value.ages?.length > 0 ||
        value.nse?.genders > 0
    ) {
      getNumberGroups(sendData, onSuccess);
    } else {
      setNumberMembeers(0);
    }
  }

  const handleUploadFile = (e) => {
    setSendImport(true);
  }

  useEffect(() => {
    if (id_group) {
      actGetGroups(id_group, resetData);
    } else {
      actListAllCountries({}, prepareDataCountry);
    }
    if (location.pathname.includes("/grupos/detalle")) {
      setDisableFields(true);
    }
  }, []);

  const handleDownloadExel = (id) => {
    actExportExel(`impor`, 'groupMembeer', (result) => {
      createDownloadExel(result);
    });
};

  return {
    control,
    handleSubmit,
    onSubmit,
    saving,
    showModal,
    handleCloseModal,
    activateUser,
    handleChangeActivate,
    listCountries,
    fields,
    append,
    remove,
    onChangeCountry,
    onChangeDeparment,
    onChangeRegion,
    onChangeRegionABI,
    onChangeSearch,
    listOptionsTool,
    onChangeTool,
    listSelectTool,
    watch,
    onChangeSection,
    listQuestions,
    onChangeQuestion,
    listAnswer,
    listResumen,
    listSelectCounties,
    listAgeRanges, 
    listNSE,
    listSection,
    id_group,
    disableFields,
    groupName,
    handleGoBack,
    numberMembeers,
    onSetNumber,
    handleUploadFile,
    sendImport,
    handleDownloadExel
  };
};

export default useGroupCreate;
