import axios from "axios";

const usePowerBiProvider = () => {
  const getPowerBiProvider = () => {
    return axios({
      method: "get",
      url: `powerbi`,
    });
  };

  return {
    getPowerBiProvider,
  };
};
export default usePowerBiProvider;
