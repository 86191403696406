import React from "react";
import useControllers from "../../../../controllers";
import useComponents from "../../../components";

const View = () => {
  const {
    ContentWrappers,
    Typography,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    InputFile,
    Badge,
    Pagination,
    Checkbox,
    useModals,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { usePaymentsScreen } = useScreenHooks();
  const { paymentsRoot } = usePaymentsScreen();
  const { handleSubmit, onSubmit, control, listCountries } = paymentsRoot();

  const { formatDate } = useGeneralHooks();

  return (
    <div className="flex w-full h-screen-60 items-center">
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-md w-11/12" as="section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white px-8 pt-10 pb-10 rounded-lg items-center flex flex-col">
            <Typography variant="h3" className="uppercase text-left w-full pb-6">
              1. Listado de pagos por país
            </Typography>
            <SelectFieldControlled
              name="country_id"
              id="abi-country_id"
              control={control}
              label="País"
              options={[...listCountries, { value: "multizonal", label: "Multizonal" }]}
            />
            <div className="mt-7">
              <DefaultButton color="secondary" type="submit">
                Cargar listado
              </DefaultButton>
            </div>
          </div>
        </form>
      </ContentWrappers>
    </div>
  );
};

export default View;
