import styled from "styled-components";
import tw from "twin.macro";

export const StyledLabel = styled.span.attrs({
  className: "StyledLabel"
})`
  ${tw`text-neutral-90 text-sm font-normal font-gotham`}
  /* line-height: 20px; */
  letter-spacing: -0.006em;
  &&.danger {
    color: red;
  }
`