import React from 'react';

// Components
import useComponents from "../../../../../components";

// Controllers
import useControllers from "../../../../../../controllers";


const FormProfilerLabels = (props) => 
{
  const {
    ContentWrappers,
    useButtons,
    TextFieldControlled,
    SelectFieldControlled,
    Typography    
  } = useComponents();

  const { DefaultButton } = useButtons();  

  const { useScreenHooks } = useControllers();
  const { useProfiler } = useScreenHooks();
  const { useProfilerCreate } = useProfiler();

  const {    
    type,
    control,
    listCountries,
    listRegions,
    handleSubmit,
    onSubmit,
    saving
  } = useProfilerCreate();

  return (    
    <form onSubmit={handleSubmit(onSubmit)}>  
      <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>

      {/* DISEÑO ORIGINAL*/}
      {/* {type != 0 ?
      <ContentWrappers
        gridColums={["1fr", "1fr"]}
        gap={{
          row: "0px",
          column: "16px",
        }}
        className="mt-4 items-center">                 
        <SelectFieldControlled
          label="País"
          name="country_id"
          id="abi-country_id"
          control={control}
          options={listCountries}/>
          <TextFieldControlled
            control={control}
            name="region"
            id="region"
            label={"Región"}/>
      </ContentWrappers> 
      : 
      <SelectFieldControlled
          label="País"
          name="country_id"
          id="abi-country_id"
          control={control}
          options={listCountries}/>
      }
      {type != 0 ? 
      <SelectFieldControlled
          label="Región ABI o Región ABI Auxiliar"
          name="abi_region"
          id="abi_region"
          control={control}
          options={listRegions}/> : ''} */}

      <ContentWrappers
        gridColums={["1fr", "1fr"]}
        gap={{
          row: "0px",
          column: "16px",
        }}
        className="mt-4 items-center">                 
        <SelectFieldControlled
          label="País"
          name="country_id"
          id="abi-country_id"
          control={control}
          options={listCountries}/>
          <SelectFieldControlled
          label="Región ABI o Región ABI Auxiliar"
          name="abi_region"
          id="abi_region"
          control={control}
          options={listRegions}/>
      </ContentWrappers> 

          <hr color="#FFA700" className="mb-10"></hr>
      <Typography variant="h3">2. CONFIGURACIÓN ESPECIFICA</Typography>        
      { type === 0 ?       
          <ContentWrappers
            gridColums={["1fr", "1fr", "1fr"]}
            gap={{
              row: "0px",
              column: "16px",
            }}
            className="mt-4 items-center">
            <TextFieldControlled
              control={control}
              name="total_licenses"
              id="total_licenses"                     
              label={"Licencias totales"}/>
            <TextFieldControlled
              control={control}
              disabled       
              name="in_use_licenses"
              id="in_use_licenses"
              label={"Licencias ocupadas"}/>
            <TextFieldControlled
              control={control}
              disabled       
              name="unused_licenses"
              id="unused_licenses"
              label={"Licencias no ocupadas"}/>  
          </ContentWrappers> 
      : type === 4 ?     
      <ContentWrappers
        gridColums={["1fr", "1fr", "1fr"]}
        gap={{
          row: "0px",
          column: "16px",
        }}
        className="mt-4 items-center">
        <TextFieldControlled
          control={control}
          name="min"
          id="min"
          label={"Rango de edad - Mínimo"}/>
        <TextFieldControlled
          control={control}
          name="max"
          id="max"
          label={"Rango de edad - Máximo"}/>
        <TextFieldControlled
          control={control}
          name="licenses"
          id="licenses"
          label={"Licencias disponible"}/>  
      </ContentWrappers>: 
      type === 5 ?
      <ContentWrappers
        gridColums={["1fr", "1fr"]}
        gap={{
          row: "0px",
          column: "16px",
        }}
          className="mt-4 items-center">
        <SelectFieldControlled
          label="Género"
          name="gender"
          id="gender"
          control={control}
          options={[{ label: "Femenino", value: 0 }, { label: "Masculino", value: 1 }]}/>
        <TextFieldControlled
          control={control}
          name="licenses"
          id="licenses"
          label={"Licencias disponible"}/>  
      </ContentWrappers> :   
      <ContentWrappers
        gridColums={["1fr", "1fr", "1fr", "1fr"]}
        gap={{
          row: "0px",
          column: "16px",
        }}
        className="mt-4 items-center">
          <TextFieldControlled
          control={control}
          name="nse"
          id="nse"
          label={"SEC"}/>
        <TextFieldControlled
          control={control}
          name="min"
          id="min"
          label={"Rango NSE - Mínimo"}/>
        <TextFieldControlled
          control={control}
          name="max"
          id="max"
          label={"Rango NSE - Máximo"}/>
        <TextFieldControlled
          control={control}
          name="licenses"
          id="licenses"
          label={"Licencias disponible"}/>  
      </ContentWrappers>}
      <div className="text-center mt-4">
          <DefaultButton color="secondary" type="submit" disabled={saving}>
            Finalizar y publicar
          </DefaultButton>
        </div>
    </form>)
}

export default FormProfilerLabels