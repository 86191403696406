import useServices from "../../services";

const useNewsActions = () => {
  const { useNewsServices } = useServices();
  const {
    listNewsService,
    createNewService,
    editNewService,
    getNewService,
    getTotalNewsService,
    cloneNewService,
    deleteNewService,
  } = useNewsServices();

  const actListNews = async (
    { page, search, status, country_id, author, init_date, end_date },
    onSuccess,
    onError
  ) => {
    try {
      const response = await listNewsService({
        page,
        search,
        status,
        country_id,
        author,
        init_date,
        end_date,
      });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actCreateNew = (data, onSuccess, onError) => async () => {
    try {
      const response = await createNewService(data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actEditNew = (idNew, data, onSuccess, onError) => async () => {
    try {
      const response = await editNewService(idNew, data);
      onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };
  const actGetNew = async (id, onSuccess, onError) => {
    try {
      const response = await getNewService(id);
      onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actTotalNews = async (onSuccess, onError) => {
    try {
      const response = await getTotalNewsService();
      onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actCloneNew = async (id, onSuccess, onError) => {
    try {
      const response = await cloneNewService(id);
      onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };
  
  const actDeleteNew = async (id, onSuccess, onError) => {
    try {
      const response = await deleteNewService(id);
      onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  return {
    actListNews,
    actCreateNew,
    actEditNew,
    actGetNew,
    actTotalNews,
    actCloneNew,
    actDeleteNew,
  };
};
export default useNewsActions;
