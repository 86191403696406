import useServices from "../../services";

const usePaymentsActions = () => {
  const { usePaymentsServices } = useServices();

  const {
    listPaymentsService,
    createPaymentsService,
    editPaymentsService,
    getPaymentsService,
    listMissionToPayService,
    editPaymentsStatusService,
    loadFilePaymentsService,
    getPaymentsDownloadService,
  } = usePaymentsServices();

  const actListPayments = async (
    {
      page,
      country_id,
      member_state,
      payment_status,
      membeer_name,
      mision_name,
      init_date_approval,
      end_date_approval,
    },
    onSuccess,
    onError
  ) => {
    try {
      const response = await listPaymentsService({
        page,
        country_id,
        member_state,
        payment_status,
        membeer_name,
        mision_name,
        init_date_approval,
        end_date_approval,
      });
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actCreatePayments = (data, onSuccess, onError) => async () => {
    try {
      const response = await createPaymentsService(data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actEditPaymets = (id, data, onSuccess, onError) => async () => {
    try {
      const response = await editPaymentsService(id, data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actGetPayment = async (id, onSuccess, onError) => {
    try {
      const response = await getPaymentsService(id);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actGetMissionToPay = async (onSuccess, onError) => {
    try {
      const response = await listMissionToPayService();
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actEditPaymetsStatus = (data, onSuccess, onError) => async () => {
    try {
      const response = await editPaymentsStatusService(data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actLoadFilesPayments = (data, onSuccess, onError) => async () => {
    try {
      const response = await loadFilePaymentsService(data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };

  return {
    actListPayments,
    actCreatePayments,
    actEditPaymets,
    actGetPayment,
    actGetMissionToPay,
    actEditPaymetsStatus,
    actLoadFilesPayments,
  };
};
export default usePaymentsActions;
