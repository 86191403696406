import React from "react";
import PowerbiEmbedded from "react-powerbi";

import useLayouts from "../../layouts";
import useControllers from "../../../controllers";

const PowerBI = () => {
  const { PrivateContentLayout } = useLayouts();

  const { useScreenHooks } = useControllers();
  const { usePowerBi } = useScreenHooks();
  const { textError, configPowerBi } = usePowerBi();

  return (
    <PrivateContentLayout
      breadcrumbsList={[]}
      showBack={location.pathname !== "/powerbi"}
      title="PowerBI"
    >
      <div className="mx-6 px-6 py-3 text-center bg-white rounded">
        {textError && !configPowerBi ? (
          <p className="text-red-600">{textError}</p>
        ) : configPowerBi ? (
          <PowerbiEmbedded
            id={configPowerBi.id} // required
            embedUrl={configPowerBi.embedUrl} // required
            accessToken={configPowerBi.accessToken} // required
            filterPaneEnabled={false}
            navContentPaneEnabled={false}
            embedType={configPowerBi.embedType}
            tokenType={configPowerBi.tokenType}
            permissions={configPowerBi.permissions}
            settings={configPowerBi.settings}
            width="600px"
            height="900px"
          />
        ) : null}
      </div>
    </PrivateContentLayout>
  );
};

export default PowerBI;
