// Packages
import React from "react";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";

// Components
import SelectField from "../SelectField";

const SelectFieldControlled = (props) => {
  const {
    control,
    name,
    id,
    label,
    options,
    tooltipTitle,
    defaultValue,
    placeholder,
    disabled,
    onChangeField,
    returnLabelValue,
  } = props;

  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: true,
    },
    defaultValue: defaultValue ?? "",
  });

  const auxOnChange = (e) => {
    const selectedOption = options.find(option => option.value === e.target.value);
    
    if (returnLabelValue && selectedOption) {
      onChange(e);
      onChangeField && onChangeField(selectedOption.label);
    } else {
      onChange(e);
      onChangeField && onChangeField(e);
    }
  };

  return (
    <SelectField
      error={error}
      label={label}
      tooltipTitle={tooltipTitle}
      id={id}
      name={name}
      onChange={auxOnChange}
      selectedValue={value}
      options={options}
      inputRef={ref}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

SelectFieldControlled.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeField: PropTypes.func,
  returnLabelValue: PropTypes.bool,
};

SelectFieldControlled.defaultProps = {
  onChangeField: () => {},
  returnLabelValue: false,
};

export default SelectFieldControlled;
