// Packages
import React, { useState } from "react";
import PropTypes from "prop-types";

// Styles
import {
  StyledQuestionsListItem,
  StyledQuestionsListItemContent,
  StyledQuestionsListItemNumber,
  StyledQuestionsListItemMove,
  StyledQuestionsListItemActionButtons,
} from "./Questions.styles";

// Components
import Typography from "../Typography";

// Assets
import useIcons from "../../../assets/icons";
import iconEye from "../../../assets/images/icon-eye.png";
import IconList from "../../../assets/icons/iconList";

// Controllers
import useControllers from "../../../controllers";

const ForwardModal = ({ sections, handleForwarQuestion, question, sectionId }) => {
  if (sections) {
    return (
      <div className="flex flex-col absolute bg-white" style={{ right: "-85px" }}>
        {sections.map((section) => (
          // Agregamos una condición para evitar renderizar el botón si section.id es igual a sectionId
          section.id !== sectionId && (
            <button
              key={section.id} // Asegurémonos de agregar un key único para cada botón
              className="flex flex-row items-center hover:bg-third-200 border border-solid border-gray-400 space-x-2 p-2"
              onClick={() =>
                handleForwarQuestion(section.id, question, sectionId, section.questions.length)
              }
            >
              <IconList />
              <p>{section.title}</p>
            </button>
          )
        ))}
      </div>
    );
  }
};


const QuestionList = (props) => {
  const {
    provided,
    title,
    itemNumber,
    questionType,
    handleRemoveQuestion,
    id,
    handleGetQuestion,
    preview,
    handlePreview,
    handleCloneQuestion,
    question,
    isActiveMissionFlag,
    sectionId,
    sections,
    handleForwarQuestion,
  } = props;

  const [isForwardModalOpen, setIsForwardModalOpen] = useState(false);

  const handleForwardModal = () => {
    setIsForwardModalOpen(!isForwardModalOpen);
  };

  const { IconArrowDown, IconDelete, IconEdit, IconCopy, IconSend } = useIcons();

  // Controllers
  const { useComponentHooks } = useControllers();
  const { useQuestions } = useComponentHooks();
  const { useQuestionList } = useQuestions();
  const { handleShowQuestion, showQuestionInfo } = useQuestionList();

  return (
    <StyledQuestionsListItem ref={provided.innerRef} {...provided.draggableProps}>
      <StyledQuestionsListItemNumber>{itemNumber}</StyledQuestionsListItemNumber>
      <StyledQuestionsListItemContent active={showQuestionInfo}>
        <div className="flex items-start justify-between mt-0 w-11/12">
          <Typography>{title}</Typography>
          <div className={`flex items-center space-x-4`}>
            {question.question?.add_other_field && <span>OT</span>}
            {preview && (
              <span className="cursor-pointer relative" onClick={() => handlePreview(id)}>
                <img src={iconEye} alt="preview" />
                {question?.required && (
                  <span className="text-danger text-lg absolute -right-1.5 -top-1.5">*</span>
                )}
              </span>
            )}
          </div>
        </div>
        <div>
          <span>Respuesta esperada</span>
          <Typography>{questionType}</Typography>

          {!isActiveMissionFlag && (
            <StyledQuestionsListItemActionButtons>
              <button
                type="button"
                onClick={() => {
                  sectionId ? handleGetQuestion(id, sectionId) : handleGetQuestion(id);
                }}
              >
                <IconEdit color="#898781" />
              </button>
              <button type="button" onClick={() => handleCloneQuestion(id)}>
                <IconCopy />
              </button>
              <button type="button" onClick={() => handleRemoveQuestion(id, sectionId)}>
                <IconDelete />
              </button>
              <button type="button" onClick={() => handleForwardModal()}>
                <IconSend />
              </button>
            </StyledQuestionsListItemActionButtons>
          )}
        </div>
        <button onClick={handleShowQuestion} type="button">
          <IconArrowDown color="#FFA700" />
        </button>
      </StyledQuestionsListItemContent>
      {isForwardModalOpen && (
        <ForwardModal
          sections={sections}
          handleForwarQuestion={handleForwarQuestion}
          question={question}
          sectionId={sectionId}
        />
      )}
      <StyledQuestionsListItemMove {...provided.dragHandleProps}>
        <span></span>
        <span></span>
        <span></span>
      </StyledQuestionsListItemMove>
    </StyledQuestionsListItem>
  );
};

QuestionList.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  itemNumber: PropTypes.number.isRequired,
  provided: PropTypes.any.isRequired,
  questionType: PropTypes.string,
  preview: PropTypes.bool,
  handlePreview: PropTypes.func,
  question: PropTypes.any,
};

export default QuestionList;
