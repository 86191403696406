// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledFormFilter = styled.div.attrs({
  className: "StyledFormFilter",
})`
  .MuiFormControl-root {
    ${tw`mb-2`}
  }
`;
