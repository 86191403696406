const IconUserStatus = ({color = "#898781", type="check"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={ color }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d={`
          M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M8.5 11a4 4 0 100-8 4 4 0 000
          ${type === "check" ? "8zM17 11l2 2 4-4" : ""}
          ${type === "x" ? "8zM18 8l5 5M23 8l-5 5" : ""}
          ${type === "hold-on" ? "8zM17 5.375a2.058 2.058 0 014 .686c0 1.373-2.058 2.059-2.058 2.059M18.91 11.005h.01" : ""}
        `}
      ></path>
    </svg>
  );
}

export default IconUserStatus;