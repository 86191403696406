import axios from "axios";

const useNotificationsProvider = () => {
  const listNotificationsProvider = ({page, search, state, type}) => {
    return axios ({
      method: "get",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/notifications?page=${page}`,
      params: {search, state, type}
    })
  }

  const getNotificationProvider = (id) => {
    return axios ({
      method: "get", 
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/notifications/${id}`,
    })
  }

  const getMembeerGroupsProvider = () => {
    return axios ({
      method: "get", 
      url: `membeer_groups-list`,
    })  
  }

  const getNotificationsEntitiesProvider = () => {
    return axios ({
      method: "get", 
      url: `notifications/entities`,
    })  
  }

  const getNotificationsEntitiesByIdProvider = (id) => {
    return axios ({
      method: "get", 
      url: `global/entity/${id}`,
    })  
  }

  const addNotificationProvider = (data) => {
    return axios ({
      method: "post",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/notifications`,
      data
    })
  }

  const addRemindersProvider = (data) => {
    return axios ({
      method: "post",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/reminders-array`,
      data: {"reminders": [...data]}
    })
  }

  const cloneNotificationProvider = ({ id, project_id }) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/notifications/clone/${id}`,
      data: { is_duplicated: true, project_id },
    });
  };

  const editNotificationProvider = ({id, datos}) => {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/notifications/${id}`,
      data: datos,
    });
  };

  const deleteNotificationProvider = (id) => {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/notifications/${id}`,      
    });
  };

  return {
    addNotificationProvider,
    addRemindersProvider,
    cloneNotificationProvider,
    deleteNotificationProvider,
    editNotificationProvider,
    getMembeerGroupsProvider,
    getNotificationProvider,
    listNotificationsProvider,
    getNotificationsEntitiesProvider, 
    getNotificationsEntitiesByIdProvider
  }
}

export default useNotificationsProvider;