const IconArrow = ({ color = "#FFA700", className = "" }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1484 14.7516C18.3733 14.9766 18.4997 15.2818 18.4997 15.6C18.4997 15.9182 18.3733 16.2234 18.1484 16.4484L13.3484 21.2484C13.1233 21.4734 12.8182 21.5997 12.5 21.5997C12.1818 21.5997 11.8766 21.4734 11.6516 21.2484L6.85158 16.4484C6.73696 16.3377 6.64554 16.2053 6.58265 16.0589C6.51976 15.9125 6.48666 15.755 6.48527 15.5957C6.48389 15.4363 6.51425 15.2783 6.57459 15.1308C6.63493 14.9834 6.72403 14.8494 6.8367 14.7367C6.94937 14.624 7.08335 14.5349 7.23083 14.4746C7.37831 14.4143 7.53632 14.3839 7.69566 14.3853C7.85499 14.3867 8.01246 14.4198 8.15886 14.4827C8.30527 14.5456 8.43768 14.637 8.54837 14.7516L11.3 17.5032V3.59999C11.3 3.28173 11.4264 2.97651 11.6514 2.75147C11.8765 2.52642 12.1817 2.39999 12.5 2.39999C12.8182 2.39999 13.1235 2.52642 13.3485 2.75147C13.5735 2.97651 13.7 3.28173 13.7 3.59999V17.5032L16.4516 14.7516C16.6766 14.5266 16.9818 14.4002 17.3 14.4002C17.6182 14.4002 17.9233 14.5266 18.1484 14.7516Z"
        fill={color}
      />
    </svg>
  );
};

export default IconArrow;
