// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";

// Styles
import { StyledMultipleChoiceAddButton } from "./NotificationCreate.styles";

const NotificationCreate = () => {
  const { useScreenHooks } = useControllers();
  const { useNotification } = useScreenHooks();
  const { useNotificationCreate } = useNotification();
  const {
    id,
    control,
    handleSubmit,
    onSubmit,
    showModal,
    saving,
    handleCloseModal,
    listCountries,
    fields,
    append,
    remove,
    listGroups,
    listEntities,
    listEntitiesById,
    handleEntitiesSelectChange,
    handleSelectGroup
  } = useNotificationCreate();

  const {
    ContentWrappers,
    useButtons,
    TextFieldControlled,
    SelectFieldControlled,
    Typography,
    useModals,
    TextAreaFieldControlled,
    SelectMultiFieldControlled,
    CheckboxControlled,
    TimePickerFieldControlled,
    DatePickerFieldControlled,
    FormLabel,
    InputFileControlled,
    AutocompleteFieldControlled,
  } = useComponents();

  const { DefaultModal } = useModals();

  const { DefaultButton } = useButtons();

  const { IconDelete, IconPlus } = useIcons();

  return (
    <div className="bg-white px-16 pt-10 pb-12 mx-24 rounded-lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>
        <ContentWrappers
          gridColums={["48%", "48%"]}
          gap={{
            row: "0px",
            column: "4%",
          }}
          className="mt-4 items-center"
        >
          <TextFieldControlled
            type="text"
            id="abi-user-name"
            control={control}
            label="Nombre de notificación"
            placeholder="Nombre de notificación"
            name="name"
          />
          <CheckboxControlled
            id="abi-user-reminder"
            control={control}
            label="Activar recordatorios"
            name="reminder"
          />
        </ContentWrappers>
        <ContentWrappers
          gridColums={["48%", "48%"]}
          gap={{
            row: "0px",
            column: "4%",
          }}
        >
          <SelectFieldControlled
            label={"Grupo membeer para envío"}
            placeholder={"Selecciona una opción"}
            name={`group`}
            id={`abi-group`}
            control={control}
            options={listGroups}
            returnLabelValue={true}
            onChangeField={(data) => handleSelectGroup(data)}
          />
          <div>
            <FormLabel label="Fecha de publicación" />
            <ContentWrappers
              gridColums={["140px", "100px", "81px"]}
              gap={{
                row: "0px",
                column: "16px",
              }}
            >
              <DatePickerFieldControlled
                name="publicDate"
                id="abi-task-publicDate"
                control={control}
              />
              <TimePickerFieldControlled
                name="publicTime"
                id="abi-task-publicTime"
                control={control}
              />
              <SelectFieldControlled
                name="publicAmpm"
                id="abi-task-publicAmpm"
                control={control}
                defaultValue={"am"}
                options={[
                  {
                    value: "am",
                    label: "a.m",
                  },
                  {
                    value: "pm",
                    label: "p.m",
                  },
                ]}
              />
            </ContentWrappers>
          </div>
        </ContentWrappers>
        <Typography variant="h3">2. AGREGAR RECORDATORIOS</Typography>
        {fields?.map((item, index) => (
          <div key={item.id} className="py-4">
            <div className="flex items-center space-x-2">
              <Typography variant="h4">RECORDATORIO {index + 1}</Typography>
              <div
                className={`cursor-pointer ${index === 0 && "hidden"}`}
                onClick={() => remove(index)}
              >
                <IconDelete />
              </div>
            </div>

            <ContentWrappers
              gridColums={["395px", "8%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
              className="items-center"
            >
              <div>
                <FormLabel label="Fecha y hora de inicio" />
                <ContentWrappers
                  gridColums={["178px", "100px", "81px"]}
                  gap={{
                    row: "0px",
                    column: "16px",
                  }}
                >
                  <DatePickerFieldControlled
                    name={`reminders[${index}].date`}
                    id={`reminders[${index}].date`}
                    control={control}
                  />
                  <TimePickerFieldControlled
                    name={`reminders[${index}].time`}
                    id={`reminders[${index}].time`}
                    control={control}
                  />
                  <SelectFieldControlled
                    name={`reminders[${index}].ampm`}
                    id={`reminders[${index}].ampm`}
                    control={control}
                    defaultValue={"am"}
                    options={[
                      {
                        value: "am",
                        label: "a.m",
                      },
                      {
                        value: "pm",
                        label: "p.m",
                      },
                    ]}
                  />
                </ContentWrappers>
              </div>
              <StyledMultipleChoiceAddButton>
                <button onClick={() => append()} type="button">
                  <IconPlus />
                </button>
              </StyledMultipleChoiceAddButton>
            </ContentWrappers>
          </div>
        ))}
        <Typography variant="h3">3.Tipo de notificación</Typography>
        <div className="flex flex-col space-y-4 mt-4">
          <CheckboxControlled
            id="abi-user-reminder-push"
            control={control}
            label="Notificación Push"
            name="reminder_push"
          />

          <ContentWrappers
            gridColums={["48%", "48%"]}
            gap={{
              row: "0px",
              column: "4%",
            }}
            className="mt-4 items-center w-full"
          >
            <SelectFieldControlled
              label={"Tipo"}
              placeholder={"Selecciona una opción"}
              name={`entity`}
              id={`abi-entities`}
              onChangeField={(data) => handleEntitiesSelectChange(data)}
              control={control}
              options={listEntities}
            />

            <AutocompleteFieldControlled
              label={"Listado"}
              placeholder={"Selecciona una opción"}
              name={`entity_id`}
              id={`abi-entity-id`}
              control={control}
              options={listEntitiesById}
              disabled={listEntitiesById?.length === 0}
            />
          </ContentWrappers>

          <TextAreaFieldControlled
            label={"Mensaje"}
            placeholder={"Escribe aquí el mensaje"}
            name={`message_push`}
            id={`abi-notification-message-push`}
            control={control}
          />
          <CheckboxControlled
            id="abi-user-reminder-email"
            control={control}
            label="Correo electrónico"
            name="reminder_email"
          />
          <TextFieldControlled
            type="text"
            id="abi-asunto-email"
            control={control}
            label="Asunto del correo"
            placeholder="Asunto correo electrónico"
            name="email_subject"
          />
          <InputFileControlled
            label={"Agregar cabezote (jpg, png, webp)"}
            placeholder={"image.jpg"}
            name={`header_image`}
            id={`abi-notification-image-email`}
            control={control}
            directPreview
            maxFiles={1}
            showPreview
          />
          <TextAreaFieldControlled
            label={"Mensaje personalizado"}
            placeholder={"Escribe aquí el mensaje"}
            name={`message_email`}
            id={`abi-notification-message-email`}
            control={control}
          />
        </div>
        <div className="text-center mt-4">
          <DefaultButton color="secondary" type="submit" disabled={saving}>
            Finalizar y publicar
          </DefaultButton>
        </div>
      </form>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          {id && (
            <Typography variant="body2">
              La notificación <b>{id ? id : ""}</b> se ha modificado con éxito.
            </Typography>
          )}
          {!id && <Typography variant="body2">La notificación se ha creado con éxito.</Typography>}
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </div>
  );
};

export default NotificationCreate;
