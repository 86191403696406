import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useAuthServices = () => {
  const { useAuthProvider } = useProviders();
  const {
    loginProvider,
    forgotPasswordProvider,
    recoveryPasswordProvider,
    validLoginProvider,
    logoutProvider,
    recoveryPasswordProviderApp,
  } = useAuthProvider();

  const loginService = ({ email, password }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(loginProvider({ email, password })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const validLoginService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(validLoginProvider()));
      } catch (error) {
        reject(error);
      }
    });
  };

  const forgotPasswordService = ({ email }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(forgotPasswordProvider({ email })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const recoveryPasswordService = ({ email, password, token }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(recoveryPasswordProvider({ email, password, token })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const recoveryPassworAppdService = ({ email, password, tokenApp }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(recoveryPasswordProviderApp({ email, password, tokenApp })));
      } catch (error) {
        reject(error);
      }
    });
  };

  const logOutService = ({ token }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(logoutProvider({ token })));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    loginService,
    forgotPasswordService,
    recoveryPasswordService,
    validLoginService,
    logOutService,
    recoveryPassworAppdService,
  };
};

export default useAuthServices;
