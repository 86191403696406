import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import useComponents from "../../../../../components";

import useHelpers from "../../../../../../helpers";

// Assets
import iconUserCircle from "../../../../../../assets/images/icon-user-circle.svg";
import useIcons from "../../../../../../assets/icons";

import {
  StyledWrapperForm,
  StyledWrapperDateForm,
  StyledGridDash,
  StyledChartDegra,
  StyledGridRow,
  BootstrapTooltip,
} from "../../View.styles";

const FollowUsers = (props) => {
  const {
    role,
    handleSubmit,
    onSubmit,
    control,
    listCountries,
    followMembeers,
    handleDownloadExcel,
    printChart,
    watch,
  } = props;

  const { useQuickFunctions } = useHelpers();
  const { useGeneral } = useQuickFunctions();
  const { formatDate } = useGeneral();

  const { SelectFieldControlled, useButtons, DatePickerFieldControlled, Typography } =
    useComponents();
  const { DefaultButton } = useButtons();

  const { IconDownload } = useIcons();

  return (
    <>
      {role === "Súper Administrador" && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-between mb-4">
            <StyledWrapperForm>
              <SelectFieldControlled
                placeholder="Selecciona un pais"
                name="country_id"
                id="country_id"
                control={control}
                options={listCountries}
                label={"PAÍS"}
              />
            </StyledWrapperForm>
            <div className="flex items-end justify-between space-x-8">
              <StyledWrapperDateForm>
                <DatePickerFieldControlled
                  name="date_start"
                  id="date_start"
                  control={control}
                  label={"DESDE"}
                />
              </StyledWrapperDateForm>
              <StyledWrapperDateForm>
                <DatePickerFieldControlled
                  name="date_end"
                  id="date_end"
                  control={control}
                  label={"HASTA"}
                />
              </StyledWrapperDateForm>

              <DefaultButton color="secondary" type="submit">
                Consultar
              </DefaultButton>
            </div>
          </div>
        </form>
      )}
      <StyledGridDash id="follow-users">
        <div>
          <p className="text-xs text-neutral-80">TASA DE CONVERSIÓN</p>
        </div>
        <div>
          {/* <p className="bg-surface rounded text-center text-sm rounded-sm py-1">
            28,500 Total descargas app
          </p> */}
        </div>
        <div className="col-span-3">
          <img src={iconUserCircle} alt="Usuarios" width="40" height="40" className="ml-3" />
        </div>
        <StyledGridRow>
          <div className="abi-rate-convertion">
            <p className="border-success-500 text-success-500">
              {followMembeers?.percentageFinishOnStart}
            </p>
          </div>
          <div className="abi-rate-convertion">
            <p className="border-success-500 text-success-500">
              {followMembeers?.percentageActiveOnFinish}
            </p>
          </div>
          <div className="abi-rate-convertion">
            <p className="border-success-500 text-success-500">
              {followMembeers?.percentageMissionCompletedOnActive}
            </p>
          </div>
          <div className="abi-rate-convertion">
            <p className="border-success-500 text-success-500">
              {followMembeers?.percentagePaidOnMissionCompleted}
            </p>
          </div>
        </StyledGridRow>
        <StyledChartDegra>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <BootstrapTooltip
              placement="top"
              title="Usuarios que han iniciado el registro de perfilamiento"
            >
              <p>Inicio de perfilamiento</p>
            </BootstrapTooltip>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <BootstrapTooltip
              placement="top"
              title="Usuarios que han terminado en su totalidad el registro de perfilamiento"
            >
              <p>Perfilamiento completo</p>
            </BootstrapTooltip>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <BootstrapTooltip
              placement="top"
              title="Usuarios que han sido aceptados y dados de alta en el panel."
            >
              <p>Usuario nuevos</p>
            </BootstrapTooltip>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <BootstrapTooltip placement="top" title="Usuarios que han contestado alguna misión">
              <p className="max-w-[200px]">Usuarios con misión completada</p>
            </BootstrapTooltip>
          </div>
          <div>
            <BootstrapTooltip placement="top" title="Usuarios que han recibido un pago">
              <p>Usuarios pagados</p>
            </BootstrapTooltip>
          </div>
        </StyledChartDegra>
        <StyledGridRow>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <p className="text-base bg-surface rounded-sm py-4 px-2 w-20 text-center">
              {followMembeers?.startedSurvey}
            </p>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <p className="text-base bg-surface-600 rounded-sm py-4 px-2 w-20 text-center">
              {followMembeers?.completedSurvey}
            </p>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <p className="text-base bg-surface-700 rounded-sm py-4 px-2 w-20 text-center">
              {followMembeers?.activeUsers}
            </p>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <p className="text-base bg-surface-800 rounded-sm py-4 px-2 w-20 text-center">
              {followMembeers?.missionCompleted}
            </p>
          </div>
          <div>
            <p className="text-base bg-surface-900 rounded-sm py-4 px-2 w-20 text-center">
              {followMembeers?.paidUsers}
            </p>
          </div>
        </StyledGridRow>
        <StyledGridRow>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center w-full">
            <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface py-4 px-2 h-56">
              {followMembeers?.migratedSocialLabs}
            </p>
            <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
              Migración social labs
            </p>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center w-full">
            <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-600 py-4 px-2 h-56">
              {followMembeers?.successRate}
            </p>
            <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
              % de éxito
            </p>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center w-full">
            <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-700 py-4 px-2 h-56">
              {followMembeers?.profiledRate}
            </p>
            <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
              % del Perfilamiento
            </p>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center w-full">
            <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-800 py-4 px-2 h-56">
              {followMembeers?.inactiveUsers}
            </p>
            <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
              Usuarios inactivos en rango de fecha
            </p>
          </div>
          <div className="flex w-full">
            <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-900 py-4 px-2">
              {followMembeers?.averagePayment}
            </p>
            <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
              Pago Prom. usuario
            </p>
          </div>
        </StyledGridRow>
        <StyledGridRow>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-700 py-4 px-2 h-56">
              {followMembeers?.membeerNotAccepted}
            </p>
            <BootstrapTooltip placement="top" title="Usuarios que no pasaron la pregunta filtro de la encuesta de perfilamiento.">
              <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
                Usuarios No Aceptados
              </p>
            </BootstrapTooltip>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center"></div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center">
            <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-700 py-4 px-2 h-56">
              {followMembeers?.waitingUsers}
            </p>
            <BootstrapTooltip placement="top" title="Usuarios que completaron la encuesta de perfilamiento, pero no han sido activados por llenado de cuotas o panel.">
              <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
                Usuarios en Lista de Espera
              </p>
            </BootstrapTooltip>
          </div>
          <div className="h-76 border-b border-neutral-70 border-dashed flex justify-center items-center"></div>
          <div className="flex">
            <p className="text-base rounded-l-sm flex-shrink-0 w-80 text-center bg-surface-900 py-4 px-2">
              {followMembeers?.totalPayment}
            </p>
            <p className="text-sm bg-neutral-40 h-56 flex justify-between items-center rounded-r-sm w-full px-2">
              Pago Total USD
            </p>
          </div>
        </StyledGridRow>
      </StyledGridDash>
      <div className="flex justify-end w-full space-x-6">
        <div className="flex space-x-2 items-center" onClick={handleDownloadExcel}>
          <Typography variant="caption" className="cursor-pointer underline" color="#424242">
            Descargar tabla
          </Typography>
          <IconDownload color="#424242" />
        </div>
        <Link
          target="_blank"
          to={{
            pathname: "/pdf/dashboard/chart",
            search: `?country_id=${watch("country_id")}&start_date=${formatDate(
              watch("date_start"),
              "Y-mm-dd",
              false
            )}&end_date=${formatDate(watch("date_end"), "Y-mm-dd", false)}`,
          }}
          className="flex space-x-2 items-center"
        >
          <Typography variant="caption" className="cursor-pointer underline" color="#424242">
            Descargar grafico
          </Typography>
          <IconDownload color="#424242" />
        </Link>
      </div>
    </>
  );
};

export default FollowUsers;
