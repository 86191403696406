import axios from "axios";

const useGamificationProvider = () => {
  const listGamificationProvider = (url, { page }) => {    
    return axios ({
      method: "get",
      url: `medal/${url}?page=${page}`,
      // params: {search, state, type}
    })
  }

  const getGamificationProvider = (id, url) => {
    return axios ({
      method: "get",
      url: `medal/${url}/${id}`,
    })
  }

  const addGamificationProvider = (data, url) => {
    return axios ({
      method: "post",
      url: `medal/${url}`,
      data
    })
  }

  const editGamificationProvider = ({id, data, url, method}) => {
    return axios({
      method: method,
      url: `medal/${url}/${id}`,
      data: data,
    });
  };
  const deleteGamificationProvider = ({id, url}) => {
    return axios({
      method: "delete",
      url: `medal/${url}/${id}`,
    });
  };

  return {
    listGamificationProvider,
    getGamificationProvider,
    addGamificationProvider,
    editGamificationProvider,
    deleteGamificationProvider,
  }

}

export default useGamificationProvider;