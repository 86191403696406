// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledTable = styled.div.attrs({
  className: "StyledTable",
})`
  ${tw`overflow-x-auto w-full`}
`;

export const StyledTableHeader = styled.div.attrs({
  className: "StyledTableHeader",
})`
  ${tw`grid px-8 border-dashed border-b border-neutral py-4 items-center`}
  grid-gap: 0.5rem;
  grid-template-columns: ${(props) => props.gridCell?.join(" ")};

  ${(props) => props.modeTable === "solid" && tw`border-solid border-neutral-70 mb-2 px-4`}
`;

export const StyledTableRowHeader = styled.div.attrs({
  className: "StyledTableRowHeader",
})`
  ${tw`text-xs text-neutral-70 uppercase font-bold`}
`;

export const StyledTableRow = styled.div.attrs({
  className: "StyledTableRow",
})`
  ${tw`grid px-8 items-center `}
  padding-bottom: 18px;
  padding-top: 18px;

   > div {
    ${tw`text-sm font-light text-neutral truncate`}
    letter-spacing: -0.006em;

    strong,
    b,
    .font-bold {
      ${tw`font-bold`}
    }
  }
`;

export const StyledTableBody = styled.div.attrs({
  className: "StyledTableBody",
})`
  > * {
    ${tw`bg-neutral-20 border-dashed border-b border-neutral-70`}
    ${(props) => props.modeTable === "solid" && tw`border-none bg-transparent py-2 px-4`}
    grid-template-columns: ${(props) => props.gridCell?.join(" ")};
    grid-gap: 0.5rem;
    &:nth-child(even) {
      ${tw`bg-white`}
    }
  }
`;
