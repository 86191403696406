import usePassiveTrackingReport from "./PassiveTrackingReport";
import useCheckpointReport from "./CheckpointReport";
import useMembeerReport from "./MembeerReport";
import usePassiveTrackingCreate from "./Create";
import usePassiveTrackingMap from "./Map";

const usePassiveTracking = () => {
  return {
    usePassiveTrackingReport,
    useCheckpointReport,
    useMembeerReport,
    usePassiveTrackingCreate,
    usePassiveTrackingMap,
  };
};

export default usePassiveTracking;
