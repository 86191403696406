// Packages
import styled from 'styled-components';
import tw from 'twin.macro';
import { FormControl, TextareaAutosize } from '@mui/material';

export const StyledFormControl = styled(FormControl)`
  ${({ fullHeight }) => fullHeight && `
    height: 100%;
  `}
  && {
    ${tw`mb-7`}
  }
`

export const StyledTextArea = styled(TextareaAutosize)`
  resize: ${props => props.resize };
  ${({ fullHeight }) => fullHeight && `
    height: 100% !important;
  `}
  && {
    ${tw `
      text-neutral text-sm font-light
      mt-2
      box-border
      bg-neutral-30
      border border-neutral-60 border-solid
      rounded
      py-3 px-4
    `}

    &::placeholder {
      ${tw`opacity-100 text-neutral-70`}
    }
    &:-ms-input-placeholder {
      ${tw`opacity-100 text-neutral-70`}
    }
    &::-webkit-input-placeholder {
      ${tw`opacity-100 text-neutral-70`}
    }

    &.Mui-error {
      ${tw`border-danger`}
    }

    &:focus-visible {
      ${tw`outline-none`}
    }

    &:disabled {
      ${tw`
      text-neutral
      font-light
      text-sm
      bg-neutral-30
    `}
    -webkit-text-fill-color: #B6BBC1;
    }
  }
`;

export const StyledHelperText = styled.span.attrs({
  className: "StyledHelperText",
})`
  ${tw`absolute left-0 text-xs font-light text-danger`}
  bottom: -22px;
`;

export const StyledMaxLength = styled.span`
  ${tw`absolute right-0 text-xs font-light`}
  bottom: -22px;
  color: ${({ maxLengthReached }) => maxLengthReached ? '#E14731' : '#242D31'};
`;
