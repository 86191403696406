import styled from "styled-components";
import tw from "twin.macro";

export const StyledStadistic = styled.div.attrs({
  className: "StyledStadistic",
})`
  ${tw`flex items-center space-x-1`}
  svg {
    ${(props) => props.label === "up" && `transform: rotate(180deg);`}
  }
`;

export const StyledLabel = styled.span.attrs({
  className: "StyledLabel",
})`
  ${tw``}
  ${(props) => (props.label === "up" ? `color:#018145` : `color:#E14731`)}
`;
export const StyledTitle = styled.span.attrs({
  className: "StyledTitle",
})`
  ${tw` text-neutral-80 text-sm font-oswald`}
`;
