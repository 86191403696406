import React from "react";
import PropTypes from "prop-types";

// Components
import useComponents from "..";

// Styles
import { StyledCard, StyledCardHeader, StyledCardContent, StyledCardActions } from "./Card.styles";

/**
 * Card component
 */
export const Card = (props) => {
  const { Typography, useButtons } = useComponents();
  const { DefaultButton } = useButtons();

  const { title, description, link } = props;

  return (
    <StyledCard>
      <StyledCardHeader title={title}/>
      <StyledCardContent>
        <p>{description}</p>
      </StyledCardContent>
      <StyledCardActions>
        <DefaultButton rounded={true}>
          <a href={link}>{"Ver todos"}</a>
        </DefaultButton>
      </StyledCardActions>
    </StyledCard>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default Card;
