const useProjectIntialstate = () => {
  const projectCreateInitialState = {
    id: "",
    name: "",
    countries: [],
    client_id: "",
    expiration_date: "",
    objective: "",
    decision: "",
    status: "",
    created_at: "",
    updated_at: "",
    missions: [],
  };
  return { projectCreateInitialState };
};

export default useProjectIntialstate;
