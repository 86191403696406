// Packages
import React from "react";

// Styles
import { StyledSearch, StyledContentButtons, StyledButtonFile } from "./MembeersView.styles";
import { StyledFormFilter } from "../../Tasks/Views/TaskView.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";
import CheckboxControlled from "../../../components/CheckboxControlled";

const MembeerView = () => {
  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    TextFieldSeach,
    SelectFieldControlled,
    Pagination,
    Typography,
    DatePickerFieldControlled,
    DatePickerRangeControlled,
    useModals,
  } = useComponents();

  const { IconUserStatus, IconUserRemove } = useIcons();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks, useGeneralHooks } = useControllers();
  const { formatDate } = useGeneralHooks();
  const { useMembeers } = useScreenHooks();
  const { useMembeersView } = useMembeers();

  const {
    listMembeers,
    control,
    handleSubmit,
    onSubmit,
    handleChangeStatusMembeer,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    listCountries,
    handleDownloadExcel,
    onClickClear,
    handleCloseMembeers,
    showModal,
    handleCloseModal,
    handleDownloadTemplate,
    handleGoToWaiting,
  } = useMembeersView();

  return (
    <div className="bg-white px-8 pt-10 pb-12 mx-12 rounded-lg">
      <ContentWrappers
        gridColums={["1fr 210px"]}
        className="mb-4 items-center border-b border-neutral-60 pb-8"
      >
        <Typography variant="h3">Listado de membeers</Typography>
        <DefaultButton color="primary" onClick={handleGoToWaiting}>
          Ver lista de espera
        </DefaultButton>
      </ContentWrappers>
      <div className="text-right">
        <Typography
          variant="overline"
          className="cursor-pointer underline"
          onClick={handleDownloadTemplate}
        >
          Descargar plantilla
        </Typography>
      </div>
      <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
        <TextFieldSeach
          placeholder="Buscar"
          name="search"
          id="search"
          control={control}
          onInputChange={onChangeSearch}
        />
        <StyledContentButtons>
          <StyledButtonFile for="inputFileMembeers" onChange={handleCloseMembeers}>
            Inactivar usuarios
            <input type="file" accept=".xlsx,.xls" id="inputFileMembeers" />
          </StyledButtonFile>
          <FilterButton handleSubmit={handleSubmit(onSubmit)}>
            <>
              <SelectFieldControlled
                name="country_id"
                id="country_id"
                control={control}
                label="País"
                options={listCountries}
              />
              <StyledFormFilter>
                <SelectFieldControlled
                  label={"Genero"}
                  name={`gender`}
                  id={`abi-filter-gender`}
                  control={control}
                  options={[
                    { value: "F", label: "Femenino" },
                    { value: "M", label: "Masculino" },
                  ]}
                />
              </StyledFormFilter>
              <StyledFormFilter>
                <SelectFieldControlled
                  label={"Estado"}
                  name={`state`}
                  id={`state`}
                  control={control}
                  options={[
                    { value: "1", label: "Activo" },
                    { value: "2", label: "Inactivo" },
                  ]}
                />
              </StyledFormFilter>
              <StyledFormFilter>
                <SelectFieldControlled
                  label={"Consumidor"}
                  name={`consumer`}
                  id={`consumer`}
                  control={control}
                  options={[
                    { value: "0", label: "No" },
                    { value: "1", label: "Si" },
                  ]}
                />
              </StyledFormFilter>
              <StyledFormFilter>
                <SelectFieldControlled
                  label={"NSE"}
                  name={`nse`}
                  id={`nse`}
                  control={control}
                  options={[
                    { value: "low", label: "Low" },
                    { value: "mid", label: "Mid" },
                    { value: "high", label: "High" },
                  ]}
                />
              </StyledFormFilter>
              <StyledFormFilter>
                <DatePickerRangeControlled
                  label="Fecha creación"
                  name="created_at"
                  id="created_at"
                  control={control}
                  onClickClear={onClickClear}
                />
              </StyledFormFilter>
              <StyledFormFilter>
                <DatePickerFieldControlled
                  name="last_date_entry"
                  id="last_date_entry"
                  label="Fecha último ingreso"
                  control={control}
                />
              </StyledFormFilter>
            </>
          </FilterButton>
          <DefaultButton color="third" onClick={handleDownloadExcel}>
            Exportar
          </DefaultButton>
        </StyledContentButtons>
      </ContentWrappers>
      <TableContent
        headerCellList={[
          // <CheckboxControlled
          //   control={ control }
          //       name="CheckAll"
          //       id="Checkall"
          //       label=""
          //       disabled>
          // </CheckboxControlled>,
          "Fecha de suscripción",
          "Ultimo ingreso",
          "Membeer",
          "Estado membeer",
          "Misiones KO",
          "Email",
          "NSE",
          "Género",
          "País",
          "Consumidor",
          "Etiqueta",
          "Acciones",
        ]}
        gridCell={[
          "1fr",
          "1fr",
          "2fr",
          "1fr",
          "1fr",
          "2fr",
          "1fr",
          "1fr",
          "1fr",
          "1fr",
          "1fr",
          ".5fr",
        ]}
      >
        {listMembeers.length > 0 ? (
          listMembeers.map((membeer) => {
            return (
              <TableRow key={membeer.id}>
                {/* <div>
                  <CheckboxControlled
                    control={ control }
                    name={`check${membeer.id}`}
                    id={`check${membeer.id}`}
                    label=""
                    disabled
                    checked={membeerChecked === membeer.id}
                    onChange={() => {setMembeerChecked(membeer.id)}}
                    >
                  </CheckboxControlled>
                </div> */}
                <div>
                  {membeer?.created_at === null ? "" : formatDate(membeer?.created_at, "dd-mm-Y")}
                </div>
                <div>
                  {membeer?.last_date_entry === null
                    ? ""
                    : formatDate(membeer?.last_date_entry, "dd-mm-Y")}
                </div>
                <div>{membeer.membeer_name}</div>
                <div>{membeer.active}</div>
                <div>{membeer.missions_completed}</div>
                <div>{membeer.email}</div>
                <div>{membeer.nse}</div>
                <div>{membeer.gender === "M" ? "Masculino" : "Femenino"}</div>
                <div>{membeer.country_name}</div>
                <div>{membeer.consumer}</div>
                <div>{membeer.label ? membeer.label.name : "Sin Etiqueta"}</div>
                <div>
                  <button type="button" onClick={() => handleChangeStatusMembeer(membeer)}>
                    {membeer.active === "Activo" ? (
                      <IconUserRemove color="#F5E003" />
                    ) : (
                      <IconUserStatus />
                    )}
                  </button>
                </div>
              </TableRow>
            );
          })
        ) : (
          <div>Sin datos para mostrar</div>
        )}
      </TableContent>
      <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2">Datos cargados con exito</Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </div>
  );
};

export default MembeerView;
