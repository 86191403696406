import React from "react";
import PropTypes from "prop-types";

import {
  StyledFooter,
  StyledFooterInfo,
  StyledFooterNav,
  StyledImageBrand
} from "./Footer.styles";

import imageAbiBrandFooter from "../../../assets/images/img-abi-brand-foorer.svg";

const Footer = ({ className = "" }) => {
  return (
    <StyledFooter className={className}>
      <StyledFooterInfo>
        <StyledImageBrand src={imageAbiBrandFooter} /> ©2022
      </StyledFooterInfo>
      <StyledFooterNav>
        <ul>
          <li>
            <a href="https://membeers.com/terminos-y-condiciones" target="_blank">
              Términos y condiciones
            </a>
          </li>
          <li>
            <a href="https://membeers.com/politica-de-proteccion-de-datos-personales" target="_blank">
              Política de privacidad
            </a>
          </li>
        </ul>
      </StyledFooterNav>
    </StyledFooter>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
