// Packages
import React from "react";
import GoogleMapReact from "google-map-react";

// Components
import useComponents from "../../../../components";

// Styles
import { StyledPassiveTrackingMap } from "./PassiveTrackingMap.styles";

// Controlled
import useControllers from "../../../../../controllers";

// Assets
import useIcons from "../../../../../assets/icons";

const PassiveTrackingMap = () => {
  const { useMarkerMap, ContentWrappers, CardWrapper, TableContent, TableRow } =
    useComponents();
  const { DefaultMarker } = useMarkerMap();
  const { IconReport } = useIcons();

  // Controlled
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { usePassiveTracking } = useTools();
  const { usePassiveTrackingMap } = usePassiveTracking();
  const { steps, cordCenter, handleIndividualDownloadExel } = usePassiveTrackingMap();

  return (
    <>
      <ContentWrappers contentWidth="100%">
        <CardWrapper padding="4rem 2rem" className="mx-8">
          <StyledPassiveTrackingMap className="w-full h-screen">
            <GoogleMapReact
              bootstrapURLKeys={{ key: `${process.env.REACT_APP_API_GOOGLE}` }}
              defaultZoom={ 14 }
              defaultCenter={{ lat: 4.6956544, lng: -74.0524032 }}
              center={cordCenter}
            >
              {steps.map((step, index) => {
                return (
                  <DefaultMarker key={index} textMarker={index + 1} lat={step.lat} lng={step.lng} />
                );
              })}
              {/* <DefaultMarker
                textMarker="1"
                lat={4.638197}
                lng={-74.192499}
              />
              <DefaultMarker
                textMarker="2"
                lat={51.508}
                lng={-0.175}
              />
              <DefaultMarker
                textMarker="3"
                lat={51.505}
                lng={-0.164}
              /> */}
            </GoogleMapReact>
          </StyledPassiveTrackingMap>

          <div className="mt-11">
            <TableContent
              headerCellList={[
                "Item",
                "Pasos de tracking",
                "Dirección / Local",
                "Georeferenciación",
                "Reporte",
              ]}
              gridCell={["95px", "197px", "1fr", "1fr", "60px"]}
            >
              {steps.map((step, index) => {
                return (
                  <TableRow key={index}>
                    <div className="text-center pr-16">{index + 1}</div>
                    <div>{step.name}</div>
                    <div>{step.address}</div>
                    <div>
                      {step.lat} - {step.lng}
                    </div>
                    <div className="flex justify-center">
                      <button
                        onClick={() => {
                          handleIndividualDownloadExel(step.id);
                        }}
                      >
                        <IconReport />
                      </button>
                    </div>
                  </TableRow>
                );
              })}
            </TableContent>
          </div>
        </CardWrapper>
        {/* <Pagination pageCount={3} /> */}
      </ContentWrappers>
    </>
  );
};

export default PassiveTrackingMap;
