const IconTask = ({ color = "#B6B9BA" }) => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.8"
        d="M10.3385 7.16063C10.6419 7.23648 10.9453 7.28705 11.2234 7.28705C12.0072 7.28705 12.7405 7.00892 13.4232 6.75608C14.2575 6.45266 15.0666 6.14925 15.9263 6.32624C15.9516 6.32624 16.0022 6.32624 16.0274 6.32624C16.3309 6.32624 16.609 6.0734 16.609 5.7447V1.11767C16.609 0.839542 16.4067 0.611983 16.1539 0.561414C14.9655 0.333855 13.9288 0.71312 13.0439 1.0671C12.0578 1.42108 11.274 1.72449 10.3638 1.31994V0.71312C10.3638 0.359139 10.1109 0.0557274 9.75693 0.00515874C9.35238 -0.0454099 9.02368 0.283286 9.02368 0.662551V13.0519H10.3638V7.16063H10.3385Z"
        fill={color}
      />
      <path
        opacity="0.24"
        d="M19.6177 23.0139L16.7353 18.3363C16.6595 18.2099 16.5331 18.1087 16.4066 18.0582L13.3472 16.8698L12.1842 14.5689C12.083 14.3414 11.8302 14.1897 11.5773 14.1897H7.78468C7.53183 14.1897 7.30428 14.3161 7.20314 14.5436L5.25624 18.2352H3.05651C2.80366 18.2352 2.5761 18.3616 2.47497 18.5891L0.0729564 23.0139C-0.0281809 23.2162 -0.028181 23.469 0.0982406 23.6713C0.224662 23.8736 0.426937 24 0.67978 24H19.0868C19.3396 24 19.5419 23.8736 19.6683 23.646C19.7695 23.469 19.7694 23.2162 19.6177 23.0139Z"
        fill={color}
      />
    </svg>
  );
};

export default IconTask;
