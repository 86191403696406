import React, { useMemo } from "react";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import useLayouts from "../../layouts";
import useControllers from "../../../controllers";

// Views
import GroupView from "./Views";
import GroupCreate from "./Create";

const UsersGroupLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const location = useLocation();
  const { useUsersGroup } = useControllers().useScreenHooks();
  const { handleBack, header } = useUsersGroup();

  const pathWithOutId = useMemo(() => {
    const pathArray = location.pathname.split("/");
    const filterArray = pathArray.filter((path) => isNaN(path));
    return filterArray.join("/");
  }, [location.pathname]);

  return (
    <PrivateContentLayout
      breadcrumbsList={
        location.pathname.split("/").length > 2
          ? header[Object.keys(header).filter((header) => location.pathname.includes(header))[1]]
          : header[Object.keys(header).filter((header) => location.pathname.includes(header))]
      }
      handleBack={handleBack}
      showBack={location.pathname !== "/grupos"}
      title="Grupos membeer"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const UsersGroup = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersGroupLayout />}>
        <Route index element={<GroupView />} />
        <Route path="creacion" element={<GroupCreate />} />
        <Route path="editar/:id_group" element={<GroupCreate />} />
        <Route path="detalle/:id_group" element={<GroupCreate />} />
      </Route>
    </Routes>
  );
};

export default UsersGroup;
