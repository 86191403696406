// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledWrapperText = styled.div.attrs({
  className: "StyledWrapperText",
})`
  ${tw` flex items-center flex-row mt-8 space-x-12`}
`;
export const StyledTextItem = styled.div.attrs({
  className: "StyledTextItem",
})`
  ${tw` `}
`;

export const StyledContentUploadFile = styled.div.attrs({
  className: "StyledContentUploadFile",
})`
  ${tw`flex items-center`}
  .StyledInputFileContent {
    ${tw`mb-0`}
    .dropzone {
      ${tw`leading-none `}

      button {
        ${tw` rounded-lg normal-case px-6 py-3 text-sm bg-primary`}
        box-shadow: 0px 2px 4px rgba(62, 62, 61, 0.08);
        height: 44px;
      }
    }
  }
`;

export const StyledTableScroll = styled.div.attrs({
  className: "StyledTableScroll",
})`
  .StyledTableHeader,
  .StyledTableBody {
    min-width: 1233px;
  }
`;
