import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";

import debounce from "lodash.debounce";
import useApi from "../../../../api";
import useHelpers from "../../../../helpers";
import useControllers from "../../..";

const brandsView = () => {
  const navigation = useNavigate();
  const [valueTextSearch, setValueTextSearch] = useState("");
  const [listBrands, setListBrands] = useState([]);
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [valueStatus, setValuestatus] = useState("");
  const [valuInitDate, setValuInitDate] = useState("");
  const [valueEndDate, setValueEndDate] = useState("");

  //Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useGeneral } = useQuickFunctions();
  const { filterBrandsShema } = useValidators();
  const { formatDate } = useGeneral();

  const { useActions } = useApi();
  const { useBrandsActions, useGeneralActions } = useActions();
  const { actListBrandPaginate } = useBrandsActions();
  const { actExportExel } = useGeneralActions();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const { control, handleSubmit, resetField } = useForm({
    mode: "all",
    resolver: yupResolver(filterBrandsShema),
    defaultValues: {
      range_date: null
    }
  });

  const onClickClear = () => resetField("range_date");

  const onSubmitFilter = (data, e) => {
    e.preventDefault();

    setValuestatus(data.status);
    setValuInitDate(!!data.range_date ? formatDate(data.range_date[0], "Y-mm-dd", false) : "");
    setValueEndDate(!!data.range_date ? formatDate(data.range_date[1], "Y-mm-dd", false) : "");

    actListBrandPaginate(
      {
        page: 1,
        search: valueTextSearch,
        status: data.status,
        init_date: !!data.range_date ? formatDate(data.range_date[0], "Y-mm-dd", false) : "",
        end_date: !!data.range_date ? formatDate(data.range_date[1], "Y-mm-dd", false) : "",
      },
      loadDataBrands
    );
  };

  const brands = [
    {
      id: 1,
      name: "Club Colombia",
      description: "Lorem Ipsum dolor asimet tres ....",
      state: "Activo",
      date: "29/03/2022",
    },
  ];

  const handleSubmitSearch = (e) => {
    setValueTextSearch(e);
    actListBrandPaginate({ page: 1, search: e }, loadDataBrands);
  };

  const handleChangeSearch = useCallback(debounce(handleSubmitSearch, 750), []);

  const handlePagination = (nro) => {
    actListBrandPaginate(
      {
        page: nro.selected + 1,
        search: valueTextSearch,
        status: valueStatus,
        init_date: valuInitDate,
        end_date: valueEndDate,
      },
      loadDataBrands
    );
  };

  const handleGoToCreate = () => {
    navigation("/marcas/crear");
  };

  const handleEdit = (brand) => {
    navigation(`/marcas/editar/${brand.id}`);
  };

  const loadDataBrands = (data) => {
    setListBrands(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };

  const handleDownloadExel = () => {
    const filters = {
      status: valueStatus,
      search: valueTextSearch,
      init_date: valuInitDate,
      end_date: valueEndDate,
    };

    const filtersProcces = prepareDataFilterDownload(filters);

    actExportExel("brands", filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  const handleIndividualDownloadExel = (id) => {
    const filters = {
      id: id,
    };
    const filtersProcces = prepareDataFilterDownload(filters);

    actExportExel("brands", filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  useEffect(() => {
    actListBrandPaginate({ page: 1 }, loadDataBrands);
  }, []);

  return {
    brands,
    handleGoToCreate,
    handleChangeSearch,
    control,
    handleSubmit,
    onSubmitFilter,
    handlePagination,
    listBrands,
    paginationSettings,
    handleEdit,
    handleDownloadExel,
    handleIndividualDownloadExel,
    onClickClear
  };
};
export default brandsView;
