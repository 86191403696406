// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";

//Forms 
import useFormsProfiler from "./forms/"


const ProfilerCreate = () => {
  const { useScreenHooks } = useControllers();
  const { useProfiler } = useScreenHooks();
  const { useProfilerCreate } = useProfiler();

  const {    
    type,
    control,
    handleSubmit,
    onSubmit,
    // showModal,
    // handleCloseModal,
    listCountries,
    // listRegions,
    // listRegionABI,
    // listStates,
    // listCities,
    // fields,
    // append,
    // remove
  } = useProfilerCreate();

  const {
    ContentWrappers,
    useButtons,
    TextFieldControlled,
    SelectFieldControlled,
    Typography,
    useModals,
    TextAreaFieldControlled,
    SelectMultiFieldControlled,
    CheckboxControlled,
    TimePickerFieldControlled,
    DatePickerFieldControlled,
    FormLabel,
    InputFileControlled,
  } = useComponents();

  const { FormProfilerLabels, FormProfilerNoLabels} = useFormsProfiler();

  const { DefaultButton } = useButtons();  
  
  return (
    <div className="bg-white px-16 pt-10 pb-12 mx-24 rounded-lg">      
        {type === 0 || type >= 4 ? <FormProfilerLabels type={type}/> : <FormProfilerNoLabels type={type}/>} 
    </div>
  )

}

export default ProfilerCreate;