import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import tw from "twin.macro";

export const StyledCard = styled(Card).attrs({
  className: "StyledCard",
})`
  background-color: #ffffff !important;
  color: #000000 !important;
  border-radius: 15px !important;
`;

export const StyledCardContent = styled(CardContent).attrs({
  className: "StyledCardContent",
})`
  > p {
    font-family: "Avenir" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #363635;
  }
`;

export const StyledCardHeader = styled(CardHeader).attrs({
  className: "StyledCardHeader",
})`
  > div {
    > span {
      font-family: "Avenir" !important;
      font-style: normal;
      font-weight: 300;
      font-size: 24px !important;
      line-height: 28px;
    }
  }
`;

export const StyledCardActions = styled(CardActions).attrs({
  className: "StyledCardActions",
})``;
