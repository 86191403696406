import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";


import useComponents from "../../../../../components";


const UserEngaments = (props) => {
  const {dataMemebers, countrySelect} = props;
  const { Typography } = useComponents();

  return (
    <>
    <div className="flex items-center mt-2">
      <div className="w-1/2">
      <Link to={{pathname: "dashboard/engament", state: {type: 'notReceivedMissionsLastWeek', countryId: countrySelect?.id}}}>
        <Typography variant="body1" className="cursor-pointer">Sin recibir misiones</Typography>
      </Link>
      <Typography variant="caption" color="#424242">Más de una semana</Typography>
      </div>
      <div className="w-1/2 bg-yellow-200 rounded-full h-2.5 mr-4">
        <div className="bg-yellow-600 rounded-full h-2.5" style={{width: dataMemebers?.notMission + '%'}}></div>
      </div>
      <Typography variant="body1" className="w-12">{dataMemebers?.notMission + '%'}</Typography>
    </div>
    <div className="flex items-center mt-2">
      <div className="w-1/2">
      <Link to={{pathname: "dashboard/engament", state: {type: 'doNotOpenedAppLast3Months', countryId: countrySelect?.id}}}>
        <Typography variant="body1" className="cursor-pointer">Sin abrir app</Typography>
      </Link>
      <Typography variant="caption" color="#424242">Más de 15 días</Typography>
      </div>
      <div className="w-1/2 bg-green-200 rounded-full h-2.5 mr-4">
        <div className="bg-green-600 rounded-full h-2.5" style={{width: dataMemebers?.openApp + '%'}}></div>
      </div>
      <Typography variant="body1" className="w-12">{dataMemebers?.openApp + '%'}</Typography>
    </div>
    <div className="flex items-center mt-2">
      <div className="w-1/2">
      <Link to={{pathname: "dashboard/engament", state: {type: 'responseRate', countryId: countrySelect?.id}}}>
        <Typography variant="body1" className="cursor-pointer">Tasa respuesta</Typography>
      </Link>
      <Typography variant="caption" color="#424242">{"< 70%"}</Typography>
      </div>
      <div className="w-1/2 bg-blue-200 rounded-full h-2.5 mr-4">
        <div className="bg-blue-600 rounded-full h-2.5" style={{width: dataMemebers?.responseRate + '%'}}></div>
      </div>
      <Typography variant="body1" className="w-12">{dataMemebers?.responseRate + '%'}</Typography>
    </div>
    <div className="flex items-center mt-2">
      <div className="w-1/2">
      <Link to={{pathname: "dashboard/engament", state: {type: 'newUser', countryId: countrySelect?.id}}}>
        <Typography variant="body1" className="cursor-pointer">Nuevos usuarios</Typography>
      </Link>
      <Typography variant="caption" color="#424242">Membeers mes actual</Typography>
      </div>
      <div className="w-1/2 bg-purple-200 rounded-full h-2.5 mr-4">
        <div className="bg-purple-600 rounded-full h-2.5" style={{width: dataMemebers?.newUser?.widthBar + '%'}}></div>
      </div>
      <Typography variant="body1" className="w-12">{dataMemebers?.newUser?.value}</Typography>
    </div>
    </>
  );
};

export default UserEngaments;
