import React from "react";
import useIcons from "../../../../assets/icons";
import useScreenHooks from "../../../../controllers/screenHooks";
import useComponents from "../../../components";

//Styles
import {
  StyledAddOptions,
  StyledListBrands,
  StyledBankCheckList,
  StyledWrapperRadio,
  StyledWarpperSum,
} from "./Create.styles";

import { StyledHelperText } from "../../../components/SelectField/SelectField.styles";

import _ from "lodash";

const Create = () => {
  const { IconPlus, IconDelete } = useIcons();

  const {
    ContentWrappers,
    CardWrapper,
    Typography,
    TextFieldControlled,
    SelectFieldControlled,
    SelectMultiFieldControlled,
    RadioButtonControlled,
    useModals,
    useButtons,
    InputSwitchControlled,
  } = useComponents();

  const { DefaultButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useContriesScreen } = useScreenHooks();
  const { countrieCreate } = useContriesScreen();
  const {
    control,
    showModal,
    handleCloseModal,
    handleSubmit,
    onSubmit,
    documentFields,
    documentAppend,
    documentRemove,
    regionFields,
    regionAppend,
    regionRemove,
    bankFields,
    bankAppend,
    bankARemove,
    nseFields,
    distributionFields,
    listCountriesFree,
    listLanguages,
    listBrands,
    errors,
    countrieName,
    textTypeOfAction,
    watch,
  } = countrieCreate();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-11/12 mt-4" as="section">
        <CardWrapper>
          <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>
          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <ContentWrappers
              gridColums={["48%", "48%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <SelectFieldControlled
                label="Nombre país"
                name="country_id"
                id="country_id"
                control={control}
                options={listCountriesFree}
              />

              <TextFieldControlled
                label="ISO"
                name="country_iso"
                id="abi-country_iso"
                control={control}
                disabled
              />
            </ContentWrappers>

            <ContentWrappers
              gridColums={["48%", "48%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <TextFieldControlled
                label="Simbolo"
                name="country_symbol"
                id="abi-country_symbol"
                control={control}
                disabled
              />

              <TextFieldControlled
                label="Moneda"
                name="country_currency"
                id="abi-country_currency"
                control={control}
                disabled
              />
            </ContentWrappers>

            <ContentWrappers
              gridColums={["48%", "48%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
              className="border-b border-neutral-60 pb-4 mb-8"
            >
              <SelectFieldControlled
                label="languaje"
                name="languaje"
                id="abi-languaje"
                control={control}
                options={listLanguages}
              />
              <TextFieldControlled
                label="Unidad de moneda por punto membeer"
                name="currency_membeer_point"
                id="abi-currency_membeer_point"
                control={control}
              />
            </ContentWrappers>

            <Typography variant="h3" className="pb-6">
              2. AGREGAR TIPO DE DOCUMENTO
            </Typography>

            {_.map(documentFields, (documentField, idx) => {
              return (
                <ContentWrappers
                  gridColums={["45%", "45%", "3%"]}
                  gap={{
                    row: "0px",
                    column: "4%",
                  }}
                  className="items-end"
                  key={`document-${documentField.id}`}
                >
                  <div>
                    <Typography variant="h4" className="flex cursor-pointer uppercase pb-3">
                      Tipo de documento {idx < 9 ? `0${idx + 1}` : idx + 1}
                      <button
                        type="button"
                        onClick={() => {
                          documentRemove(idx);
                        }}
                      >
                        <IconDelete />
                      </button>
                    </Typography>
                    <SelectFieldControlled
                      label="Tipo de documento"
                      name={`documents[${idx}].type`}
                      id={`documents[${idx}].type`}
                      control={control}
                      options={[
                        {
                          value: 1,
                          label: "Cedula cuidadania",
                        },
                        {
                          value: 2,
                          label: "Pasaporte",
                        },
                        {
                          value: 3,
                          label: "DNI",
                        },
                      ]}
                    />
                  </div>
                  <TextFieldControlled
                    label="Sigla"
                    name={`documents[${idx}].acronym`}
                    id={`documents[${idx}].acronym`}
                    control={control}
                  />
                  <StyledAddOptions>
                    <button
                      onClick={() => {
                        documentAppend({ type: "", acronym: "" });
                      }}
                      type="button"
                    >
                      <IconPlus />
                    </button>
                  </StyledAddOptions>
                </ContentWrappers>
              );
            })}

            <Typography variant="h3" className="pb-6">
              3. REGIONES ABI
            </Typography>

            {_.map(regionFields, (region, idx) => {
              return (
                <ContentWrappers
                  gridColums={["45%", "45%", "3%"]}
                  gap={{
                    row: "0px",
                    column: "4%",
                  }}
                  className="items-end"
                  key={`region-${region.id}`}
                >
                  <div>
                    <Typography variant="h4" className="flex cursor-pointer uppercase pb-3">
                      {idx + 1}. Región {idx < 9 ? `0${idx + 1}` : idx + 1}
                      <button
                        type="button"
                        onClick={() => {
                          regionRemove(idx);
                        }}
                      >
                        <IconDelete />
                      </button>
                    </Typography>

                    <TextFieldControlled
                      label="Región"
                      name={`abi_regions[${idx}].region`}
                      id={`abi_regions[${idx}].region`}
                      control={control}
                    />
                  </div>
                  <TextFieldControlled
                    label="Distribución poblacional"
                    name={`abi_regions[${idx}].population_distribution`}
                    id={`abi_regions[${idx}].population_distribution`}
                    control={control}
                  />
                  <StyledAddOptions>
                    <button
                      onClick={() => {
                        regionAppend({ region: "", population_distribution: "" });
                      }}
                      type="button"
                    >
                      <IconPlus />
                    </button>
                  </StyledAddOptions>
                </ContentWrappers>
              );
            })}

            <Typography variant="h3" className="pb-6">
              4. DISTRIBUCIÓN CUPOS DE NSE
            </Typography>
            {_.map(nseFields, (nse, idx) => {
              return (
                <StyledWarpperSum key={`nse-${nse.id}`}>
                  <ContentWrappers
                    gridColums={["100%"]}
                    gap={{
                      row: "0px",
                      column: "4%",
                    }}
                  >
                    <TextFieldControlled
                      label="LOW SEC (1 & 2) - Distribución poblacional"
                      name={`nse[${idx}].low`}
                      id={`nse[${idx}].low`}
                      control={control}
                    />
                  </ContentWrappers>

                  <ContentWrappers
                    gridColums={["100%"]}
                    gap={{
                      row: "0px",
                      column: "4%",
                    }}
                  >
                    <TextFieldControlled
                      label="MID SEC (3 & 4) - Distribución poblacional"
                      name={`nse[${idx}].mid`}
                      id={`nse[${idx}].mid`}
                      control={control}
                    />
                  </ContentWrappers>

                  <ContentWrappers
                    gridColums={["100%"]}
                    gap={{
                      row: "0px",
                      column: "4%",
                    }}
                  >
                    <TextFieldControlled
                      label="HIGH SEC (5 & 6) - Distribución poblacional"
                      name={`nse[${idx}].high`}
                      id={`nse[${idx}].high`}
                      control={control}
                    />
                  </ContentWrappers>
                  {errors.nse && <StyledHelperText>{errors.nse.message}</StyledHelperText>}
                </StyledWarpperSum>
              );
            })}

            <Typography variant="h3" className="pb-6">
              5. DISTRIBUCIÓN CUPOS POR EDAD
            </Typography>

            {_.map(distributionFields, (distage, idx) => {
              return (
                <StyledWarpperSum key={`dist-${distage.id}`}>
                  <ContentWrappers
                    gridColums={["100%"]}
                    gap={{
                      row: "0px",
                      column: "4%",
                    }}
                  >
                    <TextFieldControlled
                      label="18 - 24  - Distribución poblacional"
                      name={`age_distribution[${idx}].small`}
                      id={`age_distribution[${idx}].small`}
                      control={control}
                    />
                  </ContentWrappers>

                  <ContentWrappers
                    gridColums={["100%"]}
                    gap={{
                      row: "0px",
                      column: "4%",
                    }}
                  >
                    <TextFieldControlled
                      label="25 - 34  - Distribución poblacional"
                      name={`age_distribution[${idx}].medium`}
                      id={`age_distribution[${idx}].medium`}
                      control={control}
                    />
                  </ContentWrappers>
                  <ContentWrappers
                    gridColums={["100%"]}
                    gap={{
                      row: "0px",
                      column: "4%",
                    }}
                  >
                    <TextFieldControlled
                      label="35 - 44  - Distribución poblacional"
                      name={`age_distribution[${idx}].normal`}
                      id={`age_distribution[${idx}].normal`}
                      control={control}
                    />
                  </ContentWrappers>
                  <ContentWrappers
                    gridColums={["100%"]}
                    gap={{
                      row: "0px",
                      column: "4%",
                    }}
                  >
                    <TextFieldControlled
                      label="45 - 64  - Distribución poblacional"
                      name={`age_distribution[${idx}].big`}
                      id={`age_distribution[${idx}].big`}
                      control={control}
                    />
                  </ContentWrappers>
                  {errors.age_distribution && (
                    <StyledHelperText>{errors.age_distribution.message}</StyledHelperText>
                  )}
                </StyledWarpperSum>
              );
            })}

            <Typography variant="h3" className="pb-6">
              6. AGREGAR BANCOS
            </Typography>

            {_.map(bankFields, (bank, idx) => {
              return (
                <div key={`bank-${bank.id}`}>
                  <ContentWrappers
                    gridColums={["93%", "3%"]}
                    gap={{
                      row: "0px",
                      column: "4%",
                    }}
                    className="items-end"
                    //key={`bank-${bank.id}`}
                  >
                    <div>
                      <Typography variant="h4" className="flex cursor-pointer uppercase pb-3">
                        ENTIDAD {idx < 9 ? `0${idx + 1}` : idx + 1}
                        <button
                          type="button"
                          onClick={() => {
                            bankARemove(idx);
                          }}
                        >
                          <IconDelete />
                        </button>
                      </Typography>

                      <TextFieldControlled
                        label="Banco"
                        name={`banks[${idx}].name`}
                        id={`banks[${idx}].name`}
                        control={control}
                      />
                    </div>

                    <StyledAddOptions>
                      <button
                        onClick={() => {
                          bankAppend({
                            name: "",
                            nacionality: false,
                            mobile: false,
                            nit: false,
                            spouse: false,
                            address: false,
                            pay_form: false,
                          });
                        }}
                        type="button"
                      >
                        <IconPlus />
                      </button>
                    </StyledAddOptions>
                  </ContentWrappers>
                  <StyledBankCheckList>
                    <InputSwitchControlled
                      label={"Nacionalidad"}
                      name={`banks[${idx}].nacionality`}
                      id={`banks[${idx}].nacionality`}
                      control={control}
                    />
                    <InputSwitchControlled
                      name={`banks[${idx}].mobile`}
                      id={`banks[${idx}].mobile`}
                      label={"Teléfono móvil"}
                      control={control}
                    />
                    <InputSwitchControlled
                      name={`banks[${idx}].nit`}
                      id={`banks[${idx}].nit`}
                      label={"Número de DNI / ID"}
                      control={control}
                    />
                    <InputSwitchControlled
                      name={`banks[${idx}].spouse`}
                      id={`banks[${idx}].spouse`}
                      label={"Estado civil"}
                      control={control}
                    />
                    <InputSwitchControlled
                      name={`banks[${idx}].address`}
                      id={`banks[${idx}].address`}
                      label={"Dirección completa"}
                      control={control}
                    />
                    <InputSwitchControlled
                      name={`banks[${idx}].pay_form`}
                      id={`banks[${idx}].pay_form`}
                      label={"Formulario de dispersoras de pago"}
                      control={control}
                    />
                    <InputSwitchControlled
                      name={`banks[${idx}].email`}
                      id={`banks[${idx}].email`}
                      label={"Correo Electronico"}
                      control={control}
                    />
                    <InputSwitchControlled
                      name={`banks[${idx}].type_account`}
                      id={`banks[${idx}].type_account`}
                      label={"Tipo de cuenta"}
                      control={control}
                    />
                    <InputSwitchControlled
                      name={`banks[${idx}].account_number`}
                      id={`banks[${idx}].account_number`}
                      label={"Nro. de cuenta"}
                      control={control}
                    />
                  </StyledBankCheckList>
                  <div>
                    {(watch(`banks[${idx}].nit`) || watch(`banks[${idx}].mobile`) || watch(`banks[${idx}].account_number`)) &&<Typography variant="h4" className="pb-6 uppercase">
                      rango de carácteres permitidos en solicitud
                    </Typography>}
                    {watch(`banks[${idx}].nit`) && <div class="flex items-end gap-x-8 max-w-[650px]">
                      <TextFieldControlled
                        label="DNI"
                        type={'number'}
                        name={`banks[${idx}].validations.nit.min`}
                        id={`banks[${idx}].validations.nit.min`}
                        placeholder={'Mínimo de carácteres'}
                        control={control}
                      />
                      <TextFieldControlled
                        type={'number'}
                        name={`banks[${idx}].validations.nit.max`}
                        id={`banks[${idx}].validations.nit.max`}
                        placeholder={'Máximo de carácteres'}
                        control={control}
                      />
                      <TextFieldControlled
                        type={'text'}
                        name={`banks[${idx}].validations.nit.pattern`}
                        id={`banks[${idx}].validations.nit.pattern`}
                        placeholder={'Expresion regular'}
                        control={control}
                      />
                    </div>}
                    {watch(`banks[${idx}].mobile`) && <div class="flex items-end gap-x-8 max-w-[500px]">
                      <TextFieldControlled
                        label="Teléfono móvil"
                        type={'number'}
                        name={`banks[${idx}].validations.mobile.min`}
                        id={`banks[${idx}].validations.mobile.min`}
                        placeholder={'Mínimo de carácteres'}
                        control={control}
                      />
                      <TextFieldControlled
                        type={'number'}
                        name={`banks[${idx}].validations.mobile.max`}
                        id={`banks[${idx}].validations.mobile.max`}
                        placeholder={'Máximo de carácteres'}
                        control={control}
                      />
                    </div>}
                    {watch(`banks[${idx}].account_number`) && <div class="flex items-end gap-x-8 max-w-[500px]">
                      <TextFieldControlled
                        label="Nro. de cuenta"
                        type={'number'}
                        name={`banks[${idx}].validations.account_number.min`}
                        id={`banks[${idx}].validations.account_number.min`}
                        placeholder={'Mínimo de carácteres'}
                        control={control}
                      />
                      <TextFieldControlled
                        type={'number'}
                        name={`banks[${idx}].validations.account_number.max`}
                        id={`banks[${idx}].validations.account_number.max`}
                        placeholder={'Máximo de carácteres'}
                        control={control}
                      />
                    </div>}
                  </div>
                </div>
              );
            })}

            <Typography variant="h3" className="pb-6">
              7. AGREGAR MARCAS
            </Typography>

            <StyledListBrands>
              <SelectMultiFieldControlled
                placeholder="Selecciona una opcion"
                name={`brands`}
                id={`abi-brands`}
                control={control}
                options={listBrands}
                disableCloseOnSelect
                renderchecked
              />
            </StyledListBrands>

            <Typography variant="h3" className="pb-6">
              8. ACTIVAR O DESACTIVAR PAÍS
            </Typography>

            <StyledWrapperRadio>
              <RadioButtonControlled
                id="abi-active"
                name="active"
                control={control}
                radioOptions={[
                  { label: "Activar", value: 1 },
                  { label: "Desactivar", value: 0 },
                ]}
              />
            </StyledWrapperRadio>

            <div className="flex items-center justify-center mt-4">
              <DefaultButton color="secondary" type="submit">
                Finalizar y publicar
              </DefaultButton>
            </div>
          </form>
        </CardWrapper>
      </ContentWrappers>
      <DefaultModal isOpen={showModal}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            El país {countrieName} se ha {textTypeOfAction} con éxito.
          </Typography>
          <DefaultButton color="success" onClick={handleCloseModal}>
            Aceptar
          </DefaultButton>
        </div>
      </DefaultModal>
    </>
  );
};

export default Create;
