// Packages
import React from "react";
import _ from "lodash";

//Styles
import { StyledHelperText } from "../../../components/SelectField/SelectField.styles";

// Components
import useLayouts from "../../../layouts";
import useComponents from "../../../components";
import useComponentsProjects from "../components";

// Controllers
import useControllers from "../../../../controllers";

const EditProject = () => {
  const { PrivateContentLayout } = useLayouts();

  // Components
  const {
    ContentWrappers,
    useButtons,
    FormLabel,
    TextFieldControlled,
    SelectFieldControlled,
    TextAreaFieldControlled,
    DatePickerFieldControlled,
    CardWrapper,
    Typography,
    CheckboxGroupControlled,
  } = useComponents();

  const { DefaultButton } = useButtons();
  const { TableMisions } = useComponentsProjects();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useProjectsScreen } = useScreenHooks();
  const { useProjectsEdit, useProjectsCreate } = useProjectsScreen();

  const { listCountries, control, role, handleSubmit, onSubmit, errors, getValues } =
    useProjectsCreate();

  return (
    <>
      <ContentWrappers gridColums={["1fr"]} className="max-w-screen-2xl w-7/12" as="section">
        <CardWrapper>
          <Typography variant="h3">1. CONFIGURACIÓN GENERAL</Typography>
          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <TextFieldControlled
              label="Nombre"
              placeholder="Nombre"
              name="name"
              id="name"
              control={control}
            />
            {role === "Súper Administrador" ? (
              <>
                <FormLabel label="Paises" />

                <div className="grid grid-cols-2 gap-y-2 ml-1 relative mb-7">
                  <CheckboxGroupControlled
                    options={listCountries}
                    control={control}
                    isSelectedAll
                    name="country_id"
                  />
                  <StyledHelperText>{errors["country_id"]?.message}</StyledHelperText>
                </div>
              </>
            ) : (
              <ContentWrappers
                gridColums={["48%", "48%"]}
                gap={{
                  row: "0px",
                  column: "4%",
                }}
              >
                <SelectFieldControlled
                  label="País"
                  name="country_id"
                  id="country_id"
                  control={control}
                  options={[listCountries]}
                />
              </ContentWrappers>
            )}

            <ContentWrappers
              gridColums={["48%", "48%"]}
              gap={{
                row: "0px",
                column: "4%",
              }}
            >
              <SelectFieldControlled
                label="Cliente interno"
                name="client_id"
                id="client_id"
                control={control}
                options={[
                  {
                    label: "Global",
                    value: 0,
                  },
                ]}
                disabled
              />
              <div className="flex items-center">
                <DatePickerFieldControlled
                  label="Fecha de vencimiento"
                  name="expiration_date"
                  id="expiration_date"
                  control={control}
                  disabled
                />
              </div>
            </ContentWrappers>

            <TextAreaFieldControlled
              label="Objetivo"
              placeholder="Textarea placeholder"
              name="objective"
              id="objective"
              control={control}
              disabled
            />

            <TextAreaFieldControlled
              label="Desición a evaluar"
              placeholder="Textarea placeholder"
              name="decision"
              id="decision"
              control={control}
              disabled
            />

            {/*   <TableMisions /> */}

            <div className="justify-center flex">
              <DefaultButton color="secondary" type="submit">
                Guardar Proyecto
              </DefaultButton>
            </div>
          </form>
        </CardWrapper>
      </ContentWrappers>
    </>
  );
};

export default EditProject;
