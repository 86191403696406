// Packages
import React from "react";

// Styles
import { StyledSearch, StyledContentButtons, StyleLoading } from "./ProfilerView.styles";
import { StyledFormFilter } from "../../Tasks/Views/TaskView.styles";

// import useAssets from '../../../assets'
import { CircularProgress } from "@mui/material";

// Components
import useComponents from "../../../components";
import useComponentsProfiler from "./tables";

// Controllers
import useControllers from "../../../../controllers";


const ProfilerView = () => {

  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    useAccordion,
    TextFieldSeach,
    Pagination,
    Dropdown,
  } = useComponents();

  const {
    TableProfilerLicence,
    TableProfilerRegion,
    TableProfilerRegionABI,
    TableProfilerAgeGenderNSE
  } = useComponentsProfiler();

  const { AccordionContent, AccordionHeader, AccordionBody } = useAccordion();

  const { DefaultButton, FilterButton } = useButtons();  

  const { useScreenHooks } = useControllers();
  const { useProfiler } = useScreenHooks();
  const { useProfilerView } = useProfiler();

  const { 
    listProfilersType, 
    listProfilers, 
    loading,
    handleCreateNewPerfilacion,
    handleClonePerfilacion,
    handleEditPerfilacion,
    handleDownloadExcel,
    handleSubmit,
    onSubmit,
    handlePagination, 
    paginationSettings,
    control,
    onChangeSearch,
    onExpand, 
    expanded } = useProfilerView();

  const renderSwitch = (type, item) => {        
    switch (type) {
      case 0: return <TableProfilerLicence item={item}/>        
      case 1: return <TableProfilerRegion item={item}/>        
      case 2: return <TableProfilerRegionABI item={item}/>        
      case 3: return <TableProfilerRegionABI type={type} item={item}/>        
      case 4: return <TableProfilerAgeGenderNSE type={type} item={item}/>        
      case 5: return <TableProfilerAgeGenderNSE type={type} item={item}/>        
      case 6: return <TableProfilerAgeGenderNSE type={type} item={item}/>        
    }
  }

  return (
    <div>
      {listProfilersType.map((perfilacion, index) => {
        return (
          <div key={index}> 
            <AccordionContent type="secondary" expanded={perfilacion.type === expanded}>            
              <AccordionHeader arrowColor="#FFA700" borderExpanded={true} onExpand={onExpand} profiler={perfilacion.type}>
                <div className="flex justify-between w-full px-5">
                  <h4 className="uppercase text-base font-oswald mt-2.5">{perfilacion.title}</h4>
                  <DefaultButton color="secondary" onClick={() => {handleCreateNewPerfilacion(perfilacion)}}>
                    Crear nuevo 
                  </DefaultButton>
                </div>
              </AccordionHeader>
              <AccordionBody>
                <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
                  <TextFieldSeach 
                    placeholder="Buscar"
                    name="search"
                    id="search"
                    control={control}
                    onInputChange={onChangeSearch}
                    />
                  <StyledContentButtons>                   
                    <DefaultButton color="third" onClick={handleDownloadExcel}>
                      Exportar a Excel
                    </DefaultButton>
                  </StyledContentButtons>
                </ContentWrappers>
                { loading && <StyleLoading style={{ color: "red !important", display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </StyleLoading> }
                { !loading && 
                <>
                  <TableContent
                    headerCellList={[...perfilacion.columnsTitle]}
                    gridCell={[...perfilacion.cellSize]}
                  >                  
                    {listProfilers.length > 0 ? (
                      listProfilers.map((item, index) => {
                        return (
                          <TableRow key={index}>                                                                                                                            
                            {renderSwitch(perfilacion.type, item)}
                            <div className="flex justify-end">
                              <Dropdown options={[
                                {id: 'clone', action: ()=> handleClonePerfilacion(item.id)},
                                {id: 'edit', action: ()=> handleEditPerfilacion(item.id, perfilacion.type)},
                                ]}/>
                            </div>
                          </TableRow>
                        );
                      })
                    ) : (
                      <div>Sin datos para mostrar</div>
                    )}
                  </TableContent>
                  <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
                </>
                }
              </AccordionBody>              
            </AccordionContent>
          </div>
        )
      })}
    </div>
  );
}

export default ProfilerView;