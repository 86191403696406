import styled from "styled-components";
import tw from "twin.macro";

export const StyledTitle = styled.p.attrs({
  className: "StyledTitle",
})`
  ${tw`block text-center pointer-events-none text-4xl font-bold leading-none`}

  ${(props) => {
    switch (props.titlePosition) {
      case "center":
        return tw`
        absolute top-[44%] left-0 right-0 mx-auto w-auto -translate-y-1/2 
        `;
      default:
        return tw`
        relative w-full text-center
        `;
    }
  }}
`;
