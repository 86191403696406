// Packages
import React from "react";

// Styles
import { StyledSearch, StyledContentButtons } from "./FaqsView.styles";
import { StyledFormFilter } from "../../Tasks/Views/TaskView.styles";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import useIcons from "../../../../assets/icons";

const FaqsView = () => {
  const { IconSearch } = useIcons();

  const {
    ContentWrappers,
    TableContent,
    TableRow,
    useButtons,
    useModals,
    TextFieldControlled,
    SelectFieldControlled,
    Pagination,
    Typography,
    Badge,
    Dropdown,
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();

  const { useScreenHooks } = useControllers();
  const { useFaqs } = useScreenHooks();
  const { useFaqsView } = useFaqs();
  const {
    control,
    handleSubmit,
    onSubmit,
    listFaqs,
    handleCreateNewFaq,
    handleEditFaq,
    handleRemoveFaq,
    handleCloseModal,
    handleConfirmDelete,
    listCountries,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    modalDelete,
    handleDownloadExcel
  } = useFaqsView();

  return (
    <div className="bg-white px-8 pt-10 pb-12 mx-12 rounded-lg">
      <ContentWrappers
        gridColums={["1fr 210px"]}
        className="mb-8 items-center border-b border-neutral-60 pb-8"
      >
        <Typography variant="h3" className="uppercase">
          Listado de faqs
        </Typography>
        <DefaultButton
          color="secondary"
          onClick={handleCreateNewFaq}
        >          
          Crear Nueva FAQ
        </DefaultButton>
      </ContentWrappers>
      <ContentWrappers gridColums={["1fr 1fr"]} className="mb-10">
        <StyledSearch>
          <TextFieldControlled
            placeholder="Buscar"
            name="search"
            id="search"
            control={control}
            onInputChange={onChangeSearch}
          />
          <button type="button">
            <IconSearch />
          </button>
        </StyledSearch>
        <StyledContentButtons>
          <FilterButton handleSubmit={handleSubmit(onSubmit)}>
            <StyledFormFilter>
              <SelectFieldControlled
                placeholder={"País"}
                label="País"
                name={`country_id`}
                id={`abi-filter-country`}
                control={control}
                options={listCountries}
              />
              <SelectFieldControlled
                label={"Estado"}
                name={`status`}
                id={`abi-filter-status`}
                control={control}
                options={[
                  { value: false, label: "Inactiva" },
                  { value: true, label: "Activa" },
                ]}
              />
            </StyledFormFilter>
          </FilterButton>
          <DefaultButton color="third" onClick={handleDownloadExcel}>
            Exportar a Excel
          </DefaultButton>
        </StyledContentButtons>
      </ContentWrappers>
      <TableContent
        headerCellList={["Id", "País", "Faqs Actuales", "Estado", "Acciones"]}
        gridCell={["0.5fr", "1.2fr", "1fr", "1.25fr", "120px"]}
      >
        {listFaqs.length > 0 ? (
          listFaqs.map((list, index) => {
            return (
              <TableRow key={index}>
                <div>{list?.id}</div>
                <div className="cursor-pointer">
                  <b>{list?.country?.name}</b>
                </div>
                <div>{list.faqsCount} </div>
                <div>
                  <Badge
                    disabled={!list?.status}
                    variant={list?.status ? "success" : "neutral"}
                    label={list?.status ? "Activa" : "Inactiva"}
                  />
                </div>
                <div className="flex justify-end">
                  <Dropdown options={[
                    {id: 'delete', action: ()=> handleRemoveFaq(list.id, list.country.name)},
                    {id: 'edit', action: ()=> handleEditFaq(list)},
                    ]}/>
                </div>
              </TableRow>
            );
          })
        ) : (
          <div>Sin datos para mostrar</div>
        )}
      </TableContent>
      <Pagination onPageChange={handlePagination} pageCount={paginationSettings.totalPages} />
      <DefaultModal isOpen={modalDelete.show}>
        <div className="flex items-center flex-col space-y-8">
          <Typography variant="body2" className="text-center">
            Esta seguro de eliminar <b>{modalDelete.name}</b>?.
          </Typography>
          <div className="flex items-center space-x-4">
            <DefaultButton color="secondary" onClick={handleCloseModal}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="success" onClick={handleConfirmDelete}>
              Aceptar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default FaqsView;
