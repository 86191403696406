// Packages
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Helpers
import useHelpers from "../../../../helpers";

// Components
import useComponents from "../../../../views/components";

// Actions
import useActions from "../../../../api/actions";

// Constants
import {
  QUESTION_TYPES,
} from "../../../../config/constants/toolsAI";

const useToolsCreateAI = () => {
  // State
  const [isQuestionEditable, setIsQuestionEditable] = useState(null);
  const [isQuestionVisible, setIsQuestionVisible] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [promptCategories, setPrompCategories] = useState(null);
  const [promptList, setPromptlist] = useState(null)
  const [ pathParams, setPathParams ] = useState({
    prompt_category: ""
  });

  const toolStateAI = useSelector(state => state.organizeToolsAI);
  const surveyState = useSelector(state => state.addTools);

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast } = useQuickFunctions();
  const { error } = useToast();
  const { toolsAIValidationSchema, promptByCategoryValidationSchema } = useValidators();
  const { navigateTo } = useNavigation();

  // Components
  const { Toast } = useComponents();

  // Actions
  const { useToolsActions, usePromptsActions, dispatch } = useActions();
  const {
    actAddQuestion,
    actAddSurveyAI,
    actCleanQuestionsAI,
    actGenerateSurveyAI,
    actUpdateQuestionAI,
    actUpdateOptionAI,
    actRemoveQuestionAI,
    actRemoveOptionAI,
    actAddOptionAI,
  } = useToolsActions();

  const {
    actGetPromptCategories,
    actListPrompts,
  } = usePromptsActions();

  const {
    control,
    handleSubmit,
    watch,
    setValue: setPrompt
  } = useForm({
    mode: "all",
    resolver: yupResolver(toolsAIValidationSchema),
  });

  const {
    control: promptByCategoryControl,
    handleSubmit: getPromptByCategory,
    watch: watchPromptByCategory
  } = useForm({
    mode: "all",
    resolver: yupResolver(promptByCategoryValidationSchema),
  });

  const watchAIPrompt = watch("ai_prompt");
  const watchCategories = watchPromptByCategory("categories")

  const handleChangeQuestionVisibility = (itemNumber) => {
    setIsQuestionVisible(itemNumber);
  };

  const handleMakeQuestionEditable = (itemNumber) => {
    setIsQuestionEditable(itemNumber);
  };

  const handleEditQuestion = (prevTitle, newTitle) => {
    const data = {
      prevTitle,
      newTitle,
    };
    dispatch(actUpdateQuestionAI(data));
  };

  const handleEditOption = (questionTitle, prevOption, newOption) => {
    const data = {
      questionTitle,
      prevOption,
      newOption,
    };
    dispatch(actUpdateOptionAI(data));
  };

  const handleRemoveQuestion = (title) => {
    const data = {
      title,
    };
    dispatch(actRemoveQuestionAI(data));
  };

  const handleRemoveOption = (questionTitle, option) => {
    const data = {
      questionTitle,
      option,
    };
    dispatch(actRemoveOptionAI(data));
  };

  const handleAddOption = (questionTitle) => {
    const data = {
      questionTitle,
      option: "",
    };
    dispatch(actAddOptionAI(data));
  };

  const onSubmitPrompt = (formData) => {
    const data = {
      prompt: formData.ai_prompt,
    };

    const onSuccess = (res) => {
      navigateTo(`/herramientas/encuesta/ia`);
    };

    dispatch(actGenerateSurveyAI(data, onSuccess, onError));
    return;
  };

  const onSubmitSurvey = (e) => {
    e.preventDefault();

    const toolData = {
      id: surveyState.id,
      questions: toolStateAI.questions,
    };

    const onToolSuccess = (dataFromSection) => {
      toolStateAI.questions.forEach((question, questionIndex) => {
        const formData = new FormData();

        formData.append("question_type", question.question_type);
        formData.append("title", question.title);
        formData.append("order", questionIndex);
        formData.append("survey_section_id", dataFromSection.id);
        formData.append("required", true);

        switch (question.question_type) {
          case QUESTION_TYPES.Text.en:
            formData.append("long", false);
            formData.append("short", true);
            break;
          case QUESTION_TYPES.Checkbox.en:
          case QUESTION_TYPES.Radio.en:
            if (question.options?.length > 0) {
              question.options.forEach((option, optionIndex) => {
                formData.append(`options[${optionIndex}][name]`, option);
                formData.append(`options[${optionIndex}][is_incorrect]`, 0);
              });
            } else {
              formData.append("options[0][name]", question.question_type);
              formData.append("options[0][is_incorrect]", 0);
            }
            formData.append("in_random_order", 0);
            break;
          case QUESTION_TYPES.Range.en:
            formData.append("label_end", "max");
            formData.append("label_start", "min");
            formData.append("value_end", 0);
            formData.append("value_start", 10);
            break;
          case QUESTION_TYPES.Binary.en:
            formData.append("is_incorrect", 1);
            break;
          case QUESTION_TYPES.Ranking.en:
            if (question.options?.length > 0) {
              question.options.forEach((option, optionIndex) => {
                formData.append(`options[${optionIndex}][name]`, option);
              });
            } else {
              formData.append("options[0][name]", QUESTION_TYPES.Ranking.en);
              formData.append("options[0][is_incorrect]", 0);
            }
            break;
          default:
            break;
        }

        dispatch(actAddQuestion(formData, () => {}, onError));
      });

      setShowConfirmationModal(true);
    };

    dispatch(actAddSurveyAI(toolData, onToolSuccess, onError));
  };

  const onError = (errorMessage) => {
    error(<Toast text={errorMessage} listOfErrors={[]} state={"error"} />, {
      closeOnClick: true,
    });
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    dispatch(actCleanQuestionsAI());
    navigateTo(
      toolStateAI?.id
        ? `/herramientas/encuesta/${toolStateAI.id}/secciones/listado`
        : "/herramientas"
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const onSuccess = (data) => {
      setPrompCategories(data.data);
    }
    const onError = (errorMessage) => {
      error(<Toast text={errorMessage} />, {
        closeOnClick: true,
      });
    };

    dispatch(actGetPromptCategories(onSuccess, onError));
  }, []);

  useEffect(() => {
    const onSuccess = ({data}) => {
      console.log("pathParams", typeof pathParams.prompt_category)
      if (pathParams.prompt_category !== "") {
        setPromptlist(data);
      }
    }
    const onError = (error) => {
    }
    dispatch(actListPrompts({...pathParams}, onSuccess, onError))
  }, [pathParams]);

  const onSubmit = useCallback(
    (data) => {
      const types = data.categories.map(category => {
        return category.value
      })
      if (types.length) {
        setPathParams({
          prompt_category: types.join(',') ?? ""
        })
      }
    },
    []
  );

  return {
    control,
    handleCloseConfirmationModal,
    handleEditQuestion,
    handleEditOption,
    handleChangeQuestionVisibility,
    handleMakeQuestionEditable,
    handleRemoveQuestion,
    handleRemoveOption,
    handleSubmit,
    isQuestionEditable,
    isQuestionVisible,
    onSubmitPrompt,
    onSubmitSurvey,
    showConfirmationModal,
    watchAIPrompt,
    handleAddOption,
    promptByCategoryControl,
    getPromptByCategory,
    promptCategories,
    watchCategories,
    promptList,
    onSubmit,
    setPrompt
  };
};

export default useToolsCreateAI;
