// Packages
import styled from "styled-components";
import tw from "twin.macro";

export const StyledQuestionsList = styled.ul.attrs({
  className: "StyledQuestionsList space-y-2",
})`
  ${tw`mt-7`}
`;

export const StyledQuestionsListItem = styled.li.attrs({
  className: "StyledQuestionsListItem",
})`
  ${tw`flex mb-2`}

  > button {
    transition: 0.5s;
  }
`;

export const StyledQuestionsListItemMove = styled.span.attrs({
  className: "StyledQuestionsListItemMove",
})`
  ${tw`bg-third-200 flex justify-center items-center flex-col rounded-tr rounded-br`}
  margin-top: 3px;
  height: 32px;
  width: 32px;

  span {
    ${tw`block border-2 border-solid border-third-600 rounded-full`}
    height: 4.75px;
    width: 4.75px;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledQuestionsListItemNumber = styled.span.attrs({
  className: "StyledQuestionsListItemNumber",
})`
  ${tw`flex justify-center items-center bg-third rounded-tl rounded-bl text-xs`}
  margin-top: 3px;
  height: 32px;
  width: 32px;
`;

export const StyledQuestionsListItemContent = styled.div.attrs({
  className: "StyledQuestionsListItemContent",
})`
  ${tw`flex-1 border border-solid border-third-200 rounded bg-white relative overflow-hidden`}
  padding: 10px 16px;
  height: ${(props) => (props.active ? "auto" : "42px")};

  p {
    ${tw`text-sm truncate`}
    white-space: ${(props) => (props.active ? "initial" : "nowrap")};
    width: ${(props) => (props.active ? "100%" : "398px")};
    letter-spacing: -0.006em;
    line-height: 20px;
  }

  > div {
    ${tw`mt-4`}

    > span {
      ${tw`text-xs`}
    }

    > p {
      ${tw`text-sm font-light`}
      margin-top: 2px;
      letter-spacing: -0.006em;
    }
  }

  > button {
    ${tw`absolute`}
    right: 16px;
    top: 17px;
    transition: 0.5s;
    transform: rotate(${(props) => (props.active ? "180deg" : "0")});
  }
`;

export const StyledQuestionsListItemActionButtons = styled.div.attrs({
  className: "StyledQuestionsListItemActionButtons",
})`
  ${tw`flex justify-end`}

  button {
    margin-left: 20px;
  }
`;
