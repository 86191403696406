import { trackPromise } from "react-promise-tracker";
import useProviders from "../../providers";

const useGamificationServices = () => {
  const { useGamificationProvider } = useProviders();
  const { listGamificationProvider, addGamificationProvider, getGamificationProvider, editGamificationProvider, deleteGamificationProvider, } = useGamificationProvider();

  const listGamificationService = (url, { page }) => {    
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(listGamificationProvider(url, { page })));
      }
      catch(error) {
        reject(error);
      }
    })
  }
  
  const addGamificationService = (data, url) => {    
    return new Promise (async (resolve, reject) => {
      try {
        resolve(await trackPromise(addGamificationProvider(data, url)));
      }
      catch(error) {
        reject(error);
      }
    })
  }
  
  const getGamificationService = (id, url) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getGamificationProvider(id, url)));      
      } catch(error) {
        reject(error);
      }
    })
  }

  const editGamificationService = ({id, data, url, method}) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(editGamificationProvider({id, data, url, method})));
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const deleteGamificationService = ({id, url}) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteGamificationProvider({id, url})));
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    listGamificationService,
    addGamificationService,
    getGamificationService,
    editGamificationService,
    deleteGamificationService,
  }
}

export default useGamificationServices;