import { lazy } from "react"

const AccordionHeader = lazy(() => import("./AccordionHeader"));
const AccordionContent = lazy(() => import("./AccordionContent"));
const AccordionBody = lazy(() => import("./AccordionBody"));

const useAccordion = () => {
  return {
    AccordionHeader,
    AccordionContent,
    AccordionBody,
  }
}

export default useAccordion;