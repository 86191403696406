import React from "react";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import useScreenHooks from "../../../controllers/screenHooks";
import useLayouts from "../../layouts";

// Components
import View from "./View";
import Create from "./Create";

const BrandsLayout = () => {
  const { PrivateContentLayout } = useLayouts();
  const { useBrandsScreen } = useScreenHooks();
  const { handleBack, headerAux } = useBrandsScreen();
  const location = useLocation();

  return (
    <PrivateContentLayout
      breadcrumbsList={headerAux[location.pathname]}
      handleBack={handleBack}
      showBack={location.pathname !== "/marcas"}
      title="Marcas"
    >
      <Outlet />
    </PrivateContentLayout>
  );
};

const Brands = () => {
  return (
    <Routes>
      <Route path="/" element={<BrandsLayout />}>
        <Route index element={<View />} />
        <Route path="crear" element={<Create />} />
        <Route path="editar/:brandId" element={<Create />} />
      </Route>
    </Routes>
  );
};

export default Brands;
