// Packages
import styled from "styled-components";
import tw from "twin.macro";

// Components
import ContentWrappers from "../../../components/ContentWrappers";

export const StyledQuestionsHeader = styled(ContentWrappers)`
  ${tw`pb-6 border-b border-neutral-60 border-solid mb-8`}
`;

export const StyledQuestionsHeaderItems = styled.div.attrs({
  className: "StyledQuestionsHeaderItems",
})`
  h5 {
    ${tw`text-neutral-90`}
  }
`;

export const StyledQuestionsContent = styled.div.attrs({
  className: "StyledQuestionsContent",
})`
  ${tw`px-8`}
  h2 {
    ${tw`mb-1`}
  }
  h3 {
    ${tw`mb-7`}

    span {
      ${tw`text-third-600`}
    }
  }
`;

export const StyledQuestionsList = styled.ul.attrs({
  className: "StyledQuestionsList",
})`
  ${tw`mt-7`}
`;

export const StyledModalPreview = styled.div.attrs({
  className: "StyledModalPreview space-y-10 px-8 mx-8 flex flex-col justify-between",
})`
  min-width: 350px;
  min-height: 350px;
`;
